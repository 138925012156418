import { Button } from '@app/components/common/buttons/Button/Button.styles';
import styled from 'styled-components';

export const CardHeader = styled.div`
  width: 100%;
  padding: 1.5rem 2rem 1.5rem 2rem;
  border-radius: 0.5rem;
  border: 1px solid #bababa;
`;
export const CardHeaderTitle = styled.div`
  font-family: Mulish;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25rem;
  text-align: left;
  color: #8c8c8c;
`;
export const CardHeaderDescription = styled.div`
  font-family: Mulish;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.25rem;
  text-align: left;
  color: #545454;
`;

interface FilterButtonButtonProps {
  active?: boolean;
}

export const FilterButton = styled.div<FilterButtonButtonProps>`
  font-family: Mulish;
  font-size: 0.85rem;
  font-weight: 600;
  line-height: 1.25rem;
  text-align: left;

  display: flex;
  height: 3rem;
  padding: 1rem 1rem;
  align-items: center;
  border-radius: 2.5rem;
  cursor: pointer;

  background: ${({ active }) => (active ? 'var(--brand-colors-blue-blue-500, #263668)' : 'white')};
  color: ${({ active }) => (active ? 'white' : 'black')};
  border: ${({ active }) => (active ? 'none' : '1px solid #475582')};

  &:hover {
    background: ${({ active }) => (active ? '#1a2948' : '#f8f8f8')};
    color: ${({ active }) => (active ? '#f0f0f0' : '#2d2d2d')};
    border: ${({ active }) => (active ? 'none' : '1px solid #3d5077')};
  }
`;

export const ExportFileButton = styled(Button)`
  color: var(--secondary-color);
`;
export const Divider = styled.div`
  width: 100%;
  border-bottom: 0.1rem #d9d9d9 solid;
  margin-top: 2rem;
  margin-bottom: 2rem;
`;
