import { FONT_SIZE } from '@app/styles/themes/constants';
import { Tag } from 'antd';
import styled from 'styled-components';

export const TagJ = styled(Tag)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;

  /* padding: 0.5rem 0.5rem 0.5rem 1rem; */
  margin-bottom: 0.5rem;
  width: 27rem;
  height: 2.5rem;
  margin-right: 1rem;

  background: #f4f6fa;
  border: 1px solid #bababa;
  border-radius: 4px;
`;
export const Icon = styled.div`
  cursor: pointer;
`;
export const Title = styled.span`
  display: flex;

  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400;
  font-size: ${FONT_SIZE.xs};
  line-height: 1.125rem;
  color: var(--text-label);

  margin-left: 0.4rem;
`;
