import { H5 } from '@app/components/common/typography/H5/H5';
import {
  CentralTrackingReportModel,
  CentralTrackingShippingModel,
} from '@app/domain/interfaces/centralTracking/CentralTrackingModel';
import { Col, Divider, Row } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { FC } from 'react';
import * as S from './TableReportCentralTracking.styles';
import moment from 'moment';
import { ExpandableConfig } from 'antd/lib/table/interface';
import { IconDown, IconUp } from '@app/assets/slump-icons';
import { Tooltip } from '@app/components/common/Tooltip/Tooltip';
import { SlumpTableChart } from '../Charts/SlumpTableChart';
import { WaterTableChart } from '../Charts/WaterTableChart';

interface TableReportCentralTrackingProps {
  reportData?: CentralTrackingReportModel;
}

export const TableReportCentralTracking: FC<TableReportCentralTrackingProps> = ({ reportData }) => {
  const columns: ColumnsType<CentralTrackingShippingModel> = [
    {
      title: 'Remessa',
      dataIndex: 'idViagem',
      width: '10%',
      render: (_, shipping: CentralTrackingShippingModel) => {
        const color = shipping.finalizadoComErro
          ? '#6B25C6'
          : (shipping.aguaUtilizadaPorcentagem ?? 0) <= 30
          ? '#096DD9'
          : (shipping.aguaUtilizadaPorcentagem ?? 0) < 70
          ? '#FAAD14'
          : '#C41B24';
        return (
          <Row style={{ gap: '0.25rem' }}>
            <S.Rectangle color={color} />
            {shipping.idViagem}
          </Row>
        );
      },
      sorter: (a, b) => (a?.idViagem ? a.idViagem : 0) - (b?.idViagem ? b.idViagem : 0),
    },
    {
      title: 'Data Emissão NF',
      dataIndex: 'dataEmissaoNf',
      width: '15%',
      render: (data) => moment(data).format('DD/MM/yy HH:mm'),
      sorter: (a, b) =>
        (a?.dataEmissaoNf ? a.dataEmissaoNf.getTime() : 0) - (b?.dataEmissaoNf ? b.dataEmissaoNf.getTime() : 0),
    },
    {
      title: 'BT',
      dataIndex: 'betoneira',
      width: '7%',
      sorter: (a, b) => (a.betoneira && b.betoneira ? a.betoneira.localeCompare(b.betoneira) : 0),
    },
    {
      title: 'Motorista',
      dataIndex: 'motorista',
      width: '10%',
      sorter: (a, b) => (a.motorista && b.motorista ? a.motorista.localeCompare(b.motorista) : 0),
    },
    {
      title: 'Volume (m³)',
      dataIndex: 'volume',
      width: '10%',
      render: (volume) => (volume ? `${volume.toFixed(1)} m³` : '-'),
      sorter: (a, b) => (a?.volume ? a.volume : 0) - (b?.volume ? b.volume : 0),
    },
    {
      title: 'Água retida (L)',
      dataIndex: 'aguaTotal',
      width: '11%',
      render: (aguaTotal) => (aguaTotal ? `${aguaTotal} L` : '-'),
      sorter: (a, b) => (a?.aguaTotal ? a.aguaTotal : 0) - (b?.aguaTotal ? b.aguaTotal : 0),
    },
    {
      title: 'Água central (L)',
      dataIndex: 'aguaUtilizada',
      width: '10%',
      render: (aguaUtilizada) => (aguaUtilizada ? `${aguaUtilizada} L` : '-'),
      sorter: (a, b) => (a?.aguaUtilizada ? a.aguaUtilizada : 0) - (b?.aguaUtilizada ? b.aguaUtilizada : 0),
    },
    {
      title: 'Slump',
      dataIndex: 'slumpAtual',
      width: '5%',
      render: (_, shipping) =>
        shipping.slumpHistorico && (shipping.slumpHistorico?.length ?? 0) > 3
          ? `${shipping.slumpHistorico[shipping.slumpHistorico.length - 4].slump?.toFixed(1)}`
          : '-',
      sorter: (a, b) => (a?.slumpAtual ? a.slumpAtual : 0) - (b?.slumpAtual ? b.slumpAtual : 0),
    },
    {
      title: 'Traço',
      dataIndex: 'traco',
      width: '20%',
      sorter: (a, b) => (a?.volume ? a.volume : 0) - (b?.volume ? b.volume : 0),
      render: (traco) => <Tooltip title={traco}>{traco}</Tooltip>,
      ellipsis: true,
    },
  ];
  const expandableGroup: ExpandableConfig<CentralTrackingShippingModel> = {
    expandIcon: ({ expanded, onExpand, record }) => {
      return (
        <Row onClick={(e) => onExpand(record, e)} style={{ cursor: 'pointer' }}>
          {record.slumpHistorico?.length || record.aguaHistorico?.length ? expanded ? <IconUp /> : <IconDown /> : ''}
        </Row>
      );
    },
    expandedRowRender: (shipping) => (
      <Row>
        {shipping.idViagem &&
          shipping.slumpAlvo &&
          shipping.slumpRange &&
          shipping.slumpHistorico &&
          (shipping.slumpHistorico?.length ?? 0) > 0 && (
            <>
              <Col span={24}>
                <SlumpTableChart
                  idTravel={shipping.idViagem}
                  slumpAlvoMin={shipping.slumpAlvo - shipping.slumpRange}
                  slumpAlvoMax={shipping.slumpAlvo + shipping.slumpRange}
                  datetimeStart={shipping.dataHora}
                  datetimeEnd={shipping.dataHoraFinalizado}
                  slumpHistory={
                    shipping.slumpHistorico.length > 6
                      ? shipping.slumpHistorico.slice(3, shipping.slumpHistorico.length - 3)
                      : shipping.slumpHistorico
                  }
                />
              </Col>
              <Col span={24}>
                <Divider type="horizontal" />
              </Col>
            </>
          )}
        {shipping.idViagem &&
          shipping.aguaTotal &&
          shipping.aguaHistorico &&
          (shipping.aguaHistorico?.length ?? 0) > 0 && (
            <Col span={24}>
              <WaterTableChart
                idTravel={shipping.idViagem}
                waterLimit={shipping.aguaTotal}
                waterHistory={shipping.aguaHistorico}
                datetimeStart={shipping.dataHora}
                datetimeEnd={shipping.dataHoraFinalizado}
              />
            </Col>
          )}
      </Row>
    ),
  };

  return (
    <Row gutter={[8, 32]} style={{ width: '100%' }}>
      {reportData?.obras.map((obra) => (
        <Col key={obra.obraId} span={24}>
          <H5>{obra.obra}</H5>
          {obra.remessas?.length && (
            <S.TableReport
              columns={columns}
              dataSource={obra.remessas}
              expandable={expandableGroup}
              bordered
              rowKey="idViagem"
            />
          )}
        </Col>
      ))}
    </Row>
  );
};
