import * as React from 'react';
const SvgIconInfoCircle = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 36 36" width="1em" height="1em" role="img">
    <g fill="current" clipPath="url(#a)">
      <path d="M18 0C8.06 0 0 8.06 0 18s8.06 18 18 18 18-8.06 18-18S27.94 0 18 0Zm0 32.946C9.747 32.946 3.054 26.253 3.054 18S9.747 3.054 18 3.054 32.946 9.747 32.946 18 26.253 32.946 18 32.946Z" />
      <path d="M16.07 10.929a1.929 1.929 0 1 0 3.858 0 1.929 1.929 0 0 0-3.857 0Zm2.894 4.5h-1.929a.322.322 0 0 0-.321.321v10.929c0 .176.144.321.321.321h1.929a.322.322 0 0 0 .321-.321V15.75a.322.322 0 0 0-.321-.321Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h36v36H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgIconInfoCircle;
