import * as React from 'react';
const SvgIconAccountBook = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 36 36" width="1em" height="1em" role="img">
    <path
      fill="#000"
      fillOpacity={0.85}
      d="M32.785 4.826h-6.75V2.255a.322.322 0 0 0-.321-.322h-2.25a.322.322 0 0 0-.322.322v2.571H12.857V2.255a.322.322 0 0 0-.322-.322h-2.25a.322.322 0 0 0-.321.322v2.571h-6.75c-.711 0-1.286.575-1.286 1.286V32.79c0 .71.575 1.285 1.286 1.285h29.571c.711 0 1.286-.574 1.286-1.286V6.113c0-.711-.575-1.286-1.286-1.286Zm-1.607 26.357H4.821V7.72h5.143v1.929c0 .176.144.321.321.321h2.25a.322.322 0 0 0 .322-.321v-1.93h10.285v1.929c0 .176.145.321.322.321h2.25a.322.322 0 0 0 .321-.321V7.719h5.143v23.464Zm-8.056-17.116h-1.808a.317.317 0 0 0-.285.177l-2.95 5.842h-.112l-2.949-5.842a.322.322 0 0 0-.285-.177h-1.848a.323.323 0 0 0-.281.478l3.588 6.59h-1.953a.322.322 0 0 0-.322.321v.856c0 .177.145.321.322.321h2.615v1.354H14.24a.322.322 0 0 0-.322.322v.856c0 .176.145.321.322.321h2.615v2.162c0 .176.145.321.322.321h1.66a.322.322 0 0 0 .32-.321v-2.162h2.628a.322.322 0 0 0 .322-.321v-.856a.322.322 0 0 0-.322-.322h-2.627v-1.354h2.627a.322.322 0 0 0 .322-.321v-.856a.322.322 0 0 0-.322-.321h-1.972L23.4 14.54c.024-.048.04-.1.04-.152a.314.314 0 0 0-.318-.322Z"
    />
  </svg>
);
export default SvgIconAccountBook;
