import styled, { css } from 'styled-components';
import { Button as AntButton } from 'antd';
import { Severity } from '@app/constants/interfaces/interfaces';
import { isUndefined } from 'lodash';

interface ButtonTabProps {
  severity?: Severity;
}

export const ButtonTab = styled(AntButton)<ButtonTabProps>`
  border-radius: 0.375rem;
  width: 15.188rem;
  height: 3.825rem;
  margin-right: 1rem;

  ${({ severity }) => {
    if (!isUndefined(severity)) {
      switch (severity) {
        case 'warning':
          return css`
            background-color: var(--secondary-color);
            border-color: var(--secondary-color);
            color: var(--background-main-color);
            &:hover {
              background-color: var(--secondary-color);
              border-color: var(--secondary-color);
              color: var(--background-main-color);
            }
          `;
        case 'success':
          return css`
            background-color: var(--secondary-color);
            border-color: var(--secondary-color);
            color: var(--background-main-color);
            &:hover {
              background-color: var(--secondary-color);
              border-color: var(--secondary-color);
              color: var(--background-main-color);
            }
          `;
        case 'error':
          return css`
            background-color: var(--secondary-color);
            border-color: var(--secondary-color);
            color: var(--background-main-color);
            &:hover {
              background-color: var(--secondary-color);
              border-color: var(--secondary-color);
              color: var(--background-main-color);
            }
          `;
      }
    } else {
      return css`
        color: var(--text-label);
        border-color: var(--secondary-color);
        background: var(--background-main-color);
        &:hover {
          background: var(--button-tab-color);
          border-color: var(--secondary-color);
          color: var(--text-label);
        }
      `;
    }
  }}
`;
