import { DeviceModel } from '@app/domain/interfaces/device/deviceModel';
import * as S from './CardDeviceInformation.styles';
import { Col, Row } from 'antd';

interface ICardDeviceInformation {
  device: DeviceModel;
}

const CardDeviceInformation = ({ device }: ICardDeviceInformation) => {
  return (
    <S.CardDeviceInformationContainer>
      <Row style={{ marginBottom: '0.8rem' }}>
        <S.CardDeviceInformationTitle>Dispositivo</S.CardDeviceInformationTitle>
      </Row>
      <Row gutter={24} style={{ marginBottom: '0.8rem' }} justify="space-between">
        <Col span={6} style={{ margin: '0' }}>
          <S.CardDeviceInformationProperty>Código</S.CardDeviceInformationProperty>
          <S.CardDeviceInformationValue>{device.id}</S.CardDeviceInformationValue>
        </Col>
        <Col span={6}>
          <S.CardDeviceInformationProperty>Apelido</S.CardDeviceInformationProperty>
          <S.CardDeviceInformationValue>{device.deviceId}</S.CardDeviceInformationValue>
        </Col>
        <Col span={6}>
          <S.CardDeviceInformationProperty>Modelo</S.CardDeviceInformationProperty>
          <S.CardDeviceInformationValue>{device.tipo == 'T' ? 'Tablet' : 'Hermes'}</S.CardDeviceInformationValue>
        </Col>
      </Row>
      <Row>
        <Col>
          <S.CardDeviceInformationProperty>IMEI</S.CardDeviceInformationProperty>
          <S.CardDeviceInformationValue>{device.imei}</S.CardDeviceInformationValue>
        </Col>
      </Row>
    </S.CardDeviceInformationContainer>
  );
};

export default CardDeviceInformation;
