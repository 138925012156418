import { Collapse } from 'antd';
import styled from 'styled-components';

export const ContainerCollapse = styled(Collapse)`
  border-top: 1px solid var(--disabled-bg-color);
  width: 100%;
  color: var(--secondary-color);

  && .ant-collapse-item {
    padding: 2rem 0rem 2rem 0rem;
  }

  && .ant-collapse-header {
    padding: 0rem;
  }

  .ant-container-collapse-title {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    display: flex;
    align-items: center;
    color: var(--tooltip-background);
    padding-bottom: 1rem;
  }
`;
