import React from 'react';
import { Col, Row, Tag } from 'antd';
import * as S from './view.styles';

interface IViewFiltersHeader {
  dataType: string;
  periodType: string;
  period: string;
  regional: string;
  branch: string;
  numberoftrips: number;
  userName: string;
  withdrawnDate: string;
  numeroMinViagens?: string | null;
}

export const ViewFiltersHeader: React.FC<IViewFiltersHeader> = ({
  dataType,
  periodType,
  period,
  regional,
  branch,
  numberoftrips,
  userName,
  withdrawnDate,
  numeroMinViagens,
}) => {
  return (
    <S.CollapseContainer>
      <S.Collapse bordered={false} defaultActiveKey={1}>
        <h1>Informações do relatório</h1>
        <S.DisplayContainer>
          <Row gutter={24} justify={'space-between'}>
            <Col xs={24} md={12} lg={4} xxl={4}>
              <S.DeviceCardInfoProperty>Tipo de dado</S.DeviceCardInfoProperty>
              <S.DeviceCardInfoValue>{dataType ?? '-'}</S.DeviceCardInfoValue>
            </Col>
            {numeroMinViagens && (
              <Col xs={24} md={12} lg={3} xxl={3}>
                <S.DeviceCardInfoProperty>N° mínimo de viagens</S.DeviceCardInfoProperty>
                <S.DeviceCardInfoValue>{numeroMinViagens}</S.DeviceCardInfoValue>
              </Col>
            )}
            <Col xs={24} md={12} lg={2}>
              <S.DeviceCardInfoProperty>Tipo Período</S.DeviceCardInfoProperty>
              <S.DeviceCardInfoValue>{periodType ?? '-'}</S.DeviceCardInfoValue>
            </Col>
            <Col xs={24} md={12} lg={3}>
              <S.DeviceCardInfoProperty>Período</S.DeviceCardInfoProperty>
              <S.DeviceCardInfoValue>{period ?? '-'}</S.DeviceCardInfoValue>
            </Col>
            <Col xs={24} md={12} lg={2}>
              <S.DeviceCardInfoProperty>Regional</S.DeviceCardInfoProperty>
              <S.DeviceCardInfoValue>{regional ?? '-'}</S.DeviceCardInfoValue>
            </Col>
            <Col xs={24} md={12} lg={4} xxl={4}>
              <S.DeviceCardInfoProperty>Filial</S.DeviceCardInfoProperty>
              <S.DeviceCardInfoValue>{branch ?? '-'}</S.DeviceCardInfoValue>
            </Col>
            <Col xs={24} md={12} lg={4} xxl={4}>
              <S.DeviceCardInfoProperty>Número de viagens</S.DeviceCardInfoProperty>
              <S.DeviceCardInfoValue>{numberoftrips ?? '-'}</S.DeviceCardInfoValue>
            </Col>
            {/* <Col xs={24} md={12} lg={4} xxl={4}>
              <S.DeviceCardInfoProperty>Data retirada</S.DeviceCardInfoProperty>
              <S.DeviceCardInfoValue>{withdrawnDate ?? '-'}</S.DeviceCardInfoValue>
            </Col>
            <Col xs={24} md={12} lg={5} xxl={4}>
              <S.DeviceCardInfoProperty>Usuário</S.DeviceCardInfoProperty>
              <S.DeviceCardInfoValue>{userName ?? '-'}</S.DeviceCardInfoValue>
            </Col> */}
          </Row>
          <br />
        </S.DisplayContainer>
      </S.Collapse>
    </S.CollapseContainer>
  );
};
