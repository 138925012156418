import { InfoCircleOutlined } from '@ant-design/icons';
import { Modal } from '@app/components/common/Modal/Modal';
import { notificationController } from '@app/controllers/notificationController';
import { AlertShippingModel } from '@app/domain/interfaces/alert/alertShippingResponseModel';
import { ReplyAlertRequestModel } from '@app/domain/interfaces/alert/replyAlertRequestModel';
import { Justificativa } from '@app/domain/interfaces/configAlertModel';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import IAlertService, { AlertService } from '@app/services/alertService';
import IConfigAlertService, { ConfigAlertService } from '@app/services/configAlertService';
import { readUser } from '@app/services/localStorage.service';
import { setFooter } from '@app/store/slices/footerPageSlice';
import { setHeaderRegisterPage } from '@app/store/slices/headerRegisterPage';
import { Col, Row } from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ColetasSlump, SummaryAlertModel } from '@app/domain/interfaces/alert/summaryAlertModel';
import ISummaryAlertService, { SummaryAlertService } from '@app/services/summaryAlertService';
import WaterAlertJustify from './components/WaterAlertJustify';
import SimpleAlertJustify from './components/SimpleAlertJustify';

const alertService: IAlertService = new AlertService();
const summaryService: ISummaryAlertService = new SummaryAlertService();
const configAlertService: IConfigAlertService = new ConfigAlertService();

const AlertJustify: React.FC = () => {
  const { id, waterAlert } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [checkboxConfirm, setCheckboxConfirm] = useState(false);
  const [modalCancelVisible, setModalCancelVisible] = useState(false);
  const [isWaterAlert, setIsWaterAlert] = useState(false);
  const [report, setReport] = useState(false);
  const [alert, setAlert] = useState<AlertShippingModel>({} as AlertShippingModel);
  const [justifications, setJustifications] = useState<Justificativa[]>([]);
  const [summaryAlert, setSummaryAlert] = useState<SummaryAlertModel>({} as SummaryAlertModel);

  const fetchJustifications = async (chave: number) => {
    if (!chave) return;

    setLoading(true);

    const response = await configAlertService.getArray('obter');
    const justificationsResponse = response.find((r) => r.chaveAlerta === chave)?.justificativas;
    setJustifications(justificationsResponse ?? []);

    setLoading(false);
  };

  const fetchAlert = async () => {
    try {
      setLoading(true);

      if (!id) {
        navigate('/');
        notificationController.error({ message: 'Id do alerta não informado' });
        return;
      }

      const resAlert = await alertService.getAlertShippingByIdAlert(id);
      fetchJustifications(resAlert.alerta.logAlarmes[0].chave);
      setReport(resAlert.alerta?.status?.toString() == 'Reportado');
      setAlert(resAlert);
      setLoading(false);
    } catch {
      notificationController.error({ message: 'Não foi possível buscar os dados do alerta' });
    }
  };

  const normalizeData = (data: SummaryAlertModel): SummaryAlertModel => {
    const dadosMedicoes = data.coletasSlump;
    const viagemEstado = data.viagemEstados ?? [];
    const estadosARemover = [4, 5, 6];
    const slumpAlvoMax = data.slumpAlvoMax ?? 20;

    const estadosOrdenados = viagemEstado.sort(
      (a, b) => new Date(a.dataHora).getTime() - new Date(b.dataHora).getTime(),
    );

    const classificacaoPorEstado = new Map<number, ColetasSlump[]>();

    estadosOrdenados.forEach((estado) => {
      classificacaoPorEstado.set(estado.estadoViagem!, []);
    });

    dadosMedicoes.forEach((coleta) => {
      const estadoCorrespondente = estadosOrdenados.find(
        (estado) => new Date(estado.dataHora) > new Date(coleta.dataHora),
      );
      const estadoId = estadoCorrespondente
        ? estadoCorrespondente.estadoViagem
        : estadosOrdenados[estadosOrdenados.length - 1].estadoViagem;

      if (estadoId !== undefined) {
        const coletasNoEstado = classificacaoPorEstado.get(estadoId) || [];
        coletasNoEstado.push(coleta);
        classificacaoPorEstado.set(estadoId, coletasNoEstado);
      }
    });

    estadosARemover.forEach((estado) => {
      const coletas = classificacaoPorEstado.get(estado);
      if (coletas) {
        classificacaoPorEstado.set(estado, coletas.slice(0, -2));
      }
    });

    classificacaoPorEstado.forEach((coletas, estado) => {
      if (estadosARemover.includes(estado)) {
        classificacaoPorEstado.set(
          estado,
          coletas.filter((coleta) => coleta.centimetros !== undefined && coleta.centimetros <= slumpAlvoMax + 20),
        );
      }
    });

    const coletasFiltradas: ColetasSlump[] = [];
    classificacaoPorEstado.forEach((coletas) => {
      coletasFiltradas.push(...coletas);
    });

    return {
      ...data,
      coletasSlump: coletasFiltradas,
      viagemEstados: estadosOrdenados,
    };
  };

  const fetchSummaryAlert = async () => {
    try {
      setLoading(true);
      const res = await summaryService.get(`obterPorIdAlerta/${id}`);
      if (
        res.alerta !== null &&
        res.alerta !== undefined &&
        res.alerta.logAlarmes !== undefined &&
        res.alerta.logAlarmes.length > 0
      ) {
        fetchJustifications(res.alerta.logAlarmes[0].chave);
      } else {
        notificationController.info({ message: 'Tente novamente mais tarde. \n Aguardando informações da viagem!' });
      }
      setReport(res.alerta?.status.toString() == 'Reportado');
      const normalizedData = normalizeData(res);
      setSummaryAlert(normalizedData);

      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.error(e);
      notificationController.error({ message: 'Não foi possível buscar os dados do gráfico' });
    }
  };

  const handleJustifyAlertClick = () => {
    setLoading(true);

    const alertJustify = (
      isWaterAlert
        ? {
            IdAlerta: summaryAlert.alerta.id,
            Explicacao: summaryAlert.alerta.explicacao,
            idJustificativa: summaryAlert.alerta.idJustificativa,
            Responsavel: readUser()?.username,
            Status: report ? 1 : 2,
          }
        : {
            IdAlerta: alert.alerta.id,
            Explicacao: alert.alerta.explicacao,
            idJustificativa: alert.alerta.idJustificativa,
            Responsavel: readUser()?.username,
            Status: report ? 1 : 2,
          }
    ) as ReplyAlertRequestModel;

    alertService
      .replyAlert(alertJustify)
      .then(() => {
        navigate('/');
        setTimeout(() => {
          notificationController.success({ message: 'Alerta justificado com sucesso' });
        }, 1700);
      })
      .catch((error) => {
        notificationController.error(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (waterAlert === 'true') {
      setIsWaterAlert(true);
      fetchSummaryAlert();
      dispatch(
        setFooter({
          confirmButtonText: 'Justificar alerta',
          cancelButtonText: 'Cancelar',
          handleCancelButtonClick: () => setModalCancelVisible(true),
          handleConfirmButtonClick: handleJustifyAlertClick,
          confirmButtonDisabled: summaryAlert.alerta?.status?.toString() !== 'Novo',
        }),
      );
    } else {
      setIsWaterAlert(false);
      fetchAlert();
      dispatch(
        setFooter({
          confirmButtonText: 'Justificar alerta',
          cancelButtonText: 'Cancelar',
          handleCancelButtonClick: () => setModalCancelVisible(true),
          handleConfirmButtonClick: handleJustifyAlertClick,
          confirmButtonDisabled: alert.alerta?.status?.toString() !== 'Novo',
        }),
      );
    }
    dispatch(
      setHeaderRegisterPage({
        title: `Justificar alerta `,
        handleBackClick: () => navigate('/'),
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (waterAlert === 'false') {
      dispatch(
        setFooter({
          confirmButtonText: 'Justificar alerta',
          cancelButtonText: 'Cancelar',
          handleCancelButtonClick: () => setModalCancelVisible(true),
          handleConfirmButtonClick: handleJustifyAlertClick,
          confirmButtonDisabled:
            alert.alerta?.status?.toString() !== 'Novo' || !(alert.alerta.explicacao && checkboxConfirm),
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alert, checkboxConfirm]);

  useEffect(() => {
    if (waterAlert === 'true') {
      dispatch(
        setFooter({
          confirmButtonText: 'Justificar alerta',
          cancelButtonText: 'Cancelar',
          handleCancelButtonClick: () => setModalCancelVisible(true),
          handleConfirmButtonClick: handleJustifyAlertClick,
          confirmButtonDisabled:
            summaryAlert.alerta?.status?.toString() !== 'Novo' || !(summaryAlert.alerta.explicacao && checkboxConfirm),
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [summaryAlert, checkboxConfirm]);

  return (
    <>
      <Modal
        title={`Cancelar justificativa do alerta`}
        visible={modalCancelVisible}
        onOk={() => navigate('/')}
        onCancel={() => setModalCancelVisible(false)}
        cancelText="Cancelar"
        okText="Confirmar"
      >
        <Row align="middle">
          <Col span={2}>
            <InfoCircleOutlined size={20} style={{ color: '#FAAD14' }} />
          </Col>
          <Col span={22}>
            <div>
              Deseja realmente {` `} <span className="span-bold"> cancelar a justificativa do alerta?</span>
            </div>
          </Col>
        </Row>
      </Modal>
      {isWaterAlert ? (
        <WaterAlertJustify
          summaryAlert={summaryAlert}
          setSummaryAlert={setSummaryAlert}
          justifications={justifications}
          checkboxConfirm={checkboxConfirm}
          setCheckboxConfirm={setCheckboxConfirm}
          loading={loading}
          report={report}
          setReport={setReport}
        />
      ) : (
        <SimpleAlertJustify
          alert={alert}
          setAlert={setAlert}
          justifications={justifications}
          checkboxConfirm={checkboxConfirm}
          setCheckboxConfirm={setCheckboxConfirm}
          loading={loading}
          report={report}
          setReport={setReport}
        />
      )}
    </>
  );
};

export default AlertJustify;
