import React from 'react';
import { ButtonProps, Row } from 'antd';
import { Severity } from '@app/constants/interfaces/interfaces';
import * as S from './ButtonTab.styles';
import { Badge } from '../../Badge/Badge';

interface ButtonTabProps extends ButtonProps {
  description?: string;
  badgeCount?: number;
  severity?: Severity;
  icon?: JSX.Element;
}

export const ButtonTab = React.forwardRef<HTMLElement, ButtonTabProps>(
  ({ description, badgeCount, icon, severity, ...props }, ref) => (
    <S.ButtonTab ref={ref} {...props} severity={severity}>
      <Row justify="space-between" align="middle">
        <Row>
          {icon}
          {description}
        </Row>
        <Badge
          count={badgeCount}
          severity={severity}
          style={{ borderColor: 'transparent', fontSize: '1.125rem' }}
          overflowCount={999}
        />
      </Row>
    </S.ButtonTab>
  ),
);
