/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { withLoading } from '@app/hocs/withLoading.hoc';
import { RouteSlumpMixConfig, RoutesSlumpMix } from './routes';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { hasAccessByRole, hasAccessByRoles } from '@app/controllers/accessController';
import { useAppSelector } from '@app/hooks/reduxHooks';
const Logout = React.lazy(() => import('./Logout'));
const Error404Page = React.lazy(() => import('@app/pages/Error404Page'));
const ServerErrorPage = React.lazy(() => import('@app/pages/ServerErrorPage'));
const AuthLayout = React.lazy(() => import('@app/components/layouts/AuthLayout/AuthLayout'));

export const HOME_PATH = '/';

const ServerError = withLoading(ServerErrorPage);
const Error404 = withLoading(Error404Page);
const AuthLayoutFallback = withLoading(AuthLayout);
const LogoutFallback = withLoading(Logout);

const baseRoutes = RoutesSlumpMix.reduce((result, current) => {
  (result[current.layout?.key ?? ''] = result[current.layout?.key ?? ''] || []).push(current);
  return result;
}, Object.create(null));

export const AppRouter: React.FC = () => {
  const { user } = useAppSelector((state) => state.user);
  const [routes, setRoutes] = useState(baseRoutes);

  useEffect(() => {
    setRoutes(baseRoutes);
  }, [user]);

  return (
    <BrowserRouter>
      <Routes>
        {Object.keys(routes).map((property, index) => {
          const currentRoutes = routes[property] as RouteSlumpMixConfig[];

          return (
            <>
              {currentRoutes.map((route) => {
                if (route.children && route.children?.length > 0) {
                  return route.children?.map((routeChild) => {
                    return (
                      <Route key={index} path={HOME_PATH} element={routeChild.layout}>
                        <Route
                          key={routeChild.key}
                          path={routeChild.path}
                          element={hasAccessByRole(routeChild.role) ? routeChild.element : <></>}
                        />
                      </Route>
                    );
                  });
                } else if (route.element) {
                  return (
                    <Route key={index} path={HOME_PATH} element={route.layout}>
                      <Route
                        key={route.key}
                        path={route.path}
                        element={hasAccessByRoles(route.roles) ? route.element : <></>}
                      />
                    </Route>
                  );
                }
              })}
            </>
          );
        })}
      </Routes>
    </BrowserRouter>
  );
};
