import * as React from 'react';
const SvgIconMap = () => (
  <svg width="25" height="24" viewBox="0 0 25 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21 3L20.84 3.03L15.5 5.1L9.5 3L3.86 4.9C3.65 4.97 3.5 5.15 3.5 5.38V20.5C3.5 20.78 3.72 21 4 21L4.16 20.97L9.5 18.9L15.5 21L21.14 19.1C21.35 19.03 21.5 18.85 21.5 18.62V3.5C21.5 3.22 21.28 3 21 3ZM10.5 5.47L14.5 6.87V18.53L10.5 17.13V5.47ZM5.5 6.46L8.5 5.45V17.15L5.5 18.31V6.46ZM19.5 17.54L16.5 18.55V6.86L19.5 5.7V17.54Z"
      fill="current"
    />
  </svg>
);
export default SvgIconMap;
