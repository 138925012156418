import React from 'react';
import { TypeReport } from '@app/constants/enums/report';
import { Table } from '@app/components/common/Table/Table';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { ColumnsType } from 'antd/lib/table/Table';
import { Col, Row } from 'antd';
import { Button } from '@app/components/common/buttons/Button/Button';
import IconFileExcel from '@app/assets/slump-icons/IconFileExcel';
import { ReportTimeDetailedShipmentModel } from '@app/domain/interfaces/report/reportTimeModel';
import { ReportWaterUsageDetailedShippingModel } from '@app/domain/interfaces/report/reportWaterDetailModel';
import moment from 'moment';
import { genericExportToExcel } from '@app/utils/exportToExcel';
import { exportReportTimeDetailedToExcel } from '../function/excelFunctions';
import { ReportDriverDetailedShippingModel } from '@app/domain/interfaces/report/reportDriverDetailModel';
import { ReportTraceDetailedShippingModel } from '@app/domain/interfaces/report/reporTraceDetailModel';
import { ReactComponent as IconDashboard } from '@app/assets/icons/dashboardIcon.svg';

interface IBreakdownTableProps {
  data:
    | ReportWaterUsageDetailedShippingModel[]
    | ReportTimeDetailedShipmentModel[]
    | ReportDriverDetailedShippingModel[]
    | ReportTraceDetailedShippingModel[];
  type: TypeReport;
}

export const BreakdownTable: React.FC<IBreakdownTableProps> = ({ type, data }) => {
  const reportTypeNames = {
    [TypeReport.Water]: 'Uso de Água',
    [TypeReport.Time]: 'Tempo',
    [TypeReport.Driver]: 'Motorista',
    [TypeReport.Trace]: 'Traço',
  };

  const columnsForWaterReport: ColumnsType<ReportWaterUsageDetailedShippingModel> = [
    {
      title: 'Remessa',
      dataIndex: 'idViagem',
      showSorterTooltip: false,
      width: '100px',
    },
    {
      title: 'Emissão NF',
      dataIndex: 'dataNotaFiscal',
      showSorterTooltip: false,
      render: (dataNotaFiscal: Date) =>
        dataNotaFiscal ? moment(dataNotaFiscal).format('DD/MM/YYYY HH:mm') + 'h' : '-',
      width: '165px',
    },
    {
      title: 'BT',
      dataIndex: 'betoneira',
      showSorterTooltip: false,
      width: '69px',
    },
    {
      title: 'Motorista',
      dataIndex: 'motorista',
      showSorterTooltip: false,
      width: '270px',
    },
    // {
    //   title: 'Obra',
    //   dataIndex: 'obra',
    //   showSorterTooltip: false,
    //   width: '256px',
    // },
    {
      title: 'Volume(m³)',
      dataIndex: 'volume',
      showSorterTooltip: false,
      width: '137px',
      render: (value: number) => {
        return value !== undefined ? value.toFixed(1) : '-';
      },
    },
    {
      title: 'Água retida(L)',
      dataIndex: 'aguaRetida',
      showSorterTooltip: false,
      width: '127px',
      render: (value: number) => {
        return value !== undefined ? value.toFixed(1) : '-';
      },
    },
    {
      title: 'Água central(L)',
      dataIndex: 'aguaCentral',
      showSorterTooltip: false,
      width: '132px',
      render: (value: number) => {
        return value !== undefined ? value.toFixed(1) : '-';
      },
    },
    {
      title: 'Água obra(L)',
      dataIndex: 'aguaObra',
      showSorterTooltip: false,
      width: '100px',
      render: (value: number) => {
        return value !== undefined ? value.toFixed(1) : '-';
      },
    },
    {
      title: 'Sobra d’água(L/m³)',
      dataIndex: 'aguaSobra',
      showSorterTooltip: false,
      width: '130px',
      render: (value: number) => {
        return value !== undefined ? value.toFixed(1) : '-';
      },
    },
    {
      title: 'Traço',
      dataIndex: 'traco',
      showSorterTooltip: true,
      width: '400px',
      render: (trace: string) => <span title={trace}>{trace.length > 50 ? `${trace.slice(0, 50)}...` : trace}</span>,
    },
    {
      title: '',
      key: 'action',
      render: (_, item) => (
        <Button type="link" onClick={() => handleRedirectToDetails(item.idViagem)}>
          <IconDashboard />
        </Button>
      ),
    },
  ];
  const columnsForTimeReport: ColumnsType<ReportTimeDetailedShipmentModel> = [
    {
      title: '',
      children: [
        {
          title: <div style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>Remessa</div>,
          dataIndex: 'idViagem',
          showSorterTooltip: false,
          render: (value) => <div style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{value}</div>,
        },
        {
          title: <div style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>Data Emissão NF</div>,
          dataIndex: 'dataNotaFiscal',
          showSorterTooltip: false,
          render: (dataNotaFiscal: Date) => (
            <div style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
              {dataNotaFiscal ? moment(dataNotaFiscal).format('DD/MM/YYYY HH:mm') + 'h' : '-'}
            </div>
          ),
        },
        {
          title: 'BT',
          dataIndex: 'betoneira',
          showSorterTooltip: false,
          render: (value) => <div style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{value}</div>,
        },
        {
          title: 'Motorista',
          dataIndex: 'motorista',
          showSorterTooltip: false,
          render: (value) => <div style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{value}</div>,
        },
        {
          title: 'Obra',
          dataIndex: 'obra',
          showSorterTooltip: false,
          render: (value) => <div style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{value}</div>,
        },
        {
          title: <div style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>Volume (m³)</div>,
          dataIndex: 'volume',
          showSorterTooltip: false,
          align: 'center',
          render: (value) => (
            <div style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
              {value !== undefined ? value.toFixed(1) : '-'}
            </div>
          ),
        },
      ],
    },
    {
      title: 'CENTRAL',
      children: [
        {
          title: <div style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>Tempo (min)</div>,
          dataIndex: 'central',
          align: 'center',
          render: (value) => (
            <div style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
              {value.tempo ? value.tempo.toFixed(1) : '-'}
            </div>
          ),
        },
        {
          title: <div style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>Diesel (L)</div>,
          dataIndex: 'central',
          align: 'center',
          render: (value) => (
            <div style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
              {value.diesel ? value.diesel.toFixed(1) : '-'}
            </div>
          ),
        },
        {
          title: 'Km',
          dataIndex: 'central',
          align: 'center',
          render: (value) => (
            <div style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
              {value.distancia ? value.distancia.toFixed(1) : '-'}
            </div>
          ),
        },
        {
          title: <div style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>Diesel (L/min)</div>,
          dataIndex: 'central',
          align: 'center',
          render: (value) => (
            <div style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
              {value.consumoDiesel ? value.consumoDiesel.toFixed(3) : '-'}
            </div>
          ),
        },
        {
          title: <div style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>Km (L)</div>,
          dataIndex: 'central',
          align: 'center',
          render: (value) => (
            <div style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
              {value.consumoMedio ? value.consumoMedio.toFixed(3) : '-'}
            </div>
          ),
        },
      ],
    },
    {
      title: 'A CAMINHO',
      children: [
        {
          title: <div style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>Tempo (min)</div>,
          dataIndex: 'aCaminho',
          align: 'center',
          render: (value) => (
            <div style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
              {value.tempo ? value.tempo.toFixed(1) : '-'}
            </div>
          ),
        },
        {
          title: <div style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>Diesel (L)</div>,
          dataIndex: 'aCaminho',
          align: 'center',
          render: (value) => (
            <div style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
              {value.diesel ? value.diesel.toFixed(1) : '-'}
            </div>
          ),
        },
        {
          title: 'Km',
          dataIndex: 'aCaminho',
          align: 'center',
          render: (value) => (
            <div style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
              {value.distancia ? value.distancia.toFixed(1) : '-'}
            </div>
          ),
        },
        {
          title: <div style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>Diesel (L/min)</div>,
          dataIndex: 'aCaminho',
          align: 'center',
          render: (value) => (
            <div style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
              {value.consumoDiesel ? value.consumoDiesel.toFixed(3) : '-'}
            </div>
          ),
        },
        {
          title: <div style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>Km (L)</div>,
          dataIndex: 'aCaminho',
          align: 'center',
          render: (value) => (
            <div style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
              {value.consumoMedio ? value.consumoMedio.toFixed(3) : '-'}
            </div>
          ),
        },
      ],
    },
    {
      title: 'AGUARDO',
      children: [
        {
          title: <div style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>Tempo (min)</div>,
          dataIndex: 'aguardo',
          align: 'center',
          render: (value) => (
            <div style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
              {value.tempo ? value.tempo.toFixed(1) : '-'}
            </div>
          ),
        },
        {
          title: <div style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>Diesel (L)</div>,
          dataIndex: 'aguardo',
          align: 'center',
          render: (value) => (
            <div style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
              {value.diesel ? value.diesel.toFixed(1) : '-'}
            </div>
          ),
        },
        {
          title: 'Km',
          dataIndex: 'aguardo',
          align: 'center',
          render: (value) => (
            <div style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
              {value.distancia ? value.distancia.toFixed(1) : '-'}
            </div>
          ),
        },
        {
          title: <div style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>Diesel (L/min)</div>,
          dataIndex: 'aguardo',
          align: 'center',
          render: (value) => (
            <div style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
              {value.consumoDiesel ? value.consumoDiesel.toFixed(3) : '-'}
            </div>
          ),
        },
        {
          title: <div style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>Km (L)</div>,
          dataIndex: 'aguardo',
          align: 'center',
          render: (value) => (
            <div style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
              {value.consumoMedio ? value.consumoMedio.toFixed(3) : '-'}
            </div>
          ),
        },
      ],
    },
    {
      title: 'DESCARGA',
      children: [
        {
          title: <div style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>Tempo (min)</div>,
          dataIndex: 'descarga',
          align: 'center',
          render: (value) => (
            <div style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
              {value.tempo ? value.tempo.toFixed(1) : '-'}
            </div>
          ),
        },
        {
          title: <div style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>Diesel (L)</div>,
          dataIndex: 'descarga',
          align: 'center',
          render: (value) => (
            <div style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
              {value.diesel ? value.diesel.toFixed(1) : '-'}
            </div>
          ),
        },
        {
          title: 'Km',
          dataIndex: 'descarga',
          align: 'center',
          render: (value) => (
            <div style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
              {value.distancia ? value.distancia.toFixed(1) : '-'}
            </div>
          ),
        },
        {
          title: <div style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>Diesel (L/min)</div>,
          dataIndex: 'descarga',
          align: 'center',
          render: (value) => (
            <div style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
              {value.consumoDiesel ? value.consumoDiesel.toFixed(3) : '-'}
            </div>
          ),
        },
        {
          title: <div style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>Km (L)</div>,
          dataIndex: 'descarga',
          align: 'center',
          render: (value) => (
            <div style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
              {value.consumoMedio ? value.consumoMedio.toFixed(3) : '-'}
            </div>
          ),
        },
      ],
    },
    {
      title: 'LAVAGEM',
      children: [
        {
          title: <div style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>Tempo (min)</div>,
          dataIndex: 'lavagem',
          align: 'center',
          render: (value) => (
            <div style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
              {value.tempo ? value.tempo.toFixed(1) : '-'}
            </div>
          ),
        },
        {
          title: <div style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>Diesel (L)</div>,
          dataIndex: 'lavagem',
          align: 'center',
          render: (value) => (
            <div style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
              {value.diesel ? value.diesel.toFixed(1) : '-'}
            </div>
          ),
        },
        {
          title: 'Km',
          dataIndex: 'lavagem',
          align: 'center',
          render: (value) => (
            <div style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
              {value.distancia ? value.distancia.toFixed(1) : '-'}
            </div>
          ),
        },
        {
          title: <div style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>Diesel (L/min)</div>,
          dataIndex: 'lavagem',
          align: 'center',
          render: (value) => (
            <div style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
              {value.consumoDiesel ? value.consumoDiesel.toFixed(3) : '-'}
            </div>
          ),
        },
        {
          title: <div style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>Km (L)</div>,
          dataIndex: 'lavagem',
          align: 'center',
          render: (value) => (
            <div style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
              {value.consumoMedio ? value.consumoMedio.toFixed(3) : '-'}
            </div>
          ),
        },
      ],
    },
    {
      title: 'RETORNO',
      children: [
        {
          title: <div style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>Tempo (min)</div>,
          dataIndex: 'retorno',
          align: 'center',
          render: (value) => (
            <div style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
              {value.tempo ? value.tempo.toFixed(1) : '-'}
            </div>
          ),
        },
        {
          title: <div style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>Diesel (L)</div>,
          dataIndex: 'retorno',
          render: (value) => (
            <div style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
              {value.diesel ? value.diesel.toFixed(1) : '-'}
            </div>
          ),
        },
        {
          title: 'Km',
          dataIndex: 'retorno',
          render: (value) => (
            <div style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
              {value.distancia ? value.distancia.toFixed(1) : '-'}
            </div>
          ),
        },
        {
          title: <div style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>Diesel (L/min)</div>,
          dataIndex: 'retorno',
          align: 'center',
          render: (value) => (
            <div style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
              {value.consumoDiesel ? value.consumoDiesel.toFixed(3) : '-'}
            </div>
          ),
        },
        {
          title: <div style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>Km (L)</div>,
          dataIndex: 'retorno',
          align: 'center',
          render: (value) => (
            <div style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
              {value.consumoMedio ? value.consumoMedio.toFixed(3) : '-'}
            </div>
          ),
        },
      ],
    },
    {
      title: 'TOTAL',
      children: [
        {
          title: <div style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>Tempo (min)</div>,
          dataIndex: 'total',
          align: 'center',
          render: (value) => (
            <div style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
              {value.tempo ? value.tempo.toFixed(1) : '-'}
            </div>
          ),
        },
        {
          title: <div style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>Diesel (L)</div>,
          dataIndex: 'total',
          align: 'center',
          render: (value) => (
            <div style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
              {value.diesel ? value.diesel.toFixed(1) : '-'}
            </div>
          ),
        },
        {
          title: 'Km',
          dataIndex: 'total',
          align: 'center',
          render: (value) => (
            <div style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
              {value.distancia ? value.distancia.toFixed(1) : '-'}
            </div>
          ),
        },
        {
          title: <div style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>Diesel (L/min)</div>,
          dataIndex: 'total',
          align: 'center',
          render: (value) => (
            <div style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
              {value.consumoDiesel ? value.consumoDiesel.toFixed(3) : '-'}
            </div>
          ),
        },
        {
          title: <div style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>Km (L)</div>,
          dataIndex: 'total',
          align: 'center',
          render: (value) => (
            <div style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
              {value.consumoMedio ? value.consumoMedio.toFixed(3) : '-'}
            </div>
          ),
        },
      ],
    },
    {
      title: '',
      key: 'action',
      render: (_, item) => (
        <Button type="link" onClick={() => handleRedirectToDetails(item.idViagem)}>
          <IconDashboard />
        </Button>
      ),
    },
  ];
  const columnsForDriverReport: ColumnsType<ReportDriverDetailedShippingModel> = [
    {
      title: 'Remessa',
      dataIndex: 'idViagem',
      showSorterTooltip: false,
    },
    {
      title: 'Emissão NF',
      dataIndex: 'dataNotaFiscal',
      showSorterTooltip: false,
      render: (dataNotaFiscal: Date) =>
        dataNotaFiscal ? moment(dataNotaFiscal).format('DD/MM/YYYY HH:mm') + 'h' : '-',
    },
    {
      title: 'BT',
      dataIndex: 'betoneira',
      showSorterTooltip: false,
    },
    {
      title: 'Motorista',
      dataIndex: 'motorista',
      showSorterTooltip: false,
    },
    {
      title: 'Obra',
      dataIndex: 'obra',
      showSorterTooltip: false,
    },
    {
      title: 'Volume(m³)',
      dataIndex: 'volume',
      showSorterTooltip: false,
      render: (value: number) => {
        return value !== undefined ? value.toFixed(1) : '-';
      },
    },
    {
      title: 'Água central(L)',
      dataIndex: 'aguaCentral',
      showSorterTooltip: false,
      render: (value: number) => {
        return value !== undefined ? value.toFixed(1) : '-';
      },
    },
    {
      title: 'Água obra(L)',
      dataIndex: 'aguaObra',
      showSorterTooltip: false,
      render: (value: number) => {
        return value !== undefined ? value.toFixed(1) : '-';
      },
    },
    {
      title: 'Tempo central (min)',
      dataIndex: 'tempoCentral',
      showSorterTooltip: false,
    },
    {
      title: '',
      key: 'action',
      render: (_, item) => (
        <Button type="link" onClick={() => handleRedirectToDetails(item.idViagem)}>
          <IconDashboard />
        </Button>
      ),
    },
  ];
  const columnsForTraceReport: ColumnsType<ReportTraceDetailedShippingModel> = [
    {
      title: 'Remessa',
      dataIndex: 'idViagem',
      showSorterTooltip: false,
    },
    {
      title: 'Emissão NF',
      dataIndex: 'dataNotaFiscal',
      showSorterTooltip: false,
      render: (dataNotaFiscal: Date) =>
        dataNotaFiscal ? moment(dataNotaFiscal).format('DD/MM/YYYY HH:mm') + 'h' : '-',
      width: '200px',
    },
    {
      title: 'BT',
      dataIndex: 'betoneira',
      showSorterTooltip: false,
    },
    {
      title: 'Motorista',
      dataIndex: 'motorista',
      showSorterTooltip: false,
      width: '300px',
    },
    {
      title: 'Obra',
      dataIndex: 'obra',
      showSorterTooltip: false,
      width: '300px',
    },
    {
      title: 'Volume(m³)',
      dataIndex: 'volume',
      showSorterTooltip: false,
      render: (value: number) => {
        return value !== undefined ? value.toFixed(1) : '-';
      },
    },
    {
      title: 'Água retida(L)',
      dataIndex: 'aguaRetida',
      showSorterTooltip: false,
      width: '127px',
      render: (value: number) => {
        return value !== undefined ? value.toFixed(1) : '-';
      },
    },
    {
      title: 'Água central(L)',
      dataIndex: 'aguaCentral',
      showSorterTooltip: false,
      width: '132px',
      render: (value: number) => {
        return value !== undefined ? value.toFixed(1) : '-';
      },
    },
    {
      title: 'Água obra(L)',
      dataIndex: 'aguaObra',
      showSorterTooltip: false,
      width: '100px',
      render: (value: number) => {
        return value !== undefined ? value.toFixed(1) : '-';
      },
    },
    {
      title: 'Sobra d’água(L/m³)',
      dataIndex: 'aguaSobra',
      showSorterTooltip: false,
      width: '130px',
      render: (value: number) => {
        return value !== undefined ? value.toFixed(1) : '-';
      },
    },
    {
      title: 'Traço',
      dataIndex: 'traco',
      showSorterTooltip: true,
      width: '400px',
      render: (trace: string) => <span title={trace}>{trace.length > 50 ? `${trace.slice(0, 50)}...` : trace}</span>,
    },
    {
      title: '',
      key: 'action',
      render: (_, item) => (
        <Button type="link" onClick={() => handleRedirectToDetails(item.idViagem)}>
          <IconDashboard />
        </Button>
      ),
    },
  ];
  const getColumns = () => {
    switch (type) {
      case TypeReport.Water:
        return columnsForWaterReport;
      case TypeReport.Time:
        return columnsForTimeReport;
      case TypeReport.Driver:
        return columnsForDriverReport;
      case TypeReport.Trace:
        return columnsForTraceReport;
      default:
        return [];
    }
  };

  const handleRedirectToDetails = (idViagem: number) => {
    window.open(`/viagem/detalhamento/${idViagem}`, '_blank');
    localStorage.setItem('idViagem', idViagem.toString());
  };

  const handleExportToExcel = () => {
    switch (type) {
      case TypeReport.Water:
        let dataExport: ReportWaterUsageDetailedShippingModel[] = [];

        dataExport = (data as ReportWaterUsageDetailedShippingModel[]).map((item) => ({
          ...item,
          aguaCentral: Number(item.aguaCentral?.toFixed(1)),
          aguaRetida: Number(item.aguaRetida?.toFixed(1)),
          aguaObra: Number(item.aguaObra?.toFixed(1)),
          aguaSobra: Number(item.aguaSobra?.toFixed(1)),
        }));

        genericExportToExcel('relatorios_detalhados_uso_agua', getColumns() as ColumnsType, dataExport);
        break;
      case TypeReport.Time:
        exportReportTimeDetailedToExcel(data as ReportTimeDetailedShipmentModel[], getColumns());
        break;
      case TypeReport.Driver:
        const dataDriverExport = (data as ReportDriverDetailedShippingModel[]).map((item) => ({
          ...item,
          aguaCentral: Number(item.aguaCentral?.toFixed(1)),
          aguaObra: Number(item.aguaObra?.toFixed(1)),
        }));

        genericExportToExcel('relatorios_detalhado_motorista', getColumns() as ColumnsType, dataDriverExport);
        break;
      case TypeReport.Trace:
        const dataTraceExport = (data as ReportWaterUsageDetailedShippingModel[]).map((item) => ({
          ...item,
          aguaCentral: Number(item.aguaCentral?.toFixed(1)),
          aguaRetida: Number(item.aguaRetida?.toFixed(1)),
          aguaObra: Number(item.aguaObra?.toFixed(1)),
          aguaSobra: Number(item.aguaSobra?.toFixed(1)),
        }));
        genericExportToExcel('relatorios_detalhado_traco', getColumns() as ColumnsType, dataTraceExport);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <PageTitle>{`Relátorio - ${reportTypeNames[type]}`}</PageTitle>
      <Row align={'middle'} justify={'end'} style={{ width: '100%' }}>
        <Col>
          <Button
            type="link"
            icon={<IconFileExcel />}
            onClick={handleExportToExcel}
            style={{ color: 'var(secondary-color)' }}
          >
            Exportar Excel
          </Button>
        </Col>
      </Row>

      <Table
        hasBorder
        style={{ paddingLeft: '0', paddingRight: '0', width: '100%' }}
        bordered={true}
        columns={getColumns()}
        dataSource={data}
        rowKey="id"
      />
    </>
  );
};
