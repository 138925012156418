import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import MainContent from '../main/MainContent/MainContent';
import * as S from '../main/MainLayout/MainLayout.styles';
import MainSider from '../main/sider/MainSider/MainSider';
import HeaderConcreteMixerLocation from './Header/HeaderConcreteMixerLocation';
import { Spinner } from '@app/components/common/SpinSlump/SpinSlump.styles';
import { useAppSelector } from '@app/hooks/reduxHooks';

const ConcreteMixerLocation: React.FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [siderCollapsed, setSiderCollapsed] = useState(true);

  const loading = useAppSelector((state) => state.concreteMixerLocations.loading);

  return (
    <S.LayoutMaster>
      <MainSider hidden={false} forceCollapse={true} isCollapsed={siderCollapsed} setCollapsed={setSiderCollapsed} />
      <S.LayoutMain collapse={true}>
        <HeaderConcreteMixerLocation />
        <Spinner spinning={loading}>
          <MainContent id="main-content" $isTwoColumnsLayout={false}>
            <div style={{ padding: '1rem' }}>
              <Outlet />
            </div>
          </MainContent>
        </Spinner>
      </S.LayoutMain>
    </S.LayoutMaster>
  );
};

export default ConcreteMixerLocation;
