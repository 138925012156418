import styled from 'styled-components';
import { Typography } from 'antd';
import { media } from '@app/styles/themes/constants';

export const Text = styled(Typography.Title)`
  &.ant-typography {
    font-size: 1rem;
    margin-bottom: 0;
    font-family: Mulish;
    font-weight: 500;
    line-height: 1.25rem;
    text-align: left;

    color: var(--text-main-info-color);

    @media only screen and (${media.md}) {
      font-weight: 500;
      font-size: 0.875rem;
    }

    @media only screen and (${media.xl}) {
      font-weight: 700;
      font-size: 1rem;
    }
  }
`;
