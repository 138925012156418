/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { Table } from '@app/components/common/Table/Table';
import { ClientModel } from '@app/domain/interfaces/clientModel';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { useMounted } from '@app/hooks/useMounted';
import IClientService, { ClientService } from '@app/services/clientService';
import { setHeader } from '@app/store/slices/headerSlice';
import { ColumnsType } from 'antd/lib/table';
import React, { useCallback, useEffect, useState } from 'react';
import DropdownTable from '@app/components/dropdown/DropdownTable';
import { Menu, MenuItem } from '@app/components/common/Menu/Menu';
import { useNavigate } from 'react-router-dom';
import { Spinner } from '@app/components/common/Spinner/Spinner';
import { hasAccessByPermission } from '@app/controllers/accessController';
import { IconMore } from '@app/assets/slump-icons';
import Dashboard from '@app/components/dashboard/Dashboard';
import { Button } from '@app/components/common/buttons/Button/Button';
import { genericExportToExcel } from '@app/utils/exportToExcel';

const clientService: IClientService = new ClientService();

const ClientDashboard: React.FC = () => {
  const { isMounted } = useMounted();
  const navigate = useNavigate();
  const [tableData, setTableData] = useState<{ data: ClientModel[]; loading: boolean }>({
    data: [],
    loading: true,
  });

  const [client, setClient] = useState<ClientModel>({
    cnpj: '',
    razaoSocial: '',
    complemento: '',
  });
  const [cepInitialEditState, setCepInitialEditState] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [editClient, setEditClient] = useState(false);
  const [clients, setClients] = useState<ClientModel[]>(); //undefined
  const [searchClientFiltered, setSearchClientFiltered] = useState<ClientModel[]>(); //olhar o filtro

  const dispatch = useAppDispatch();

  const columns: ColumnsType<ClientModel> = [
    {
      title: 'Cód.',
      dataIndex: 'id',
      showSorterTooltip: false,
      width: '10%',
      sorter: (a: ClientModel, b: ClientModel) => (a?.id ? a.id : 0) - (b?.id ? b.id : 0),
      defaultSortOrder: 'descend',
      ellipsis: true,
    },
    {
      title: 'Razão Social',
      dataIndex: 'razaoSocial',
      showSorterTooltip: false,
      width: '20%',
      sorter: (a, b) => (a.razaoSocial && b.razaoSocial ? a.razaoSocial.localeCompare(b.razaoSocial) : 0),
      ellipsis: true,
    },
    {
      title: 'CNPJ',
      dataIndex: 'cnpj',
      showSorterTooltip: false,
      width: '20%',
      sorter: (a, b) => (a.cnpj && b.cnpj ? a.cnpj.localeCompare(b.cnpj) : 0),
      ellipsis: true,
    },
    {
      title: 'Endereço',
      dataIndex: 'logradouro',
      showSorterTooltip: false,
      width: '50%',
      render: (logradouro, row) => {
        return (
          <span>{`${row.logradouro}, ${row.numero}, ${row.complemento}. ${row.bairro}, ${row.cidade}-${row.estado}. ${row.cep}`}</span>
        );
      },
      sorter: (a, b) => (a.logradouro && b.logradouro ? a.logradouro.localeCompare(b.logradouro) : 0),
      ellipsis: true,
    },
    {
      title: '',
      dataIndex: 'id',
      width: '10%',
      render: (_, client) => {
        const canEdit = hasAccessByPermission('client', 'U');
        return (
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end' }}>
            {canEdit && (
              <DropdownTable
                buttonText=""
                iconD={
                  <div style={{ color: 'var(--text-label)' }}>
                    <IconMore />
                  </div>
                }
                trigger={['click']}
                placement="bottomRight"
                overlay={
                  <Menu>
                    <MenuItem onClick={() => handleEditClick(client)}>
                      <Button type="text">Editar</Button>
                    </MenuItem>
                  </Menu>
                }
              ></DropdownTable>
            )}
          </div>
        );
      },
    },
  ];

  const handleEditClick = (client: ClientModel) => {
    if (client.id !== null && client.id !== undefined) {
      setCepInitialEditState(true);
      navigate(`/gestao/concreteiras/editarCliente/${client.id}`);
      setEditClient(true);
      fetchClientsUpdate();
    }
  };

  const handleSearchClientFilter = (event: React.FormEvent<HTMLInputElement>) => {
    if (event.currentTarget.value.length > 0) {
      const inputValue = event.currentTarget.value.toLowerCase();

      setSearchClientFiltered(
        clients?.filter(
          (item) =>
            item.cnpj
              ?.toLowerCase()
              .replaceAll('.', '')
              .replace('/', '')
              .includes(
                inputValue
                  .replaceAll('.', '')
                  .replace('/', '')
                  .replace(/^\s+|\s+$/g, ''),
              ) || item.razaoSocial?.toLowerCase().includes(inputValue.replace(/^\s+|\s+$/g, '')),
        ),
      );
    } else {
      setSearchClientFiltered(clients);
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    navigate('/gestao/concreteiras/cadastrarCliente');
  };

  const handleExportToExcel = () => {
    genericExportToExcel('Concreteiras', columns, searchClientFiltered ?? []);
  };

  const fetchClientsUpdate = () => {
    clientService.getArray('obter').then((res) => {
      setTableData({ data: res, loading: false });
    });
  };

  const fetchClients = useCallback(() => {
    clientService.getArray('obter').then((res) => {
      if (isMounted.current) {
        setTableData({ data: res, loading: false });
      }
    });
  }, [isMounted]);

  const fetchData = useCallback(async () => {
    setLoading(true);

    const resClients = await clientService.getArray('obter');

    setClients(resClients);
    setSearchClientFiltered(resClients);

    setLoading(false);
  }, [isMounted]);

  useEffect(() => {
    fetchData();
  }, [fetchClients]);

  useEffect(() => {
    if (isMounted.current) {
      dispatch(
        setHeader({
          title: 'Concreteiras',
        }),
      );
    }
  }, [searchClientFiltered]);

  return (
    <>
      <PageTitle>Concreteiras</PageTitle>
      <Spinner spinning={loading}>
        <Dashboard
          title={`Concreteiras   ${client?.razaoSocial ? `- ${client?.razaoSocial}` : ''}`}
          buttonText="Cadastrar nova concreteira"
          handleButtonClick={handleClick}
          table={<Table bordered={true} columns={columns} dataSource={searchClientFiltered} loading={loading} />}
          placeholderTextSearch="Pesquisar por CNPJ ou Razão Social"
          handleSearchOnChange={handleSearchClientFilter}
          exportToExcel={handleExportToExcel}
        />
      </Spinner>
    </>
  );
};

export default ClientDashboard;
