import IReportService, { ReportService } from '@app/services/reportService';
import { readUser } from '@app/services/localStorage.service';
import { setHeader } from '@app/store/slices/headerSlice';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ViewFiltersHeader } from '@app/components/common/ViewFiltersHeader';
import { PageContainer } from '@app/components/common/PageContainer/PageContainer';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import moment from 'moment';
import { BreakdownTable } from './components/BreakdownTable';
import { TypeReport } from '@app/constants/enums/report';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { Spinner } from '@app/components/common/Spinner/Spinner';
import { notificationController } from '@app/controllers/notificationController';
import { ReportWaterUsageDetailedShippingModel } from '@app/domain/interfaces/report/reportWaterDetailModel';
import IClientBranchService, { ClientBranchService } from '@app/services/clientBranchService';
import { ReportTimeDetailedShipmentModel } from '@app/domain/interfaces/report/reportTimeModel';
import { ReportDriverDetailedShippingModel } from '@app/domain/interfaces/report/reportDriverDetailModel';
import { ReportTraceDetailedShippingModel } from '@app/domain/interfaces/report/reporTraceDetailModel';

const reportService: IReportService = new ReportService();
const branchService: IClientBranchService = new ClientBranchService();

interface FilterHeader {
  codigosFilialSiac: string;
  motorista: string;
  motoristaMatricula: string | null;
  traco: string;
  dataHoraFim: string;
  dataHoraInicio: string;
  numeroViagem: number;
  tiposDado: string;
  nomeGrupo: string;
  numeroMinViagens?: string | null;
}

export const ReportDetails: React.FC = () => {
  const { tipo } = useParams();
  const [filters, setFilter] = useState<FilterHeader>();
  const [numberTrips, setNumberTrips] = useState<number>();
  const [period, setPeriod] = useState<string>('-');
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<
    | ReportWaterUsageDetailedShippingModel[]
    | ReportTimeDetailedShipmentModel[]
    | ReportDriverDetailedShippingModel[]
    | ReportTraceDetailedShippingModel[]
  >([]);
  const [branches, setBranches] = useState<string>();

  const handleSetPeriod = (start: string, end: string) => {
    if (start === moment().subtract(7, 'days').format('DD-MM-YYYY') && end === moment().format('DD-MM-YYYY')) {
      setPeriod('Últimos 7 dias');
    } else if (
      start === moment().startOf('month').format('DD-MM-YYYY') &&
      end === moment().endOf('month').format('DD-MM-YYYY')
    ) {
      setPeriod('Mês atual');
    } else if (
      start === moment().subtract(1, 'month').startOf('month').format('DD-MM-YYYY') &&
      end === moment().subtract(1, 'month').endOf('month').format('DD-MM-YYYY')
    ) {
      setPeriod('Mês passado');
    } else {
      setPeriod('Personalizado');
    }
  };
  const handleInit = () => {
    const urlParams = new URLSearchParams(window.location.search);

    // Obtém os valores dos parâmetros
    const dataInicio = urlParams.get('dataHoraInicio');
    const dataFim = urlParams.get('dataHoraFim');

    const filtersJson: FilterHeader = {
      codigosFilialSiac: urlParams.get('codigosFilialSiac') ?? '-',
      motorista: urlParams.get('motorista') ?? '-',
      motoristaMatricula: urlParams.get('valorClicado'),
      traco: urlParams.get('valorClicado') ?? '-',
      dataHoraFim: moment(dataFim, 'DD-MM-yyyy').format('DD-MM-yyyy') ?? '-',
      dataHoraInicio: moment(dataInicio, 'DD-MM-yyyy').format('DD-MM-yyyy') ?? '',
      numeroViagem: 0,
      tiposDado: urlParams.get('tipoDado')?.split(',').join(', ') ?? '-',
      nomeGrupo: urlParams.get('nomeGrupo') ?? '-',
      numeroMinViagens: urlParams.get('numeroMinViagens'),
    };

    handleSetPeriod(filtersJson.dataHoraInicio, filtersJson.dataHoraFim);
    setFilter(filtersJson);
    fetchBranches(filtersJson);
    fetchReportDetails(filtersJson);
  };

  const fetchReportDetails = async (filter: FilterHeader) => {
    setLoading(true);

    const codeBranch = filter?.codigosFilialSiac.split(',').map((code) => Number(code));
    const start = moment(filter?.dataHoraInicio, 'DD-MM-yyyy').toDate();
    const end = moment(filter?.dataHoraFim, 'DD-MM-yyyy').toDate();

    switch (Number(tipo)) {
      case TypeReport.Water:
        reportService
          .getReportWaterDetailed({ dataHoraInicio: start, dataHoraFim: end, codigosFilialSiac: codeBranch ?? [] })
          .then((res) => {
            setNumberTrips(res.quantidadeViagens);
            setData(res.remessas ?? []);
            setLoading(false);
          })
          .catch(() => {
            notificationController.error({ message: 'Não encontrado dados para o filtro informado' });
            setLoading(false);
          });
        break;
      case TypeReport.Time:
        reportService
          .getReportTimeDetailed({ dataHoraInicio: start, dataHoraFim: end, codigosFilialSiac: codeBranch ?? [] })
          .then((res) => {
            setNumberTrips(res.quantidadeViagens);
            setData(res.remessas ?? []);
            setLoading(false);
          })
          .catch(() => {
            notificationController.error({ message: 'Não encontrado dados para o filtro informado' });
            setLoading(false);
          });
        break;
      case TypeReport.Driver:
        const matricula = Number(filter.motoristaMatricula ?? 0);

        if (!matricula) {
          notificationController.error({ description: 'Erro!', message: 'Matricula do motorista é obrigatória' });
          setLoading(false);
        }

        reportService
          .getReportDriverDetailed({
            dataHoraInicio: start,
            dataHoraFim: end,
            codigosFilialSiac: codeBranch ?? [],
            motoristaMatricula: matricula,
          })
          .then((res) => {
            setNumberTrips(res.quantidadeViagens);
            setData(res.remessas ?? []);
            setLoading(false);
          })
          .catch(() => {
            notificationController.error({ message: 'Não encontrado dados para o filtro informado' });
            setLoading(false);
          });

        break;
      case TypeReport.Trace:
        if (!filter.traco) {
          notificationController.error({ description: 'Erro!', message: 'Traço é obrigatório' });
          setLoading(false);
        }

        reportService
          .getReportTraceDetailed({
            dataHoraInicio: start,
            dataHoraFim: end,
            codigosFilialSiac: codeBranch ?? [],
            traco: filter.traco,
          })
          .then((res) => {
            setNumberTrips(res.quantidadeViagens);
            setData(res.remessas ?? []);
            setLoading(false);
          })
          .catch(() => {
            notificationController.error({ message: 'Não encontrado dados para o filtro informado' });
            setLoading(false);
          });
        break;
      default:
        break;
    }
  };
  const fetchBranches = useCallback((filter: FilterHeader) => {
    branchService
      .getArray('')
      .then((res) => {
        let newBranch = '';
        const branchCodes = filter?.codigosFilialSiac
          .toString()
          .split(',')
          .map((c) => Number(c));
        res
          .filter((branch) => branchCodes?.includes(branch.codigoFilialSiac))
          .map((branch) => {
            if (!newBranch) newBranch = branch.nome;
            else newBranch = `${newBranch}, ${branch.nome}`;
          });

        setBranches(newBranch);
      })
      .catch(() => notificationController.error({ message: 'Erro ao buscar as filiais.' }));
  }, []);

  useEffect(() => {
    dispatch(
      setHeader({
        title: 'Relatórios',
      }),
    );
    handleInit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <PageTitle>Relatórios</PageTitle>
      <PageContainer>
        <Spinner spinning={loading}>
          <ViewFiltersHeader
            dataType={filters?.tiposDado ?? ' - '}
            numeroMinViagens={filters?.numeroMinViagens}
            period={
              moment(filters?.dataHoraInicio, 'DD-MM-YYYY').format('DD/MM/YYYY') +
              ' - ' +
              moment(filters?.dataHoraFim, 'DD-MM-YYYY').format('DD/MM/YYYY')
            }
            periodType={period}
            regional={filters?.nomeGrupo ?? ' - '}
            branch={branches ?? ' - '}
            numberoftrips={numberTrips ?? 0}
            withdrawnDate={moment().format('DD-MM-YYYY')}
            userName={readUser()?.username ?? '-'}
          />
          <BreakdownTable data={data} type={Number(tipo)} />
        </Spinner>
      </PageContainer>
    </>
  );
};
