import { ReactComponent as Logo } from '@app/assets/images/Logo-Azul-Vertical.svg';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { BaseFormInputItem } from '@app/components/common/forms/components/BaseFormInputItem/BaseFormInputItem';
import { InputPassword } from '@app/components/common/inputs/InputPassword/InputPassword';
import * as Auth from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import { notificationController } from '@app/controllers/notificationController';
import IAuthService, { AuthService } from '@app/services/authService';
import { Space } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

interface NewPasswordFormData {
  password: string;
  confirmPassword: string;
}

const initStates = {
  password: 'new-password',
  confirmPassword: 'new-password',
};

const authService: IAuthService = new AuthService();

export const NewPasswordForm: React.FC = () => {
  const navigate = useNavigate();
  const { token } = useParams();
  const [isLoading, setLoading] = useState(false);
  const [passErrorText, setPassErrorText] = useState('');
  const [validPass, setValidPass] = useState<boolean | undefined>();
  const [validConfirmPass, setValidConfirmPass] = useState<boolean | undefined>();
  const [newPassModel, setNewPassModel] = useState<NewPasswordFormData>({} as NewPasswordFormData);

  const handleSubmit = () => {
    setLoading(true);

    if (!token) {
      notificationController.error({ message: 'Não é possível completar a solicitação' });
      setLoading(false);
      return;
    }

    authService
      .setNewPassword({
        token: token,
        senha: newPassModel.password,
        confirmaSenha: newPassModel.confirmPassword,
      })
      .then(() => {
        navigate('/auth/login');
        notificationController.success({
          message: 'Nova senha criada',
        });
      })
      .catch((error) => {
        notificationController.error(error);
        setLoading(false);
      });
  };

  const validatePassword = (pass: string): boolean => {
    if (pass?.length < 6) {
      setValidPass(false);
      setPassErrorText('Senha pequena');
      return false;
    }

    if (!/(?=.*[A-Z]).*$/.test(pass)) {
      setValidPass(false);
      setPassErrorText('Senha deve conter letras maiúsculas');
      return false;
    }

    if (!/(?=.*[a-z]).*$/.test(pass)) {
      setValidPass(false);
      setPassErrorText('Senha deve conter letras minúsculas');
      return false;
    }

    if (!/(?=.*[0-9]).*$/.test(pass)) {
      setValidPass(false);
      setPassErrorText('Senha deve conter números');
      return false;
    }

    if (!/\W/.test(pass)) {
      setValidPass(false);
      setPassErrorText('Senha deve conter caracteres especiais');
      return false;
    }

    setValidPass(undefined);
    return true;
  };

  useEffect(() => {
    if (newPassModel.password) {
      validatePassword(newPassModel.password);
    }
  }, [newPassModel.password]);

  useEffect(() => {
    if (newPassModel.password != newPassModel.confirmPassword) {
      setValidConfirmPass(false);
    } else {
      setValidConfirmPass(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newPassModel.confirmPassword]);

  return (
    <Auth.FormWrapper>
      <Logo style={{ marginBottom: '2rem' }} />
      <BaseForm
        layout="vertical"
        onFinish={handleSubmit}
        requiredMark="optional"
        initialValues={initStates}
        style={{ width: '398px' }}
      >
        <Auth.FormTitle>Crie sua nova senha</Auth.FormTitle>
        <BaseFormInputItem
          label="Nova senha"
          name="password"
          supportText="Ex: mínimo de 6 caracteres com letras e números."
          isSuccess={validPass}
          errorText={passErrorText}
        >
          <InputPassword
            placeholder="Digite sua senha"
            type="password"
            onChange={(e) => setNewPassModel({ ...newPassModel, password: e.currentTarget.value })}
          />
        </BaseFormInputItem>
        <BaseFormInputItem
          label="Confirme sua nova senha"
          name="confirmPassword"
          isSuccess={validConfirmPass}
          supportText="Ex: mínimo de 6 caracteres com letras e números."
          errorText="Senhas diferentes"
        >
          <InputPassword
            placeholder="Digite sua senha"
            type="password"
            onChange={(e) => setNewPassModel({ ...newPassModel, confirmPassword: e.currentTarget.value })}
          />
        </BaseFormInputItem>
        <BaseForm.Item noStyle>
          <Space direction="vertical" style={{ width: '100%' }}>
            <Auth.SubmitButton type="primary" htmlType="submit" loading={isLoading}>
              Criar senha
            </Auth.SubmitButton>
          </Space>
        </BaseForm.Item>
      </BaseForm>
    </Auth.FormWrapper>
  );
};
