import styled, { css } from 'styled-components';
import { Col, Menu, Progress as AntdProgress } from 'antd';
import { LAYOUT, media } from '@app/styles/themes/constants';

export const Column = styled(Col)`
  padding: ${LAYOUT.desktop.paddingVertical} ${LAYOUT.desktop.paddingHorizontal};
`;

interface ConfigColumn {
  $responsiveColumnsLayout: boolean;
}

export const ConfigColumn = styled(Col)<ConfigColumn>`
  @media only screen and ${media.md} {
    ${(props) =>
      props?.$responsiveColumnsLayout &&
      css`
        background-color: var(--sider-background-color);
        padding: ${LAYOUT.desktop.paddingVertical} ${LAYOUT.desktop.paddingHorizontal};
      `}
  }
`;

export const DropdownHeader = styled(Menu)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 1.25rem;

  @media only screen and ${media.md} {
    align-items: center;
    flex-wrap: wrap;
    word-wrap: break-word;
  }

  & .ant-btn-text {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

// Container to position the text over the progress bar
export const ProgressContainer = styled.div`
  position: relative;
  width: fit-content;
`;

// Styled version of AntdProgress
export const Progress = styled(AntdProgress)`
  .ant-progress-inner {
    background-color: #159d3b; // Example style
  }
`;

interface ProgressInnerTextProps {
  color: string;
}

export const ProgressInnerText = styled.div<ProgressInnerTextProps>`
  position: absolute;
  width: 100%;
  top: 2px;
  left: 0;
  padding-right: 8px;
  font-size: 15px;
  text-align: end;
  ${(props) => css`
    color: ${props.color};
  `}
`;

// export const Progress = styled(AntdProgress)`
//   background-color: blue;
//   //padding: 2rem;
//
//   .ant-progress-inner {
//     // put the ProgressInnerText component as a child of this component
//     background-color: #159d3b;
//   }
// `;
//
// export const ProgressInnerText = styled.div`
//   position: absolute;
//   display: inline-block;
//   text-align: right;
//   width: 100%;
// `;
