/* eslint-disable @typescript-eslint/no-unused-vars */
import IconMore from '@app/assets/slump-icons/IconMore';
import { Menu, MenuItem } from '@app/components/common/Menu/Menu';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { Spinner } from '@app/components/common/Spinner/Spinner';
import { Table } from '@app/components/common/Table/Table';
import { Button } from '@app/components/common/buttons/Button/Button.styles';
import Dashboard from '@app/components/dashboard/Dashboard';
import DropdownTable from '@app/components/dropdown/DropdownTable';
import { hasAccessByPermission } from '@app/controllers/accessController';
import { notificationController } from '@app/controllers/notificationController';
import { ConfigAlertModel, Justificativa } from '@app/domain/interfaces/configAlertModel';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { useMounted } from '@app/hooks/useMounted';
import IConfigAlertService, { ConfigAlertService } from '@app/services/configAlertService';
import { readUserFilter } from '@app/services/localStorage.service';
import { setHeader } from '@app/store/slices/headerSlice';
import { genericExportToExcel } from '@app/utils/exportToExcel';
import { Badge } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const configAlertService: IConfigAlertService = new ConfigAlertService();

const ConfigAlertDashboard: React.FC = () => {
  const { isMounted } = useMounted();

  const dispatch = useAppDispatch();
  const { id } = useParams();

  const [justificar, setJustificar] = useState<boolean>(false);
  const [justificativasTag, setJustificativasTag] = useState<Justificativa[]>([]);
  const [explicar, setExplicar] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [editConfigAlert, setEditConfigAlert] = useState(false);
  const [continuarButtonDisabled, setContinuarButtonDisabled] = useState(true);
  const [searchValue, setSearchValue] = useState<string>('');
  const [searchConfigAlertFiltered, setSearchConfigAlertFiltered] = useState<ConfigAlertModel[]>(); //olhar o filtro
  const [configAlerts, setConfigAlerts] = useState<ConfigAlertModel[]>(); //undefined

  const navigate = useNavigate();

  const [configAlert, setConfigAlert] = useState<ConfigAlertModel>({
    titulo: '',
    categoria: '',
    descricao: '',
    justificativas: [],
  });

  const [tableData, setTableData] = useState<{ data: ConfigAlertModel[]; loading: boolean }>({
    data: [],
    loading: true,
  });

  const columns: ColumnsType<ConfigAlertModel> = [
    {
      title: 'Chave',
      dataIndex: 'chaveAlerta',
      showSorterTooltip: false,
      sorter: (a, b) => (a.chaveAlerta && b.chaveAlerta ? a.chaveAlerta - b.chaveAlerta : 0),
      ellipsis: true,
    },
    {
      title: 'Categoria',
      dataIndex: 'categoria',
      showSorterTooltip: false,
      sorter: (a, b) => (a.categoria && b.categoria ? a.categoria.localeCompare(b.categoria) : 0),
      ellipsis: true,
    },
    {
      title: 'Título',
      dataIndex: 'titulo',
      showSorterTooltip: false,
      sorter: (a, b) => (a.titulo && b.titulo ? a.titulo.localeCompare(b.titulo) : 0),
      ellipsis: true,
    },
    {
      title: 'Descrição',
      dataIndex: 'descricao',
      showSorterTooltip: false,
      render: (descricao: string) => {
        return descricao ? descricao : '-';
      },
      ellipsis: true,
    },
    {
      title: 'Campo motivo',
      dataIndex: 'justificar',
      showSorterTooltip: false,
      render: (justificar: number) => {
        return justificar ? <Badge color="green" text="Sim" /> : <Badge color="red" text="Não" />;
      },
    },
    {
      title: 'Campo explicação',
      dataIndex: 'explicar',
      showSorterTooltip: false,
      render: (explicar: number) => {
        return explicar ? <Badge color="green" text="Sim" /> : <Badge color="red" text="Não" />;
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      showSorterTooltip: false,
      render: (status: number) => {
        return status == 0 ? <Badge color="green" text="Ativo" /> : <Badge color="red" text="Inativo" />;
      },
    },
    {
      title: '',
      dataIndex: 'id',
      width: '5%',
      render: (chaveAlerta, row) => {
        const canEdit = hasAccessByPermission('config_alert', 'U');
        return (
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end' }}>
            {canEdit && (
              <DropdownTable
                buttonText=""
                iconD={
                  <div style={{ color: '#545454' }}>
                    <IconMore />
                  </div>
                }
                trigger={['click']}
                placement="bottomRight"
                overlay={
                  <Menu>
                    <MenuItem key={0}>
                      <Button type="text" onClick={() => handleEditConfigAlertClick(row, chaveAlerta)}>
                        Editar
                      </Button>
                    </MenuItem>
                  </Menu>
                }
              ></DropdownTable>
            )}
          </div>
        );
      },
    },
  ];

  const handleEditConfigAlertClick = (configAlert: ConfigAlertModel, chaveAlerta: number) => {
    const configAlerta = searchConfigAlerta(chaveAlerta);

    sessionStorage.setItem('chaveAlerta', chaveAlerta.toString());
    navigate(`/configuracao/alertas/editarAlerta/${configAlert.chaveAlerta}`);
    // setJustificar(configAlerta.justificar === undefined ? false : configAlerta.justificar);
    // setExplicar(configAlerta.explicar === undefined ? false : configAlerta.explicar);
    // setJustificativasTag(configAlerta.justificativas === undefined ? [] : configAlerta.justificativas);
  };

  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault();
    navigate(`/configuracao/alertas/cadastrarAlerta`);
  };

  const handleExportToExcel = () => {
    genericExportToExcel(
      'Alertas',
      columns,
      searchConfigAlertFiltered?.map((ca) => ({
        ...ca,
        justificar: ca.justificar ? 'Sim' : 'Não',
        explicar: ca.explicar ? 'Sim' : 'Não',
        status: ca.status ? 'Ativo' : 'Inativo',
      })) ?? [],
    );
  };

  const searchConfigAlerta = (chaveAlerta: number) => {
    return tableData.data.find((t) => t.chaveAlerta === chaveAlerta);
  };

  const handleSearchConfigAlertFilter = (event: React.FormEvent<HTMLInputElement>) => {
    if (event.currentTarget.value.length > 0) {
      const inputValue = event.currentTarget.value.toLowerCase();
      setSearchConfigAlertFiltered(
        configAlerts?.filter(
          (item) =>
            item.chaveAlerta?.toString().toLowerCase().includes(inputValue) ||
            item.titulo?.toLowerCase().includes(inputValue),
        ),
      );
    } else {
      setSearchConfigAlertFiltered(configAlerts);
    }
  };

  const fetchConfigAlert = useCallback(
    (id: number) => {
      setLoading(true);
      configAlertService
        .getArray(`obter`)
        .then((res: ConfigAlertModel[]) => {
          if (isMounted.current) {
            setConfigAlerts(res);
            setSearchConfigAlertFiltered(res);
            setLoading(false);
          }
        })
        .catch((e) => {
          setLoading(false);
          notificationController.error({ message: 'Erro ao obter alertas.' });
        });
    },
    [isMounted],
  );

  useEffect(() => {
    const filter = readUserFilter();

    if (filter.client?.id) {
      fetchConfigAlert(filter.client.id);
      setConfigAlert(filter.client);
    }

    dispatch(
      setHeader({
        title: 'Alertas',
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <PageTitle>Cadastro de Alertas</PageTitle>
      <Spinner spinning={loading}>
        {hasAccessByPermission('configAlert', 'U') ? (
          <Dashboard
            title={`Alertas ${
              readUserFilter()?.client?.razaoSocial ? `- ${readUserFilter()?.client?.razaoSocial}` : ''
            }`}
            buttonText="Cadastrar novo alerta"
            handleButtonClick={handleClick}
            table={<Table bordered={true} columns={columns} dataSource={searchConfigAlertFiltered} />}
            placeholderTextSearch="Pesquisar por chave ou título"
            handleSearchOnChange={handleSearchConfigAlertFilter}
            exportToExcel={handleExportToExcel}
          />
        ) : (
          <Dashboard
            title=""
            buttonText="Cadastrar nova concreteira"
            handleButtonClick={handleClick}
            table={<Table bordered={true} columns={columns} dataSource={searchConfigAlertFiltered} />}
            placeholderTextSearch="Pesquisar por chave ou título"
            handleSearchOnChange={handleSearchConfigAlertFilter}
            exportToExcel={handleExportToExcel}
          />
        )}
      </Spinner>
    </>
  );
};

export default ConfigAlertDashboard;
