import { ConstructionModel } from '@app/domain/interfaces/report/reportConcreteMixerModel';
import React, { useEffect, useState } from 'react';
import { notificationController } from '@app/controllers/notificationController';
import IReportService, { ReportService } from '@app/services/reportService';
import { Spinner } from '@app/components/common/Spinner/Spinner';
import MapConcreteMixer from '@app/components/common/Map/ConcreteMixerMap/MapConcreteMixer';
import { Col, ModalProps, Row } from 'antd';
import { Modal } from '@app/components/common/Modal/Modal';
import { ConcreteMixerReturning } from '@app/domain/interfaces/monitoring/footerMonitoringModels';
import IDeviceService, { DeviceService } from '@app/services/deviceService';

interface IModalMapConcreteMixers extends ModalProps {
  mixerSelected: ConcreteMixerReturning;
}

const reportService: IReportService = new ReportService();
const deviceService: IDeviceService = new DeviceService();

export const ModalMapConcreteMixersLocation: React.FC<IModalMapConcreteMixers> = ({ mixerSelected, ...props }) => {
  const [construction, setConstruction] = useState<ConstructionModel>();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (mixerSelected?.deviceId) {
      setLoading(true);

      reportService
        .getMixerReportByDeviceMonitoring(mixerSelected.deviceId)
        .then(async (res) => {
          if (!res?.obras?.[0].id) {
            const deviceConectado = await (await deviceService.get(`/obter/${mixerSelected.deviceId}`)).connectionState;
            setConstruction({
              remessas: [
                {
                  ...res?.obras?.[0].remessas?.[0],
                  betoneira: mixerSelected.betoneira,
                  conectado: deviceConectado?.toLocaleLowerCase() == 'connected',
                  betoneiraLocalizacao: res?.obras?.[0].remessas?.[0].betoneiraLocalizacao,
                },
              ],
            });

            notificationController.info({
              message: 'Betoneira não está em viagem no momento.',
            });
            setLoading(false);
            return;
          }

          setConstruction(res?.obras?.[0]);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
          setConstruction({});
          notificationController.error({
            message: 'Houve um erro ao buscar dados da betoneira.',
          });
        });

      return;
    }
  }, [mixerSelected]);

  return (
    <Modal
      title="Localização da betoneira"
      bodyStyle={{ overflowY: 'auto' }}
      width={'80%'}
      style={{ top: '10px' }}
      footer={null}
      {...props}
    >
      <Spinner spinning={loading}>
        <Row style={{ marginTop: '1rem', marginBottom: '1rem' }}>
          <Col xs={24}>
            <MapConcreteMixer construction={construction} showFooter={false}></MapConcreteMixer>
          </Col>
        </Row>
      </Spinner>
    </Modal>
  );
};
