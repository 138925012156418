import * as React from 'react';
const SvgIconAlert = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.45561 19.6055C3.45561 20.0796 3.83865 20.4626 4.31275 20.4626H19.6878C20.1619 20.4626 20.5449 20.0796 20.5449 19.6055V13.3644C20.5449 8.64476 16.7199 4.81976 12.0003 4.81976C7.28061 4.81976 3.45561 8.64476 3.45561 13.3644V19.6055ZM5.38418 13.3644C5.38418 9.71083 8.34668 6.74833 12.0003 6.74833C15.6538 6.74833 18.6163 9.71083 18.6163 13.3644V18.534H9.1074V13.9537C9.1074 13.8064 8.98686 13.6858 8.83954 13.6858H7.66097C7.51365 13.6858 7.39311 13.8064 7.39311 13.9537V18.534H5.38418V13.3644ZM4.09579 6.60101L5.1565 5.54029C5.23954 5.45725 5.23954 5.32065 5.1565 5.23761L3.33775 3.41886C3.29748 3.37898 3.24309 3.35661 3.18641 3.35661C3.12974 3.35661 3.07535 3.37898 3.03507 3.41886L1.97436 4.47958C1.93448 4.51985 1.91211 4.57424 1.91211 4.63092C1.91211 4.68759 1.93448 4.74198 1.97436 4.78226L3.79311 6.60101C3.87615 6.68404 4.01007 6.68404 4.09579 6.60101ZM22.0315 4.47958L20.9708 3.41886C20.9305 3.37898 20.8761 3.35661 20.8195 3.35661C20.7628 3.35661 20.7084 3.37898 20.6681 3.41886L18.8494 5.23761C18.8095 5.27789 18.7871 5.33227 18.7871 5.38895C18.7871 5.44563 18.8095 5.50002 18.8494 5.54029L19.9101 6.60101C19.9931 6.68404 20.1297 6.68404 20.2128 6.60101L22.0315 4.78226C22.1145 4.69654 22.1145 4.56261 22.0315 4.47958ZM20.5717 22.1769H3.42882C2.95472 22.1769 2.57168 22.5599 2.57168 23.034V23.6769C2.57168 23.7948 2.66811 23.8912 2.78597 23.8912H21.2145C21.3324 23.8912 21.4288 23.7948 21.4288 23.6769V23.034C21.4288 22.5599 21.0458 22.1769 20.5717 22.1769ZM11.2503 3.10547H12.7503C12.8681 3.10547 12.9645 3.00904 12.9645 2.89118V0.319754C12.9645 0.201897 12.8681 0.105469 12.7503 0.105469H11.2503C11.1324 0.105469 11.036 0.201897 11.036 0.319754V2.89118C11.036 3.00904 11.1324 3.10547 11.2503 3.10547Z"
      fill="current"
    />
  </svg>
);
export default SvgIconAlert;
