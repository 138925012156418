import IProfileService, { ProfileService } from '@app/services/profileService';
import { ProfileModel } from '@app/domain/interfaces/profile/profileModel';
import { ColumnsType } from 'antd/lib/table';
import { Badge } from '@app/components/common/Badge/Badge';
import { Spinner } from '@app/components/common/Spinner/Spinner';
import Dashboard from '@app/components/dashboard/Dashboard';
import { Table } from '@app/components/common/Table/Table';
import { useEffect, useState } from 'react';
import { hasAccessByPermission } from '@app/controllers/accessController';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { useNavigate } from 'react-router-dom';
import { notificationController } from '@app/controllers/notificationController';
import DropdownTable from '@app/components/dropdown/DropdownTable';
import { IconMore } from '@app/assets/slump-icons';
import { Menu, MenuItem } from '@app/components/common/Menu/Menu';
import { Button } from '@app/components/common/buttons/Button/Button';
import { SpinnerSlump } from '@app/components/common/SpinSlump/SpinSlump';
import { Modal } from '@app/components/common/Modal/Modal';
import { setHeader } from '@app/store/slices/headerSlice';
import { readUserFilter } from '@app/services/localStorage.service';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { genericExportToExcel } from '@app/utils/exportToExcel';

enum ConfirmDeleteStepEnum {
  ValidateUser = 0,
  ConfirmDelete = 1,
}
const profileService: IProfileService = new ProfileService();

const ProfileDashboard: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [modalLoading, setModalLoading] = useState<boolean>(false);
  const [modalDeleteVisible, setModalDeleteVisible] = useState<boolean>(false);
  const [modalConfirmDeleteVisible, setModalConfirmDeleteVisible] = useState(false);
  const [profiles, setProfiles] = useState<ProfileModel[]>([]);
  const [profilesFiltered, setProfilesFiltered] = useState<ProfileModel[]>([]);
  const [profile, setProfile] = useState<ProfileModel>({} as ProfileModel);

  const handleNewProfileClick = () => navigate('/gestao/perfil');
  const handleEditClick = (id: number) => navigate(`/gestao/perfil/${id}`);
  const handleViewClick = (id: number) => navigate(`/gestao/perfil/${id}/visualizar`);
  const handleDeleteClick = (profile: ProfileModel) => {
    setProfile(profile);

    if (profile.isDeleted || !profile.idIdentityServer) {
      setModalConfirmDeleteVisible(true);
    } else {
      setModalDeleteVisible(true);
    }
  };
  const handleProfileFilter = (event: React.FormEvent<HTMLInputElement>) => {
    if (event.currentTarget.value.length > 0) {
      const inputValue = event.currentTarget.value.toLowerCase();
      setProfilesFiltered(profiles?.filter((item) => item.descricao?.toString().toLowerCase().includes(inputValue)));
    } else {
      setProfilesFiltered(profiles);
    }
  };
  const handlerConfirmDelete = async (step: ConfirmDeleteStepEnum) => {
    setModalLoading(true);

    switch (step) {
      case ConfirmDeleteStepEnum.ValidateUser:
        await profileService.api
          .get(`${profile?.idIdentityServer}/usuarios`)
          .then(async (res) => {
            if (res.data) {
              setModalDeleteVisible(false);
              setModalConfirmDeleteVisible(true);
            } else {
              await handlerConfirmDelete(ConfirmDeleteStepEnum.ConfirmDelete);
              setModalDeleteVisible(false);
            }
          })
          .catch((error) => notificationController.error(error));
        break;
      case ConfirmDeleteStepEnum.ConfirmDelete:
        await profileService
          .delete(`${profile.isDeleted ? 'ativar' : 'desativar'}/${profile.id}`)
          .then(async () => {
            const newProfiles = profiles.map((p) => {
              if (p.id == profile.id) {
                return { ...p, isDeleted: !profile.isDeleted };
              }
              return p;
            });

            setProfiles(newProfiles);
            setProfilesFiltered(newProfiles);
            setModalConfirmDeleteVisible(false);
            notificationController.success({
              message: `Perfil ${profile.isDeleted ? 'ativado' : 'desativado'} com sucesso.`,
            });
          })
          .catch((error) => notificationController.error(error));
        break;
    }

    setModalLoading(false);
  };

  const handleExportToExcel = () => {
    genericExportToExcel(
      'perfis',
      columns,
      profilesFiltered?.map((p) => ({
        ...p,
        isDeleted: !p.isDeleted ? 'Ativo' : 'Inativo',
      })) ?? [],
    );
  };

  const columns: ColumnsType<ProfileModel> = [
    {
      title: 'Nome',
      dataIndex: 'descricao',
      showSorterTooltip: false,
      sorter: (a, b) => (a.descricao && b.descricao ? a.descricao.localeCompare(b.descricao) : 0),
      width: '50%',
    },
    {
      title: 'Status',
      dataIndex: 'isDeleted',
      showSorterTooltip: false,
      sorter: (a, b) => (a.isDeleted === b.isDeleted ? 0 : a.isDeleted ? -1 : 1),
      width: '50%',
      render: (isDeleted: boolean) => {
        return (
          <Badge
            status={!isDeleted ? 'error' : 'success'}
            text={!isDeleted ? 'Ativo' : 'Inativo'}
            color={!isDeleted ? 'green' : 'red'}
          />
        );
      },
    },
    {
      title: '',
      dataIndex: 'id',
      width: '10%',
      showSorterTooltip: false,
      sortDirections: [],
      sorter: (a, b) => (a?.id ? a.id : 0) - (b?.id ? b.id : 0),
      defaultSortOrder: 'descend',
      render: (_, profile) => {
        const canEdit = hasAccessByPermission('profile', 'U');
        const canDelete = hasAccessByPermission('profile', 'D');
        return (
          !profile.emAlteracaoPermissoes && (
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end' }}>
              {(canEdit || canDelete) && (
                <DropdownTable
                  buttonText=""
                  iconD={
                    <div style={{ color: '#545454' }}>
                      <IconMore />
                    </div>
                  }
                  trigger={['click']}
                  placement="bottomRight"
                  overlay={
                    <Menu>
                      {canEdit && (
                        <MenuItem onClick={() => handleEditClick(profile.id ?? 0)}>
                          <Button type="text">Editar</Button>
                        </MenuItem>
                      )}
                      {canDelete && (
                        <MenuItem onClick={() => handleDeleteClick(profile)}>
                          <Button type="text">{profile.isDeleted ? 'Ativar' : 'Desativar'}</Button>
                        </MenuItem>
                      )}
                      <MenuItem onClick={() => handleViewClick(profile.id ?? 0)}>
                        <Button type="text">Visualizar</Button>
                      </MenuItem>
                    </Menu>
                  }
                ></DropdownTable>
              )}
            </div>
          )
        );
      },
    },
  ];

  const fecth = () => {
    setLoading(true);
    profileService
      .getArray('')
      .then((res) => {
        setProfiles(res);
        setProfilesFiltered(res);
        setLoading(false);
      })
      .catch((error) => {
        notificationController.error(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    dispatch(
      setHeader({
        title: 'Perfis',
      }),
    );

    fecth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <PageTitle>Perfis</PageTitle>
      <Spinner spinning={loading}>
        <Dashboard
          title={`Perfis ${readUserFilter()?.client?.razaoSocial ? `- ${readUserFilter()?.client?.razaoSocial}` : ''}`}
          buttonText={hasAccessByPermission('profile', 'C') ? 'Cadastrar novo perfil' : ''}
          handleButtonClick={handleNewProfileClick}
          table={<Table bordered={true} columns={columns} dataSource={profilesFiltered} />}
          placeholderTextSearch="Pesquisar por Nome"
          handleSearchOnChange={handleProfileFilter}
          exportToExcel={handleExportToExcel}
        />
      </Spinner>
      <Modal
        title="Deletar perfil"
        visible={modalDeleteVisible}
        onOk={() => handlerConfirmDelete(ConfirmDeleteStepEnum.ValidateUser)}
        onCancel={() => setModalDeleteVisible(false)}
      >
        Tem certeza que deseja excluir o perfil?
        <br />
        <SpinnerSlump spinning={modalLoading}></SpinnerSlump>
      </Modal>
      <Modal
        title={profile.isDeleted ? 'Ativar Perfil' : 'Existem usuários associados a este perfil'}
        visible={modalConfirmDeleteVisible}
        onOk={() => handlerConfirmDelete(ConfirmDeleteStepEnum.ConfirmDelete)}
        onCancel={() => setModalConfirmDeleteVisible(false)}
      >
        {profile.isDeleted
          ? 'Tem certeza que deseja ativar o perfil?'
          : 'Tem certeza que deseja desativar o perfil? Todos usuários perderam seus respectivos acessos.'}
        <br />
        <SpinnerSlump spinning={modalLoading}></SpinnerSlump>
      </Modal>
    </>
  );
};

export default ProfileDashboard;
