import React, { ReactNode } from 'react';
import { InputProps as AntInputProps, InputRef } from 'antd';
import * as S from './InputTag.styles';

export interface InputTagProps extends AntInputProps {
  icon?: ReactNode;
}

export const InputTag = React.forwardRef<InputRef, InputTagProps>(
  ({ className, children, style, icon, ...props }, ref) => (
    <S.InputTag ref={ref} className={className} suffix={icon} {...props} style={{ ...style }}>
      {children}
    </S.InputTag>
  ),
);
