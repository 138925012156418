import React from 'react';
import * as S from './Description.styles';

export interface DescriptionProps {
  title?: string;
  subtitle?: string;
  children?: React.ReactNode;
}

export const Description: React.FC<DescriptionProps> = ({ title, subtitle, children }) => {
  return (
    <>
      <S.Container>
        <S.DescriptionWrapper>
          <S.Title>{title}</S.Title>
          <S.Subtitle>{subtitle}</S.Subtitle>
        </S.DescriptionWrapper>

        {children}
      </S.Container>
    </>
  );
};
