export enum TravelState {
  AguardandoRemessa = 1,
  ChegadaRemessa = 2,
  SaidaCentral = 3,
  ChegadaObra = 4,
  InicioDescarga = 5,
  FinalDescarga = 6,
  SaidaObra = 7,
  ChegadaCentral = 8,
}
