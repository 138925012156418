import { SummaryAlertModel } from '@app/domain/interfaces/alert/summaryAlertModel';
import React from 'react';
import ApexCharts from 'apexcharts';
import { useEffect } from 'react';
import {
  getDifferenceBetweenDatesInHours,
  getDifferenceBetweenDatesInMinutes,
  renderViagemEstadoTexto,
} from '@app/utils/utils';
import moment from 'moment';

interface IChart {
  summaryAlert: SummaryAlertModel;
}

export const Chart: React.FC<IChart> = ({ summaryAlert }) => {
  useEffect(() => {
    const maxValueWater = summaryAlert.usosAgua ? Math.max(...summaryAlert?.usosAgua?.map((u) => u.litros ?? 0)) : 0;

    //chart slump
    if (summaryAlert.coletasSlump?.length > 0) {
      const optionsSlump = {
        chart: {
          id: 'slump',
          type: 'line',
          group: 'chart',
          width: '100%',
          height: 500,
          toolbar: {
            show: false,
          },
        },
        legend: {
          show: true,
          showForSingleSeries: true,
          position: 'top',
          horizontalAlign: 'right',
          fontSize: '14px',
          fontFamily: 'Mulish',
          fontWeight: 600,
          fontStyle: 'normal',
          markers: {
            fillColors: ['#ffffff'],
            strokeColor: '#707070',
            strokeWidth: 1,
            radius: 10,
          },
        },
        tooltip: {
          style: {
            fontFamily: 'Mulish',
          },
        },
        grid: {
          padding: {
            left: 20,
          },
        },
        colors: ['#707070'],
        series: [
          {
            type: 'line',
            name: 'Coleta de Slump (cm)',
            data: summaryAlert.coletasSlump
              ?.map((s) => ({ y: s.centimetros, x: new Date(s.dataHora).getTime() }))
              .sort((a, b) => b.x - a.x),
          },
        ],
        yaxis: {
          labels: {
            style: {
              fontSize: '16px',
              fontFamily: 'Mulish',
              fontWeight: 600,
            },
            formatter: (value: number) => value?.toFixed(1),
          },
        },
        xaxis: {
          min: moment(summaryAlert.dataHoraInicioViagem).add(-10, 'm').toDate().getTime(),
          max: moment(summaryAlert.dataHoraFimViagem).add(10, 'm').toDate().getTime(),
          type: 'datetime',
          tickAmount: 15,
          labels: {
            datetimeUTC: true,
            formatter: (value: number | number[]) =>
              typeof value == 'number' ? moment(value).format('HH:mm') : moment(value[0]).format('HH:mm'),
          },
          tooltip: {
            enabled: false,
          },
        },
        stroke: {
          width: [2],
        },
        annotations: {
          yaxis: [
            {
              y: summaryAlert.slumpAlvoMin,
              y2: summaryAlert.slumpAlvoMax,
              strokeDashArray: 0,
              borderWidth: 50,
              borderColor: '#023611',
              fillColor: '#117e2f26',
              label: {
                style: {
                  fontSize: '10px',
                  fontFamily: 'Mulish',
                  color: '#333',
                  background: '#117e2f26',
                },
                text: 'Slump Alvo',
              },
            },
          ],
          xaxis: summaryAlert?.viagemEstados
            ?.map((estado) => {
              return {
                x: new Date(estado.dataHora).getTime(),
                y: maxValueWater,
                strokeDashArray: 0,
                borderColor: '#5EB576',
                width: '120px',
                height: '18px',
                label: {
                  position: 'bottom',
                  orientation: 'vertical',
                  hideOverlappingLabels: true,
                  showDuplicates: true,
                  style: {
                    color: '#707070',
                    background: '#fff',
                    fontSize: '16px',
                    fontFamily: 'Mulish',
                    padding: {
                      left: 15,
                      top: 5,
                      bottom: 5,
                    },
                  },
                },
              };
            })
            .concat(
              summaryAlert.viagemEstados
                ?.filter((estado) => estado.estadoViagem != 1) //filtro para não renderizar o estado aguardando remessa (estava sebrepondo o chegada remessa)
                .map((estado, index) => {
                  return {
                    x: new Date(estado.dataHora).getTime(),
                    y: maxValueWater,
                    strokeDashArray: 0,
                    borderColor: '#5EB576',
                    width: '120px',
                    height: '18px',
                    label: {
                      position:
                        summaryAlert.viagemEstados && index < summaryAlert.viagemEstados.length - 2
                          ? estado.estadoViagem &&
                            getDifferenceBetweenDatesInMinutes(
                              new Date(estado?.dataHora),
                              new Date(summaryAlert.viagemEstados[index + 2]?.dataHora),
                            ) <= 5 &&
                            getDifferenceBetweenDatesInHours(
                              new Date(estado?.dataHora),
                              new Date(summaryAlert.viagemEstados[index + 2]?.dataHora),
                            ) < 1
                            ? 'top'
                            : 'bottom'
                          : 'bottom',
                      orientation: 'vertical',
                      hideOverlappingLabels: false,
                      showDuplicates: true,
                      style: {
                        color: '#707070',
                        background: '#fff',
                        fontSize: '16px',
                        fontFamily: 'Mulish',
                        padding: {
                          left: 15,
                          top: 5,
                          bottom: 5,
                        },
                      },
                      text: renderViagemEstadoTexto(estado.estadoViagem ?? 0),
                    },
                  };
                }),
            ),
        },
      };
      const chartSlump = new ApexCharts(document.querySelector('#chart_slump'), optionsSlump);
      chartSlump.render();
    }

    //chart water
    const options = {
      //opcoes do grafico em geral
      chart: {
        id: 'water',
        type: 'line',
        group: 'chart',
        width: '100%',
        height: 600,
        toolbar: {
          show: false,
        },
      },
      legend: {
        show: true,
        position: 'top',
        horizontalAlign: 'right',
        fontSize: '14px',
        fontFamily: 'Mulish',
        fontWeight: 600,
        fontStyle: 'normal',
        markers: {
          width: 24,
          height: 5,
          radius: 0,
        },
      },
      tooltip: {
        style: {
          fontFamily: 'Mulish',
        },
      },
      stroke: {
        curve: 'smooth',
        width: [2, 2, 8],
      },
      //series de dados dos graficos, os dados do eixo x e y devem seguir o mesmo padrao
      //necessario sort para nao desorganizar o grafico
      colors: ['#0072DC', '#C41B24'],
      series: [
        {
          type: 'line',
          name: 'Linha 1 - Uso da água (L)',
          data: summaryAlert.usosAgua
            ?.map((u) => ({ y: u.litros, x: new Date(u.dataHora).getTime() }))
            .sort((a, b) => b.x - a.x),
        },
        {
          type: 'line',
          name: 'Linha 2 - Limite da água (L)',
          data: summaryAlert.usosAgua
            ?.map((u) => ({ y: summaryAlert.limiteAgua, x: new Date(u.dataHora).getTime() }))
            .sort((a, b) => b.x - a.x),
        },
      ],
      yaxis: {
        labels: {
          style: {
            fontSize: '16px',
            fontFamily: 'Mulish',
            fontWeight: 600,
          },
          formatter: (value: number) => value?.toFixed(1),
        },
      },
      xaxis: {
        min: moment(summaryAlert.dataHoraInicioViagem).add(-10, 'm').toDate().getTime(),
        max: moment(summaryAlert.dataHoraFimViagem).add(10, 'm').toDate().getTime(),
        type: 'datetime',
        tickAmount: 15,
        labels: {
          datetimeUTC: true,
          formatter: (value: number | number[]) =>
            typeof value == 'number' ? moment(value).format('HH:mm') : moment(value[0]).format('HH:mm'),
        },
        tooltip: {
          enabled: false,
        },
      },
      annotations: {
        xaxis: summaryAlert.viagemEstados
          ?.filter((estado) => estado.estadoViagem != 1) //filtro para não renderizar o estado aguardando remessa (estava sebrepondo o chegada remessa)
          .map((estado, index) => {
            return {
              x: new Date(estado.dataHora).getTime(),
              y: maxValueWater,
              strokeDashArray: 0,
              borderColor: '#5EB576',
              width: '120px',
              height: '18px',
              label: {
                position:
                  summaryAlert.viagemEstados && index < summaryAlert.viagemEstados.length - 2
                    ? estado.estadoViagem &&
                      getDifferenceBetweenDatesInMinutes(
                        new Date(estado?.dataHora),
                        new Date(summaryAlert.viagemEstados[index + 2]?.dataHora),
                      ) <= 5 &&
                      getDifferenceBetweenDatesInHours(
                        new Date(estado?.dataHora),
                        new Date(summaryAlert.viagemEstados[index + 2]?.dataHora),
                      ) < 1
                      ? 'top'
                      : 'bottom'
                    : 'bottom',
                orientation: 'vertical',
                hideOverlappingLabels: false,
                showDuplicates: true,
                style: {
                  color: '#707070',
                  background: '#fff',
                  fontSize: '16px',
                  fontFamily: 'Mulish',
                  padding: {
                    left: 15,
                    top: 5,
                    bottom: 5,
                  },
                },
                text: renderViagemEstadoTexto(estado.estadoViagem ?? 0),
              },
            };
          }),
        points: [
          {
            x: new Date(summaryAlert.estouroAgua?.dataHora ?? '').getTime(),
            y: summaryAlert.estouroAgua?.litros,
            marker: {
              size: 6,
              fillColor: '#fff',
              strokeColor: '#C41B24',
              radius: 2,
            },
            label: {
              borderColor: '#C41B24',
              offsetY: 0,
              textAnchor: 'middle',
              style: {
                color: '#707070',
                background: '#fff',
                fontFamily: 'Mulish',
                fontSize: '14px',
                padding: {
                  left: 15,
                  right: 15,
                  top: 5,
                  bottom: 5,
                },
              },
            },
            tooltip: {
              style: {
                fontFamily: 'Mulish',
              },
            },
          },
        ],
      },
    };
    const chart = new ApexCharts(document.querySelector('#chart_principal'), options);
    chart.render();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div id="chart_slump"></div>
      <div id="chart_principal"></div>
    </>
  );
};
