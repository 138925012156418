export enum AlertType {
  Novo = 0,
  Reportado = 1,
  Confirmado = 2,
}

export enum AlertEntidade {
  FiltroHorario = 'Horario',
  FiltroBetoneira = 'Betoneira',
  FiltroMotorista = 'Motorista',
  FiltroCliente = 'Cliente',
  FiltroTitulo = 'Titulo',
  FiltroCategoria = 'Categoria',
  FiltroPeriodo = 'Período',
}

export enum AlertEstado {
  Novo = 0,
  Reportado = 1,
  Confirmado = 2,
}
