/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Spinner } from '@app/components/common/Spinner/Spinner';
import { Modal } from '@app/components/common/Modal/Modal';
import { BaseFormInputItem } from '@app/components/common/forms/components/BaseFormInputItem/BaseFormInputItem';
import { Select } from '@app/components/common/selects/Select/Select';
import { Col, Row } from 'antd';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { GroupModel } from '@app/domain/interfaces/client_branch/groupModel';
import { ClientBranchModel, ClientBranchRegionals } from '@app/domain/interfaces/client_branch/clientBranchModel';
import IGroupService, { GroupService } from '@app/services/groupService';
import IClientBranchService, { ClientBranchService } from '@app/services/clientBranchService';
import IRegionalService, { RegionalService } from '@app/services/regionalService';
import { BranchModel } from '@app/domain/interfaces/monitoring/branchMonitoringModel';
import { setHeaderBranchSelected } from '@app/store/slices/monitoringSlice';
import { useDispatch } from 'react-redux';
import { RegionalGroupModel } from '@app/domain/interfaces/regional/regionalModel';

const groupService: IGroupService = new GroupService();
const clientBranchService: IClientBranchService = new ClientBranchService();
const regionalService: IRegionalService = new RegionalService();

interface IModalFilterHeaderMonitoring {
  modalVisible: boolean;
  setModalVisible: Dispatch<SetStateAction<boolean>>;
}

const ModalFilterHeaderMonitoring: React.FC<IModalFilterHeaderMonitoring> = ({ modalVisible, setModalVisible }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [groups, setGroups] = useState<GroupModel[]>([]);
  const [regionals, setRegionals] = useState<RegionalGroupModel[]>([]);
  const [branches, setBranches] = useState<ClientBranchModel[]>([]);
  const [selectedGroup, setSelectedGroup] = useState<number | null>(null);
  const [selectedRegional, setSelectedRegional] = useState<number | null>(null);
  const [selectedBranch, setSelectedBranch] = useState<number | null>(null);
  const handlerBranchChange = (payload: BranchModel) => dispatch(setHeaderBranchSelected(payload));

  useEffect(() => {
    fetchGroups();
  }, []);

  useEffect(() => {
    if (selectedGroup) {
      console.log(selectedGroup);
      fetchRegionals(selectedGroup);
    }
  }, [selectedGroup]);

  useEffect(() => {
    if (selectedRegional) {
      console.log(selectedRegional);
      fetchBranchesByRegional(selectedRegional);
    }
  }, [selectedRegional]);

  const fetchGroups = async () => {
    setGroups(await groupService.getArray(''));
  };

  const fetchRegionals = async (idAgrupamento: number) => {
    const response = await regionalService.getArray(`/${idAgrupamento}`);

    if (response.length > 0) {
      setRegionals(response);
    } else {
      if (selectedGroup) fetchBranchesByGroup(selectedGroup);
    }
  };

  const fetchBranchesByGroup = async (idAgrupamento: number) => {
    setBranches(await clientBranchService.getArray(`/obterPorAgrupamento/${idAgrupamento}`));
  };

  const fetchBranchesByRegional = async (idRegional: number) => {
    const response = await clientBranchService.getArray(`/obterPorRegional/${idRegional}`);

    if (response.length > 0) {
      setBranches(response);
    } else {
      if (selectedGroup) {
        fetchBranchesByGroup(selectedGroup);
      }
    }
  };

  const handleSetHeaderBranch = async () => {
    if (selectedBranch) {
      const branch = branches.find((b) => b.id === selectedBranch);
      if (branch) {
        handlerBranchChange({
          filial: branch.nome,
          idFilial: branch.codigoFilialSiac,
          pontosDeCarga: [],
        } as BranchModel);

        setSelectedGroup(null);
        setSelectedRegional(null);
        setSelectedBranch(null);
        setModalVisible(false);
      }
    }
  };

  return (
    <Modal
      title="Filtros"
      open={modalVisible}
      onCancel={() => {
        setSelectedBranch(null);
        setSelectedGroup(null);
        setSelectedRegional(null);
        setModalVisible(false);
      }}
      okText="Filtrar"
      size="large"
      okButtonProps={{ disabled: !selectedBranch }}
      onOk={handleSetHeaderBranch}
    >
      <Spinner spinning={loading}>
        <BaseForm layout="vertical">
          <Row gutter={18}>
            <Col xs={24} md={8}>
              <BaseFormInputItem style={{ width: '100%' }} label="Grande centro">
                <Select
                  options={groups.map((g) => ({ label: g.nome, value: g.id }))}
                  value={selectedGroup}
                  onSelect={(e) => {
                    setSelectedGroup(e as unknown as number);
                    setSelectedRegional(null);
                    setSelectedBranch(null);
                  }}
                />
              </BaseFormInputItem>
            </Col>

            <Col xs={24} md={8}>
              <BaseFormInputItem style={{ width: '100%' }} label="Região">
                <Select
                  options={regionals.map((r) => ({ label: r.nome, value: r.id }))}
                  disabled={!selectedGroup || regionals.length <= 0}
                  onSelect={(e) => {
                    setSelectedRegional(e as unknown as number);
                    setSelectedBranch(null);
                  }}
                  value={selectedRegional}
                />
              </BaseFormInputItem>
            </Col>

            <Col xs={24} md={8}>
              <BaseFormInputItem style={{ width: '100%' }} label="Filial">
                <Select
                  options={branches.map((b) => ({ label: b.nome, value: b.id }))}
                  disabled={(regionals.length > 0 && !selectedRegional) || !selectedGroup}
                  onSelect={(e) => setSelectedBranch(e as unknown as number)}
                  value={selectedBranch}
                />
              </BaseFormInputItem>
            </Col>
          </Row>
        </BaseForm>
      </Spinner>
    </Modal>
  );
};

export default ModalFilterHeaderMonitoring;
