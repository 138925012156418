import { RegionalModel } from '@app/domain/interfaces/chargePointModel';
import { ApiServiceBase, IApiServiceBase } from './apiServiceBase';

export default interface IChargePointService extends IApiServiceBase<RegionalModel, RegionalModel> {
  getActivesByClient(): Promise<RegionalModel[]>;
}

export class ChargePointService extends ApiServiceBase<RegionalModel, RegionalModel> implements IChargePointService {
  constructor() {
    super('admin/pontoscarga');
  }

  async getActivesByClient(): Promise<RegionalModel[]> {
    return await this.getArray(`obterPorCliente`);
  }
}
