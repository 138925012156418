import { ApiServiceBase, IApiServiceBase } from './apiServiceBase';
import { ReportTravelInfoDetailFilterModel } from '@app/domain/interfaces/report/reportTravelInfoDetailFilter';
import { ReportBaseModel } from '@app/domain/interfaces/report/reportBaseModel';

export default interface ITravelInfoDetailsService
  extends IApiServiceBase<ReportTravelInfoDetailFilterModel, ReportBaseModel> {
  getTravelInfoDetail(filter: ReportTravelInfoDetailFilterModel): Promise<ReportTravelInfoDetailFilterModel>;
}

export class TravelInfoDetailsService
  extends ApiServiceBase<ReportTravelInfoDetailFilterModel, ReportBaseModel>
  implements ITravelInfoDetailsService
{
  constructor() {
    super('admin/info-viagem');
  }

  async getTravelInfoDetail(filter: ReportTravelInfoDetailFilterModel): Promise<ReportTravelInfoDetailFilterModel> {
    return (await this.api.post(`obter-detalhado`, filter))?.data;
  }
}
