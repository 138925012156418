import { ProfileDropdown } from '@app/components/header/components/profileDropdown/ProfileDropdown/ProfileDropdown';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { Col, Row } from 'antd';
import React from 'react';
import * as S from './HeaderRegister.styles';

interface HeaderRegistrationPagesProps {
  isTwoColumnsLayout: boolean;
  title?: string;
  icon: JSX.Element;
}

export const HeaderRegistrationPages: React.FC<HeaderRegistrationPagesProps> = ({
  isTwoColumnsLayout,

  icon,
}) => {
  const { title, handleBackClick } = useAppSelector((state) => state.headerRegister);

  const headerTitle = (
    <Row>
      <Col>
        <Row align="middle" justify="center">
          <S.Icon onClick={handleBackClick}>{icon}</S.Icon>
          <S.HeaderTitle>{title}</S.HeaderTitle>
        </Row>
      </Col>
    </Row>
  );

  return (
    <Row
      justify="space-between"
      align="middle"
      style={{ borderBottom: '1px solid var(--disabled-bg-color)', width: '100%' }}
    >
      {headerTitle}
      <S.ProfileColumn xl={3} xxl={4} $isTwoColumnsLayout={isTwoColumnsLayout}>
        <Row align="middle" justify="end" style={{ marginBottom: '1rem' }}>
          <ProfileDropdown />
        </Row>
      </S.ProfileColumn>
    </Row>
  );
};
