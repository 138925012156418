import ApexCharts from 'apexcharts';
import MouseTracker from '@app/components/common/MouseTracker/MouseTracker';
import { CentralTrackingShippingWaterModel } from '@app/domain/interfaces/centralTracking/CentralTrackingModel';
import { ITooltipConfig } from '@app/pages/modules/Reports/Charts/interface/chartReport';
import { getDifferenceBetweenDatesInMinutes, minutesToHoursAndMinutes } from '@app/utils/utils';
import moment from 'moment';
import { FC, useEffect, useState } from 'react';

interface WaterTableChartProps {
  idTravel: number;
  waterLimit: number;
  datetimeStart: Date;
  datetimeEnd: Date;
  waterHistory: CentralTrackingShippingWaterModel[];
}

export const WaterTableChart: FC<WaterTableChartProps> = ({
  idTravel,
  waterLimit,
  waterHistory,
  datetimeStart,
  datetimeEnd,
}) => {
  const [tooltipConfig, setTooltipConfig] = useState<ITooltipConfig>({
    visible: false,
    title: '',
    value: '',
  });
  useEffect(() => {
    const start = new Date(datetimeStart);
    const end = new Date(datetimeEnd);
    const midpoint = new Date((start.getTime() + end.getTime()) / 2);

    if (waterHistory?.length > 0) {
      const options = {
        chart: {
          id: 'water',
          type: 'line',
          group: 'chart',
          width: '100%',
          height: 300,
          toolbar: {
            show: false,
          },
        },
        grid: {
          padding: {
            left: 40,
            right: 40,
          },
        },
        legend: {
          show: true,
          position: 'top',
          horizontalAlign: 'right',
          fontSize: '14px',
          fontFamily: 'Mulish',
          fontWeight: 600,
          fontStyle: 'normal',
          markers: {
            width: 24,
            height: 5,
            radius: 0,
          },
        },
        tooltip: {
          style: {
            fontFamily: 'Mulish',
          },
        },
        series: [
          {
            type: 'line',
            name: 'Linha 1 - Uso da água (L)',
            color: '#0072DC',
            data: [
              ...waterHistory
                ?.filter((u) => u.dataHora !== undefined)
                ?.map((u) => ({ y: u.aguaUtilizada, x: new Date(u.dataHora ?? 0).getTime() })),
              { y: waterHistory[0].aguaUtilizada, x: new Date(datetimeStart).getTime() },
              { y: waterHistory[waterHistory.length - 1].aguaUtilizada, x: new Date(datetimeEnd).getTime() },
            ].sort((a, b) => b.x - a.x),
          },
          {
            type: 'line',
            name: 'Linha 2 - Limite da água (L)',
            color: '#C41B24',
            data: [
              ...waterHistory
                ?.filter((u) => u.dataHora !== undefined)
                ?.map((u) => ({ y: waterLimit, x: new Date(u.dataHora ?? 0).getTime() })),
              { y: waterLimit, x: new Date(datetimeStart).getTime() },
              { y: waterLimit, x: new Date(datetimeEnd).getTime() },
            ].sort((a, b) => b.x - a.x),
          },
          {
            name: 'Tempos da viagem',
            data: [],
            color: '#374450',
          },
        ],
        yaxis: {
          labels: {
            style: {
              fontSize: '16px',
              fontFamily: 'Mulish',
              fontWeight: 600,
            },
            formatter: (value: number) => value?.toFixed(1),
          },
        },
        xaxis: {
          type: 'datetime',
          min: new Date(datetimeStart).getTime(),
          max: new Date(datetimeEnd).getTime(),
          tickAmount: 15,
          labels: {
            datetimeUTC: true,
            formatter: (value: number | number[]) =>
              typeof value == 'number' ? moment(value).format('HH:mm') : moment(value[0]).format('HH:mm'),
          },
          tooltip: {
            enabled: false,
          },
        },
        stroke: {
          curve: 'smooth',
          width: [2, 2, 1],
        },
        annotations: {
          points: [
            {
              x: midpoint.getTime(),
              y: 0,
              marker: {
                size: 9,
                shape: 'square',
                fillColor: '#374450',
                strokeColor: '#374450',
                tooltip: {
                  enabled: true,
                },
              },
              mouseEnter: () => {
                setTooltipConfig({
                  title: 'Tempo',
                  value: minutesToHoursAndMinutes(getDifferenceBetweenDatesInMinutes(start, end)),
                  visible: true,
                });
              },
              mouseLeave: () => {
                setTooltipConfig({
                  title: 'Tempo',
                  value: '',
                  visible: false,
                });
              },
            },
          ],
        },
      };

      const chartSlump = new ApexCharts(document.querySelector(`#chart_water_${idTravel}`), options);
      chartSlump.render();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      {!(waterHistory?.length > 0) && <h3 style={{ paddingLeft: '1rem' }}>Nenhum dado disponível para exibição.</h3>}

      {tooltipConfig.visible && (
        <MouseTracker offset={{ x: 0, y: 0 }}>
          <div className="tooltip">
            <span className="square"></span>
            <span className="text-label">Tempo Central</span>
            <span className="text-value">{tooltipConfig.value}</span>
          </div>
        </MouseTracker>
      )}
      <div id={`chart_water_${idTravel}`}></div>
    </>
  );
};
