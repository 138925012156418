import { Collapse as AntdCollapse, Row, Col, CollapseProps } from 'antd';
import * as S from './Collapse.styles';
import { BaseForm } from '../forms/BaseForm/BaseForm';

export const { Panel } = AntdCollapse;

export interface CollapseChartProps extends CollapseProps {
  title?: string;
  subtitle?: string;
}

export const Collapse: React.FC<CollapseChartProps> = ({ title, subtitle, ...props }) => {
  return (
    <S.Collapse key="slump" {...props}>
      <Panel
        key="1"
        header={
          <Row align={'middle'} justify={'space-between'}>
            <Col>
              <S.CollapseHeaderName>{title}</S.CollapseHeaderName>
            </Col>
          </Row>
        }
      >
        <h1 style={{ marginTop: '1rem' }}>{subtitle}</h1>
        <Row style={{ marginTop: '1rem', marginBottom: '1rem', width: '100%' }}>
          <BaseForm layout="vertical" style={{ width: '100%' }}>
            {props.children}
          </BaseForm>
        </Row>
      </Panel>
    </S.Collapse>
  );
};
