import { Button } from '@app/components/common/buttons/Button/Button';
import { Drawer } from '@app/components/common/Drawer/Drawer';
import { DeviceModel } from '@app/domain/interfaces/device/deviceModel';
import { Spin } from 'antd';
import { useEffect, useState } from 'react';
import DisplayInput from '@app/components/common/inputs/DisplayInput/DisplayInput';
import moment from 'moment';
import { DeviceTwinService } from '@app/services/deviceTwinService';
import { DeviceTwinReportedModel } from '@app/domain/interfaces/device/deviceTwinReportedModel';
import { notificationController } from '@app/controllers/notificationController';
import CardDeviceInformation from '@app/components/common/Card/CardDeviceInformation/CardDeviceInformation';

interface DrawerViewDeviceProps {
  visible: boolean;
  device: DeviceModel;
  onClose: () => void;
}

const deviceTwinService: DeviceTwinService = new DeviceTwinService();

const DrawerViewDevice = ({ visible, device, onClose }: DrawerViewDeviceProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [twinReported, setTwinReported] = useState<DeviceTwinReportedModel>({});

  useEffect(() => {
    setIsLoading(true);
    if (visible) {
      deviceTwinService.api
        .get(`reported/obterPorId/${device.id}`)
        .then((res) => {
          setIsLoading(false);
          setTwinReported(res.data);
        })
        .catch((error: Error) => {
          if (error.message.includes('https://slx-brso-dev-ioth-barramento.azure-devices.net/twins')) {
            notificationController.error({ message: 'Ocorreu uma falha - 1001: Dispositivo não encontrado' });
          } else {
            notificationController.error({ message: error.message });
          }
          setIsLoading(false);
        });
    } else {
      setTwinReported({});
    }
  }, [device.id, visible]);

  return (
    <Drawer
      title="Dispositivo"
      visible={visible}
      placement="right"
      footer={
        <Button onClick={onClose} type="primary">
          Voltar a listagem
        </Button>
      }
      textSpan=""
      onClose={onClose}
      style={{ overflowX: 'hidden' }}
    >
      <CardDeviceInformation device={device}></CardDeviceInformation>
      <DisplayInput
        label="Status Conexão"
        value={device.connectionState === 'Connected' ? 'Conectado' : 'Desconectado'}
      />
      <DisplayInput
        label="Última Atualização"
        value={
          twinReported.ultimoUpdate && twinReported.ultimoUpdate?.toString() != '0001-01-01T00:00:00Z'
            ? moment(twinReported.ultimoUpdate).format('DD/MM/YYYY HH:mm')
            : '-'
        }
      />
      <DisplayInput label="Versão Gateway" value={twinReported.versaoGateway} />
      <DisplayInput label="Versão Central" value={twinReported.versaoCentral} />
      <DisplayInput label="Versão H2" value={twinReported.versaoH2} />
      <DisplayInput label="Versão STCA" value={twinReported.versaoSTCA} />
      <Spin spinning={isLoading}></Spin>
    </Drawer>
  );
};

export default DrawerViewDevice;
