import { notificationController } from '@app/controllers/notificationController';
import { ClaimModel } from '@app/domain/interfaces/profile/claimModel';
import { ProfileModel } from '@app/domain/interfaces/profile/profileModel';
import { ApiServiceBase, IApiServiceBase } from './apiServiceBase';
import { ArgsProps } from 'antd/lib/notification';

export default interface IProfileService extends IApiServiceBase<ProfileModel, ProfileModel> {}

export class ProfileService extends ApiServiceBase<ProfileModel, ProfileModel> implements IProfileService {
  constructor() {
    super('admin/perfis');
  }

  async getClaimsByUserIdIdentity(id: string): Promise<ClaimModel[]> {
    try {
      return await (
        await this.api.get(`permissoes/${id}`)
      ).data;
    } catch (error) {
      notificationController.error(error as ArgsProps);
      return [];
    }
  }
}
