import { FONT_SIZE, media } from '@app/styles/themes/constants';
import { Divider } from 'antd';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DividerMain = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

export const DividerContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
`;

export const DividerTitle = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;

  font-family: 'Mulish';
  font-style: normal;
  line-height: 24px;
  color: var(--primary-color);
  font-size: 1.25rem;
  font-weight: 800;

  @media only screen and (${media.xs}) {
    font-size: ${FONT_SIZE.xs};
  }
  @media only screen and (${media.md}) {
    font-size: ${FONT_SIZE.md};
  }
  @media only screen and (${media.xxl}) {
    font-size: 1.5rem;
  }
`;

export const DividerSubtitle = styled.span`
  display: flex;
  align-items: center;
  flex-direction: row;

  font-family: 'Mulish';
  font-style: normal;
  line-height: 24px;
  color: var(--primary-color);
  font-size: 0.925rem;
  font-weight: 600;
  font-size: ${FONT_SIZE.md};
  letter-spacing: 0.03rem;

  @media only screen and (${media.xs}) {
    font-size: ${FONT_SIZE.xxs};
  }
  @media only screen and (${media.md}) {
    font-size: ${FONT_SIZE.xs};
  }
  @media only screen and (${media.xxl}) {
    font-size: 1.125rem;
  }
`;

export const DividerS = styled(Divider)`
  background-color: #ced6e2;
  margin: 0.5rem 1.125rem 0.5rem 1.2rem;
  height: 45px;

  &:nth-child(4) {
    display: none;
  }
`;

export const DividerIcon = styled.div`
  display: flex;
  justify-content: center;
  width: 1.5625rem;
  align-items: center;
  @media only screen and (${media.xxl}) {
    width: 2.5625rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
