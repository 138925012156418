import crypto from 'crypto-js';
import appSettings from '@app/config/appsettings';

export const handleEncryptText = async (text: string): Promise<string> => {
  const secretKey = appSettings().Crypto.SecretKey;
  const secretIV = appSettings().Crypto.IV;
  const key = crypto.enc.Utf8.parse(secretKey);
  const iv = crypto.enc.Base64.parse(secretIV);
  const encoded = crypto.enc.Utf8.parse(text);
  const cipherText = crypto.TripleDES.encrypt(encoded, key, { mode: crypto.mode.CBC, iv: iv });
  return cipherText.toString();
};
