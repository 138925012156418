import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { setUser } from '@app/store/slices/userSlice';
import {
  deleteToken,
  deleteUser,
  getTypeUserFromClaim,
  persistToken,
  readToken,
  setUserFilter,
} from '@app/services/localStorage.service';
import { decodeToken } from 'react-jwt';
import IUserService, { UserService } from '@app/services/userService';
import IAuthService, { AuthService } from '@app/services/authService';
import { LoginModel } from '@app/domain/interfaces/auth/loginModel';
import { UserType } from '@app/constants/enums/userType';
import IClientService, { ClientService } from '@app/services/clientService';

export interface AuthSlice {
  token: string | null;
}

const initialState: AuthSlice = {
  token: readToken(),
};

interface IDecodedUserToken {
  name: string;
  client_admin: string | null;
  master: string | null;
  user_filial: string | null;
}

const authService: IAuthService = new AuthService();
const userService: IUserService = new UserService();
const clientService: IClientService = new ClientService();

export const doLogin = createAsyncThunk('auth/doLogin', async (loginPayload: LoginModel, { dispatch }) =>
  authService.login(loginPayload).then(async (res) => {
    persistToken(res.access_token);
    const data = decodeToken(res.access_token) as IDecodedUserToken;
    const user = await userService.getByUsername('obterPorUsername', data?.name);
    const type = getTypeUserFromClaim(user.claims ?? []);

    dispatch(setUser({ ...user, type: type }));

    if (type !== UserType.Master) {
      let claim = user?.claims?.find((c) => c.claimType == UserType.ClientAdmin || c.claimType == 'id_client');
      if (claim === undefined && user.simulationMode) {
        claim = user?.claims?.find((c) => c.claimType == UserType.UserFilial);
      }
      const client = await clientService.get(`obter/${claim?.claimValue}`);
      setUserFilter({ client: client });
    } else {
      setUserFilter({});
    }

    return res.access_token;
  }),
);

export const doLogout = createAsyncThunk('auth/doLogout', (payload, { dispatch }) => {
  deleteToken();
  deleteUser();
  dispatch(setUser(null));
});

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(doLogin.fulfilled, (state, action) => {
      state.token = action.payload;
    });
    builder.addCase(doLogout.fulfilled, (state) => {
      state.token = '';
    });
  },
});

export default authSlice.reducer;
