import React, { useEffect } from 'react';
import { ScheduleModel } from '@app/domain/interfaces/schedule/scheduleModel';
import { useNavigate, useParams } from 'react-router-dom';
import IScheduleService, { ScheduleService } from '@app/services/scheduleServices';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import {
  CardInfoContainer,
  CardInfoProperty,
  CardInfoTitle,
  CardInfoValue,
} from '@app/components/common/Card/CardInfo/CardDeviceInformation.styles';
import moment from 'moment';
import { Col, Divider } from 'antd';
import { PageContainer } from '@app/components/common/PageContainer/PageContainer';
import { setHeaderRegisterPage } from '@app/store/slices/headerRegisterPage';
import { setFooter } from '@app/store/slices/footerPageSlice';
import { Spinner } from '@app/components/common/Spinner/Spinner';
import { notificationController } from '@app/controllers/notificationController';
import { ReactComponent as IconCheck } from '@app/assets/icons/check_circle_outline.svg';
import { ReactComponent as IconRenew } from '@app/assets/icons/autorenew.svg';
import { ReactComponent as IconX } from '@app/assets/icons/highlight_off.svg';
import { Row, CardInfoColumnTitle, CardInfoName, CardInfoStatus } from './View.styles';

export type GroupedConcreteMixer = {
  id: number;
  betoneira: string;
  Central?: boolean;
  Hermes?: boolean;
  CAN?: boolean;
  H2?: boolean;
};

const scheduleService: IScheduleService = new ScheduleService();

export const groupeConcreteMixers = (concreteMixers: ScheduleModel['betoneiras']) => {
  if (!concreteMixers) return [];
  const grouped = concreteMixers.reduce((acc: Record<number, GroupedConcreteMixer>, curr) => {
    if (!acc[curr.idAgendamentoBetoneira]) {
      acc[curr.idAgendamentoBetoneira] = {
        id: curr.id,
        betoneira: curr.betoneira,
      };
    }

    if (curr.tipo === 'Central' || curr.tipo === 'Hermes' || curr.tipo === 'H2' || curr.tipo === 'CAN') {
      acc[curr.idAgendamentoBetoneira][curr.tipo] = curr.atualizado;
    }

    return acc;
  }, {});
  return Object.values(grouped) ?? [];
};

const ScheduleView: React.FC = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [schedule, setSchedule] = React.useState<ScheduleModel | undefined>(undefined);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [groupedConcreteMixers, setGroupedConcreteMixers] = React.useState<GroupedConcreteMixer[]>([]);

  useEffect(() => {
    dispatch(
      setHeaderRegisterPage({
        title: 'Agendamento',
        handleBackClick: () => navigate('/atualizacao-remota/agendamento'),
      }),
    );
    dispatch(setFooter({ confirmButtonText: '' }));
  }, [dispatch, navigate]);

  useEffect(() => {
    setLoading(true);
    scheduleService
      .getScheduleById(Number(id))
      .then((res) => {
        setSchedule(res);
        setGroupedConcreteMixers(groupeConcreteMixers(res?.betoneiras));
      })
      .catch((error) => {
        notificationController.error(error);
      })
      .finally(() => setLoading(false));
  }, [id]);

  const renderStatus = (status: boolean | undefined) => {
    if (status === undefined) return <CardInfoStatus> - </CardInfoStatus>;
    if (schedule?.status === 'Cancelado') return <IconX />;

    return status ? <IconCheck /> : <IconRenew />;
  };

  const renderRow = (betoneira: GroupedConcreteMixer, index: number) => {
    return (
      <Row key={index}>
        <Col xs={4} lg={4}>
          <CardInfoName>{betoneira.betoneira}</CardInfoName>
        </Col>
        <Col xs={4} lg={4}>
          <CardInfoStatus>{renderStatus(betoneira.Central)}</CardInfoStatus>
        </Col>
        <Col xs={4} lg={4}>
          <CardInfoStatus>{renderStatus(betoneira.H2)}</CardInfoStatus>
        </Col>
        <Col xs={4} lg={4}>
          <CardInfoStatus>{renderStatus(betoneira.CAN)}</CardInfoStatus>
        </Col>
        <Col xs={6} lg={6}>
          <CardInfoStatus>{renderStatus(betoneira.Hermes)}</CardInfoStatus>
        </Col>
      </Row>
    );
  };

  return (
    <Spinner spinning={loading}>
      <PageContainer>
        <CardInfoContainer style={{ marginBottom: '2rem' }}>
          <Row>
            <CardInfoTitle>Informações do agendamento</CardInfoTitle>
          </Row>
          <Row gutter={24} justify={'space-between'} style={{ paddingBottom: '1.5rem' }}>
            <Col xs={24} md={12} lg={4}>
              <CardInfoProperty>Cliente</CardInfoProperty>
              <CardInfoValue>{schedule?.cliente}</CardInfoValue>
            </Col>
            <Col xs={24} md={12} lg={4}>
              <CardInfoProperty>Status</CardInfoProperty>
              <CardInfoValue>{schedule?.status}</CardInfoValue>
            </Col>
            <Col xs={24} md={12} lg={4}>
              <CardInfoProperty>Data/Hora</CardInfoProperty>
              <CardInfoValue>{moment(schedule?.dataHora).format('DD/MM/YYYY HH:mm') + 'h'}</CardInfoValue>
            </Col>
            <Col xs={24} md={12} lg={4}>
              <CardInfoProperty>Usuário responsável</CardInfoProperty>
              <CardInfoValue>{schedule?.usuarioResponsavel}</CardInfoValue>
            </Col>
          </Row>
          <Row gutter={24} justify={'space-between'} style={{ paddingBottom: '1.5rem' }}>
            {Array.from({ ...schedule?.versoes, length: 4 }).map((versao, index) => (
              <Col key={index} xs={24} md={12} lg={4}>
                <CardInfoProperty>{versao?.tipo && `Versão ${versao?.tipo}`}</CardInfoProperty>
                <CardInfoValue>{versao?.versao}</CardInfoValue>
              </Col>
            ))}
          </Row>
        </CardInfoContainer>

        <CardInfoContainer>
          <Row>
            <CardInfoTitle>Atualizações</CardInfoTitle>
          </Row>
          <Row gutter={16}>
            <Col xs={22} xl={11}>
              <Row>
                <Col xs={4} xl={4}>
                  <CardInfoProperty>Betoneiras</CardInfoProperty>
                </Col>
                <Col xs={4} xl={4}>
                  <CardInfoColumnTitle>Versão Central</CardInfoColumnTitle>
                </Col>
                <Col xs={4} xl={4}>
                  <CardInfoColumnTitle>Versão H2</CardInfoColumnTitle>
                </Col>
                <Col xs={4} xl={4}>
                  <CardInfoColumnTitle>Versão CAN</CardInfoColumnTitle>
                </Col>
                <Col xs={6} xl={6}>
                  <CardInfoColumnTitle>Versão Hermes</CardInfoColumnTitle>
                </Col>
              </Row>
              {groupedConcreteMixers
                ?.slice(0, Math.ceil(groupedConcreteMixers.length / 2))
                .map((betoneira, index) => renderRow(betoneira, index))}
            </Col>

            <Col xs={0} xl={1}>
              <Divider type="vertical" style={{ backgroundColor: '#D9D9D9', height: '100%' }} />
            </Col>

            {schedule?.betoneiras && schedule?.betoneiras.length > 1 && (
              <Col xs={22} xl={11}>
                <Row hideWhenXs>
                  <Col xs={0} xl={4}>
                    <CardInfoProperty>Betoneiras</CardInfoProperty>
                  </Col>
                  <Col xs={0} xl={4}>
                    <CardInfoColumnTitle>Versão Central</CardInfoColumnTitle>
                  </Col>
                  <Col xs={0} xl={4}>
                    <CardInfoColumnTitle>Versão H2</CardInfoColumnTitle>
                  </Col>
                  <Col xs={0} xl={4}>
                    <CardInfoColumnTitle>Versão CAN</CardInfoColumnTitle>
                  </Col>
                  <Col xs={0} xl={6}>
                    <CardInfoColumnTitle>Versão Hermes</CardInfoColumnTitle>
                  </Col>
                </Row>
                {groupedConcreteMixers
                  ?.slice(Math.ceil(groupedConcreteMixers.length / 2))
                  .map((betoneira, index) => renderRow(betoneira, index))}
              </Col>
            )}
          </Row>
        </CardInfoContainer>
      </PageContainer>
    </Spinner>
  );
};

export default ScheduleView;
