import { ReportTimeDetailedShipmentModel } from '@app/domain/interfaces/report/reportTimeModel';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import moment from 'moment';

export const exportReportTimeDetailedToExcel = async (data: ReportTimeDetailedShipmentModel[], columns: any) => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('Report');

  let columnIndex = 1;

  columns.forEach((column: any) => {
    const cell = worksheet.getCell(1, columnIndex);
    cell.value = column.title as string;
    const span = column?.children?.length || 1;
    if (span > 1) {
      worksheet.mergeCells(1, columnIndex, 1, columnIndex + span - 1);
    }
    columnIndex += span;
  });

  const normalizedColumns = columns.flatMap((column: any) =>
    column.children.map((child: any) => ({
      title: child.title?.props?.children ?? child.title ?? '',
      dataIndex: child.dataIndex,
    })),
  );

  const titleRow = worksheet.getRow(2);
  normalizedColumns.forEach((column: any, index: number) => {
    titleRow.getCell(index + 1).value = column.title;
  });

  //render data

  data.map((item) => {
    worksheet.addRow([
      item.idViagem,
      item.dataNotaFiscal ? moment(item.dataNotaFiscal).format('DD/MM/YYYY HH:mm') + 'h' : '-',
      item.betoneira,
      item.motorista,
      item.obra,
      item.volume ? item.volume.toFixed(1) : '-',
      item.central.tempo ? item.central.tempo.toFixed(1) : '-',
      item.central.diesel ? item.central.diesel.toFixed(1) : '-',
      item.central.distancia ? item.central.distancia.toFixed(1) : '-',
      item.central.consumoDiesel ? item.central.consumoDiesel.toFixed(1) : '-',
      item.central.consumoMedio ? item.central.consumoMedio.toFixed(1) : '-',
      item.aCaminho.tempo ? item.aCaminho.tempo.toFixed(1) : '-',
      item.aCaminho.diesel ? item.aCaminho.diesel.toFixed(1) : '-',
      item.aCaminho.distancia ? item.aCaminho.distancia.toFixed(1) : '-',
      item.aCaminho.consumoDiesel ? item.aCaminho.consumoDiesel.toFixed(1) : '-',
      item.aCaminho.consumoMedio ? item.aCaminho.consumoMedio.toFixed(1) : '-',
      item.aguardo.tempo ? item.aguardo.tempo.toFixed(1) : '-',
      item.aguardo.diesel ? item.aguardo.diesel.toFixed(1) : '-',
      item.aguardo.distancia ? item.aguardo.distancia.toFixed(1) : '-',
      item.aguardo.consumoDiesel ? item.aguardo.consumoDiesel.toFixed(1) : '-',
      item.aguardo.consumoMedio ? item.aguardo.consumoMedio.toFixed(1) : '-',
      item.descarga.tempo ? item.descarga.tempo.toFixed(1) : '-',
      item.descarga.diesel ? item.descarga.diesel.toFixed(1) : '-',
      item.descarga.distancia ? item.descarga.distancia.toFixed(1) : '-',
      item.descarga.consumoDiesel ? item.descarga.consumoDiesel.toFixed(1) : '-',
      item.descarga.consumoMedio ? item.descarga.consumoMedio.toFixed(1) : '-',
      item.lavagem.tempo ? item.lavagem.tempo.toFixed(1) : '-',
      item.lavagem.diesel ? item.lavagem.diesel.toFixed(1) : '-',
      item.lavagem.distancia ? item.lavagem.distancia.toFixed(1) : '-',
      item.lavagem.consumoDiesel ? item.lavagem.consumoDiesel.toFixed(1) : '-',
      item.lavagem.consumoMedio ? item.lavagem.consumoMedio.toFixed(1) : '-',
      item.retorno.tempo ? item.retorno.tempo.toFixed(1) : '-',
      item.retorno.diesel ? item.retorno.diesel.toFixed(1) : '-',
      item.retorno.distancia ? item.retorno.distancia.toFixed(1) : '-',
      item.retorno.consumoDiesel ? item.retorno.consumoDiesel.toFixed(1) : '-',
      item.retorno.consumoMedio ? item.retorno.consumoMedio.toFixed(1) : '-',
      item.total.tempo ? item.total.tempo.toFixed(1) : '-',
      item.total.diesel ? item.total.diesel.toFixed(1) : '-',
      item.total.distancia ? item.total.distancia.toFixed(1) : '-',
      item.total.consumoDiesel ? item.total.consumoDiesel.toFixed(1) : '-',
      item.total.consumoMedio ? item.total.consumoMedio.toFixed(1) : '-',
    ]);
  });

  worksheet.columns.forEach((column) => {
    column.width = 20;
  });

  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  saveAs(blob, 'relatorio-tempo-detalhado.xlsx');
};
