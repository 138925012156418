import React from 'react';
import ApexCharts from 'apexcharts';
import { useEffect } from 'react';
import moment from 'moment';
import {
  getDifferenceBetweenDatesInHours,
  getDifferenceBetweenDatesInMinutes,
  renderViagemEstadoTexto,
} from '@app/utils/utils';
import { IChartReport, ITooltipConfig } from './interface/chartReport';
import MouseTracker from '@app/components/common/MouseTracker/MouseTracker';
import { Col, Row } from 'antd';
import { getTooltipConfig } from '@app/pages/modules/Reports/Charts/getTooltipConfig';
import { DetailedTravelInfoConsumoCombustivelModel } from '@app/domain/interfaces/report/TravelInfoDetailsReport';

export const ChartFuelConsumption: React.FC<IChartReport> = ({ travelDetails }) => {
  const [tooltipConfig, setTooltipConfig] = React.useState<ITooltipConfig>({
    visible: false,
    title: '',
    value: '',
  });

  const closestConsumoCombustivel = (
    dataHoraToFind: string | undefined,
    consumos: DetailedTravelInfoConsumoCombustivelModel[],
  ): DetailedTravelInfoConsumoCombustivelModel | undefined => {
    if (!dataHoraToFind || consumos.length === 0) {
      return undefined; // Returns undefined if no valid date or empty array
    }

    return consumos.reduce((closest, current) => {
      const dateToFind = new Date(dataHoraToFind).getTime();
      const currentDate = new Date(current.dataHora!).getTime() ?? 0;
      const closestDate = new Date(closest.dataHora!).getTime() ?? 0;
      return Math.abs(currentDate - dateToFind) < Math.abs(closestDate - dateToFind) ? current : closest;
    });
  };

  useEffect(() => {
    if (travelDetails.consumoCombustivel?.length > 0) {
      const options = {
        chart: {
          id: 'consumo',
          type: 'line',
          group: 'chart',
          width: '100%',
          height: 600,
          toolbar: {
            show: false,
          },
        },
        legend: {
          show: true,
          showForSingleSeries: true,
          position: 'top',
          horizontalAlign: 'right',
          fontSize: '14px',
          fontFamily: 'Mulish',
          fontWeight: 600,
          fontStyle: 'normal',
          markers: {
            width: 24,
            height: 5,
            radius: 0,
          },
        },
        tooltip: {
          style: {
            fontFamily: 'Mulish',
          },
        },
        stroke: {
          curve: 'smooth',
          width: [2, 2, 8],
        },
        colors: ['#C41B24', '#374450'],
        series: [
          {
            type: 'line',
            name: 'Linha 1 - Consumo de combustível (L)',
            data: travelDetails.consumoCombustivel
              ?.filter((u) => u.dataHora !== undefined)
              ?.map((u) => ({ y: u.litros, x: u.dataHora ? new Date(u.dataHora).getTime() : 0 }))
              .sort((a, b) => b.x - a.x),
          },
          // {
          //   type: 'line',
          //   name: 'Tempos da viagem',
          //   data: travelDetails.temposViagemConsumo
          //     ?.map((u) => ({ y: 0, x: u.dataHora ? new Date(u.dataHora).getTime() : 0 }))
          //     .sort((a, b) => b.x - a.x),
          // },
        ],
        yaxis: {
          // min: 0,
          forceNiceScale: true,
          labels: {
            style: {
              fontSize: '16px',
              fontFamily: 'Mulish',
              fontWeight: 600,
            },
            formatter: (value: number) => value?.toFixed(0) + ' L',
          },
        },
        xaxis: {
          type: 'datetime',
          tickAmount: 15,
          labels: {
            datetimeUTC: true,
            formatter: (value: number | number[]) =>
              typeof value == 'number' ? moment(value).format('HH:mm') : moment(value[0]).format('HH:mm'),
          },
          tooltip: {
            enabled: false,
          },
        },
        annotations: {
          yaxis: [
            {
              y: 0,
            },
          ],
          xaxis: travelDetails.estadosViagem
            ?.filter((estadoViagem) => estadoViagem.estadoViagemAtual && estadoViagem.estadoViagemAtual > 2)
            .map((estado, index) => {
              return {
                x: estado.dataHora ? new Date(estado.dataHora).getTime() : 0,
                strokeDashArray: 0,
                borderColor: '#5EB576',
                label: {
                  position:
                    index < travelDetails.alertas.length - 2 &&
                    estado.estadoViagemAtual !== 1 &&
                    getDifferenceBetweenDatesInMinutes(
                      new Date(estado.dataHora ?? 0),
                      new Date(travelDetails.alertas[index + 2]?.dataHora ?? 0),
                    ) <= 5 &&
                    getDifferenceBetweenDatesInHours(
                      new Date(estado.dataHora ?? 0),
                      new Date(travelDetails.alertas[index + 2]?.dataHora ?? 0),
                    ) < 1
                      ? 'top'
                      : 'top',
                  orientation: 'vertical',
                  style: {
                    color: '#707070',
                    background: '#fff',
                    fontSize: '16px',
                    offsetX: 0,
                    offsetY: -30,
                    padding: {
                      left: 20,
                      right: 10,
                      top: 5,
                      bottom: 5,
                    },
                  },
                  text: renderViagemEstadoTexto(estado.estadoViagemAtual ?? 0),
                },
              };
            }),
          points: travelDetails.temposViagemConsumo?.map((tempoViagem, index) => {
            return {
              x: tempoViagem.dataHoraEntreEstados ? new Date(tempoViagem.dataHoraEntreEstados).getTime() : 0,
              y: 0,
              marker: {
                size: 9,
                shape: 'square',
                fillColor: '#374450',
                strokeColor: '#374450',
                tooltip: {
                  enabled: true,
                },
              },
              mouseEnter: () => {
                const tooltipConfig = getTooltipConfig(tempoViagem);
                const selectedConsumoMin = closestConsumoCombustivel(
                  travelDetails.estadosViagem[index + 1]?.dataHora,
                  travelDetails.consumoCombustivel,
                );

                const selectedConsumoMax = closestConsumoCombustivel(
                  travelDetails.estadosViagem[index + 2]?.dataHora,
                  travelDetails.consumoCombustivel,
                );

                setTooltipConfig({
                  ...tooltipConfig,
                  secondTitle: 'Consumo diesel',
                  secondValue:
                    selectedConsumoMin?.litros !== undefined && selectedConsumoMax?.litros !== undefined
                      ? (selectedConsumoMax?.litros - selectedConsumoMin.litros)?.toFixed(2) + ' L'
                      : '-',
                });
              },
              mouseLeave: () => {
                setTooltipConfig({
                  title: '',
                  value: '',
                  visible: false,
                });
              },
            };
          }),
        },
      };
      const chartTemperature = new ApexCharts(document.querySelector('#chart_consumption'), options);
      chartTemperature.render();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!(travelDetails.consumoCombustivel?.length > 0) && (
        <h3 style={{ paddingLeft: '1rem' }}>Nenhum dado disponível para exibição.</h3>
      )}
      {tooltipConfig.visible && (
        <MouseTracker offset={{ x: 0, y: 0 }}>
          <div className="tooltip">
            <Row gutter={[10, 10]}>
              <Col xs={3} md={3} style={{ marginTop: '1rem' }}>
                <span className="square"></span>
              </Col>
              <Col xs={7} md={7}>
                <Col>
                  <span className="text-label">{tooltipConfig.title}</span>
                  <span className="text-value">{tooltipConfig.value}</span>
                </Col>
                <Col>
                  <span className="text-label">{tooltipConfig.secondTitle}</span>
                  <span className="text-value">{tooltipConfig.secondValue}</span>
                </Col>
              </Col>
            </Row>
          </div>
        </MouseTracker>
      )}
      <div id="chart_consumption"></div>
    </>
  );
};
