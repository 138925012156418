import { Badge as AntBadge } from 'antd';
import styled, { css } from 'styled-components';
import { NotificationType } from '../Notification/Notification';

interface BadgeProps {
  severity?: NotificationType;
}

export const Badge = styled(AntBadge)<BadgeProps>`
  & .ant-badge-count {
    ${(props) =>
      !props.severity
        ? css`
            background: transparent;
            color: var(--text-label);
            font-size: 1.125rem;
            font-weight: 600;
          `
        : css`
            background: transparent;
            border-color: transparent !important;
            border-radius: 21px;
            color: var(--background-main-color);
          `}
  }
`;
