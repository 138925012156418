import React, { useCallback, useRef } from 'react';
import { Col, Divider, Row } from 'antd';
import appSettings from '@app/config/appsettings';
import { ReactComponent as Loading } from '@app/assets/icons/loading.svg';
import { ReactComponent as IconAccessTime } from '@app/assets/icons/access_time.svg';
import { LastTransmissionDataModel } from '@app/components/common/SendCommandModal/SendCommandModal';
import { Panel } from '@app/components/common/Collapse/Collapse';
import * as S from './InfoLastTransmission.styles';
import JsonFormatter from 'react-json-formatter';
import { Spinner } from '@app/components/common/SpinSlump/SpinSlump.styles';
import { SpinnerSlump } from '@app/components/common/SpinSlump/SpinSlump';

const jsonStyle = {
  propertyStyle: { color: '#0F162A' },
  stringStyle: { color: '#263668' },
  numberStyle: { color: '#475582' },
};

interface ILastTransmissionProps {
  lastTransmissionData: LastTransmissionDataModel | null;
}

const InfoLastTransmission: React.FC<ILastTransmissionProps> = ({ lastTransmissionData }) => {
  return (
    <>
      {lastTransmissionData === null ? (
        <S.Container>
          <Divider />
          <S.Title>Informações da última transmissão</S.Title>
          <Row align="middle" justify="center">
            <Col>
              <SpinnerSlump size="large" />
            </Col>
          </Row>
          <Row align="middle" justify="center">
            <Col>
              <S.TitleProgress>Aguarde enquanto carregamos as informações da última transmissão</S.TitleProgress>
            </Col>
            <Col>
              <S.ContainerModal>
                <S.WapperModal>
                  <IconAccessTime />
                  <S.TimeModal>Tempo estimado: 20 segundos</S.TimeModal>
                </S.WapperModal>
              </S.ContainerModal>
            </Col>
          </Row>
        </S.Container>
      ) : (
        <S.Container>
          <Divider />
          <S.Title>Informações da última transmissão</S.Title>
          <S.Collapse bordered={false} defaultActiveKey={'JSON'}>
            <Panel header="JSON" key={'JSON'}>
              <JsonFormatter json={lastTransmissionData.payload} tabWith={4} jsonStyle={jsonStyle} />
            </Panel>
          </S.Collapse>
        </S.Container>
      )}
    </>
  );
};

export default InfoLastTransmission;
