import userReducer from '@app/store/slices/userSlice';
import authReducer from '@app/store/slices/authSlice';
import nightModeReducer from '@app/store/slices/nightModeSlice';
import themeReducer from '@app/store/slices/themeSlice';
import pwaReducer from '@app/store/slices/pwaSlice';
import headerReducer from '@app/store/slices/headerSlice';
import headerRegisterReducer from '@app/store/slices/headerRegisterPage';
import footerRegisterReducer from '@app/store/slices/footerPageSlice';
import monitoringReducer from '@app/store/slices/monitoringSlice';
import trackingReducer from '@app/store/slices/trackingSlice';
import reportsReducer from '@app/store/slices/reportsSlice';
import concreteMixerLocationsReducer from '@app/store/slices/concreteMixerLocationsSlice';

export default {
  user: userReducer,
  auth: authReducer,
  nightMode: nightModeReducer,
  theme: themeReducer,
  pwa: pwaReducer,
  header: headerReducer,
  headerRegister: headerRegisterReducer,
  footerRegister: footerRegisterReducer,
  monitoring: monitoringReducer,
  tracking: trackingReducer,
  concreteMixerLocations: concreteMixerLocationsReducer,
  reports: reportsReducer,
};
