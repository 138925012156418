import React from 'react';
import * as S from './Box.styles';

interface IBoxHeader {
  children: React.ReactNode;
}

export const BoxHeader: React.FC<IBoxHeader> = ({ children }) => {
  return (
    <S.CollapseContainer>
      <S.Collapse bordered={false} defaultActiveKey={1}>
        <S.DisplayContainer>{children}</S.DisplayContainer>
      </S.Collapse>
    </S.CollapseContainer>
  );
};
