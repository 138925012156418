import { PageContainer } from '@app/components/common/PageContainer/PageContainer';
import { Spinner } from '@app/components/common/SpinSlump/SpinSlump.styles';
import { notificationController } from '@app/controllers/notificationController';
import { CentralTrackingReportModel } from '@app/domain/interfaces/centralTracking/CentralTrackingModel';
import ICentralTrackingService, { CentralTrackingService } from '@app/services/centralTrackingService';
import { FC, useCallback, useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import * as S from './CentralTrackingPointReport.styles';
import { Col, Row } from 'antd';
import { setHeader } from '@app/store/slices/headerSlice';
import { useDispatch } from 'react-redux';
import { IconFileExcel, IconFilePdf } from '@app/assets/slump-icons';
import { WaterPieChart } from './components/WaterPieChart/WaterPieChart';
import { TableReportCentralTracking } from './components/Table/TableReportCentralTracking';
import moment from 'moment';
import {
  exportCentralTrackingReportToExcel,
  exportCentralTrackingReportToPdf,
} from './functions/central-tracking-functions';
import { readUser } from '@app/services/localStorage.service';

const centralService: ICentralTrackingService = new CentralTrackingService();
const filters = [
  'Todas viagens',
  'Água central menor ou igual 30% da água retida',
  'Água central entre 30%-70% da água retida',
  'Água central maior ou igual que 70% da água retida ',
  'Outros eventos',
];

export const CentralTrackingPointReport: FC = () => {
  const dispatch = useDispatch();
  const { idFilial, pontoDeCarga } = useParams();
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState<boolean>(false);
  const [filterSelected, setFilterSelected] = useState<number>(0);
  const [normalWaterQuantity, setNormalWaterQuantity] = useState<number>(0);
  const [warningWaterQuantity, setWarningWaterQuantity] = useState<number>(0);
  const [dangerWaterQuantity, setDangerWaterQuantity] = useState<number>(0);
  const [errorWaterQuantity, setErrorWaterQuantity] = useState<number>(0);
  const [reportData, setReportData] = useState<CentralTrackingReportModel>();
  const [reportDataFiltered, setReportFilteredData] = useState<CentralTrackingReportModel>();

  const fetch = useCallback(async () => {
    setLoading(true);
    if (idFilial && pontoDeCarga) {
      centralService
        .getChargingPointReport(Number(idFilial), pontoDeCarga)
        .then((res) => {
          setLoading(false);
          setReportData(res);
        })
        .catch(() => {
          setLoading(false);
          notificationController.error({ message: 'Erro ao buscar os dados do relatório' });
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFilterButtonClick = (indexFilterClicked: number) => {
    setFilterSelected(indexFilterClicked);

    switch (indexFilterClicked) {
      case 0:
        setReportFilteredData(reportData);
        break;
      case 1:
        setReportFilteredData({
          ...reportData,
          obras:
            reportData?.obras
              .map((o) => ({
                ...o,
                remessas: o.remessas.filter((r) => !r.finalizadoComErro && (r.aguaUtilizadaPorcentagem ?? 0) <= 30),
              }))
              .filter((o) => o.remessas.length > 0) ?? [],
        });
        break;
      case 2:
        setReportFilteredData({
          ...reportData,
          obras:
            reportData?.obras
              .map((o) => ({
                ...o,
                remessas: o.remessas.filter(
                  (r) =>
                    !r.finalizadoComErro &&
                    (r.aguaUtilizadaPorcentagem ?? 0) > 30 &&
                    (r.aguaUtilizadaPorcentagem ?? 0) < 70,
                ),
              }))
              .filter((o) => o.remessas.length > 0) ?? [],
        });
        break;
      case 3:
        setReportFilteredData({
          ...reportData,
          obras:
            reportData?.obras
              .map((o) => ({
                ...o,
                remessas: o.remessas.filter((r) => !r.finalizadoComErro && (r.aguaUtilizadaPorcentagem ?? 0) > 70),
              }))
              .filter((o) => o.remessas.length > 0) ?? [],
        });
        break;
      case 4:
        setReportFilteredData({
          ...reportData,
          obras:
            reportData?.obras
              .map((o) => ({ ...o, remessas: o.remessas.filter((r) => r.finalizadoComErro) }))
              .filter((o) => o.remessas.length > 0) ?? [],
        });
        break;

      default:
        break;
    }
  };
  const handleExportPdf = () => {
    if (reportDataFiltered)
      exportCentralTrackingReportToPdf(
        {
          regional: searchParams.get('regional') ?? '-',
          filial: searchParams.get('filial') ?? '-',
          dataHora: moment(new Date()).format('DD/MM/yyyy'),
          usuario: readUser()?.username ?? '-',
          pontoCarga: pontoDeCarga ?? '-',
        },
        reportDataFiltered,
      );
  };
  const handleExportExcel = () => {
    if (reportDataFiltered)
      exportCentralTrackingReportToExcel(
        {
          regional: searchParams.get('regional') ?? '-',
          filial: searchParams.get('filial') ?? '-',
          dataHora: moment(new Date()).format('DD/MM/yyyy'),
          usuario: readUser()?.username ?? '-',
          pontoCarga: pontoDeCarga ?? '-',
        },
        reportDataFiltered,
      );
  };

  useEffect(() => {
    setReportFilteredData(reportData);

    let normalCount = 0;
    let warningCount = 0;
    let dangerCount = 0;
    let errorCount = 0;

    reportData?.obras.map((o) => {
      errorCount += o.remessas.filter((r) => r.finalizadoComErro)?.length ?? 0;
      normalCount +=
        o.remessas.filter((r) => !r.finalizadoComErro && (r.aguaUtilizadaPorcentagem ?? 0) <= 30)?.length ?? 0;
      warningCount +=
        o.remessas.filter(
          (r) =>
            !r.finalizadoComErro && (r.aguaUtilizadaPorcentagem ?? 0) > 30 && (r.aguaUtilizadaPorcentagem ?? 0) < 70,
        )?.length ?? 0;
      dangerCount +=
        o.remessas.filter((r) => !r.finalizadoComErro && (r.aguaUtilizadaPorcentagem ?? 0) > 70)?.length ?? 0;
    });

    setNormalWaterQuantity(normalCount);
    setWarningWaterQuantity(warningCount);
    setDangerWaterQuantity(dangerCount);
    setErrorWaterQuantity(errorCount);
  }, [reportData]);

  useEffect(() => {
    dispatch(
      setHeader({
        title: 'Relatório',
      }),
    );
    fetch();
  }, [dispatch, fetch]);

  return (
    <Spinner spinning={loading}>
      <PageContainer>
        <S.CardHeader>
          <Row justify={'space-between'}>
            <Col>
              <S.CardHeaderTitle>Regional</S.CardHeaderTitle>
              <S.CardHeaderDescription>{searchParams.get('regional') ?? '-'}</S.CardHeaderDescription>
            </Col>
            <Col>
              <S.CardHeaderTitle>Filial</S.CardHeaderTitle>
              <S.CardHeaderDescription>{searchParams.get('filial') ?? '-'}</S.CardHeaderDescription>
            </Col>
            <Col>
              <S.CardHeaderTitle>Ponto de Carga</S.CardHeaderTitle>
              <S.CardHeaderDescription>{pontoDeCarga}</S.CardHeaderDescription>
            </Col>
            <Col>
              <S.CardHeaderTitle>Data</S.CardHeaderTitle>
              <S.CardHeaderDescription>{moment(new Date()).format('DD/MM/yyyy')}</S.CardHeaderDescription>
            </Col>
          </Row>
        </S.CardHeader>
        <Row gutter={[18, 18]} style={{ marginTop: '2rem' }}>
          <Col>
            <S.CardHeaderDescription>Filtro</S.CardHeaderDescription>
          </Col>
        </Row>
        <Row gutter={[8, 8]} style={{ marginTop: '0.5rem' }}>
          {filters.map((description, index) => (
            <Col key={index}>
              <S.FilterButton active={filterSelected == index} onClick={() => handleFilterButtonClick(index)}>
                {description}
              </S.FilterButton>
            </Col>
          ))}
        </Row>
        <Row justify={'end'} style={{ width: '100%' }}>
          <Col>
            <S.ExportFileButton onClick={() => handleExportPdf()} type="link" icon={<IconFilePdf />}>
              Exportar para pdf
            </S.ExportFileButton>
          </Col>
          <Col>
            <S.ExportFileButton onClick={() => handleExportExcel()} type="link" icon={<IconFileExcel />}>
              Exportar para excel
            </S.ExportFileButton>
          </Col>
        </Row>
        <S.Divider />
        <WaterPieChart
          handleChartFilter={handleFilterButtonClick}
          normalWaterQuantity={normalWaterQuantity}
          warningWaterQuantity={warningWaterQuantity}
          dangerWaterQuantity={dangerWaterQuantity}
          errorWaterQuantity={errorWaterQuantity}
        />
        <TableReportCentralTracking reportData={reportDataFiltered} />
      </PageContainer>
    </Spinner>
  );
};
