import { ButtonProps as AntButtonProps } from 'antd';
import React from 'react';

import * as S from './Toolbar.styles';

export interface ButtonProps extends AntButtonProps {
  title?: string;
  key?: string;
}

export const Toolbar: React.FC<ButtonProps> = ({ key, title, ...props }) => {
  return (
    <S.ContainerWrapp key={key}>
      <S.Title>{title}</S.Title>
      <S.Container>{props.children}</S.Container>
    </S.ContainerWrapp>
  );
};
