import React, { useEffect, useRef } from 'react';
import { Button } from '@app/components/common/buttons/Button/Button';
import { RequireFullscreen } from '@app/components/common/RequireFullscreen/RequireFullscreen';
import { HeaderActionWrapper } from '../../Header.styles';
import { IconArrowClose, IconArrowExpand } from '@app/assets/slump-icons';

export const HeaderFullscreen: React.FC = () => {
  const rootRef = useRef<HTMLElement | null>(null);

  useEffect(() => {
    rootRef.current = document.getElementById('root');
  }, []);

  return (
    <RequireFullscreen component={rootRef}>
      {(isFullscreen) => (
        <HeaderActionWrapper>
          <Button
            style={{ color: '#263668' }}
            type="text"
            icon={isFullscreen ? <IconArrowClose /> : <IconArrowExpand />}
          />
        </HeaderActionWrapper>
      )}
    </RequireFullscreen>
  );
};
