/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { CardMonitoring } from '@app/components/common/CardMonitoring/CardMonitoring';
import { Spinner } from '@app/components/common/Spinner/Spinner';
import appSettings from '@app/config/appsettings';
import env from '@app/config/env.config';
import { notificationController } from '@app/controllers/notificationController';
import { BranchMonitoringModel } from '@app/domain/interfaces/monitoring/branchMonitoringModel';
import { FooterMonitoringModel } from '@app/domain/interfaces/monitoring/footerMonitoringModels';
import { HeaderMonitoringModel } from '@app/domain/interfaces/monitoring/headerMonitoringModel';
import { LogMonitoringModel } from '@app/domain/interfaces/monitoring/logMonitoringModel';
import { useAppSelector } from '@app/hooks/reduxHooks';
import IChargePointService, { ChargePointService } from '@app/services/chargePointService';
import { setFooter, setHeaderBranchsMonitoring, setHeaderInfoMonitoring } from '@app/store/slices/monitoringSlice';
import { HttpTransportType, HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { Col, Row } from 'antd';
import { motion } from 'framer-motion';
import { shuffle, toNumber } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';

const chargePointService: IChargePointService = new ChargePointService();

const transitionSpring = {
  type: 'spring',
  damping: 25,
  stiffness: 120,
};

const MonitoringDashboard: React.FC = () => {
  const dispatch = useDispatch();
  const [logs, setLogs] = useState<LogMonitoringModel[]>([]);
  const [sizeColumn, setSizeColumn] = useState(6);
  const [connection, setConnection] = useState<HubConnection | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const { branchMonitoringSelected } = useAppSelector((state) => state.monitoring);

  useEffect(() => {
    sessionStorage.setItem('filial', '0');

    const newConnection = new HubConnectionBuilder()
      .withUrl(`${appSettings().APIs.Monitoramento.UrlBase}/monitoramentohub`, {
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets,
      })
      .withAutomaticReconnect()
      .build();

    setConnection(newConnection);
    fetchChargePoints();

    dispatch(
      setHeaderInfoMonitoring({
        agua: '-',
        inspecao: '-',
        produtividade: '-',
        barraProgresso: 0,
      }),
    );
  }, []);

  useEffect(() => {
    if (connection) {
      connection
        .start()
        .then((result) => {
          connection.on('connected', (monitoringReceived: LogMonitoringModel[]) => {
            if (env.ENVIRONMENT === 'dev') {
              console.log('connected', monitoringReceived);
            }
          });

          connection.on('monitoramento', (monitoringReceived: any) => {
            handleSetMonitorings(monitoringReceived.monitoramentos);
          });

          connection.on('monitoramentos', (monitoringReceived: any[]) => {
            monitoringReceived.forEach((m) => {
              handleSetMonitorings(m.monitoramentos);
            });
          });

          connection.on('rodape', (footer: FooterMonitoringModel) => {
            const filial = sessionStorage.getItem('filial');

            if (footer.idFilial.toString() == filial) {
              dispatch(setFooter(footer));
            }
          });

          connection.on('cabecalho', (header: HeaderMonitoringModel) => {
            const idFilial = sessionStorage.getItem('filial');

            if (header.idFilial.toString() == idFilial) {
              dispatch(setHeaderInfoMonitoring(header));
            }
          });
        })
        .catch((e: Error) => console.error('Connection failed: ', e));
    }
  }, [connection]);

  useEffect(() => {
    if (branchMonitoringSelected.idFilial > 0) {
      fetchMonitoring();
      fetchBranchData();
      fetchLoadPointData();
      sessionStorage.setItem('filial', branchMonitoringSelected.idFilial.toString());
    }
  }, [branchMonitoringSelected]);

  const fetchMonitoring = async () => {
    setLoading(true);
    fetch(`${appSettings().APIs.Monitoramento.UrlBase}/monitoramento/${branchMonitoringSelected.idFilial}`, {
      method: 'GET',
    })
      .then((data) => data.json())
      .then((json: any[]) => {
        const newLogs: LogMonitoringModel[] = [];
        json.forEach((j: any) => {
          newLogs.push(...j.monitoramentos);
        });

        setLogs(newLogs);
        sessionStorage.setItem('monitoramentos', JSON.stringify(newLogs));
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  };

  const fetchChargePoints = () => {
    setLoading(true);
    chargePointService
      .getActivesByClient()
      .then((res) => {
        dispatch(
          setHeaderBranchsMonitoring(
            res.map((data) => ({
              idRegional: data.idRegional,
              regional: data.regional,
              filiais: data.filiais.map((f) => ({
                idFilial: f.idFilial,
                filial: f.filial,
                pontosDeCarga: f.pontosDeCarga.map((p) => ({
                  id: p.idPontoCarga,
                  idFilial: p.idFilial,
                  idPontoCarga: p.idPontoCarga,
                  descricao: p.descricao,
                  ativo: p.ativo,
                  centroCustoId: p.centroCustoId,
                  latitude: p.latitude,
                  longitude: p.longitude,
                  nomePontoCarga: p.nomePontoCarga,
                  raio: p.raio,
                })),
              })),
            })),
          ),
        );
        setLoading(false);
      })
      .catch((error) => {
        notificationController.error(error);
        setLoading(false);
      });
  };

  const fetchBranchData = async () => {
    setLoading(true);
    fetch(`${appSettings().APIs.Monitoramento.UrlBase}/monitoramento/obterRodape`, {
      method: 'POST',
      body: JSON.stringify({
        idFilial: branchMonitoringSelected.idFilial,
      }),
      headers: new Headers({
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }),
    })
      .then((data) => data.json())
      .then((json) => {
        if (json.rodape !== null) {
          dispatch(setFooter(json.rodape));
        }
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  };

  const fetchLoadPointData = async () => {
    setLoading(true);
    fetch(`${appSettings().APIs.Monitoramento.UrlBase}/monitoramento/obterCabecalho`, {
      method: 'POST',
      body: JSON.stringify({
        idFilial: branchMonitoringSelected.idFilial,
      }),
      headers: new Headers({
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }),
    })
      .then((data) => data.json())
      .then((json) => {
        if (json !== null) {
          dispatch(setHeaderInfoMonitoring(json));
        }
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  };

  const handleSetMonitorings = (monitoring: LogMonitoringModel[]) => {
    const filial = sessionStorage.getItem('filial') ?? '0';
    const monitorings = monitoring.filter((m) => m.idFilial.toString() == filial);
    if (monitorings.length > 0) {
      setLogs(monitorings);
    }
  };

  const onAnimatedEnd = (id: string) => {
    if (logs) {
      setLogs(
        logs.map((l, i) => {
          if (l.id === id) {
            return { ...l, onAnimate: false };
          } else {
            return l;
          }
        }),
      );
    }
  };

  return (
    <>
      <PageTitle>Monitoramento</PageTitle>
      {logs === null ? (
        <div>Nenhum ponto de carga selecionado...</div>
      ) : (
        <Spinner spinning={loading}>
          <Row gutter={[16, 16]}>
            {logs.map((log, index) => {
              if (!log.finalizada) {
                return (
                  <Col key={log.id} xs={24} md={12} xl={sizeColumn}>
                    <motion.div layout transition={transitionSpring} style={{ height: '100%' }}>
                      <CardMonitoring
                        size="small"
                        priority={index + 1}
                        status={log.obraStatus}
                        logMonitoring={log}
                        onAnimatedEnd={() => onAnimatedEnd(log.id)}
                      />
                    </motion.div>
                  </Col>
                );
              }
            })}
          </Row>
        </Spinner>
      )}
    </>
  );
};

export default MonitoringDashboard;
