import React from 'react';
import ApexCharts from 'apexcharts';
import { useEffect } from 'react';
import moment from 'moment';
import {
  formatMinutesToHoursAndMinutes,
  getDifferenceBetweenDatesInHours,
  getDifferenceBetweenDatesInMinutes,
  renderViagemEstadoTexto,
} from '@app/utils/utils';
import { IChartReport, ITooltipConfig } from './interface/chartReport';
import MouseTracker from '@app/components/common/MouseTracker/MouseTracker';
import { getTooltipConfig } from '@app/pages/modules/Reports/Charts/getTooltipConfig';

export const ChartEngineRotation: React.FC<IChartReport> = ({ travelDetails }) => {
  const [tooltipConfig, setTooltipConfig] = React.useState<ITooltipConfig>({
    visible: false,
    title: '',
    value: '',
  });

  useEffect(() => {
    if (travelDetails.velocidadeMotor?.length > 0) {
      const options = {
        chart: {
          id: 'water',
          type: 'line',
          group: 'chart',
          width: '100%',
          height: 600,
          toolbar: {
            show: false,
          },
        },
        legend: {
          show: true,
          showForSingleSeries: true,
          position: 'top',
          horizontalAlign: 'right',
          fontSize: '14px',
          fontFamily: 'Mulish',
          fontWeight: 600,
          fontStyle: 'normal',
          markers: {
            width: 24,
            height: 5,
            radius: 0,
          },
        },
        tooltip: {
          style: {
            fontFamily: 'Mulish',
          },
        },
        stroke: {
          curve: 'smooth',
          width: [2, 2, 8],
        },
        colors: ['#0072DC', '#C41B24', '#374450'],
        series: [
          {
            type: 'line',
            name: 'Linha 1 - Rotação do motor (rpm)',
            data: travelDetails.velocidadeMotor
              ?.filter((u) => u.dataHora !== undefined)
              ?.map((u) => ({ y: u.velocidadeMotor, x: u.dataHora ? new Date(u.dataHora).getTime() : 0 }))
              .sort((a, b) => b.x - a.x),
          },
          // {
          //   type: 'line',
          //   name: 'Tempos da viagem',
          //   data: travelDetails.temposViagemConsumo
          //     ?.map((u) => ({ y: 0, x: u.dataHora ? new Date(u.dataHora).getTime() : 0 }))
          //     .sort((a, b) => b.x - a.x),
          // },
        ],
        yaxis: {
          labels: {
            style: {
              fontSize: '16px',
              fontFamily: 'Mulish',
              fontWeight: 600,
            },
            formatter: (value: number) => value?.toFixed(1) + ' rpm',
          },
        },
        xaxis: {
          type: 'datetime',
          tickAmount: 15,
          labels: {
            datetimeUTC: true,
            formatter: (value: number | number[]) =>
              typeof value == 'number' ? moment(value).format('HH:mm') : moment(value[0]).format('HH:mm'),
          },
          tooltip: {
            enabled: false,
          },
        },
        annotations: {
          yaxis: [
            {
              y: travelDetails.velocidadeMotorIdeal?.velocidadeMotorIdealMinima ?? 1000,
              y2: travelDetails.velocidadeMotorIdeal?.velocidadeMotorIdealMaxima ?? 1500,
              strokeDashArray: 0,
              borderWidth: 50,
              borderColor: '#023611',
              fillColor: '#117e2f26',
              label: {
                style: {
                  fontSize: '10px',
                  fontFamily: 'Mulish',
                  color: '#333',
                  background: '#117e2f26',
                },
                text: '',
              },
            },
          ],
          xaxis: travelDetails.estadosViagem
            ?.filter((estadoViagem) => estadoViagem.estadoViagemAtual && estadoViagem.estadoViagemAtual > 2)
            .map((estado, index) => {
              return {
                x: estado.dataHora ? new Date(estado.dataHora).getTime() : 0,
                strokeDashArray: 0,
                borderColor: '#5EB576',
                label: {
                  position:
                    index < travelDetails.alertas.length - 2 &&
                    estado.estadoViagemAtual !== 1 &&
                    getDifferenceBetweenDatesInMinutes(
                      new Date(estado.dataHora ?? 0),
                      new Date(travelDetails.alertas[index + 2]?.dataHora ?? 0),
                    ) <= 5 &&
                    getDifferenceBetweenDatesInHours(
                      new Date(estado.dataHora ?? 0),
                      new Date(travelDetails.alertas[index + 2]?.dataHora ?? 0),
                    ) < 1
                      ? 'top'
                      : 'top',
                  orientation: 'vertical',
                  style: {
                    color: '#707070',
                    background: '#fff',
                    fontSize: '16px',
                    offsetX: 0,
                    offsetY: -30,
                    padding: {
                      left: 20,
                      right: 10,
                      top: 5,
                      bottom: 5,
                    },
                  },
                  text: renderViagemEstadoTexto(estado.estadoViagemAtual ?? 0),
                },
              };
            }),
          points: travelDetails.temposViagemConsumo?.map((tempoViagem) => {
            return {
              x: tempoViagem.dataHoraEntreEstados ? new Date(tempoViagem.dataHoraEntreEstados).getTime() : 0,
              y: 0,
              marker: {
                size: 9,
                shape: 'square',
                fillColor: '#374450',
                strokeColor: '#374450',
                tooltip: {
                  enabled: true,
                },
              },
              mouseEnter: () => {
                setTooltipConfig(getTooltipConfig(tempoViagem));
              },
              mouseLeave: () => {
                setTooltipConfig({
                  title: '',
                  value: '',
                  visible: false,
                });
              },
            };
          }),
        },
      };
      const chartTemperature = new ApexCharts(document.querySelector('#chart_engine_rotation'), options);
      chartTemperature.render();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!(travelDetails.velocidadeMotor?.length > 0) && (
        <h3 style={{ paddingLeft: '1rem' }}>Nenhum dado disponível para exibição.</h3>
      )}
      {tooltipConfig.visible && (
        <MouseTracker offset={{ x: 0, y: 0 }}>
          <div className="tooltip">
            <span className="square"></span>
            <span className="text-label">{tooltipConfig.title}</span>
            <span className="text-value">{tooltipConfig.value}</span>
          </div>
        </MouseTracker>
      )}
      <div id="chart_engine_rotation">
        <div>
          <label style={{ marginRight: '1rem' }}>
            Tempo de percurso{' '}
            <strong>
              {travelDetails.velocidadeMotor.length > 1
                ? formatMinutesToHoursAndMinutes(
                    getDifferenceBetweenDatesInMinutes(
                      new Date(travelDetails.velocidadeMotor[0].dataHora ?? 0),
                      new Date(travelDetails.velocidadeMotor[travelDetails.velocidadeMotor.length - 1].dataHora ?? 0),
                    ),
                  ) + ' '
                : ' - '}
            </strong>
          </label>
          <label>
            Percentual na faixa verde{' '}
            <strong>{travelDetails.velocidadeMotorIdeal.porcentagemDentroIdeal + ' %'}</strong>
          </label>
        </div>
      </div>
    </>
  );
};
