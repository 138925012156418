import React from 'react';
import * as S from './TrackingHeader.styles';
import { Col, Divider, Menu, Row } from 'antd';
import DropdownTable from '@app/components/dropdown/DropdownTable';
import { DividerT } from '@app/components/common/divider/Divider';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { useDispatch } from 'react-redux';
import {
  setHeaderBranchSelected,
  setHeaderGroupSelected,
  setHeaderGroupsTracking,
  setHeaderBranchsTracking,
} from '@app/store/slices/trackingSlice';
import { DividerTitle } from '@app/components/common/divider/Divider.styles';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as DownIcon } from '@app/assets/icons/downIcon.svg';
import { ReactComponent as ClockIcon } from '@app/assets/icons/clockIcon.svg';
import MenuItem from 'antd/lib/menu/MenuItem';
import { Button } from '@app/components/common/buttons/Button/Button';
import Clock from '@app/components/common/Clock/Clock';
import { HeaderFullscreen } from '@app/components/header/components/HeaderFullscreen/HeaderFullscreen';
import IGroupService, { GroupService } from '@app/services/groupService';
import IClientBranchService, { ClientBranchService } from '@app/services/clientBranchService';
import { IconMonitoring, IconReportChart } from '@app/assets/slump-icons';

interface TrackingHeaderProps {
  responsiveColumnsLayout: boolean;
}
interface TrackingHeaderProps {
  forceCollapse: boolean;
  setForceCollapse: (isCollapsed: boolean) => void;
  setHiddenSider: Dispatch<SetStateAction<boolean>>;
  hiddenSider: boolean;
}

const groupService: IGroupService = new GroupService();
const branchService: IClientBranchService = new ClientBranchService();

export const TrackingHeader: React.FC<TrackingHeaderProps> = ({
  forceCollapse,
  setForceCollapse,
  responsiveColumnsLayout,
  setHiddenSider,
  hiddenSider,
}) => {
  const dispatch = useDispatch();
  const handlerClickFullScreen = () => {
    setForceCollapse(!!forceCollapse);
    setHiddenSider(!hiddenSider);
  };
  const navigate = useNavigate();
  const headerBranchsTracking = useAppSelector((state) => state.tracking.branchsTracking);
  const headerBranchSelected = useAppSelector((state) => state.tracking.branchTrackingSelected);
  const headerGroupSelected = useAppSelector((state) => state.tracking.groupTrackingSelected);
  const headerGroupsTracking = useAppSelector((state) => state.tracking.groupsTracking);
  const [filterBranchs, setFilterBranchs] = useState(headerBranchsTracking);

  const handlerBranchChange = (id: string) =>
    dispatch(setHeaderBranchSelected(headerBranchsTracking.find((b) => b.id === id)));

  useEffect(() => {
    fetchCombosData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeGroup = (id: string) => {
    dispatch(setHeaderGroupSelected(headerGroupsTracking.find((b) => b.id === id)));
    setFilterBranchs(headerBranchsTracking.filter((cb) => cb.agrupamentos.idAgrupamento == Number.parseInt(id)));
  };

  const fetchCombosData = async () => {
    try {
      const groupIds: number[] = [];
      const resBranches = await branchService.getArray('');
      const resGroups = await groupService.getArray('');

      resBranches?.filter((cb) => cb.codigoFilialSiac).map((cb) => groupIds.push(cb.agrupamentos.idAgrupamento));
      dispatch(setHeaderGroupsTracking(resGroups.filter((g) => groupIds.includes(g.id))));

      dispatch(setHeaderBranchsTracking(resBranches));
    } catch (error) {}
  };

  return (
    <>
      <Row
        justify="space-between"
        align="middle"
        style={{ background: 'white', height: '3rem', padding: '0.5rem 2.5rem 0rem 1rem', marginBottom: '1.75rem' }}
      >
        <Col style={{ margin: 0 }}>
          <S.PageTitle>Acompanhamento da central</S.PageTitle>
        </Col>
        <S.ConfigColumn $responsiveColumnsLayout={responsiveColumnsLayout}>
          <Row align="middle" justify="end" gutter={[{ xxl: 8 }, { xxl: 4 }]} style={{}}>
            <S.DropdownHeader>
              <DropdownTable
                buttonText={!headerGroupSelected.nome ? 'Selecione a regional' : headerGroupSelected.nome}
                iconD={<DownIcon width={12} />}
                trigger={['click']}
                placement="bottomRight"
                overlay={
                  <Menu>
                    {headerGroupsTracking?.map((group) => (
                      <MenuItem key={group.id} onClick={() => handleChangeGroup(group.id)}>
                        <Button type="text">{group.nome}</Button>
                      </MenuItem>
                    ))}
                  </Menu>
                }
              />

              <DividerT />
              <DropdownTable
                buttonText={!headerBranchSelected.nome ? 'Selecione o ponto de carga' : headerBranchSelected.nome}
                iconD={<DownIcon width={12} />}
                trigger={['click']}
                placement="bottomRight"
                overlay={
                  <Menu>
                    {filterBranchs.map((cb) => (
                      <MenuItem key={cb.id} onClick={() => handlerBranchChange(cb.id)}>
                        <Button type="text">{cb.nome}</Button>
                      </MenuItem>
                    ))}
                  </Menu>
                }
              />
            </S.DropdownHeader>
            <DividerT />
            <ClockIcon style={{ marginRight: '0.925rem' }} />
            <DividerTitle>
              <Clock />
            </DividerTitle>
            {/* <DividerT
              icon={
                <div
                  onClick={() => navigate('/monitoramento/betoneiras')}
                  style={{ color: '#263668', fontSize: '1.75rem', cursor: 'pointer' }}
                >
                  <IconReportChart />
                </div>
              }
            /> */}
            <DividerT
              icon={
                <div
                  onClick={() => navigate('/monitoramento')}
                  style={{ color: '#263668', fontSize: '32px', cursor: 'pointer' }}
                >
                  <IconMonitoring />
                </div>
              }
            />

            <DividerT
              icon={
                <div onClick={handlerClickFullScreen}>
                  <HeaderFullscreen />
                </div>
              }
            />
          </Row>
        </S.ConfigColumn>
      </Row>
      <Row style={{ borderBottom: '1px solid #D1D1D1', width: '100%' }}></Row>
    </>
  );
};
