import { TagProps } from 'antd';
import React from 'react';
import * as S from './Tag.styles';

export interface ITag extends TagProps {
  title?: string;
  bgColor?: string;
  textColor?: string;
}

export const Tag: React.FC<ITag> = (props) => {
  return <S.TagWrapper {...props}>{props.children}</S.TagWrapper>;
};
