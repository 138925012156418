import * as React from 'react';
const SvgIconMonitoring = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path
      fill="current"
      d="M4.93185 6.76554L4.92735 6.76104L5.70583 5.76432C5.72029 5.74568 5.73828 5.73007 5.75878 5.7184C5.77928 5.70672 5.80188 5.6992 5.82529 5.69628C5.8487 5.69335 5.87245 5.69507 5.8952 5.70134C5.91794 5.70761 5.93922 5.71831 5.95782 5.73282L7.09179 6.61704L8.67799 4.58986C8.69245 4.57121 8.71044 4.55561 8.73094 4.54393C8.75144 4.53226 8.77404 4.52474 8.79745 4.52181C8.82086 4.51889 8.84461 4.52061 8.86736 4.52688C8.8901 4.53315 8.91138 4.54385 8.92998 4.55836L9.78271 5.22659C9.80135 5.24104 9.81695 5.25904 9.82863 5.27954C9.84031 5.30004 9.84782 5.32264 9.85075 5.34605C9.85368 5.36945 9.85195 5.39321 9.84568 5.41595C9.83941 5.4387 9.82872 5.45998 9.81421 5.47858L8.228 7.50802L8.2325 7.51026L7.45403 8.50698C7.39328 8.58573 7.28078 8.59923 7.20203 8.53848L6.06807 7.65426L4.62586 9.4992C4.56511 9.57795 4.45262 9.59145 4.37387 9.5307L3.52114 8.86472C3.4424 8.80398 3.4289 8.69148 3.48965 8.61273L4.93185 6.76554ZM6.8398 0C10.6174 0 13.6796 3.06215 13.6796 6.83979C13.6796 8.42149 13.1419 9.87719 12.2419 11.0359L17.9477 16.7417C17.9812 16.7756 18 16.8212 18 16.8688C18 16.9165 17.9812 16.9621 17.9477 16.996L16.996 17.9477C16.9262 18.0174 16.8115 18.0174 16.7417 17.9477L11.0359 12.2419C9.8772 13.1418 8.4215 13.6796 6.8398 13.6796C3.06216 13.6796 0 10.6174 0 6.83979C0 3.06215 3.06216 0 6.8398 0ZM3.2129 10.4667C4.18038 11.4364 5.46959 11.9696 6.8398 11.9696C8.21 11.9696 9.49922 11.4364 10.4667 10.4667C11.4364 9.4992 11.9696 8.20999 11.9696 6.83979C11.9696 5.46958 11.4364 4.18037 10.4667 3.2129C9.49922 2.24318 8.21 1.70995 6.8398 1.70995C5.46959 1.70995 4.18038 2.24318 3.2129 3.2129C2.24318 4.18037 1.70995 5.46958 1.70995 6.83979C1.70995 8.20999 2.24318 9.4992 3.2129 10.4667Z"
    />
  </svg>
);
export default SvgIconMonitoring;
