import styled from 'styled-components';
import { Avatar } from '../../Avatar/Avatar';

export const CardProfileContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
`;

export const CardProfileWrap = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: start;
  align-items: flex-start;
  margin-right: 6rem;
`;

export const CardProfileName = styled.span`
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
  display: flex;
  align-items: center;
  color: #383838;
`;

export const CardProfileEmail = styled.span`
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.5rem;
  display: flex;
  align-items: center;
  color: #8c8c8c;
`;

export const AvatarSlump = styled(Avatar)`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0.5px solid #a9b3d2;
  margin-right: 1rem;
`;
