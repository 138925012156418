import React from 'react';
import * as S from './CentralContainer.styles';
import { ReactComponent as IconDashboard } from '@app/assets/icons/dashboardIcon.svg';
import { Button } from '@app/components/common/buttons/Button/Button';

export interface DescriptionProps {
  title?: string;
  onClickReport?: () => void;
  children?: React.ReactNode;
}

export const CentralContainer: React.FC<DescriptionProps> = ({ title, onClickReport, children }) => {
  return (
    <>
      <S.Container>
        <S.ContainerWrapper>
          <S.ContainerkWrap>
            <S.Title>{title}</S.Title>
            <Button type="link" onClick={onClickReport}>
              <IconDashboard />
            </Button>
          </S.ContainerkWrap>
          {children}
        </S.ContainerWrapper>
      </S.Container>
    </>
  );
};
