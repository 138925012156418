import React from 'react';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { ForgotPasswordSuccessForm } from '@app/components/auth/ForgotPasswordSuccessForm/ForgotPasswordSuccessForm';

const ForgotPasswordSuccessPage: React.FC = () => {
  return (
    <>
      <PageTitle>E-mail enviado</PageTitle>
      <ForgotPasswordSuccessForm />
    </>
  );
};

export default ForgotPasswordSuccessPage;
