import { InfoCircleOutlined } from '@ant-design/icons';
import { Checkbox } from '@app/components/common/Checkbox/Checkbox';
import { Description } from '@app/components/common/Description/Description';
import { Modal } from '@app/components/common/Modal/Modal';
import { PageContainer } from '@app/components/common/PageContainer/PageContainer';
import { Radio, RadioGroup } from '@app/components/common/Radio/Radio';
import RowCollpasable from '@app/components/common/RowCollapsable/RowCollpasable';
import { SpinnerSlump } from '@app/components/common/SpinSlump/SpinSlump';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { BaseFormInputItem } from '@app/components/common/forms/components/BaseFormInputItem/BaseFormInputItem';
import { Input } from '@app/components/common/inputs/Input/Input.styles';
import { IconsByGroupProfile } from '@app/constants/iconsByGroupProfile';
import { hasAccessByPermission } from '@app/controllers/accessController';
import { notificationController } from '@app/controllers/notificationController';
import { ModuleModel } from '@app/domain/interfaces/profile/moduleModel';
import { PermissionModel } from '@app/domain/interfaces/profile/permissionModel';
import { ProfileModel } from '@app/domain/interfaces/profile/profileModel';
import { ClaimModel } from '@app/domain/interfaces/profile/claimModel';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import IProfileService, { ProfileService } from '@app/services/profileService';
import { setFooter } from '@app/store/slices/footerPageSlice';
import { setHeaderRegisterPage } from '@app/store/slices/headerRegisterPage';
import { groupByKey } from '@app/utils/utils';
import { Col, Row } from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Select } from '@app/components/common/selects/Select/Select';
import { isUserMaster, readUserFilter } from '@app/services/localStorage.service';
import { ClientModel } from '@app/domain/interfaces/clientModel';
import IClientService, { ClientService } from '@app/services/clientService';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';

const profileService: IProfileService = new ProfileService();
const clientService: IClientService = new ClientService();
interface GroupModule {
  name: string;
  modules: ModuleModel[];
}

const ProfileCreate: React.FC = () => {
  const { id, view } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [selectedAll, setSelectedAll] = useState(false);
  const [clients, setClients] = useState<ClientModel[]>([]);
  const [groupModules, setGroupModules] = useState<GroupModule[]>([]);
  const [profile, setProfile] = useState<ProfileModel>({ isDeleted: false } as ProfileModel);
  const [modalCancelVisible, setModalCancelVisible] = useState(false);

  const handleSaveProfile = () => {
    setLoading(true);

    if (!id) {
      profileService
        .post(``, profile)
        .then(() => {
          setTimeout(() => {
            navigate('/gestao/perfis');
            notificationController.success({
              message: `Perfil criado com sucesso${
                profile.isDeleted ? '!' : ', aguarde o processamento das permissões!'
              }`,
            });
          }, 1700);
        })
        .catch((error) => {
          notificationController.error(error);
          setLoading(false);
        });
    } else {
      profileService
        .update(``, profile)
        .then(() => {
          setTimeout(() => {
            navigate('/gestao/perfis');
            notificationController.success({
              message: `Perfil atualizado com sucesso${
                profile.isDeleted ? '!' : ', aguarde o processamento das permissões!'
              }`,
            });
          }, 1700);
        })
        .catch((error) => {
          notificationController.error(error);
          setLoading(false);
        });
    }
  };
  const handleStatusOnChange = () => setProfile({ ...profile, isDeleted: !profile.isDeleted });
  const handleChangeProfile = (e: React.ChangeEvent<HTMLInputElement>) => {
    setProfile({ ...profile, [e.target.name]: e.target.value });
  };
  const handleFormatModules = (modulesToFormat: ModuleModel[]) => {
    const resModules = modulesToFormat
      .filter((m) => m.nomeLogico !== 'client')
      .sort((a, b) => a.idGrupoModulo - b.idGrupoModulo);

    const groupModules = groupByKey(resModules, 'nomeGrupoModulo');

    return Object.keys(groupModules).map((value) => ({
      name: value,
      modules: groupModules[value],
    }));
  };

  //checkbox handlers
  const handleCheckAll = (checked: boolean) => {
    setSelectedAll(checked);

    if (!checked) {
      setProfile({ ...profile, claims: [] });
      return;
    }

    let allClaims: ClaimModel[] = [];

    groupModules.map((group) => {
      group.modules.map((module) => {
        const claims = module.children
          .map((c) => {
            const hasPermission = profile?.claims?.find(
              (p) => p?.claimType === c?.nomeLogicoModulo && p?.claimValue === c?.valor,
            );
            if (!hasPermission) {
              return {
                roleId: profile.idIdentityServer,
                claimType: c.nomeLogicoModulo,
                claimValue: c.valor,
              } as ClaimModel;
            }
          })
          .filter((claim) => claim);

        allClaims = allClaims.concat(claims as ClaimModel[]);
      });
    });

    setProfile({ ...profile, claims: [...(profile?.claims ?? []), ...allClaims] });
  };
  const handleCheckModuleAll = (checked: boolean, module: ModuleModel) => {
    if (checked) {
      const claims = module.children
        .map((c) => {
          const hasPermission = profile?.claims?.find(
            (p) => p?.claimType === c?.nomeLogicoModulo && p?.claimValue === c?.valor,
          );
          if (!hasPermission) {
            return {
              roleId: profile.idIdentityServer,
              claimType: c.nomeLogicoModulo,
              claimValue: c.valor,
            } as ClaimModel;
          }
        })
        .filter((claim) => claim);

      setProfile({ ...profile, claims: [...(profile?.claims ?? []), ...(claims as ClaimModel[])] });
    } else {
      setProfile({ ...profile, claims: profile?.claims?.filter((c) => c?.claimType !== module.nomeLogico) });
    }
  };
  const handleCheckItem = (checked: boolean, permission: PermissionModel) => {
    if (checked) {
      setProfile({
        ...profile,
        claims: [
          ...(profile?.claims ?? []),
          {
            roleId: profile.idIdentityServer,
            claimType: permission.nomeLogicoModulo,
            claimValue: permission.valor,
          } as ClaimModel,
        ],
      });
    } else {
      setProfile({
        ...profile,
        claims: profile?.claims?.filter(
          (c) => c?.claimType !== permission.nomeLogicoModulo && c?.claimValue !== permission.valor,
        ),
      });
    }
  };

  //checkbox renders
  const renderCheckboxAll = (module: ModuleModel): JSX.Element => {
    const selecteds = profile?.claims?.filter((claim) => claim?.claimType === module?.nomeLogico);

    if (!selecteds?.length) {
      return (
        <Checkbox checked={false} value={module.nomeLogico} onChange={() => handleCheckModuleAll(true, module)}>
          Selecionar todas as permissões
        </Checkbox>
      );
    } else if (selecteds?.length >= module.children.length) {
      return (
        <Checkbox checked={true} value={module.nomeLogico} onChange={() => handleCheckModuleAll(false, module)}>
          Selecionar todas as permissões
        </Checkbox>
      );
    } else {
      return (
        <Checkbox indeterminate={true} value={module.nomeLogico} onChange={() => handleCheckModuleAll(true, module)}>
          Selecionar todas as permissões
        </Checkbox>
      );
    }
  };
  const renderCheckboxItem = (permission: PermissionModel): JSX.Element => {
    const hasPermission = profile?.claims?.find(
      (c) => c?.claimType === permission.nomeLogicoModulo && c?.claimValue === permission.valor,
    );

    if (hasPermission) {
      return (
        <Checkbox
          checked={true}
          value={permission.nomeLogicoModulo}
          onChange={() => handleCheckItem(false, permission)}
        >
          {permission.nome}
        </Checkbox>
      );
    } else {
      return (
        <Checkbox
          checked={false}
          value={permission.nomeLogicoModulo}
          onChange={() => handleCheckItem(true, permission)}
        >
          {permission.nome}
        </Checkbox>
      );
    }
  };

  const fetchData = async () => {
    setLoading(true);

    if (id) {
      await profileService
        .get(`${id}`)
        .then((res) => {
          setProfile(res);
        })
        .catch((error) => {
          notificationController.error(error);
        });
    }

    await clientService
      .getArray('obter')
      .then((res: ClientModel[]) => {
        setClients(res);
      })
      .catch((error) => {
        notificationController.error(error);
      });

    await profileService.api
      .get('modulos')
      .then((res) => {
        setGroupModules(handleFormatModules(res.data));
      })
      .catch((error) => {
        notificationController.error(error);
      });

    setLoading(false);
  };

  useEffect(() => {
    if ((id && !hasAccessByPermission('profile', 'U')) || (!id && !hasAccessByPermission('profile', 'C'))) {
      notificationController.error({ message: 'Acesso proibído' });
      navigate('/gestao/perfis');
    }

    const filter = readUserFilter();

    if (filter.client) {
      setProfile((prevState) => ({ ...prevState, idCliente: filter.client!.id }));
    }

    dispatch(
      setHeaderRegisterPage({
        title: `${id ? 'Editar' : 'Novo'} Perfil`,
        handleBackClick: () => navigate('/gestao/perfis'),
      }),
    );

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(
      setFooter({
        confirmButtonDisabled: !profile.descricao || !profile.idCliente || view === 'visualizar',
        confirmButtonText: 'Salvar',
        cancelButtonText: 'Cancelar',
        handleConfirmButtonClick: handleSaveProfile,
        handleCancelButtonClick: () => setModalCancelVisible(true),
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);

  return (
    <>
      <PageTitle>Perfil</PageTitle>
      <Modal
        title={`Cancelar edição do perfil`}
        visible={modalCancelVisible}
        onOk={() => navigate('/gestao/perfis')}
        onCancel={() => setModalCancelVisible(false)}
        cancelText="Cancelar"
        okText="Confirmar"
      >
        <Row align="middle">
          <Col span={2}>
            <InfoCircleOutlined size={20} style={{ color: '#FAAD14' }} />
          </Col>
          <Col span={22}>Deseja realmente cancelar a edição do perfil?</Col>
        </Row>
      </Modal>
      <PageContainer>
        <BaseForm layout="vertical" style={{ width: '100%' }} disabled={view == 'visualizar'}>
          <SpinnerSlump spinning={loading}>
            <Description
              title="Informações do perfil"
              subtitle="Preencha os campos abaixo para cadastrar um novo perfil"
            >
              <Row gutter={24}>
                <Col xs={24} md={8}>
                  <BaseFormInputItem label="Nome do perfil" errorText="Campo inválido">
                    <Input
                      name="descricao"
                      onChange={handleChangeProfile}
                      placeholder="Informe o nome do perfil"
                      value={profile.descricao}
                    />
                  </BaseFormInputItem>
                </Col>
                {isUserMaster() && (
                  <Col xs={24} md={8}>
                    <BaseFormInputItem label="Selecionar cliente">
                      <Select
                        showArrow
                        showSearch
                        placeholder="Selecione por razão social"
                        value={profile.idCliente && profile.idCliente != 0 ? profile.idCliente : undefined}
                        onChange={(value) => setProfile({ ...profile, idCliente: value as number })}
                        options={clients.map((c) => ({ value: c.id, label: c.razaoSocial }))}
                        filterOption={(input, option) =>
                          (option?.label ?? '').toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      />
                    </BaseFormInputItem>
                  </Col>
                )}
                <Col xs={24} md={8}>
                  <BaseFormInputItem label="Status do perfil">
                    <RadioGroup name="isDeleted" onChange={handleStatusOnChange} value={profile.isDeleted ? 0 : 1}>
                      <Radio value={1}>Ativo</Radio>
                      <Radio value={0}>Inativo</Radio>
                    </RadioGroup>
                  </BaseFormInputItem>
                </Col>
              </Row>
            </Description>
            {!profile.isDeleted && (
              <Description
                title="Configurações das permissões"
                subtitle="Selecione os campos abaixo para configurar as permissões desse perfil"
              >
                <Row style={{ paddingBottom: '1.5rem' }}>
                  <Checkbox onChange={() => handleCheckAll(!selectedAll)} checked={selectedAll}>
                    Selecionar todas as permissões
                  </Checkbox>
                </Row>
                {groupModules.map((group) => (
                  <RowCollpasable
                    header={
                      <Row gutter={18}>
                        <Col>{IconsByGroupProfile.find((i) => i.groupName == group.name)?.icon}</Col>
                        <Col>{group.name}</Col>
                      </Row>
                    }
                    key={group.name}
                  >
                    <Row style={{ width: '100%', paddingLeft: '3.4rem' }} gutter={[24, 24]}>
                      {group.modules.map((module) => (
                        <Col key={module.id} xs={24} md={8} xl={4}>
                          <div className="ant-container-collapse-title">{module.descricao}</div>
                          {module.children?.length > 1 && (
                            <Row style={{ width: '100%', paddingBottom: '0.5rem' }}>{renderCheckboxAll(module)}</Row>
                          )}

                          {module.children.map((permission) => (
                            <Row key={permission.id} style={{ paddingLeft: '1.8rem', paddingBottom: '0.5rem' }}>
                              {renderCheckboxItem(permission)}
                            </Row>
                          ))}
                        </Col>
                      ))}
                    </Row>
                  </RowCollpasable>
                ))}
              </Description>
            )}
          </SpinnerSlump>
        </BaseForm>
      </PageContainer>
    </>
  );
};

export default ProfileCreate;
