import { TravelInfoDetailsModel } from '@app/domain/interfaces/report/TravelInfoDetailsReport';
import { TravelInfo } from '@app/pages/modules/Reports/components/HeaderInfoDetailDetail/components/TravelInfo/TravelInfo';
import { WaterConcreteInfo } from '@app/pages/modules/Reports/components/HeaderInfoDetailDetail/components/WaterConcreteInfo/WaterConcreteInfo';
import { TravelTimeInfo } from '@app/pages/modules/Reports/components/HeaderInfoDetailDetail/components/TravelTimeInfo/TravelTimeInfo';

interface HeaderInfoProps {
  travelDetails: TravelInfoDetailsModel;
}

const HeaderInfoDetailDetail: React.FC<HeaderInfoProps> = ({ travelDetails }) => {
  return (
    <>
      <TravelInfo travelDetails={travelDetails} />
      <WaterConcreteInfo travelDetails={travelDetails} />
      <TravelTimeInfo travelDetails={travelDetails} />
    </>
  );
};

export default HeaderInfoDetailDetail;
