import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Modal } from '@app/components/common/Modal/Modal';
import { ResumeDataModel } from '@app/domain/interfaces/report/reportTripAnalyticModel';
import Dashboard from '@app/components/dashboard/Dashboard';
import { Table } from '@app/components/common/Table/Table';
import { ColumnsType } from 'antd/lib/table';

interface IModalTripGraphicProps {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  tripResume: ResumeDataModel;
}

const ModalTripResume: React.FC<IModalTripGraphicProps> = ({ visible, setVisible, tripResume }) => {
  const [resume, setResume] = useState<ResumeDataModel[]>([]);

  const columns: ColumnsType<ResumeDataModel> = [
    {
      title: 'Água utilizada',
      dataIndex: 'aguaUtilizada',
      showSorterTooltip: false,
      width: '10%',
      render: (_, data) => <div>{data.aguaUtilizada ? `${data.aguaUtilizada?.toFixed(2)} L` : '-'}</div>,
    },
    {
      title: 'Dif. Água',
      dataIndex: 'difAgua',
      showSorterTooltip: false,
      width: '10%',
      render: (_, data) => <div>{data.difAgua ? `${data.difAgua?.toFixed(2)} L` : '-'}</div>,
    },
    {
      title: 'Água retida',
      dataIndex: 'aguaRetida',
      showSorterTooltip: false,
      width: '10%',
      render: (_, data) => <div>{data.aguaRetida ? `${data.aguaRetida?.toFixed(2)} L` : '-'}</div>,
    },
    {
      title: 'Tempo de inspeção na central',
      dataIndex: 'tempoInspecaoCentral',
      showSorterTooltip: false,
      width: '20%',
      render: (_, data) => (
        <div>
          {data.tempoInspecaoCentral
            ? `${data.tempoInspecaoCentral?.toFixed(2)} ${data.tempoInspecaoCentral === 1 ? 'min' : 'mins'}`
            : '-'}
        </div>
      ),
    },
    {
      title: 'Qtd. Inversões do balão',
      dataIndex: 'qtdInversaoBalao',
      showSorterTooltip: false,
      width: '25%',
      render: (_, data) => <div>{data.qtdInversaoBalao ? `${data.qtdInversaoBalao}` : '-'}</div>,
    },
    {
      title: 'Qtd. Freio de estacionamento',
      dataIndex: 'qtdFreioEstacionamento',
      showSorterTooltip: false,
      width: '25%',
      render: (_, data) => <div>{data.qtdFreioEstacionamento ? `${data.qtdFreioEstacionamento}` : '-'}</div>,
    },
  ];

  useEffect(() => {
    const resumes = [];
    resumes.push(tripResume);
    setResume([...resumes]);
  }, [tripResume]);

  return (
    <>
      <Modal
        open={visible}
        onCancel={() => setVisible(!visible)}
        size="medium"
        style={{ display: 'block', minWidth: '90vw' }}
        footer={null}
      >
        <Dashboard
          title="Resumo"
          table={<Table columns={columns} dataSource={resume} scroll={{ x: 800 }} bordered />}
        />
      </Modal>
    </>
  );
};

export default ModalTripResume;
