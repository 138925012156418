import { UserModel } from '@app/domain/interfaces/user/userModel';
import { AxiosResponse } from 'axios';
import { ApiServiceBase, IApiServiceBase } from './apiServiceBase';

interface ActiveDeactiveUser {
  id?: number;
  userId?: string;
  ativo: boolean;
}

export default interface IUserService extends IApiServiceBase<UserModel, UserModel> {
  activeDeactive(model: ActiveDeactiveUser): Promise<AxiosResponse>;
  getByUsername(endpoint: string, username: string): Promise<UserModel>;
}

export class UserService extends ApiServiceBase<UserModel, UserModel> implements IUserService {
  constructor() {
    super('admin/usuarios');
  }

  async activeDeactive(model: ActiveDeactiveUser): Promise<AxiosResponse> {
    const response = await this.api.post('ativarDesativarUsuario', model);
    return response.data;
  }

  async getByUsername(endpoint: string, username: string): Promise<UserModel> {
    try {
      const response = await this.api.get(`${endpoint}/${username}`);
      return response?.data;
    } catch (error) {
      throw error;
    }
  }
}
