import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { Modal } from '@app/components/common/Modal/Modal';
import {
  DetailedTravelInfoAlertModel,
  TravelInfoDetailsModel,
} from '@app/domain/interfaces/report/TravelInfoDetailsReport';
import { TravelInfo } from '@app/pages/modules/Reports/components/HeaderInfoDetailDetail/components/TravelInfo/TravelInfo';
import { Table } from '@app/components/common/Table/Table';
import { ColumnsType } from 'antd/lib/table';
import { renderMixerStatus } from '@app/components/common/Map/functions/mapFunctions';
import { TravelState } from '@app/constants/enums/travelState';
import { BoxHeader } from '@app/components/common/BoxHeader/Box';

import * as S from './AlertModal.styles';
import IconFilePdf from '@app/assets/slump-icons/IconFilePdf';
import { Button } from '@app/components/common/buttons/Button/Button';
import moment from 'moment';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { notificationController } from '@app/controllers/notificationController';
import { HeaderLogoName } from '@app/pages/modules/Reports/components/HeaderPDF/components/HeaderLogoName/HeaderLogoName';

interface IAlertModalProps {
  travelInfoDetails: TravelInfoDetailsModel;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  setLoading: Dispatch<SetStateAction<boolean>>;
}

const AlertModal: React.FC<IAlertModalProps> = ({ travelInfoDetails, open, setOpen, setLoading }) => {
  const { alertas, obraEndereco } = travelInfoDetails;

  const columns: ColumnsType<DetailedTravelInfoAlertModel> = [
    {
      title: 'Alerta',
      dataIndex: 'descricao',
      key: 'descricao',
    },
    {
      title: 'Categoria',
      dataIndex: 'tipoAlerta',
      key: 'tipoAlerta',
    },
    {
      title: 'Data/Hora',
      dataIndex: 'dataHora',
      key: 'dataHora',
      render: (dataHora) => (dataHora ? moment(dataHora).format('DD/MM/YYYY - HH:mm') : '-'),
    },
    {
      title: 'Estado da Viagem',
      dataIndex: 'estadoViagem',
      key: 'estadoViagem',
      render: (estadoViagem: TravelState) => (estadoViagem ? renderMixerStatus(estadoViagem) : '-'),
    },
    {
      title: 'Endereço',
      dataIndex: 'endereco',
      key: 'endereco',
      render: (endereco) => (endereco ? endereco : obraEndereco),
    },
  ];

  const handleExportToPdf = () => {
    generatePdf();
  };

  const generatePdf = async () => {
    const exportableDiv = document.getElementById('exportable-div-alert-modal') as HTMLElement;
    const exportableHeader = document.getElementById('exportable-header-alert-modal') as HTMLElement;

    if (!exportableDiv || !exportableHeader) return;

    // set config to export
    exportableDiv.style.padding = '10px 20px';
    exportableHeader.style.display = 'flex';

    setLoading(true);

    const pdf = new jsPDF({ orientation: 'portrait', unit: 'mm' });

    html2canvas(exportableDiv)
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const imgProps = pdf.getImageProperties(imgData);
        const pageWidth = pdf.internal.pageSize.getWidth();
        const pageHeight = (imgProps.height * pageWidth) / imgProps.width;
        pdf.addImage(imgData, 'PNG', 0, 0, pageWidth, pageHeight);
        pdf.save(`alertas.pdf`);
      })
      .catch(() => {
        notificationController.error({ message: 'Ocorreu um erro ao gerar o relatório.' });
      })
      .finally(() => {
        // reset config
        exportableDiv.style.padding = '0';
        exportableHeader.style.display = 'none';
        setLoading(false);
      });
  };

  return (
    <Modal
      title="Alertas"
      open={open}
      onCancel={() => setOpen(false)}
      footer={null}
      size={'large'}
      style={{ minWidth: '90%' }}
    >
      <S.ButtonContainer>
        <Button
          type="link"
          icon={<IconFilePdf />}
          onClick={handleExportToPdf}
          style={{ color: 'var(secondary-color)' }}
        >
          Exportar para pdf
        </Button>
      </S.ButtonContainer>
      <div id="exportable-div-alert-modal">
        <HeaderLogoName
          id="exportable-header-alert-modal"
          reportName={`Relatório - Alertas`}
          style={{ display: 'none' }}
        />
        <BoxHeader>
          <TravelInfo travelDetails={travelInfoDetails} isAlertModal={true} />
        </BoxHeader>
        <Table columns={columns} dataSource={alertas} style={{ padding: 0 }} bordered pagination={undefined} />
      </div>
    </Modal>
  );
};

export default AlertModal;
