import styled from 'styled-components';

export const HelperContainerBox = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  padding: 2rem 2rem 1rem 5rem;
  width: 100vw;
  background: #1e1e1e;
  z-index: 5;
  color: white;
  gap: 1rem;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 0.5rem;
`;

export const HelperTitle = styled.h2`
  margin: 0;
  text-transform: uppercase;
`;

export const HelperSubTitle = styled.h5`
  margin: 0;
  text-transform: uppercase;
`;

export const CloseButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin-right: -1rem;
  font-size: 1.2rem;
`;

export const HelperContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 4rem;
  row-gap: 2rem;
  flex-wrap: wrap;
  padding-bottom: 1rem;
  align-items: center;
`;

export const Item = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;

export const ItemSquareBox = styled.div`
  width: 1rem;
  height: 1rem;
  border: 1px solid white;
`;

export const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  row-gap: 0.1rem;
  color: var(--neutral-gray-gray-200, #d1d1d1);
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
`;

export const SubItem = styled.span`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;
