import { useAppSelector } from '@app/hooks/reduxHooks';
import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import MainContent from '../main/MainContent/MainContent';
import * as S from '../main/MainLayout/MainLayout.styles';
import MainSider from '../main/sider/MainSider/MainSider';
import { FooterMonitoring } from './Footer/FooterMonitoring';
import HeaderMonitoring from './Header/HeaderMonitoring';

const MonitoringLayout: React.FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [siderCollapsed, setSiderCollapsed] = useState(true);
  const [forceCollapse, setForceCollapse] = useState(true);
  const [hiddenSider, setHiddenSider] = useState(false);
  const { betoneirasDisponiveis, betoneirasRetornando } = useAppSelector((state) => state.monitoring.footer);

  return (
    <S.LayoutMaster>
      <MainSider
        hidden={hiddenSider}
        forceCollapse={forceCollapse}
        isCollapsed={siderCollapsed}
        setCollapsed={setSiderCollapsed}
      />
      <S.LayoutMain collapse={forceCollapse}>
        <HeaderMonitoring
          forceCollapse={forceCollapse}
          setForceCollapse={setForceCollapse}
          responsiveColumnsLayout={false}
          setHiddenSider={setHiddenSider}
          hiddenSider={hiddenSider}
        />

        <MainContent id="main-content" $isTwoColumnsLayout={false}>
          <div style={{ padding: '1rem' }}>
            <Outlet />
          </div>
        </MainContent>
        <FooterMonitoring
          concreteMixerAvailable={betoneirasDisponiveis ?? []}
          concreteMixerReturning={betoneirasRetornando ?? []}
        />
      </S.LayoutMain>
    </S.LayoutMaster>
  );
};

export default MonitoringLayout;
