import { FONT_SIZE, media } from '@app/styles/themes/constants';
import styled from 'styled-components';

export const FooterContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1px 1fr;
  width: 100%;
`;

export const BetoneirasDisponiveisContainer = styled.div`
  overflow: hidden;
`;

export const BetoneirasRetornandoContainer = styled.div`
  overflow: hidden;
  margin-left: 1rem;
`;

interface ContainerAnimationProps {
  run: string;
}

export const Container = styled.div<ContainerAnimationProps>`
  display: flex;

  animation: animateContainer 15s linear backwards infinite;
  animation-play-state: ${(props) => props.run};

  @keyframes animateContainer {
    from {
      transform: translateX(calc(5vw));
    }
    to {
      transform: translateX(calc(-100%));
    }
  }
`;

export const ContainerD = styled.div<ContainerAnimationProps>`
  display: flex;
  margin-top: 1rem;
  animation: animateContainer 15s linear backwards infinite;
  animation-play-state: ${(props) => props.run};

  @keyframes animateContainer {
    from {
      transform: translateX(calc(0vw));
    }
    to {
      transform: translateX(calc(-100%));
    }
  }
`;

export const Title = styled.span`
  font-family: 'Mulish';
  font-size: 1.25rem;
  line-height: 1.5rem;
  font-weight: 700;
  overflow-y: hidden;

  color: #f9f9fe;

  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 0.625rem;

  @media only screen and (${media.xs}) {
    font-size: ${FONT_SIZE.xs};
    margin-bottom: 0.425rem;
  }
  @media only screen and (${media.md}) {
    font-size: ${FONT_SIZE.md};
    margin-bottom: 0.525rem;
  }
  @media only screen and (${media.xxl}) {
    font-size: 1.325rem;
    margin-bottom: 0.725rem;
  }
`;

export const SeeAllButton = styled.span`
  margin-right: 1rem;
  cursor: pointer;
  text-decoration: underline;
`;

export const Helper = styled.span`
  text-align: end;
  padding: 0 1.5rem 1rem 0;
`;

export const HelperContainerBox = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  padding: 2rem 2rem 1rem 5rem;
  width: 100vw;
  background: #1e1e1e;
  z-index: 5;
  color: white;
  gap: 1rem;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 0.5rem;
`;

export const HelperTitle = styled.h2`
  margin: 0;
  text-transform: uppercase;
`;

export const CloseButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin-right: -1rem;
  font-size: 1.2rem;
`;

export const HelperContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 4rem;
  row-gap: 2rem;
  flex-wrap: wrap;
  padding-bottom: 1rem;
`;

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
`;

export const ItemTitle = styled.div`
  color: #fff;
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
`;

export const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  row-gap: 0.1rem;
  color: var(--neutral-gray-gray-200, #d1d1d1);
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
`;

export const SubItem = styled.span`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;
