import React from 'react';
import { Row } from 'antd';
import {
  CardInfoProperty,
  CardInfoTitle,
  CardInfoValue,
} from '@app/components/common/Card/CardInfo/CardDeviceInformation.styles';
import * as S from '@app/pages/modules/Reports/components/HeaderInfoDetailDetail/HeaderInfoDetailDetail.styles';
import { TravelInfoDetailsModel } from '@app/domain/interfaces/report/TravelInfoDetailsReport';
import { formatMinutesToHoursAndMinutes } from '@app/utils/utils';

interface TravelTimeInfoProps {
  travelDetails: TravelInfoDetailsModel;
}

export const TravelTimeInfo: React.FC<TravelTimeInfoProps> = ({ travelDetails }) => {
  return (
    <>
      <Row>
        <CardInfoTitle>Informações dos tempos da viagem</CardInfoTitle>
      </Row>
      <Row>
        <S.ColStyled>
          <CardInfoProperty>Central</CardInfoProperty>
          <CardInfoValue style={{ marginTop: 0 }}>
            {travelDetails.tempoCentral ? formatMinutesToHoursAndMinutes(travelDetails.tempoCentral) : '-'}
          </CardInfoValue>
        </S.ColStyled>
        <S.ColStyled>
          <CardInfoProperty>Caminho</CardInfoProperty>
          <CardInfoValue style={{ marginTop: 0 }}>
            {travelDetails.tempoACaminho ? formatMinutesToHoursAndMinutes(travelDetails.tempoACaminho) : '-'}
          </CardInfoValue>
        </S.ColStyled>
        <S.ColStyled>
          <CardInfoProperty>Aguardo</CardInfoProperty>
          <CardInfoValue style={{ marginTop: 0 }}>
            {travelDetails.tempoAguardo ? formatMinutesToHoursAndMinutes(travelDetails.tempoAguardo) : '-'}
          </CardInfoValue>
        </S.ColStyled>
        <S.ColStyled>
          <CardInfoProperty>Descarga</CardInfoProperty>
          <CardInfoValue style={{ marginTop: 0 }}>
            {travelDetails.tempoDescarga ? formatMinutesToHoursAndMinutes(travelDetails.tempoDescarga) : '-'}
          </CardInfoValue>
        </S.ColStyled>
        <S.ColStyled style={{ width: '200px' }}>
          <CardInfoProperty>Tempo até descarga</CardInfoProperty>
          <S.SpecialCardInfoValue style={{ marginTop: 0 }}>
            {travelDetails.tempoAteDescarga ? formatMinutesToHoursAndMinutes(travelDetails.tempoAteDescarga) : '-'}
          </S.SpecialCardInfoValue>
        </S.ColStyled>
        <S.ColStyled>
          <CardInfoProperty>Lavagem</CardInfoProperty>
          <CardInfoValue style={{ marginTop: 0 }}>
            {travelDetails.tempoLavagem ? formatMinutesToHoursAndMinutes(travelDetails.tempoLavagem) : '-'}
          </CardInfoValue>
        </S.ColStyled>
        <S.ColStyled>
          <CardInfoProperty>Retorno</CardInfoProperty>
          <CardInfoValue style={{ marginTop: 0 }}>
            {travelDetails.tempoRetorno ? formatMinutesToHoursAndMinutes(travelDetails.tempoRetorno) : '-'}
          </CardInfoValue>
        </S.ColStyled>
        <S.ColStyled style={{ width: '200px' }}>
          <CardInfoProperty>Tempo total de viagem</CardInfoProperty>
          <S.SpecialCardInfoValue style={{ marginTop: 0 }}>
            {travelDetails.tempoTotal ? formatMinutesToHoursAndMinutes(travelDetails.tempoTotal) : '-'}
          </S.SpecialCardInfoValue>
        </S.ColStyled>
      </Row>
    </>
  );
};
