import {
  CardInfoContainer,
  CardInfoProperty,
  CardInfoTag,
  CardInfoTitle,
  CardInfoValue,
} from '@app/components/common/Card/CardInfo/CardDeviceInformation.styles';
import { Checkbox } from '@app/components/common/Checkbox/Checkbox';
import { Description } from '@app/components/common/Description/Description';
import { PageContainer } from '@app/components/common/PageContainer/PageContainer';
import { RadioGroup } from '@app/components/common/Radio/Radio';
import { SpinnerSlump } from '@app/components/common/SpinSlump/SpinSlump';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { BaseFormInputItem } from '@app/components/common/forms/components/BaseFormInputItem/BaseFormInputItem';
import { TextArea } from '@app/components/common/inputs/Input/Input';
import { readUser } from '@app/services/localStorage.service';
import { Col, Radio, Row, Select } from 'antd';
import { SummaryAlertModel } from '@app/domain/interfaces/alert/summaryAlertModel';
import { Chart } from '../Charts/Chart';
import { Justificativa } from '@app/domain/interfaces/configAlertModel';

interface IWaterAlertJustify {
  summaryAlert: SummaryAlertModel;
  setSummaryAlert: (value: SummaryAlertModel) => void;
  justifications: Justificativa[];
  loading: boolean;
  checkboxConfirm: boolean;
  setCheckboxConfirm: (value: boolean) => void;
  report: boolean;
  setReport: (value: boolean) => void;
}

const WaterAlertJustify: React.FC<IWaterAlertJustify> = ({
  summaryAlert,
  setSummaryAlert,
  justifications,
  loading,
  checkboxConfirm,
  setCheckboxConfirm,
  report,
  setReport,
}) => {
  return (
    <SpinnerSlump spinning={loading}>
      <PageContainer>
        <Description title={summaryAlert.alerta?.titulo}></Description>
        <CardInfoTag style={{ marginTop: '-1.5rem' }}>
          ID viagem<span>{summaryAlert.idViagem}</span>
        </CardInfoTag>
        <CardInfoContainer>
          <Row>
            <CardInfoTitle>Informações da viagem</CardInfoTitle>
          </Row>
          <Row gutter={24} justify={'space-between'} style={{ paddingBottom: '1.5rem' }}>
            <Col xs={24} lg={2}>
              <CardInfoProperty>Filial</CardInfoProperty>
              <CardInfoValue>{summaryAlert.alerta?.filial ?? '-'}</CardInfoValue>
            </Col>
            <Col xs={24} lg={7}>
              <CardInfoProperty>Cliente</CardInfoProperty>
              <CardInfoValue>{summaryAlert.alerta?.cliente ?? '-'}</CardInfoValue>
            </Col>
            <Col xs={24} lg={5}>
              <CardInfoProperty>Motorista</CardInfoProperty>
              <CardInfoValue>{summaryAlert.alerta?.motorista ?? '-'}</CardInfoValue>
            </Col>
            <Col xs={24} lg={10}>
              <CardInfoProperty>Descrição do traço</CardInfoProperty>
              <CardInfoValue>{summaryAlert.alerta?.traco ?? '-'}</CardInfoValue>
            </Col>
          </Row>
          <Row gutter={24} justify={'space-between'} style={{ paddingBottom: '2.5rem' }}>
            <Col xs={24} lg={11}>
              <CardInfoProperty>Endereço do cliente</CardInfoProperty>
              <CardInfoValue>{summaryAlert.alerta?.enderecoCliente ?? '-'}</CardInfoValue>
            </Col>
            <Col xs={24} lg={3}>
              <CardInfoProperty>Veículo</CardInfoProperty>
              <CardInfoValue>{summaryAlert.alerta?.veiculo ?? '-'}</CardInfoValue>
            </Col>
            <Col xs={24} lg={5}>
              <CardInfoProperty>Peça</CardInfoProperty>
              <CardInfoValue>{summaryAlert.alerta?.peca ?? '-'}</CardInfoValue>
            </Col>
            <Col xs={24} lg={5}>
              <CardInfoProperty>Volume programado</CardInfoProperty>
              <CardInfoValue>{summaryAlert.alerta?.volumeProgramado ?? '-'} m³</CardInfoValue>
            </Col>
          </Row>
          <Row>
            <CardInfoTitle>Informações da água no concreto</CardInfoTitle>
          </Row>
          <Row gutter={24} justify={'space-between'}>
            <Col xs={24} lg={12}>
              <Row>
                <Col xs={24} lg={4}>
                  <CardInfoProperty>Central</CardInfoProperty>
                  <CardInfoValue>{summaryAlert.aguaConcreto?.central ?? '-'} L</CardInfoValue>
                </Col>
                <Col xs={24} lg={4}>
                  <CardInfoProperty>Obra</CardInfoProperty>
                  <CardInfoValue>{summaryAlert.aguaConcreto?.obra ?? '-'} L</CardInfoValue>
                </Col>
                <Col xs={24} lg={4}>
                  <CardInfoProperty>Retida</CardInfoProperty>
                  <CardInfoValue>{summaryAlert.aguaConcreto?.retida ?? '-'} L</CardInfoValue>
                </Col>
                <Col xs={24} lg={4}>
                  <CardInfoProperty>Sobra de água</CardInfoProperty>
                  <CardInfoValue>{summaryAlert.aguaConcreto?.totalSobra ?? '-'} L/m³</CardInfoValue>
                </Col>
              </Row>
            </Col>
          </Row>
        </CardInfoContainer>

        <BaseForm
          layout="vertical"
          style={{ width: '100%' }}
          disabled={summaryAlert.alerta?.status?.toString() !== 'Novo'}
        >
          {summaryAlert.usosAgua && summaryAlert.usosAgua.length > 0 && (
            <Description title="Uso de água (L)" subtitle="Análise do uso de água durante a viagem">
              <Col>
                <Chart summaryAlert={summaryAlert} />
              </Col>
            </Description>
          )}

          <Description title="Evento" subtitle="Preencha os campos para confirmar este evento">
            <Row>
              <Col xs={24} md={8}>
                <BaseFormInputItem label="Deseja reportar alerta?">
                  <RadioGroup
                    name="status"
                    value={!report ? 'Confirmado' : 'Reportado'}
                    defaultValue="Confirmado"
                    onChange={(e) => setReport(e.target.value === 'Confirmado' ? false : true)}
                  >
                    <Radio value="Reportado">Sim</Radio>
                    <Radio value="Confirmado">Não</Radio>
                  </RadioGroup>
                </BaseFormInputItem>
              </Col>
            </Row>
            {justifications?.length > 0 && (
              <Row>
                <Col xs={24} md={8}>
                  <BaseFormInputItem label="Motivo">
                    <Select
                      showArrow
                      showSearch
                      placeholder="Selecione um motivo"
                      value={summaryAlert?.alerta?.idJustificativa}
                      onChange={(value) =>
                        setSummaryAlert({
                          ...summaryAlert,
                          alerta: { ...summaryAlert.alerta, idJustificativa: (value ?? 0) as number },
                        })
                      }
                      options={justifications.map((j) => ({ label: j.justificativa, value: j.id }))}
                    />
                  </BaseFormInputItem>
                </Col>
              </Row>
            )}
            <Row>
              <Col xs={24} md={8}>
                <BaseFormInputItem label="Explique">
                  <TextArea
                    placeholder="Digite sua explicação do motivo selecionado"
                    rows={4}
                    value={summaryAlert?.alerta?.explicacao}
                    onChange={(e) =>
                      setSummaryAlert({
                        ...summaryAlert,
                        alerta: { ...summaryAlert.alerta, explicacao: e.target.value },
                      })
                    }
                  />
                </BaseFormInputItem>
              </Col>
            </Row>
            <Row style={{ paddingBottom: '1.5rem' }}>
              <Checkbox value={checkboxConfirm} onChange={(value) => setCheckboxConfirm(!value.target.value)}>
                Evento analisado e confirmado por usuário {summaryAlert?.alerta?.responsavel ?? readUser()?.username}
              </Checkbox>
            </Row>
          </Description>
        </BaseForm>
      </PageContainer>
    </SpinnerSlump>
  );
};

export default WaterAlertJustify;
