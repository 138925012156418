import { UserOutlined } from '@ant-design/icons';
import { DrawerSpan } from '@app/components/common/Drawer/Drawer.styles';
import { DrawerLinkItem } from '@app/components/common/Drawer/DrawerLink/DrawerLinkItem/DrawerLinkItem';
import { PageContainer } from '@app/components/common/PageContainer/PageContainer';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { BaseFormInputItem } from '@app/components/common/forms/components/BaseFormInputItem/BaseFormInputItem';
import { Input } from '@app/components/common/inputs/Input/Input';
import { notificationController } from '@app/controllers/notificationController';
import { ClientModel } from '@app/domain/interfaces/clientModel';
import { GroupModel } from '@app/domain/interfaces/client_branch/groupModel';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import IGroupService, { GroupService } from '@app/services/groupService';
import { readUserFilter } from '@app/services/localStorage.service';
import { setFooter } from '@app/store/slices/footerPageSlice';
import { setHeaderRegisterPage } from '@app/store/slices/headerRegisterPage';
import { Col, Row, Select, Spin } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { HelperNotification } from '@app/components/common/HelperNotification/HelperNotification';
import { Description } from '@app/components/common/Description/Description';
import { RegionalGroupModel } from '@app/domain/interfaces/regional/regionalModel';
import { Tag } from '@app/components/common/Tag/Tag';

const groupService: IGroupService = new GroupService();

const ClientGroupCreate: React.FC = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [group, setGroup] = useState<GroupModel>({} as GroupModel);
  const [client, setClient] = useState<ClientModel>({} as ClientModel);

  const regionalType = [
    { value: 1, label: 'Norte' },
    { value: 2, label: 'Sul' },
    { value: 3, label: 'Leste' },
    { value: 4, label: 'Oeste' },
  ];

  const handlerChangeGroup = (e: React.FormEvent<HTMLInputElement>) => {
    setGroup({ ...group, [e.currentTarget.name]: e.currentTarget.value });
  };

  const handleOnSelectRegional = (value: string) => {
    const isRegionalSelected = group.regionais?.some((r) => r.nome === value);

    if (!isRegionalSelected) {
      setGroup({
        ...group,
        regionais: [...(group.regionais || []), { nome: value } as RegionalGroupModel],
      });
    }
  };

  const handlerOnDeselectGroup = (value: string) => {
    console.log(value);
    const isExistingRegional = group.regionais?.some((r) => r.nome === value);

    if (isExistingRegional) {
      setGroup({
        ...group,
        regionais: group.regionais.filter((r) => r.nome !== value),
      });
    }
  };

  const fetchData = useCallback(async () => {
    setLoading(true);

    const resGroup = await groupService.getArray(`obterPorId/${id}`);
    setGroup(resGroup as unknown as GroupModel);

    setLoading(false);
  }, [id]);

  const handlerSaveGroup = useCallback(async () => {
    setLoading(true);
    if (group.id) {
      await groupService
        .update('', group)
        .then(() => {
          notificationController.success({ message: 'Grupo atualizado com sucesso' });
          navigate('/gestao/grupos');
        })
        .catch((error) => {
          notificationController.error(error);
        })
        .finally(() => setLoading(false));
    } else {
      await groupService
        .post('', { ...group, idCliente: client?.id ?? 0 })
        .then(() => {
          notificationController.success({ message: 'Grupo inserido com sucesso' });
          navigate('/gestao/grupos');
        })
        .catch((error) => {
          notificationController.error(error);
        })
        .finally(() => setLoading(false));
    }
  }, [client?.id, group, navigate]);

  useEffect(() => {
    const filter = readUserFilter();

    if (filter.client?.id) {
      setClient(filter.client);
    }
  }, []);

  useEffect(() => {
    if (Number(id)) fetchData();

    setGroup({} as GroupModel);
  }, [fetchData, id]);

  useEffect(() => {
    dispatch(
      setHeaderRegisterPage({
        title: Number(id) ? 'Editar grupo' : 'Novo grupo',
        handleBackClick: () => navigate('/gestao/grupos'),
      }),
    );
    dispatch(
      setFooter(
        {
          confirmButtonDisabled: !(group.nome && client.id),
          confirmButtonText: Number(id) ? 'Editar grupo' : 'Cadastrar grupo',
          cancelButtonText: 'Cancelar',
          handleConfirmButtonClick: handlerSaveGroup,
          handleCancelButtonClick: () => navigate('/gestao/grupos'),
        },
        [group, client],
      ),
    );
  }, [client, dispatch, group, handlerSaveGroup, id, navigate]);

  return (
    <>
      <PageTitle>Grupos</PageTitle>
      <PageContainer>
        <Spin spinning={loading}>
          <BaseForm layout="vertical" style={{ width: '100%' }}>
            {client.id ? (
              <BaseFormInputItem label="Concreteira">
                <DrawerLinkItem
                  style={{ marginTop: '-2px', width: '100%', maxWidth: '400px' }}
                  title={client.razaoSocial}
                  subtitle={client.cnpj}
                  icon={<UserOutlined />}
                ></DrawerLinkItem>
              </BaseFormInputItem>
            ) : (
              <DrawerSpan style={{ margin: '2rem 0rem 2rem 0rem', color: 'var(--error-color)' }}>
                Necessário selecionar um cliente no filtro para finalizar o cadastro
              </DrawerSpan>
            )}

            <Description title="Informações do grupo" subtitle="Preencha os campos para cadastrar um novo grupo">
              <HelperNotification>
                {'Caso deseje selecionar regionais, você pode escolher uma ou mais. O campo "Regional" é opcional.'}
              </HelperNotification>
              <Row gutter={[20, 20]}>
                <Col span={12}>
                  <BaseFormInputItem
                    label="Nome grupo"
                    supportText="Como o grupo vai ficar conhecido no sistema"
                    //isSuccess={group.nome ? undefined : false}
                    //errorText="Nome inválido"
                  >
                    <Input
                      name="nome"
                      placeholder="Informe o nome do grupo"
                      value={group.nome}
                      onChange={handlerChangeGroup}
                    />
                  </BaseFormInputItem>
                </Col>
                <Col span={12}>
                  <BaseFormInputItem label="Regional" supportText="Opcional">
                    <Select
                      showSearch
                      showArrow
                      mode="tags"
                      placeholder="Selecione grupos que a filial pertence"
                      value={group.regionais?.map((f) => f.nome)}
                      onSelect={handleOnSelectRegional}
                      onDeselect={!id ? handlerOnDeselectGroup : undefined}
                      style={{ width: '100%' }}
                      options={regionalType.map((r) => ({ value: r.label, label: r.label }))}
                      tagRender={(props) => {
                        return (
                          <Tag
                            key={props.value}
                            closable={!id}
                            closeIcon={!id}
                            onClose={!id ? props.onClose : undefined}
                          >
                            {props.label}
                          </Tag>
                        );
                      }}
                    />
                  </BaseFormInputItem>
                </Col>
              </Row>
            </Description>
          </BaseForm>
        </Spin>
      </PageContainer>
    </>
  );
};

export default ClientGroupCreate;
