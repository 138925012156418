import styled from 'styled-components';

export const Notification = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 1rem;
  margin-bottom: 1rem;
  align-items: center;
  border-radius: 0.5rem;
  border: 1px solid var(--brand-colors-blue-blue-500, #263668);
  background: rgba(202, 210, 236, 0.6);
`;

export const TextInfo = styled.span`
  color: var(--brand-colors-blue-blue-800, #0f162a);
  font-family: Mulish;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.25rem; /* 142.857% */
  margin-left: 1rem;
`;
