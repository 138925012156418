import * as React from 'react';
const SvgIconEdit = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 36 36" width="1em" height="1em" role="img">
    <path
      fill="#000"
      fillOpacity={0.85}
      d="M7.782 27.64c.08 0 .16-.009.241-.02l6.758-1.186a.394.394 0 0 0 .213-.113L32.026 9.29a.4.4 0 0 0 0-.567l-6.678-6.682a.398.398 0 0 0-.285-.116.398.398 0 0 0-.285.116L7.746 19.073a.408.408 0 0 0-.113.213l-1.185 6.758a1.346 1.346 0 0 0 .378 1.197c.265.258.599.398.956.398Zm2.708-7.008L25.063 6.063l2.945 2.945-14.573 14.57-3.572.63.627-3.576Zm22.295 10.382H3.214c-.711 0-1.286.575-1.286 1.286v1.446c0 .177.145.322.322.322h31.5a.322.322 0 0 0 .321-.322V32.3c0-.711-.575-1.286-1.286-1.286Z"
    />
  </svg>
);
export default SvgIconEdit;
