import * as React from 'react';
const SvgIconReport = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21.1766 6.01607L15.4124 0.251786C15.2516 0.0910715 15.0347 0 14.807 0H3.42843C2.95432 0 2.57129 0.383036 2.57129 0.857143V23.1429C2.57129 23.617 2.95432 24 3.42843 24H20.5713C21.0454 24 21.4284 23.617 21.4284 23.1429V6.62411C21.4284 6.39643 21.3374 6.17679 21.1766 6.01607ZM19.4516 7.01786H14.4106V1.97679L19.4516 7.01786ZM19.4999 22.0714H4.49986V1.92857H12.5891V7.71429C12.5891 8.01265 12.7077 8.2988 12.9187 8.50978C13.1296 8.72076 13.4158 8.83929 13.7141 8.83929H19.4999V22.0714ZM11.7856 14.8393H6.857C6.73915 14.8393 6.64272 14.9357 6.64272 15.0536V16.3393C6.64272 16.4571 6.73915 16.5536 6.857 16.5536H11.7856C11.9034 16.5536 11.9999 16.4571 11.9999 16.3393V15.0536C11.9999 14.9357 11.9034 14.8393 11.7856 14.8393ZM6.64272 11.4107V12.6964C6.64272 12.8143 6.73915 12.9107 6.857 12.9107H17.1427C17.2606 12.9107 17.357 12.8143 17.357 12.6964V11.4107C17.357 11.2929 17.2606 11.1964 17.1427 11.1964H6.857C6.73915 11.1964 6.64272 11.2929 6.64272 11.4107Z"
      fill="current"
    />
  </svg>
);
export default SvgIconReport;
