import React from 'react';
import { TravelInfoDetailsModel } from '@app/domain/interfaces/report/TravelInfoDetailsReport';
import { Row } from 'antd';
import {
  CardInfoProperty,
  CardInfoTitle,
  CardInfoValue,
} from '@app/components/common/Card/CardInfo/CardDeviceInformation.styles';
import * as S from '@app/pages/modules/Reports/components/HeaderInfoDetailDetail/HeaderInfoDetailDetail.styles';

interface TravelInfoProps {
  travelDetails: TravelInfoDetailsModel;
  isAlertModal?: boolean;
}

export const TravelInfo: React.FC<TravelInfoProps> = ({ travelDetails, isAlertModal = false }) => {
  return (
    <>
      {!isAlertModal ? (
        <Row>
          <CardInfoTitle>Informações da viagem</CardInfoTitle>
        </Row>
      ) : null}
      <Row gutter={24}>
        <S.Col xs={24} sm={12} md={6} lg={4} xxl={2}>
          <CardInfoProperty>ID Viagem</CardInfoProperty>
          <CardInfoValue style={{ marginTop: 0 }}>{travelDetails.idViagem}</CardInfoValue>
        </S.Col>
        <S.Col xs={24} sm={12} md={6} lg={4} xxl={3}>
          <CardInfoProperty>Filial</CardInfoProperty>
          <CardInfoValue style={{ marginTop: 0 }}>{travelDetails.filial ?? '-'}</CardInfoValue>
        </S.Col>
        <S.Col xs={24} sm={12} md={6} lg={4} xxl={3}>
          <CardInfoProperty>Ponto de carga</CardInfoProperty>
          <CardInfoValue style={{ marginTop: 0 }}>{travelDetails.pontoDeCarga ?? '-'}</CardInfoValue>
        </S.Col>
        <S.Col xs={24} sm={12} md={8} lg={12} xxl={4}>
          <CardInfoProperty>Cliente</CardInfoProperty>
          <CardInfoValue style={{ marginTop: 0 }}>{travelDetails.obra ?? '-'}</CardInfoValue>
        </S.Col>
        <S.Col xs={24} sm={12} md={8} lg={8} xxl={4}>
          <CardInfoProperty>Motorista</CardInfoProperty>
          <CardInfoValue style={{ marginTop: 0 }}>{travelDetails.motorista ?? '-'}</CardInfoValue>
        </S.Col>
        <S.Col xs={24} sm={12} md={12} lg={16} xxl={8}>
          <CardInfoProperty>Descrição do traço</CardInfoProperty>
          <CardInfoValue style={{ marginTop: 0 }}>{travelDetails.traco ?? '-'}</CardInfoValue>
        </S.Col>
      </Row>
      <Row gutter={24}>
        <S.Col xs={24} sm={24} md={12} lg={8} xxl={12}>
          <CardInfoProperty>Endereço do cliente</CardInfoProperty>
          <CardInfoValue style={{ marginTop: 0 }}>{travelDetails.obraEndereco ?? '-'}</CardInfoValue>
        </S.Col>
        <S.Col xs={24} sm={8} md={4} lg={4} xxl={4}>
          <CardInfoProperty>Veículo</CardInfoProperty>
          <CardInfoValue style={{ marginTop: 0 }}>{travelDetails.betoneira ?? '-'}</CardInfoValue>
        </S.Col>
        <S.Col xs={24} sm={8} md={4} lg={4} xxl={4}>
          <CardInfoProperty>Peça</CardInfoProperty>
          <CardInfoValue style={{ marginTop: 0 }}>{travelDetails.peca ?? '-'}</CardInfoValue>
        </S.Col>
        <S.Col xs={24} sm={8} md={4} lg={8} xxl={4}>
          <CardInfoProperty>Volume programado</CardInfoProperty>
          <CardInfoValue style={{ marginTop: 0 }}>{travelDetails.volume ?? '-'} m³</CardInfoValue>
        </S.Col>
      </Row>
    </>
  );
};
