import styled from 'styled-components';

export const ContainerWrapp = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Container = styled.span`
  display: flex;
  flex-direction: row;
`;

export const Title = styled.span`
  display: flex;

  font-family: 'Mulish';
  font-style: normal;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: #545454;

  /* margin-left: 0.5rem; */
`;
