import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Error } from '@app/components/Error/Error';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { setHeader } from '@app/store/slices/headerSlice';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { ArrowLeftOutlined } from '@ant-design/icons';

const Error404Page: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  useEffect(() => {
    dispatch(
      setHeader({
        icon: <ArrowLeftOutlined />,
        title: 'Página não encontrada',
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <PageTitle>{t('common.clientError')}</PageTitle>
      <Error
        title="404"
        subtitle="Página não encontrada"
        textLink={'Para mais informações entre em contato com a equipe de suporte'}
        msg={'Desculpe, não conseguimos encontrar o que você procura.'}
      />
    </>
  );
};

export default Error404Page;
