import { DeviceModel } from '../domain/interfaces/device/deviceModel';
import { ApiServiceBase, IApiServiceBase } from './apiServiceBase';

export default interface IDeviceService extends IApiServiceBase<DeviceModel, DeviceModel> {}

export class DeviceService extends ApiServiceBase<DeviceModel, DeviceModel> implements IDeviceService {
  constructor() {
    super('admin/dispositivos');
  }
}
