/* eslint-disable @typescript-eslint/no-unused-vars */
import { InfoCircleOutlined } from '@ant-design/icons';
import { Description } from '@app/components/common/Description/Description';
import { PageContainer } from '@app/components/common/PageContainer/PageContainer';
import { Radio, RadioChangeEvent, RadioGroup } from '@app/components/common/Radio/Radio';
import { SpinnerSlump } from '@app/components/common/SpinSlump/SpinSlump';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { BaseFormInputItem } from '@app/components/common/forms/components/BaseFormInputItem/BaseFormInputItem';
import { Input } from '@app/components/common/inputs/Input/Input.styles';
import { notificationController } from '@app/controllers/notificationController';
import { ClientModel } from '@app/domain/interfaces/clientModel';
import { ConcreteMixerModel } from '@app/domain/interfaces/concreteMixerModel';
import { DeviceModel } from '@app/domain/interfaces/device/deviceModel';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { useMounted } from '@app/hooks/useMounted';
import IClientService, { ClientService } from '@app/services/clientService';
import IConcreteMixerService, { ConcreteMixerService } from '@app/services/concreteMixerService';
import IDeviceService, { DeviceService } from '@app/services/deviceService';
import { isUserMaster } from '@app/services/localStorage.service';
import { setFooter } from '@app/store/slices/footerPageSlice';
import { setHeaderRegisterPage } from '@app/store/slices/headerRegisterPage';
import { Col, Modal, Row, Select } from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const deviceService: IDeviceService = new DeviceService();
const clientService: IClientService = new ClientService();
const concreteMixerService: IConcreteMixerService = new ConcreteMixerService();

const DeviceCreate: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isMounted } = useMounted();
  const [loading, setLoading] = useState(false);
  const [modalCancelVisible, setModalCancelVisible] = useState(false);
  const [idMixerSelected, setIdMixerSelected] = useState(0);
  const [imeiValid, setImeiValid] = useState<boolean | undefined>(undefined);
  const [imeiError, setImeiError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [saveValid, setSaveValid] = useState(true);
  const [clients, setClients] = useState<ClientModel[]>([]);
  const [concreteMixers, setConcreteMixers] = useState<ConcreteMixerModel[]>([]);
  const [device, setDevice] = useState<DeviceModel>({} as DeviceModel);

  const handleChangeDevice = (event: React.FormEvent<HTMLInputElement>) => {
    const x = event.currentTarget.value.replace(/\D/g, '').match(/(\d{0,6})(\d{0,2})(\d{0,6})(\d{0,1})/);
    if (x !== null) {
      event.currentTarget.value = !x[2] ? x[1] : x[1] + '-' + x[2] + '-' + x[3] + '-' + x[4];

      setDevice({ ...device, imei: event.currentTarget.value });

      if (event.currentTarget.value.length > 0 && event.currentTarget.value.length === 18) {
        setImeiValid(true);
      } else {
        setImeiValid(false);
      }
    }
  };

  const handleDeviceImeiOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Backspace' && device.imei?.endsWith('-')) {
      setDevice({ ...device, imei: device.imei?.slice(0, -2) });
    }
  };

  const handleTipoOnChange = (e: RadioChangeEvent) => {
    setDevice({ ...device, tipo: e.target.value });
  };

  const handleChangeMixer = (id: number) => setDevice({ ...device, idBetoneira: id });

  const handleClientChange = (id: number) => {
    fetchConcreteMixers(id);
    setDevice({ ...device, idCliente: id });
  };

  const fetchConcreteMixers = async (id: number | undefined) => {
    setIsLoading(true);

    concreteMixerService
      .getArray('obter')
      .then((res: ConcreteMixerModel[]) => {
        setConcreteMixers(res.filter((mixer) => mixer.idCliente === id && !mixer.deviceId));
        setIsLoading(false);
      })
      .catch((error) => {
        notificationController.error(error);
        setIsLoading(false);
      });
  };

  const fetchClient = () => {
    setIsLoading(true);

    clientService
      .getArray('obter')
      .then((res: ClientModel[]) => {
        setClients(res);
        setIsLoading(false);
      })
      .catch((error) => {
        notificationController.error(error);
        setIsLoading(false);
      });
  };

  const handleSaveDevice = async () => {
    setIsLoading(true);

    if (!id) {
      const newDeviceId = await deviceService.api.get('gerarDeviceId').then((res) => {
        return res.data;
      });
      setDevice({ ...device, deviceId: newDeviceId });
      deviceService
        .post('inserir', { ...device, deviceId: newDeviceId })
        .then(() => {
          setIsLoading(false);
          notificationController.success({ message: 'Cadastro de novo dispositivo realizado com sucesso!' });
          navigate('/gestao/dispositivos');
        })
        .catch((error) => {
          notificationController.error({ message: `Erro ao obter o dispositivo, ${error}` });
          setIsLoading(false);
        });
    } else {
      deviceService
        .update('atualizar', device)
        .then(() => {
          setIsLoading(false);
          notificationController.success({ message: 'Dispositivo atualizado com sucesso' });
          navigate('/gestao/dispositivos');
        })
        .catch((error) => {
          notificationController.error({ message: `Erro ao atualizar o dispositivo, ${error}` });
          setIsLoading(false);
        });
    }
  };

  const fetchDevice = (id: number) => {
    setLoading(true);

    deviceService
      .get(`obterPorId/${id}`)
      .then(async (res) => {
        if (res.idCliente) {
          await fetchConcreteMixers(res.idCliente);
        }
        setDevice(res);
        setLoading(false);
      })
      .catch((error) => {
        notificationController.error(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (imeiValid && device.tipo) {
      setSaveValid(false);
    } else {
      setSaveValid(true);
    }
  }, [imeiValid, device.tipo, device.idCliente]);

  useEffect(() => {
    setDevice({ ...device, idBetoneira: idMixerSelected });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idMixerSelected]);

  useEffect(() => {
    if (isMounted.current) {
      if (id) {
        setImeiValid(true);
        fetchDevice(parseInt(id));
      }
    }
    fetchClient();

    dispatch(
      setHeaderRegisterPage({
        title: !id ? `Novo dispositivo` : 'Editar dispositivo',
        handleBackClick: () => navigate('/gestao/dispositivos'),
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(
      setFooter({
        confirmButtonDisabled: !(imeiValid && device.tipo),
        confirmButtonText: id ? 'Salvar' : 'Cadastrar',
        cancelButtonText: 'Cancelar',
        handleConfirmButtonClick: handleSaveDevice,
        handleCancelButtonClick: () => setModalCancelVisible(true),
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [device]);

  return (
    <>
      <Modal
        title={`Cancelar ${id ? 'edição' : 'cadastro'} do dispositivo`}
        visible={modalCancelVisible}
        onOk={() => navigate('/gestao/dispositivos')}
        onCancel={() => setModalCancelVisible(false)}
        cancelText="Cancelar"
        okText="Confirmar"
      >
        <Row align="middle">
          <Col span={2}>
            <InfoCircleOutlined size={20} style={{ color: '#FAAD14' }} />
          </Col>
          <Col span={22}>Deseja realmente cancelar {id ? 'a edição' : 'o cadastro'} do dispositivo?</Col>
        </Row>
      </Modal>
      <PageContainer>
        <BaseForm layout="vertical" style={{ width: '100%' }}>
          <SpinnerSlump spinning={loading}>
            <Description
              title="Informações do dispositivo"
              subtitle={`Preencha os campos para ${!id ? 'cadastrar um novo' : 'editar um'}  dispositivo`}
            >
              <Row gutter={18}>
                <Col xs={24} md={8}>
                  <BaseFormInputItem
                    label="IMEI"
                    isSuccess={imeiValid}
                    errorText={imeiError.length > 0 ? imeiError : 'Campo inválido'}
                    supportText="15 caracteres somente números"
                  >
                    <Input
                      disabled={id ? true : false}
                      name="imei"
                      placeholder="Digite o IMEI do dispositivo"
                      value={device.imei}
                      onChange={handleChangeDevice}
                      onKeyDown={handleDeviceImeiOnKeyDown}
                    />
                  </BaseFormInputItem>
                </Col>
                {id ? (
                  <Col xs={24} md={8}>
                    <BaseFormInputItem label="Apelido">
                      <Input placeholder="" value={device.deviceId} disabled />
                    </BaseFormInputItem>
                  </Col>
                ) : null}

                <Col xs={24} md={8}>
                  <BaseFormInputItem label="Descrição" supportText="Opcional">
                    <Input
                      name="descricao"
                      placeholder="Digite a descrição do dispositivo"
                      value={device.descricao}
                      onChange={(e) => setDevice({ ...device, descricao: e.target.value })}
                    />
                  </BaseFormInputItem>
                </Col>
              </Row>
              <Row gutter={18}>
                <Col xs={24} md={24}>
                  <BaseFormInputItem label="Tipo de dispositivo" supportText="">
                    <RadioGroup
                      name="tipo"
                      onChange={handleTipoOnChange}
                      value={device.tipo}
                      style={{ width: '20rem', display: 'flex', flexDirection: 'column' }}
                    >
                      <Radio value="T">Tablet</Radio>
                      <Radio value="H">Hermes</Radio>
                    </RadioGroup>
                  </BaseFormInputItem>
                </Col>
              </Row>
            </Description>
            <Description
              title="Informações do cliente e caminhão betoneira"
              subtitle="Vincule o dispositivo informado a um cliente e a uma placa do caminhão ou n° da BT"
            >
              {isUserMaster() && (
                <Row gutter={18}>
                  <Col xs={24} md={8}>
                    <BaseFormInputItem label="Pesquisar cliente" supportText="Opcional">
                      <Select
                        showArrow
                        showSearch
                        placeholder="Selecione por razão social"
                        disabled={!isUserMaster()}
                        value={device.idCliente && device.idCliente != 0 ? device.idCliente : undefined}
                        onChange={(value) => handleClientChange(value as number)}
                        options={clients.map((c) => ({ value: c.id, label: c.razaoSocial }))}
                        filterOption={(input, option) =>
                          (option?.label ?? '').toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      />
                    </BaseFormInputItem>
                  </Col>
                  <Col xs={24} md={8}>
                    <BaseFormInputItem label="Pesquisar n° da BT ou placa do caminhão" supportText="Opcional">
                      <Select
                        showArrow
                        showSearch
                        placeholder="Selecione o n° da BT ou placa do caminhão"
                        disabled={!device.idCliente}
                        value={device.idBetoneira == 0 ? undefined : device.idBetoneira}
                        onChange={(value) => handleChangeMixer(value as number)}
                        options={concreteMixers.map((c) => ({
                          value: c.id,
                          label: `${c.numeroBT} - ${c.placaCaminhao}`,
                        }))}
                        filterOption={(input, option) =>
                          (option?.label ?? '').toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      />
                    </BaseFormInputItem>
                  </Col>
                </Row>
              )}
            </Description>
          </SpinnerSlump>
        </BaseForm>
      </PageContainer>
    </>
  );
};

export default DeviceCreate;
