import styled from 'styled-components';
import { Checkbox as AntdCheckbox } from 'antd';

const { Group } = AntdCheckbox;

export const Checkbox = styled(AntdCheckbox)`
  font-family: 'Mulish' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 0.875rem !important;
  line-height: 1.125rem !important;

  & .ant-checkbox-inner {
    border-radius: 0.1875rem;
    height: 1.25rem;
    width: 1.25rem;
    border: 1px solid var(--secondary-color);
    margin-bottom: -2px;
  }

  & .ant-checkbox-disabled .ant-checkbox-inner {
    background-color: var(--disabled-input-color);
    border: 1px solid var(--disabled-input-color);
  }

  .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
    border-color: var(--secondary-color);
  }

  & .ant-checkbox-disabled + span {
    color: var(--disabled-input-color);
  }
`;

export const CheckboxGroup = styled(Group)`
  & .ant-checkbox-inner {
    border-radius: 0.1875rem;
    height: 1.25rem;
    width: 1.25rem;
    border: 1px solid var(--secondary-color);
  }
`;
