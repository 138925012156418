import React from 'react';
import { Row, ButtonProps } from 'antd';
import * as S from './TabsReports.styles';
import { DateTime } from '@app/constants/interfaces/interfaces';

interface ButtonReportProps extends ButtonProps {
  title?: string;
  key?: string;
  dateTime?: DateTime;
}

export const TabReports = React.forwardRef<HTMLElement, ButtonReportProps>(
  ({ title, key, dateTime, ...props }, ref) => (
    <S.ButtonTabReports key={key} ref={ref} {...props} dateTime={dateTime}>
      <Row>
        <S.Title>{title}</S.Title>
      </Row>
    </S.ButtonTabReports>
  ),
);
