import { Input as AntInput } from 'antd';
import styled from 'styled-components';

export const InputPassword = styled(AntInput.Password)`
  .ant-input-password-icon.anticon {
    color: var(--text-label);
    font-weight: 700 !important;
    &:hover {
      color: var(--text-label);
    }
  }
  &.ant-form-item-required {
    color: var(--text-label) !important;
    font-weight: 700 !important;
  }
`;
