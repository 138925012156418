import ConcreteMixerTrackingMap from '@app/components/common/Map/ConcreteMixerTrackingMap/ConcreteMixerTrackingMap';
import { PageContainer } from '@app/components/common/PageContainer/PageContainer';
import { Spinner } from '@app/components/common/Spinner/Spinner';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { setHeader } from '@app/store/slices/headerSlice';
import React, { useEffect } from 'react';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { Row } from 'antd';

const ConcreteMixerTracking: React.FC = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(
      setHeader({
        title: 'Localizar Betoneiras',
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Spinner>
      <PageTitle>Localizar</PageTitle>
      <PageContainer>
        <Row style={{ height: '45rem', width: '100%' }}>
          <ConcreteMixerTrackingMap></ConcreteMixerTrackingMap>
        </Row>
      </PageContainer>
    </Spinner>
  );
};

export default ConcreteMixerTracking;
