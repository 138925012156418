import { RoutesSlumpMix } from '@app/components/router/routes';
import { UserType } from '@app/constants/enums/userType';
import { readUser } from '@app/services/localStorage.service';

export const getFirstUrlWithAccess = () => {
  let urlWithPermission;

  for (const route of RoutesSlumpMix) {
    if (route.roles && hasAccessByRoles(route.roles)) {
      urlWithPermission = route.path;
      break;
    }

    if (route.children) {
      for (const child of route.children) {
        if (hasAccessByRole(child.role)) {
          urlWithPermission = child.path;
          break;
        }
      }
      if (urlWithPermission) {
        break;
      }
    }

    if (route.role && hasAccessByRole(route.role)) {
      urlWithPermission = route.path;
      break;
    }
  }

  return urlWithPermission;
};

export const hasAccessByRole = (role: string | undefined): boolean => {
  const user = readUser();
  if (user?.type == UserType.Master || !role) return true;
  return user?.claims?.find((claim) => role == claim.claimType) ? true : false;
};

export const hasAccessByRoles = (roles: string[] | undefined): boolean => {
  const user = readUser();
  if (user?.type == UserType.Master || !roles) return true;
  return user?.claims?.find((claim) => roles.includes(claim.claimType)) ? true : false;
};

export const hasAccessByPermission = (role: string, permission: string): boolean => {
  const user = readUser();

  if (user?.type == UserType.Master) {
    return true;
  }

  return user?.claims?.find((claim) => role.includes(claim.claimType) && permission == claim.claimValue) ? true : false;
};
