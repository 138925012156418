import { createAction, createSlice, PrepareAction } from '@reduxjs/toolkit';
import { TravelInfoModel } from '@app/domain/interfaces/concrete_mixer_location/travelInfoModel';

export interface ITravelInfoSlice {
  deviceId: string;
  concreteMixerLocations: TravelInfoModel;
  loading: boolean;
}

const initialState: ITravelInfoSlice = {
  deviceId: '',
  concreteMixerLocations: {
    listaInfoViagem: [],
  },
  loading: false,
};

export const setDeviceIdTravelInfo = createAction<PrepareAction<string>>(
  'concreteMixerLocations/deviceId',
  (payload: string) => {
    return {
      payload,
    };
  },
);

export const setConcreteMixerLocations = createAction<PrepareAction<TravelInfoModel>>(
  'concreteMixerLocations/concreteMixerLocations',
  (payload: TravelInfoModel) => {
    return {
      payload,
    };
  },
);

export const setConcreteMixerLocationsLoading = createAction<PrepareAction<boolean>>(
  'concreteMixerLocations/loading',
  (payload: boolean) => {
    return {
      payload,
    };
  },
);

export const concreteMixerLocationsSlice = createSlice({
  name: 'monitoring',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setDeviceIdTravelInfo, (state, action) => {
      state.deviceId = action.payload;
    });

    builder.addCase(setConcreteMixerLocations, (state, action) => {
      state.concreteMixerLocations = action.payload;
    });

    builder.addCase(setConcreteMixerLocationsLoading, (state, action) => {
      state.loading = action.payload;
    });
  },
});

export default concreteMixerLocationsSlice.reducer;
