import React from 'react';
import { Col, Row } from 'antd';
import * as S from './HeaderCharPDF.styles';
import { ReactComponent as IconSupermix } from '@app/assets/images/Logo-Azul-Vertical.svg';
import { HeaderLogoName } from '@app/pages/modules/Reports/components/HeaderPDF/components/HeaderLogoName/HeaderLogoName';

interface IViewFiltersHeader {
  className?: string;
  style?: React.CSSProperties;
  reportName: string;
  dataType: string;
  periodType: string;
  period: string;
  regional: string;
  branch: string;
  numeroViagens?: number;
  userName: string;
  withdrawnDate: string;
}

export const HeaderCharPDF: React.FC<IViewFiltersHeader> = ({
  className,
  style,
  reportName,
  dataType,
  periodType,
  period,
  regional,
  branch,
  numeroViagens,
  userName,
  withdrawnDate,
}) => {
  return (
    <S.Container className={className ?? ''} style={style}>
      <HeaderLogoName reportName={`Relatório ${reportName}`} />
      <S.CollapseContainer>
        <S.Collapse bordered={false} defaultActiveKey={1}>
          <S.DisplayContainer>
            <Row gutter={24} justify={'space-between'}>
              <Col>
                <S.DeviceCardInfoProperty>Tipo de dado</S.DeviceCardInfoProperty>
                <S.DeviceCardInfoValue>{dataType ?? '-'}</S.DeviceCardInfoValue>
              </Col>
              <Col>
                <S.DeviceCardInfoProperty>Tipo Período</S.DeviceCardInfoProperty>
                <S.DeviceCardInfoValue>{periodType ?? '-'}</S.DeviceCardInfoValue>
              </Col>
              <Col>
                <S.DeviceCardInfoProperty>Período</S.DeviceCardInfoProperty>
                <S.DeviceCardInfoValue>{period ?? '-'}</S.DeviceCardInfoValue>
              </Col>
              <Col>
                <S.DeviceCardInfoProperty>Regional</S.DeviceCardInfoProperty>
                <S.DeviceCardInfoValue>{regional ?? '-'}</S.DeviceCardInfoValue>
              </Col>
              <Col>
                <S.DeviceCardInfoProperty>Filial</S.DeviceCardInfoProperty>
                <S.DeviceCardInfoValue>{branch ?? '-'}</S.DeviceCardInfoValue>
              </Col>
              <Col>
                <S.DeviceCardInfoProperty>Número de viagens</S.DeviceCardInfoProperty>
                <S.DeviceCardInfoValue>{numeroViagens ?? '-'}</S.DeviceCardInfoValue>
              </Col>
              <Col>
                <S.DeviceCardInfoProperty>Data retirada</S.DeviceCardInfoProperty>
                <S.DeviceCardInfoValue>{withdrawnDate ?? '-'}</S.DeviceCardInfoValue>
              </Col>
              <Col>
                <S.DeviceCardInfoProperty>Usuário</S.DeviceCardInfoProperty>
                <S.DeviceCardInfoValue>{userName ?? '-'}</S.DeviceCardInfoValue>
              </Col>
            </Row>
          </S.DisplayContainer>
        </S.Collapse>
      </S.CollapseContainer>
    </S.Container>
  );
};
