/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { CheckCircleOutlined, FileExcelOutlined, FilePdfOutlined, InfoCircleOutlined } from '@ant-design/icons';
import {
  exportShipmentToExcel,
  exportShipmentToPdf,
  renderMixerStatus,
} from '@app/components/common/Map/functions/mapFunctions';
import { Tag } from '@app/components/common/Tag/Tag';
import { Tooltip } from '@app/components/common/Tooltip/Tooltip';
import { ConstructionModel, ShipmentModel, TimeModel } from '@app/domain/interfaces/report/reportConcreteMixerModel';
import { minutesToHoursAndMinutes } from '@app/utils/utils';
import { ColumnsType } from 'antd/lib/table/Table';
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import * as S from '@app/components/common/Map/styles';
import { Col, Row } from 'antd';
import { notificationController } from '@app/controllers/notificationController';
import IReportService, { ReportService } from '@app/services/reportService';
import { Table } from '@app/components/common/Table/Table';
import { Spinner } from '@app/components/common/Spinner/Spinner';
import { ConcreteMixerModel } from '@app/domain/interfaces/concreteMixerModel';
import ModalFooterMonitoring from '@app/components/common/ModalFooter/ModalFooterMonitoring';
import { LogMonitoringModel } from '@app/domain/interfaces/monitoring/logMonitoringModel';

interface IModalTripInfo {
  modalVisible: boolean;
  setModalVisible: Dispatch<SetStateAction<boolean>>;
  monitoring: LogMonitoringModel;
}

const reportService: IReportService = new ReportService();

const ModalTripInfo: React.FC<IModalTripInfo> = ({ modalVisible, setModalVisible, monitoring }) => {
  const printRef = useRef<any>();
  const [construction, setConstruction] = useState<ConstructionModel>();
  const [loading, setLoading] = useState<boolean>(false);

  const columns: ColumnsType<ShipmentModel> = [
    {
      title: 'Remessa',
      dataIndex: 'id',
      showSorterTooltip: false,
      sortDirections: [],
      render: (id, shipment) => (
        <>
          {id}&nbsp;
          <Tooltip title={shipment.traco}>
            <InfoCircleOutlined />
          </Tooltip>
        </>
      ),
    },
    {
      title: 'BT',
      dataIndex: 'betoneira',
      showSorterTooltip: false,
      sortDirections: [],
    },
    {
      title: 'Motorista',
      dataIndex: 'motorista',
      showSorterTooltip: false,
      sortDirections: [],
      render: (motorista, shipment) => (
        <>
          {motorista}&nbsp;
          <Tooltip title={shipment.motoristaMatricula}>
            <InfoCircleOutlined />
          </Tooltip>
        </>
      ),
    },
    {
      title: 'Peça',
      dataIndex: 'peca',
      showSorterTooltip: false,
      sortDirections: [],
    },
    {
      title: 'Volume',
      dataIndex: 'volume',
      showSorterTooltip: false,
      sortDirections: [],
      render: (volume) => (volume ? `${volume}m³` : '-'),
    },
    {
      title: 'Água retida',
      dataIndex: 'aguaRetida',
      showSorterTooltip: false,
      sortDirections: [],
      render: (agua) => (agua ? `${agua}L` : '-'),
    },
    {
      title: 'Água central',
      dataIndex: 'agua',
      showSorterTooltip: false,
      sortDirections: [],
      render: (_, shipment) => (shipment.agua?.central ? `${shipment.agua?.central.toFixed(1)}L` : '-'),
    },
    {
      title: 'Água obra',
      dataIndex: 'agua',
      showSorterTooltip: false,
      sortDirections: [],
      render: (_, shipment) => (shipment.agua?.obra ? `${shipment.agua?.obra.toFixed(1)}L` : '-'),
    },
    {
      title: 'Sobra da água',
      dataIndex: 'agua',
      showSorterTooltip: false,
      sortDirections: [],
      render: (_, shipment) => (shipment.agua?.sobraM3 ? `${shipment.agua?.sobraM3.toFixed(1)}L/m³` : '-'),
    },
    {
      title: 'Central',
      dataIndex: 'tempo',
      showSorterTooltip: false,
      sortDirections: [],
      render: (tempo: TimeModel) => minutesToHoursAndMinutes(tempo?.central),
    },
    {
      title: 'A caminho',
      dataIndex: 'tempo',
      showSorterTooltip: false,
      sortDirections: [],
      render: (tempo: TimeModel) => minutesToHoursAndMinutes(tempo?.aCaminho),
    },
    {
      title: 'Aguardando',
      dataIndex: 'tempo',
      showSorterTooltip: false,
      sortDirections: [],
      render: (tempo: TimeModel) => minutesToHoursAndMinutes(tempo?.aguardando),
    },
    {
      title: 'Descarregando',
      dataIndex: 'tempo',
      showSorterTooltip: false,
      sortDirections: [],
      render: (tempo: TimeModel) => minutesToHoursAndMinutes(tempo?.descarregando),
    },
    {
      title: 'Tempo total',
      dataIndex: 'tempo',
      showSorterTooltip: false,
      sortDirections: [],
      render: (tempo: TimeModel) => minutesToHoursAndMinutes(tempo?.total),
    },
    {
      title: 'Estado atual',
      dataIndex: 'estado',
      showSorterTooltip: false,
      sortDirections: [],
      align: 'center',
      render: (estado) =>
        estado == 8 ? (
          <CheckCircleOutlined style={{ color: '#159D3B' }} />
        ) : (
          <Tag textColor="#383838" bgColor="#E8E8E8" style={{ fontSize: '0.75rem' }}>
            {renderMixerStatus(estado)}
          </Tag>
        ),
    },
  ];

  useEffect(() => {
    if (monitoring.obraId > 0 && modalVisible) {
      setLoading(true);

      reportService
        .getMixerReportByConstruction(monitoring.obraId)
        .then((res) => {
          if (!res?.obras?.[0].id) {
            setConstruction({
              remessas: [
                {
                  ...res?.obras?.[0].remessas?.[0],
                  betoneira:
                    monitoring.betoneirasAguardando.contador > 0
                      ? monitoring.betoneirasAguardando.betoneiras[0]
                      : monitoring.betoneirasCaminho.contador > 0
                      ? monitoring.betoneirasCaminho.betoneiras[0]
                      : monitoring.betoneirasDescarregando.contador > 0
                      ? monitoring.betoneirasDescarregando.betoneiras[0]
                      : monitoring.betoneirasPatio.contador > 0
                      ? monitoring.betoneirasPatio.betoneiras[0]
                      : '',
                  conectado: res?.obras?.[0].remessas?.[0].conectado,
                  betoneiraLocalizacao: res?.obras?.[0].remessas?.[0].betoneiraLocalizacao,
                },
              ],
            });

            notificationController.info({
              message: 'Betoneira não está em viagem no momento.',
            });
            setLoading(false);
            return;
          }

          setConstruction(res?.obras?.[0]);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
          setConstruction({});
          notificationController.error({
            message: 'Houve um erro ao buscar dados da betoneira.',
          });
        });

      return;
    }
  }, [monitoring]);

  return (
    <ModalFooterMonitoring
      title="Informações das viagens"
      open={modalVisible}
      onCancel={() => setModalVisible(false)}
      bodyStyle={{ overflowY: 'auto' }}
    >
      <Spinner spinning={loading}>
        <div ref={printRef}>
          <Row align={'bottom'}>
            <Col xs={24} md={3}>
              <S.FooterInfoCardDescription>Obra</S.FooterInfoCardDescription>
              <S.FooterInfoCardValue>{construction?.obra ?? '-'}</S.FooterInfoCardValue>
            </Col>
            <Col xs={24} md={15}>
              <S.FooterInfoCardDescription>Endereço</S.FooterInfoCardDescription>
              <S.FooterInfoCardValue>{construction?.obraEndereco ?? '-'}</S.FooterInfoCardValue>
            </Col>
            <Col xs={24} md={6}>
              <Row justify={'end'} gutter={18}>
                <Col>
                  <S.FooterInfoCardLinkButton
                    onClick={() =>
                      exportShipmentToPdf(construction?.remessas ?? [], construction?.obra, construction?.obraEndereco)
                    }
                  >
                    <FilePdfOutlined /> Exportar pdf
                  </S.FooterInfoCardLinkButton>
                </Col>
                <Col>
                  <S.FooterInfoCardLinkButton onClick={() => exportShipmentToExcel(construction?.remessas ?? [])}>
                    <FileExcelOutlined /> Exportar excel
                  </S.FooterInfoCardLinkButton>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row style={{ marginTop: '1rem', marginBottom: '1rem' }}>
            <Col xs={24}>
              <Table columns={columns} dataSource={construction?.remessas} bordered pagination={{ pageSize: 4 }} />
            </Col>
          </Row>
        </div>
      </Spinner>
    </ModalFooterMonitoring>
  );
};

export default ModalTripInfo;
