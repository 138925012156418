import { FC, useEffect, useRef } from 'react';
import ApexCharts from 'apexcharts';
import * as S from './SlumpHistoryChart.style';
import { ReactComponent as Logo } from '@app/assets/small-logo.svg';
import { Col, Row } from 'antd';
import { CentralTrackingShippingSlumpModel } from '@app/domain/interfaces/centralTracking/CentralTrackingModel';
import moment from 'moment';

interface SlumpHistoryChartProps {
  slumpMax: number;
  slumpMin: number;
  travelId: number;
  slumpHistory: CentralTrackingShippingSlumpModel[];
}

const SlumpHistoryChart: FC<SlumpHistoryChartProps> = ({ slumpHistory, slumpMin, slumpMax, travelId }) => {
  const chartRef = useRef<HTMLDivElement | null>(null);
  const chartInstance = useRef<ApexCharts | null>(null);

  useEffect(() => {
    if (chartRef.current && !chartInstance.current) {
      const optionsSlump = {
        chart: {
          animations: {
            enabled: true,
            easing: 'easeinout',
            speed: 800,
          },
          id: 'slump',
          type: 'line',
          group: 'chart',
          width: '100%',
          height: 250,
          toolbar: {
            show: false,
          },
        },
        legend: {
          show: false,
          showForSingleSeries: true,
          position: 'top',
          horizontalAlign: 'right',
          fontSize: '14px',
          fontFamily: 'Mulish',
          fontWeight: 600,
          fontStyle: 'normal',
          markers: {
            width: 24,
            height: 5,
            radius: 0,
          },
        },
        tooltip: {
          style: {
            fontFamily: 'Mulish',
          },
        },
        grid: {
          padding: {
            left: 20,
            right: 20,
          },
        },
        colors: ['#707070', '#374450'],
        series: [
          {
            type: 'line',
            name: 'Slump',
            data: slumpHistory
              ?.filter((s) => s.slump && s.slump >= 0)
              ?.map((s) => ({ y: s.slump?.toFixed(1), x: new Date(s.dataHora ?? 0).getTime() }))
              .sort((a, b) => b.x - a.x),
            markers: {
              size: 5,
              strokeWidth: 0,
              hover: {
                size: 8,
              },
            },
          },
        ],
        yaxis: {
          type: 'numeric',
          title: {
            text: 'Slump',
            style: {
              fontSize: '16px',
              fontFamily: 'Mulish',
              fontWeight: 600,
            },
          },
          min: 0,
          max: 30,
          tickAmount: 6,
          tickPlacement: 'between',
          labels: {
            style: {
              fontSize: '16px',
              fontFamily: 'Mulish',
              fontWeight: 600,
            },
            formatter: (value: number) => value?.toFixed(1),
          },
        },
        xaxis: {
          type: 'datetime',
          tickPlacement: 'on',
          labels: {
            rotateAlways: true,
            datetimeUTC: true,
            formatter: function (value: number) {
              const dataPoints = slumpHistory.map((s) => new Date(s.dataHora).getTime());
              const min = Math.min(...dataPoints);
              const max = Math.max(...dataPoints);
              value = Math.round(value);

              if (value === min || value === max) {
                return moment(value).format('HH:mm');
              }
              return '';
            },
          },
          tooltip: {
            enabled: false,
          },
        },
        stroke: {
          width: [2],
        },
        annotations: {
          yaxis: [
            {
              y: slumpMin,
              y2: slumpMax,
              strokeDashArray: 0,
              borderWidth: 50,
              borderColor: '#FAAD14',
              fillColor: '#FCCE72B2',
              opacity: 0.9,
            },
          ],
        },
      };
      chartInstance.current = new ApexCharts(chartRef.current, optionsSlump);
      chartInstance.current.render();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (chartInstance.current) {
      chartInstance.current.updateSeries(
        [
          {
            data: slumpHistory
              ?.filter((s) => s.slump && s.slump >= 0 && s.dataHora)
              ?.map((s) => ({ y: s.slump?.toFixed(1), x: new Date(s.dataHora).getTime() }))
              .sort((a, b) => a.x - b.x),
          },
        ],
        true,
      );
    }
  }, [slumpHistory]);

  return (
    <S.SlumpHistoryChartCardContainer>
      <S.SlumpHistoryChartCardHeader>
        <Row justify={'space-between'} align={'middle'}>
          <Col span={2}>
            <Row justify={'center'}>
              <Logo />
            </Row>
          </Col>
          <Col span={20}>Slump na central</Col>
          <Col span={2}>
            <Row justify={'center'}>X</Row>
          </Col>
        </Row>
      </S.SlumpHistoryChartCardHeader>
      <Row justify={'end'} style={{ paddingTop: '2rem' }}>
        <Col>
          <Row>Data/hora última atualização</Row>
          <Row>
            {slumpHistory.length
              ? moment(slumpHistory[slumpHistory.length - 1]?.dataHora).format('DD/MM/YY HH:mm')
              : '-'}
          </Row>
        </Col>
      </Row>
      <Row style={{ paddingTop: '0.5rem', width: '100%' }}>
        <Col span={24}>
          <div ref={chartRef} id={`chart_${travelId}`}></div>
        </Col>
      </Row>
    </S.SlumpHistoryChartCardContainer>
  );
};

export default SlumpHistoryChart;
