import { FONT_SIZE } from '@app/styles/themes/constants';
import { Modal as AntModal } from 'antd';
import styled from 'styled-components';

export const Modal = styled(AntModal)`
  .ant-modal-header {
    background: var(--background-base-color);
  }

  .ant-modal-title {
    color: var(--text-label);
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 1.75rem;
    display: flex;
    align-items: center;
  }

  .ant-modal-close-icon {
    color: var(--text-label);
  }

  .ant-modal-body {
    font-size: ${FONT_SIZE.xs};
  }

  .ant-modal-content {
    border-radius: 0.5rem;
  }

  .ant-col {
    display: flex;
  }

  .span-bold {
    font-weight: 800;
  }
`;
