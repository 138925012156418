import { InfoCircleOutlined } from '@ant-design/icons';
import { Description } from '@app/components/common/Description/Description';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { BaseFormInputItem } from '@app/components/common/forms/components/BaseFormInputItem/BaseFormInputItem';
import { Input } from '@app/components/common/inputs/Input/Input';
import { PageContainer } from '@app/components/common/PageContainer/PageContainer';
import appSettings from '@app/config/appsettings';
import { notificationController } from '@app/controllers/notificationController';
import { ClientModel } from '@app/domain/interfaces/clientModel';
import {
  ClientBranchModel,
  ClientBranchRegionals,
  ClientBranchReturnModel,
} from '@app/domain/interfaces/client_branch/clientBranchModel';
import { GroupBranchModel } from '@app/domain/interfaces/client_branch/groupBranchModel';
import { GroupModel } from '@app/domain/interfaces/client_branch/groupModel';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { useMounted } from '@app/hooks/useMounted';
import IClientBranchService, { ClientBranchService } from '@app/services/clientBranchService';
import IClientService, { ClientService } from '@app/services/clientService';
import IGroupService, { GroupService } from '@app/services/groupService';
import { isUserMaster, readUserFilter } from '@app/services/localStorage.service';
import { setFooter } from '@app/store/slices/footerPageSlice';
import { setHeaderRegisterPage } from '@app/store/slices/headerRegisterPage';
import { Col, Modal, Row, Select, Tag } from 'antd';
import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Radio, RadioGroup } from '@app/components/common/Radio/Radio';
import { IconMap } from '@app/assets/slump-icons';
import MapBranchLocation from './components/MapBranchLocation';
import { Button } from '@app/components/common/buttons/Button/Button';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { HelperNotification } from '@app/components/common/HelperNotification/HelperNotification';
import { Checkbox } from '@app/components/common/Checkbox/Checkbox';
import * as S from './Create.styles';
import { ChargingPoint } from './Create.styles';
import { Spinner } from '@app/components/common/Spinner/Spinner';
import IRegionalService, { RegionalService } from '@app/services/regionalService';
import { RegionalGroupModel } from '@app/domain/interfaces/regional/regionalModel';

const clientService: IClientService = new ClientService();
const groupService: IGroupService = new GroupService();
const clientBranchService: IClientBranchService = new ClientBranchService();
const regionalService: IRegionalService = new RegionalService();

interface ClientBranchValidationFields {
  idCliente?: boolean | undefined;
  nome?: boolean | undefined;
  cep?: boolean | undefined;
  codigoFilialSiac?: boolean | undefined;
  latitude?: boolean | undefined;
  longitude?: boolean | undefined;
  raio?: boolean | undefined;
}

interface ChargingPoint {
  id: number;
  selected: boolean;
}

const ClientBranchCreate: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isMounted } = useMounted();
  const [loading, setLoading] = useState(false);
  const [mapVisible, setMapVisible] = useState(false);
  const [modalCancelVisible, setModalCancelVisible] = useState(false);
  const [groups, setGroups] = useState<GroupModel[]>();
  const [regionals, setRegionals] = useState<RegionalGroupModel[]>();
  const [groupsSelecteds, setGroupsSelecteds] = useState<number | null>(null);
  const [regionalsSelecteds, setRegionalsSelecteds] = useState<number[]>([]);
  const [clients, setClients] = useState<ClientModel[]>([]);
  const [client, setClient] = useState<ClientModel>({} as ClientModel);
  const [branches, setBranches] = useState<ClientBranchModel[]>([]);
  const [branch, setBranch] = useState<ClientBranchModel>({} as ClientBranchModel);
  const [branchValidation, setBranchValidation] = useState<ClientBranchValidationFields>(
    {} as ClientBranchValidationFields,
  );
  const [chargingPointsCheckbox, setChargingPointsCheckbox] = useState<ChargingPoint[]>([
    { id: 1, selected: true },
    { id: 2, selected: false },
    { id: 3, selected: false },
  ]);

  const handlerChangeLatLng = async (e: React.FormEvent<HTMLInputElement>) => {
    let value = e.currentTarget.value
      .replace(/[^\d.-]/g, '')
      .replace(/^-{2,}/, '-')
      .replace(/\.(?=.*\.)/g, '');

    if (value.length >= 4 && !value.includes('.')) {
      if (value.includes('-')) {
        value = value + '.';
      } else {
        value = value.slice(0, 3) + '.' + value.slice(3, 4);
      }
    }

    setBranch({ ...branch, [e.currentTarget.name]: Number(value) });
  };
  const handlerChangeCep = async (e: React.FormEvent<HTMLInputElement>) => {
    const x = e.currentTarget.value.replace(/\D/g, '').match(/(\d{0,5})(\d{0,3})/);

    if (x !== null) {
      const cep = !x[2] ? x[1] : x[1] + '-' + x[2];

      try {
        if (cep?.length === 9) {
          setLoading(true);
          const cepResponse = await axios.get(appSettings().APIs.Consulta.CEP + cep.replace('-', '') + '/json');

          if (cepResponse.data.erro == 'true') {
            throw new Error('CEP Inválido');
          }
          setBranch({
            ...branch,
            cep: cep,
            logradouro: cepResponse.data.logradouro,
            cidade: cepResponse.data.localidade,
            estado: cepResponse.data.uf,
            bairro: cepResponse.data.bairro,
            numero: cepResponse.data.complemento,
            // complemento: cepResponse.data.complemento,
          });
          setBranchValidation({
            ...branchValidation,
            cep: true,
          });
          setLoading(false);
          return;
        } else {
          throw new Error('CEP Inválido');
        }
      } catch (error) {
        setLoading(false);
        setBranchValidation({ ...branchValidation, cep: false });
        setBranch({
          ...branch,
          cep: cep,
          logradouro: '',
          cidade: '',
          estado: '',
          bairro: '',
          complemento: '',
        });
      }
    }
  };
  const handlerChangeBranch = (e: React.FormEvent<HTMLInputElement>) => {
    setBranch({ ...branch, [e.currentTarget.name]: e.currentTarget.value });
    setBranchValidation({
      ...branchValidation,
      [e.currentTarget.name]: e.currentTarget.value?.length > 0,
    });
  };

  const handlerOnSelectGroup = async (value: number) => {
    setGroupsSelecteds(value);

    await fetchRegionals(value);
  };

  const handlerOnSelectRegional = async (value: number) => {
    setRegionalsSelecteds(regionalsSelecteds.concat(value));
  };

  const handlerOnDeselectBranch = async (value: number) => {
    setBranch({ ...branch, filiaisRetorno: branch.filiaisRetorno.filter((f) => f.id != value) });
  };

  const handlerOnSelectBranch = async (value: number) => {
    if ((branch.filiaisRetorno ? branch.filiaisRetorno.length : 0) < 8) {
      const newBranch = branches.find((f) => f.id == value) as ClientBranchReturnModel;

      if (newBranch) {
        setBranch({
          ...branch,
          filiaisRetorno: branch.filiaisRetorno ? [...branch.filiaisRetorno, newBranch] : [newBranch],
        });
      }
    }
  };

  const handleSaveBranch = async () => {
    setLoading(true);

    if (branch.id) {
      await clientBranchService
        .update('', {
          ...branch,
          agrupamentos: { idAgrupamento: id, idFilial: branch.id } as unknown as GroupBranchModel,
          regionais: regionalsSelecteds.map((r) => ({ idFilial: branch.id, idRegional: r })) as ClientBranchRegionals[],
        })
        .then(() => {
          setLoading(false);
          notificationController.success({ message: 'Filial atualizada com sucesso' });
          navigate('/gestao/filiais');
        })
        .catch((error) => {
          notificationController.error(error);
          setLoading(false);
        });
    } else {
      await clientBranchService
        .post('', {
          ...branch,
          agrupamentos: { idAgrupamento: id } as unknown as GroupBranchModel,
          regionais: regionalsSelecteds.map((r) => ({ idFilial: branch.id, idRegional: r })) as ClientBranchRegionals[],
        })
        .then(() => {
          setLoading(false);
          notificationController.success({ message: 'Filial criada com sucesso' });
          navigate('/gestao/filiais');
        })
        .catch((error) => {
          notificationController.error(error);
          setLoading(false);
        });
    }
  };

  const handleMultiportalOnChange = () => setBranch({ ...branch, isMultiportal: !branch.isMultiportal });

  const fetchGroups = useCallback(async (id: number) => {
    setLoading(true);
    setGroups(await groupService.getArray(id.toString()));
    setLoading(false);
  }, []);

  const fetchRegionals = useCallback(async (id: number) => {
    setLoading(true);
    setRegionals(await regionalService.getArray(id.toString()));
    setLoading(false);
  }, []);

  const fetchClients = useCallback(async () => {
    setLoading(true);
    setClients(await clientService.getArray('obter'));
    setLoading(false);
  }, []);

  const fetchBranches = useCallback(async (idCliente: number) => {
    setLoading(true);
    const branches = await clientBranchService.getArray(`${idCliente}`);
    setBranches(branches.filter((b) => b.id != parseInt(id ?? '0')));
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (id) {
      clientBranchService.get(`obterPorId/${id}`).then((res) => {
        setBranch(res);
        setChargingPointsCheckbox((prevState) => [
          { ...prevState[0], selected: !!res.codigoPontoDeCarga01 },
          { ...prevState[1], selected: !!res.codigoPontoDeCarga02 },
          { ...prevState[2], selected: !!res.codigoPontoDeCarga03 },
        ]);

        fetchGroups(res.idCliente);
        fetchBranches(res.idCliente);
        setGroupsSelecteds(res?.agrupamentos?.idAgrupamento);
        setBranchValidation({
          ...branchValidation,
          cep: res.cep?.length > 0,
          idCliente: res.idCliente > 0,
          nome: res.nome?.length > 0,
          latitude: res.latitude ? true : false,
          longitude: res.longitude ? true : false,
          raio: res.raio ? true : false,
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    const filter = readUserFilter();

    if (isMounted.current && filter.client?.id) {
      fetchGroups(filter.client.id);
      setClient(filter.client);
      setBranch({ ...branch, idCliente: filter.client.id });
    }

    fetchClients();

    dispatch(
      setHeaderRegisterPage({
        title: id ? 'Editar filial' : 'Nova filial',
        handleBackClick: () => navigate('/gestao/filiais'),
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (branch?.idCliente) {
      setBranchValidation({ ...branchValidation, idCliente: true });
      fetchGroups(branch?.idCliente);
      fetchBranches(branch?.idCliente);
      setGroupsSelecteds(null);
    } else {
      setBranchValidation({ ...branchValidation, idCliente: undefined });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [branch.idCliente]);

  useEffect(() => {
    //usado para atualizar botao de confirmar e a função
    dispatch(
      setFooter({
        confirmButtonDisabled: !(
          branch.idCliente &&
          branch.cep &&
          branch.nome &&
          branch.latitude &&
          branch.longitude &&
          branch.raio &&
          ((chargingPointsCheckbox[0].selected && !!branch.codigoPontoDeCarga01) ||
            (chargingPointsCheckbox[1].selected && !!branch.codigoPontoDeCarga02) ||
            (chargingPointsCheckbox[2].selected && !!branch.codigoPontoDeCarga03))
        ),
        confirmButtonText: id ? 'Editar filial' : 'Cadastrar filial',
        cancelButtonText: 'Cancelar',
        handleConfirmButtonClick: handleSaveBranch,
        handleCancelButtonClick: () => setModalCancelVisible(true),
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [branch, dispatch, groupsSelecteds, handleSaveBranch, id]);

  return (
    <>
      <PageTitle>Filiais</PageTitle>
      <Modal
        title={`Cancelar ${id ? 'edição' : 'cadastro'} da filial`}
        visible={modalCancelVisible}
        onOk={() => navigate('/gestao/filiais')}
        onCancel={() => setModalCancelVisible(false)}
        cancelText="Cancelar"
        okText="Confirmar"
      >
        <Row align="middle">
          <Col span={2}>
            <InfoCircleOutlined size={20} style={{ color: '#FAAD14' }} />
          </Col>
          <Col span={22}>Deseja realmente cancelar {id ? 'a edição' : 'o cadastro'} da filial?</Col>
        </Row>
      </Modal>
      <PageContainer>
        <BaseForm layout="vertical" style={{ width: '100%' }}>
          <Spinner spinning={loading}>
            <Description
              title="Informações da filial"
              subtitle="Preencha os campos para cadastrar uma nova filial para o cliente"
            >
              <Row gutter={18}>
                <HelperNotification style={{ maxWidth: '848px' }}>
                  Obrigatório selecionar pelo menos um ponto de carga.
                </HelperNotification>
              </Row>
              <Row gutter={18}>
                <Col xs={24} md={8}>
                  <BaseFormInputItem
                    label="Concreteiras"
                    isSuccess={branchValidation?.idCliente}
                    errorText="Selecione uma concreteira"
                  >
                    <Select
                      disabled={!isUserMaster()}
                      showSearch
                      showArrow
                      placeholder="Selecione a concreteira"
                      value={branch?.idCliente}
                      onSelect={(value) => setBranch({ ...branch, idCliente: value })}
                      style={{ width: '100%' }}
                      options={clients?.map((client) => ({ value: client.id, label: client.razaoSocial }))}
                      filterOption={(input, option) =>
                        (option?.label?.toLowerCase() ?? '').indexOf(input.toLowerCase()) >= 0
                      }
                    />
                  </BaseFormInputItem>
                </Col>
                <Col xs={24} md={8}>
                  <BaseFormInputItem
                    label="Nome filial"
                    supportText="Como a filial vai ficar conhecida no sistema"
                    isSuccess={branchValidation?.nome}
                    errorText="Nome inválido"
                  >
                    <Input
                      name="nome"
                      placeholder="Informe o Nome da filial"
                      value={branch.nome}
                      onChange={handlerChangeBranch}
                    />
                  </BaseFormInputItem>
                </Col>
                <Col xs={24} md={8}>
                  <BaseFormInputItem label="Codigo do SIAC" isSuccess={branchValidation?.codigoFilialSiac}>
                    <Input
                      name="codigoFilialSiac"
                      placeholder="Digite o código do SIAC"
                      value={branch.codigoFilialSiac}
                      onChange={handlerChangeBranch}
                    />
                  </BaseFormInputItem>
                </Col>
              </Row>
              <Row gutter={18} style={{ display: 'flex', alignItems: 'center', gap: '1.5rem' }}>
                <S.ChargingPoint>
                  <Checkbox
                    checked={chargingPointsCheckbox[0].selected}
                    onChange={(e) => {
                      const checked = e.target.checked;
                      setChargingPointsCheckbox([
                        { ...chargingPointsCheckbox[0], selected: checked },
                        { ...chargingPointsCheckbox[1] },
                        { ...chargingPointsCheckbox[2] },
                      ]);
                      if (!checked) {
                        setBranch((prevState) => ({
                          ...prevState,
                          codigoPontoDeCarga01: undefined,
                        }));
                      }
                    }}
                  >
                    P1
                  </Checkbox>
                  <BaseFormInputItem label={'Código P1'} errorText="Código inválido">
                    <Input
                      style={{ width: '143px' }}
                      placeholder="Digite o código"
                      value={branch.codigoPontoDeCarga01}
                      disabled={!chargingPointsCheckbox[0].selected && branch.codigoPontoDeCarga01 === undefined}
                      maxLength={8}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      onChange={(e) => {
                        const value = e.currentTarget.value;
                        if (value === '' || /^\d*$/.test(value)) {
                          setBranch((prevState) => ({
                            ...prevState,
                            codigoPontoDeCarga01: value === '' ? undefined : parseInt(value, 10),
                          }));
                        }
                      }}
                    />
                  </BaseFormInputItem>
                </S.ChargingPoint>
                <S.ChargingPoint>
                  <Checkbox
                    checked={chargingPointsCheckbox[1].selected}
                    onChange={(e) => {
                      const checked = e.target.checked;
                      setChargingPointsCheckbox([
                        { ...chargingPointsCheckbox[0] },
                        { ...chargingPointsCheckbox[1], selected: checked },
                        { ...chargingPointsCheckbox[2] },
                      ]);
                      if (!checked) {
                        setBranch((prevState) => ({
                          ...prevState,
                          codigoPontoDeCarga02: undefined,
                        }));
                      }
                    }}
                  >
                    P2
                  </Checkbox>
                  <BaseFormInputItem label="Código P2" errorText="Código inválido">
                    <Input
                      style={{ width: '143px' }}
                      placeholder="Digite o código"
                      value={branch.codigoPontoDeCarga02}
                      disabled={!chargingPointsCheckbox[1].selected && branch.codigoPontoDeCarga02 === undefined}
                      maxLength={8}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      onChange={(e) => {
                        const value = e.currentTarget.value;
                        if (value === '' || /^\d*$/.test(value)) {
                          setBranch((prevState) => ({
                            ...prevState,
                            codigoPontoDeCarga02: value === '' ? undefined : parseInt(value, 10),
                          }));
                        }
                      }}
                    />
                  </BaseFormInputItem>
                </S.ChargingPoint>
                <S.ChargingPoint>
                  <Checkbox
                    checked={chargingPointsCheckbox[2].selected}
                    onChange={(e) => {
                      const checked = e.target.checked;
                      setChargingPointsCheckbox([
                        { ...chargingPointsCheckbox[0] },
                        { ...chargingPointsCheckbox[1] },
                        { ...chargingPointsCheckbox[2], selected: checked },
                      ]);
                      if (!checked) {
                        setBranch((prevState) => ({
                          ...prevState,
                          codigoPontoDeCarga03: undefined,
                        }));
                      }
                    }}
                  >
                    P3
                  </Checkbox>
                  <BaseFormInputItem label="Código P3" errorText="Código inválido">
                    <Input
                      style={{ width: '143px' }}
                      placeholder="Digite o código"
                      value={branch.codigoPontoDeCarga03}
                      disabled={!chargingPointsCheckbox[2].selected && branch.codigoPontoDeCarga03 === undefined}
                      maxLength={8}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      onChange={(e) => {
                        const value = e.currentTarget.value;
                        if (value === '' || /^\d*$/.test(value)) {
                          setBranch((prevState) => ({
                            ...prevState,
                            codigoPontoDeCarga03: value === '' ? undefined : parseInt(value, 10),
                          }));
                        }
                      }}
                    />
                  </BaseFormInputItem>
                </S.ChargingPoint>
              </Row>
            </Description>
            <Description title="Endereço" subtitle="Preencha os campos abaixo para informar o endereço da filial">
              <Row gutter={18}>
                <Col xs={24} md={6}>
                  <BaseFormInputItem
                    label="Endereço"
                    supportText="8 caracteres somente números"
                    isSuccess={branchValidation?.cep}
                    errorText="CEP inválido"
                  >
                    <Input name="cep" placeholder="Informe o CEP" value={branch.cep} onChange={handlerChangeCep} />
                  </BaseFormInputItem>
                </Col>
              </Row>
              <Row gutter={18} align={'bottom'}>
                <Col xs={24} md={8}>
                  <BaseFormInputItem label="Rua/Avenida">
                    <Input
                      name="logradouro"
                      placeholder="Digite a rua/avenida"
                      value={branch.logradouro}
                      onChange={handlerChangeBranch}
                    />
                  </BaseFormInputItem>
                </Col>
                <Col xs={24} md={8}>
                  <BaseFormInputItem label="Cidade">
                    <Input name="cidade" placeholder="Cidade" value={branch.cidade} onChange={handlerChangeBranch} />
                  </BaseFormInputItem>
                </Col>
                <Col xs={24} md={8}>
                  <BaseFormInputItem label="Estado">
                    <Input name="estado" placeholder="Estado" value={branch.estado} onChange={handlerChangeBranch} />
                  </BaseFormInputItem>
                </Col>
              </Row>
              <Row gutter={18}>
                <Col xs={24} md={8}>
                  <BaseFormInputItem label="Bairro">
                    <Input name="bairro" placeholder="Bairro" value={branch.bairro} onChange={handlerChangeBranch} />
                  </BaseFormInputItem>
                </Col>
                <Col xs={24} md={4}>
                  <BaseFormInputItem label="Número">
                    <Input
                      name="numero"
                      placeholder="Digite o Número"
                      value={branch.numero}
                      onChange={handlerChangeBranch}
                    />
                  </BaseFormInputItem>
                </Col>
                <Col xs={24} md={4}>
                  <BaseFormInputItem label="Complemento">
                    <Input
                      name="complemento"
                      placeholder="Complemento"
                      value={branch.complemento}
                      onChange={handlerChangeBranch}
                    />
                  </BaseFormInputItem>
                </Col>
              </Row>
            </Description>
            <Description title="Localização" subtitle="Preencha os dados de latitude, longitude e raio">
              <Row gutter={18}>
                <Col xs={24} md={4}>
                  <BaseFormInputItem label="Latitude">
                    <Input
                      name="latitude"
                      placeholder="Digite o valor da latitude"
                      value={branch.latitude}
                      onChange={handlerChangeLatLng}
                    />
                  </BaseFormInputItem>
                </Col>
                <Col xs={24} md={4}>
                  <BaseFormInputItem label="Longitude">
                    <Input
                      name="longitude"
                      placeholder="Digite o valor da longitude"
                      value={branch.longitude}
                      onChange={handlerChangeLatLng}
                    />
                  </BaseFormInputItem>
                </Col>
                <Col xs={24} md={4}>
                  <BaseFormInputItem label="Raio" supportText="Metros">
                    <Input
                      name="raio"
                      type="number"
                      placeholder="Digite o Raio"
                      value={branch.raio}
                      onChange={handlerChangeBranch}
                    />
                  </BaseFormInputItem>
                </Col>
                <Col xs={24} md={4} style={{ display: 'flex', alignItems: 'end', paddingBottom: '2.55rem' }}>
                  <Button
                    type="primary"
                    block
                    icon={<IconMap />}
                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                    onClick={() => setMapVisible(!mapVisible)}
                  >
                    {!mapVisible ? 'Visualizar mapa' : 'Ocultar mapa'}
                  </Button>
                </Col>
              </Row>
              {mapVisible && (
                <Row>
                  <MapBranchLocation branch={branch} setBranch={setBranch} />
                </Row>
              )}
            </Description>
            <Description
              title="Agrupamento de filiais"
              subtitle="Selecione os grupos que você deseja realizar o agrupamento"
            >
              <Row gutter={18}>
                <Col xs={24} md={8}>
                  <BaseFormInputItem label="Agrupamentos" supportText="Selecione um ou mais grupos">
                    <Select
                      showSearch
                      showArrow
                      placeholder="Selecione grupos que a filial pertence"
                      value={groupsSelecteds}
                      onSelect={handlerOnSelectGroup}
                      style={{ width: '100%' }}
                      options={groups?.map((group) => ({ value: group.id, label: group.nome }))}
                    />
                  </BaseFormInputItem>
                </Col>
                {regionals && regionals?.length > 0 && (
                  <Col xs={24} md={8}>
                    <BaseFormInputItem label="Regionais" supportText="Selecione uma ou mais regionais">
                      <Select
                        showSearch
                        showArrow
                        mode="tags"
                        placeholder="Selecione regionais que a filial pertence"
                        value={regionalsSelecteds}
                        onSelect={handlerOnSelectRegional}
                        style={{ width: '100%' }}
                        options={regionals?.map((regional) => ({ value: regional.id, label: regional.nome }))}
                        tagRender={(props) => (
                          <Tag key={props.value} closable onClose={props.onClose}>
                            {props.label}
                          </Tag>
                        )}
                      />
                    </BaseFormInputItem>
                  </Col>
                )}
              </Row>
              <Row gutter={18}>
                <Col xs={24} md={8}>
                  <BaseFormInputItem label="Multiportal">
                    <RadioGroup
                      name="isMultiportal"
                      onChange={handleMultiportalOnChange}
                      value={branch.isMultiportal ? 1 : 0}
                    >
                      <Radio value={0}>Não</Radio>
                      <Radio value={1}>Sim</Radio>
                    </RadioGroup>
                  </BaseFormInputItem>
                </Col>
              </Row>
            </Description>
            <Description title="Retornos da filial" subtitle="Selecione as possíveis filiais de retorno para a atual">
              <Row gutter={18}>
                <Col xs={24} md={8}>
                  <BaseFormInputItem label="Retorno" supportText="Selecione no máximo 8">
                    <Select
                      showSearch
                      showArrow
                      mode="multiple"
                      placeholder="Selecione as filiais de retorno"
                      value={branch.filiaisRetorno?.map((f) => f.id)}
                      onSelect={handlerOnSelectBranch}
                      onDeselect={handlerOnDeselectBranch}
                      style={{ width: '100%' }}
                      options={branches?.map((b) => ({ value: b.id, label: b.nome }))}
                      tagRender={(props) => (
                        <Tag key={props.value} closable onClose={props.onClose}>
                          {props.label}
                        </Tag>
                      )}
                    />
                  </BaseFormInputItem>
                </Col>
              </Row>
            </Description>
          </Spinner>
        </BaseForm>
      </PageContainer>
    </>
  );
};

export default ClientBranchCreate;
