import * as React from 'react';
const SvgIconRpmShow = () => (
  <svg width="24" height="25" viewBox="0 0 24 25" fill="current" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_9474_47042)">
      <path
        d="M11 17.5783C11 18.1283 11.45 18.5783 12 18.5783C12.55 18.5783 13 18.1283 13 17.5783C13 17.0283 12.55 16.5783 12 16.5783C11.45 16.5783 11 17.0283 11 17.5783ZM11 3.57831V7.57831H13V5.65831C16.39 6.14831 19 9.04831 19 12.5783C19 16.4483 15.87 19.5783 12 19.5783C8.13 19.5783 5 16.4483 5 12.5783C5 10.8983 5.59 9.35831 6.58 8.15831L12 13.5783L13.41 12.1683L6.61 5.36831V5.38831C4.42 7.02831 3 9.62831 3 12.5783C3 17.5483 7.02 21.5783 12 21.5783C16.97 21.5783 21 17.5483 21 12.5783C21 7.60831 16.97 3.57831 12 3.57831H11ZM18 12.5783C18 12.0283 17.55 11.5783 17 11.5783C16.45 11.5783 16 12.0283 16 12.5783C16 13.1283 16.45 13.5783 17 13.5783C17.55 13.5783 18 13.1283 18 12.5783ZM6 12.5783C6 13.1283 6.45 13.5783 7 13.5783C7.55 13.5783 8 13.1283 8 12.5783C8 12.0283 7.55 11.5783 7 11.5783C6.45 11.5783 6 12.0283 6 12.5783Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_9474_47042">
        <rect width="24" height="24" fill="white" transform="translate(0 0.578308)" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgIconRpmShow;
