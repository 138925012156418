import { styled } from 'styled-components';
import { Select as AntSelect } from 'antd';

export const FooterButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 0.5rem;
  margin: 1rem 0;
  margin-top: 3rem;
`;

export const Container = styled.div``;

export const TitleProgress = styled.span`
  display: flex;
  justify-content: start;
  align-items: center;

  color: var(--text-label);
  font-family: 'Mulish';
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  margin-bottom: 1rem;
  margin-top: 1rem;
`;

export const ContainerModal = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  margin-bottom: 1rem;
  margin-top: 0.5rem;
`;

export const WapperModal = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
`;

export const TimeModal = styled.span`
  display: flex;
  justify-content: start;
  align-items: center;

  color: var(--text-label);
  font-family: 'Mulish';
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  margin-left: 0.5rem;
`;

export const Title = styled.h3`
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 2rem;
`;

export const ButtonsMapWrapper = styled.div`
  position: absolute;
  top: 10px;
  right: 60px;

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-start;
  gap: 1.12rem;
  z-index: 100;
  //margin-top: 10px;
  //margin-right: 10px;

  width: auto;
  height: auto;
`;

// export const Collapse = styled(AntCollapse)`
//   margin-top: 2rem;
//   background-color: white;
//   margin-left: -0.75rem;

//   && .ant-collapse-header-text {
//     color: var(--Neutral-Gray-Gray800, #383838);
//     font-family: Mulish;
//     font-size: 18px;
//     font-style: bold;
//     font-weight: 600;
//     line-height: normal;
//   }

//   & .ant-collapse-expand-icon {
//     color: var(--secondary-color);
//     margin-right: 1rem;
//     font-weight: bold;
//   }
// `;

export const Select = styled(AntSelect)`
  &.ant-select-selection-item {
    display: flex;
    flex-direction: row;
  }

  &.ant-select-selection-item::after {
    content: '';
    width: 0.5rem;
    height: 0.5rem;
  }
`;

export const ContainerChangeValue = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: flex-start;
  align-items: center;
`;

export const CollapseHeaderName = styled.span`
  color: var(--Neutral-Gray-Gray800, #383838);
  font-family: Mulish;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const ContainerParamers = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
export const Row = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;
