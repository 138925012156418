import { Description } from '@app/components/common/Description/Description';
import { TabReports } from '@app/components/common/TabsReports/TabsReports';
import { Toolbar } from '@app/components/common/Toolbar/Toolbar';
import { GroupByEnum, GroupedChartEnum } from '@app/constants/enums/chart';
import { TypeReport } from '@app/constants/enums/report';
import { reportTypes } from '@app/constants/reportTypes';
import { notificationController } from '@app/controllers/notificationController';
import { ReportFilterModel } from '@app/domain/interfaces/report/reportFilterModel';
import { ReportTimeConsumptionModel, ReportTimeModel } from '@app/domain/interfaces/report/reportTimeModel';
import IReportService, { ReportService } from '@app/services/reportService';
import { Col, Row } from 'antd';
import { useEffect, useState } from 'react';
import DatetimeChart, { ISeriesChart } from '../components/DatetimeChart';
import { getDifferenceDaysBetweenDates } from '@app/utils/utils';
import { Spinner } from '@app/components/common/Spinner/Spinner';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { Table } from '@app/components/common/Table/Table';
import { ColumnsType } from 'antd/lib/table';
import { ReportWaterModel } from '@app/domain/interfaces/report/reportWaterModel';

const reportService: IReportService = new ReportService();

interface ITimeReportChart {
  filter: ReportFilterModel;
  typeSelecteds: string[];
  handleOpenReportDetailed: (groupedBy: GroupByEnum, datesClicked: string, valueClicked: string) => void;
}

export const TimeReport: React.FC<ITimeReportChart> = ({ filter, typeSelecteds, handleOpenReportDetailed }) => {
  const [loading, setLoading] = useState(false);
  const [time, setTime] = useState<ReportTimeModel>();
  const [rangeDays, setRangeDays] = useState<number>(0);
  const [activeTabGroupedChart, setActiveTabGroupedChart] = useState<GroupedChartEnum>(GroupedChartEnum.Grouped);
  const [activeTabGroupByDate, setActiveTabGroupByDate] = useState<GroupByEnum>(GroupByEnum.Day);
  const report = reportTypes.find((report) => report.type == TypeReport.Time);

  const columns: ColumnsType<ReportWaterModel> = [
    {
      title: 'BT',
      dataIndex: 'betoneira',
      showSorterTooltip: false,
      width: '8%',
      // sorter: (a, b) => (a.chaveAlerta && b.chaveAlerta ? a.chaveAlerta - b.chaveAlerta : 0),
    },
    {
      title: 'Motorista',
      dataIndex: 'motorista',
      showSorterTooltip: false,
      // sorter: (a, b) => (a.categoria && b.categoria ? a.categoria.localeCompare(b.categoria) : 0),
      width: '15%',
    },
    {
      title: 'Obra',
      dataIndex: 'obra',
      showSorterTooltip: false,
      // sorter: (a, b) => (a.titulo && b.titulo ? a.titulo.localeCompare(b.titulo) : 0),
      width: '10%',
    },
    {
      title: 'Volume',
      dataIndex: 'volume',
      showSorterTooltip: false,
      render: (descricao: string) => {
        return descricao ? descricao : '-';
      },
      width: '10%',
    },
    {
      title: 'Água Central',
      dataIndex: 'aguaCentral',
      showSorterTooltip: false,
      // render: (justificar: number) => {
      //   return justificar ? <Badge color="green" text="Sim" /> : <Badge color="red" text="Não" />;
      // },
      width: '10%',
    },
    {
      title: 'Água Obra',
      dataIndex: 'aguaObra',
      showSorterTooltip: false,
      // render: (explicar: number) => {
      //   return explicar ? <Badge color="green" text="Sim" /> : <Badge color="red" text="Não" />;
      // },
      width: '10%',
    },
    {
      title: 'Sobra d´água',
      dataIndex: 'sobraAgua',
      showSorterTooltip: false,
      // render: (status: number) => {
      //   return status == 0 ? <Badge color="green" text="Ativo" /> : <Badge color="red" text="Inativo" />;
      // },
      width: '10%',
    },
    {
      title: 'Traço',
      dataIndex: 'traco',
      showSorterTooltip: false,
      // render: (status: number) => {
      //   return status == 0 ? <Badge color="green" text="Ativo" /> : <Badge color="red" text="Inativo" />;
      // },
      width: '18%',
    },
    {
      title: 'Data/Hora',
      dataIndex: 'dataHora',
      showSorterTooltip: false,
      // render: (status: number) => {
      //   return status == 0 ? <Badge color="green" text="Ativo" /> : <Badge color="red" text="Inativo" />;
      // },
      width: '10%',
    },
  ];

  const fetchTimeReport = () => {
    setLoading(true);
    reportService
      .getTimeReport({ ...filter, codigosFilialSiac: filter.codigosFilialSiac })
      .then((res) => {
        setTime(res);
        setLoading(false);
      })
      .catch(() => {
        setTime({} as ReportTimeModel);
        notificationController.error({ message: 'Não foi possível encontrar dados para o filtro' });
        setLoading(false);
      });
  };
  const handleChangeTypeSelected = async (value: GroupByEnum) => {
    setLoading(true);
    setTimeout(() => {
      setActiveTabGroupByDate(value);
      setLoading(false);
    }, 1000);
  };

  const handleChangeGroupedTypeSelected = async (value: GroupedChartEnum) => {
    setLoading(true);
    setTimeout(() => {
      setActiveTabGroupedChart(value);
      setLoading(false);
    }, 1000);
  };

  useEffect(() => {
    fetchTimeReport();
    const days = getDifferenceDaysBetweenDates(filter.dataHoraInicio, filter.dataHoraFim);
    setActiveTabGroupByDate(
      days < 31 ? GroupByEnum.Day : days >= 31 && days < 120 ? GroupByEnum.Week : GroupByEnum.Month,
    );
    setRangeDays(days);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  return (
    <Spinner spinning={loading}>
      {(time?.tempos?.length ?? 0) > 0 && (
        <>
          <div style={{ overflow: 'hidden', marginLeft: '3rem', marginRight: '3rem' }}>
            <BaseForm style={{ width: '100%' }}>
              {typeSelecteds.map((type) => report?.children.map((rt) => rt.logicalName === type)).length > 1 ? (
                <Description
                  title="Tempo total (min)"
                  subtitle={`Análise 
                  ${activeTabGroupedChart === 0 ? 'agrupada' : ''}
                  ${activeTabGroupedChart === 1 ? 'individual' : ''}
                  do tempo total`}
                >
                  <Row gutter={18}>
                    <Col xs={24} md={8} xxl={6}>
                      <Toolbar title="Selecione o tipo de visualização">
                        {rangeDays < 31 && (
                          <TabReports
                            onClick={() => handleChangeTypeSelected(GroupByEnum.Day)}
                            dateTime={activeTabGroupByDate === GroupByEnum.Day ? 'day' : undefined}
                            title="Dia"
                          />
                        )}
                        {rangeDays < 120 && (
                          <TabReports
                            onClick={() => handleChangeTypeSelected(GroupByEnum.Week)}
                            dateTime={activeTabGroupByDate === GroupByEnum.Week ? 'week' : undefined}
                            title="Semana"
                          />
                        )}
                        <TabReports
                          onClick={() => handleChangeTypeSelected(GroupByEnum.Month)}
                          dateTime={activeTabGroupByDate === GroupByEnum.Month ? 'month' : undefined}
                          title="Mês"
                        />
                      </Toolbar>
                    </Col>
                    <Col xs={24} md={8} xxl={6}>
                      <Toolbar title="Selecione o tipo de comparação">
                        <TabReports
                          onClick={() => handleChangeGroupedTypeSelected(GroupedChartEnum.Grouped)}
                          dateTime={activeTabGroupedChart === GroupedChartEnum.Grouped ? 'grouped' : undefined}
                          title="Agrupado"
                        />
                        <TabReports
                          onClick={() => handleChangeGroupedTypeSelected(GroupedChartEnum.Individual)}
                          dateTime={activeTabGroupedChart === GroupedChartEnum.Individual ? 'individual' : undefined}
                          title="Individual"
                        />
                      </Toolbar>
                    </Col>
                  </Row>
                </Description>
              ) : (
                <>
                  {typeSelecteds.map((type) => (
                    <div key={type}>
                      <Description
                        title={`${report?.children
                          .filter((reportTimeType) => reportTimeType.logicalName === type)
                          .map((reportTimeType) => reportTimeType.name)} (min)`}
                        subtitle={`Análise do consumo de tempo ${report?.children
                          .filter((reportTimeType) => reportTimeType.logicalName === type)
                          .map((reportTimeType) => reportTimeType.name.toLocaleLowerCase())}`}
                      >
                        <Row gutter={18}>
                          <Col xs={24} md={8} xxl={6}>
                            <Toolbar title="Selecione o tipo de visualização">
                              {rangeDays < 31 && (
                                <TabReports
                                  onClick={() => handleChangeTypeSelected(GroupByEnum.Day)}
                                  dateTime={activeTabGroupByDate === GroupByEnum.Day ? 'day' : undefined}
                                  title="Dia"
                                />
                              )}
                              {rangeDays < 120 && (
                                <TabReports
                                  onClick={() => handleChangeTypeSelected(GroupByEnum.Week)}
                                  dateTime={activeTabGroupByDate === GroupByEnum.Week ? 'week' : undefined}
                                  title="Semana"
                                />
                              )}
                              <TabReports
                                onClick={() => handleChangeTypeSelected(GroupByEnum.Month)}
                                dateTime={activeTabGroupByDate === GroupByEnum.Month ? 'month' : undefined}
                                title="Mês"
                              />
                            </Toolbar>
                          </Col>
                        </Row>
                      </Description>
                    </div>
                  ))}
                </>
              )}
            </BaseForm>
          </div>
          <div id="chart-print">
            {activeTabGroupedChart === GroupedChartEnum.Grouped ? (
              <DatetimeChart
                handleOpenReportDetailed={handleOpenReportDetailed}
                chartId="chart-grouped"
                colors={report?.children.map((reportTimeType) => reportTimeType.color) ?? ['#1E2B53']}
                groupBy={activeTabGroupByDate}
                isSecondGraph={false}
                type="column"
                seriesData={
                  report?.children
                    .filter((reportTimeType) => typeSelecteds.find((value) => value === reportTimeType.logicalName))
                    .map((reportTimeType) => {
                      return {
                        type: 'bar',
                        name: reportTimeType.name,
                        group: reportTimeType?.logicalName == 'total' ? 'total' : 'other',
                        data: time?.tempos?.map((item) => ({
                          x: new Date(item.dataHora).getTime(),
                          y: item[reportTimeType?.logicalName as keyof ReportTimeConsumptionModel] as number,
                          z: item.qtdViagens,
                        })),
                      } as ISeriesChart;
                    }) ?? []
                }
                reportHeader={{
                  title: 'Tempo',
                  branch: 'Regional 1',
                  numberOfTrips: 1,
                  reportDate: new Date(),
                  user: 'nickolas.aguiar',
                }}
                table={<Table bordered={true} columns={columns} />}
              />
            ) : (
              typeSelecteds.map((type) => (
                <DatetimeChart
                  handleOpenReportDetailed={handleOpenReportDetailed}
                  chartId={type}
                  colors={[report?.children.find((rt) => rt.logicalName === type)?.color ?? '#263668']}
                  groupBy={activeTabGroupByDate}
                  isSecondGraph={false}
                  key={type}
                  type="column"
                  seriesData={
                    report?.children
                      .filter((reportTimeType) => reportTimeType.logicalName === type)
                      .map((reportTimeType) => {
                        return {
                          type: 'bar',
                          name: reportTimeType.name,
                          group: reportTimeType?.logicalName == 'total' ? 'total' : 'other',
                          data: time?.tempos?.map((item) => ({
                            x: new Date(item.dataHora).getTime(),
                            y: item[reportTimeType?.logicalName as keyof ReportTimeConsumptionModel] as number,
                          })),
                        } as ISeriesChart;
                      }) ?? []
                  }
                  reportHeader={{
                    title: 'Tempo',
                    branch: 'Regional 1',
                    numberOfTrips: 1,
                    reportDate: new Date(),
                    user: 'nickolas.aguiar',
                  }}
                />
              ))
            )}
          </div>
        </>
      )}
    </Spinner>
  );
};
