import { Tag } from 'antd';
import styled, { css } from 'styled-components';
import { ITag } from './Tag';

export const TagWrapper = styled(Tag)<ITag>`
  border-radius: 8px;
  padding: 6px 8px;
  width: auto;
  white-space: pre-line;
  border: none;
  background-color: var(--text-header);
  text-transform: capitalize;

  font-size: 0.875rem;
  color: var(--input-placeholder-color);
  white-space: pre-line;
  border: none;
  padding: 2px, 8px, 2px, 8px;
  border-radius: 4px;
  gap: 8px;
  font-family: Mulish;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.25rem;
  letter-spacing: 0em;
  text-align: center;

  ${({ textColor, bgColor }) => css`
    color: ${textColor};
    background-color: ${bgColor};
  `}
`;
