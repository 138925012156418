import { ModalProps } from 'antd';
import * as S from './ModalFooter.styles';

interface ModalFooterProps extends ModalProps {}

const ModalFooter: React.FC<ModalFooterProps> = (props) => {
  return <S.ModalFooter {...props}>{props.children}</S.ModalFooter>;
};

export default ModalFooter;
