import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import React, { useCallback, useEffect, useState } from 'react';
import { CentralContainer } from './components/CentralContainer/CentralContainer';
import { Col, Row } from 'antd';
import { setHeader } from '@app/store/slices/headerSlice';
import {
  CentralTrackingChargingPointModel,
  CentralTrackingModel,
  CentralTrackingShippingModel,
} from '@app/domain/interfaces/centralTracking/CentralTrackingModel';
import { CardCentralTracking } from './components/CardCentralTracking/CardCentralTracking';
import { FooterHelper } from '@app/pages/modules/CentralTracking/components/FooterHelper/FooterHelper';
import { Spinner } from '@app/components/common/Spinner/Spinner';
import { motion } from 'framer-motion';
import ICentralTrackingService, { CentralTrackingService } from '@app/services/centralTrackingService';
import { notificationController } from '@app/controllers/notificationController';
import { HubConnection, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import appSettings from '@app/config/appsettings';
import env from '@app/config/env.config';
import {
  CentralTrackingUpdateFinished,
  CentralTrackingUpdateSlump,
  CentralTrackingUpdateWater,
} from '@app/domain/interfaces/centralTracking/CentralTrackingUpdateModels';

const transitionSpring = {
  type: 'spring',
  damping: 4,
  stiffness: 90,
};

const centralService: ICentralTrackingService = new CentralTrackingService();

const CentralTrackingDashboard: React.FC = () => {
  const dispatch = useAppDispatch();
  const [centralTrackingData, setCentralTrackingData] = React.useState<CentralTrackingModel>();
  const [loading, setLoading] = useState<boolean>(false);
  const [connection, setConnection] = useState<HubConnection | null>(null);
  const [lastConnectionsHandler, setLastConnectionHandler] = useState<string>();
  const { branchTrackingSelected, groupTrackingSelected } = useAppSelector((state) => state.tracking);
  const [waterReceived, setWaterReceived] = useState<CentralTrackingUpdateWater>();
  const [slumpReceived, setSlumpReceived] = useState<CentralTrackingUpdateSlump>();
  const [finishedReceived, setFinishedReceived] = useState<CentralTrackingUpdateFinished>();
  const [shippingReceived, setShippingReceived] = useState<CentralTrackingShippingModel>();

  const fetch = useCallback((branchId: number) => {
    setLoading(true);

    centralService
      .getChargingPointsByCodigoFilial([branchId])
      .then((res) => {
        setLoading(false);
        setCentralTrackingData(res);
      })
      .catch((err) => {
        setLoading(false);
        if (env.ENVIRONMENT === 'dev' || env.ENVIRONMENT === 'loc') {
          console.log(err);
        }
        setCentralTrackingData(undefined);
        notificationController.error({
          message: 'Erro! Não foi possível buscar os dados da central desta filial.',
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleHubConnection = async (idFilial: number) => {
    try {
      await fetch(idFilial);

      if (lastConnectionsHandler) {
        connection?.invoke('LeaveGroup', lastConnectionsHandler);
      }

      setLastConnectionHandler(`acompanhamento_central_${branchTrackingSelected.codigoFilialSiac}`);

      connection?.stop().then(() => {
        connection
          ?.start()
          .then(() => {
            connection.on(
              'connected',
              (central: CentralTrackingModel[]) =>
                (env.ENVIRONMENT === 'dev' || env.ENVIRONMENT === 'loc') && console.log('connected', central),
            );
            connection.invoke('JoinGroup', `acompanhamento_central_${branchTrackingSelected.codigoFilialSiac}`);
            //Canais de atualização do hub
            connection.on(`acompanhamento_central_agua`, (waterUpdate: CentralTrackingUpdateWater) =>
              setWaterReceived(waterUpdate),
            );
            connection.on(`acompanhamento_central_slump`, (slumpUpdate: CentralTrackingUpdateSlump) =>
              setSlumpReceived(slumpUpdate),
            );
            connection.on(`acompanhamento_central_finalizado`, (finishedUpdate: CentralTrackingUpdateFinished) =>
              setFinishedReceived(finishedUpdate),
            );
            connection.on(`acompanhamento_central_remessa`, (shipping: CentralTrackingShippingModel) =>
              setShippingReceived(shipping),
            );
          })
          .catch((e: Error) => console.error('Connection failed: ', e));
      });
    } catch (error) {}
  };

  useEffect(() => {
    if (waterReceived) {
      setCentralTrackingData({
        ...centralTrackingData,
        pontosCarga: centralTrackingData?.pontosCarga.map((p) => ({
          ...p,
          remessas: p.remessas?.map((r) => {
            if (r.idViagem == waterReceived.idViagem) {
              const agua = { dataHora: waterReceived.dataHora, aguaUtilizada: waterReceived.aguaUtilizada };
              return {
                ...r,
                aguaHistorico: r.aguaHistorico ? [...r.aguaHistorico, agua] : [agua],
                aguaUtilizada: waterReceived.aguaUtilizada,
                aguaUtilizadaPorcentagem: waterReceived.aguaUtilizadaPorcentagem,
                dataHoraUltimaAtualizacaoAgua: waterReceived.dataHora,
                temperaturaAmbiente: waterReceived.temperaturaAmbiente,
                temperaturaConcreto: waterReceived.temperaturaConcreto,
              };
            } else return r;
          }),
        })) as CentralTrackingChargingPointModel[],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [waterReceived]);
  useEffect(() => {
    if (slumpReceived) {
      setCentralTrackingData({
        ...centralTrackingData,
        pontosCarga: centralTrackingData?.pontosCarga.map((p) => ({
          ...p,
          remessas: p.remessas?.map((r) => {
            if (r.idViagem == slumpReceived.idViagem) {
              const slump = { dataHora: slumpReceived.dataHora, slump: slumpReceived.slump };
              console.log(r.slumpHistorico?.length ? [...r.slumpHistorico, slump] : [slump]);

              return {
                ...r,
                slumpAtual: slumpReceived.slump,
                rotacaoMotor: slumpReceived.rotacaoMotor,
                dataHoraUltimaAtualizacaoSlump: slumpReceived.dataHora,
                slumpHistorico: r.slumpHistorico?.length ? [...r.slumpHistorico, slump] : [slump],
              };
            } else return r;
          }),
        })) as CentralTrackingChargingPointModel[],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slumpReceived]);
  useEffect(() => {
    if (finishedReceived) {
      setCentralTrackingData({
        ...centralTrackingData,
        pontosCarga: centralTrackingData?.pontosCarga.map((p) => ({
          ...p,
          remessas: p.remessas?.map((r) =>
            r.idViagem == finishedReceived.idViagem
              ? {
                  ...r,
                  finalizado: finishedReceived.finalizado,
                  finalizadoComErro: finishedReceived.finalizadoComErro,
                }
              : r,
          ),
        })) as CentralTrackingChargingPointModel[],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finishedReceived]);
  useEffect(() => {
    if (shippingReceived) {
      console.log(shippingReceived);

      const hasPoint = centralTrackingData?.pontosCarga.find((p) => p.pontoCarga == shippingReceived.pontoCarga);

      if (hasPoint) {
        setCentralTrackingData({
          ...centralTrackingData,
          pontosCarga: centralTrackingData?.pontosCarga.map((p) =>
            p.pontoCarga == shippingReceived.pontoCarga
              ? { ...p, remessas: [...(p.remessas ?? []), shippingReceived] }
              : p,
          ) as CentralTrackingChargingPointModel[],
        });
      } else {
        setCentralTrackingData({
          ...centralTrackingData,
          pontosCarga: [
            ...(centralTrackingData?.pontosCarga ?? []),
            {
              idFilial: branchTrackingSelected.codigoFilialSiac,
              pontoCarga: shippingReceived.pontoCarga,
              remessas: [shippingReceived],
            },
          ],
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shippingReceived]);
  useEffect(() => {
    if (branchTrackingSelected.codigoFilialSiac) handleHubConnection(branchTrackingSelected.codigoFilialSiac);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [branchTrackingSelected, fetch]);
  useEffect(() => {
    dispatch(
      setHeader({
        title: 'Acompanhamento da central',
      }),
    );

    const newConnection = new HubConnectionBuilder()
      .withUrl(`${appSettings().APIs.Acompanhamento.UrlBase}/acompanhamento-central-hub`)
      .configureLogging(LogLevel.Information)
      .withAutomaticReconnect()
      .build();

    setConnection(newConnection);
  }, [dispatch]);

  return (
    <>
      <Spinner spinning={loading} />
      {branchTrackingSelected.codigoPontoDeCarga01 ? (
        <Row gutter={[15, 15]} style={{ width: '100%' }}>
          <Col span={24}>
            <CentralContainer
              title="P1"
              onClickReport={() =>
                window.open(
                  `/acompanhamento/relatorio/${branchTrackingSelected.codigoFilialSiac}/P1?regional=${groupTrackingSelected.nome}&filial=${branchTrackingSelected.nome}`,
                  '_blank',
                )
              }
            >
              {centralTrackingData?.pontosCarga
                ?.filter((p) => p.pontoCarga == 'P1' && p.remessas?.find((r) => !r.finalizado))
                ?.map((point, index) => (
                  <Row key={index} gutter={[15, 15]} style={{ width: '100%' }}>
                    {point.remessas
                      ?.sort((a, b) => (a.finalizado == b.finalizado ? 0 : !a.finalizado ? -1 : 1))
                      ?.slice(0, 2)
                      ?.map((shipping) => (
                        <Col key={`${shipping.idViagem}-${shipping.deviceId}`} xs={24} md={12}>
                          <motion.div
                            style={{ height: '100%' }}
                            className={`${
                              shipping.finalizado
                                ? 'animate__animated animate__fadeOutRight'
                                : 'animate__animated animate__fadeInLeft'
                            }`}
                            transition={transitionSpring}
                          >
                            <CardCentralTracking centralTrackingData={shipping} onAnimatedEnd={() => {}} />
                          </motion.div>
                        </Col>
                      ))}
                  </Row>
                ))}
            </CentralContainer>
          </Col>
        </Row>
      ) : (
        <></>
      )}
      {branchTrackingSelected.codigoPontoDeCarga02 ? (
        <Row gutter={[15, 15]} style={{ width: '100%' }}>
          <Col span={24}>
            <CentralContainer
              title="P2"
              onClickReport={() =>
                window.open(
                  `/acompanhamento/relatorio/${branchTrackingSelected.codigoFilialSiac}/P2?regional=${groupTrackingSelected.nome}&filial=${branchTrackingSelected.nome}`,
                  '_blank',
                )
              }
            >
              {centralTrackingData?.pontosCarga
                ?.filter((p) => p.pontoCarga == 'P2' && p.remessas?.find((r) => !r.finalizado))
                ?.map((point, index) => (
                  <Row key={index} gutter={[15, 15]} style={{ width: '100%' }}>
                    {point.remessas
                      ?.sort((a, b) => (a.finalizado == b.finalizado ? 0 : !a.finalizado ? -1 : 1))
                      ?.slice(0, 2)
                      ?.map((shipping) => (
                        <Col key={`${shipping.idViagem}-${shipping.deviceId}`} xs={24} md={12}>
                          <motion.div
                            style={{ height: '100%' }}
                            className={`${
                              shipping.finalizado
                                ? 'animate__animated animate__fadeOutRight'
                                : 'animate__animated animate__fadeInLeft'
                            }`}
                            transition={transitionSpring}
                          >
                            <CardCentralTracking centralTrackingData={shipping} onAnimatedEnd={() => {}} />
                          </motion.div>
                        </Col>
                      ))}
                  </Row>
                ))}
            </CentralContainer>
          </Col>
        </Row>
      ) : (
        <></>
      )}
      {branchTrackingSelected.codigoPontoDeCarga03 ? (
        <Row gutter={[15, 15]} style={{ width: '100%' }}>
          <Col span={24}>
            <CentralContainer
              title="P3"
              onClickReport={() =>
                window.open(
                  `/acompanhamento/relatorio/${branchTrackingSelected.codigoFilialSiac}/P3?regional=${groupTrackingSelected.nome}&filial=${branchTrackingSelected.nome}`,
                  '_blank',
                )
              }
            >
              {centralTrackingData?.pontosCarga
                ?.filter((p) => p.pontoCarga == 'P3' && p.remessas?.find((r) => !r.finalizado))
                ?.map((point, index) => (
                  <Row key={index} gutter={[15, 15]} style={{ width: '100%' }}>
                    {point.remessas
                      ?.sort((a, b) => (a.finalizado == b.finalizado ? 0 : !a.finalizado ? -1 : 1))
                      ?.slice(0, 2)
                      ?.map((shipping) => (
                        <Col key={`${shipping.idViagem}-${shipping.deviceId}`} xs={24} md={12}>
                          <motion.div
                            style={{ height: '100%' }}
                            className={`${
                              shipping.finalizado
                                ? 'animate__animated animate__fadeOutRight'
                                : 'animate__animated animate__fadeInLeft'
                            }`}
                            transition={transitionSpring}
                          >
                            <CardCentralTracking centralTrackingData={shipping} onAnimatedEnd={() => {}} />
                          </motion.div>
                        </Col>
                      ))}
                  </Row>
                ))}
            </CentralContainer>
          </Col>
        </Row>
      ) : (
        <></>
      )}

      <FooterHelper />
    </>
  );
};

export default CentralTrackingDashboard;
