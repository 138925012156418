import styled from 'styled-components';
import { Col as AntdCol } from 'antd';
import { CardInfoValue } from '@app/components/common/Card/CardInfo/CardDeviceInformation.styles';

export const Col = styled(AntdCol)`
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
`;

export const ColStyled = styled(Col)`
  width: 140px;
`;

export const SpecialCardInfoValue = styled(CardInfoValue)`
  color: var(--secondary-color);
  font-weight: bold;
`;
