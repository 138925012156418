import styled from 'styled-components';
import BgConcreto from '@app/assets/images/bg-concreto.png';

const height = '132px';
const width = '165px';

interface SlumpProps {
  slumpValue: number;
}

interface SlumpGoalProps {
  targetSlump: number;
  targetTolerance: number;
}

const convertSlumpToHeight = (slumpValue: number) => {
  return Math.round(-(10 / 3) * slumpValue) + 100;
};

const convertSlumpToleranceToHeight = (tolerance: number) => {
  return (20 / 3) * tolerance + 2;
};

const getSlumpColor = (slumpValue: number, targetSlump: number, targetTolerance: number) => {
  return validateSlumpValue(slumpValue, targetSlump, targetTolerance) ? 'green' : 'red';
};

export const validateSlumpValue = (slumpValue: number, targetSlump: number, targetTolerance: number) => {
  return slumpValue >= targetSlump - targetTolerance && slumpValue <= targetSlump + targetTolerance;
};

export const SlumpContainer = styled.div`
  width: ${width};
  height: ${height};
  position: relative;
  box-sizing: content-box !important;
  //border: 1px solid red;
`;

export const RulerScale = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  width: 25px;
  height: ${height};
  background-color: #a3a3a3;
  left: 100px;
  z-index: 0;
`;

export const RulerMark = styled.div`
  width: 10px;
  height: 2px;
  background-color: #000;
  z-index: 0;
`;

export const BarLimit = styled.div`
  position: absolute;
  bottom: 100px;
  width: ${width};
  height: 10px;
  background-color: #383838;
  z-index: 0;
`;

export const CurrentLine = styled.div<SlumpProps>`
  position: absolute;
  bottom: calc(${(props) => convertSlumpToHeight(props.slumpValue)}px - 1px);
  height: 1px;
  width: calc(${width} - 24px);
  left: 24px;
  background-image: linear-gradient(60deg, #000, #000 50%, transparent 50%, transparent 100%);
  background-size: 10px 1px;
  border: none;
  z-index: 2;
  transition: bottom 1s ease-in-out;
`;

export const CurrentValue = styled.span<SlumpProps & SlumpGoalProps>`
  position: absolute;
  bottom: calc(${(props) => convertSlumpToHeight(props.slumpValue)}px - 5px);
  color: ${(props) => getSlumpColor(props.slumpValue, props.targetSlump, props.targetTolerance)};
  text-align: end;
  width: 20px;
  font-family: Mulish;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  transition: bottom 1s ease-in-out;
`;

export const TrapazoidConcrete = styled.div<SlumpProps>`
  position: absolute;
  background-image: url(${BgConcreto});
  background-size: 100%;
  clip-path: polygon(25% 0%, 75% 0%, 100% 100%, 0% 100%);
  left: 61px;
  width: 100px;
  bottom: 0;
  height: ${(props) => convertSlumpToHeight(props.slumpValue)}px;
  z-index: 0;
`;

export const TrapazoidConcreteBase = styled.div`
  position: absolute;
  bottom: -5px;
  height: 5px;
  background-color: #383838;
  width: 110px;
  left: 56px;
`;

export const GoalBar = styled.div<SlumpGoalProps>`
  position: absolute;
  height: ${(props) => convertSlumpToleranceToHeight(props.targetTolerance)}px;
  bottom: ${(props) => convertSlumpToHeight(props.targetSlump)}px;
  transform: translate(50%, 50%);
  right: calc(50% - 12px);
  width: calc(${width} - 22px);
  opacity: 0.75;
  background: #e9f4ee;
  border-bottom: 1px #159d3b solid;
  border-top: 1px #159d3b solid;
  z-index: 2;
`;
