import styled, { css, keyframes } from 'styled-components';
import WaterWave from '@app/assets/images/water-wave.png';
import BlueFill from '@app/assets/images/water-blue-fill.png';
import PurpleFill from '@app/assets/images/water-purple-fill.png';
import RedFill from '@app/assets/images/water-red-fill.png';
import YellowFill from '@app/assets/images/water-yellow-fill.png';
import { ReactComponent as ImportedBellIcon } from '@app/assets/icons/notification_important.svg';
import Keyframes from 'styled-components/dist/models/Keyframes';

interface CupProps {
  fillLevel: number;
  isFillAnimating: boolean;
}

const calculateFillPosition = (fillLevel: number = 100): number => {
  if (fillLevel > 100) fillLevel = 100;
  const cupHeight = 122;
  return cupHeight - (cupHeight * fillLevel) / 100;
};

export const BellIcon = styled(ImportedBellIcon)<CupProps>`
  display: block;
  width: 40px;
  height: 40px;
  font-size: 40px;
  animation: ring 4s ease-in-out infinite;
  transform-origin: 50% 4px;

  position: relative;
  top: ${({ fillLevel }) =>
    fillLevel !== undefined ? `calc(36px + ${calculateFillPosition(fillLevel) / 2}px)` : '50%'};
  left: 46px;
  transform: translate(-50%, -50%);

  -webkit-animation: ring 4s ease-in-out infinite;
  -webkit-transform-origin: 50% 4px;
  -moz-animation: ring 4s ease-in-out infinite;

  @keyframes ring {
    0% {
      transform: rotate(0);
    }
    1% {
      transform: rotate(30deg);
    }
    3% {
      transform: rotate(-28deg);
    }
    5% {
      transform: rotate(34deg);
    }
    7% {
      transform: rotate(-32deg);
    }
    9% {
      transform: rotate(30deg);
    }
    11% {
      transform: rotate(-28deg);
    }
    13% {
      transform: rotate(26deg);
    }
    15% {
      transform: rotate(-24deg);
    }
    17% {
      transform: rotate(22deg);
    }
    19% {
      transform: rotate(-20deg);
    }
    21% {
      transform: rotate(18deg);
    }
    23% {
      transform: rotate(-16deg);
    }
    25% {
      transform: rotate(14deg);
    }
    27% {
      transform: rotate(-12deg);
    }
    29% {
      transform: rotate(10deg);
    }
    31% {
      transform: rotate(-8deg);
    }
    33% {
      transform: rotate(6deg);
    }
    35% {
      transform: rotate(-4deg);
    }
    37% {
      transform: rotate(2deg);
    }
    39% {
      transform: rotate(-1deg);
    }
    41% {
      transform: rotate(1deg);
    }

    43% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(0);
    }
  }
`;

const getImageByFillLevel = (fillLevel?: number): string => {
  if (fillLevel === undefined || fillLevel === null) return PurpleFill;
  if (fillLevel === 0) return PurpleFill;
  if (fillLevel <= 30) return BlueFill;
  if (fillLevel <= 70) return YellowFill;
  return RedFill;
};

const getAnimationByFillLevel = (fillLevel?: number): Keyframes => {
  if (!fillLevel) return fillAnimation0;
  switch (true) {
    case fillLevel <= 20:
      return fillAnimation0;
    case fillLevel <= 30:
      return fillAnimation20;
    case fillLevel <= 40:
      return fillAnimation40;
    case fillLevel <= 80:
      return fillAnimation80;
    case fillLevel <= 100:
      return fillAnimation100;
    default:
      return fillAnimation0;
  }
};

const fillAnimation0 = keyframes`
  0% {
    background-position: -200px 100px;
  }
`;

const fillAnimation20 = keyframes`
  0% {
    background-position: -200px 100px;
  }
  20% {
    background-position: -350px 80px;
  }
`;
const fillAnimation40 = keyframes`
  0% {
    background-position: -200px 100px;
  }
  20% {
    background-position: -350px 80px;
  }
  40% {
    background-position: -800px 40px;
  }
`;
const fillAnimation80 = keyframes`
  0% {
    background-position: -200px 100px;
  }
  20% {
    background-position: -350px 80px;
  }
  40% {
    background-position: -800px 40px;
  }
  80% {
    background-position: -1250px 20px;
  }
`;

const fillAnimation100 = keyframes`
  0% {
    background-position: -200px 100px;
  }
  20% {
    background-position: -350px 80px;
  }
  40% {
    background-position: -800px 40px;
  }
  80% {
    background-position: -1250px 20px;
  }
  100% {
    background-position: -1350px 0;
  }
`;

export const Cup = styled.div<CupProps>`
  width: 128px;
  height: 122px;
  border-radius: 6px;
  box-shadow: 0 0 0 5px #fff;
  background-image: ${({ fillLevel, isFillAnimating }) =>
    `url(${isFillAnimating ? WaterWave : getImageByFillLevel(fillLevel)})`};
  background-color: #d1d1d1;
  background-repeat: repeat-x;
  background-position: 0
    ${({ fillLevel, isFillAnimating }) => (isFillAnimating ? '122px' : `${calculateFillPosition(fillLevel)}px`)};

  ${({ fillLevel, isFillAnimating }) =>
    isFillAnimating &&
    css`
      animation: ${getAnimationByFillLevel(fillLevel)} 3s linear forwards;
    `}
  transition: background-image 0.1s ease-in-out
`;
