import React from 'react';
import * as S from './FooterM.styles';

export interface FooterMProps {
  title?: string;
  children?: React.ReactNode;
}

export const FooterM: React.FC<FooterMProps> = ({ children }) => {
  return (
    <S.FooterContainer>
      <S.FooterWrap>{children}</S.FooterWrap>
    </S.FooterContainer>
  );
};
