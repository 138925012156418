import * as React from 'react';
const SvgIconFilePdf = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 36 36" width="1em" height="1em" role="img">
    <g clipPath="url(#a)">
      <path
        fill="current"
        fillOpacity={0.85}
        d="m18.774 20.507.013-.056c.233-.96.526-2.158.297-3.242-.153-.856-.784-1.19-1.322-1.214-.635-.028-1.201.334-1.342.86-.265.964-.028 2.282.406 3.962-.547 1.301-1.418 3.194-2.057 4.319-1.19.614-2.785 1.563-3.022 2.76-.048.221.008.502.14.755.15.282.387.499.664.603.12.044.265.08.434.08.707 0 1.852-.57 3.379-3.19.233-.076.474-.157.707-.237 1.093-.37 2.226-.755 3.25-.928 1.133.607 2.423.996 3.299.996.868 0 1.21-.514 1.338-.823.225-.543.117-1.226-.25-1.591-.53-.523-1.82-.66-3.828-.41-.988-.603-1.635-1.422-2.105-2.644Zm-4.407 6.103c-.559.812-.98 1.218-1.21 1.395.27-.495.796-1.017 1.21-1.395Zm3.52-9.462c.208.358.18 1.439.02 1.985-.197-.8-.225-1.933-.109-2.065.032.004.06.028.089.08Zm-.065 4.842c.43.743.973 1.382 1.571 1.856-.868.197-1.66.522-2.366.812l-.495.2c.535-.968.98-2.065 1.29-2.868Zm6.252 2.631c.004.009.008.02-.016.037h-.008l-.008.012c-.032.02-.362.213-1.78-.346 1.631-.076 1.808.293 1.812.297Zm7.69-15.597L23.118.378A1.283 1.283 0 0 0 22.21 0H5.142C4.43 0 3.856.575 3.856 1.286v33.428c0 .711.575 1.286 1.286 1.286h25.714c.711 0 1.286-.575 1.286-1.286V9.936c0-.341-.137-.67-.378-.912Zm-2.587 1.503h-7.562V2.965l7.562 7.562Zm.072 22.58H6.75V2.893h12.134v8.678a1.688 1.688 0 0 0 1.688 1.688h8.678v19.848Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="current" d="M0 0h36v36H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgIconFilePdf;
