import { ClientModel } from '@app/domain/interfaces/clientModel';
import { ApiServiceBase, IApiServiceBase } from './apiServiceBase';

export default interface IClientService extends IApiServiceBase<ClientModel, ClientModel> {}

export class ClientService extends ApiServiceBase<ClientModel, ClientModel> implements IClientService {
  constructor() {
    super('admin/clientes');
  }
}
