import { BaseFormSupportTextWrapper } from './BaseFormSupportText.styles';

interface BaseFormSupportText {
  text?: string;
  isSuccess?: boolean;
}

export const BaseFormSupportText: React.FC<BaseFormSupportText> = ({ text, isSuccess }) => (
  <BaseFormSupportTextWrapper isSuccess={isSuccess}>{text}</BaseFormSupportTextWrapper>
);
