import styled from 'styled-components';
import { media } from '@app/styles/themes/constants';

export const ReferencesWrapper = styled.div`
  background: var(--background-rgb-color);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: nowrap;

  @media only screen and (${media.sm}) {
    align-items: center;
  }

  @media only screen and (${media.xl}) {
    flex-direction: row;
    justify-content: center;
  }
`;

export const Text = styled.span`
  display: flex;
  align-items: center;
  white-space: pre-wrap;
  flex-wrap: wrap;
  color: var(--footer-color);
  font-weight: 600;

  @media only screen and (${media.xl}) {
    margin-bottom: 0;
  }
`;

export const Icons = styled.div`
  margin-left: -0.5rem;
  margin-right: -0.5rem;
  display: flex;
  flex-wrap: nowrap;
`;

// export function withStyles<T>(Component: React.ComponentType<T>): ReturnType<T> {
//   return styled(Component)`
//     font-size: 2rem;
//     margin-left: 0.5rem;
//     margin-right: 0.5rem;
//   `;
// }
