import { NotificationType } from '@app/components/common/Notification/Notification';
import { Priority } from '@app//constants/enums/priorities';
import { decodeToken } from 'react-jwt';
import moment from 'moment';
import { TravelState } from '@app/constants/enums/travelState';

interface IDecodedUserToken {
  client_admin: string | null;
  master: string | null;
  user_filial: string | null;
}
export const renderViagemEstadoTexto = (idViagem: number): string => {
  switch (idViagem) {
    case TravelState.AguardandoRemessa:
      return 'Aguardando Remessa';
    case TravelState.ChegadaRemessa:
      return 'Chegada Remessa';
    case TravelState.SaidaCentral:
      return 'Saída Central';
    case TravelState.ChegadaObra:
      return 'Chegada Obra';
    case TravelState.InicioDescarga:
      return 'Início Descarga';
    case TravelState.FinalDescarga:
      return 'Final Descarga';
    case TravelState.SaidaObra:
      return 'Saída Obra';
    case TravelState.ChegadaCentral:
      return 'Chegada Central';
    default:
      return '';
  }
};

export function minutesToHoursAndMinutes(totalMinutes?: number): string {
  if (!totalMinutes) return '-';

  const hours = Math.floor(totalMinutes / 60);
  const minutes = (totalMinutes % 60).toFixed(0).padStart(2, '0');

  return hours > 0 ? `${hours.toFixed(0)}h${minutes}` : `${minutes} min`;
}

export function secondsToHoursMinutesAndSeconds(totalSeconds?: number): string {
  if (!totalSeconds) return '-';

  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = (totalSeconds % 60).toFixed(0).padStart(2, '0');

  const formattedMinutes = minutes.toFixed(0).padStart(2, '0');

  return hours > 0
    ? `${hours.toFixed(0)}h${formattedMinutes}min`
    : minutes > 0
    ? `${formattedMinutes}m${seconds}s`
    : `${seconds}s`;
}

export const getDifferenceDaysBetweenDates = (startDate: Date, endDate: Date): number => {
  if (startDate.toString() == 'Invalid Date' || endDate.toString() == 'Invalid Date') return 0;
  return moment(endDate).diff(moment(startDate), 'days');
};

export const getDifferenceBetweenDatesInMinutes = (startDate: Date, endDate: Date): number => {
  if (startDate.toString() == 'Invalid Date' || endDate.toString() == 'Invalid Date') return 0;
  return moment(endDate).diff(moment(startDate), 'minutes');
};

export const getDifferenceBetweenDatesInSeconds = (startDate: Date, endDate: Date): number => {
  if (startDate.toString() == 'Invalid Date' || endDate.toString() == 'Invalid Date') return 0;
  return moment(endDate).diff(moment(startDate), 'seconds');
};

export const getDifferenceBetweenDatesInHours = (startDate: Date, endDate: Date): number => {
  if (startDate.toString() == 'Invalid Date' || endDate.toString() == 'Invalid Date') return 0;
  const hours = moment.utc(moment(endDate).diff(moment(startDate))).hours();
  return hours;
};

export const isDateMatching = (itemDate: string, inputValue: string): boolean => {
  const formatDate = (date: string) => moment(date).format('DD/MM/YYYY HH:mm');

  const formattedDate = formatDate(itemDate);
  const inputWithoutNonNumeric = inputValue.replaceAll(/[^0-9]/g, '');

  return (
    formattedDate.toLowerCase().includes(inputValue) ||
    formattedDate.replaceAll(/[^0-9]/g, '').includes(inputWithoutNonNumeric)
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const groupByKey = (list: any[], key: string) =>
  list.reduce((hash, obj) => ({ ...hash, [obj[key]]: (hash[obj[key]] || []).concat(obj) }), {});

export const getUserType = (token: string): string => {
  const decodedToken = decodeToken(token) as IDecodedUserToken;
  const { client_admin, master, user_filial } = decodedToken;

  if (master !== null && master !== undefined) {
    return 'master';
  } else if (client_admin !== null && client_admin !== undefined) {
    return 'client_admin';
  } else if (user_filial !== null && user_filial !== undefined) {
    return 'user_filial';
  } else {
    return '';
  }
};

export const camelize = (string: string): string => {
  return string
    .split(' ')
    .map((word, index) => (index === 0 ? word.toLowerCase() : word[0].toUpperCase() + word.slice(1)))
    .join('');
};

export const capitalize = (word: string): string => `${word[0].toUpperCase()}${word.slice(1)}`;

export const hexToRGB = (hex: string): string => {
  const r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16);

  return `${r}, ${g}, ${b}`;
};

export const getDifference = (value: number, prevValue: number): string | null =>
  prevValue !== 0 ? `${((Math.abs(value - prevValue) / prevValue) * 100).toFixed(0)}%` : '100%';

export const normalizeProp = (prop: string | number | [number, number]): string =>
  typeof prop === 'number' ? `${prop}px` : (Array.isArray(prop) && `${prop[0]}px ${prop[1]}px`) || prop.toString();

export const defineColorByPriority = (priority: Priority): string => {
  switch (priority) {
    case Priority.INFO:
      return 'var(--primary-color)';
    case Priority.LOW:
      return 'var(--confimed-hover)';
    case Priority.MEDIUM:
      return 'var(--new-alert-color)';
    case Priority.HIGH:
      return 'var(--reported-color)';
    default:
      return 'var(--confimed-hover)';
  }
};

export const defineColorBySeverity = (severity: NotificationType | undefined, rgb = false): string => {
  const postfix = rgb ? 'rgb-color' : 'color';
  switch (severity) {
    case 'error':
    case 'warning':
    case 'success':
      return `var(--${severity}-${postfix})`;
    case 'info':
    default:
      return `var(--primary-${postfix})`;
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const mergeBy = (a: any[], b: any[], key: string): any[] =>
  a.filter((elem) => !b.find((subElem) => subElem[key] === elem[key])).concat(b);

export const getSmoothRandom = (factor: number, start: number): number => {
  const halfEnvelope = 1 / factor / 2;
  const max = Math.min(1, start + halfEnvelope);
  const min = Math.max(0, start - halfEnvelope);

  return Math.random() * (max - min) + min;
};

export const shadeColor = (color: string, percent: number): string => {
  let R = parseInt(color.substring(1, 3), 16);
  let G = parseInt(color.substring(3, 5), 16);
  let B = parseInt(color.substring(5, 7), 16);

  R = parseInt(((R * (100 + percent)) / 100).toString());
  G = parseInt(((G * (100 + percent)) / 100).toString());
  B = parseInt(((B * (100 + percent)) / 100).toString());

  R = R < 255 ? R : 255;
  G = G < 255 ? G : 255;
  B = B < 255 ? B : 255;

  const RR = R.toString(16).length == 1 ? '0' + R.toString(16) : R.toString(16);
  const GG = G.toString(16).length == 1 ? '0' + G.toString(16) : G.toString(16);
  const BB = B.toString(16).length == 1 ? '0' + B.toString(16) : B.toString(16);

  return '#' + RR + GG + BB;
};

export const hexToHSL = (hex: string): { h: number; s: number; l: number } => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  if (result) {
    let r = parseInt(result[1], 16);
    let g = parseInt(result[2], 16);
    let b = parseInt(result[3], 16);
    (r /= 255), (g /= 255), (b /= 255);
    const max = Math.max(r, g, b),
      min = Math.min(r, g, b);
    let h, s;
    const l = (max + min) / 2;
    if (max == min) {
      h = s = 0; // achromatic
    } else {
      const d = max - min;
      s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
      switch (max) {
        case r:
          h = (g - b) / d + (g < b ? 6 : 0);
          break;
        case g:
          h = (b - r) / d + 2;
          break;
        case b:
          h = (r - g) / d + 4;
          break;
        default:
          h = 0;
      }
      h /= 6;
    }
    return {
      h,
      s,
      l,
    };
  } else {
    throw new Error('Non valid HEX color');
  }
};

export const formatNumberWithCommas = (value: number): string => {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const formatSecondsToHoursAndMinutesReports = (seconds: number): string => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);

  if (hours < 1) return `${minutes} min`;

  return `${hours}h${minutes}`;
};

export const formatMinutesToHoursAndMinutes = (minutes: number): string => {
  const hours = Math.floor(minutes / 60);
  const min = (minutes % 60).toString().padStart(2, '0');
  if (hours < 1) return `${min} min`;

  return `${hours}h${min}`;
};

export const generateUuid = (): string => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};
