import { ClientBranchModel } from '@app/domain/interfaces/client_branch/clientBranchModel';
import { ApiServiceBase, IApiServiceBase } from './apiServiceBase';

export default interface IClientBranchService extends IApiServiceBase<ClientBranchModel, ClientBranchModel> {}

export class ClientBranchService
  extends ApiServiceBase<ClientBranchModel, ClientBranchModel>
  implements IClientBranchService
{
  constructor() {
    super('admin/filiais');
  }
}
