import * as React from 'react';
const SvgIconStreet = () => (
  <svg width="17" height="16" viewBox="0 0 17 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.5 0H14.5V16H16.5V0Z" fill="current" />
    <path d="M2.5 0H0.5V16H2.5V0Z" fill="current" />
    <path d="M9.5 0H7.5V4H9.5V0Z" fill="current" />
    <path d="M9.5 6H7.5V10H9.5V6Z" fill="current" />
    <path d="M9.5 12H7.5V16H9.5V12Z" fill="current" />
  </svg>
);
export default SvgIconStreet;
