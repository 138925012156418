import React, { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import MainContent from '../main/MainContent/MainContent';
import MainSider from '../main/sider/MainSider/MainSider';
import * as S from '../main/MainLayout/MainLayout.styles';
import { HeaderRegistrationPages } from './header/HeaderRegister';
import { useResponsive } from '@app/hooks/useResponsive';
import { FooterReagister } from './footer/FooterRegister';
import { ReactComponent as ArrowLeft } from '@app/assets/icons/arrow-left.svg';
import DrawerChangeVision from '@app/components/drawers/profile/DrawerChangeVision';

export const RegistrationPagesLayout: React.FC = () => {
  const [siderCollapsed, setSiderCollapsed] = useState(true);
  const [isTwoColumnsLayout, setIsTwoColumnsLayout] = useState(true);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [forceCollapse, setForceCollapse] = useState(false);

  const { isDesktop } = useResponsive();
  const location = useLocation();

  useEffect(() => {
    setIsTwoColumnsLayout(!isDesktop);
  }, [location.pathname, isDesktop]);

  return (
    <S.LayoutMaster>
      <MainSider
        hidden={false}
        forceCollapse={forceCollapse}
        isCollapsed={siderCollapsed}
        setCollapsed={setSiderCollapsed}
      />
      <S.LayoutMain>
        <HeaderRegistrationPages icon={<ArrowLeft />} isTwoColumnsLayout={isTwoColumnsLayout} />
        <MainContent id="main-content" $isTwoColumnsLayout={true}>
          <div style={{ padding: '1rem' }}>
            <Outlet />
            <DrawerChangeVision />
          </div>
          <FooterReagister />
        </MainContent>
      </S.LayoutMain>
    </S.LayoutMaster>
  );
};
