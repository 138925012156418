import * as S from '@app/pages/modules/Reports/components/HeaderPDF/HeaderCharPDF.styles';
import { ReactComponent as IconSupermix } from '@app/assets/images/Logo-Azul-Vertical.svg';
import React from 'react';

interface HeaderLogoNameProps extends React.HTMLAttributes<HTMLDivElement> {
  reportName: string;
}

export const HeaderLogoName: React.FC<HeaderLogoNameProps> = ({ reportName, ...props }) => {
  return (
    <S.TitleContainer {...props}>
      <IconSupermix style={{ width: '300px' }} />
      <S.Title>{reportName}</S.Title>
    </S.TitleContainer>
  );
};
