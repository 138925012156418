import { ArrowLeftOutlined } from '@ant-design/icons';
import { Description } from '@app/components/common/Description/Description';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { BaseFormInputItem } from '@app/components/common/forms/components/BaseFormInputItem/BaseFormInputItem';
import { PageContainer } from '@app/components/common/PageContainer/PageContainer';
import { Select } from '@app/components/common/selects/Select/Select';
import { Spinner } from '@app/components/common/Spinner/Spinner';
import { isUserMaster, readUser, readUserFilter } from '@app/services/localStorage.service';
import { notificationController } from '@app/controllers/notificationController';
import { ClientModel } from '@app/domain/interfaces/clientModel';
import { ConcreteMixerModel } from '@app/domain/interfaces/concreteMixerModel';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import IClientService, { ClientService } from '@app/services/clientService';
import { setHeaderRegisterPage } from '@app/store/slices/headerRegisterPage';
import { Col, Row } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import IConcreteMixerService, { ConcreteMixerService } from '@app/services/concreteMixerService';
import { ScheduleModel } from '@app/domain/interfaces/schedule/scheduleModel';
import { setFooter } from '@app/store/slices/footerPageSlice';
import IScheduleService, { ScheduleService } from '@app/services/scheduleServices';
import IFirmwareVersionService, { FirmwareVersionService } from '@app/services/firmwareVersionService';
import { FirmwareVersionModel } from '@app/domain/interfaces/firmware_version/firmwareVersionModel';
import _ from 'lodash';

const scheduleService: IScheduleService = new ScheduleService();
const clientService: IClientService = new ClientService();
const concreteMixerService: IConcreteMixerService = new ConcreteMixerService();
const firmwareVersionService: IFirmwareVersionService = new FirmwareVersionService();

type VersionData = {
  central_version: FirmwareVersionModel[];
  hermes_version: FirmwareVersionModel[];
  h2_version: FirmwareVersionModel[];
  can_version: FirmwareVersionModel[];
};

const ScheduleCreate: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);

  const [schedule, setSchedule] = useState<ScheduleModel>({} as ScheduleModel);
  const [clients, setClients] = useState<ClientModel[]>([]);
  const [concreteMixers, setConcreteMixers] = useState<ConcreteMixerModel[]>([]);
  const [versionsOptions, setVersionsOptions] = useState<VersionData>({
    central_version: [],
    hermes_version: [],
    h2_version: [],
    can_version: [],
  });

  const fetchClients = useCallback(() => {
    setLoading(true);
    clientService
      .getArray('obter')
      .then((res: ClientModel[]) => {
        setClients(res);
      })
      .catch((error) => {
        notificationController.error({ message: 'Erro ao buscar clientes', description: error });
      })
      .finally(() => setLoading(false));
  }, []);

  const fetchConcreteMixers = (idCliente: number) => {
    setLoading(true);

    concreteMixerService
      .getArray('obter')
      .then((res: ConcreteMixerModel[]) => {
        const _concreteMixers = res.filter((mixer) => mixer.idCliente === idCliente && mixer.deviceId);
        setConcreteMixers(_.uniqBy(_concreteMixers, 'id'));
        setLoading(false);
      })
      .catch((error) => {
        notificationController.error(error);
        setLoading(false);
      });
  };

  const fetchVersions = (idCliente: number) => {
    const endpointMap: Record<keyof VersionData, string> = {
      central_version: 'obterPorIdTipo/1',
      hermes_version: 'obterPorIdTipo/2',
      h2_version: 'obterPorIdTipo/3',
      can_version: 'obterPorIdTipo/4',
    };

    const isMaster = isUserMaster();

    Promise.all(Object.values(endpointMap).map((url) => firmwareVersionService.getArray(url)))
      .then((results) => {
        const versionData: VersionData = Object.keys(endpointMap).reduce((acc, key, idx) => {
          acc[key as keyof VersionData] = isMaster
            ? results[idx].filter((version) => version.idCliente === idCliente)
            : results[idx];
          return acc;
        }, {} as VersionData);

        setVersionsOptions(versionData);
      })
      .catch((error) => {
        notificationController.error(error);
      });
  };

  const handlerClientChange = useCallback((value: number) => {
    setSchedule((prevState) => ({ ...prevState, idCliente: value, ids_concrete_mixer: [] }));
    fetchConcreteMixers(value);
    fetchVersions(value);
  }, []);

  const handlerCreateSchedule = useCallback(() => {
    setLoading(true);
    const user = readUser();
    const username = user?.username ?? '';
    const idsVersoes = [
      schedule.id_can_version,
      schedule.id_central_version,
      schedule.id_h2_version,
      schedule.id_hermes_version,
    ].filter((value): value is number => value !== undefined);
    const idsBetoneiras = schedule.ids_concrete_mixer;

    scheduleService
      .post('', {
        id: 0,
        idCliente: schedule.idCliente,
        idsVersoes: idsVersoes,
        idsBetoneira: idsBetoneiras,
        usuarioResponsavel: username,
      })
      .then(() => {
        notificationController.success({
          message: 'Agendamento cadastrado com sucesso!',
        });
        navigate('/atualizacao-remota/agendamento');
      })
      .catch((error) => {
        notificationController.error(error);
        setLoading(false);
      });
  }, [schedule, navigate]);

  const enableConfirmButton =
    schedule.idCliente &&
    Number(schedule.ids_concrete_mixer?.length) > 0 &&
    (schedule.id_can_version || schedule.id_central_version || schedule.id_h2_version || schedule.id_hermes_version);

  useEffect(() => {
    dispatch(
      setHeaderRegisterPage({
        title: 'Novo agendamento',
        icon: <ArrowLeftOutlined />,
        handleBackClick: () => navigate(`/atualizacao-remota/agendamento`),
      }),
    );
    dispatch(
      setFooter({
        confirmButtonDisabled: !enableConfirmButton,
        confirmButtonText: 'Agendar atualização',
        handleCancelButtonClick: () => {
          navigate(-1);
        },
        handleConfirmButtonClick: () => {
          handlerCreateSchedule();
        },
        cancelButtonText: 'Cancelar',
      }),
    );
  }, [dispatch, enableConfirmButton, handlerCreateSchedule, navigate]);

  useEffect(() => {
    const filter = readUserFilter();
    if (!isUserMaster() && filter.client?.id) {
      setSchedule((prevState) => ({ ...prevState, idCliente: filter.client?.id || 0 }));
      handlerClientChange(filter.client?.id);
    } else {
      fetchClients();
    }
  }, [fetchClients, handlerClientChange]);

  return (
    <>
      <PageContainer>
        <BaseForm layout="vertical" style={{ width: '100%' }}>
          <Spinner spinning={loading}>
            <Description
              title="Informações do agendamento"
              subtitle="Preencha os campos para cadastrar um novo agendamento"
            >
              <Row gutter={18}>
                {isUserMaster() && (
                  <Col xs={24} md={8}>
                    <BaseFormInputItem label="Cliente" supportText="Pesquise e selecione apenas um cliente">
                      <Select
                        showSearch
                        showArrow
                        placeholder="Selecione um cliente"
                        value={schedule?.idCliente}
                        onChange={(value) => handlerClientChange(value as number)}
                        style={{ width: '100%' }}
                        options={clients?.map((client) => ({ value: client.id, label: client.razaoSocial }))}
                        filterOption={(input, option) => option?.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      />
                    </BaseFormInputItem>
                  </Col>
                )}
                <Col xs={24} md={8}>
                  <BaseFormInputItem label="Betoneira" supportText="Pesquise e selecione uma ou mais betoneiras">
                    <Select
                      showArrow
                      showSearch
                      mode="multiple"
                      placeholder="Selecione o n° da BT ou placa do caminhão"
                      disabled={!schedule.idCliente}
                      value={schedule.ids_concrete_mixer}
                      onChange={(value) =>
                        setSchedule((prevState) => ({
                          ...prevState,
                          ids_concrete_mixer: value as number[],
                        }))
                      }
                      options={concreteMixers.map((c) => ({
                        value: c.id,
                        label: `${c.id}: ${c.numeroBT} - ${c.placaCaminhao}`,
                      }))}
                      filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                    />
                  </BaseFormInputItem>
                </Col>
              </Row>
            </Description>
            <Description
              title="Informações da versão do módulo"
              subtitle="Preencha os campos com a versão que o módulo deverá ser atualizada"
            >
              <Row gutter={18}>
                <Col xs={12} md={6}>
                  <BaseFormInputItem label="Versão da Central">
                    <Select
                      showArrow
                      showSearch
                      allowClear
                      placeholder="Selecione a versão da Central"
                      disabled={!schedule.idCliente}
                      value={schedule.id_central_version}
                      onChange={(value) =>
                        setSchedule((prevState) => ({
                          ...prevState,
                          id_central_version: value as number,
                        }))
                      }
                      options={versionsOptions.central_version.map((version) => ({
                        value: version.id,
                        label: `${version.versao}`,
                      }))}
                      filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                    />
                  </BaseFormInputItem>
                </Col>
                <Col xs={12} md={6}>
                  <BaseFormInputItem label="Versão do Hermes">
                    <Select
                      showArrow
                      showSearch
                      allowClear
                      placeholder="Selecione a versão do Hermes"
                      disabled={!schedule.idCliente}
                      value={schedule.id_hermes_version}
                      onChange={(value) =>
                        setSchedule((prevState) => ({ ...prevState, id_hermes_version: value as number }))
                      }
                      options={versionsOptions.hermes_version.map((version) => ({
                        value: version.id,
                        label: `${version.versao}`,
                      }))}
                      filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                    />
                  </BaseFormInputItem>
                </Col>
                <Col xs={12} md={6}>
                  <BaseFormInputItem label="Versão do H2">
                    <Select
                      showArrow
                      showSearch
                      allowClear
                      placeholder="Selecione a versão do H2"
                      disabled={!schedule.idCliente}
                      value={schedule.id_h2_version}
                      onChange={(value) =>
                        setSchedule((prevState) => ({ ...prevState, id_h2_version: value as number }))
                      }
                      options={versionsOptions.h2_version.map((version) => ({
                        value: version.id,
                        label: `${version.versao}`,
                      }))}
                      filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                    />
                  </BaseFormInputItem>
                </Col>
                <Col xs={12} md={6}>
                  <BaseFormInputItem label="Versão da CAN">
                    <Select
                      showArrow
                      showSearch
                      allowClear
                      placeholder="Selecione a versão da CAN"
                      disabled={!schedule.idCliente}
                      value={schedule.id_can_version}
                      onChange={(value) =>
                        setSchedule((prevState) => ({ ...prevState, id_can_version: value as number }))
                      }
                      options={versionsOptions.can_version.map((version) => ({
                        value: version.id,
                        label: `${version.versao}`,
                      }))}
                      filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                    />
                  </BaseFormInputItem>
                </Col>
              </Row>
            </Description>
          </Spinner>
        </BaseForm>
      </PageContainer>
    </>
  );
};
export default ScheduleCreate;
