import env from './env.config';
import dev from './appsettings.dev.json';
import prd from './appsettings.prd.json';
import loc from './appsettings.loc.json';
import qas from './appsettings.qas.json';

const appSettings = () => {
  const environment = env.ENVIRONMENT;

  switch (environment) {
    case 'dev':
      return dev;
    case 'prd':
      return prd;
    case 'loc':
      return loc;
    case 'qas':
      return qas;
    default:
      return loc;
  }
};

export default appSettings;
