import styled from 'styled-components';
import { Row } from 'antd';

export const RowDisplayInput = styled(Row)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  border-bottom: 1px solid;
  border-color: var(--input-placeholder-color);
  padding-top: 3rem;
  padding-bottom: 0.5rem;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.375rem;
`;
