import { Input as AntInput, InputProps } from 'antd';
import styled from 'styled-components';

export const InputTag = styled(AntInput)<InputProps>`
  .ant-select-arrow {
    color: var(--input-placeholder-color);
    cursor: pointer;
  }

  color: var(--text-label);
  font-family: 'Mulish';
  font-style: normal;
  font-size: 0.875rem;
  line-height: 1.375rem;
  border-radius: 6px;
  font-weight: 700 !important;

  background: #ffff;
  border: 2px solid var(--secondary-color);
  border-radius: 4px;
  margin-bottom: 0.5rem;
  width: 30rem;
  height: 2.5rem;

  ::placeholder {
    font-weight: 400 !important;
  }
`;
