import { Button } from '@app/components/common/buttons/Button/Button.styles';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { Table } from '@app/components/common/Table/Table';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { useMounted } from '@app/hooks/useMounted';
import { setHeader } from '@app/store/slices/headerSlice';
import type { ColumnsType } from 'antd/lib/table';
import React, { useCallback, useEffect, useState } from 'react';
import { notificationController } from '@app/controllers/notificationController';
import { Col, Row, Tag } from 'antd';
import { Modal } from '@app/components/common/Modal/Modal';
import { ScheduleModel } from '@app/domain/interfaces/schedule/scheduleModel';
import IScheduleService, { ScheduleService } from '@app/services/scheduleServices';
import { Spinner } from '@app/components/common/Spinner/Spinner';
import { hasAccessByPermission } from '@app/controllers/accessController';
import DropdownTable from '@app/components/dropdown/DropdownTable';
import { IconMore } from '@app/assets/slump-icons';
import { Menu, MenuItem } from '@app/components/common/Menu/Menu';
import Dashboard from '@app/components/dashboard/Dashboard';
import { useNavigate } from 'react-router-dom';
import 'react-initials-avatar/lib/ReactInitialsAvatar.css';
import { SpinnerSlump } from '@app/components/common/SpinSlump/SpinSlump';
import moment from 'moment';
import { handleExportClick } from './ExportScheduleToExcel';
import { genericExportToExcel } from '@app/utils/exportToExcel';

const scheduleService: IScheduleService = new ScheduleService();

const ScheduleDashboard: React.FC = () => {
  const dispatch = useAppDispatch();
  const { isMounted } = useMounted();
  const [schedule, setSchedule] = useState<ScheduleModel>({} as ScheduleModel);
  const [schedules, setSchedules] = useState<ScheduleModel[]>();
  const [loading, setLoading] = useState(false);
  const [modalCancelVisible, setModalCancelVisible] = useState(false);
  const [searchScheduleFiltered, setSearchScheduleFiltered] = useState<ScheduleModel[]>();

  const navigate = useNavigate();

  const handleCancelClick = (user: ScheduleModel) => {
    setSchedule(user);
    setModalCancelVisible(true);
  };

  const handleCancelSchedule = () => {
    setLoading(true);

    scheduleService
      .delete(`${schedule.id}`)
      .then(() => {
        notificationController.success({
          message: `Agendamento cancelado com sucesso!`,
        });
        setModalCancelVisible(false);
        setLoading(false);
        fetchSchedules();
      })
      .catch((error) => {
        setModalCancelVisible(false);
        setLoading(false);
        notificationController.error(error);
      });
  };

  const handleSearchScheduleFilter = (event: React.FormEvent<HTMLInputElement>) => {
    if (event.currentTarget.value.length > 0) {
      const inputValue = event.currentTarget.value.toLowerCase();
      setSearchScheduleFiltered(
        schedules?.filter(
          (item) =>
            item.cliente?.toLowerCase().includes(inputValue) ||
            item.usuarioResponsavel?.toLowerCase().includes(inputValue),
        ),
      );
    } else {
      setSearchScheduleFiltered(schedules);
    }
  };

  const handleExportToExcel = () => {
    genericExportToExcel(
      'agendamento',
      columns,
      searchScheduleFiltered?.map((a) => ({
        ...a,
        dataHora: a.dataHora ? moment(a.dataHora).format('DD/MM/YYYY HH:mm') : '',
        status: a.status === 'Cancelado' ? 'Cancelado' : a.status === 'Pendente' ? 'Pendente' : 'Atualizado',
      })) ?? [],
    );
  };

  const columns: ColumnsType<ScheduleModel> = [
    {
      title: 'Clientes',
      dataIndex: 'cliente',
      showSorterTooltip: false,
      sorter: (a, b) => (a.cliente && b.cliente ? a.cliente.localeCompare(b.cliente) : 0),
      width: '20%',
      render: (client: string) => {
        return (
          <Row align="middle">
            <Col>{client}</Col>
          </Row>
        );
      },
    },
    {
      title: 'Usuários responsáveis',
      dataIndex: 'usuarioResponsavel',
      showSorterTooltip: false,
      sorter: (a, b) =>
        a.usuarioResponsavel && b.usuarioResponsavel ? a.usuarioResponsavel.localeCompare(b.usuarioResponsavel) : 0,
      width: '20%',
    },
    {
      title: 'Data/Hora',
      dataIndex: 'dataHora',
      showSorterTooltip: false,
      sorter: (a, b) =>
        a.dataHora && b.dataHora ? new Date(a.dataHora).getTime() - new Date(b.dataHora).getTime() : 0,
      width: '15%',
      render: (date: string) => moment(date).format('DD/MM/YYYY HH:mm') + 'h',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      sorter: (a, b) => (a.status && b.status ? a.status.localeCompare(b.status) : 0),
      showSorterTooltip: false,
      width: '3%',
      render: (status: ScheduleModel['status']) => {
        switch (status) {
          case 'Pendente':
            return (
              <Tag color="#FEF7E7" style={{ color: '#644508', padding: '0.375rem 0.5rem', border: '0.375rem' }}>
                Pendente
              </Tag>
            );
          case 'Atualizado':
            return (
              <Tag color="#E9F4EE" style={{ color: '#083F18', padding: '0.375rem 0.5rem', border: '0.375rem' }}>
                Atualizado
              </Tag>
            );
          case 'Cancelado':
            return (
              <Tag color="#FEE9EA" style={{ color: '#620E12', padding: '0.375rem 0.5rem', border: '0.375rem' }}>
                Cancelado
              </Tag>
            );
        }
      },
    },
    {
      title: '',
      dataIndex: 'id',
      width: '10%',
      showSorterTooltip: false,
      sorter: (a, b) => (a?.id ? a.id : 0) - (b?.id ? b.id : 0),
      sortDirections: [],
      defaultSortOrder: 'descend',
      render: (_, schedule) => {
        const canView = hasAccessByPermission('schedule', 'R');
        const canDelete = hasAccessByPermission('schedule', 'D');
        return (
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end' }}>
            <DropdownTable
              buttonText=""
              iconD={
                <div style={{ color: '#545454' }}>
                  <IconMore />
                </div>
              }
              trigger={['click']}
              placement="bottomRight"
              overlay={
                <Menu>
                  {canView && (
                    <MenuItem key="edit" onClick={() => navigate(`/atualizacao-remota/agendamento/${schedule.id}`)}>
                      <Button type="text">Visualizar agendamento</Button>
                    </MenuItem>
                  )}
                  <MenuItem key="export-xls" onClick={() => handleExportClick(schedule)}>
                    <Button type="text">Exportar arquivo excel</Button>
                  </MenuItem>
                  {canDelete && schedule.status === 'Pendente' && (
                    <MenuItem key="delete" onClick={() => handleCancelClick(schedule)}>
                      <Button type="text">Cancelar agendamento</Button>
                    </MenuItem>
                  )}
                </Menu>
              }
            ></DropdownTable>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    if (isMounted.current) {
      dispatch(
        setHeader({
          title: 'Agendamento',
        }),
      );
    }
  }, [dispatch, isMounted]);

  const fetchSchedules = useCallback(async () => {
    setLoading(true);

    scheduleService
      .getArray('')
      .then((res) => {
        setSchedules(res);
        setSearchScheduleFiltered(res);
      })
      .catch((error) => {
        notificationController.error(error);
      })
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    fetchSchedules();
  }, [isMounted, fetchSchedules]);

  const handleNewScheduleClick = (e: React.MouseEvent) => {
    e.preventDefault();
    navigate(`/atualizacao-remota/agendamento/cadastrar`);
  };

  useEffect(() => {
    if (!modalCancelVisible) {
      setSchedule({} as ScheduleModel);
    }
  }, [modalCancelVisible]);

  return (
    <>
      <Modal
        title="Cancelar agendamento"
        open={modalCancelVisible}
        onOk={() => handleCancelSchedule()}
        onCancel={() => setModalCancelVisible(false)}
        cancelText="Não"
        okText="Sim"
      >
        <SpinnerSlump spinning={loading}>
          <p>Tem certeza que deseja cancelar o agendamento selecionado?</p>
        </SpinnerSlump>
      </Modal>

      <PageTitle>Agendamento</PageTitle>
      <Spinner spinning={loading}>
        <Dashboard
          title="Agendamento"
          buttonText="Novo Agendamento"
          handleButtonClick={handleNewScheduleClick}
          placeholderTextSearch="Pesquisar por cliente, data ou usuário"
          handleSearchOnChange={handleSearchScheduleFilter}
          exportToExcel={handleExportToExcel}
          table={
            <Table
              columns={columns}
              dataSource={searchScheduleFiltered}
              scroll={{ x: 800 }}
              bordered
              onRow={(record) => {
                return {
                  onDoubleClick: () => {
                    navigate(`/atualizacao-remota/agendamento/${record.id}`);
                  },
                };
              }}
            />
          }
        />
      </Spinner>
    </>
  );
};

export default ScheduleDashboard;
