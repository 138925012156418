import * as React from 'react';
const SvgIconCaminhaoBetoneiraEsquerda = () => (
  <svg width="20" height="20" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20.3211 10.6075L22.7002 11.2398V10.8C22.7002 10.7193 22.6848 10.6632 22.66 10.6171L22.6598 10.6172L22.6538 10.6054L21.6038 8.52536L21.6038 8.52539L21.6001 8.51782C21.5373 8.388 21.3871 8.29004 21.2202 8.29004H20.3202V10.6072C20.3205 10.6073 20.3208 10.6074 20.3211 10.6075Z"
      fill="current"
      stroke="current"
    />
    <path
      d="M18.3102 10.6198V8.2998H17.9502C17.7128 8.2998 17.5202 8.48945 17.5202 8.7298V15.1998C17.5202 15.4372 17.7098 15.6298 17.9502 15.6298H22.2702C22.5076 15.6298 22.7002 15.4402 22.7002 15.1998V13.3142L19.8034 12.5435C18.9253 12.3163 18.3102 11.5289 18.3102 10.6198Z"
      fill="current"
      stroke="current"
    />
    <mask id="path-3-inside-1_7613_1028" fill="current">
      <path d="M17.0198 13.8199H5.85984C5.44984 13.8199 5.05984 13.6499 4.78984 13.3499L2.76984 11.1399C2.48984 10.8399 2.10984 10.6699 1.69984 10.6699H0.839844L1.88984 15.0299C2.04984 15.6799 2.62984 16.1399 3.29984 16.1399H18.5898" />
    </mask>
    <path
      d="M17.0198 13.8199H5.85984C5.44984 13.8199 5.05984 13.6499 4.78984 13.3499L2.76984 11.1399C2.48984 10.8399 2.10984 10.6699 1.69984 10.6699H0.839844L1.88984 15.0299C2.04984 15.6799 2.62984 16.1399 3.29984 16.1399H18.5898"
      fill="current"
    />
    <path
      d="M4.78984 13.3499L5.52799 12.6752L5.53314 12.681L4.78984 13.3499ZM2.76984 11.1399L3.50094 10.4576L3.50797 10.4653L2.76984 11.1399ZM0.839844 10.6699L-0.13236 10.9041L-0.429571 9.66992H0.839844V10.6699ZM1.88984 15.0299L0.918816 15.2689L0.917639 15.2641L1.88984 15.0299ZM17.0198 14.8199H5.85984V12.8199H17.0198V14.8199ZM5.85984 14.8199C5.17497 14.8199 4.51188 14.5359 4.04655 14.0189L5.53314 12.681C5.60781 12.7639 5.72472 12.8199 5.85984 12.8199V14.8199ZM4.05172 14.0246L2.03172 11.8146L3.50797 10.4653L5.52797 12.6753L4.05172 14.0246ZM2.03879 11.8222C1.94219 11.7187 1.82439 11.6699 1.69984 11.6699V9.66992C2.3953 9.66992 3.03749 9.9611 3.5009 10.4576L2.03879 11.8222ZM1.69984 11.6699H0.839844V9.66992H1.69984V11.6699ZM1.81205 10.4358L2.86205 14.7958L0.917639 15.2641L-0.13236 10.9041L1.81205 10.4358ZM2.86086 14.7909C2.91217 14.9994 3.09554 15.1399 3.29984 15.1399V17.1399C2.16414 17.1399 1.18751 16.3605 0.918829 15.2689L2.86086 14.7909ZM3.29984 15.1399H18.5898V17.1399H3.29984V15.1399Z"
      fill="current"
      mask="url(#path-3-inside-1_7613_1028)"
    />
    <path
      d="M1.32686 6.80999L1.32382 6.80953C1.2597 7.01401 1.3283 7.24941 1.49234 7.38782L1.4933 7.38863L4.2899 9.76034V9.53996C4.2899 9.21722 4.35168 8.89828 4.46132 8.60552C4.46146 8.60515 4.4616 8.60477 4.46174 8.6044L5.91032 4.68821C6.09434 4.18119 6.67125 3.90334 7.1944 4.10139C7.70016 4.28667 7.97669 4.86336 7.77791 5.38595L6.3343 9.2887C6.3121 9.36513 6.2999 9.45108 6.2999 9.53996V11.2016L8.1399 11.7724V10.39C8.1399 10.0814 8.20396 9.78508 8.29765 9.5157L8.2976 9.51569L8.30012 9.50876L9.54896 6.08196C9.7368 5.54728 10.321 5.30356 10.8235 5.47747C11.3612 5.66362 11.6068 6.24972 11.4324 6.75352L11.4325 6.75355L11.4292 6.76237L10.1722 10.1842C10.151 10.2503 10.1399 10.3242 10.1399 10.39V11.762L12.9555 11.161L12.9569 11.1607C13.3438 11.0792 13.6527 10.7948 13.7712 10.4057C13.7713 10.4053 13.7714 10.405 13.7715 10.4047L14.3204 8.56829C14.3205 8.56807 14.3205 8.56784 14.3206 8.56762C14.4031 8.28746 14.3368 7.99125 14.1504 7.78684L14.1504 7.78685L14.1486 7.78486L11.4786 4.82486L11.4783 4.82445C11.2704 4.59355 11.0018 4.42514 10.7042 4.34119L10.7025 4.34073L8.46901 3.70257C8.21099 3.63666 7.95794 3.61565 7.72389 3.64586L7.72122 3.64619L2.46122 4.29619L2.46043 4.29629C2.24272 4.32284 2.05872 4.47898 1.99779 4.67701L1.9978 4.67701L1.99686 4.67999L1.32686 6.80999Z"
      fill="current"
      stroke="current"
    />
    <path
      d="M20.0902 20.8602C18.9469 20.8602 18.0202 19.9334 18.0202 18.7902C18.0202 17.647 18.9469 16.7202 20.0902 16.7202C21.2334 16.7202 22.1602 17.647 22.1602 18.7902C22.1602 19.9334 21.2334 20.8602 20.0902 20.8602Z"
      stroke="current"
      strokeWidth="0.92"
      strokeMiterlimit="10"
    />
    <path
      d="M13.5101 20.8602C12.3668 20.8602 11.4401 19.9334 11.4401 18.7902C11.4401 17.647 12.3668 16.7202 13.5101 16.7202C14.6533 16.7202 15.5801 17.647 15.5801 18.7902C15.5801 19.9334 14.6533 20.8602 13.5101 20.8602Z"
      stroke="current"
      strokeWidth="0.92"
      strokeMiterlimit="10"
    />
    <path
      d="M5.88019 20.8602C4.73696 20.8602 3.81019 19.9334 3.81019 18.7902C3.81019 17.647 4.73696 16.7202 5.88019 16.7202C7.02342 16.7202 7.9502 17.647 7.9502 18.7902C7.9502 19.9334 7.02342 20.8602 5.88019 20.8602Z"
      stroke="current"
      strokeWidth="0.92"
      strokeMiterlimit="10"
    />
    <path d="M11.25 18.79H7.95" stroke="current" strokeWidth="0.92" strokeMiterlimit="10" />
    <path d="M18.54 6.08008H17.02" stroke="current" strokeWidth="0.92" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
export default SvgIconCaminhaoBetoneiraEsquerda;
