import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import MainContent from '../main/MainContent/MainContent';
import * as S from '../main/MainLayout/MainLayout.styles';
import MainSider from '../main/sider/MainSider/MainSider';
import HeaderMonitoring from './Header/HeaderConcreteMixerMonitoring';

const ConcreteMixerMonitoring: React.FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [siderCollapsed, setSiderCollapsed] = useState(true);

  return (
    <S.LayoutMaster>
      <MainSider hidden={false} forceCollapse={true} isCollapsed={siderCollapsed} setCollapsed={setSiderCollapsed} />
      <S.LayoutMain collapse={true}>
        <HeaderMonitoring />
        <MainContent id="main-content" $isTwoColumnsLayout={false}>
          <div style={{ padding: '1rem' }}>
            <Outlet />
          </div>
        </MainContent>
      </S.LayoutMain>
    </S.LayoutMaster>
  );
};

export default ConcreteMixerMonitoring;
