import { VersionModuleModel } from '@app/domain/interfaces/firmware_version/versionModuleModel';
import { FirmwareVersionModel } from './../domain/interfaces/firmware_version/firmwareVersionModel';
import { ApiServiceBase, IApiServiceBase } from './apiServiceBase';
import { VersionStatusModel } from '@app/domain/interfaces/firmware_version/versionStatusModel';

export default interface IFirmwareVersionService extends IApiServiceBase<FirmwareVersionModel, FirmwareVersionModel> {
  getFirmwareVersionById(id: number): Promise<FirmwareVersionModel>;
  getModulesOptions(): Promise<VersionModuleModel[]>;
  getStatusOptions(): Promise<VersionStatusModel[]>;
}

export class FirmwareVersionService
  extends ApiServiceBase<FirmwareVersionModel, FirmwareVersionModel>
  implements IFirmwareVersionService
{
  constructor() {
    super('admin/versao');
  }

  async getFirmwareVersionById(id: number): Promise<FirmwareVersionModel> {
    try {
      return this.getArray(`obterPorId/${id}`) as unknown as Promise<FirmwareVersionModel>;
    } catch (error) {
      throw error;
    }
  }

  async getModulesOptions(): Promise<VersionModuleModel[]> {
    try {
      return this.getArray('tipos') as unknown as VersionModuleModel[];
    } catch (error) {
      throw error;
    }
  }

  async getStatusOptions(): Promise<VersionStatusModel[]> {
    try {
      return this.getArray('status') as unknown as VersionStatusModel[];
    } catch (error) {
      throw error;
    }
  }
}
