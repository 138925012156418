import * as React from 'react';
const SvgIconFileExcel = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 36 36" width="1em" height="1em" role="img">
    <g clipPath="url(#a)">
      <path
        fill="current"
        fillOpacity={0.85}
        d="M31.764 9.024 23.118.378A1.283 1.283 0 0 0 22.21 0H5.142C4.43 0 3.856.575 3.856 1.286v33.428c0 .711.575 1.286 1.286 1.286h25.714c.711 0 1.286-.575 1.286-1.286V9.936c0-.341-.137-.67-.378-.912Zm-2.587 1.503h-7.562V2.965l7.562 7.562Zm.072 22.58H6.75V2.893h12.134v8.678a1.688 1.688 0 0 0 1.688 1.688h8.678v19.848Zm-11.166-12.37L15.6 16.621a.485.485 0 0 0-.413-.233h-1.543a.448.448 0 0 0-.257.076.48.48 0 0 0-.15.667l3.308 5.24-3.351 5.335a.483.483 0 0 0 .41.74h1.386a.484.484 0 0 0 .41-.23l2.519-4.078 2.503 4.074a.48.48 0 0 0 .41.23h1.506a.481.481 0 0 0 .406-.743l-3.375-5.24 3.427-5.324a.483.483 0 0 0-.405-.743h-1.435a.485.485 0 0 0-.414.233l-2.459 4.11Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="current" d="M0 0h36v36H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgIconFileExcel;
