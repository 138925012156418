import { ConcreteMixerReturning } from '@app/domain/interfaces/monitoring/footerMonitoringModels';
import { Divider } from 'antd';
import React from 'react';
import { FooterListItem } from '../FooterListItem/FooterListItem';
import * as S from './FooterList.styles';

export interface FooterListProps {
  footerListItem: ConcreteMixerReturning[];
}

export const FooterList: React.FC<FooterListProps> = ({ footerListItem }) => {
  return (
    <S.FooterListContainer>
      <S.FooterListWrap>
        {footerListItem.map((i) => {
          return (
            <>
              <FooterListItem
                key={i.betoneira}
                betoneira={i.betoneira}
                motorista={i.motorista}
                tempoRetorno={i.tempoRetorno}
              />
              <Divider type="vertical" style={{ backgroundColor: '#8894B7', height: '69px', marginRight: '1.5rem' }} />
            </>
          );
        })}
      </S.FooterListWrap>
    </S.FooterListContainer>
  );
};
