import React from 'react';
import * as S from './HelperNotification.styles';
import { InfoCircleOutlined } from '@ant-design/icons';

interface HelperNotificationProps {
  children: React.ReactNode;
  style?: React.CSSProperties;
  iconColor?: string;
}

export const HelperNotification: React.FC<HelperNotificationProps> = ({
  children,
  style,
  iconColor = 'var(--primary-color)',
}) => {
  return (
    <S.Notification style={style}>
      <InfoCircleOutlined style={{ color: iconColor, fontSize: '1.5rem' }} />
      <S.TextInfo>{children}</S.TextInfo>
    </S.Notification>
  );
};
