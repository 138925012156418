import * as React from 'react';
const SvgIconFila = () => (
  <svg width="12" height="21" viewBox="0 0 12 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 0.5V6.5H0.0100002L0 6.51L4 10.5L0 14.5L0.0100002 14.51H0V20.5H12V14.51H11.99L12 14.5L8 10.5L12 6.51L11.99 6.5H12V0.5H0Z"
      fill="#C41B24"
    />
  </svg>
);
export default SvgIconFila;
