import { IFooter } from '@app/constants/interfaces/FooterPage/IFooter';
import { createAction, createSlice, PrepareAction } from '@reduxjs/toolkit';

export interface FooterPageSlice {
  confirmButtonText: string;
  handleConfirmButtonClick: () => void;
  cancelButtonText: string;
  handleCancelButtonClick: () => void;
  confirmButtonDisabled: boolean;
}

const initialState: FooterPageSlice = {
  confirmButtonDisabled: false,
  confirmButtonText: '',
  handleConfirmButtonClick: () => {
    // event.preventDefault();
  },
  cancelButtonText: '',
  handleCancelButtonClick: () => {
    // event.preventDefault();
  },
};

export const setFooter = createAction<PrepareAction<IFooter>>('footerPage/setFooterPage', (newFooter: IFooter) => {
  return {
    payload: newFooter,
  };
});

export const footerPageSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setFooter, (state, action) => {
      state.confirmButtonDisabled = action.payload.confirmButtonDisabled;
      state.confirmButtonText = action.payload.confirmButtonText;
      state.handleConfirmButtonClick = action.payload.handleConfirmButtonClick;
      state.cancelButtonText = action.payload.cancelButtonText;
      state.handleCancelButtonClick = action.payload.handleCancelButtonClick;
    });
  },
});

export default footerPageSlice.reducer;
