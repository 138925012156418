import { IHeaderRegisterPage } from '@app/constants/interfaces/Header/IHeaderRegisterPage';
import { AlertModel } from '@app/domain/interfaces/alert/alertModel';
import { ClientModel } from '@app/domain/interfaces/clientModel';
import { ClientBranchModel } from '@app/domain/interfaces/client_branch/clientBranchModel';
import { GroupModel } from '@app/domain/interfaces/client_branch/groupModel';
import { ConcreteMixerModel } from '@app/domain/interfaces/concreteMixerModel';
import { ConfigAlertModel } from '@app/domain/interfaces/configAlertModel';
import { DeviceModel } from '@app/domain/interfaces/device/deviceModel';
import { createAction, createSlice, PrepareAction } from '@reduxjs/toolkit';

export interface HeaderRegisterPagerSlice {
  title: string;
  handleBackClick: (event: React.MouseEvent<HTMLElement>) => void;
  tableData:
    | ClientModel[]
    | ConcreteMixerModel[]
    | ConfigAlertModel[]
    | AlertModel[]
    | DeviceModel[]
    | ClientBranchModel[]
    | GroupModel[]
    | [];
  drawerVisibleRegister: boolean;
}

const initialState: HeaderRegisterPagerSlice = {
  title: '',
  handleBackClick: (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
  },
  tableData: [],
  drawerVisibleRegister: false,
};

export const setHeaderRegisterPage = createAction<PrepareAction<IHeaderRegisterPage>>(
  'headerRegisterPage/setHeaderRegisterPage',
  (newHeaderRegister: IHeaderRegisterPage) => {
    return {
      payload: newHeaderRegister,
    };
  },
);

export const setDrawerVisibleRegister = createAction<PrepareAction<boolean>>(
  'headerRegisterPage/setDrawerVisible',
  (visible: boolean) => {
    return {
      payload: visible,
    };
  },
);

export const headerRegisterSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setHeaderRegisterPage, (state, action) => {
      state.title = action.payload.title;
      state.handleBackClick = action.payload.handleBackClick;
    });
    builder.addCase(setDrawerVisibleRegister, (state, action) => {
      state.drawerVisibleRegister = action.payload;
    });
  },
});

export default headerRegisterSlice.reducer;
