import { References } from '@app/components/common/References/References';
import DrawerChangeVision from '@app/components/drawers/profile/DrawerChangeVision';
import { useResponsive } from '@app/hooks/useResponsive';
import React, { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { Header } from '../../../header/Header';
import MainContent from '../TrackingContent/TrackingContent';
import { TrackingHeader } from '../TrackingHeader/TrackingHeader';
import MainSider from '../../main/sider/MainSider/MainSider';
import * as S from '../../main/MainLayout/MainLayout.styles';
import { Divider } from 'antd';

const TrackingLayout: React.FC = () => {
  const [isTwoColumnsLayout, setIsTwoColumnsLayout] = useState(true);
  const [siderCollapsed, setSiderCollapsed] = useState(true);
  const [hiddenSider, setHiddenSider] = useState(false);
  const [forceCollapse, setForceCollapse] = useState(true);
  const { isDesktop } = useResponsive();
  const location = useLocation();

  const toggleSider = () => {
    setSiderCollapsed(!siderCollapsed);
  };

  useEffect(() => {
    setIsTwoColumnsLayout(!isDesktop);
  }, [location.pathname, isDesktop]);

  return (
    <S.LayoutMaster>
      <MainSider
        hidden={hiddenSider}
        forceCollapse={forceCollapse}
        isCollapsed={siderCollapsed}
        setCollapsed={setSiderCollapsed}
      />
      <S.LayoutMain collapse={forceCollapse}>
        <TrackingHeader
          forceCollapse={forceCollapse}
          setForceCollapse={setForceCollapse}
          responsiveColumnsLayout={false}
          setHiddenSider={setHiddenSider}
          hiddenSider={hiddenSider}
        />
        <MainContent id="main-content" $isTwoColumnsLayout={false}>
          <div style={{ padding: '1rem' }}>
            <Outlet />
          </div>
        </MainContent>
      </S.LayoutMain>
    </S.LayoutMaster>
  );
};

export default TrackingLayout;
