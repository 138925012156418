import React, { useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import { ProfileDropdown } from '../components/profileDropdown/ProfileDropdown/ProfileDropdown';
import { HeaderSearch } from '../components/HeaderSearch/HeaderSearch';
import * as S from '../Header.styles';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { FilterOutlined, PlusSquareOutlined } from '@ant-design/icons';
import { Button } from '@app/components/common/buttons/Button/Button';
import { TableDataType } from '@app/constants/enums/tableDataType';

interface MobileHeaderProps {
  toggleSider: () => void;
  isSiderOpened: boolean;
}

export const MobileHeader: React.FC<MobileHeaderProps> = ({ toggleSider, isSiderOpened }) => {
  const { buttonText, title, handleButtonClick, tableDataType, alertFilter } = useAppSelector((state) => state.header);
  const [alertFilterCount, setAlertFilterCount] = useState<number>(0);

  useEffect(() => {
    if (alertFilter !== null) {
      setAlertFilterCount(alertFilter);
    } else {
      setAlertFilterCount(0);
    }
  }, [alertFilter]);

  return (
    <Row justify="space-between" align="middle">
      <Col>
        <ProfileDropdown />
      </Col>
      <Col
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          width: '6rem',
          marginRight: '0.5rem',
          justifyContent: 'center',
          alignItems: 'center',
          marginBottom: '-0.5rem',
        }}
      >
        <S.HeaderTitle>{title}</S.HeaderTitle>
      </Col>
      <Col>
        <Button type="ghost" onClick={handleButtonClick}>
          {buttonText?.length > 0 ? (
            tableDataType === TableDataType.Alert ? (
              <div>
                <FilterOutlined /> {alertFilterCount > 0 && `(${alertFilterCount})`}
              </div>
            ) : (
              <PlusSquareOutlined />
            )
          ) : null}
        </Button>
      </Col>

      <Col>
        <Row align="middle">
          <Col>{(title?.length > 0 && buttonText?.length > 0) ?? <HeaderSearch />}</Col>
        </Row>
      </Col>

      <S.BurgerCol>{!isSiderOpened && <S.MobileBurger onClick={toggleSider} isCross={isSiderOpened} />}</S.BurgerCol>
    </Row>
  );
};
