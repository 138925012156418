import styled, { css } from 'styled-components';
import { Col, Menu, Progress as AntdProgress } from 'antd';
import { LAYOUT, media, FONT_SIZE, FONT_FAMILY, FONT_WEIGHT, BASE_COLORS } from '@app/styles/themes/constants';

export const Column = styled(Col)`
  padding: ${LAYOUT.desktop.paddingVertical} ${LAYOUT.desktop.paddingHorizontal};
`;

interface ConfigColumn {
  $responsiveColumnsLayout: boolean;
}

export const ConfigColumn = styled(Col)<ConfigColumn>`
  @media only screen and (${media.md}) {
    ${(props) =>
      props?.$responsiveColumnsLayout &&
      css`
        background-color: var(--sider-background-color);
        padding: ${LAYOUT.desktop.paddingVertical} ${LAYOUT.desktop.paddingHorizontal};
      `}
  }
`;

export const DropdownHeader = styled(Menu)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 1.25rem;

  @media only screen and (${media.md}) {
    align-items: center;
    flex-wrap: wrap;
    word-wrap: break-word;
  }

  & .ant-btn-text {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

// Container to position the text over the progress bar
export const ProgressContainer = styled.div`
  position: relative;
  width: fit-content;
`;

// Styled version of AntdProgress
export const Progress = styled(AntdProgress)`
  .ant-progress-inner {
    background-color: #159d3b; // Example style
  }
`;

interface ProgressInnerTextProps {
  color: string;
}

export const ProgressInnerText = styled.div<ProgressInnerTextProps>`
  position: absolute;
  width: 100%;
  top: 2px;
  left: 0;
  padding-right: 8px;
  font-size: 15px;
  text-align: end;
  ${(props) => css`
    color: ${props.color};
  `}
`;

export const PageTitle = styled.p`
  font-size: ${FONT_SIZE.xxl} !important;
  font-weight: ${FONT_WEIGHT.semibold};
  font-family: ${FONT_FAMILY.main};
  color: var(--primary-color);
  margin-bottom: 0;
  margin-left: 15px;

  @media only screen and (${media.xs}) {
    font-size: ${FONT_SIZE.xs};
  }

  @media only screen and (${media.md}) {
    font-family: 'Mulish';
    font-size: ${FONT_SIZE.xl};
    flex-wrap: wrap;
  }

  @media only screen and (${media.sm}) {
    font-size: ${FONT_SIZE.xs};
  }

  @media only screen and (${media.xxl}) {
    font-size: ${FONT_SIZE.xxl};
  }
`;
