import * as React from 'react';
const SvgIconUp = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 36 36" width="1em" height="1em" role="img">
    <path
      fill="#383838"
      d="M33.208 27.776 19.04 8.245a1.282 1.282 0 0 0-2.077 0l-14.17 19.53a.321.321 0 0 0 .26.511h3.014c.205 0 .398-.1.518-.265L18 12.287 29.415 28.02c.12.165.313.265.518.265h3.013a.323.323 0 0 0 .262-.51Z"
    />
  </svg>
);
export default SvgIconUp;
