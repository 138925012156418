export enum ObraProgressoStatus {
  Previsto = 0,
  PertoAtraso = 1,
  Atrasado = 2,
  MuitoAtrasado = 3,
}

export enum BombaStatus {
  aCaminho = 'A CAMINHO',
  BombaObra = 'BOMBA NA OBRA',
  Suspensa = 'SUSPENSO PELA PROG.',
}

export enum CardMonitoringStatus {
  InProgress = 0,
  PartialSuspended = 1,
  TotalSuspended = 2,
  Canceled = 3,
}
