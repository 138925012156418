import { ThemeType } from '@app/constants/interfaces/interfaces';
import { hexToRGB } from '@app/utils/utils';
import { css } from 'styled-components';
import { BASE_COLORS } from './constants';
import { darkColorsTheme, antDarkColorsTheme } from './dark/darkTheme';
import { lightColorsTheme } from './light/lightTheme';

export const themeObject = {
  light: lightColorsTheme,
  dark: darkColorsTheme,
};

export const antThemeObject = {
  light: {},
  dark: antDarkColorsTheme,
};

const getThemeVariables = (theme: ThemeType) => css`
  color-scheme: ${theme};
  --primary-color: ${themeObject[theme].primary};
  --secondary-color: ${themeObject[theme].secondary};
  --background-main-color: ${themeObject[theme].backgroundMain};
  --background-color: ${themeObject[theme].backgroundCard};
  --background-base-color: ${themeObject[theme].backgroundColorBase};
  --layout-header-main-color: ${themeObject[theme].headerMain};
  --text-main-color: ${themeObject[theme].textMain};
  --text-main-info-color: ${themeObject[theme].textMainInfo};
  --text-dark-color: ${themeObject[theme].textDark};
  --text-label: ${themeObject[theme].textLabel};
  --text-header: ${themeObject[theme].textHeader};
  --border-pagination: ${themeObject[theme].borderPagination};
  --alert-text-color: ${themeObject[theme].alertTextColor};
  --text-sider-primary-color: ${themeObject[theme].textSiderPrimary};
  --text-sider-secondary-color: ${themeObject[theme].textSiderSecondary};
  --text-pagination-color: ${themeObject[theme].textPagination};
  --layout-sider-bg-color: ${themeObject[theme].layoutSiderBg};
  --border-color: ${themeObject[theme].borderCollapse};
  --collapse-background-color: ${themeObject[theme].collapseBackground};
  --text-secondary-color: ${themeObject[theme].textSecondary};
  --tooltip-background: ${themeObject[theme].tooltipBackground};

  --disabled-input-color: ${themeObject[theme].disabledInput};
  --input-placeholder-color: ${themeObject[theme].inputPlaceholder};
  --disabled-placeholder-color: ${themeObject[theme].disablePlaceholder};
  /*  */
  --border-base: ${themeObject[theme].borderBase};
  --border-drawer: ${themeObject[theme].borderDrawer};
  --error-color: ${themeObject[theme].inputError};
  --success-color: ${themeObject[theme].inputSuccess};
  --warning-color: ${themeObject[theme].inputWarning};
  --button-hover: ${themeObject[theme].buttonHover};
  --button-tab-color: ${themeObject[theme].buttonTabColor};
  --new-alert-hover: ${themeObject[theme].newAlertHover};
  --confimed-hover: ${themeObject[theme].confirmedHover};
  --reported-hover: ${themeObject[theme].reportedHover};
  --new-alert-color: ${themeObject[theme].newAlert};
  --confirmed-color: ${themeObject[theme].confirmed};
  --reported-color: ${themeObject[theme].reported};
  --shadow-color: ${themeObject[theme].shadow};
  --footer-color: ${themeObject[theme].footerColor};
  --scroll-color: ${themeObject[theme].scroll};
  --primary-rgb-color: ${hexToRGB(themeObject[theme].primary)};
  --info-rgb-color: ${hexToRGB(themeObject[theme].primary)};
  --secondary-rgb-color: ${hexToRGB(themeObject[theme].secondary)};
  --error-rgb-color: ${hexToRGB(themeObject[theme].inputError)};
  --success-rgb-color: ${hexToRGB(themeObject[theme].inputSuccess)};
  --background-rgb-color: ${hexToRGB(themeObject[theme].backgroundCard)};
  --notification-error-color: ${themeObject[theme].notificationError};
  --notification-success-color: ${themeObject[theme].notificationSuccess};
  --notification-warning-color: ${themeObject[theme].notificationWarning} --icon-color: ${themeObject[theme].icon};
  --icon-hover-color: ${themeObject[theme].iconHover};
  --box-shadow: ${themeObject[theme].boxShadow};
  --box-shadow-hover: ${themeObject[theme].boxShadowHover};
  --shadow: ${themeObject[theme].shadow};
  --disabled-bg-color: ${themeObject[theme].backgroundDisabledInput};
  --avatar-user-color: ${themeObject[theme].avatarUser};
`;

export const lightThemeVariables = css`
  ${getThemeVariables('light')}
`;

export const darkThemeVariables = css`
  ${getThemeVariables('dark')}
  --ant-success-color-deprecated-bg: ${antThemeObject['dark'].successBg} !important;
  --ant-success-color-deprecated-border: ${antThemeObject['dark'].successBorder} !important;
`;

export const commonThemeVariables = css`
  color-scheme: light dark;
  --white: ${BASE_COLORS.white};
  --black: ${BASE_COLORS.black};
  --green: ${BASE_COLORS.green};
  --orange: ${BASE_COLORS.orange};
  --gray: ${BASE_COLORS.gray};
  --lightgrey: ${BASE_COLORS.lightgrey};
  --lightgreen: ${BASE_COLORS.lightgreen};
  --blue: ${BASE_COLORS.blue};
  --skyblue: ${BASE_COLORS.skyblue};
  --red: ${BASE_COLORS.red};
`;

export const antOverrideCssVariables = css`
  --ant-primary-1: var(--primary1-color) !important;
`;
