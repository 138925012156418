import styled, { css } from 'styled-components';

interface BaseFormSupportTextWrapperProps {
  isSuccess?: boolean;
}

export const BaseFormSupportTextWrapper = styled.span<BaseFormSupportTextWrapperProps>`
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.25rem;
  margin-left: 0.5rem;

  ${(props) =>
    props.isSuccess
      ? css`
          color: var(--success-color);
        `
      : css`
          color: var(--error-color);
        `}

  ${({ isSuccess }) =>
    isSuccess == null || isSuccess == undefined
      ? css`
          color: var(--input-placeholder-color);
        `
      : isSuccess
      ? css`
          color: var(--success-color);
        `
      : css`
          color: var(--notification-error-color);
          font-weight: 700;
        `}
`;
