import { ApiServiceBase, IApiServiceBase } from './apiServiceBase';
import { TravelInfoModel } from '@app/domain/interfaces/concrete_mixer_location/travelInfoModel';

export default interface ITravelInfoService extends IApiServiceBase<TravelInfoModel, TravelInfoModel> {}

export class TravelInfoService extends ApiServiceBase<TravelInfoModel, TravelInfoModel> implements ITravelInfoService {
  constructor() {
    super('admin/info-viagem');
  }
}
