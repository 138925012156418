import { PauseCircleFilled } from '@ant-design/icons';
import {
  IconArrowCircle,
  IconBetoneiraFilled,
  IconHome,
  IconInfoCircle,
  IconStreet,
  IconTempo,
  IconFalta,
  IconFila,
  IconMore,
} from '@app/assets/slump-icons';
import { BombaStatus, CardMonitoringStatus } from '@app/constants/enums/monitoring';
import { LogMonitoringModel } from '@app/domain/interfaces/monitoring/logMonitoringModel';
import 'animate.css';
import { Carousel, Col, Progress, Row, Space } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';
import * as S from './CardMonitoring.styles';
import { Tooltip } from '../Tooltip/Tooltip';
import DropdownTable from '@app/components/dropdown/DropdownTable';
import { Menu, MenuItem } from '../Menu/Menu';
import { Button } from '../buttons/Button/Button';
import ModalTripInfo from '@app/pages/modules/Monitoring/components/ModalTripInfo';
import ModalMapTripInfo from '@app/pages/modules/Monitoring/components/ModalMapTripInfo';
import { Popover } from '@app/components/common/Popover/Popover';

interface CardMonitoringProps {
  priority: number;
  status?: CardMonitoringStatus;
  size?: 'small' | 'middle' | 'large' | string;
  logMonitoring?: LogMonitoringModel;
  onAnimatedEnd?: () => void;
}

const alignStart: React.CSSProperties = { textAlign: 'start' };
const alignCenter: React.CSSProperties = { textAlign: 'center' };
const alignEnd: React.CSSProperties = { textAlign: 'end' };

export const CardMonitoring: React.FC<CardMonitoringProps> = ({
  priority,
  size,
  status = CardMonitoringStatus.InProgress,
  logMonitoring,
  onAnimatedEnd,
}) => {
  const [modalInfoTripVisible, setModalInfoTripVisible] = useState<boolean>(false);
  const [modalMapTripVisible, setModalMapTripVisible] = useState<boolean>(false);

  const IconsContent = (
    <Row gutter={6} justify="space-between" align="bottom" style={{ width: '100%' }}>
      <S.CardMonitoringContentCol span={6} hasDivider={true}>
        <Popover
          show={!!logMonitoring?.betoneirasPatio?.contador && logMonitoring?.betoneirasPatio?.contador > 0}
          title={'Betoneiras pátio'}
          content={logMonitoring?.betoneirasPatio?.betoneiras?.join('\n')}
        >
          <S.CardMonitoringIconMarker quantity={logMonitoring?.betoneirasPatio?.contador ?? 0}>
            <IconHome />
            {logMonitoring?.betoneirasPatio?.contador ?? 0}
          </S.CardMonitoringIconMarker>
        </Popover>
      </S.CardMonitoringContentCol>
      <S.CardMonitoringContentCol span={6} hasDivider={true}>
        <Popover
          show={!!logMonitoring?.betoneirasCaminho?.contador && logMonitoring?.betoneirasCaminho?.contador > 0}
          title={'Betoneiras caminho'}
          content={logMonitoring?.betoneirasCaminho?.betoneiras?.join('\n')}
        >
          <S.CardMonitoringIconMarker quantity={logMonitoring?.betoneirasCaminho?.contador ?? 0}>
            <IconStreet />
            {logMonitoring?.betoneirasCaminho?.contador ?? 0}
          </S.CardMonitoringIconMarker>
        </Popover>
      </S.CardMonitoringContentCol>
      <S.CardMonitoringContentCol span={6} hasDivider={true}>
        <Popover
          show={!!logMonitoring?.betoneirasAguardando?.contador && logMonitoring?.betoneirasAguardando?.contador > 0}
          title={'Betoneiras aguardando'}
          content={logMonitoring?.betoneirasAguardando?.betoneiras?.join('\n')}
        >
          <S.CardMonitoringIconMarker quantity={logMonitoring?.betoneirasAguardando?.contador ?? 0}>
            {logMonitoring?.filaObra ? <IconFila /> : logMonitoring?.faltaBetoneira ? <IconFalta /> : <IconTempo />}
            {logMonitoring?.betoneirasAguardando?.contador ?? 0}
          </S.CardMonitoringIconMarker>
        </Popover>
      </S.CardMonitoringContentCol>
      <S.CardMonitoringContentCol span={6}>
        <Popover
          show={
            !!logMonitoring?.betoneirasDescarregando?.contador && logMonitoring?.betoneirasDescarregando?.contador > 0
          }
          title={'Betoneiras descarregando'}
          content={logMonitoring?.betoneirasDescarregando?.betoneiras?.join('\n')}
        >
          <S.CardMonitoringIconMarker quantity={logMonitoring?.betoneirasDescarregando?.contador ?? 0}>
            <IconArrowCircle />
            {logMonitoring?.betoneirasDescarregando?.contador ?? 0}
          </S.CardMonitoringIconMarker>
        </Popover>
      </S.CardMonitoringContentCol>
    </Row>
  );

  const renderBomba = () => {
    if (logMonitoring?.bombas?.length && logMonitoring?.bombas?.length > 1) {
      return (
        <div>
          B+{' '}
          <Popover
            title="Bombas"
            content={
              <div>
                {logMonitoring.bombas.map((bomba) => (
                  <div key={bomba} style={{ backgroundColor: 'white' }}>
                    {bomba}
                  </div>
                ))}{' '}
              </div>
            }
          >
            <span
              style={{
                fontSize: '0.75rem',
                marginTop: '0.125rem',
                cursor: 'pointer',
              }}
            >
              <IconInfoCircle />
            </span>
          </Popover>
        </div>
      );
    }

    return logMonitoring?.bombas[0] ?? '-';
  };

  return (
    <>
      <ModalTripInfo
        modalVisible={modalInfoTripVisible}
        setModalVisible={setModalInfoTripVisible}
        monitoring={logMonitoring ?? ({} as LogMonitoringModel)}
      />

      <ModalMapTripInfo
        modalVisible={modalMapTripVisible}
        setModalVisible={setModalMapTripVisible}
        monitoring={logMonitoring ?? ({} as LogMonitoringModel)}
      />

      <S.CardMonitoring
        key={logMonitoring?.id}
        status={status}
        className={`${logMonitoring?.onAnimate ? 'animate__animated animate__pulse' : ''}`}
        onAnimationEnd={onAnimatedEnd}
      >
        <S.CardMonitoringHeader>
          <Row gutter={6}>
            <Col span={3} style={{ fontSize: '1rem', textAlign: 'start' }}>
              <div>{`${priority}º`}</div>
            </Col>
            <Col span={19}>
              <Row justify="space-between">
                <Col style={{ fontSize: '1rem' }} span={12}>{`${
                  logMonitoring?.obraNome ?? ''
                } - ${logMonitoring?.codigoProgramacao}`}</Col>
                <Col span={6} style={{ fontSize: '1rem', textAlign: 'end' }}>
                  {renderBomba()}
                </Col>
              </Row>
            </Col>
            <Col span={2}>
              {(logMonitoring?.obraId ?? 0) > 0 && (
                <DropdownTable
                  buttonText=""
                  iconD={
                    <div style={{ color: 'var(--text-label)' }}>
                      <IconMore />
                    </div>
                  }
                  trigger={['click']}
                  placement="bottomRight"
                  overlay={
                    <Menu>
                      <MenuItem
                        onClick={() => {
                          setModalInfoTripVisible(false);
                          setModalMapTripVisible(true);
                        }}
                      >
                        <Button type="text" style={{ fontWeight: 'normal' }}>
                          Mapa das viagens
                        </Button>
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          setModalMapTripVisible(false);
                          setModalInfoTripVisible(true);
                        }}
                      >
                        <Button type="text" style={{ fontWeight: 'normal' }}>
                          Informações das viagens
                        </Button>
                      </MenuItem>
                    </Menu>
                  }
                />
              )}
            </Col>
          </Row>
        </S.CardMonitoringHeader>
        <S.CardMonitoringSubHeader>
          <Row gutter={6}>
            <Col span={3}></Col>
            <Col span={19}>
              <Row justify="space-between">
                <Col span={16} style={alignStart}>
                  <Row gutter={2}>
                    <Space size="small">
                      <span>{`${logMonitoring?.contrato ?? ''} | ${logMonitoring?.peca ?? ''} `}</span>{' '}
                      <Tooltip title={logMonitoring?.traco ?? ''}>
                        <div
                          style={{
                            fontSize: '0.75rem',
                            marginLeft: '-0.438rem',
                            marginTop: '0.125rem',
                            width: '0.75rem',
                            cursor: 'pointer',
                          }}
                          onClick={async () => {
                            await navigator.clipboard.writeText(logMonitoring?.traco ?? '');
                          }}
                        >
                          <IconInfoCircle />
                        </div>
                      </Tooltip>
                    </Space>
                  </Row>
                </Col>
                <Col span={8} style={{ ...alignEnd }}>
                  <span>{logMonitoring?.obraStatus !== 0 ? logMonitoring?.obraStatusDescricao ?? '' : ''}</span>
                </Col>
              </Row>
            </Col>
            <Col span={1}></Col>
          </Row>
        </S.CardMonitoringSubHeader>
        <S.CardMonitoringContent size={size ?? 'small'}>{IconsContent}</S.CardMonitoringContent>
        <S.CardMonitoringFooter>
          <Row>
            <Progress percent={logMonitoring?.obraProgresso ?? 0} showInfo={false} strokeColor="#159D3B" />
          </Row>
          <Row>
            <Col style={alignStart} span={8}>
              {moment(logMonitoring?.dataInicio).format('HH:mm')}
            </Col>
            <Col style={alignCenter} span={8}>
              <Popover
                show={logMonitoring?.volumeSuspenso !== undefined && logMonitoring?.volumeSuspenso > 0}
                title={'Volume suspenso'}
                content={`${logMonitoring?.volumeSuspenso ?? 0} M³`}
              >
                {`${logMonitoring?.qtdEntregue ?? 0}/`}
                <span>{`${logMonitoring?.qtdSolicitada ?? 0}M³`}</span>
              </Popover>
            </Col>
            <Col style={alignEnd} span={8}>
              {moment(logMonitoring?.dataFimPrevisao).format('HH:mm')}
            </Col>
          </Row>
        </S.CardMonitoringFooter>
      </S.CardMonitoring>
    </>
  );
};
