import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import * as Auth from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import { ReactComponent as Logo } from '@app/assets/images/Logo-Azul-Vertical.svg';
import { Input } from '@app/components/common/inputs/Input/Input';
import { Form, Space } from 'antd';
import IAuthService, { AuthService } from '@app/services/authService';
import { notificationController } from '@app/controllers/notificationController';

interface ForgotPasswordFormData {
  email: string;
}

const authService: IAuthService = new AuthService();

export const ForgotPasswordForm: React.FC = () => {
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);

  const handleSubmit = (values: ForgotPasswordFormData) => {
    setLoading(true);
    authService
      .requestNewPassword(values.email)
      .then(() => {
        navigate('/auth/forgot-password-success');
      })
      .catch((err: Error) => {
        setLoading(false);
        notificationController.error({ message: err.message });
      });
  };

  return (
    <Auth.FormWrapper>
      <Logo style={{ marginBottom: '2rem' }} />
      <BaseForm layout="vertical" onFinish={handleSubmit} requiredMark="optional" initialValues={{ remember: true }}>
        <Auth.FormTitle>Esqueceu a senha?</Auth.FormTitle>
        <Auth.FormDescription>
          Informe seu e-mail cadastrado no SlumpMix para enviarmos as instruções para trocar sua senha
        </Auth.FormDescription>
        <Form.Item label="E-mail" name="email" rules={[{ required: true, message: 'Campo obrigatório' }]}>
          <Input placeholder="Digite seu e-mail" />
        </Form.Item>
        <BaseForm.Item noStyle>
          <Space direction="vertical" style={{ width: '100%', marginTop: '1rem' }}>
            <Auth.SubmitButton type="primary" htmlType="submit" loading={isLoading}>
              Enviar
            </Auth.SubmitButton>
          </Space>
        </BaseForm.Item>
      </BaseForm>
    </Auth.FormWrapper>
  );
};
