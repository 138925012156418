import { Col } from 'antd';
import styled from 'styled-components';

export const CardDeviceInformationContainer = styled.div`
  box-sizing: border-box;
  width: 100%;
  min-height: 11.125rem;
  border: 0.063rem solid var(--disabled-bg-color);
  border-radius: 0.5rem;
  flex: none;
  order: 0;
  flex-grow: 0;
  padding: 1.5rem;
`;

export const CardDeviceInformationTitle = styled.div`
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 120%;
  color: var(--text-label);
`;

export const CardDeviceInformationProperty = styled.div`
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.563rem;
  color: var(--input-placeholder-color);
`;

export const CardDeviceInformationValue = styled.div`
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.563rem;
  color: var(--text-label);
  margin-top: -0.5rem;
`;

export const CardDeviceInformationCol = styled(Col)``;
