import { TableDataType } from '@app/constants/enums/tableDataType';
import { IHeader } from '@app/constants/interfaces/Header/IHeader';
import { AlertModel } from '@app/domain/interfaces/alert/alertModel';
import { ClientModel } from '@app/domain/interfaces/clientModel';
import { ClientBranchModel } from '@app/domain/interfaces/client_branch/clientBranchModel';
import { GroupModel } from '@app/domain/interfaces/client_branch/groupModel';
import { ConcreteMixerModel } from '@app/domain/interfaces/concreteMixerModel';
import { ConfigAlertModel } from '@app/domain/interfaces/configAlertModel';
import { DeviceModel } from '@app/domain/interfaces/device/deviceModel';
import { ProfileModel } from '@app/domain/interfaces/profile/profileModel';
import { createAction, createSlice, PrepareAction } from '@reduxjs/toolkit';
import { ReactNode } from 'react';

interface Filter {
  tableDataFiltered:
    | ClientModel[]
    | ConcreteMixerModel[]
    | ConfigAlertModel[]
    | AlertModel[]
    | DeviceModel[]
    | ClientBranchModel[]
    | GroupModel[]
    | ProfileModel[]
    | [];
}

export interface HeaderSlice {
  loadingState: boolean;
  icon: ReactNode;
  title: string;
  buttonText: string;
  handleButtonClick: (event: React.MouseEvent<HTMLElement>) => void;
  tableDataFiltered:
    | ClientModel[]
    | ConcreteMixerModel[]
    | ConfigAlertModel[]
    | AlertModel[]
    | DeviceModel[]
    | ClientBranchModel[]
    | GroupModel[]
    | Client[]
    | ProfileModel[]
    | [];
  tableData:
    | ClientModel[]
    | ConcreteMixerModel[]
    | ConfigAlertModel[]
    | AlertModel[]
    | DeviceModel[]
    | ClientBranchModel[]
    | GroupModel[]
    | ProfileModel[]
    | [];
  tableDataType: TableDataType;
  alertTextPredicate: string | null;
  alertFilter: number | null;
  drawerVisible: boolean;
}

const initialState: HeaderSlice = {
  loadingState: false,
  icon: '',
  title: '',
  buttonText: '',
  handleButtonClick: (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
  },
  tableDataFiltered: [],
  tableData: [],
  tableDataType: TableDataType.None,
  alertTextPredicate: null,
  alertFilter: null,
  drawerVisible: false,
};

export const setDrawerVisible = createAction<PrepareAction<boolean>>('header/setDrawerVisible', (visible: boolean) => {
  return {
    payload: visible,
  };
});

export const setHeader = createAction<PrepareAction<IHeader>>('header/setHeader', (newHeader: IHeader) => {
  return {
    payload: newHeader,
  };
});

export const setFilter = createAction<PrepareAction<Filter>>('header/setFilter', (newTableFiltered: Filter) => {
  return {
    payload: newTableFiltered,
  };
});

export const setAlertFilter = createAction<PrepareAction<number>>('header/setAlertFilter', (value: number) => {
  return {
    payload: value,
  };
});

export const setAlertTextPredicate = createAction<PrepareAction<string>>(
  'header/setAlertTextPredicate',
  (newAlertTextPredicate: string) => {
    return {
      payload: newAlertTextPredicate,
    };
  },
);

export const headerSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setHeader, (state, action) => {
      state.loadingState = true;
      state.title = action.payload.title;
      state.icon = action.payload.icon;
      state.buttonText = action.payload.buttonText;
      state.handleButtonClick = action.payload.handleButtonClick;
      state.tableData = action.payload.tableData;
      state.tableDataType = action.payload.tableDataType;
      state.tableDataFiltered = state.tableData;
      state.loadingState = false;
    });

    builder.addCase(setFilter, (state, action) => {
      state.tableDataFiltered = action.payload.tableDataFiltered;
    });

    builder.addCase(setAlertTextPredicate, (state, action) => {
      state.alertTextPredicate = action.payload;
    });

    builder.addCase(setAlertFilter, (state, action) => {
      state.alertFilter = action.payload;
    });

    builder.addCase(setDrawerVisible, (state, action) => {
      state.drawerVisible = action.payload;
    });
  },
});

export default headerSlice.reducer;
