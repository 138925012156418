import { Col, Row } from 'antd';
import Clock from '@app/components/common/Clock/Clock';
import * as S from './HeaderConcreteMixerLocation.styles';
import { useNavigate } from 'react-router-dom';
import { IconMonitoring } from '@app/assets/slump-icons';
import { ClockCircleOutlined } from '@ant-design/icons';
import React, { useCallback, useEffect, useState } from 'react';
import IConcreteMixerService, { ConcreteMixerService } from '@app/services/concreteMixerService';
import { ConcreteMixerModel } from '@app/domain/interfaces/concreteMixerModel';
import { notificationController } from '@app/controllers/notificationController';
import { Select } from '@app/components/common/selects/Select/Select';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { useDispatch } from 'react-redux';
import { setMixerMonitoring } from '@app/store/slices/monitoringSlice';
import { DayjsDatePicker } from '@app/components/common/pickers/DayjsDatePicker';
import ITravelInfoService, { TravelInfoService } from '@app/services/travelInfoService';

import { Dayjs } from 'dayjs';
import {
  setConcreteMixerLocations,
  setConcreteMixerLocationsLoading,
  setDeviceIdTravelInfo,
} from '@app/store/slices/concreteMixerLocationsSlice';
import { TravelInfoModel } from '@app/domain/interfaces/concrete_mixer_location/travelInfoModel';

type RangeValue = [Dayjs | null, Dayjs | null] | null;

interface HeaderMonitoringProps {}

const mixerService: IConcreteMixerService = new ConcreteMixerService();
const travelInfoService: ITravelInfoService = new TravelInfoService();

const HeaderConcreteMixerLocation: React.FC<HeaderMonitoringProps> = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [mixers, setMixers] = useState<ConcreteMixerModel[]>([]);
  const mixer = useAppSelector((state) => state.monitoring.mixer);
  const selectedDeviceId = useAppSelector((state) => state.concreteMixerLocations.deviceId);
  const [rangeValue, setRangeValue] = useState<RangeValue>([null, null]);

  const fetchMixers = useCallback(() => {
    mixerService
      .getArray('obter-todos-com-device')
      .then((res) => setMixers(res.filter((r) => r.deviceId)))
      .catch(() => notificationController.error({ message: 'Erro ao buscar a lista de betoneiras.' }));
  }, []);

  useEffect(() => {
    fetchMixers();
  }, [fetchMixers]);

  const fetchTravelInfo = useCallback(
    (deviceId: string, dates: RangeValue) => {
      if (!dates) return false;

      const initialDate = dates[0]?.toISOString();
      const finalDate = dates[1]?.toISOString();

      if (!initialDate || !finalDate) return false;
      if (!deviceId) return notificationController.error({ message: 'Selecione uma betoneira.' });

      dispatch(setConcreteMixerLocationsLoading(true));

      const params = {
        deviceId: deviceId ?? '',
        dataHoraInicio: initialDate ?? '',
        dataHoraFim: finalDate ?? '',
      };

      travelInfoService
        .post('obter', params as unknown as TravelInfoModel)
        .then((res) => {
          dispatch(setDeviceIdTravelInfo(deviceId ?? ''));
          dispatch(setConcreteMixerLocations(res));
        })
        .catch(() => notificationController.error({ message: 'Erro ao buscar informações de localização.' }))
        .finally(() => dispatch(setConcreteMixerLocationsLoading(false)));
    },
    [dispatch],
  );

  return (
    <>
      <S.HeaderRow justify="space-between" align="middle">
        <Col xs={20} md={20}>
          {/*Localizar Betoneiras*/}
          <S.HeaderSubRow>
            <Select
              showSearch
              placeholder="Pesquise o n° da BT ou placa do caminhão"
              value={mixer?.id}
              onChange={(value) => {
                const mixer = mixers.find((b) => b.id === Number(value));
                dispatch(setDeviceIdTravelInfo(mixer?.deviceId ?? ''));
                dispatch(setMixerMonitoring(mixers.find((b) => b.id === Number(value))));
                fetchTravelInfo(mixer?.deviceId ?? '', rangeValue);
              }}
              options={mixers.map((c) => ({
                value: c.id,
                label: `${c.numeroBT} | ${c.placaCaminhao}`,
              }))}
              filterOption={(input, option) => (option?.label ?? '').toLowerCase().indexOf(input.toLowerCase()) >= 0}
              style={{ width: '370px' }}
            />
            <DayjsDatePicker.RangePicker
              showTime
              value={rangeValue}
              format={'DD/MM/YYYY HH:mm'}
              style={{ width: '370px' }}
              onOk={(value) => {
                setRangeValue(value);
                fetchTravelInfo(selectedDeviceId, value);
              }}
            />
          </S.HeaderSubRow>
        </Col>
        <Col xs={4} md={4}>
          <Row justify={'end'} gutter={10}>
            <Col>
              <S.HeaderSubRow align={'middle'}>
                <ClockCircleOutlined />
              </S.HeaderSubRow>
            </Col>
            <Col>
              <S.HeaderSubRow align={'middle'}>
                <Clock />
              </S.HeaderSubRow>
            </Col>
            <Col>
              <S.HeaderDivider />
            </Col>
            <Col>
              <S.HeaderSubRow align={'middle'}>
                <S.HeaderButton onClick={() => navigate('/monitoramento')} style={{ marginTop: '0.5rem' }}>
                  <IconMonitoring />
                </S.HeaderButton>
              </S.HeaderSubRow>
            </Col>
          </Row>
        </Col>
      </S.HeaderRow>
      <Row style={{ borderBottom: '1px solid var(--disabled-bg-color)', width: '100%' }}></Row>
    </>
  );
};

export default HeaderConcreteMixerLocation;
