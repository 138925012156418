import * as React from 'react';
const SvgIconFalta = () => (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.5 2H15.5V5.5L12.66 8.34L13.91 9.59L17.5 6.01L17.49 6H17.5V0H5.5V1.17L7.5 3.17V2Z" fill="#C41B24" />
    <path
      d="M1.59994 0.0996094L0.189941 1.50961L9.08994 10.4096L5.49994 13.9996L5.50994 14.0096H5.49994V19.9996H17.4999V18.8296L19.9899 21.3196L21.3999 19.9096L1.59994 0.0996094ZM15.4999 17.9996H7.49994V14.4996L10.3399 11.6596L15.4999 16.8296V17.9996Z"
      fill="#C41B24"
    />
  </svg>
);
export default SvgIconFalta;
