import React, { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { Modal } from '@app/components/common/Modal/Modal';
import { ContainerChart } from '@app/pages/modules/Reports/components/ReportComponent.styles';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { Description } from '@app/components/common/Description/Description';
import { Col, Row, Select } from 'antd';
import { BaseFormInputItem } from '@app/components/common/forms/components/BaseFormInputItem/BaseFormInputItem';
import { HeaderLogoName } from '@app/pages/modules/Reports/components/HeaderPDF/components/HeaderLogoName/HeaderLogoName';
import { ChartTravelledDistance } from '@app/pages/modules/Reports/Charts/ChartTravelledDistance';
import { ChartFuelConsumption } from '@app/pages/modules/Reports/Charts/ChartFuelConsumption';
import { TravelInfoDetailsModel } from '@app/domain/interfaces/report/TravelInfoDetailsReport';
import { ChartMixerSpeed } from '@app/pages/modules/Reports/Charts/ChartMixerSpeed';
import { ChartEngineRotation } from '@app/pages/modules/Reports/Charts/ChartEngineRotation';
import { ChartOilPressure } from '@app/pages/modules/Reports/Charts/ChartOilPressure';
import { ChartBalloonRotationSpeed } from '@app/pages/modules/Reports/Charts/ChartBalloonRotationSpeed';
import { ChartBalloonRotationDirection } from '@app/pages/modules/Reports/Charts/ChartBalloonRotationDirection';
import { ChartTemperature } from '@app/pages/modules/Reports/Charts/ChartTemperature';
import { ChartWater } from '@app/pages/modules/Reports/Charts/ChartWater';
import { ChartSlump } from '@app/pages/modules/Reports/Charts/ChartSlump';
import { Collapse } from '@app/components/common/Collapse/Collapse';
import { Tag } from '@app/components/common/Tag/Tag';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { Spinner } from '@app/components/common/Spinner/Spinner';

interface IModalTripGraphicProps {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  travelDetails: TravelInfoDetailsModel;
}

const charts = [
  { id: 1, label: 'Slump' },
  { id: 2, label: 'Água utilizada' },
  { id: 3, label: 'Temperatura do concreto e ambiente' },
  { id: 4, label: 'Sentido de rotação do balão' },
  { id: 5, label: 'Rotação do balão' },
  { id: 6, label: 'Pressão de óleo' },
  { id: 7, label: 'Rotação do motor' },
  { id: 8, label: 'Velocidade do caminhão' },
  { id: 9, label: 'Consumo de combustível' },
  { id: 10, label: 'Distância percorrida' },
];

const ModalTripGraphic: React.FC<IModalTripGraphicProps> = ({ visible, setVisible, travelDetails }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedCharts, setSelectedCharts] = useState<number[]>(charts.map((chart) => chart.id));
  const [activeKeys, setActiveKeys] = useState<string[]>([]);

  const handleChartSelection = (selected: number[]) => {
    setSelectedCharts(selected);
  };

  const waitForStableDOM = (element: Element) => {
    return new Promise((resolve) => {
      const observer = new MutationObserver((mutations, obs) => {
        obs.disconnect();
        resolve(element);
      });
      observer.observe(element, {
        attributes: true,
        childList: true,
        subtree: true,
        characterData: true,
      });
      // Also add a timeout to resolve observer if no changes occur
      setTimeout(() => {
        observer.disconnect();
        resolve(element);
      }, 1000); // Adjust timeout as needed
    });
  };

  const switchChartComponent = (chartId: number) => {
    switch (chartId) {
      case 1:
        return {
          title: 'Slump',
          subtitle: 'Análise da coleta do slump',
          chartComponent: <ChartSlump key={1} travelDetails={travelDetails} />,
        };
      case 2:
        return {
          title: 'Água utilizada',
          subtitle: 'Análise do uso de água',
          chartComponent: <ChartWater key={2} travelDetails={travelDetails} />,
        };
      case 3:
        return {
          title: 'Temperatura do concreto e ambiente',
          subtitle: 'Análise da temperatura do concreto e temperatura ambiente',
          chartComponent: <ChartTemperature key={3} travelDetails={travelDetails} />,
        };
      case 4:
        return {
          title: 'Sentido de rotação do balão',
          subtitle: 'Análise do sentido da rotação do balão carga e descarga',
          chartComponent: <ChartBalloonRotationDirection key={4} travelDetails={travelDetails} />,
        };
      case 5:
        return {
          title: 'Rotação do balão',
          subtitle: 'Análise do sentido da rotação do balão',
          chartComponent: <ChartBalloonRotationSpeed key={5} travelDetails={travelDetails} />,
        };
      case 6:
        return {
          title: 'Pressão de óleo',
          subtitle: 'Análise da pressão do óleo na carga e descarga',
          chartComponent: <ChartOilPressure key={6} travelDetails={travelDetails} />,
        };
      case 7:
        return {
          title: 'Rotação do motor',
          subtitle: 'Análise da rotação do motor',
          chartComponent: <ChartEngineRotation key={7} travelDetails={travelDetails} />,
        };
      case 8:
        return {
          title: 'Velocidade do caminhão',
          subtitle: 'Análise da velocidade do caminhão',
          chartComponent: <ChartMixerSpeed key={8} travelDetails={travelDetails} />,
        };
      case 9:
        return {
          title: 'Consumo de combustível',
          subtitle: 'Análise do consumo de combustível',
          chartComponent: <ChartFuelConsumption key={9} travelDetails={travelDetails} />,
        };
      case 10:
        return {
          title: 'Distância percorrida',
          subtitle: 'Análise da distância percorrida',
          chartComponent: <ChartTravelledDistance key={10} travelDetails={travelDetails} />,
        };
      default:
        return null;
    }
  };

  const expandAll = useCallback(() => {
    setActiveKeys(selectedCharts.map((chart) => chart.toString())); // Assuming each Collapse is uniquely keyed by chartId
  }, [selectedCharts]);

  const renderSelectedCharts = () => {
    return selectedCharts.map((chartId) => {
      const isActive = activeKeys.includes(chartId.toString());
      return (
        <Collapse
          key={chartId}
          activeKey={isActive ? ['1'] : []}
          title={switchChartComponent(chartId)?.title}
          // subtitle={switchChartComponent(chartId)?.subtitle}
          onChange={() => {
            const newActiveKeys = isActive
              ? activeKeys.filter((key) => key !== chartId.toString())
              : [...activeKeys, chartId.toString()];
            setActiveKeys(newActiveKeys);
          }}
        >
          <div key={chartId} className={'exportable_chart'}>
            <div className={'exportable_chart_title'} style={{ display: 'none' }}>
              <h2>{switchChartComponent(chartId)?.title}</h2>
            </div>
            {switchChartComponent(chartId)?.chartComponent}
          </div>
        </Collapse>
      );
    });
  };

  const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

  const groupChartsForPages = (charts: HTMLElement[]): HTMLElement[][] => {
    const pages: HTMLElement[][] = [];
    let currentPage: HTMLElement[] = [];

    charts.forEach((chart) => {
      if (pages.length === 0 && currentPage.length < 2) {
        // First page: up to 2 charts
        currentPage.push(chart);
      } else if (currentPage.length < 3) {
        // Other pages: up to 3 charts
        currentPage.push(chart);
      }

      if (currentPage.length === 2 && pages.length === 0) {
        // First page full
        pages.push(currentPage);
        currentPage = [];
      } else if (currentPage.length === 3 && pages.length > 0) {
        // Subsequent pages full
        pages.push(currentPage);
        currentPage = [];
      }
    });

    // Add remaining charts if any
    if (currentPage.length > 0) {
      pages.push(currentPage);
    }

    return pages;
  };

  // TODO: change to jsPDF.html() method or reduce to one html2canvas call
  const exportToPdf = useCallback(async (): Promise<void> => {
    setLoading(true);
    expandAll();
    await sleep(2000); // Garantir que todos os gráficos estejam expandidos e renderizados

    const exportableHeader = document.getElementById('exportable-header-alert-modal') as HTMLElement | null;
    const exportableHeaderInfo = document.getElementById('exportable-header-info-detail') as HTMLElement | null;
    const exportableDiv = document.getElementById('exportable-div-report-detail') as HTMLElement | null;
    const chartElements = document.getElementsByClassName('exportable_chart');
    const exportableChartTitles = document.getElementsByClassName('exportable_chart_title');

    const chartsArray = Array.from(chartElements) as HTMLElement[];
    const chartTitlesArray = Array.from(exportableChartTitles) as HTMLElement[];

    if (!exportableHeader || !exportableHeaderInfo || !exportableDiv) {
      console.error('Um ou mais elementos estão faltando');
      setLoading(false);
      return;
    }

    try {
      const fixedWidth = 1920;
      chartsArray.forEach((element) => {
        element.style.width = `${fixedWidth}px`;
        element.style.padding = '20px';
      });
      chartTitlesArray.forEach((element) => {
        element.style.display = 'block';
      });
      exportableHeader.style.display = 'flex';
      exportableHeaderInfo.style.width = `${fixedWidth}px`;
      exportableHeaderInfo.style.padding = '10px 20px';

      const pages = groupChartsForPages(chartsArray); // Organizar gráficos em páginas
      const pdf = new jsPDF({
        orientation: 'portrait',
        unit: 'pt',
        format: [595, 842],
      });

      await waitForStableDOM(document.body);

      let isFirstPage = true;
      let yOffset = 0; // Deslocamento Y para começar a adicionar gráficos

      for (const page of pages) {
        if (!isFirstPage) {
          pdf.addPage();
          yOffset = 0; // Resetar yOffset para nova página
        }

        // Sempre adicionar o cabeçalho em cada página
        const mainHeaderCanvas = await html2canvas(exportableHeader, { scale: 2 });
        const mainHeaderImgData = mainHeaderCanvas.toDataURL('image/jpeg', 1);
        const mainHeaderImgProps = pdf.getImageProperties(mainHeaderImgData);
        const pageWidth = pdf.internal.pageSize.getWidth();
        const mainHeaderHeight = (mainHeaderImgProps.height * pageWidth) / mainHeaderImgProps.width;
        pdf.addImage(mainHeaderImgData, 'PNG', 0, yOffset, pageWidth, mainHeaderHeight);

        yOffset += mainHeaderHeight;

        // Adicionar informações do cabeçalho na primeira página
        if (isFirstPage) {
          const headerInfoCanvas = await html2canvas(exportableHeaderInfo, { scale: 2 });
          const headerInfoImgData = headerInfoCanvas.toDataURL('image/jpeg', 1);
          const headerInfoImgProps = pdf.getImageProperties(headerInfoImgData);
          const headerInfoHeight = (headerInfoImgProps.height * pageWidth) / headerInfoImgProps.width;
          pdf.addImage(headerInfoImgData, 'PNG', 0, yOffset, pageWidth, headerInfoHeight);
          yOffset += headerInfoHeight;
        }

        // Processar cada gráfico para a página atual
        for (const chart of page) {
          const canvas = await html2canvas(chart, { scale: 2 });
          const imgData = canvas.toDataURL('image/jpeg', 1);
          const imgHeight = (canvas.height * pageWidth) / canvas.width;
          pdf.addImage(imgData, 'PNG', 0, yOffset, pageWidth, imgHeight);
          yOffset += imgHeight;
        }

        isFirstPage = false;
      }

      pdf.save('relatorio_detalhado_viagem.pdf');
    } catch (error) {
      console.error('Falha ao gerar o PDF:', error);
    } finally {
      // Resetar estilos específicos para cada elemento
      if (exportableDiv) {
        exportableDiv.style.width = '';
        exportableDiv.style.padding = '0';
      }
      if (exportableHeader) {
        exportableHeader.style.width = '';
        exportableHeader.style.display = 'none';
      }
      if (exportableHeaderInfo) {
        exportableHeaderInfo.style.width = '';
        exportableHeaderInfo.style.padding = '0';
      }

      chartsArray.forEach((element) => {
        element.style.width = '';
        element.style.padding = '0';
      });

      chartTitlesArray.forEach((element) => {
        element.style.display = 'none';
      });

      setLoading(false);
    }
  }, [expandAll]);

  return (
    <>
      <Modal
        open={visible}
        onCancel={() => setVisible(!visible)}
        size="medium"
        style={{ display: 'block', minWidth: '90vw', minHeight: '100%' }}
        footer={null}
      >
        <Spinner spinning={loading}>
          <ContainerChart>
            <BaseForm layout="vertical" style={{ width: '100%' }}>
              <Description title="Gráficos" subtitle="">
                <Row gutter={18}>
                  <Col xs={24} md={10}>
                    <BaseFormInputItem
                      label="Tipo de gráficos"
                      supportText="Escolha um ou mais tipos de gráficos para analisar."
                      style={{ marginBottom: '2rem' }}
                    >
                      <Select
                        showSearch
                        showArrow
                        value={selectedCharts}
                        mode="tags"
                        placeholder="Pesquise por tipo de gráfico "
                        style={{ width: '100%' }}
                        options={charts?.map((chart) => ({ value: chart.id, label: chart.label }))}
                        tagRender={(props) => (
                          <Tag key={props.value} closable onClose={props.onClose}>
                            {props.label}
                          </Tag>
                        )}
                        onChange={handleChartSelection}
                      />
                    </BaseFormInputItem>
                  </Col>
                </Row>
                <div id="exportable-div-report-detail">
                  <HeaderLogoName
                    id="exportable-header-alert-modal"
                    reportName={`Informações detalhadas da viagem`}
                    style={{ display: 'none' }}
                  />
                  {renderSelectedCharts()}
                </div>
              </Description>
            </BaseForm>
          </ContainerChart>
        </Spinner>
      </Modal>
    </>
  );
};

export default ModalTripGraphic;
