import { Dispatch, SetStateAction, useState } from 'react';
import { Modal } from '@app/components/common/Modal/Modal';
import { ColumnsType } from 'antd/lib/table';
import { ConcreteMixerReturning } from '@app/domain/interfaces/monitoring/footerMonitoringModels';
import { Table } from '@app/components/common/Table/Table';
import { IconMap } from '@app/assets/slump-icons';
import { ModalMapConcreteMixersLocation } from '@app/pages/modules/Monitoring/components/ModalMapConcreteMixersLocation';
import { setMixerMonitoring } from '@app/store/slices/monitoringSlice';
import { useDispatch } from 'react-redux';

interface IModalConcreteMixersReturning {
  modalVisible: boolean;
  setModalVisible: Dispatch<SetStateAction<boolean>>;
  concreteMixerReturning: ConcreteMixerReturning[];
}

const ModalConcreteMixersReturning: React.FC<IModalConcreteMixersReturning> = ({
  modalVisible,
  setModalVisible,
  concreteMixerReturning,
}) => {
  const [selectedConcreteMixer, setSelectedConcreteMixer] = useState<ConcreteMixerReturning>(
    {} as ConcreteMixerReturning,
  );
  const dispatch = useDispatch();
  const [modalMapTripVisible, setModalMapTripVisible] = useState<boolean>(false);

  const columns: ColumnsType<ConcreteMixerReturning> = [
    {
      title: 'BT',
      dataIndex: 'betoneira',
      showSorterTooltip: false,
      width: '3%',
      render: (betoneira) => <div style={{ whiteSpace: 'nowrap', wordBreak: 'keep-all' }}>{betoneira}</div>,
    },
    {
      title: '',
      dataIndex: 'betoneira',
      showSorterTooltip: false,
      width: '3%',
      render: (_, concreteMixer) => (
        <div
          style={{
            cursor: 'pointer',
          }}
          onClick={() => {
            setSelectedConcreteMixer(concreteMixer);
            setModalMapTripVisible((prevState) => !prevState);
            setModalVisible((prevState) => !prevState);
            dispatch(
              setMixerMonitoring({
                numeroBT: concreteMixer.betoneira,
                deviceId: concreteMixer.deviceId,
              }),
            );
          }}
        >
          <IconMap />
        </div>
      ),
    },
    {
      title: 'Motorista',
      dataIndex: 'motorista',
      showSorterTooltip: false,
      width: '10%',
    },
    {
      title: 'Tempo chegada na central',
      dataIndex: 'tempoRetorno',
      showSorterTooltip: false,
      width: '10%',
    },
  ];

  return (
    <>
      <Modal
        open={modalVisible}
        title="Betoneiras retornando"
        cancelText=""
        okText=""
        width={'80%'}
        onCancel={() => setModalVisible(!modalVisible)}
        footer={null}
        centered
      >
        <Table columns={columns} dataSource={concreteMixerReturning} bordered />
      </Modal>

      <ModalMapConcreteMixersLocation
        mixerSelected={{ ...selectedConcreteMixer }}
        open={modalMapTripVisible}
        onCancel={() => {
          setModalMapTripVisible((prevState) => !prevState);
          setModalVisible((prevState) => !prevState);
        }}
        onOk={() => {
          setModalMapTripVisible((prevState) => !prevState);
          setModalVisible((prevState) => !prevState);
        }}
      />
    </>
  );
};

export default ModalConcreteMixersReturning;
