import appSettings from '@app/config/appsettings';
import { ClientBranchModel } from '@app/domain/interfaces/client_branch/clientBranchModel';
import { LocalizationModel } from '@app/domain/interfaces/report/reportConcreteMixerModel';
import { Circle, GoogleMap, GoogleMapProps, Marker, useJsApiLoader } from '@react-google-maps/api';
import { useCallback, useEffect, useRef, useState } from 'react';
import { GoogleMapStyled } from '@app/components/common/Map/styles';

const supermixLocation = {
  lat: -19.878877423869252,
  lng: -43.97931295928024,
};

interface MapBranchLocationProps {
  branch: ClientBranchModel;
  setBranch: (value: ClientBranchModel) => void;
}

const MapBranchLocation: React.FC<MapBranchLocationProps> = ({ branch, setBranch }) => {
  const mapRef = useRef<google.maps.Map | null>(null);
  const controlsRef = useRef<HTMLDivElement | null>(null);
  const [centerMap, setCenterMap] = useState<LocalizationModel>();
  const { isLoaded } = useJsApiLoader({
    id: 'mixer-dashboard-map',
    googleMapsApiKey: appSettings().MAPS_API_KEY,
    libraries: ['places', 'geometry'],
  });

  const onMapClick = (e: google.maps.MapMouseEvent) => onCircleClick(e);
  const onCircleClick = (e: google.maps.MapMouseEvent) => {
    if (e.domEvent instanceof MouseEvent && !e.domEvent.ctrlKey && e.latLng && mapRef.current) {
      setBranch({ ...branch, latitude: e.latLng!.lat(), longitude: e.latLng!.lng() });
    }
  };

  const onMapLoad: GoogleMapProps['onLoad'] = useCallback((map: google.maps.Map) => {
    mapRef.current = map;

    map.setOptions({
      gestureHandling: 'cooperative',
      streetViewControl: false,
      mapTypeControl: false,
      fullscreenControl: true,
      zoom: 14,
      fullscreenControlOptions: {
        position: google.maps.ControlPosition.RIGHT_BOTTOM,
      },
      zoomControl: true,
      zoomControlOptions: {
        position: google.maps.ControlPosition.RIGHT_TOP,
      },
    });

    if (map && controlsRef.current) {
      map.controls[google.maps.ControlPosition.RIGHT_TOP].push(controlsRef.current);
    }
  }, []);

  useEffect(() => {
    setCenterMap({
      lat: branch.latitude ?? supermixLocation.lat,
      lng: branch.longitude ?? supermixLocation.lng,
      raio: branch.raio ? Number(branch.raio) : 0,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isLoaded && (
        <GoogleMap mapContainerStyle={GoogleMapStyled} onLoad={onMapLoad} center={centerMap} onClick={onMapClick}>
          {branch.latitude && branch.longitude && (
            <>
              <Marker
                position={{ lat: branch.latitude, lng: branch.longitude }}
                icon={{
                  path: 'M 0, 0 m -5, 0 a 5,5 0 1,0 10,0 a 5,5 0 1,0 -10,0', // Cria um círculo
                  fillColor: '#263668',
                  fillOpacity: 1.0,
                  scale: 1,
                  strokeColor: '#263668',
                  strokeWeight: 1,
                }}
              />
              <Circle
                center={{ lat: branch.latitude, lng: branch.longitude }}
                radius={branch.raio ? Number(branch.raio) : 0}
                onClick={onCircleClick}
                options={{
                  fillColor: '#263668',
                  fillOpacity: 0.5,
                  strokeColor: '#263668',
                  strokeOpacity: 1,
                  strokeWeight: 2,
                }}
              />
            </>
          )}
        </GoogleMap>
      )}
    </>
  );
};

export default MapBranchLocation;
