import { FONT_SIZE } from '@app/styles/themes/constants';
import { DateTime } from '@app/constants/interfaces/interfaces';
import { Button as AntButton } from 'antd';
import styled, { css } from 'styled-components';
import { isUndefined } from 'lodash';

interface ButtonTabProps {
  dateTime?: DateTime;
  timeLine?: boolean;
}

export const ButtonTabReports = styled(AntButton)<ButtonTabProps>`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  padding: 1rem 1.5rem;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  margin-right: 1rem;
  text-align: center;

  border-radius: 40px;
  &[disabled],
  &[disabled]:active,
  &[disabled]:focus,
  &[disabled]:hover {
    color: var(--text-label);
    border-color: var(--secondary-color);
    background-color: var(--background-main-color);
    opacity: 0.4;
  }

  ${({ dateTime }) => {
    if (!isUndefined(dateTime)) {
      switch (dateTime) {
        case 'day':
          return css`
            background-color: var(--secondary-color);
            border-color: var(--secondary-color);
            color: var(--background-main-color);

            &:hover {
              background-color: var(--secondary-color);
              border-color: var(--secondary-color);
              color: var(--background-main-color);
            }
          `;
        case 'week':
          return css`
            background-color: var(--secondary-color);
            border-color: var(--secondary-color);
            color: var(--background-main-color);

            &:hover {
              background-color: var(--secondary-color);
              border-color: var(--secondary-color);
              color: var(--background-main-color);
            }
          `;
        case 'month':
          return css`
            background-color: var(--secondary-color);
            border-color: var(--secondary-color);
            color: var(--background-main-color);

            &:hover {
              background-color: var(--secondary-color);
              border-color: var(--secondary-color);
              color: var(--background-main-color);
            }
          `;
        case 'grouped':
          return css`
            background-color: var(--secondary-color);
            border-color: var(--secondary-color);
            color: var(--background-main-color);

            &:hover {
              background-color: var(--secondary-color);
              border-color: var(--secondary-color);
              color: var(--background-main-color);
            }
          `;
        case 'individual':
          return css`
            background-color: var(--secondary-color);
            border-color: var(--secondary-color);
            color: var(--background-main-color);
            &:hover {
              background-color: var(--secondary-color);
              border-color: var(--secondary-color);
              color: var(--background-main-color);
            }
          `;
      }
    } else {
      return css`
        color: var(--text-label);
        border-color: var(--secondary-color);
        background: var(--background-main-color);
        &:hover {
          background: var(--button-tab-color);
          border-color: var(--secondary-color);
          color: var(--text-label);
        }
      `;
    }
  }}
`;

export const Title = styled.span`
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 600;
  font-size: ${FONT_SIZE.md};
  line-height: 1.125rem;
`;
