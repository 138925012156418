import { References } from '@app/components/common/References/References';
import DrawerChangeVision from '@app/components/drawers/profile/DrawerChangeVision';
import { useResponsive } from '@app/hooks/useResponsive';
import React, { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { Header } from '../../../header/Header';
import MainContent from '../MainContent/MainContent';
import { MainHeader } from '../MainHeader/MainHeader';
import MainSider from '../sider/MainSider/MainSider';
import * as S from './MainLayout.styles';

const MainLayout: React.FC = () => {
  const [isTwoColumnsLayout, setIsTwoColumnsLayout] = useState(true);
  const [siderCollapsed, setSiderCollapsed] = useState(true);
  const { isDesktop } = useResponsive();
  const location = useLocation();

  const toggleSider = () => {
    setSiderCollapsed(!siderCollapsed);
  };

  useEffect(() => {
    setIsTwoColumnsLayout(!isDesktop);
  }, [location.pathname, isDesktop]);

  return (
    <S.LayoutMaster>
      <MainSider hidden={false} isCollapsed={siderCollapsed} setCollapsed={setSiderCollapsed} />
      <S.LayoutMain>
        <MainHeader isTwoColumnsLayout={isTwoColumnsLayout}>
          <Header toggleSider={toggleSider} isSiderOpened={!siderCollapsed} isTwoColumnsLayout={isTwoColumnsLayout} />
        </MainHeader>
        <MainContent id="main-content" $isTwoColumnsLayout={isTwoColumnsLayout}>
          <div>
            <Outlet />
            <DrawerChangeVision />
            {/* <DrawerSuccess
        text="Dispositivo vinculado com sucesso"
        title=""
        placement="right"
        visible={drawerSuccessVisible}
        onClose={() => setDrawerSuccessVisible(false)}
        style={{ overflowX: 'hidden' }}
      /> */}
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '78px',
              margin: '0px',
              padding: '0px',
              minHeight: '78px',
              maxHeight: '78px',
              background: '#FFF',
            }}
          >
            <References />
          </div>
        </MainContent>
      </S.LayoutMain>
    </S.LayoutMaster>
  );
};

export default MainLayout;
