import { FONT_WEIGHT, LAYOUT, media } from '@app/styles/themes/constants';
import { Col } from 'antd';
import styled, { css } from 'styled-components';

interface ProfileColumn {
  $isTwoColumnsLayout: boolean;
}

export const ProfileColumn = styled(Col)<ProfileColumn>`
  padding: ${LAYOUT.desktop.paddingVertical} ${LAYOUT.desktop.paddingHorizontal};

  @media only screen and (${media.md}) {
    ${(props) =>
      props?.$isTwoColumnsLayout &&
      css`
        background-color: var(--sider-background-color);
        padding: ${LAYOUT.desktop.paddingVertical} ${LAYOUT.desktop.paddingHorizontal};
      `}
  }
`;

export const HeaderTitle = styled.h1`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0.825rem;

  font-family: 'Mulish';
  font-size: 2rem !important;
  font-weight: ${FONT_WEIGHT.bold};
  color: #1c1c1c;

  @media only screen and (${media.md}) {
    font-family: 'Mulish';
    font-size: 1.5rem;
    flex-wrap: wrap;
    word-wrap: break-word;
  }
  @media only screen and (${media.xs}) {
    font-size: 1.4rem;
  }
  @media only screen and (${media.xxl}) {
    font-size: 2rem;
  }
`;

export const Icon = styled.div`
  padding: ${LAYOUT.desktop.paddingVertical} ${LAYOUT.desktop.paddingHorizontal};
  margin-left: 1rem;

  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
