import styled from 'styled-components';

export const SlumpHistoryChartCardContainer = styled.div`
  width: 41.188rem;
  height: 26.938rem;
  gap: 0rem;
  border-radius: 0.5rem 0rem 0rem 0rem;
  opacity: 0rem;
`;
export const SlumpHistoryChartCardHeader = styled.div`
  width: 100%;
  height: 5.125rem;
  top: -1px;
  padding: 1.5rem 0rem 0rem 0rem;
  gap: 0px;
  border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;
  border: 0rem 0rem 0.063rem 0rem;
  opacity: 0rem;
  font-family: Mulish;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.75rem;
  text-align: left;
  color: #383838;
  border-bottom: 1px solid #e8e8e8;
`;
export const SlumpHistoryChartCardContent = styled.div``;
