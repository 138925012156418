import { TagProps as AntTagProps } from 'antd';
import React from 'react';
import * as S from './TagJustification.styles';

export interface TagJProps extends AntTagProps {
  title?: string;
  key?: string;
}

export const TagJustification: React.FC<TagJProps> = ({ key, title, ...props }) => {
  return (
    <S.TagJ key={key}>
      <S.Title>{title}</S.Title>
      <S.Icon>{props.icon}</S.Icon>
    </S.TagJ>
  );
};
