import { ScheduleModel } from '@app/domain/interfaces/schedule/scheduleModel';
import { GroupedConcreteMixer, groupeConcreteMixers } from './View';
import * as FileSaver from 'file-saver';
import ExcelJS from 'exceljs';
import IScheduleService, { ScheduleService } from '@app/services/scheduleServices';
import moment from 'moment';

const scheduleService: IScheduleService = new ScheduleService();

const prepareScheduleDataForExport = (schedule: ScheduleModel) => {
  return {
    ID: schedule.id,
    Cliente: schedule.cliente,
    Status: schedule.status,
    UsuarioResponsavel: schedule.usuarioResponsavel,
    DataHora: moment(schedule.dataHora).format('DD/MM/YYYY HH:mm'),
  };
};

const prepareBetoneirasDataForExport = (normalizedBetoneiras: GroupedConcreteMixer[], canceled: boolean) => {
  return normalizedBetoneiras.map((concreteMixer: GroupedConcreteMixer) => {
    if (canceled) {
      return {
        Betoneira: concreteMixer.betoneira,
        Central: concreteMixer.CAN === undefined ? '-' : 'Cancelado',
        Hermes: concreteMixer.Hermes === undefined ? '-' : 'Cancelado',
        H2: concreteMixer.H2 === undefined ? '-' : 'Cancelado',
        CAN: concreteMixer.CAN === undefined ? '-' : 'Cancelado',
      };
    }

    return {
      Betoneira: concreteMixer.betoneira,
      Central: concreteMixer.Central === undefined ? '-' : concreteMixer.Central ? 'Atualizado' : 'Pendente',
      Hermes: concreteMixer.Hermes === undefined ? '-' : concreteMixer.Hermes ? 'Atualizado' : 'Pendente',
      H2: concreteMixer.H2 === undefined ? '-' : concreteMixer.H2 ? 'Atualizado' : 'Pendente',
      CAN: concreteMixer.CAN === undefined ? '-' : concreteMixer.CAN ? 'Atualizado' : 'Pendente',
    };
  });
};

const getMaxColumnWidth = (data: Array<{ [key: string]: unknown }>, key: string, header: string) => {
  let maxLength = header.length;
  data.forEach((row) => {
    const value = row[key];
    const length = value ? value.toString().length : 0;
    if (length > maxLength) maxLength = length;
  });
  return maxLength + 2;
};

const ExportToExcel = async ({
  scheduleData,
  betoneirasData,
  fileName = 'export',
}: {
  scheduleData: { [key: string]: unknown };
  betoneirasData: { [key: string]: unknown }[];
  fileName?: string;
}) => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('data');

  // Add schedule headers
  worksheet.addRow([
    'ID',
    'Cliente',
    'Status',
    'Usuario Responsavel',
    'Data/Hora',
    'Betoneira',
    'Central',
    'Hermes',
    'H2',
    'CAN',
  ]);

  // Add schedule data
  worksheet.addRow([
    scheduleData.ID,
    scheduleData.Cliente,
    scheduleData.Status,
    scheduleData.UsuarioResponsavel,
    scheduleData.DataHora,
  ]);

  // Add betoneiras data
  betoneirasData.forEach((data) => {
    worksheet.addRow(['', '', '', '', '', data.Betoneira, data.Central, data.Hermes, data.H2, data.CAN]);
  });

  // Set column widths
  worksheet.columns = [
    { key: 'ID', width: 10 },
    { key: 'Cliente', width: getMaxColumnWidth([scheduleData], 'Cliente', 'Cliente') },
    { key: 'Status', width: getMaxColumnWidth([scheduleData], 'Status', 'Status') },
    {
      key: 'UsuarioResponsavel',
      width: getMaxColumnWidth([scheduleData], 'UsuarioResponsavel', 'Usuario Responsavel'),
    },
    { key: 'DataHora', width: getMaxColumnWidth([scheduleData], 'DataHora', 'Data/Hora') },
    { key: 'Betoneira', width: getMaxColumnWidth(betoneirasData, 'Betoneira', 'Betoneira') },
    { key: 'Central', width: getMaxColumnWidth(betoneirasData, 'Central', 'Central') },
    { key: 'Hermes', width: getMaxColumnWidth(betoneirasData, 'Hermes', 'Hermes') },
    { key: 'H2', width: getMaxColumnWidth(betoneirasData, 'H2', 'H2') },
    { key: 'CAN', width: getMaxColumnWidth(betoneirasData, 'CAN', 'CAN') },
  ];

  // Save to file
  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
  });
  FileSaver.saveAs(blob, `${fileName}.xlsx`);
};

export const handleExportClick = (schedule: ScheduleModel) => {
  scheduleService.get(`${schedule.id}`).then((res) => {
    const normalizedBetoneiras = groupeConcreteMixers(res.betoneiras);
    const scheduleData = prepareScheduleDataForExport(res);
    const betoneirasData = prepareBetoneirasDataForExport(normalizedBetoneiras, res.status === 'Cancelado');
    ExportToExcel({
      scheduleData: scheduleData,
      betoneirasData: betoneirasData,
      fileName: 'Agendamentos',
    });
  });
};
