import React from 'react';
import * as S from '../List.styles';

export interface ListItemsProps {
  key?: number;
  justification?: string;
}

export const ListItem: React.FC<ListItemsProps> = ({ key, justification }) => {
  return (
    <S.ListContainer key={key}>
      <S.Item>{justification}</S.Item>
    </S.ListContainer>
  );
};
