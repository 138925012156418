import { graphic } from 'echarts';
import { BASE_COLORS } from '../constants';
import { ITheme } from '../types';

const chartColors = {
  chartTooltipLabel: '#6a7985',
  chartColor1: '#339CFD',
  chartColor1Tint: '#339CFD', // update
  chartColor2: '#dc88f5',
  chartColor2Tint: '#dc88f5', // update
  chartColor3: '#FFB765',
  chartColor3Tint: '#FFB765', // update
  chartColor4: '#306955',
  chartColor4Tint: '#306955', // update
  chartColor5: '#ff3d71',
  chartColor5Tint: '#ff3d71', // update
  chartPrimaryGradient: new graphic.LinearGradient(0, 0, 0, 1, [
    {
      offset: 0,
      color: 'rgba(51, 156, 253, 0.35)',
    },
    {
      offset: 1,
      color: 'rgba(51, 156, 253, 0)',
    },
  ]),
  chartSecondaryGradient: new graphic.LinearGradient(0, 0, 0, 1, [
    {
      offset: 0,
      color: 'rgba(255, 82, 82, 0.35)',
    },
    {
      offset: 1,
      color: 'rgba(255, 82, 82, 0)',
    },
  ]),
  chartSecondaryGradientSpecular: new graphic.LinearGradient(0, 0, 0, 1, [
    {
      offset: 0,
      color: 'rgba(255, 255, 255, 0)',
    },
    {
      offset: 1,
      color: 'rgba(255, 82, 82, 0.5)',
    },
  ]),
};

export const darkColorsTheme: ITheme = {
  primary: '#121430',
  secondary: '#0072DD',
  backgroundMain: '#1e2142',
  backgroundCard: '#25284B',
  background: BASE_COLORS.white,
  headerMain: 'transparent',

  textMain: '#ffffff',
  textMainInfo: '#ffffff',
  textDark: '#404040',
  alertTextColor: '#000',

  textSiderPrimary: '#339CFD',
  textSiderSecondary: '#797C9A',
  layoutSiderBg: '#121430',

  borderCollapse: '#ffffff',
  collapseBackground: '#1D203E',
  textSecondary: '#ffffff',

  disablePlaceholder: '#ffffff',
  inputPlaceholder: '#ffffff',
  disabledInput: '#CCD9E3',
  backgroundDisabledInput: '#CCD9E3',
  borderInput: '#a9a9a9',
  inputError: '#FF5252',
  inputSuccess: '#57D682',
  inputWarning: '#FBBD43',

  buttonHover: '#1c2137',
  newAlertHover: '#FDDEA1',
  confirmedHover: '#8ED5A2',
  reportedHover: '#FBA7AB',

  newAlert: '#FBBD43',
  confirmed: '#3DB05D',
  reported: '#F74E57',
  notificationError: '#FFE2E2',
  notificationSuccess: '#EFFFF4',
  notificationWarning: '#FEF7E7',
  icon: '#a9a9a9',
  iconHover: '#ffffff',
  boxShadowHover: 'none',
  boxShadow: 'none',
  shadow: 'rgba(0, 0, 0, 0.07)',
  scroll: '#797C9A',
  spinnerBase: '#121430',
  additionalBackground: '#1D203E',
  timelineBackground: '#f5f5f5',
  heading: BASE_COLORS.white,
  borderBase: '#a9a9a9',
  tooltipBackground: '#E6ECF1',
  ...chartColors,
  backgroundColorBase: '',
  textLabel: '',
  textHeader: '',
  borderPagination: '',
  textPagination: '',
  buttonTabColor: '',
  footerColor: '',
  borderDrawer: '',
  avatarUser: '',
};

export const antDarkColorsTheme = {
  successBg: '#DFFBE7',
  successBorder: '#3DB05D',
};
