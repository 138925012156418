import * as React from 'react';
const SvgIconRight = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 36 36" width="1em" height="1em" role="img">
    <path
      fill="#383838"
      d="M28.193 16.988 10.081 2.842a.32.32 0 0 0-.518.253V6.2a.65.65 0 0 0 .245.507L24.272 18 9.808 29.295a.638.638 0 0 0-.246.506v3.106c0 .27.31.418.519.253l18.112-14.147a1.281 1.281 0 0 0 .364-1.575 1.283 1.283 0 0 0-.364-.45Z"
    />
  </svg>
);
export default SvgIconRight;
