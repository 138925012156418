import * as S from './FooterHelper.styles';
import { ReactComponent as HelperIcon } from '@app/assets/icons/help.svg';
import { ReactComponent as CloseIcon } from '@app/assets/icons/close.svg';
import { ReactComponent as FirstImage } from '@app/assets/icons/legenda_dashboard_follow_up.svg';
import { ReactComponent as SecondImage } from '@app/assets/icons/legenda_dashboard_follow_up_graph.svg';
import { useState } from 'react';

interface FooterDashboardFollowUpProps {}

export const FooterHelper: React.FC<FooterDashboardFollowUpProps> = ({}) => {
  const [helperVisible, setHelperVisible] = useState(false);

  return (
    <div>
      <>
        <HelperIcon
          style={{ cursor: 'pointer', position: 'absolute', right: '1rem', bottom: '1rem' }}
          onClick={() => setHelperVisible(!helperVisible)}
        />
      </>
      {helperVisible && (
        <S.HelperContainerBox>
          <S.Header>
            <S.HelperTitle>AJUDA</S.HelperTitle>
            <S.CloseButton onClick={() => setHelperVisible(false)}>
              <CloseIcon />
            </S.CloseButton>
          </S.Header>
          <S.HelperSubTitle>Legenda - Gráficos</S.HelperSubTitle>
          <S.HelperContainer>
            <S.Item>
              <S.ItemSquareBox style={{ background: '#096DD9' }} />
              <S.Content>água central menor ou igual 30% da água retida</S.Content>
            </S.Item>
            <S.Item>
              <S.ItemSquareBox style={{ background: '#FAAD14' }} />
              <S.Content>água central entre 30%-70% da água retida</S.Content>
            </S.Item>
            <S.Item>
              <S.ItemSquareBox style={{ background: '#C41B24' }} />
              <S.Content>água central maior ou igual que 70% da água retida</S.Content>
            </S.Item>
            <S.Item>
              <S.ItemSquareBox style={{ background: '#6B25C6' }} />
              <S.Content>outros eventos</S.Content>
            </S.Item>
          </S.HelperContainer>
          <S.HelperContainer>
            <FirstImage />
            <SecondImage />
          </S.HelperContainer>
        </S.HelperContainerBox>
      )}
    </div>
  );
};
