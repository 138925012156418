import styled from 'styled-components';
import { Radio as AntdRadio } from 'antd';

export const Radio = styled(AntdRadio)`
  .ant-radio-disabled + span {
    color: #8c8c8c;
  }
`;

export const RadioButton = styled(AntdRadio.Button)`
  &.ant-radio-button-wrapper-disabled {
    color: #99b2c6;
  }
`;
