/* eslint-disable @typescript-eslint/no-unused-vars */
import { InfoCircleOutlined } from '@ant-design/icons';
import { Description } from '@app/components/common/Description/Description';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { BaseFormInputItem } from '@app/components/common/forms/components/BaseFormInputItem/BaseFormInputItem';
import { Input } from '@app/components/common/inputs/Input/Input.styles';
import { Modal } from '@app/components/common/Modal/Modal';
import { PageContainer } from '@app/components/common/PageContainer/PageContainer';
import { SpinnerSlump } from '@app/components/common/SpinSlump/SpinSlump';
import appSettings from '@app/config/appsettings';
import { notificationController } from '@app/controllers/notificationController';
import { ClientModel } from '@app/domain/interfaces/clientModel';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { useMounted } from '@app/hooks/useMounted';
import IClientService, { ClientService } from '@app/services/clientService';
import { setFooter } from '@app/store/slices/footerPageSlice';
import { setHeaderRegisterPage } from '@app/store/slices/headerRegisterPage';
import { Col, Row } from 'antd';
import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const clientService: IClientService = new ClientService();

const ClientCreate: React.FC = () => {
  const { isMounted } = useMounted();
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [client, setClient] = useState<ClientModel>({
    cnpj: '',
    razaoSocial: '',
    complemento: '',
  });
  const [cnpjValido, setCnpjValido] = useState<boolean | undefined>(undefined);
  const [modalCancelVisible, setModalCancelVisible] = useState(false);
  const [cepValido, setCepValido] = useState<boolean | undefined>(undefined);
  const [cepInitialEditState, setCepInitialEditState] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [validation, setValidation] = useState(true);
  const [updateClientTable, setUpdateClient] = useState(false);

  const handleBackClient = () => {
    navigate('/gestao/concreteiras');
  };

  const fetchClient = (id: number) => {
    if (!client.id) {
      clientService.get(`obter/${id}`).then((res) => {
        setClient(res);
      });
    }
  };

  const handleCnpjOnChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const x = e.currentTarget.value.replace(/\D/g, '').match(/(\d{0,2})(\d{0,3})(\d{0,3})(\d{0,4})(\d{0,2})/);

    if (x !== null) {
      e.currentTarget.value = !x[2] ? x[1] : x[1] + '.' + x[2] + '.' + x[3] + '/' + x[4] + (x[5] ? '-' + x[5] : '');
      setClient({ ...client, cnpj: e.currentTarget.value });
    }
  };

  const handleCnpjOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Backspace' && client.cnpj.endsWith('/')) {
      setClient({ ...client, cnpj: client.cnpj.slice(0, -2) });
    }
  };

  useEffect(() => {
    async function buscaCnpj() {
      const rawCnpj = client.cnpj.replace(/[^\w\s]/gi, '');
      if (client.cnpj.length === 18) {
        setLoading(true);
        try {
          const cnpjResponse = await axios.get(`${appSettings().APIs.Consulta.CNPJ}/${rawCnpj}`);
          if (cnpjResponse.status === 200) {
            setCnpjValido(true);
            setClient({ ...client, razaoSocial: cnpjResponse.data.razao_social });
          }
        } catch (error) {
          setCnpjValido(false);
          notificationController.error({ message: 'CNPJ Inválido.' });
        }
        setLoading(false);
      }
    }

    if (!id) {
      buscaCnpj();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client.cnpj]);

  const handleCadastrarConcreteira = async (e: React.MouseEvent) => {
    if (client.complemento === null || client.complemento === undefined) {
      client.complemento = ' ';
    }

    if (!client.id) {
      setLoading(true);

      clientService
        .post('inserir', client)
        .then((res) => {
          cleanForm();
          setTimeout(() => {
            setUpdateClient(true);
            notificationController.success({ message: 'Concreteira cadastrada com sucesso!' });
            handleBackClient();
          }, 1700);
        })
        .catch((err) => {
          setLoading(false);
          console.error(err);
          notificationController.error({ message: `Erro ao cadastrar a concreteira, tente novamente. ${err}` });
        });
    } else {
      setLoading(true);
      clientService
        .update('atualizar', client)
        .then((res) => {
          cleanForm();
          setTimeout(() => {
            setUpdateClient(true);
            notificationController.success({ message: 'Concreteira editada com sucesso!' });
            handleBackClient();
          }, 1700);
        })
        .catch((err) => {
          setLoading(false);
          console.error(err);
          notificationController.error({ message: 'Erro ao atualizar a concreteira, tente novamente.' });
        });
    }
  };

  const cleanForm = () => {
    setClient({
      cnpj: '',
      razaoSocial: '',
    });
    setCepValido(undefined);
    setCnpjValido(undefined);
    setCepInitialEditState(false);
  };

  const handlerChangeCep = async (e: React.FormEvent<HTMLInputElement>) => {
    const x = e.currentTarget.value.replace(/\D/g, '').match(/(\d{0,5})(\d{0,3})/);

    if (x !== null) {
      const cep = !x[2] ? x[1] : x[1] + '-' + x[2];

      try {
        if (cep?.length === 9) {
          setLoading(true);
          const cepResponse = await axios.get(appSettings().APIs.Consulta.CEP + cep.replace('-', '') + '/json');

          if (cepResponse.data.erro == 'true') {
            throw new Error('CEP Inválido');
          }

          setClient({
            ...client,
            cep: cep,
            logradouro: cepResponse.data.logradouro,
            cidade: cepResponse.data.localidade,
            estado: cepResponse.data.uf,
            bairro: cepResponse.data.bairro,
            complemento: cepResponse.data.complemento,
          });
          setCepValido(true);
          setLoading(false);
          return;
        } else {
          throw new Error('CEP Inválido');
        }
      } catch (error) {
        setLoading(false);
        setCepValido(false);
        setClient({
          ...client,
          cep: cep,
          logradouro: '',
          cidade: '',
          estado: '',
          bairro: '',
          complemento: '',
        });
      }
    }
  };

  useEffect(() => {
    if (id) {
      setCepValido(true);
      setCnpjValido(true);
      fetchClient(parseInt(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isMounted.current) {
      dispatch(
        setHeaderRegisterPage({
          title: !id ? 'Nova Concreteira' : 'Editar Concreteira',
          handleBackClick: handleBackClient,
        }),
      );
      dispatch(
        setFooter({
          confirmButtonDisabled: !(cnpjValido && cepValido),
          confirmButtonText: id ? 'Salvar' : 'Cadastrar',
          handleCancelButtonClick: () => setModalCancelVisible(true),
          handleConfirmButtonClick: handleCadastrarConcreteira,
          cancelButtonText: 'Cancelar',
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client]);

  useEffect(() => {
    if (client.logradouro !== undefined && client.razaoSocial.length > 0 && cepValido && cnpjValido) {
      setValidation(false);
    } else {
      setValidation(true);
    }
  }, [client.logradouro, client.razaoSocial, cnpjValido, cepValido]);

  return (
    <>
      <Modal
        title={`Cancelar ${id ? 'edição' : 'cadastro'} da concreteira`}
        visible={modalCancelVisible}
        onOk={handleBackClient}
        onCancel={() => setModalCancelVisible(false)}
        cancelText="Cancelar"
        okText="Confirmar"
      >
        <Row align="middle">
          <Col span={2}>
            <InfoCircleOutlined size={20} style={{ color: '#FAAD14' }} />
          </Col>
          <Col span={22}>Deseja realmente cancelar {id ? 'a edição' : 'o cadastro'} da concreteira?</Col>
        </Row>
      </Modal>
      <PageContainer>
        <BaseForm layout="vertical" style={{ width: '100%' }}>
          <SpinnerSlump spinning={loading}>
            <Description title="Informações do cliente" subtitle="Preencha os campos para cadastrar um novo cliente">
              <Row gutter={18}>
                <Col xs={24} md={8}>
                  <BaseFormInputItem
                    label="CNPJ"
                    supportText="14 caracteres somente números"
                    isSuccess={cnpjValido}
                    errorText="Campo inválido"
                    successText="14 caracteres somente números"
                  >
                    <Input
                      placeholder="Digite o número do CNPJ"
                      value={client.cnpj}
                      onChange={handleCnpjOnChange}
                      onKeyDown={handleCnpjOnKeyDown}
                      disabled={client.id ? true : false}
                    />
                  </BaseFormInputItem>
                </Col>
                <Col xs={24} md={8}>
                  <BaseFormInputItem label="Razão Social" supportText="" errorText="Campo inválido" successText="">
                    <Input disabled placeholder="Digite o nome da razão social" value={client.razaoSocial} />
                  </BaseFormInputItem>
                </Col>
                {/*  <Col xs={24} md={8}>
              <BaseFormInputItem label="Telefone" supportText="" errorText="Campo inválido" successText="">
                <Input placeholder="Digite o telefone" />
              </BaseFormInputItem>
            </Col> */}
              </Row>
            </Description>

            <Description title="Endereço" subtitle="Preencha os campos abaixo para informar o endereço do cliente">
              <Row gutter={18}>
                <Col xs={24} md={5}>
                  <BaseFormInputItem
                    label="CEP"
                    supportText="8 caracteres somente números"
                    isSuccess={cepValido}
                    errorText="CEP inválido"
                    successText="8 caracteres somente números"
                  >
                    <Input placeholder="Digite o CEP" value={client.cep} onChange={handlerChangeCep} />
                  </BaseFormInputItem>
                </Col>
              </Row>

              <Row gutter={18}>
                <Col xs={24} md={8}>
                  <BaseFormInputItem label="Cidade" supportText="" errorText="Campo inválido" successText="">
                    <Input
                      placeholder="Digite a cidade"
                      value={client.cidade}
                      onChange={(e) => setClient({ ...client, cidade: e.target.value })}
                    />
                  </BaseFormInputItem>
                </Col>
                <Col xs={24} md={8}>
                  <BaseFormInputItem label="Estado" supportText="" errorText="Campo inválido" successText="">
                    <Input
                      placeholder="Digite o estado"
                      value={client.estado}
                      onChange={(e) => setClient({ ...client, estado: e.target.value })}
                    />
                  </BaseFormInputItem>
                </Col>
                <Col xs={24} md={8}>
                  <BaseFormInputItem label="Rua/Avenida" supportText="" errorText="Campo inválido" successText="">
                    <Input
                      placeholder="Digite a rua/avenida"
                      value={client.logradouro}
                      onChange={(e) => setClient({ ...client, logradouro: e.target.value })}
                    />
                  </BaseFormInputItem>
                </Col>
              </Row>

              <Row gutter={18}>
                <Col xs={24} md={8}>
                  <BaseFormInputItem label="Bairro" supportText="" errorText="Campo inválido" successText="">
                    <Input
                      placeholder="Digite o bairro"
                      value={client.bairro}
                      onChange={(e) => setClient({ ...client, bairro: e.target.value })}
                    />
                  </BaseFormInputItem>
                </Col>
                <Col xs={24} md={4}>
                  <BaseFormInputItem label="Número" supportText="" errorText="Campo inválido" successText="">
                    <Input
                      placeholder="Digite o número"
                      value={client.numero}
                      onChange={(e) => setClient({ ...client, numero: e.target.value.replace(/[^a-z0-9 ]/gi, '') })}
                    />
                  </BaseFormInputItem>
                </Col>
                <Col xs={24} md={4}>
                  <BaseFormInputItem label="Complemento" supportText="" errorText="Campo inválido" successText="">
                    <Input
                      placeholder="Digite o complemento"
                      value={client.complemento}
                      onChange={(e) => setClient({ ...client, complemento: e.target.value })}
                    />
                  </BaseFormInputItem>
                </Col>
              </Row>
            </Description>
          </SpinnerSlump>
        </BaseForm>
      </PageContainer>
    </>
  );
};
export default ClientCreate;
