import 'animate.css';
import { Col, Row, Spin } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import * as S from './CardCentralTracking.styles';
import { NotificationImportantRedIcon } from './CardCentralTracking.styles';
import { Tooltip } from '@app/components/common/Tooltip/Tooltip';
import { ReactComponent as IconConcreteMixerTruck } from '@app/assets/icons/caminhaoBetoneira.svg';
import { ReactComponent as IconThermostat } from '@app/assets/icons/thermostat.svg';
import { ReactComponent as IconClock } from '@app/assets/icons/clock.svg';
import { IconInfoCircle, IconReportChart, IconRpmShow } from '@app/assets/slump-icons';
import { CentralTrackingShippingModel } from '@app/domain/interfaces/centralTracking/CentralTrackingModel';
import FillCupWater from '@app/pages/modules/CentralTracking/components/FillCupWater/FillCupWater';
import Slump, { validateSlumpValue } from '@app/pages/modules/CentralTracking/components/CardSlump/Slump/Slump';
import { getDifferenceBetweenDatesInSeconds, secondsToHoursMinutesAndSeconds } from '@app/utils/utils';
import Flow from '@app/pages/modules/CentralTracking/components/CardSlump/Flow/Flow';
import { TooltipCard } from '@app/components/common/TooltipCard/TooltipCard';
import SlumpHistoryChart from '../Charts/SlumpHistoryChart';
import { LoadingOutlined } from '@ant-design/icons';

interface CardCentralTrackingProps {
  centralTrackingData?: CentralTrackingShippingModel;
  onAnimatedEnd?: () => void;
}

export const CardCentralTracking: React.FC<CardCentralTrackingProps> = ({ centralTrackingData, onAnimatedEnd }) => {
  const [date, setDate] = useState(new Date());

  const refreshClock = () => {
    setDate(new Date());
  };

  useEffect(() => {
    const timerId = setInterval(refreshClock, 1000);
    return function cleanup() {
      clearInterval(timerId);
    };
  }, []);

  const IconsContent = (
    <Row align="middle" style={{ width: '100%' }}>
      <Col xs={24} sm={8} md={8}>
        <Row align={'middle'} gutter={4}>
          <IconClock />
          <Col>
            <S.CardCentralTrackingPropertyIcon>Central</S.CardCentralTrackingPropertyIcon>
          </Col>
        </Row>
        <Row>
          <Col>
            <S.CardCentralTrackingLabel style={{ marginLeft: '1rem', textTransform: 'none' }}>
              {secondsToHoursMinutesAndSeconds(
                getDifferenceBetweenDatesInSeconds(centralTrackingData?.dataHora ?? new Date(), date),
              )}
            </S.CardCentralTrackingLabel>
          </Col>
        </Row>
      </Col>
      <Col xs={24} sm={8} md={8}>
        <Row align={'middle'} gutter={4}>
          <IconThermostat />
          <Col>
            <S.CardCentralTrackingPropertyIcon>Ambiente</S.CardCentralTrackingPropertyIcon>
          </Col>
        </Row>
        <Row justify={'center'}>
          <Col>
            <S.CardCentralTrackingLabel style={{ marginTop: '0.5rem' }}>
              {centralTrackingData?.temperaturaAmbiente
                ? `${centralTrackingData.temperaturaAmbiente.toFixed(0)}°C`
                : '-'}
            </S.CardCentralTrackingLabel>
          </Col>
        </Row>
      </Col>
      <Col xs={24} sm={8} md={8}>
        <Row align={'middle'} gutter={4}>
          <IconThermostat />
          <Col>
            <S.CardCentralTrackingPropertyIcon>Concreto</S.CardCentralTrackingPropertyIcon>
          </Col>
        </Row>
        <Row justify={'center'}>
          <Col>
            <S.CardCentralTrackingLabel style={{ marginTop: '0.5rem' }}>
              {centralTrackingData?.temperaturaConcreto
                ? `${centralTrackingData.temperaturaConcreto.toFixed(0)}°C`
                : '-'}
            </S.CardCentralTrackingLabel>
          </Col>
        </Row>
      </Col>
    </Row>
  );

  const BucketContent = (
    <Row justify={'center'} align={'middle'} style={{ width: '100%', height: '100%' }}>
      <Row justify={'center'} align={'middle'} style={{ width: '100%' }}>
        <S.CardCentralTrackingLabel>
          {`${
            centralTrackingData?.aguaTotal?.toFixed(0) ?? '-'
          } L / ${centralTrackingData?.aguaRetidaPorcentagem?.toFixed(0)}%`}
          <Tooltip title="Água retida / Retenção">
            <S.CardCentralTrackingLabelInfoIcon>
              &nbsp;
              <IconInfoCircle />
            </S.CardCentralTrackingLabelInfoIcon>
          </Tooltip>
        </S.CardCentralTrackingLabel>
      </Row>
      <Row justify={'center'} align={'middle'} style={{ width: '100%' }}>
        <FillCupWater fillLevel={centralTrackingData?.aguaUtilizadaPorcentagem ?? 0 * 100} />
      </Row>
      <Row justify={'center'} align={'middle'}>
        <S.CardCentralTrackingLabel>
          {`${centralTrackingData?.aguaUtilizada?.toFixed(0) ?? '-'} L`}
          <Tooltip
            title={`Água central ${
              centralTrackingData?.dataHoraUltimaAtualizacaoAgua
                ? `- ${moment(centralTrackingData?.dataHoraUltimaAtualizacaoAgua).format('DD/MM/yy HH:mm')}`
                : ''
            }`}
          >
            <S.CardCentralTrackingLabelInfoIcon>
              &nbsp;
              <IconInfoCircle />
            </S.CardCentralTrackingLabelInfoIcon>
          </Tooltip>
        </S.CardCentralTrackingLabel>
      </Row>
    </Row>
  );

  const SlumpFlowContent = (
    <S.SlumpFlowContent>
      <Row align={'middle'} justify={'space-between'}>
        <Col xs={12}>
          <Row justify={'start'}>
            <S.CardCentralTrackingTitle>
              {centralTrackingData?.slumpAlvo && centralTrackingData.slumpAlvo <= 30 ? 'SLUMP' : 'FLOW'}{' '}
              {centralTrackingData?.slumpHistorico &&
                centralTrackingData?.slumpHistorico.length > 0 &&
                getDifferenceBetweenDatesInSeconds(
                  centralTrackingData?.slumpHistorico[centralTrackingData?.slumpHistorico.length - 1].dataHora,
                  new Date(),
                ) <= 20 && <S.CardCentralTrackingSpin indicator={<LoadingOutlined spin />} size="default" />}
            </S.CardCentralTrackingTitle>
          </Row>
        </Col>
        <Col xs={12}>
          <Row justify={'end'} align={'middle'}>
            <S.CardCentralTrackingValue style={{ textTransform: 'none', display: 'flex', alignItems: 'center' }}>
              {centralTrackingData?.rotacaoMotor && (
                <>
                  <IconRpmShow />
                  {`${centralTrackingData?.rotacaoMotor} rpm`}
                </>
              )}
            </S.CardCentralTrackingValue>
          </Row>
        </Col>
      </Row>

      {centralTrackingData?.slumpAtual != undefined &&
      centralTrackingData?.slumpAtual != null &&
      centralTrackingData?.slumpAlvo &&
      (centralTrackingData?.slumpAlvo ?? 0) <= 30 ? (
        <>
          <Row justify={'center'}>
            <Col>
              {centralTrackingData?.slumpAlvo && centralTrackingData?.slumpAlvo <= 30 ? (
                <Slump
                  slumpValue={
                    centralTrackingData.slumpAtual < 0
                      ? 0
                      : centralTrackingData.slumpAtual > 30
                      ? 30
                      : centralTrackingData.slumpAtual
                  }
                  targetSlump={centralTrackingData?.slumpAlvo ?? 0}
                  targetTolerance={centralTrackingData?.slumpRange ?? 0}
                />
              ) : (
                <Flow
                  slumpValue={centralTrackingData?.slumpAtual ?? 0}
                  targetSlump={centralTrackingData?.slumpAlvo ?? 0}
                  targetTolerance={centralTrackingData?.slumpRange ?? 0}
                />
              )}
            </Col>
          </Row>
          <Row justify={'space-between'} align={'bottom'}>
            <Col>
              <Row>
                <S.CardCentralTrackingProperty>Alvo</S.CardCentralTrackingProperty>
              </Row>
              <S.CardCentralTrackingLabel style={{ marginTop: '0.5rem' }}>
                {centralTrackingData?.slumpAlvo?.toFixed(0) ?? 0}
                <span> +- </span>
                {centralTrackingData?.slumpRange?.toFixed(0) ?? 0}
              </S.CardCentralTrackingLabel>
            </Col>
            <Col>
              <Row align={'middle'}>
                <S.CardCentralTrackingProperty>Atual</S.CardCentralTrackingProperty>
                {!validateSlumpValue(
                  centralTrackingData?.slumpAtual ?? 0,
                  centralTrackingData?.slumpAlvo ?? 0,
                  centralTrackingData?.slumpRange ?? 0,
                ) && <NotificationImportantRedIcon />}
              </Row>
              <Row gutter={6} align={'middle'}>
                <Col>
                  <S.CardCentralTrackingLabel style={{ marginTop: '0.5rem' }}>
                    {centralTrackingData?.slumpAtual?.toFixed(1) ?? 0}
                  </S.CardCentralTrackingLabel>
                </Col>
                {centralTrackingData.slumpHistorico?.length ? (
                  <Col>
                    <TooltipCard
                      placement="bottomRight"
                      autoAdjustOverflow
                      overlay={
                        <SlumpHistoryChart
                          travelId={centralTrackingData.idViagem ?? 0}
                          slumpHistory={centralTrackingData.slumpHistorico}
                          slumpMax={centralTrackingData.slumpAlvo + (centralTrackingData.slumpRange ?? 0)}
                          slumpMin={centralTrackingData.slumpAlvo - (centralTrackingData.slumpRange ?? 0)}
                        />
                      }
                    >
                      <S.CardCentralTrackingLabel style={{ marginTop: '0.5rem', cursor: 'pointer' }}>
                        <IconReportChart />
                      </S.CardCentralTrackingLabel>
                    </TooltipCard>
                  </Col>
                ) : (
                  <></>
                )}
              </Row>
            </Col>
          </Row>
        </>
      ) : (
        <>
          <Row align={'middle'}>
            <Col style={{ marginTop: '-1.5rem' }}>
              <S.NoContentTitle>Resultado indisponível</S.NoContentTitle>
              <S.NoContentDescription>
                As informações do {(centralTrackingData?.slumpAlvo ?? 0) <= 30 ? 'slump' : 'flow'} ainda não estão
                disponíveis.
              </S.NoContentDescription>
            </Col>
          </Row>
          <Row align={'middle'}></Row>
        </>
      )}
    </S.SlumpFlowContent>
  );

  return (
    <>
      <S.CardCentralTracking onAnimationEnd={onAnimatedEnd}>
        <Row style={{ width: '100%' }}>
          {/* primeiro container */}
          <Col xs={16}>
            <Row style={{ height: '100%' }}>
              <Col span={24}>
                <Row align={'middle'}>
                  <Col xs={12}>
                    <Row align={'middle'}>
                      <IconConcreteMixerTruck />
                      <S.CardCentralTrackingTitle>
                        &nbsp;{`${centralTrackingData?.betoneira}`}
                      </S.CardCentralTrackingTitle>
                      <S.CardCentralTrackingMixerMeters>
                        {`/ ${centralTrackingData?.volume?.toFixed(0)} M³`}
                      </S.CardCentralTrackingMixerMeters>
                    </Row>
                  </Col>
                  <Col xs={12}>
                    <Row align={'middle'} justify={'end'}>
                      <S.CardCentralTrackingProperty>Emissão NF</S.CardCentralTrackingProperty>
                      <S.CardCentralTrackingValue>
                        {moment(centralTrackingData?.dataEmissaoNf).format('DD/MM/YY HH:mm')}
                      </S.CardCentralTrackingValue>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col span={24} style={{ height: '100%' }}>
                <Row gutter={4} style={{ paddingTop: '0.75rem', height: '90%' }}>
                  <Col xs={8}>{BucketContent}</Col>
                  <Col xs={16}>
                    <S.CardCentralTrackingInfoContent>
                      <Row>
                        <S.CardCentralTrackingProperty>Motorista</S.CardCentralTrackingProperty>
                        <S.CardCentralTrackingValue>{centralTrackingData?.motorista}</S.CardCentralTrackingValue>
                      </Row>
                      <Row>
                        <S.CardCentralTrackingProperty>Cliente</S.CardCentralTrackingProperty>
                        <S.CardCentralTrackingValue>{centralTrackingData?.cliente}</S.CardCentralTrackingValue>
                      </Row>
                      <Row>
                        <S.CardCentralTrackingProperty>Traço</S.CardCentralTrackingProperty>
                        <S.CardCentralTrackingValue style={{ wordBreak: 'break-word' }}>
                          {centralTrackingData?.traco}
                        </S.CardCentralTrackingValue>
                      </Row>
                      <Row>{IconsContent}</Row>
                    </S.CardCentralTrackingInfoContent>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          {/* segundo container */}
          <Col xs={8}>
            <S.CardCentralTrackingSlumpContent>{SlumpFlowContent}</S.CardCentralTrackingSlumpContent>
          </Col>
        </Row>
      </S.CardCentralTracking>
    </>
  );
};
