import { AlertShippingModel } from '@app/domain/interfaces/alert/alertShippingResponseModel';
import { Justificativa } from '@app/domain/interfaces/configAlertModel';
import {
  CardInfoContainer,
  CardInfoProperty,
  CardInfoTag,
  CardInfoTitle,
  CardInfoValue,
} from '@app/components/common/Card/CardInfo/CardDeviceInformation.styles';
import { Checkbox } from '@app/components/common/Checkbox/Checkbox';
import { Description } from '@app/components/common/Description/Description';
import { PageContainer } from '@app/components/common/PageContainer/PageContainer';
import { RadioGroup } from '@app/components/common/Radio/Radio';
import { SpinnerSlump } from '@app/components/common/SpinSlump/SpinSlump';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { BaseFormInputItem } from '@app/components/common/forms/components/BaseFormInputItem/BaseFormInputItem';
import { TextArea } from '@app/components/common/inputs/Input/Input';
import { readUser } from '@app/services/localStorage.service';
import { Col, Radio, Row, Select } from 'antd';

interface ISimpleAlertJustify {
  alert: AlertShippingModel;
  setAlert: (value: AlertShippingModel) => void;
  justifications: Justificativa[];
  loading: boolean;
  checkboxConfirm: boolean;
  setCheckboxConfirm: (value: boolean) => void;
  report: boolean;
  setReport: (value: boolean) => void;
}

const SimpleAlertJustify: React.FC<ISimpleAlertJustify> = ({
  alert,
  setAlert,
  justifications,
  loading,
  checkboxConfirm,
  setCheckboxConfirm,
  report,
  setReport,
}) => {
  return (
    <SpinnerSlump spinning={loading}>
      <PageContainer>
        <Description title={alert?.alerta?.logAlarmes[0].titulo}></Description>
        <CardInfoTag style={{ marginTop: '-1.5rem' }}>
          ID viagem<span>{alert?.alerta?.idViagem.toString()}</span>
        </CardInfoTag>
        <CardInfoContainer>
          <Row>
            <CardInfoTitle>Informações da viagem</CardInfoTitle>
          </Row>
          <Row gutter={24} justify={'space-between'} style={{ paddingBottom: '1.5rem' }}>
            <Col xs={24} lg={2}>
              <CardInfoProperty>Filial</CardInfoProperty>
              <CardInfoValue>{alert?.remessas ? alert?.remessas[0].filial.descricaoFilial : ''}</CardInfoValue>
            </Col>
            <Col xs={24} lg={7}>
              <CardInfoProperty>Cliente</CardInfoProperty>
              <CardInfoValue>{alert?.alerta?.cliente}</CardInfoValue>
            </Col>
            <Col xs={24} lg={5}>
              <CardInfoProperty>Motorista</CardInfoProperty>
              <CardInfoValue>{alert?.alerta?.nomeMotorista}</CardInfoValue>
            </Col>
            <Col xs={24} lg={10}>
              <CardInfoProperty>Descrição do traço</CardInfoProperty>
              <CardInfoValue>{alert?.remessas ? alert.remessas[0].tracos[0].tracoDescricao : '-'}</CardInfoValue>
            </Col>
          </Row>
          <Row gutter={24} justify={'space-between'} style={{ paddingBottom: '2.5rem' }}>
            <Col xs={24} lg={11}>
              <CardInfoProperty>Endereço do cliente</CardInfoProperty>
              <CardInfoValue>{alert?.alerta?.endereco ?? '-'}</CardInfoValue>
            </Col>
            <Col xs={24} lg={3}>
              <CardInfoProperty>Veículo</CardInfoProperty>
              <CardInfoValue>{alert?.alerta?.numeroBT ?? '-'}</CardInfoValue>
            </Col>
            <Col xs={24} lg={5}>
              <CardInfoProperty>Peça</CardInfoProperty>
              <CardInfoValue>{alert?.remessas ? alert?.remessas[0].peca.descricao : '-'}</CardInfoValue>
            </Col>
            <Col xs={24} lg={5}>
              <CardInfoProperty>Volume programado</CardInfoProperty>
              <CardInfoValue>
                {alert?.remessas && alert?.remessas[0].volumeProgramado
                  ? `${alert?.remessas[0].volumeProgramado} m³`
                  : '-'}
              </CardInfoValue>
            </Col>
          </Row>
          <Row>
            <CardInfoTitle>Informações dos tempos da viagem</CardInfoTitle>
          </Row>
          <Row gutter={24} justify={'space-between'}>
            <Col xs={24} lg={12}>
              <Row>
                <Col xs={24} lg={4}>
                  <CardInfoProperty>Central</CardInfoProperty>
                  <CardInfoValue>{alert.tempoCentral}</CardInfoValue>
                </Col>
                <Col xs={24} lg={4}>
                  <CardInfoProperty>Caminho</CardInfoProperty>
                  <CardInfoValue>{alert.tempoCaminho}</CardInfoValue>
                </Col>
                <Col xs={24} lg={4}>
                  <CardInfoProperty>Aguardo</CardInfoProperty>
                  <CardInfoValue>{alert.tempoAguardo}</CardInfoValue>
                </Col>
                <Col xs={24} lg={4}>
                  <CardInfoProperty>Descarga</CardInfoProperty>
                  <CardInfoValue>{alert.tempoDescarga}</CardInfoValue>
                </Col>
                <Col xs={24} lg={4}>
                  <CardInfoProperty>Tempo total</CardInfoProperty>
                  <CardInfoValue>{alert.tempoTotal}</CardInfoValue>
                </Col>
              </Row>
            </Col>
          </Row>
        </CardInfoContainer>

        <BaseForm layout="vertical" style={{ width: '100%' }} disabled={alert.alerta?.status?.toString() !== 'Novo'}>
          <Description title="Evento" subtitle="Preencha os campos para confirmar este evento">
            <Row>
              <Col xs={24} md={8}>
                <BaseFormInputItem label="Deseja reportar alerta?">
                  <RadioGroup
                    name="status"
                    value={!report ? 'Confirmado' : 'Reportado'}
                    defaultValue="Confirmado"
                    onChange={(e) => setReport(e.target.value === 'Confirmado' ? false : true)}
                  >
                    <Radio value="Reportado">Sim</Radio>
                    <Radio value="Confirmado">Não</Radio>
                  </RadioGroup>
                </BaseFormInputItem>
              </Col>
            </Row>
            {justifications?.length > 0 && (
              <Row>
                <Col xs={24} md={8}>
                  <BaseFormInputItem label="Motivo">
                    <Select
                      showArrow
                      showSearch
                      placeholder="Selecione um motivo"
                      value={alert?.alerta?.idJustificativa}
                      onChange={(value) =>
                        setAlert({
                          ...alert,
                          alerta: { ...alert.alerta, idJustificativa: (value ?? 0) as number },
                        })
                      }
                      options={justifications.map((j) => ({ label: j.justificativa, value: j.id }))}
                    />
                  </BaseFormInputItem>
                </Col>
              </Row>
            )}
            <Row>
              <Col xs={24} md={8}>
                <BaseFormInputItem label="Explique">
                  <TextArea
                    placeholder="Digite sua explicação do motivo selecionado"
                    rows={4}
                    value={alert?.alerta?.explicacao}
                    onChange={(e) =>
                      setAlert({
                        ...alert,
                        alerta: { ...alert.alerta, explicacao: e.target.value },
                      })
                    }
                  />
                </BaseFormInputItem>
              </Col>
            </Row>
            <Row style={{ paddingBottom: '1.5rem' }}>
              <Checkbox value={checkboxConfirm} onChange={(value) => setCheckboxConfirm(!value.target.value)}>
                Evento analisado e confirmado por usuário {alert?.alerta?.responsavel ?? readUser()?.username}
              </Checkbox>
            </Row>
          </Description>
        </BaseForm>
      </PageContainer>
    </SpinnerSlump>
  );
};

export default SimpleAlertJustify;
