import {
  IconAlert,
  IconCadastro,
  IconConfig,
  IconDashboard,
  IconMonitoring,
  IconReport,
  IconUsers,
} from '@app/assets/slump-icons';

interface IIconsByGroupProfile {
  groupName: string;
  icon: JSX.Element;
}

export const IconsByGroupProfile: IIconsByGroupProfile[] = [
  {
    groupName: 'Início',
    icon: (
      <div>
        <IconDashboard />
      </div>
    ),
  },
  {
    groupName: 'Alertas',
    icon: (
      <div>
        <IconAlert />
      </div>
    ),
  },
  {
    groupName: 'Monitoramento',
    icon: <IconMonitoring />,
  },
  {
    groupName: 'Cadastros',
    icon: (
      <div>
        <IconCadastro />
      </div>
    ),
  },
  {
    groupName: 'Usuários e perfis',
    icon: (
      <div>
        <IconUsers />
      </div>
    ),
  },
  {
    groupName: 'Relatórios',
    icon: (
      <div>
        <IconReport />
      </div>
    ),
  },
  {
    groupName: 'Configurações',
    icon: (
      <div>
        <IconConfig />
      </div>
    ),
  },
];
