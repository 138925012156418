import * as React from 'react';
const SvgIconDown = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 36 36" width="1em" height="1em" role="img">
    <path
      fill="#383838"
      d="M32.946 7.715h-3.013c-.205 0-.398.1-.519.265L18 23.714 6.585 7.98a.645.645 0 0 0-.518-.265H3.053a.323.323 0 0 0-.261.51l14.167 19.53a1.282 1.282 0 0 0 2.077 0l14.167-19.53a.32.32 0 0 0-.257-.51Z"
    />
  </svg>
);
export default SvgIconDown;
