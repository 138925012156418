/* eslint-disable @typescript-eslint/no-unused-vars */
import { InfoCircleOutlined } from '@ant-design/icons';
import { Description } from '@app/components/common/Description/Description';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { BaseFormInputItem } from '@app/components/common/forms/components/BaseFormInputItem/BaseFormInputItem';
import { Input } from '@app/components/common/inputs/Input/Input';
import { PageContainer } from '@app/components/common/PageContainer/PageContainer';
import { SpinnerSlump } from '@app/components/common/SpinSlump/SpinSlump';
import { notificationController } from '@app/controllers/notificationController';
import { ClientModel } from '@app/domain/interfaces/clientModel';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { useMounted } from '@app/hooks/useMounted';
import IClientService, { ClientService } from '@app/services/clientService';
import { isUserMaster, readUserFilter } from '@app/services/localStorage.service';
import { setFooter } from '@app/store/slices/footerPageSlice';
import { setHeaderRegisterPage } from '@app/store/slices/headerRegisterPage';
import { Col, Modal, Row, Select } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ConcreteMixerModel } from '@app/domain/interfaces/concreteMixerModel';
import IConcreteMixerService, { ConcreteMixerService } from '@app/services/concreteMixerService';

const clientService: IClientService = new ClientService();
const concreteMixerService: IConcreteMixerService = new ConcreteMixerService();

const ConcreteMixerCreate: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isMounted } = useMounted();
  const [loading, setLoading] = useState(false);
  const [modalCancelVisible, setModalCancelVisible] = useState(false);
  const [clients, setClients] = useState<ClientModel[]>([]);
  const [client, setClient] = useState<ClientModel>({} as ClientModel);
  const [textoFrotaInvalida, setTextoFrotaInvalida] = useState('');
  const [textoPlacaInvalida, setTextoPlacaInvalida] = useState('');
  const [concreteMixer, setConcreteMixer] = useState<ConcreteMixerModel>({
    cliente: '',
    createdAt: new Date(),
    estadoBetoneira: 0,
    idCliente: 0,
    modeloCaminhao: '',
    numeroBT: '',
    placaCaminhao: '',
    qtdParafusos: 0,
    raioFlange: 0,
  });
  const [numeroFrotaValido, setNumeroFrotaValido] = useState<boolean | undefined>(undefined);
  const [modeloCaminhaoValido, setModeloCaminhaoValido] = useState<boolean | undefined>(undefined);
  const [placaCaminhaoValido, setPlacaCaminhaoValido] = useState<boolean | undefined>(undefined);
  const [parafusosValido, setParafusosValido] = useState<boolean | undefined>(undefined);
  const [raioFlangeValido, setRaioFlangeValido] = useState<boolean | undefined>(undefined);
  const [mixers, setMixers] = useState<ConcreteMixerModel[]>([]);
  const [validation, setValidation] = useState(true);

  const handleSaveMixer = () => {
    setLoading(true);

    if (!concreteMixer.idCliente) {
      notificationController.error({ message: 'Nenhum cliente selecionado' });
      return;
    }

    if (!concreteMixer.id) {
      concreteMixerService
        .post('inserir', { ...concreteMixer })
        .then((res) => {
          setLoading(false);
          cleanForm();
          notificationController.success({ message: 'Betoneira criada com sucesso' });
          navigate('/gestao/betoneiras');
        })
        .catch((err) => {
          setLoading(false);
          console.error(err);
          notificationController.error({ message: 'Erro ao cadastrar a betoneiras, tente novamente.' });
        });
    } else {
      concreteMixerService
        .update('atualizar', { ...concreteMixer })
        .then((res) => {
          setLoading(false);
          notificationController.success({ message: 'Betoneira atualizada com sucesso' });
          navigate('/gestao/betoneiras');
        })
        .catch((err) => {
          notificationController.error({ message: 'Erro ao atualizar a betoneiras, tente novamente.' });
          setLoading(false);
        });
    }
  };

  const fetchClients = useCallback(async () => {
    setLoading(true);
    setClients(await clientService.getArray('obter'));
    setLoading(false);
  }, []);

  const handleModeloCaminhaoOnChange = (e: React.FormEvent<HTMLInputElement>) => {
    if (e.currentTarget.value.length === 0) {
      setModeloCaminhaoValido(undefined);
    } else {
      setModeloCaminhaoValido(true);
    }

    setConcreteMixer({ ...concreteMixer, modeloCaminhao: e.currentTarget.value });
  };

  const handleNumeroFrotaOnChange = async (e: React.FormEvent<HTMLInputElement>) => {
    const x = e.currentTarget.value.replace(/\D/g, '').match(/(\d{0,4})/);

    if (x !== null) {
      e.currentTarget.value = !x[2] ? x[1] : x[1] + '-' + x[2];
      e.currentTarget.value = 'BT-' + e.currentTarget.value;
      setConcreteMixer({ ...concreteMixer, numeroBT: e.currentTarget.value });

      if (e.currentTarget.value.length > 0 && e.currentTarget.value.length === 7) {
        if (mixers?.find((f) => f.numeroBT === e.currentTarget.value)) {
          setNumeroFrotaValido(false);
          setTextoFrotaInvalida('Número de frota já cadastrado');
        } else {
          setNumeroFrotaValido(true);
          setTextoFrotaInvalida('');
        }
      } else {
        setNumeroFrotaValido(false);
        setTextoFrotaInvalida('');
      }
    }
  };

  const handlePlacaOnChange = (e: React.FormEvent<HTMLInputElement>) => {
    const x = e.currentTarget.value
      .toUpperCase()
      .replace(/[\W_]/gi, '')
      .match(/^([A-Z0-9]{0,3})([A-Z0-9]{0,4})/);

    if (x !== null) {
      e.currentTarget.value = !x[2] ? x[1] : x[1] + '-' + x[2];

      setConcreteMixer({ ...concreteMixer, placaCaminhao: e.currentTarget.value });

      if (e.currentTarget.value.length > 0 && e.currentTarget.value.length === 8) {
        if (mixers?.find((f) => f.placaCaminhao === e.currentTarget.value)) {
          setPlacaCaminhaoValido(false);
          setTextoPlacaInvalida('Placa do caminhão já cadastrada');
        } else {
          setPlacaCaminhaoValido(true);
          setTextoPlacaInvalida('');
        }
      } else {
        setPlacaCaminhaoValido(false);
        setTextoPlacaInvalida('');
      }
    }
  };

  const handleRaioOnChange = (e: React.FormEvent<HTMLInputElement>) => {
    const x = e.currentTarget.value.replace(/\D/g, '').match(/(\d{0,3})/);

    if (x !== null && x['input'] !== undefined) {
      if (x[1].toString().length === 1) {
        e.currentTarget.value = '00' + x[1];
      } else if (x[1].toString().length === 3 && x[1][0] === '0' && x[1][1] === '0') {
        x[1] = x[1][1] + x['input'][2] + x['input'][3];
        e.currentTarget.value = x[1];
      } else if (x[1].toString().length === 3 && x[1][0] === '0' && x[1][1] !== '0') {
        x[1] = x[1][1] + x['input'][2] + x['input'][3];
        e.currentTarget.value = x[1];
      } else {
        e.currentTarget.value = !x[2] ? x[1] : x[1] + x[2];
      }

      setConcreteMixer({ ...concreteMixer, raioFlange: Number.parseFloat(e.currentTarget.value) });

      if (Number.parseInt(e.currentTarget.value) > 0) {
        setRaioFlangeValido(true);
      } else {
        setRaioFlangeValido(false);
      }
    }
  };

  const handleParafusosOnChange = (e: React.FormEvent<HTMLInputElement>) => {
    const x = e.currentTarget.value.replace(/\D/g, '').match(/(\d{0,2})/);

    if (x !== null && x['input'] !== undefined) {
      if (x[1].toString().length === 1) {
        e.currentTarget.value = 0 + x[1];
      } else if (x[1].toString().length === 2 && x[1][0] === '0') {
        x[1] = x[1][1] + x['input'][2];
        e.currentTarget.value = x[1];
      } else {
        e.currentTarget.value = !x[2] ? x[1] : x[1] + x[2];
      }
      setConcreteMixer({ ...concreteMixer, qtdParafusos: Number.parseFloat(e.currentTarget.value) });

      if (Number.parseInt(e.currentTarget.value) > 0) {
        setParafusosValido(true);
      } else {
        setParafusosValido(false);
      }
    }
  };

  const cleanForm = () => {
    setConcreteMixer({
      cliente: '',
      createdAt: new Date(),
      estadoBetoneira: 0,
      idCliente: 0,
      modeloCaminhao: '',
      numeroBT: '',
      placaCaminhao: '',
      qtdParafusos: 0,
      raioFlange: 0,
    });
    setNumeroFrotaValido(undefined);
    setModeloCaminhaoValido(undefined);
    setPlacaCaminhaoValido(undefined);
    setParafusosValido(undefined);
    setRaioFlangeValido(undefined);
  };

  useEffect(() => {
    if (id) {
      setNumeroFrotaValido(true);
      setModeloCaminhaoValido(true);
      setPlacaCaminhaoValido(true);
      setParafusosValido(true);
      setRaioFlangeValido(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      concreteMixer.idCliente !== undefined &&
      numeroFrotaValido &&
      modeloCaminhaoValido &&
      placaCaminhaoValido &&
      parafusosValido &&
      raioFlangeValido
    ) {
      setValidation(false);
    } else {
      setValidation(true);
    }
  }, [
    concreteMixer.idCliente,
    numeroFrotaValido,
    modeloCaminhaoValido,
    placaCaminhaoValido,
    parafusosValido,
    raioFlangeValido,
  ]);

  useEffect(() => {
    const filter = readUserFilter();

    if (isMounted.current) {
      if (id) {
        concreteMixerService.get(`obter/${id}`).then((res) => {
          setConcreteMixer(res);
        });
      } else if (filter.client?.id) {
        setClient(filter.client);
        setConcreteMixer((prevState) => ({ ...prevState, idCliente: Number(filter.client!.id) }));
      }
      fetchClients();
      dispatch(
        setHeaderRegisterPage({
          title: id ? 'Editar caminhão betoneira' : 'Novo caminhão betoneira',
          handleBackClick: () => navigate('/gestao/betoneiras'),
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(
      setFooter({
        confirmButtonDisabled: !(
          numeroFrotaValido &&
          modeloCaminhaoValido &&
          parafusosValido &&
          raioFlangeValido &&
          placaCaminhaoValido &&
          concreteMixer.idCliente
        ),
        confirmButtonText: id ? 'Salvar' : 'Cadastrar',
        cancelButtonText: 'Cancelar',
        handleConfirmButtonClick: handleSaveMixer,
        handleCancelButtonClick: () => setModalCancelVisible(true),
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [concreteMixer]);

  return (
    <>
      <Modal
        title={`Cancelar ${id ? 'edição' : 'cadastro'} do caminhão betoneira`}
        open={modalCancelVisible}
        onOk={() => navigate('/gestao/betoneiras')}
        onCancel={() => setModalCancelVisible(false)}
        cancelText="Cancelar"
        okText="Confirmar"
      >
        <Row align="middle">
          <Col span={2}>
            <InfoCircleOutlined size={20} style={{ color: '#FAAD14' }} />
          </Col>
          <Col span={22}>Deseja realmente cancelar {id ? 'a edição' : 'o cadastro'} do caminhão betoneira?</Col>
        </Row>
      </Modal>
      <PageContainer>
        <BaseForm layout="vertical" style={{ width: '100%' }}>
          <SpinnerSlump spinning={loading}>
            <Description
              title="Informações do caminhão betoneira"
              subtitle="Preencha os campos para cadastrar um novo caminhão betoneira"
            >
              <Row gutter={18}>
                <Col xs={24} md={8}>
                  <BaseFormInputItem
                    label="Número de Frota"
                    supportText="6 caracteres letras e números"
                    isSuccess={numeroFrotaValido}
                    errorText={textoFrotaInvalida.length > 0 ? textoFrotaInvalida : 'Campo inválido'}
                    successText=""
                  >
                    <Input
                      placeholder="Informe o número de frota do veículo"
                      onChange={handleNumeroFrotaOnChange}
                      value={concreteMixer.numeroBT}
                    />
                  </BaseFormInputItem>
                </Col>
                <Col xs={24} md={8}>
                  <BaseFormInputItem
                    label="Modelo do caminhão"
                    supportText=""
                    isSuccess={modeloCaminhaoValido}
                    errorText="Campo inválido"
                    successText=""
                  >
                    <Input
                      maxLength={50}
                      placeholder="Informe o modelo do veículo"
                      onChange={handleModeloCaminhaoOnChange}
                      value={concreteMixer.modeloCaminhao}
                    />
                  </BaseFormInputItem>
                </Col>
                <Col xs={24} md={8}>
                  <BaseFormInputItem
                    label="Placa do caminhão"
                    supportText="7 caracteres letras e números"
                    isSuccess={placaCaminhaoValido}
                    errorText="Placa do caminhão já cadastrada"
                  >
                    <Input
                      name="placaCaminhao"
                      placeholder="Digite a placa do caminhão"
                      value={concreteMixer.placaCaminhao}
                      onChange={handlePlacaOnChange}
                    />
                  </BaseFormInputItem>
                </Col>
                <Col xs={24} md={4}>
                  <BaseFormInputItem
                    label="Número de parafusos (Qtd)"
                    isSuccess={parafusosValido}
                    errorText="Campo inválido"
                  >
                    <Input
                      placeholder="Digite o número"
                      onChange={handleParafusosOnChange}
                      value={concreteMixer.qtdParafusos}
                      type="number"
                    />
                  </BaseFormInputItem>
                </Col>
                <Col xs={24} md={4}>
                  <BaseFormInputItem label="Raio da flange(mm)" isSuccess={raioFlangeValido} errorText="Campo inválido">
                    <Input
                      placeholder="Digite o raio"
                      onChange={handleRaioOnChange}
                      value={concreteMixer.raioFlange}
                      type="number"
                    />
                  </BaseFormInputItem>
                </Col>
              </Row>
            </Description>
            {isUserMaster() && (
              <Description title="Informações do cliente" subtitle="Vincule o veículo informado a um cliente ">
                <Row gutter={18}>
                  <Col xs={24} md={6}>
                    <BaseFormInputItem label="Pesquisar cliente" errorText="CEP inválido">
                      <Select
                        showArrow
                        showSearch
                        style={{ width: 330 }}
                        placeholder="Pesquise por razão social"
                        disabled={!isUserMaster()}
                        value={
                          concreteMixer.idCliente && concreteMixer.idCliente != 0 ? concreteMixer.idCliente : undefined
                        }
                        onChange={(value) => setConcreteMixer({ ...concreteMixer, idCliente: value as number })}
                        options={clients?.map((client) => ({ value: client.id, label: client.razaoSocial }))}
                        filterOption={(input, option) =>
                          (option?.label ?? '').toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      />
                    </BaseFormInputItem>
                  </Col>
                </Row>
              </Description>
            )}
          </SpinnerSlump>
        </BaseForm>
      </PageContainer>
    </>
  );
};

export default ConcreteMixerCreate;
