import { ApiServiceBase, IApiServiceBase } from './apiServiceBase';
import { CommandModel } from '@app/domain/interfaces/commandModel/commandModel';

export default interface ICommadService extends IApiServiceBase<CommandModel, CommandModel> {}

export class CommandService extends ApiServiceBase<CommandModel, CommandModel> {
  constructor() {
    super('admin/comandos');
  }
}
