import { UserType } from '@app/constants/enums/userType';
import { IUserFilterSessionModel } from '@app/constants/interfaces/Session/IUserFilterSessionModel';
import { ClaimModel } from '@app/domain/interfaces/profile/claimModel';
import { UserModel } from '@app/domain/interfaces/user/userModel';

export const persistToken = (token: string): void => {
  localStorage.setItem('accessToken', token);
};
export const readToken = (): string | null => {
  return localStorage.getItem('accessToken') || null;
};
export const deleteToken = (): void => localStorage.removeItem('accessToken');

export const persistUser = (user: UserModel): void => {
  localStorage.setItem('user', JSON.stringify(user));
};
export const readUser = (): UserModel | null => {
  const userStr = localStorage.getItem('user');
  return userStr ? JSON.parse(userStr) : null;
};
export const deleteUser = (): void => localStorage.removeItem('user');

export const setUserFilter = (userFilter: IUserFilterSessionModel): void => {
  localStorage.setItem('userFilter', JSON.stringify(userFilter));
};

export const isUserMaster = (): boolean => readUser()?.type == UserType.Master;

export const readUserFilter = (): IUserFilterSessionModel => {
  const userFilter = localStorage.getItem('userFilter');

  if (userFilter) {
    return JSON.parse(userFilter);
  }

  return {} as IUserFilterSessionModel;
};

export const deleteUserFilter = (): void => {
  localStorage.removeItem('userFilter');
};

export const hasUserFilter = (): boolean => {
  const user = readUser();
  const sessionFilter = localStorage.getItem('userFilter');

  if (sessionFilter && user?.type == UserType.Master) {
    return true;
  } else if (sessionFilter) {
    const userFilter = JSON.parse(sessionFilter) as IUserFilterSessionModel;
    return userFilter.group || userFilter.branch ? true : false;
  }

  return false;
};

export const getClaimValue = (claimType: string): string | undefined => {
  return readUser()?.claims?.find((c) => c.claimType == claimType)?.claimValue;
};

export const getTypeUserFromClaim = (claims: ClaimModel[]) => {
  return claims.find(
    (c) => c.claimType == UserType.Master || c.claimType == UserType.ClientAdmin || c.claimType == UserType.UserFilial,
  )?.claimType;
};

export const persistFilter = (filter: string): void => {
  localStorage.setItem('filter', filter);
};

export const readFilter = (): string | null => {
  return localStorage.getItem('filter');
};

export const deleteFilter = (): void => {
  localStorage.removeItem('filter');
};

export const hasFilter = (): boolean => {
  const value = localStorage.getItem('filter');

  if (value !== null) {
    return true;
  }
  return false;
};

export const persistValue = (key: string, value: string): void => {
  localStorage.setItem(key, value);
};

export const readValue = (key: string): string | null => {
  return localStorage.getItem(key);
};

export const deleteValue = (key: string): void => {
  localStorage.removeItem(key);
};

export const hasValue = (key: string): boolean => {
  const value = localStorage.getItem(key);

  if (value !== null) {
    return true;
  }
  return false;
};
