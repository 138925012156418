import { Progress, Row } from 'antd';
import { ReactComponent as ClockIcon } from '@app/assets/icons/clockIcon.svg';
import { HeaderFullscreen } from '@app/components/header/components/HeaderFullscreen/HeaderFullscreen';
import Clock from '@app/components/common/Clock/Clock';
import * as S from './HeaderMonitoring.styles';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { DividerT } from '@app/components/common/divider/Divider';
import { DividerTitle } from '@app/components/common/divider/Divider.styles';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { IconCaminhaoBetoneiraEsquerda, IconFilter, IconHome } from '@app/assets/slump-icons';
import { useNavigate } from 'react-router-dom';
import { HeaderMonitoringModel } from '@app/domain/interfaces/monitoring/headerMonitoringModel';
import { hasAccessByRole } from '@app/controllers/accessController';
import ModalFilterHeaderMonitoring from './components/ModalFilterHeaderMonitoring';

interface HeaderMonitoringProps {
  responsiveColumnsLayout: boolean;
}
interface HeaderMonitoringProps {
  forceCollapse: boolean;
  setForceCollapse: (isCollapsed: boolean) => void;
  setHiddenSider: Dispatch<SetStateAction<boolean>>;
  hiddenSider: boolean;
}

const HeaderMonitoring: React.FC<HeaderMonitoringProps> = ({
  forceCollapse,
  setForceCollapse,
  responsiveColumnsLayout,
  setHiddenSider,
  hiddenSider,
}) => {
  const handlerClickFullScreen = () => {
    setForceCollapse(!!forceCollapse);
    setHiddenSider(!hiddenSider);
  };
  const navigate = useNavigate();
  const headerInfoMonitoring = useAppSelector((state) => state.monitoring.headerInfoMonitoring);
  const headerBranchSelected = useAppSelector((state) => state.monitoring.branchMonitoringSelected);
  const [header, setHeader] = useState<HeaderMonitoringModel>({} as HeaderMonitoringModel);
  const [modalFilterVisible, setModalFilterVisible] = useState<boolean>(false);

  useEffect(() => {
    setHeader(headerInfoMonitoring);
  }, [headerInfoMonitoring]);

  return (
    <>
      <ModalFilterHeaderMonitoring modalVisible={modalFilterVisible} setModalVisible={setModalFilterVisible} />
      <Row
        justify="space-between"
        align="middle"
        style={{ background: 'white', height: '5rem', padding: '0.5rem 2.5rem 0rem 1rem' }}
      >
        <Row>
          <S.DropdownHeader>
            <div style={{ marginLeft: '-1rem' }}>
              <span style={{ fontWeight: 'bolder', color: '#263668' }}>{headerBranchSelected.filial}</span>
            </div>
            <DividerT isDivider={false} />
            <div
              style={{
                marginLeft: '-2.5rem',
                cursor: 'pointer',
                display: 'flex',
                flexDirection: 'row',
                gap: '0.5rem',
                alignItems: 'center',
                justifyItems: 'center',
                color: '#263668',
              }}
              onClick={() => setModalFilterVisible(!modalFilterVisible)}
            >
              <IconFilter />
              <span style={{ color: '#263668', fontWeight: 'bold' }}>Filtro</span>
            </div>
          </S.DropdownHeader>
        </Row>
        <S.Column xl={12} xxl={15}>
          <Row align="middle" justify="center" gutter={[{ xxl: 6 }, { xxl: 9 }]}>
            <DividerT
              title={`${
                header?.aguaCentral ? Number.parseFloat(header.aguaCentral)?.toFixed(1)?.toString() : '-'
              } L/m³`}
              subtitle="Água Central"
              isDivider={true}
            />
            <DividerT title={`${header.inspecao} min`} subtitle="Inspeção" isDivider={false} />
            <DividerT title={`${header.produtividade} m³/h`} subtitle="Produtividade" isDivider={false} />
          </Row>
        </S.Column>
        <S.ConfigColumn $responsiveColumnsLayout={responsiveColumnsLayout}>
          <Row align="middle" justify="end" gutter={[{ xxl: 8 }, { xxl: 4 }]} style={{}}>
            <ClockIcon style={{ marginRight: '0.925rem' }} />
            <DividerTitle>
              <Clock />
            </DividerTitle>
            {hasAccessByRole('central_monitoring') && (
              <DividerT
                icon={
                  <div
                    onClick={() => navigate('/acompanhamento')}
                    style={{ color: '#263668', fontSize: '1.75rem', cursor: 'pointer' }}
                  >
                    <IconHome />
                  </div>
                }
              />
            )}
            {hasAccessByRole('mixer_monitoring') && (
              <DividerT
                icon={
                  <div
                    onClick={() => navigate('/monitoramento/betoneiras')}
                    style={{ color: '#263668', fontSize: '1.75rem', cursor: 'pointer' }}
                  >
                    <IconCaminhaoBetoneiraEsquerda />
                  </div>
                }
              />
            )}

            <DividerT
              icon={
                <div onClick={handlerClickFullScreen}>
                  <HeaderFullscreen />
                </div>
              }
            />
          </Row>
        </S.ConfigColumn>
      </Row>
      <Row style={{ padding: '0rem 2rem 0rem 1rem' }}>
        {/*<S.Progress percent={header.barraProgresso} showInfo={false} strokeColor="#159D3B">*/}
        {/*  <S.ProgressInnerText>Quero queroooooo</S.ProgressInnerText>*/}
        {/*</S.Progress>*/}
        <S.ProgressContainer style={{ width: header.barraProgresso + '%' }}>
          <Progress percent={100} showInfo={false} strokeColor="#159D3B" strokeWidth={16} />
          <S.ProgressInnerText color="white">
            {`${
              header.volumeExpedido
                ? Number(header?.volumeExpedido ?? 0)
                    .toFixed(1)
                    .toString()
                : '-'
            } M³`}
          </S.ProgressInnerText>
        </S.ProgressContainer>
        {header.barraProgresso < 100 && (
          <S.ProgressContainer style={{ width: 100 - header.barraProgresso + '%' }}>
            <Progress percent={0} showInfo={false} strokeColor="#159D3B" strokeWidth={16} />
            <S.ProgressInnerText color="black">
              {`${
                header.volumeTotal
                  ? Number(header?.volumeTotal ?? 0)
                      .toFixed(1)
                      .toString()
                  : '-'
              } M³`}
            </S.ProgressInnerText>
          </S.ProgressContainer>
        )}
      </Row>
    </>
  );
};

export default HeaderMonitoring;
