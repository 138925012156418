import * as S from './Flow.styles';

interface SlumpProps {
  slumpValue: number;
  targetSlump: number;
  targetTolerance: number;
}

const Flow: React.FC<SlumpProps> = ({ slumpValue, targetSlump, targetTolerance }) => {
  return (
    <S.FluxoContainer>
      <S.RulerScale>
        {Array.from(Array(20)).map((_, i) => (
          <S.RulerMark key={i} />
        ))}
      </S.RulerScale>
      <S.BoxContainer>
        <S.TargetRingOutter targetSlump={targetSlump} targetTolerance={targetTolerance} />
        <S.TargetRingInner targetSlump={targetSlump} targetTolerance={targetTolerance} />
        <S.CurrentCircle slumpValue={slumpValue} />
        <S.CurrentLineLeft slumpValue={slumpValue} />
        <S.CurrentLineRigh slumpValue={slumpValue} />
        <S.CurrentValue slumpValue={slumpValue} targetSlump={targetSlump} targetTolerance={targetTolerance}>
          {slumpValue.toFixed(1).toString().replace('.', ',')}
        </S.CurrentValue>
      </S.BoxContainer>
    </S.FluxoContainer>
  );
};

export default Flow;
