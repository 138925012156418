import { Button } from '@app/components/common/buttons/Button/Button.styles';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { Table } from '@app/components/common/Table/Table';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { setHeader } from '@app/store/slices/headerSlice';
import type { ColumnsType } from 'antd/lib/table';
import React, { useCallback, useEffect, useState } from 'react';
import { notificationController } from '@app/controllers/notificationController';
import { Col, Row } from 'antd';
import { Modal } from '@app/components/common/Modal/Modal';
import { Spinner } from '@app/components/common/Spinner/Spinner';
import DropdownTable from '@app/components/dropdown/DropdownTable';
import { IconMore } from '@app/assets/slump-icons';
import { Menu, MenuItem } from '@app/components/common/Menu/Menu';
import Dashboard from '@app/components/dashboard/Dashboard';
import { useNavigate } from 'react-router-dom';
import 'react-initials-avatar/lib/ReactInitialsAvatar.css';
import moment from 'moment';
import IFirmwareVersionService, { FirmwareVersionService } from '@app/services/firmwareVersionService';
import { FirmwareVersionModel } from '@app/domain/interfaces/firmware_version/firmwareVersionModel';
import { InfoCircleOutlined } from '@ant-design/icons';
import { hasAccessByPermission } from '@app/controllers/accessController';
import { genericExportToExcel } from '@app/utils/exportToExcel';

const firmwareVersionService: IFirmwareVersionService = new FirmwareVersionService();

const FirmwareVersionDashboard: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [firmwareVersion, setFirmwareVersion] = useState<FirmwareVersionModel>({} as FirmwareVersionModel);
  const [firmwareVersions, setFirmwareVersions] = useState<FirmwareVersionModel[]>();
  const [loading, setLoading] = useState(false);
  const [modalDeleteVisible, setModalDeleteVisible] = useState(false);
  const [modalEditVisible, setModalEditVisible] = useState(false);
  const [tableData, setTableData] = useState<FirmwareVersionModel[]>();

  const fetchFirmwareVersions = useCallback(async () => {
    setLoading(true);
    const response = await firmwareVersionService.getArray('');
    if (response) {
      setFirmwareVersions(response);
      setTableData(response);
    } else {
      notificationController.error({ message: 'Erro ao buscar verões de firmware' });
    }
    setLoading(false);
  }, []);

  const handleEditClick = useCallback(
    (firmwareVersion: FirmwareVersionModel) => {
      setFirmwareVersion(firmwareVersion);
      if (firmwareVersion.existeAgendamento) {
        return setModalEditVisible(true);
      }
      navigate(`/gestao/versao-firmware/${firmwareVersion.id}`);
    },
    [navigate],
  );

  const handleDeleteClick = useCallback((firmwareVersion: FirmwareVersionModel) => {
    setFirmwareVersion(firmwareVersion);
    setModalDeleteVisible(true);
  }, []);

  const handleExportToExcel = () => {
    genericExportToExcel(
      'Versões de firmware',
      columns,
      tableData?.map((v) => ({
        ...v,
        dataHora: v.dataHora ? moment(v.dataHora).format('DD/MM/YYYY HH:mm') : '',
      })) ?? [],
    );
  };

  const columns: ColumnsType<FirmwareVersionModel> = [
    {
      title: 'Módulos',
      dataIndex: 'tipo',
      showSorterTooltip: false,
      sorter: (a, b) => (a.tipo && b.tipo ? a.tipo.localeCompare(b.tipo) : 0),
      width: '20%',
      render: (tipo: string) => {
        return (
          <Row>
            <Col>{tipo}</Col>
          </Row>
        );
      },
    },
    {
      title: 'Versões firmware',
      dataIndex: 'versao',
      showSorterTooltip: false,
      sorter: (a, b) => (a.versao && b.versao ? a.versao.localeCompare(b.versao) : 0),
      width: '20%',
      render: (firmwareVersion: string) => <>{firmwareVersion}</>,
    },
    {
      title: 'Data/Hora',
      dataIndex: 'dataHora',
      showSorterTooltip: false,
      sorter: (a, b) =>
        a.dataHora && b.dataHora ? new Date(a.dataHora).getTime() - new Date(b.dataHora).getTime() : 0,
      width: '15%',
      render: (dataHora: string) => moment(dataHora).format('DD/MM/YYYY HH:mm') + 'h',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      sorter: (a, b) => (a.status && b.status ? a.status.localeCompare(b.status) : 0),
      showSorterTooltip: false,
      width: '3%',
    },
    {
      title: '',
      dataIndex: 'id',
      width: '10%',
      showSorterTooltip: false,
      sorter: (a, b) => (a?.id ? a.id : 0) - (b?.id ? b.id : 0),
      sortDirections: [],
      defaultSortOrder: 'descend',
      render: (_, firmwareVersion) => {
        const canEdit = hasAccessByPermission('firmware', 'U');
        const canDelete = hasAccessByPermission('firmware', 'D');
        return (
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end' }}>
            {(canEdit || canDelete) && (
              <DropdownTable
                buttonText=""
                iconD={
                  <div style={{ color: '#545454' }}>
                    <IconMore />
                  </div>
                }
                trigger={['click']}
                placement="bottomRight"
                overlay={
                  <Menu>
                    {canEdit && (
                      <MenuItem key="edit" onClick={() => handleEditClick(firmwareVersion)}>
                        <Button type="text">Editar</Button>
                      </MenuItem>
                    )}
                    {canDelete && firmwareVersion.status && (
                      <MenuItem key="delete" onClick={() => handleDeleteClick(firmwareVersion)}>
                        <Button type="text">Deletar</Button>
                      </MenuItem>
                    )}
                  </Menu>
                }
              ></DropdownTable>
            )}
          </div>
        );
      },
    },
  ];

  const handleSearchOnChange = (event: React.FormEvent<HTMLInputElement>) => {
    if (event.currentTarget.value.length > 0) {
      const inputValue = event.currentTarget.value.toLowerCase();
      setTableData(
        firmwareVersions?.filter(
          (item) => item.tipo?.toLowerCase().includes(inputValue) || item.versao?.toLowerCase().includes(inputValue),
        ),
      );
    } else {
      setTableData(firmwareVersions);
    }
  };

  const deleteFirmwareVersion = useCallback(() => {
    setLoading(true);
    firmwareVersionService
      .delete(`${firmwareVersion.id}`)
      .then(() => {
        notificationController.success({ message: 'Versão de firmware deletada com sucesso' });
        fetchFirmwareVersions();
        setModalDeleteVisible(false);
      })
      .catch(() => {
        notificationController.error({ message: 'Erro ao deletar versão de firmware' });
      })
      .finally(() => setLoading(false));
  }, [fetchFirmwareVersions, firmwareVersion.id]);

  useEffect(() => {
    fetchFirmwareVersions();
    dispatch(setHeader({ title: 'Versão' }));
  }, [dispatch, fetchFirmwareVersions]);

  return (
    <>
      <Modal
        title="Deletar versão"
        open={modalDeleteVisible}
        onOk={() => deleteFirmwareVersion()}
        onCancel={() => setModalDeleteVisible(false)}
        cancelText="Cancelar"
        okText="Confirmar"
      >
        <Row align="middle">
          <Col span={2}>
            <InfoCircleOutlined size={20} style={{ color: '#FAAD14' }} />
          </Col>
          <Col span={22}>
            {firmwareVersion.existeAgendamento ? (
              <span>
                Essa versão está sendo utilizada no agendamento, deseja realmente <strong> deletar essa versão </strong>
                selecionada?
              </span>
            ) : (
              <>Deseja realmente deletar a versão selecionada?</>
            )}
          </Col>
        </Row>
      </Modal>

      <Modal
        title="Editar versão"
        open={modalEditVisible}
        onOk={() => navigate(`/gestao/versao-firmware/${firmwareVersion.id}`)}
        onCancel={() => setModalEditVisible(false)}
        cancelText="Cancelar"
        okText="Confirmar"
      >
        <Row align="middle">
          <Col span={2}>
            <InfoCircleOutlined size={20} style={{ color: '#FAAD14' }} />
          </Col>
          <Col span={22}>
            <span>
              Essa versão está sendo utilizada no agendamento, deseja realmente <strong> editar essa versão </strong>
              selecionada?
            </span>
          </Col>
        </Row>
      </Modal>

      <PageTitle>Versão firmware</PageTitle>
      <Spinner spinning={loading}>
        <Dashboard
          title="Versão firmware"
          buttonText="Cadastrar nova versão"
          handleButtonClick={() => navigate('/gestao/versao-firmware/cadastrar')}
          placeholderTextSearch="Pesquisar por módulo, versão, data ou status"
          handleSearchOnChange={handleSearchOnChange}
          table={<Table columns={columns} dataSource={tableData} scroll={{ x: 800 }} bordered />}
          exportToExcel={handleExportToExcel}
        />
      </Spinner>
    </>
  );
};

export default FirmwareVersionDashboard;
