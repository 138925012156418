import { media } from '@app/styles/themes/constants';
import styled from 'styled-components';
import { ReactComponent as ImportedBellIcon } from '@app/assets/icons/notification_important.svg';
import { Spin } from 'antd';

export const CardCentralTracking = styled.div`
  display: flex;
  justify-content: space-around;
  font-family: 'Mulish';
  font-style: normal;
  width: auto;
  border-radius: 12px;
  padding: 1rem;
  height: 100%;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.08);
  background: #ffffff;
  color: #263668;
  border: 1px solid #d1d1d1;
  flex: 1;
  width: 100%;

  @media screen and (min-width: 768px) {
    max-width: 100%;
  }
`;

export const CardCentralTrackingSpin = styled(Spin)`
  span {
    color: #263668;
  }
`;

export const CardCentralTrackingInfoContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export const CardCentralTrackingSlumpContent = styled.div`
  border-left: 1px solid #d1d1d1;
  width: 0;
  margin: 0 0.5rem;
  min-height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const CardCentralTrackingPropertyIcon = styled.span`
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 1.5rem;
  color: #8c8c8c;
  text-transform: uppercase;
`;

export const CardCentralTrackingProperty = styled.span`
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #8c8c8c;
  text-transform: uppercase;
  margin-right: 0.5rem;
`;

export const CardCentralTrackingValue = styled.span`
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #263668;
  text-transform: uppercase;
`;

export const CardCentralTrackingTitle = styled.span`
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.875rem;
  color: #263668;
  text-transform: uppercase;
`;
export const CardCentralTrackingMixerMeters = styled.span`
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #263668;
  text-transform: uppercase;
  margin-left: 0.3rem;
`;

export const CardCentralTrackingSubtitle = styled.span`
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 1.5rem;
  color: #263668;
  text-transform: uppercase;
  margin-left: 0.2rem;
  margin-top: 0.2rem;
`;

export const CardCentralTrackingLabelInfoIcon = styled.span`
  font-size: small !important;
`;

export const CardCentralTrackingLabel = styled.span`
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #263668;
  text-transform: uppercase;
  text-align: center;
  margin-top: 0.125rem;
`;

export const NoContentTitle = styled.div`
  font-family: Mulish;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: #707070;
`;
export const NoContentDescription = styled.div`
  font-family: Mulish;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #545454;
`;

export const CardCentralTrackingCentralContent = styled.div`
  font-weight: 600;
  font-size: 28px;
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  justify-content: start;
  max-width: 450px;

  .ant-carousel {
    font-size: 28px;
    line-height: 34px;
  }
`;

export const CardCentralTrackingCentralIcon = styled.div`
  font-size: 1rem;
  width: 0.875rem;
  margin-top: 0.625rem;
  cursor: pointer;

  @media only screen and (${media.xs}) {
    font-size: 0.875rem;
    margin-top: 0.125rem;
  }

  @media only screen and (${media.sm}) {
    font-size: 0.875rem;
    margin-top: 0.125rem;
  }

  @media only screen and (${media.md}) {
    font-size: 1rem;
    margin-top: 0.625rem;
  }

  @media only screen and (${media.xxl}) {
    font-size: 1.25rem;
    margin-top: 0.625rem;
  }
`;

export const Divider = styled.div`
  border-left: 1px solid #d1d1d1;
  width: 0;
  margin: 0 0.5rem;
  min-height: 100%;
`;

export const SlumpFlowContent = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  padding-left: 0.875rem;
  padding-right: 0.5rem;
`;

export const SlumpFlowTitle = styled.span`
  color: var(--Brand-Colors-Blue-Blue500, #263668);
  font-family: Mulish;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;

  @media only screen and (${media.xs}) {
    font-size: 0.875rem;
    margin-top: 0.125rem;
  }

  @media only screen and (${media.md}) {
    font-family: 'Mulish';
    font-size: 0.875rem;
    flex-wrap: wrap;
    margin-top: 0.125rem;
  }

  @media only screen and (${media.sm}) {
    font-size: 0.875rem;
    margin-top: 0.125rem;
  }

  @media only screen and (${media.xxl}) {
    font-size: 1rem;
    margin-top: 0.125rem;
  }
`;

export const NotificationImportantRedIcon = styled(ImportedBellIcon)`
  color: #ff0000;
  width: 1rem;
  height: 1rem;
  margin-left: -0.25rem;
  cursor: pointer;
  path {
    fill: #a71916;
  }
`;
