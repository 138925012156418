import styled from 'styled-components';
import { Menu as AntMenu } from 'antd';
import { FONT_SIZE } from '@app/styles/themes/constants';

export const Menu = styled(AntMenu)`
  background: transparent;

  a {
    width: 100%;
    display: block;
  }

  .ant-menu-item {
    display: flex;
    align-items: center;
    justify-items: center;
    overflow: hidden;
  }

  .ant-menu-item,
  .ant-menu-submenu {
    font-size: ${FONT_SIZE.xs};
    margin-bottom: 1.5px;
    margin-top: 0px;
    overflow: hidden;
  }

  .ant-menu-inline .ant-menu-item {
    padding-left: 3.75rem !important;
  }

  .ant-menu-item-icon {
    min-width: 20px;
    width: 1.25rem;
  }

  .ant-menu-sub {
    background-color: #161a30;
    margin-top: -4px;
    margin-bottom: -4px;
  }

  .ant-menu-inline .ant-menu-item:not(:last-child),
  .ant-menu-vertical .ant-menu-item:not(:last-child),
  .ant-menu-vertical-left .ant-menu-item:not(:last-child),
  .ant-menu-vertical-right .ant-menu-item:not(:last-child) {
    margin-bottom: 0px;
  }

  .ant-menu-inline .ant-menu-item::after,
  .ant-menu-vertical .ant-menu-item::after,
  .ant-menu-vertical-left .ant-menu-item::after,
  .ant-menu-vertical-right .ant-menu-item::after {
    border: 0px;
  }

  .ant-menu-submenu-expand-icon,
  .ant-menu-submenu-arrow,
  span[role='img'],
  a,
  .ant-menu-item,
  .ant-menu-submenu {
    color: var(--text-sider-secondary-color);
    fill: var(--text-sider-secondary-color);
  }

  .ant-menu-item:hover,
  .ant-menu-submenu-title:hover {
    .ant-menu-submenu-expand-icon,
    .ant-menu-submenu-arrow,
    span[role='img'],
    a,
    .ant-menu-item-icon,
    .ant-menu-title-content {
      color: var(--text-secondary-color);
      fill: var(--text-secondary-color);
    }
  }

  .ant-menu-submenu-selected {
    .ant-menu-submenu-title {
      color: var(--text-secondary-color);
      .ant-menu-submenu-expand-icon,
      .ant-menu-submenu-arrow,
      span[role='img'] {
        color: var(--text-secondary-color);
        fill: var(--text-secondary-color);
      }
    }
  }

  .ant-menu-item-selected {
    background-color: #263668 !important;
    border-right: 6px solid #a71916;
    .ant-menu-submenu-expand-icon,
    .ant-menu-submenu-arrow,
    span[role='img'],
    .ant-menu-item-icon,
    a {
      color: var(--text-secondary-color);
      fill: var(--text-secondary-color);
    }
    ::after {
      border: 0px;
    }
  }

  .ant-menu-item-active,
  .ant-menu-submenu-active .ant-menu-submenu-title {
    background-color: #263668 !important;
  }

  .ant-menu-inline .ant-menu-item-active,
  .ant-menu-inline .ant-menu-item-content-active,
  .ant-menu-sub-active,
  .ant-menu-sub-title {
    background-color: #475582 !important;
  }

  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #475582 !important;
    margin-bottom: 0px;
  }
`;
