import { FONT_SIZE, media } from '@app/styles/themes/constants';
import styled from 'styled-components';

export const FooterListContainer = styled.div`
  display: flex;
  justify-content: start;
  margin-right: 1rem;
  white-space: nowrap;
  justify-content: center;
`;

export const FooterListWrapper = styled.div`
  display: flex;
  max-width: 5rem;
`;

export const FooterListItems = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
`;

export const FooterNumeroBT = styled.span`
  font-family: 'Mulish';
  font-size: 1.25rem;
  line-height: 1.5rem;
  font-weight: 700;
  text-transform: uppercase;
  color: #e8e8e8;

  margin-top: 0.125rem;

  @media only screen and (${media.xs}) {
    font-size: ${FONT_SIZE.md};
  }
  @media only screen and (${media.md}) {
    font-size: ${FONT_SIZE.lg};
    margin-top: 0.225rem;
  }
  @media only screen and (${media.xxl}) {
    font-size: 1.25rem;
    margin-top: 0.725rem;
  }
`;

export const FooterTime = styled.span`
  font-family: 'Mulish';
  font-size: ${FONT_SIZE.lg};
  line-height: 1.375rem;
  font-weight: 700;
  text-transform: uppercase;
  color: #b3c5d4;

  margin-top: 0.2rem;

  @media only screen and (${media.xs}) {
    font-size: ${FONT_SIZE.xs};
  }
  @media only screen and (${media.md}) {
    font-size: ${FONT_SIZE.md};
  }
  @media only screen and (${media.xxl}) {
    margin-top: 0.3rem;
    font-size: 1.125rem;
  }
`;
export const FooterDriver = styled.span`
  font-family: 'Mulish';
  font-size: ${FONT_SIZE.md};
  line-height: 1rem;
  font-weight: 700;
  text-transform: uppercase;
  color: #ffffff;

  margin-top: 0rem;

  @media only screen and (${media.xs}) {
    font-size: ${FONT_SIZE.xxs};
  }
  @media only screen and (${media.md}) {
    font-size: ${FONT_SIZE.xs};
  }
  @media only screen and (${media.xxl}) {
    margin-top: 0.3rem;
    font-size: 1rem;
  }
`;
