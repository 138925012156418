import { CheckCircleFilled, CloseCircleOutlined, ExclamationCircleOutlined, InfoCircleFilled } from '@ant-design/icons';
import { notification } from 'antd';
import { ArgsProps } from 'antd/lib/notification';
import React from 'react';
import styled from 'styled-components';

interface IconWrapperProps {
  $isOnlyTitle: boolean;
}

const notificationStyle: React.CSSProperties = {
  width: '24.688rem',
  height: '5.375rem',
  paddingTop: '1.125rem',
  paddingLeft: '1.125rem',
  paddingBottom: '1rem',
  paddingRight: '1rem',
};

const titleStyle: React.CSSProperties = {
  fontFamily: 'Mulish',
  fontStyle: 'normal',
  fontWeight: '600',
  fontSize: '1rem',
  lineHeight: '1.25rem',
  paddingTop: '0.3rem',
  marginLeft: '-0.75rem',
};

const descriptionStyle: React.CSSProperties = {
  fontFamily: 'Mulish',
  fontStyle: 'normal',
  fontWeight: '500',
  fontSize: '0.875rem',
  marginTop: '1rem',
  marginLeft: '-2.2rem',
  lineHeight: '1.125rem',
};

const IconWrapper = styled.div<IconWrapperProps>`
  font-size: ${(props) => (props.$isOnlyTitle ? '1rem' : '1rem')};
  line-height: 2rem;
`;

const EmptyDescription = styled.div`
  margin-top: -0.75rem;
`;

type NotificationProps = ArgsProps;

const openSuccessNotification = (config: NotificationProps): void => {
  notification.success({
    ...config,
    icon: (
      <IconWrapper $isOnlyTitle={!config.description}>
        <CheckCircleFilled style={{ color: '#3DB05D' }} />
      </IconWrapper>
    ),
    message: <div style={{ ...titleStyle, color: '#041F0C', marginTop: '0.825rem' }}>{config.message}</div>,
    description: config.description ? (
      <div
        style={{
          ...descriptionStyle,
          color: '#041F0C',
          marginTop: '0.825rem',
        }}
      >
        {config.description}
      </div>
    ) : (
      <EmptyDescription />
    ),
    style: { ...notificationStyle, borderColor: '#3DB05D', backgroundColor: '#DFFBE7' },
  });
};

const openInfoNotification = (config: NotificationProps): void => {
  notification.info({
    ...config,
    icon: (
      <IconWrapper $isOnlyTitle={!config.description}>
        <InfoCircleFilled style={{ color: '#263668' }} />
      </IconWrapper>
    ),
    message: <div style={{ ...titleStyle, color: '#080B15' }}>{config.message}</div>,
    description: config.description ? (
      <div style={{ ...descriptionStyle, color: '#080B15' }}>{config.description}</div>
    ) : (
      <EmptyDescription />
    ),
    style: { ...notificationStyle, borderColor: '#263668', backgroundColor: '#CAD2EC' },
  });
};

const openWarningNotification = (config: NotificationProps): void => {
  notification.warning({
    ...config,
    icon: (
      <IconWrapper $isOnlyTitle={!config.description}>
        <ExclamationCircleOutlined style={{ color: '#FBBD43' }} />
      </IconWrapper>
    ),
    message: <div style={{ ...titleStyle, color: '#322304' }}>{config.message}</div>,
    description: config.description ? (
      <div style={{ ...descriptionStyle, color: '#322304' }}>{config.description}</div>
    ) : (
      <EmptyDescription />
    ),
    style: { ...notificationStyle, borderColor: '#FBBD43', backgroundColor: '#FFF7E8' },
  });
};

const openErrorNotification = (config: NotificationProps): void => {
  notification.error({
    ...config,
    icon: (
      <IconWrapper $isOnlyTitle={!config.description}>
        <CloseCircleOutlined style={{ color: '#B84340' }} />
      </IconWrapper>
    ),
    message: <div style={{ ...titleStyle, color: '#210504' }}>{config.message}</div>,
    description: config.description ? (
      <div style={{ ...descriptionStyle, color: '#210504' }}>{config.description}</div>
    ) : (
      <EmptyDescription />
    ),
    style: { ...notificationStyle, borderColor: '#B84340', backgroundColor: '#FCE9E9' },
  });
};

export const notificationController = {
  success: openSuccessNotification,
  info: openInfoNotification,
  warning: openWarningNotification,
  error: openErrorNotification,
};
