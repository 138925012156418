export enum GroupByEnum {
  Hours = 0,
  Day = 1,
  Week = 2,
  Month = 3,
}

export enum GroupedChartEnum {
  Grouped = 0,
  Individual = 1,
}
