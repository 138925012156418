import { Button } from '@app/components/common/buttons/Button/Button';
import CardDeviceInformation from '@app/components/common/Card/CardDeviceInformation/CardDeviceInformation';
import { Drawer } from '@app/components/common/Drawer/Drawer';
import { DrawerSpan } from '@app/components/common/Drawer/Drawer.styles';
import { DrawerSuccess } from '@app/components/common/Drawer/DrawerSuccess/DrawerSuccess';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { BaseFormInputItem } from '@app/components/common/forms/components/BaseFormInputItem/BaseFormInputItem';
import { notificationController } from '@app/controllers/notificationController';
import { ClientModel } from '@app/domain/interfaces/clientModel';
import { ConcreteMixerModel } from '@app/domain/interfaces/concreteMixerModel';
import { DeviceModel } from '@app/domain/interfaces/device/deviceModel';
import IClientService, { ClientService } from '@app/services/clientService';
import IConcreteMixerService, { ConcreteMixerService } from '@app/services/concreteMixerService';
import IDeviceService, { DeviceService } from '@app/services/deviceService';
import { isUserMaster } from '@app/services/localStorage.service';
import { Col, Row, Select, Spin } from 'antd';
import { useEffect, useState } from 'react';

interface DrawerLinkConcreteMixerProps {
  device: DeviceModel;
  setDevice: (value: DeviceModel | ((prevVar: DeviceModel) => DeviceModel)) => void;
  visible: boolean;
  onClose: (finish: boolean) => void;
  setUpdateTable: (value: boolean | ((prevVar: boolean) => boolean)) => void;
}

const deviceService: IDeviceService = new DeviceService();
const clientService: IClientService = new ClientService();
const concreteMixerService: IConcreteMixerService = new ConcreteMixerService();

const DrawerLinkConcreteMixer: React.FC<DrawerLinkConcreteMixerProps> = ({
  device,
  visible,
  onClose,
  setDevice,
  setUpdateTable,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [drawerSuccessVisible, setDrawerSuccessVisible] = useState(false);
  const [clients, setClients] = useState<ClientModel[]>([]);
  const [concreteMixers, setConcreteMixers] = useState<ConcreteMixerModel[]>([]);

  const fetchClients = () => {
    setIsLoading(true);

    clientService
      .getArray('obter')
      .then((res: ClientModel[]) => {
        setClients(res);
        setIsLoading(false);
      })
      .catch((error) => {
        notificationController.error(error);
        setIsLoading(false);
      });
  };
  const fetchConcreteMixers = (id: number) => {
    setIsLoading(true);

    concreteMixerService
      .getArray('obter')
      .then((res: ConcreteMixerModel[]) => {
        setConcreteMixers(res.filter((mixer) => mixer.idCliente === id && !mixer.deviceId));
        setIsLoading(false);
      })
      .catch((error) => {
        notificationController.error(error);
        setIsLoading(false);
      });
  };

  const handleChangeMixer = (id: number) => {
    setDevice({ ...device, idBetoneira: id });
  };
  const handleCloseDrawer = () => {
    setDrawerSuccessVisible(false);
    onClose(false);
    setIsLoading(false);
  };
  const handleSaveDevice = () => {
    setIsLoading(true);

    deviceService.api
      .put('vincularBetoneira', { idBetoneira: device.idBetoneira, idDispositivo: device.id })
      .then(() => {
        setDrawerSuccessVisible(true);
        setUpdateTable(true);
        setIsLoading(false);
        setTimeout(() => {
          handleCloseDrawer();
        }, 1700);
      })
      .catch((error) => {
        notificationController.error({ message: `Erro ao atualizar o dispositivo, ${error}` });
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (visible && device.idCliente) {
      fetchClients();
      fetchConcreteMixers(device.idCliente);
    } else {
      handleCloseDrawer();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  return (
    <>
      <Drawer
        onClose={() => onClose(false)}
        title="Vincular dispositivo"
        placement="right"
        open={visible}
        footer={
          <Row gutter={16} style={{ width: '100%' }}>
            <Col span={12}>
              <Button onClick={async () => handleCloseDrawer()} type="ghost" block>
                Cancelar
              </Button>
            </Col>
            <Col span={12}>
              <Button onClick={() => handleSaveDevice()} type="primary" block disabled={!device.idCliente}>
                Vincular
              </Button>
            </Col>
          </Row>
        }
        textSpan=""
        style={{ overflowX: 'hidden' }}
      >
        <Spin spinning={isLoading}>
          <BaseForm layout="vertical">
            <CardDeviceInformation device={device}></CardDeviceInformation>
            {isUserMaster() && (
              <>
                <DrawerSpan style={{ marginTop: '2rem', marginBottom: '1rem' }}>
                  Vincule o cliente ao dispositivo
                </DrawerSpan>
                <BaseFormInputItem label="Pesquisar cliente" supportText="Opcional">
                  <Select
                    showArrow
                    showSearch
                    style={{ width: 330 }}
                    placeholder="Selecione por razão social"
                    disabled={true}
                    value={device.idCliente && device.idCliente != 0 ? device.idCliente : undefined}
                    options={clients.map((c) => ({ value: c.id, label: c.razaoSocial }))}
                    filterOption={(input, option) =>
                      (option?.label ?? '').toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  />
                </BaseFormInputItem>
              </>
            )}
            <DrawerSpan style={{ marginTop: '2rem', marginBottom: '1rem' }}>
              Vincule o n° da BT ou placa do caminhão do cliente ao dispositivo
            </DrawerSpan>
            <BaseFormInputItem label="Pesquisar n° da BT ou placa do caminhão" supportText="Opcional">
              <Select
                showArrow
                showSearch
                style={{ width: 330 }}
                placeholder="Selecione o n° da BT ou placa do caminhão"
                disabled={!device.idCliente}
                value={device.idBetoneira == 0 ? undefined : device.idBetoneira}
                onChange={(value) => handleChangeMixer(value as number)}
                options={concreteMixers.map((c) => ({ value: c.id, label: `${c.numeroBT} - ${c.placaCaminhao}` }))}
                filterOption={(input, option) => (option?.label ?? '').toLowerCase().indexOf(input.toLowerCase()) >= 0}
              />
            </BaseFormInputItem>
          </BaseForm>
        </Spin>
      </Drawer>
      <DrawerSuccess
        text={`Dispositivo ${device.id ? 'atualizado' : 'inserido'} com sucesso`}
        title={`${device.id ? 'Atualizar' : 'Inserir'} dispositivo`}
        placement="right"
        open={drawerSuccessVisible}
        style={{ overflowX: 'hidden' }}
      />
    </>
  );
};

export default DrawerLinkConcreteMixer;
