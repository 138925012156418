import React from 'react';
import * as S from './References.styles';

export const References: React.FC = () => {
  return (
    <S.ReferencesWrapper>
      <S.Text style={{ color: 'var(--primary-color)' }}>
        © {new Date().getFullYear()} SlumpMix, concreto inteligente -{' '}
      </S.Text>
      <S.Text>Todos os direitos reservados.</S.Text>
    </S.ReferencesWrapper>
  );
};
