import { ReactNode } from 'react';
import * as S from './PageContainer.styles';

type PageContainerProps = {
  children: ReactNode;
};

export const PageContainer = ({ children }: PageContainerProps) => {
  return (
    <>
      <S.Container>{children}</S.Container>
    </>
  );
};
