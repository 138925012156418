import styled from 'styled-components';
import { Drawer as AntDrawer } from 'antd';

export const DrawerSpan = styled.span`
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.75rem;
  margin-top: 1.5rem;
  display: flex;
  flex-wrap: wrap;
  width: 75%;
  align-items: center;
  text-align: center;
  color: #263668;
  flex: none;
  order: 0;
  flex-grow: 0;
`;

export const Drawer = styled(AntDrawer)`
  .ant-drawer-content {
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }

  .ant-drawer-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .ant-drawer-header-title {
    height: 4.75rem;
    display: flex;

    &::before {
      content: url('../../../assets/white-small-logo.svg');
      background: url('../../../assets/white-small-logo.svg');
      width: 20px;
      height: 20px;
    }
  }

  .ant-drawer-title {
    font-size: 1.5rem;
    line-height: 1.75rem;
    font-family: 'Mulish';
    gap: 2rem;
    margin-left: 3rem;
    min-width: 14rem;
  }

  .ant-drawer-close {
    margin-left: 19.2rem;
    margin-right: 2.01125rem;
    position: absolute;

    &:hover {
      color: #fff;
    }
  }

  .ant-drawer-extra {
    margin-right: 20rem;
  }

  .ant-row {
    margin-bottom: -0.75rem;
  }

  .ant-drawer-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;
