import React from 'react';
import ApexCharts from 'apexcharts';
import { useEffect } from 'react';
import moment from 'moment';
import {
  getDifferenceBetweenDatesInHours,
  getDifferenceBetweenDatesInMinutes,
  renderViagemEstadoTexto,
} from '@app/utils/utils';
import { IChartReport, ITooltipConfig } from './interface/chartReport';
import MouseTracker from '@app/components/common/MouseTracker/MouseTracker';
import { getTooltipConfig } from '@app/pages/modules/Reports/Charts/getTooltipConfig';
import { HelperNotification } from '@app/components/common/HelperNotification/HelperNotification';

export const ChartSlump: React.FC<IChartReport> = ({ travelDetails }) => {
  const [tooltipConfig, setTooltipConfig] = React.useState<ITooltipConfig>({
    visible: false,
    title: '',
    value: '',
  });
  useEffect(() => {
    if (travelDetails.coletaSlump?.coletaCentral && travelDetails.coletaSlump?.coletaCentral.length > 0) {
      const optionsSlump = {
        chart: {
          id: 'slump',
          type: 'line',
          group: 'chart',
          width: '100%',
          height: 500,
          toolbar: {
            show: false,
          },
        },
        legend: {
          show: true,
          showForSingleSeries: true,
          position: 'top',
          horizontalAlign: 'right',
          fontSize: '14px',
          fontFamily: 'Mulish',
          fontWeight: 600,
          fontStyle: 'normal',
          markers: {
            width: 24,
            height: 5,
            radius: 0,
          },
        },
        tooltip: {
          style: {
            fontFamily: 'Mulish',
          },
        },
        grid: {
          padding: {
            left: 20,
          },
        },
        colors: ['#707070', '#707070', '#374450'],
        series: [
          {
            type: 'line',
            name: 'Linha 1 - Slump Central (cm)',
            data: travelDetails.coletaSlump.coletaCentral
              ?.filter((s) => s.centimetros && s.centimetros >= 0)
              ?.map((s) => ({ y: s.centimetros, x: new Date(s.dataHora ?? 0).getTime() }))
              .sort((a, b) => b.x - a.x),
            markers: {
              size: 5,
              strokeWidth: 0,
              hover: {
                size: 8,
              },
            },
          },
          {
            type: 'line',
            name: 'Linha 2 - Slump Obra (cm)',
            data: travelDetails.coletaSlump.coletaObra
              ?.filter((s) => s.centimetros && s.centimetros >= 0)
              ?.map((s) => ({ y: s.centimetros, x: new Date(s.dataHora ?? 0).getTime() }))
              .sort((a, b) => b.x - a.x),
            markers: {
              size: 5,
              strokeWidth: 0,
              hover: {
                size: 8,
              },
            },
          },
          {
            type: 'line',
            name: 'Tempos da viagem',
            data: travelDetails.temposViagemConsumo
              ?.map((u) => ({ y: 0, x: u.dataHora ? new Date(u.dataHora).getTime() : 0 }))
              .sort((a, b) => b.x - a.x),
            markers: {
              size: 5,
              shape: 'square',
              // strokeWidth: 0,
              hover: {
                size: 8,
              },
            },
          },
        ],
        yaxis: {
          min: travelDetails?.slumpAlvoMin && travelDetails?.slumpAlvoMin < 30 ? 0 : 30,
          max:
            travelDetails?.slumpAlvoMax && travelDetails?.slumpAlvoMax < 30
              ? 30
              : (travelDetails.slumpAlvoMax ?? 90) + 10,
          labels: {
            style: {
              fontSize: '16px',
              fontFamily: 'Mulish',
              fontWeight: 600,
            },
            formatter: (value: number) => value?.toFixed(1),
          },
        },
        xaxis: {
          min: new Date(travelDetails.estadosViagem[1]?.dataHora ?? 0).getTime(),
          max: new Date(travelDetails.estadosViagem[travelDetails.estadosViagem.length - 1]?.dataHora ?? 0).getTime(),
          type: 'datetime',
          tickAmount: 15,
          labels: {
            datetimeUTC: true,
            formatter: (value: number | number[]) =>
              typeof value == 'number' ? moment(value).format('HH:mm') : moment(value[0]).format('HH:mm'),
          },
          tooltip: {
            enabled: false,
          },
        },
        stroke: {
          width: [2, 2],
        },
        annotations: {
          yaxis: [
            {
              y: travelDetails.slumpAlvoMin,
              y2: travelDetails.slumpAlvoMax,
              strokeDashArray: 0,
              borderWidth: 50,
              borderColor: '#023611',
              fillColor: '#117e2f26',
              label: {
                style: {
                  fontSize: '10px',
                  fontFamily: 'Mulish',
                  color: '#333',
                  background: '#117e2f26',
                },
                text: 'Slump Alvo',
              },
            },
          ],
          xaxis: travelDetails.estadosViagem
            ?.filter((estadoViagem) => estadoViagem.estadoViagemAtual && estadoViagem.estadoViagemAtual > 2)
            .map((estado, index) => {
              return {
                x: estado.dataHora ? new Date(estado.dataHora).getTime() : 0,
                strokeDashArray: 0,
                borderColor: '#5EB576',
                label: {
                  position:
                    index < travelDetails.alertas.length - 2 &&
                    estado.estadoViagemAtual !== 1 &&
                    getDifferenceBetweenDatesInMinutes(
                      new Date(estado.dataHora ?? 0),
                      new Date(travelDetails.alertas[index + 2]?.dataHora ?? 0),
                    ) <= 5 &&
                    getDifferenceBetweenDatesInHours(
                      new Date(estado.dataHora ?? 0),
                      new Date(travelDetails.alertas[index + 2]?.dataHora ?? 0),
                    ) < 1
                      ? 'top'
                      : 'top',
                  orientation: 'vertical',
                  style: {
                    color: '#707070',
                    background: '#fff',
                    fontSize: '16px',
                    offsetX: 0,
                    offsetY: -30,
                    padding: {
                      left: 20,
                      right: 10,
                      top: 5,
                      bottom: 5,
                    },
                  },
                  text: renderViagemEstadoTexto(estado.estadoViagemAtual ?? 0),
                },
              };
            }),
          points: travelDetails.temposViagemConsumo?.map((tempoViagem) => {
            return {
              x: tempoViagem.dataHoraEntreEstados ? new Date(tempoViagem.dataHoraEntreEstados).getTime() : 0,
              y: 0,
              marker: {
                size: 9,
                shape: 'square',
                fillColor: '#374450',
                strokeColor: '#374450',
                tooltip: {
                  enabled: true,
                },
              },
              mouseEnter: () => {
                setTooltipConfig(getTooltipConfig(tempoViagem));
              },
              mouseLeave: () => {
                setTooltipConfig({
                  title: '',
                  value: '',
                  visible: false,
                });
              },
            };
          }),
        },
      };
      const chartSlump = new ApexCharts(document.querySelector('#chart_slump'), optionsSlump);
      chartSlump.render();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!(travelDetails.coletaSlump?.coletaCentral?.length && travelDetails.coletaSlump?.coletaCentral?.length > 0) ? (
        <h3 style={{ paddingLeft: '1rem' }}>Nenhum dado disponível para exibição.</h3>
      ) : (
        <HelperNotification>
          Os dados apresentados neste gráfico representam uma sugestão do slump observado e estão sujeitos a variações.
        </HelperNotification>
      )}
      {tooltipConfig.visible && (
        <MouseTracker offset={{ x: 0, y: 0 }}>
          <div className="tooltip">
            <span className="square"></span>
            <span className="text-label">{tooltipConfig.title}</span>
            <span className="text-value">{tooltipConfig.value}</span>
          </div>
        </MouseTracker>
      )}
      <div id="chart_slump"></div>
    </>
  );
};
