import ApexCharts from 'apexcharts';
import { HelperNotification } from '@app/components/common/HelperNotification/HelperNotification';
import { CentralTrackingShippingSlumpModel } from '@app/domain/interfaces/centralTracking/CentralTrackingModel';
import moment from 'moment';
import { FC, useEffect, useState } from 'react';
import MouseTracker from '@app/components/common/MouseTracker/MouseTracker';
import { ITooltipConfig } from '@app/pages/modules/Reports/Charts/interface/chartReport';
import { getDifferenceBetweenDatesInMinutes, minutesToHoursAndMinutes } from '@app/utils/utils';

interface SlumpTableChartProps {
  idTravel: number;
  slumpAlvoMin: number;
  slumpAlvoMax: number;
  datetimeStart: Date;
  datetimeEnd: Date;
  slumpHistory: CentralTrackingShippingSlumpModel[];
}

export const SlumpTableChart: FC<SlumpTableChartProps> = ({
  idTravel,
  slumpAlvoMin,
  slumpAlvoMax,
  slumpHistory,
  datetimeStart,
  datetimeEnd,
}) => {
  const [tooltipConfig, setTooltipConfig] = useState<ITooltipConfig>({
    visible: false,
    title: 'Tempo',
    value: '',
  });
  useEffect(() => {
    const start = new Date(datetimeStart);
    const end = new Date(datetimeEnd);
    const midpoint = new Date((start.getTime() + end.getTime()) / 2);

    if (slumpHistory?.length > 0) {
      const optionsSlump = {
        chart: {
          id: 'slump',
          type: 'line',
          group: 'chart',
          width: '100%',
          height: 300,
          toolbar: {
            show: false,
          },
        },
        legend: {
          show: true,
          showForSingleSeries: true,
          position: 'top',
          horizontalAlign: 'right',
          fontSize: '14px',
          fontFamily: 'Mulish',
          fontWeight: 600,
          fontStyle: 'normal',
          markers: {
            width: 24,
            height: 5,
            radius: 0,
          },
        },
        tooltip: {
          style: {
            fontFamily: 'Mulish',
          },
        },
        grid: {
          padding: {
            left: 40,
            right: 40,
          },
        },
        series: [
          {
            type: 'line',
            name: 'Linha 1 - Slump (cm)',
            color: '#374450',
            data: [
              ...slumpHistory
                ?.filter((s) => s.slump && s.slump >= 0)
                ?.map((s) => ({ y: s.slump, x: new Date(s.dataHora ?? 0).getTime() })),
              { y: slumpHistory[0].slump, x: new Date(datetimeStart).getTime() },
              { y: slumpHistory[slumpHistory.length - 1].slump, x: new Date(datetimeEnd).getTime() },
            ].sort((a, b) => b.x - a.x),
            markers: {
              size: 5,
              strokeWidth: 0,
              hover: {
                size: 8,
              },
            },
          },
          {
            name: 'Slump alvo',
            data: [],
            color: '#FCCE72B2',
          },
          {
            name: 'Tempos da viagem',
            data: [],
            color: '#374450',
          },
        ],
        yaxis: {
          min: slumpAlvoMin && slumpAlvoMin < 30 ? 0 : 30,
          max: slumpAlvoMax && slumpAlvoMax < 30 ? 30 : (slumpAlvoMax ?? 90) + 10,
          labels: {
            style: {
              fontSize: '16px',
              fontFamily: 'Mulish',
              fontWeight: 600,
            },
            formatter: (value: number) => value?.toFixed(1),
          },
        },
        xaxis: {
          min: new Date(datetimeStart).getTime(),
          max: new Date(datetimeEnd).getTime(),
          type: 'datetime',
          tickAmount: 15,
          labels: {
            datetimeUTC: true,
            formatter: (value: number | number[]) =>
              typeof value == 'number' ? moment(value).format('HH:mm') : moment(value[0]).format('HH:mm'),
          },
          tooltip: {
            enabled: false,
          },
        },
        stroke: {
          width: [2],
        },
        annotations: {
          yaxis: [
            {
              y: slumpAlvoMin,
              y2: slumpAlvoMax,
              strokeDashArray: 0,
              borderWidth: 50,
              borderColor: '#FAAD14',
              fillColor: '#FCCE72B2',
            },
          ],
          xaxis: [
            {
              x: new Date(datetimeStart).getTime(),
              strokeDashArray: 0,
              borderColor: '#5EB576',
              label: {
                position: 'top',
                orientation: 'vertical',
                style: {
                  color: '#707070',
                  background: '#fff',
                  fontSize: '16px',
                  offsetX: 0,
                  offsetY: -30,
                  padding: {
                    left: 20,
                    right: 10,
                    top: 5,
                    bottom: 5,
                  },
                },
                text: 'Chegada Remessa',
              },
            },
            {
              x: new Date(datetimeEnd).getTime(),
              strokeDashArray: 0,
              borderColor: '#5EB576',
              label: {
                position: 'top',
                orientation: 'vertical',
                style: {
                  color: '#707070',
                  background: '#fff',
                  fontSize: '16px',
                  offsetX: 0,
                  offsetY: -30,
                  padding: {
                    left: 20,
                    right: 10,
                    top: 5,
                    bottom: 5,
                  },
                },
                text: 'Saída da central',
              },
            },
          ],
          points: [
            {
              x: midpoint.getTime(),
              y: 0,
              marker: {
                size: 9,
                shape: 'square',
                fillColor: '#374450',
                strokeColor: '#374450',
                tooltip: {
                  enabled: true,
                },
              },
              mouseEnter: () => {
                setTooltipConfig({
                  title: 'Tempo',
                  value: minutesToHoursAndMinutes(getDifferenceBetweenDatesInMinutes(start, end)),
                  visible: true,
                });
              },
              mouseLeave: () => {
                setTooltipConfig({
                  title: 'Tempo',
                  value: '',
                  visible: false,
                });
              },
            },
          ],
        },
      };
      const chartSlump = new ApexCharts(document.querySelector(`#chart_slump_${idTravel}`), optionsSlump);
      chartSlump.render();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!(slumpHistory?.length > 0) && <h3 style={{ paddingLeft: '1rem' }}>Nenhum dado disponível para exibição.</h3>}
      {tooltipConfig.visible && (
        <MouseTracker offset={{ x: 0, y: 0 }}>
          <div className="tooltip">
            <span className="square"></span>
            <span className="text-label">{tooltipConfig.title}</span>
            <span className="text-value">{tooltipConfig.value}</span>
          </div>
        </MouseTracker>
      )}
      <HelperNotification>
        Os dados apresentados neste gráfico representam uma sugestão do slump observado e estão sujeitos a variações.
      </HelperNotification>
      <div id={`chart_slump_${idTravel}`}></div>
    </>
  );
};
