import { ApiServiceBase, IApiServiceBase } from './apiServiceBase';
import { RegionalGroupModel } from '@app/domain/interfaces/regional/regionalModel';

export default interface IRegionalService extends IApiServiceBase<RegionalGroupModel, RegionalGroupModel> {}

export class RegionalService
  extends ApiServiceBase<RegionalGroupModel, RegionalGroupModel>
  implements IRegionalService
{
  constructor() {
    super('admin/regionais');
  }
}
