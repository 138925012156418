import { UserModel } from '@app/domain/interfaces/user/userModel';
import { ApiServiceBase, IApiServiceBase } from './apiServiceBase';

export default interface IUserBranchService extends IApiServiceBase<UserModel, UserModel> {
  // activeDeactive(model: ActiveDeactiveUser): Promise<AxiosResponse>;
  // getByUsername(endpoint: string, username: string): Promise<UserModel>;
}

export class UserBranchService extends ApiServiceBase<UserModel, UserModel> implements IUserBranchService {
  constructor() {
    super('admin/usuarioFilial');
  }

  // async activeDeactive(model: ActiveDeactiveUser): Promise<AxiosResponse> {
  //   const response = await this.api.post('ativarDesativarUsuario', model);
  //   return response.data;
  // }

  // async getByUsername(endpoint: string, username: string): Promise<UserModel> {
  //   try {
  //     const response = await this.api.get(`${endpoint}/${username}`);
  //     return response?.data;
  //   } catch (error) {
  //     throw error;
  //   }
  // }
}
