import * as React from 'react';
const SvgIconReportChart = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21.3333 21.8333H21.8333V21.3333V2.66667V2.16667H21.3333H2.66667H2.16667V2.66667V21.3333V21.8333H2.66667H21.3333ZM2.66667 0.5H21.3333C22.5239 0.5 23.5 1.47614 23.5 2.66667V21.3333C23.5 22.5239 22.5239 23.5 21.3333 23.5H2.66667C1.47614 23.5 0.5 22.5239 0.5 21.3333V2.66667C0.5 1.47614 1.47614 0.5 2.66667 0.5ZM5.83333 9.83333H7.5V18.1667H5.83333V9.83333ZM11.1667 5.83333H12.8333V18.1667H11.1667V5.83333ZM16.5 13.8333H18.1667V18.1667H16.5V13.8333Z"
      fill="current"
      stroke="current"
    />
  </svg>
);

export default SvgIconReportChart;
