import styled from 'styled-components';
import { Collapse as AntdCollapse } from 'antd';

export const Container = styled.div`
  margin: 0 1.5rem;
  padding: 0 1.5rem;
`;

export const TitleContainer = styled.div`
  display: flex;
  height: 8rem;
  //justify-content: space-between;
  align-items: center;
  //background-color: blue;
  //margin-bottom: 1.5rem;
`;

export const Title = styled.h1`
  width: 100%;
  text-align: center;
  padding-right: 300px; // 128px is the width of the IconSupermix
  text-transform: uppercase;
  font-family: Mulish;
  font-size: 16px;
  font-weight: 700;
  line-height: 20.08px;
  color: black;
`;

export const CollapseContainer = styled.div`
  && .ant-collapse-item {
    border: none;
  }

  box-sizing: border-box;
  width: 100%;
  border: 0.063rem solid var(--disabled-bg-color);
  border-radius: 0.5rem;
  flex: none;
  order: 0;
  flex-grow: 1;
  padding: 1.5rem;
`;

export const Collapse = styled(AntdCollapse)`
  background-color: white;
  width: 100%;
`;

export const DisplayContainer = styled.div``;

export const ImageContainer = styled.div`
  box-sizing: border-box;
  width: 100%;
  flex: none;
  order: 0;
  flex-grow: 0;
  padding: 1.5rem;
  margin-bottom: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DeviceCardInfoProperty = styled.div`
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 600;
  font-size: 0.8rem;
  line-height: 1.563rem;
  color: var(--input-placeholder-color);
`;

export const DeviceCardInfoValue = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 600;
  font-size: 0.9rem;
  line-height: 1.563rem;
  color: var(--text-label);
  margin-top: -0.1;
  overflow-wrap: break-word;
`;
