import { ConcreteMixerModel } from '@app/domain/interfaces/concreteMixerModel';
import {
  BranchModel,
  BranchMonitoringModel,
  RegionalModel,
} from '@app/domain/interfaces/monitoring/branchMonitoringModel';
import { FooterMonitoringModel } from '@app/domain/interfaces/monitoring/footerMonitoringModels';
import { HeaderMonitoringModel } from '@app/domain/interfaces/monitoring/headerMonitoringModel';
import { PrepareAction, createAction, createSlice } from '@reduxjs/toolkit';

export interface IMonitoringSlice {
  headerInfoMonitoring: HeaderMonitoringModel;
  branchsMonitoring: RegionalModel[];
  branchMonitoringSelected: BranchModel;
  footer: FooterMonitoringModel;
  mixer?: ConcreteMixerModel;
}

const initialState: IMonitoringSlice = {
  headerInfoMonitoring: {
    aguaCentral: '0',
    inspecao: '0',
    produtividade: '0',
    barraProgresso: 0,
    idFilial: 0,
    volumeExpedido: 0,
    volumeTotal: 0,
  },
  branchsMonitoring: [],
  branchMonitoringSelected: {
    filial: '',
    idFilial: 0,
    pontosDeCarga: [],
  },
  footer: {
    id: 0,
    idFilial: 0,
    betoneirasRetornando: [],
    betoneirasDisponiveis: [],
  },
};

export const setMixerMonitoring = createAction<PrepareAction<ConcreteMixerModel>>(
  'monitoring/setMixerMonitoring',
  (mixer: ConcreteMixerModel) => {
    return {
      payload: mixer,
    };
  },
);
export const setHeaderInfoMonitoring = createAction<PrepareAction<HeaderMonitoringModel>>(
  'monitoring/setHeaderInfoMonitoring',
  (header: HeaderMonitoringModel) => {
    return {
      payload: header,
    };
  },
);
export const setHeaderBranchsMonitoring = createAction<PrepareAction<RegionalModel[]>>(
  'monitoring/setHeaderBranchsMonitoring',
  (branchs: RegionalModel[]) => {
    return {
      payload: branchs,
    };
  },
);
export const setHeaderBranchSelected = createAction<PrepareAction<BranchModel>>(
  'monitoring/setHeaderBranchSelected',
  (branch: BranchModel) => {
    return {
      payload: branch,
    };
  },
);

export const setFooter = createAction<PrepareAction<FooterMonitoringModel>>('monitoring/setFooter', (newFooter) => {
  return {
    payload: newFooter,
  };
});

export const monitoringSlice = createSlice({
  name: 'monitoring',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setHeaderInfoMonitoring, (state, action) => {
      state.headerInfoMonitoring = action.payload;
    });

    builder.addCase(setHeaderBranchsMonitoring, (state, action) => {
      state.branchsMonitoring = action.payload;
    });

    builder.addCase(setHeaderBranchSelected, (state, action) => {
      state.branchMonitoringSelected = action.payload;
    });

    builder.addCase(setFooter, (state, action) => {
      state.footer = action.payload;
    });

    builder.addCase(setMixerMonitoring, (state, action) => {
      state.mixer = action.payload;
    });
  },
});

export default monitoringSlice.reducer;
