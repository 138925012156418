import { Col, Row } from 'antd';
import ApexCharts from 'apexcharts';
import { FC, useEffect, useState } from 'react';
import * as S from './WaterPieChart.styles';
import './WaterPieChart.styles.css';

interface WaterPieChartProps {
  normalWaterQuantity: number;
  warningWaterQuantity: number;
  dangerWaterQuantity: number;
  errorWaterQuantity: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleChartFilter: (indexClicked: number) => void;
}

export const WaterPieChart: FC<WaterPieChartProps> = (data) => {
  const [justRender, setJustRender] = useState<boolean>(false);
  useEffect(() => {
    if (data.normalWaterQuantity || data.warningWaterQuantity || data.dangerWaterQuantity || data.errorWaterQuantity) {
      const sum =
        data.normalWaterQuantity + data.warningWaterQuantity + data.dangerWaterQuantity + data.errorWaterQuantity;
      const normalWaterPercent = (data.normalWaterQuantity ?? 1 / sum) * 100;
      const warningWaterPercent = (data.warningWaterQuantity ?? 1 / sum) * 100;
      const dangerWaterPercent = (data.dangerWaterQuantity ?? 1 / sum) * 100;
      const errorWaterPercent = (data.errorWaterQuantity ?? 1 / sum) * 100;

      const options = {
        chart: {
          type: 'pie',
          width: '100%',
          height: 500,
          toolbar: {
            show: false,
          },
          events: {
            dataPointSelection: (event: MouseEvent, context: any, config: any) =>
              config.selectedDataPoints[0].length == 0
                ? data.handleChartFilter(0)
                : data.handleChartFilter(config.dataPointIndex + 1),
          },
        },
        tooltip: {
          enabled: false,
        },
        series: [normalWaterPercent, warningWaterPercent, dangerWaterPercent, errorWaterPercent],
        labels: [
          data.normalWaterQuantity,
          data.warningWaterQuantity,
          data.dangerWaterQuantity,
          data.errorWaterQuantity,
        ],
        colors: ['#096DD9', '#FAAD14', '#C41B24', '#6B25C6'],
        legend: {
          position: 'right',
          showForSingleSeries: true,
          horizontalAlign: 'center',
          fontSize: '14px',
          fontFamily: 'Mulish',
          fontWeight: 600,
          fontStyle: 'normal',
          markers: {
            width: 32,
            height: 20,
            radius: 2,
          },
        },
        dataLabels: {
          enabled: false,
        },
      };

      const chart = new ApexCharts(document.querySelector('#water_pie_chart'), options);

      if (!justRender) chart.render();

      setJustRender(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  return (
    <>
      <Row gutter={[8, 8]} style={{ width: '100%' }} justify={'space-between'}>
        <Col>
          <S.SubtitleRow>
            <S.SubtitleRectangle color="#096DD9"></S.SubtitleRectangle>
            <S.Subtitle>água central menor ou igual 30% da água retida</S.Subtitle>
          </S.SubtitleRow>
        </Col>
        <Col>
          <S.SubtitleRow>
            <S.SubtitleRectangle color="#FAAD14"></S.SubtitleRectangle>
            <S.Subtitle>água central entre 30%-70% da água retida</S.Subtitle>
          </S.SubtitleRow>
        </Col>
        <Col>
          <S.SubtitleRow>
            <S.SubtitleRectangle color="#C41B24"></S.SubtitleRectangle>
            <S.Subtitle>água central maior ou igual que 70% da água retida</S.Subtitle>
          </S.SubtitleRow>
        </Col>
        <Col>
          <S.SubtitleRow>
            <S.SubtitleRectangle color="#6B25C6"></S.SubtitleRectangle>
            <S.Subtitle>outros eventos</S.Subtitle>
          </S.SubtitleRow>
        </Col>
      </Row>
      <Row justify={'center'} style={{ width: '100%', marginTop: '2.5rem', marginBottom: '1.5rem' }}>
        <Col>
          <div id="water_pie_chart"></div>
        </Col>
      </Row>
    </>
  );
};
