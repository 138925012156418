import styled from 'styled-components';
import BgConcreto from '@app/assets/images/bg-concreto.png';

const height = 130;
const width = 168;

interface SlumpProps {
  slumpValue: number;
}

interface SlumpGoalProps {
  targetSlump: number;
  targetTolerance: number;
}

const convertSlumpToDiameter = (slumpValue: number) => {
  // slumpValue = 30 -> diameter = 60
  // slumpValue = 100 -> diameter = 120
  // slumpValue = x -> diameter = ?
  return slumpValue + 20;
};

const getSlumpColor = (slumpValue: number, targetSlump: number, targetTolerance: number) => {
  if (slumpValue >= targetSlump - targetTolerance && slumpValue <= targetSlump + targetTolerance) {
    return '#13c413';
  }
  return '#fff';
};

export const FluxoContainer = styled.div`
  width: ${width}px;
  height: ${height}px;
  position: relative;
`;

export const BoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  width: 100%;
  height: 100%;
  z-index: 1;
  border: 1px solid #d1d1d1;
  box-shadow: 0 0 0 2px rgba(178, 178, 178);
`;

export const RulerScale = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  width: ${width + 10}px;
  height: 10px;
  background-color: rgba(163, 163, 163, 0.6);
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
  z-index: 3;
`;

export const RulerMark = styled.div`
  width: 2px;
  height: calc(9px / 2);
  background-color: #383838;
  z-index: 3;
`;

export const TargetRingOutter = styled.div<SlumpGoalProps>`
  position: absolute;
  width: calc(${(props) => convertSlumpToDiameter(props.targetSlump + props.targetTolerance)}px - 1px);
  height: calc(${(props) => convertSlumpToDiameter(props.targetSlump + props.targetTolerance)}px - 1px);
  border-radius: 50%;
  box-shadow: 0 0 0 2px rgba(178, 178, 178);
  box-sizing: border-box;
  z-index: 2;
`;

export const TargetRingInner = styled.div<SlumpGoalProps>`
  position: absolute;
  width: calc(${(props) => convertSlumpToDiameter(props.targetSlump - props.targetTolerance)}px - 3px);
  height: calc(${(props) => convertSlumpToDiameter(props.targetSlump - props.targetTolerance)}px - 3px);
  border-radius: 50%;
  box-shadow: 0 0 0 2px rgba(178, 178, 178);
  box-sizing: border-box;
  z-index: 2;
`;

export const CurrentCircle = styled.div<SlumpProps>`
  position: absolute;
  width: ${(props) => convertSlumpToDiameter(props.slumpValue)}px;
  height: ${(props) => convertSlumpToDiameter(props.slumpValue)}px;
  /* background-color: #707070; */
  background-image: ${({}) => `url(${BgConcreto})`};
  background-size: cover;
  background-repeat: repeat;
  //background-color: blue;
  // background-color: ${(props) => getSlumpColor(props.slumpValue, 50, 5)};
  border-radius: 50%;
  z-index: 1;
  transition: width 1s ease-in-out;
  transition: height 1s ease-in-out;
`;

export const CurrentValue = styled.div<SlumpProps & SlumpGoalProps>`
  position: absolute;
  top: 43px;
  z-index: 3;
  color: ${(props) => getSlumpColor(props.slumpValue, props.targetSlump, props.targetTolerance)};
  font-family: Mulish;
  font-size: 10px;
  font-weight: 700;
  line-height: 12.55px;
  text-align: left;
`;

export const CurrentLineLeft = styled.div<SlumpProps>`
  position: absolute;
  bottom: 50%;
  //bottom: calc(${(props) => convertSlumpToDiameter(props.slumpValue)}px - 1px);
  height: 70px;
  width: 1px;
  left: calc(50% - ${(props) => convertSlumpToDiameter(props.slumpValue) / 2}px);
  background-image: linear-gradient(0deg, #383838, #383838 50%, transparent 50%, transparent 100%);
  background-size: 1px 10px;
  border: none;
  z-index: 3;
  transition: left 1s ease-in-out;
`;

export const CurrentLineRigh = styled.div<SlumpProps>`
  position: absolute;
  bottom: 50%;
  //bottom: calc(${(props) => convertSlumpToDiameter(props.slumpValue)}px - 1px);
  height: 70px;
  width: 1px;
  left: calc(50% + ${(props) => convertSlumpToDiameter(props.slumpValue) / 2 - 1}px);
  background-image: linear-gradient(0deg, #383838, #383838 50%, transparent 50%, transparent 100%);
  background-size: 1px 8px;
  border: none;
  z-index: 3;
  transition: left 1s ease-in-out;
`;
