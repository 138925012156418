/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Description } from '@app/components/common/Description/Description';
import { Spinner } from '@app/components/common/Spinner/Spinner';
import { TabReports } from '@app/components/common/TabsReports/TabsReports';
import { Toolbar } from '@app/components/common/Toolbar/Toolbar';
import { GroupByEnum } from '@app/constants/enums/chart';
import { TypeReport } from '@app/constants/enums/report';
import { reportTypes } from '@app/constants/reportTypes';
import { Col, Row } from 'antd';
import ApexCharts from 'apexcharts';
import React, { useEffect, useState } from 'react';
import DatetimeChart from './DatetimeChart';
import { GraphContainer, SecondGraphContainer } from './ReportComponent.styles';

export interface ISeriesDataGroupedChart {
  y: any;
  x: any;
  z?: any;
}

export interface ISeriesDataChildGroupedChart {
  xAxisParent: any;
  series: ISeriesDataGroupedChart[];
}

interface IGroupedChart {
  chartId: string;
  name: string;
  type: TypeReport;
  seriesData: ISeriesDataGroupedChart[];
  seriesDataChild: ISeriesDataChildGroupedChart[];
  colors: string[];
  negativeColor?: string;
  rangeDays: number;
  typeSelecteds: string[];
  xAxisChildOpen?: any;
  handleOpenReportDetailed?: (groupedBy: GroupByEnum, datesClicked: string, valueClicked: string) => void;
}

const GroupedChart: React.FC<IGroupedChart> = (groupedChartProps) => {
  const [loading, setLoading] = useState(false);
  const [isCreated, setIsCreated] = useState(false);
  const [openChartChild, setOpenChartChild] = useState(false);
  const [xAxisArrowPosition, setXAxisArrowPosition] = useState(0);
  const [xAxisSelected, setXAxisSelected] = useState('');
  const [xAxisLastSelected, setXAxisLastSelected] = useState('');
  const [barClicked, setBarClicked] = useState(false);
  const [activeTabGroupByDate, setActiveTabGroupByDate] = useState<GroupByEnum>(GroupByEnum.Day);
  const report = reportTypes.find((report) => report.type == groupedChartProps.type);

  const handleChangeTypeSelected = async (value: GroupByEnum) => {
    setLoading(true);
    setTimeout(() => {
      setActiveTabGroupByDate(value);
      setLoading(false);
    }, 200);
  };

  const handleOpenChartChild = (event: MouseEvent, xAxysClicked: any) => {
    const container = document.getElementById(`${groupedChartProps.chartId}`);

    if (container) {
      setXAxisArrowPosition(event.clientX - container?.getBoundingClientRect().x);
    }

    setXAxisSelected(groupedChartProps.seriesData[xAxysClicked].x);
    setBarClicked(true);
  };

  const renderOptions = () => {
    // Add negative color when the value is negative
    const modifiedSeriesData = groupedChartProps.seriesData.map((dataPoint) => {
      if (dataPoint.y < 0)
        return {
          ...dataPoint,
          fillColor: groupedChartProps.negativeColor,
        };
      return dataPoint;
    });

    return {
      chart: {
        animations: {
          enabled: false,
        },
        id: groupedChartProps.chartId,
        type: 'bar',
        width: '100%',
        height: 500,
        stacked: true,
        toolbar: {
          show: false,
        },
        events: {
          dataPointSelection: (event: MouseEvent, _: any, config: any) =>
            handleOpenChartChild(event, config.dataPointIndex),
          xAxisLabelClick: (event: MouseEvent, _: any, config: any) => handleOpenChartChild(event, config.labelIndex),
        },
      },
      states: {
        normal: {
          filter: {
            type: 'desaturate',
          },
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: 'darken',
            value: 1,
          },
        },
      },
      fill: {
        opacity: 1,
      },
      plotOptions: {
        bar: {
          horizontal: false,
        },
      },
      grid: {
        strokeDashArray: 0.5,
      },
      series: [{ name: groupedChartProps.name, data: modifiedSeriesData }],
      colors: groupedChartProps.colors,
      legend: {
        show: true,
        showForSingleSeries: true,
        position: 'top',
        horizontalAlign: 'right',
        fontSize: '14px',
        fontFamily: 'Mulish',
        fontWeight: 600,
        fontStyle: 'normal',
        markers: {
          width: 16,
          height: 18,
          radius: 0,
        },
      },
      tooltip: {
        style: {
          fontSize: '14px',
          fontFamily: 'Mulish',
          fontWeight: 600,
        },
        x: {
          formatter: (value: string) => value,
        },
        z: {
          title: 'Viagens: ',
        },
      },
      yaxis: {
        forceNiceScale: true,
        annotations: {
          yaxis: [
            {
              y: 0,
            },
          ],
        },
        labels: {
          style: {
            fontSize: '12px',
            fontFamily: 'Mulish',
            fontWeight: 600,
          },
          formatter: (value: number) => value?.toFixed(1),
        },
      },
      xaxis: {
        type: 'category',
        tooltip: {
          enabled: false,
        },
        labels: {
          rotate: -66,
          rotateAlways: true,
          trim: true,
          style: {
            colors: '#707070',
            fontSize: '14px',
            fontFamily: 'Mulish',
            fontWeight: 600,
          },
        },
        style: {
          fontSize: '12px',
          fontFamily: 'Mulish',
          fontWeight: 600,
        },
        categories: groupedChartProps.seriesData.map((item) => item.x),
      },
    };
  };

  useEffect(() => {
    if (!barClicked) return;

    if (xAxisLastSelected === xAxisSelected) {
      setOpenChartChild(false);
      setXAxisLastSelected('');
    } else {
      setOpenChartChild(true);
      setXAxisLastSelected(xAxisSelected);
    }

    setBarClicked(false);
  }, [barClicked]);

  useEffect(() => {
    if (groupedChartProps.xAxisChildOpen) {
      setXAxisSelected(groupedChartProps.xAxisChildOpen);
      setXAxisArrowPosition(0);
      setOpenChartChild(true);
    } else {
      setOpenChartChild(false);
    }
  }, [groupedChartProps.xAxisChildOpen]);

  useEffect(() => {
    if (isCreated) {
      ApexCharts.exec(groupedChartProps.chartId, 'updateOptions', renderOptions(), true, true);
    }
  }, [groupedChartProps.seriesData]);

  useEffect(() => {
    const chart = new ApexCharts(document.getElementById(groupedChartProps.chartId), renderOptions());
    chart.render();

    setIsCreated(true);

    return () => {
      chart.destroy();
    };
  }, []);

  return (
    <>
      <div
        style={{ padding: '2rem', pageBreakInside: 'avoid' }}
        className="chart-print"
        id={groupedChartProps.chartId}
      ></div>
      {openChartChild && xAxisArrowPosition > 0 && <GraphContainer position={xAxisArrowPosition} />}
      <SecondGraphContainer id={`${groupedChartProps.chartId}-child-chart-container`} visible={openChartChild}>
        <>
          <Spinner spinning={loading}>
            <Description
              title={`${xAxisSelected}`}
              subtitle={`Análise detalhada do consumo de ${groupedChartProps.name}`}
            >
              <Row gutter={18}>
                <Col xs={24} md={6}>
                  <Toolbar title="Selecione o tipo de visualização">
                    {groupedChartProps.rangeDays < 31 && (
                      <TabReports
                        key="day"
                        onClick={() => handleChangeTypeSelected(GroupByEnum.Day)}
                        dateTime={activeTabGroupByDate === GroupByEnum.Day ? 'day' : undefined}
                        title="Dia"
                      />
                    )}
                    {groupedChartProps.rangeDays < 120 && (
                      <TabReports
                        key="week"
                        onClick={() => handleChangeTypeSelected(GroupByEnum.Week)}
                        dateTime={activeTabGroupByDate === GroupByEnum.Week ? 'week' : undefined}
                        title="Semana"
                      />
                    )}
                    <TabReports
                      key="month"
                      onClick={() => handleChangeTypeSelected(GroupByEnum.Month)}
                      dateTime={activeTabGroupByDate === GroupByEnum.Month ? 'month' : undefined}
                      title="Mês"
                    />
                  </Toolbar>
                </Col>
              </Row>
            </Description>
            <DatetimeChart
              handleOpenReportDetailed={groupedChartProps.handleOpenReportDetailed}
              chartId={`${groupedChartProps.chartId}-child-chart`}
              colors={report?.children.map((reportType) => reportType.color) ?? ['#6D89B1']}
              groupBy={activeTabGroupByDate}
              isSecondGraph={true}
              type="column"
              seriesData={[
                {
                  type: 'bar',
                  name: groupedChartProps.name,
                  group: 'other',
                  data: groupedChartProps?.seriesDataChild?.filter((s) => s.xAxisParent == xAxisSelected)[0]?.series,
                },
              ]}
            />
          </Spinner>
        </>
      </SecondGraphContainer>
    </>
  );
};

export default GroupedChart;
