/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import { CheckOutlined, InfoCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button } from '@app/components/common/buttons/Button/Button';
import { Checkbox } from '@app/components/common/Checkbox/Checkbox';
import { Description } from '@app/components/common/Description/Description';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { BaseFormInputItem } from '@app/components/common/forms/components/BaseFormInputItem/BaseFormInputItem';
import { TextArea } from '@app/components/common/inputs/Input/Input';
import { Input } from '@app/components/common/inputs/Input/Input.styles';
import { List } from '@app/components/common/List/List';
import { Modal } from '@app/components/common/Modal/Modal';
import { PageContainer } from '@app/components/common/PageContainer/PageContainer';
import { Select } from '@app/components/common/selects/Select/Select';
import { Spinner } from '@app/components/common/Spinner/Spinner';
import { notificationController } from '@app/controllers/notificationController';
import { ConfigAlertModel, Justificativa } from '@app/domain/interfaces/configAlertModel';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { useMounted } from '@app/hooks/useMounted';
import IConfigAlertService, { ConfigAlertService } from '@app/services/configAlertService';
import { setFooter } from '@app/store/slices/footerPageSlice';
import { setHeaderRegisterPage } from '@app/store/slices/headerRegisterPage';
import { Col, Row } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as MoreIcon } from '@app/assets/icons/moreIcon.svg';
import DropdownTable from '@app/components/dropdown/DropdownTable';
import { Menu, MenuItem } from '@app/components/common/Menu/Menu';
import { ModalAlert } from '@app/components/common/ModalAlert/ModalAlert';
import { InputTag } from '@app/components/common/inputs/InputTag/InputTag';
import { TagJustification } from '@app/components/common/TagsList/TagJustification/TagJustification';
import { UserModel } from '@app/domain/interfaces/user/userModel';
import { ClientModel } from '@app/domain/interfaces/clientModel';
import { ClientBranchModel } from '@app/domain/interfaces/client_branch/clientBranchModel';
import IClientBranchService, { ClientBranchService } from '@app/services/clientBranchService';
import { isUserMaster } from '@app/services/localStorage.service';
import IClientService, { ClientService } from '@app/services/clientService';
import { IUserFilterSessionModel } from '@app/constants/interfaces/Session/IUserFilterSessionModel';
import IUserService, { UserService } from '@app/services/userService';
import IUserBranchService, { UserBranchService } from '@app/services/userBranchService';

const configAlertService: IConfigAlertService = new ConfigAlertService();
const clientBranchService: IClientBranchService = new ClientBranchService();
const clientService: IClientService = new ClientService();
const userService: IUserService = new UserService();
const userBranchService: IUserBranchService = new UserBranchService();

const ConfigAlertCreate: React.FC = () => {
  const { isMounted } = useMounted();
  const [configAlert, setConfigAlert] = useState<ConfigAlertModel | null>(null);
  const { id } = useParams();
  const [tableData, setTableData] = useState<{ data: ConfigAlertModel[]; loading: boolean }>({
    data: [],
    loading: true,
  });
  const [chaveAlertaValida, setChaveAlertaValida] = useState<boolean | undefined>(undefined);
  const [chaveError, setChaveError] = useState('');
  const [tituloValido, setTituloValido] = useState<boolean | undefined>(undefined);
  const [categoriaValida, setCategoriaValida] = useState<boolean | undefined>(undefined);
  const [justificar, setJustificar] = useState<boolean>(false);
  const [justificativasTag, setJustificativasTag] = useState<Justificativa[]>([]);
  const [justificativaTag, setJustificativaTag] = useState<string>('');
  const [explicar, setExplicar] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [modalCancel, setModalCancel] = useState(false);
  const [modelJustifications, setModelJustifications] = useState(false);
  const [clients, setClients] = useState<ClientModel[]>([]);
  const [selectClient, setSelectClient] = useState<number | null>(null);
  const [selectBranch, setSelectBranch] = useState<number | null>(null);
  const [branches, setBranches] = useState<ClientBranchModel[]>([]);
  const [filter, setFilter] = useState({} as IUserFilterSessionModel);
  const [users, setUsers] = useState<UserModel[]>([]);
  const [clientValida, setClientValida] = useState<boolean | undefined>(undefined);
  const [branchValida, setBranchValida] = useState<boolean | undefined>(undefined);

  const handleClientChange = (id: number) => {
    setSelectClient(id);
    setSelectBranch(null);
    setClientValida(true);
    setConfigAlert({ ...configAlert, idCliente: id });
  };

  const handleBranchChange = (id: number) => {
    setSelectBranch(id);
    setBranchValida(true);
    setConfigAlert({ ...configAlert, idFilial: id });
  };

  const userLogado = useAppSelector((state) => state.user.user);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const fetchConfigAlert = async () => {
    if (id !== null && id !== undefined) {
      setLoading(true);
      const response = await configAlertService.get(`obter/${id}`);
      setConfigAlert(response);
      setSelectClient(response.idCliente && response.idCliente > 0 ? response.idCliente : null);
      setSelectBranch(response.idFilial && response.idFilial > 0 ? response.idFilial : null);
      setJustificar(response.justificativas !== undefined && response.justificativas.length > 0);
      setJustificativasTag(response.justificativas ?? []);
      setLoading(false);
    }
  };

  // useEffect(() => {
  //   const idString = sessionStorage.getItem('chaveAlerta');
  //   if (idString !== null && idString !== undefined) {
  //     const idInt = parseInt(idString);
  //     const configAlertEdit = configAlerts.map((e) => {
  //       if (e.id === idInt) {
  //         return e;
  //       }
  //     });

  //     if (configAlertEdit.length > 0 && configAlertEdit[0] !== undefined) {
  //       setConfigAlert(configAlertEdit[0]);
  //     }
  //   }
  // }, [configAlerts]);

  const fetchCombosData = useCallback(async (idClient: number | null | undefined) => {
    try {
      setLoading(true);

      if (isUserMaster()) {
        const resUsers = await userService.getArray(`obterPorIdCliente/${idClient}`);
        setUsers(resUsers);
      }

      const resBranches = await clientBranchService.getArray(idClient!.toString());
      setBranches(resBranches);

      setLoading(false);
    } catch (error: any) {
      notificationController.error(error);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (selectClient) {
      fetchCombosData(selectClient);
      setFilter({ ...filter, group: undefined, branch: undefined, idUserSimulation: undefined });
    }
  }, [selectClient]);

  useEffect(() => {
    fetchClients();
  }, []);

  useEffect(() => {
    fetchConfigAlert();
    sessionStorage.removeItem('justificativas');
  }, []);

  useEffect(() => {
    sessionStorage.setItem('justificativas', JSON.stringify(justificativasTag));
  }, [justificativasTag]);

  useEffect(() => {
    if (!isUserMaster()) {
      const idUser = userLogado?.idCliente;
      fetchCombosData(idUser);
      setSelectClient(idUser ?? 0);
    }
  }, [clients]);

  const cleanForm = () => {
    setConfigAlert({
      titulo: '',
      categoria: '',
      justificativas: [],
    });
    setChaveAlertaValida(undefined);
    setTituloValido(undefined);
    setCategoriaValida(undefined);
    setJustificativasTag([]);
    setJustificar(false);
    setExplicar(false);
  };

  const handleChaveAlertaOnChange = async (e: React.FormEvent<HTMLInputElement>) => {
    const x = e.currentTarget.value.replace(/\D/g, '').match(/(\d{0,4})/);

    if (x !== null) {
      e.currentTarget.value = !x[2] ? x[1] : x[1] + '.' + x[2];
      setConfigAlert({ ...configAlert, chaveAlerta: Number.parseInt(e.currentTarget.value) });

      if (e.currentTarget.value.length === 4) {
        if (
          tableData.data.find((c: ConfigAlertModel) => (c.chaveAlerta === configAlert ? configAlert.chaveAlerta : ''))
        ) {
          setChaveAlertaValida(false);
          setChaveError('Chave já cadastrada.');
        } else {
          setChaveAlertaValida(true);
          setChaveError('');
        }
      } else {
        setChaveAlertaValida(undefined);
        setChaveError('');
      }
    }
  };

  const handleTituloOnChange = (e: React.FormEvent<HTMLInputElement>) => {
    if (e.currentTarget.value.length >= 3) {
      setTituloValido(true);
    } else {
      setTituloValido(undefined);
    }

    setConfigAlert({ ...configAlert, titulo: e.currentTarget.value });
  };

  const handleCategoryOnChange = (e: any, { label }: any) => {
    setCategoriaValida(true);

    setConfigAlert({ ...configAlert, categoria: label });
  };

  const handleExplicarOnChange = () => {
    setExplicar(!explicar);
  };

  function isEmptyOrSpaces(str: string) {
    return str === null || str.match(/^ *$/) !== null;
  }

  const handleCreateAndEditConfigAlerta = () => {
    setLoading(true);
    const justificativasSession = sessionStorage.getItem('justificativas');
    configAlertService
      .post('inserir', {
        categoria: configAlert ? configAlert.categoria : '',
        descricao: configAlert ? configAlert.descricao : '',
        titulo: configAlert ? configAlert.titulo : '',
        chaveAlerta: configAlert ? configAlert.chaveAlerta : 0,
        justificativas: justificativasSession ? JSON.parse(justificativasSession) : [],
        explicar: explicar,
        justificar: justificar,
        status: configAlert ? configAlert.status : 0,
        id: configAlert ? configAlert.id : 0,
        idCliente: selectClient ?? 0,
        idFilial: selectBranch ?? 0,
      })
      .then(() => {
        handleBack();
        setTimeout(() => {
          notificationController.success({
            message: !id ? 'Alerta cadastrado com sucesso!' : 'Alerta atualizado com sucesso!',
          });
        }, 1700);
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
        notificationController.error({
          message: !id ? 'Erro ao cadastrar alertas, tente novamente.' : 'Erro ao editar alertas, tente novamente!',
        });
      });
  };

  const handleJustificativasOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && !isEmptyOrSpaces(e.currentTarget.value)) {
      setJustificativasTag([...justificativasTag, { justificativa: e.currentTarget.value }]);
      setJustificativaTag('');
    }
  };

  const handleEditTagOnChange = (index: number, tag: Justificativa) => {
    setJustificativasTag(justificativasTag.map((j, i) => (index === i ? tag : j)));
  };

  const handleSalveTags = () => {
    setJustificar(justificativasTag.length > 0);
    setModelJustifications(false);
  };

  const handleDeleteTag = (remove: any) => {
    const removeTags = justificativasTag.filter((tag) => tag.justificativa !== remove);
    setJustificativasTag(removeTags);
  };

  const fetchClients = useCallback(() => {
    clientService
      .getArray('obter')
      .then((res: ClientModel[]) => {
        setClients(res);
      })
      .catch((error) => {
        notificationController.error(error);
      });
  }, []);

  useEffect(() => {
    if (isMounted.current) {
      dispatch(
        setHeaderRegisterPage({
          title: !id ? 'Novo alerta' : 'Editar alerta',
          handleBackClick: handleBack,
        }),
      );
      dispatch(
        setFooter({
          confirmButtonText: id ? 'Salvar' : 'Cadastrar',
          handleConfirmButtonClick: handleCreateAndEditConfigAlerta,
          cancelButtonText: 'Cancelar',
          handleCancelButtonClick: () => setModalCancel(true),
        }),
      );
    }
  }, [configAlert]);

  useEffect(() => {
    if (filter.branch?.id) {
      setUsersByBranch(filter.branch.id);
      setFilter({ ...filter, idUserSimulation: undefined });
    }
  }, [filter.branch]);

  const setUsersByBranch = async (branchId: number) => {
    setLoading(true);
    const resUsers = await userBranchService.getArray(`obterUsuariosPorFilial/${branchId}`);
    setUsers(resUsers);
    setLoading(false);
  };

  const handleBack = () => {
    navigate('/configuracao/alertas');
  };

  const categorias = [
    {
      value: 1,
      label: 'Operação',
    },
    {
      value: 2,
      label: 'Manutenção',
    },
    {
      value: 3,
      label: 'Tecnologia',
    },
    {
      value: 4,
      label: 'Engenharia',
    },
  ];

  return (
    <>
      <Modal
        title={`Cancelar ${!id ? 'cadastro' : 'edição'}`}
        open={modalCancel}
        onOk={() => navigate('/configuracao/alertas')}
        onCancel={() => setModalCancel(false)}
        cancelText="Cancelar"
        okText="Confirmar"
      >
        <Row align="middle">
          <Col span={2}>
            <InfoCircleOutlined size={20} style={{ color: '#FAAD14' }} />
          </Col>
          <Col span={22}>Deseja realmente cancelar {!id ? 'o cadastro' : 'a edição'} do alerta?</Col>
        </Row>
      </Modal>
      <ModalAlert
        title="Adicionar ou editar motivo"
        open={modelJustifications}
        onOk={handleSalveTags}
        onCancel={() => setModelJustifications(false)}
        cancelText="Cancelar"
        okText="Salvar"
      >
        <BaseForm>
          <Row gutter={18}>
            <Col xs={24} md={24}>
              <BaseFormInputItem
                label="Adicionar opções de motivo"
                supportText={`Pressione a tecla enter ou clique no botão incluir motivo`}
              >
                <Input
                  placeholder="Digite o motivo"
                  maxLength={40}
                  style={{ width: '47rem' }}
                  value={justificativaTag}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setJustificativaTag(e.currentTarget.value)}
                  onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => handleJustificativasOnKeyDown(e)}
                />
              </BaseFormInputItem>
            </Col>
          </Row>
          <Row justify="end" align="middle">
            <Col>
              <Button
                type="primary"
                onClick={() => {
                  setJustificativasTag([...justificativasTag, { justificativa: justificativaTag }]);
                  setJustificativaTag('');
                }}
              >
                Adicionar motivo
              </Button>
            </Col>
          </Row>
          {justificativasTag.length > 0 ? (
            <BaseForm>
              <Row align="middle">
                <Col
                  xs={24}
                  md={24}
                  style={{
                    fontSize: '1rem',
                    fontWeight: 600,
                    color: 'var(--text-label)',
                    marginTop: '1rem',
                    marginBottom: '1rem',
                  }}
                >
                  Motivos adicionados
                </Col>
                {justificativasTag.map((tag, i) => (
                  <>
                    {tag.editTag ? (
                      <Col xs={24} md={12}>
                        <InputTag
                          maxLength={45}
                          onChange={(e) => handleEditTagOnChange(i, { ...tag, justificativa: e.currentTarget.value })}
                          onPressEnter={() => handleEditTagOnChange(i, { ...tag, editTag: false })}
                          ref={(input) => input && input.focus()}
                          placeholder={tag.justificativa}
                          value={tag.justificativa}
                          suffix={
                            <CheckOutlined
                              style={{ cursor: 'pointer', marginRight: '0.5rem' }}
                              onClick={() => handleEditTagOnChange(i, { ...tag, editTag: false })}
                            />
                          }
                          bordered={true}
                          style={{ cursor: 'end', marginRight: '0.875rem' }}
                        />
                      </Col>
                    ) : (
                      <Col xs={24} md={12}>
                        <TagJustification
                          style={{ marginRight: '0.875rem' }}
                          icon={
                            <>
                              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end' }}>
                                <DropdownTable
                                  buttonText=""
                                  trigger={['click']}
                                  iconD={<MoreIcon />}
                                  placement="bottomRight"
                                  overlay={
                                    <Menu>
                                      <MenuItem key={0}>
                                        <Button
                                          type="text"
                                          onClick={() => handleEditTagOnChange(i, { ...tag, editTag: true })}
                                        >
                                          Editar
                                        </Button>
                                      </MenuItem>
                                      <MenuItem>
                                        <Button type="text" onClick={() => handleDeleteTag(tag.justificativa)}>
                                          Excluir
                                        </Button>
                                      </MenuItem>
                                    </Menu>
                                  }
                                ></DropdownTable>
                              </div>
                            </>
                          }
                          title={tag.justificativa}
                        />
                      </Col>
                    )}
                  </>
                ))}
              </Row>
            </BaseForm>
          ) : (
            <>
              <Row
                align="middle"
                justify="center"
                style={{
                  marginTop: '3.875rem',
                  fontWeight: 700,
                  fontSize: '1.125rem',
                  color: 'var(--input-placeholder-color)',
                }}
              >
                <Col>Nenhum motivo cadastrado</Col>
              </Row>
              <Row align="middle" justify="center" style={{ marginBottom: '3rem', color: 'var(--text-label)' }}>
                <Col>Adicione uma justificativa para continuar</Col>
              </Row>
            </>
          )}
        </BaseForm>
      </ModalAlert>
      <PageContainer>
        <BaseForm layout="vertical" style={{ width: '100%' }}>
          <Spinner spinning={loading}>
            <Description title="Informações " subtitle="Preencha os campos para cadastrar um novo alerta">
              <Row gutter={18}>
                <Col xs={24} md={8}>
                  <BaseFormInputItem
                    label="Chave"
                    errorText={chaveError.length > 0 ? chaveError : 'Campo inválido'}
                    isSuccess={chaveAlertaValida}
                    successText=""
                  >
                    <Input
                      placeholder="Informe a chave do alerta"
                      value={configAlert ? configAlert.chaveAlerta : ''}
                      disabled={id ? true : false}
                      type="number"
                      onChange={handleChaveAlertaOnChange}
                    />
                  </BaseFormInputItem>
                </Col>
                <Col xs={24} md={8}>
                  <BaseFormInputItem label="Título" isSuccess={tituloValido} errorText="Titulo inválido">
                    <Input
                      placeholder="Informe o título do alerta"
                      value={configAlert ? configAlert.titulo : ''}
                      onChange={handleTituloOnChange}
                    />
                  </BaseFormInputItem>
                </Col>
                <Col xs={24} md={8}>
                  <BaseFormInputItem label="Categoria" isSuccess={categoriaValida} errorText="Categoria inválida">
                    <Select
                      onChange={handleCategoryOnChange}
                      placeholder="Selecione a categoria"
                      value={configAlert ? configAlert.categoria : ''}
                      options={categorias}
                    />
                  </BaseFormInputItem>
                </Col>
                {isUserMaster() && (
                  <Col xs={24} md={8}>
                    <BaseFormInputItem label="Concreteira" isSuccess={clientValida} errorText="Concreteira inválida">
                      <Select
                        showArrow
                        showSearch
                        style={{ width: '100%' }}
                        placeholder="Selecione a concreteira"
                        value={selectClient}
                        onChange={(value) => handleClientChange(value as number)}
                        options={clients.map((c) => ({ value: c.id, label: c.razaoSocial }))}
                        filterOption={(input, option) => option?.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      />
                    </BaseFormInputItem>
                  </Col>
                )}
                <Col xs={24} md={8}>
                  <BaseFormInputItem label="Filial" isSuccess={branchValida} errorText="Filial inválida">
                    <Select
                      showArrow
                      showSearch
                      style={{ width: '100%' }}
                      value={selectBranch}
                      placeholder="Selecione as filiais"
                      options={branches.map((br) => ({ value: br.id, label: br.nome }))}
                      onChange={(value) => handleBranchChange(value as number)}
                      filterOption={(input, option) => option?.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    />
                  </BaseFormInputItem>
                </Col>
                <Col xs={24} md={8}>
                  <BaseFormInputItem label="Descrição" supportText="Opcional">
                    <TextArea
                      placeholder="Escreva uma descrição"
                      value={configAlert ? configAlert.descricao : ''}
                      onChange={(e) => setConfigAlert({ ...configAlert, descricao: e.target.value })}
                    />
                  </BaseFormInputItem>
                </Col>
              </Row>
            </Description>

            <Description
              title="Informações complementares"
              subtitle="Adicione pelo menos uma das opções para finalizar o cadastro"
            >
              <Row>
                <Col>
                  <div>
                    <Checkbox
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'flex-start',
                        alignSelf: 'flex-end',
                        flexDirection: 'row',
                        marginTop: '1rem',
                      }}
                      checked={explicar}
                      onChange={handleExplicarOnChange}
                    >
                      Adicionar campo de explicação
                    </Checkbox>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col style={{ marginTop: '0.125rem', marginBottom: '0.525rem' }}>
                  <Button
                    type="link"
                    icon={
                      <PlusOutlined
                        style={{
                          color: 'var(--secondary-color)',
                          cursor: 'pointer',
                        }}
                      />
                    }
                    onClick={() => setModelJustifications(true)}
                  >
                    Adicionar ou editar motivo
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col>
                  {justificar ? (
                    <List
                      title="Motivos adicionados"
                      justificationItems={justificativasTag.map((j) => {
                        return {
                          key: j.id,
                          justification: j.justificativa,
                        };
                      })}
                    />
                  ) : null}
                </Col>
              </Row>
            </Description>
          </Spinner>
        </BaseForm>
      </PageContainer>
    </>
  );
};
export default ConfigAlertCreate;
