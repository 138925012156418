import React, { ReactNode } from 'react';
import * as S from './DrawerLinkItem.styles';

interface DrawerLinkItemProps {
  icon?: ReactNode;
  title: string;
  subtitle?: string;
  style?: React.CSSProperties;
}

export const DrawerLinkItem: React.FC<DrawerLinkItemProps> = ({ ...props }) => {
  return (
    <S.DrawerLinkItemContainer style={props.style}>
      <S.DrawerLinkItemIcon>{props.icon}</S.DrawerLinkItemIcon>
      <S.DrawerLinkItemTitleContainer>
        <S.DrawerLinkItemTitle>{props.title}</S.DrawerLinkItemTitle>
        <S.DrawerLinkItemSubTitle>{props.subtitle}</S.DrawerLinkItemSubTitle>
      </S.DrawerLinkItemTitleContainer>
    </S.DrawerLinkItemContainer>
  );
};
