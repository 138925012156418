import { FONT_SIZE } from '@app/styles/themes/constants';
import styled from 'styled-components';

export const ImageWrapper = styled.div`
  margin-bottom: 1.875rem;
`;

export const FormDescription = styled.div`
  color: #374450;
  width: 398px;
  height: 47px;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 600;
  font-size: ${FONT_SIZE.lg};
  line-height: 24px;
  margin: 1.5rem 0rem 2.5rem 0rem;
`;

export const SubFormDescription = styled.div`
  color: #374450;
  width: 398px;
  height: 47px;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 600;
  font-size: ${FONT_SIZE.md};
  line-height: 24px;
  margin: 0.625rem 0rem 1.5rem 0rem;
`;

export const BoldItem = styled.span`
  color: var(--primary-color);
  font-weight: 800;
`;
