import styled from 'styled-components';
import { Collapse as AntdCollapse } from 'antd';

export const Collapse = styled(AntdCollapse)`
  margin-bottom: 1rem;
`;

export const CollapseHeaderName = styled.span`
  color: var(--Neutral-Gray-Gray800, #383838);
  font-family: 'Mulish';
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;
