import { FooterList } from '@app/components/common/footerMonitoring/FooterList/FooterList';
import { FooterM } from '@app/components/common/footerMonitoring/FooterM';
import * as S from './FooterMonitoring.styles';
import { ConcreteMixerReturning } from '@app/domain/interfaces/monitoring/footerMonitoringModels';
import { ReactComponent as HelperIcon } from '@app/assets/icons/help.svg';
import { ReactComponent as CloseIcon } from '@app/assets/icons/close.svg';
import { ReactComponent as HomeIcon } from '@app/assets/icons/home.svg';
import { ReactComponent as RoadIcon } from '@app/assets/icons/road.svg';
import { ReactComponent as HourglassIcon } from '@app/assets/icons/hourglass.svg';
import { ReactComponent as HourglassFullIcon } from '@app/assets/icons/hourglass_full.svg';
import { ReactComponent as HourglassDisabledIcon } from '@app/assets/icons/hourglass_disabled.svg';
import { ReactComponent as UpdateIcon } from '@app/assets/icons/update.svg';
import { ReactComponent as InfoIcon } from '@app/assets/icons/info.svg';
import { useState } from 'react';
import ModalConcreteMixersReturning from '@app/pages/modules/Monitoring/components/ModalConcreteMixersReturning';
import ModalConcreteMixersAvailable from '@app/pages/modules/Monitoring/components/ModalConcreteMixersAvailable';

interface FooterMonitoringProps {
  concreteMixerReturning: ConcreteMixerReturning[];
  concreteMixerAvailable: ConcreteMixerReturning[];
}

export const FooterMonitoring: React.FC<FooterMonitoringProps> = ({
  concreteMixerReturning,
  concreteMixerAvailable,
}) => {
  const [helperVisible, setHelperVisible] = useState(false);
  const [modalConcreteMixersReturningVisible, setModalConcreteMixersReturningVisible] = useState<boolean>(false);
  const [modalConcreteMixersAvailableVisible, setModalConcreteMixersAvailableVisible] = useState<boolean>(false);

  return (
    <div>
      <ModalConcreteMixersReturning
        modalVisible={modalConcreteMixersReturningVisible}
        setModalVisible={setModalConcreteMixersReturningVisible}
        concreteMixerReturning={concreteMixerReturning}
      />
      <ModalConcreteMixersAvailable
        modalVisible={modalConcreteMixersAvailableVisible}
        setModalVisible={setModalConcreteMixersAvailableVisible}
      />
      {helperVisible && (
        <S.HelperContainerBox>
          <S.Header>
            <S.HelperTitle>AJUDA</S.HelperTitle>
            <S.CloseButton onClick={() => setHelperVisible(false)}>
              <CloseIcon />
            </S.CloseButton>
          </S.Header>
          <S.HelperContainer>
            <S.Item>
              <S.ItemTitle>Header + Cards</S.ItemTitle>
              <S.Content>Filtra por ponto de carga</S.Content>
            </S.Item>
            <S.Item>
              <S.ItemTitle>Footer</S.ItemTitle>
              <S.Content>Filtra por filial</S.Content>
            </S.Item>
            <S.Item>
              <S.ItemTitle>Status dos ícones</S.ItemTitle>
              <S.Content>
                <S.SubItem>
                  <HomeIcon /> Central
                </S.SubItem>
                <S.SubItem>
                  <RoadIcon /> A caminho
                </S.SubItem>
                <S.SubItem>
                  <HourglassIcon /> Aguardando
                </S.SubItem>
                <S.SubItem>
                  <HourglassFullIcon /> FILA
                </S.SubItem>
                <S.SubItem>
                  <HourglassDisabledIcon /> FALTA
                </S.SubItem>
                <S.SubItem>
                  <UpdateIcon /> Descarregando
                </S.SubItem>
                <S.SubItem>
                  <InfoIcon /> Grupo do traço
                </S.SubItem>
              </S.Content>
            </S.Item>
            <S.Item>
              <S.ItemTitle>Abreviações</S.ItemTitle>
              <S.Content>
                <S.SubItem>
                  <strong>BM </strong> Bomba móvel
                </S.SubItem>
                <S.SubItem>
                  <strong>BC </strong> Bomba do cliente
                </S.SubItem>
                <S.SubItem>
                  <strong>BL </strong> Bomba lança
                </S.SubItem>
                <S.SubItem>
                  <strong>BT </strong> Betoneira
                </S.SubItem>
                <S.SubItem>
                  <strong>CONV </strong> CONVENCIONAL
                </S.SubItem>
                <S.SubItem>
                  <strong>CANC </strong> CANCELADA
                </S.SubItem>
                <S.SubItem>
                  <strong>SUSP. PARC. </strong> SUSPENSÃO PARCIAL
                </S.SubItem>
                <S.SubItem>
                  <strong>SUSP. TOT. </strong> SUSPENSÃO TOTAL
                </S.SubItem>
              </S.Content>
            </S.Item>
          </S.HelperContainer>
        </S.HelperContainerBox>
      )}
      <FooterM>
        <HelperIcon
          style={{ cursor: 'pointer', position: 'absolute', right: '0.5rem', bottom: '10rem' }}
          onClick={() => setHelperVisible(!helperVisible)}
        />
        <S.FooterContainer>
          <S.BetoneirasDisponiveisContainer>
            <S.Title>
              <div>
                BETONEIRAS DISPONÍVEIS
                {concreteMixerAvailable.filter((c) => c.status).length > 0 && (
                  <span style={{ marginLeft: '1rem' }}>{concreteMixerAvailable.filter((c) => c.status).length}</span>
                )}
              </div>
              <S.SeeAllButton
                onClick={() => setModalConcreteMixersAvailableVisible(!modalConcreteMixersAvailableVisible)}
              >
                Ver todas
              </S.SeeAllButton>
            </S.Title>
            <S.ContainerD run={concreteMixerAvailable.length > 4 ? 'running' : 'paused'}>
              <FooterList
                footerListItem={concreteMixerAvailable
                  .filter((c) => c.status)
                  .map((item) => {
                    return {
                      betoneira: item.betoneira,
                      motorista: item.motorista.slice(0, 20),
                      tempoRetorno: '',
                      status: item.status,
                    };
                  })}
              />
            </S.ContainerD>
          </S.BetoneirasDisponiveisContainer>
          <div style={{ height: '100vh', backgroundColor: '#CCD9E3' }}></div>
          <S.BetoneirasRetornandoContainer>
            <S.Title>
              <div>
                BETONEIRAS RETORNANDO
                {concreteMixerReturning.length > 0 && (
                  <span style={{ marginLeft: '1rem' }}>{concreteMixerReturning.length}</span>
                )}
              </div>
              <S.SeeAllButton
                onClick={() => setModalConcreteMixersReturningVisible(!modalConcreteMixersReturningVisible)}
              >
                Ver todas
              </S.SeeAllButton>
            </S.Title>

            <S.Container run={concreteMixerReturning.length > 4 ? 'running' : 'paused'}>
              <FooterList
                footerListItem={concreteMixerReturning.map((item) => {
                  return {
                    betoneira: item.betoneira,
                    motorista: item.motorista.slice(0, 20),
                    tempoRetorno: item.tempoRetorno,
                    status: item.status,
                  };
                })}
              />
            </S.Container>
          </S.BetoneirasRetornandoContainer>
        </S.FooterContainer>
      </FooterM>
    </div>
  );
};
