import { AlertType } from '@app/constants/enums/alerts';
import { AlertFilter } from '@app/constants/types/alertTypes';
import { AlertModel } from '@app/domain/interfaces/alert/alertModel';
import { GetAlertRequestModel } from '@app/domain/interfaces/alert/getAlertRequestModel';
import {
  GetAlertResponseModel,
  GetTodosAlertasResponseModel,
} from '@app/domain/interfaces/alert/getAlertResponseModel';
import { GetAlertsFilterRequestModel } from '@app/domain/interfaces/alert/getAlertsFilterRequestModel';
import { ReplyAlertRequestModel } from '@app/domain/interfaces/alert/replyAlertRequestModel';
import { UpdateAlertRequestModel } from '@app/domain/interfaces/alert/updateAlertRequestModel';
import { ApiServiceBase, IApiServiceBase } from './apiServiceBase';
import { AlertShippingModel } from '@app/domain/interfaces/alert/alertShippingResponseModel';

export default interface IAlertService extends IApiServiceBase<AlertModel, AlertModel> {
  getAlerts(alertRequest: GetAlertRequestModel, type: AlertType): Promise<GetAlertResponseModel>;
  getCountAlerts(predicate: string, entidades: AlertFilter[] | undefined): Promise<GetTodosAlertasResponseModel>;
  replyAlert(alertRequest: ReplyAlertRequestModel): Promise<void>;
  updateAlert(alertRequest: UpdateAlertRequestModel): Promise<void>;
  getFilters(alertRequest: GetAlertsFilterRequestModel): Promise<AlertFilter[]>;
  getAlertShippingByIdAlert(id: string): Promise<AlertShippingModel>;
}

export class AlertService extends ApiServiceBase<AlertModel, AlertModel> implements IAlertService {
  constructor() {
    super('admin/alertas');
  }

  async getCountAlerts(predicate: '', entidades: []) {
    const res = await this.api.post('obterContagem', {
      predicate,
      entidades,
    });

    return (await res.data) as GetTodosAlertasResponseModel;
  }

  async getAlerts(alertRequest: GetAlertRequestModel, type: AlertType) {
    let endpoint = '';

    switch (type) {
      case AlertType.Novo:
        endpoint = 'obterNovos';
        break;
      case AlertType.Confirmado:
        endpoint = 'obterConfirmados';
        break;
      case AlertType.Reportado:
        endpoint = 'obterReportados';
        break;
    }

    const res = await this.api.post(endpoint, alertRequest);

    return (await res.data) as GetAlertResponseModel;
  }

  async replyAlert(alertRequest: ReplyAlertRequestModel) {
    const res = await this.api.post('responder', alertRequest);

    return await res.data;
  }

  async updateAlert(alertRequest: UpdateAlertRequestModel) {
    const res = await this.api.put('atualizar', alertRequest);

    return await res.data;
  }

  async getFilters(alertRequest: GetAlertsFilterRequestModel) {
    const res = await this.api.post('filtros', alertRequest);

    return await res.data.filtros;
  }

  async getAlertShippingByIdAlert(id: string): Promise<AlertShippingModel> {
    const res = await this.api.get(`obterPorId/${id}`);

    return (await res.data) as AlertShippingModel;
  }
}
