import * as React from 'react';
const SvgIconArrowClose = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="currentColor">
    <g clipPath="url(#clip0_1806_7358)">
      <path
        d="M22 3.41L16.71 8.7L20 12H12V4L15.29 7.29L20.59 2L22 3.41ZM3.41 22L8.7 16.71L12 20V12H4L7.29 15.29L2 20.59L3.41 22Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_1806_7358">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgIconArrowClose;
