import { Modal, ModalProps } from 'antd';
import styled from 'styled-components';

export const ModalFooter = styled(Modal)<ModalProps>`
  position: fixed !important;
  top: 35% !important;
  bottom: 0 !important;
  width: 100% !important;
  max-width: 100% !important;
  min-height: 100rem;

  & .ant-modal-title {
    font-family: Mulish;
    font-size: 20px;
    font-weight: 700;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: left;
    color: #383838;
  }

  & .ant-modal-header {
    border: none !important;
  }

  & .ant-modal-content {
    height: 100% !important;
  }

  & .ant-modal-footer {
    display: none;
  }

  @media print {
    & .ant-modal-body {
      padding: 2rem;
    }
  }
`;

export const ModalFooterMonitoring = styled(Modal)<ModalProps>`
  position: fixed !important;
  top: 15% !important;
  bottom: 0 !important;
  width: 100% !important;
  max-width: 100% !important;
  min-height: 100rem;

  & .ant-modal-title {
    font-family: Mulish;
    font-size: 20px;
    font-weight: 700;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: left;
    color: #383838;
  }

  & .ant-modal-header {
    border: none !important;
  }

  & .ant-modal-content {
    height: 100% !important;
  }

  & .ant-modal-footer {
    display: none;
  }

  @media print {
    & .ant-modal-body {
      padding: 2rem;
    }
  }
`;
