import styled from 'styled-components';

export const FooterContainer = styled.footer`
  background-color: #1c213c;
`;
export const FooterWrap = styled.div`
  padding: 1.525rem 2.25rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  height: 8rem;
  width: 100%;
  margin: 0;
  overflow: hidden;
`;
