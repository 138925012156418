import * as React from 'react';
const SvgIconFilter = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 36 36" width="1em" height="1em" role="img">
    <path
      fill="#000"
      fillOpacity={0.85}
      d="M32.79 3.621H3.21c-.984 0-1.598 1.073-1.104 1.929l9.345 15.886v9.667c0 .711.57 1.286 1.278 1.286h10.543a1.28 1.28 0 0 0 1.278-1.286v-9.667L33.9 5.55c.49-.856-.125-1.929-1.11-1.929ZM21.673 29.496h-7.345v-6.268h7.349v6.268h-.004Zm.386-9.506-.382.667h-7.353l-.382-.667L5.975 6.514h24.05L22.06 19.99Z"
    />
  </svg>
);
export default SvgIconFilter;
