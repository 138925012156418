import { ScheduleModel } from '@app/domain/interfaces/schedule/scheduleModel';
import { ApiServiceBase, IApiServiceBase } from './apiServiceBase';

export default interface IScheduleService extends IApiServiceBase<ScheduleModel, ScheduleModel> {
  getScheduleById(id: number): Promise<ScheduleModel | undefined>;
}

export class ScheduleService extends ApiServiceBase<ScheduleModel, ScheduleModel> implements IScheduleService {
  constructor() {
    super('admin/agendamento');
  }

  async getScheduleById(id: number): Promise<ScheduleModel | undefined> {
    try {
      const response = await this.api.get(`${id}`);
      return response.data as unknown as ScheduleModel;
    } catch (error) {
      throw error;
    }
  }
}
