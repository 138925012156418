import styled from 'styled-components';
import { Popover as AntTooltip } from 'antd';

export const TooltipCard = styled(AntTooltip)`
  &.ant-tooltip {
    max-width: 43.75rem;
  }

  &.ant-tooltip-arrow {
    --antd-arrow-background-color: #ffffff;
  }
`;
