import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Modal } from '@app/components/common/Modal/Modal';
import { ColumnsType } from 'antd/lib/table';
import { ConcreteMixerReturning } from '@app/domain/interfaces/monitoring/footerMonitoringModels';
import { Table } from '@app/components/common/Table/Table';
import appSettings from '@app/config/appsettings';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { notificationController } from '@app/controllers/notificationController';
import { Col, DatePicker, Row, Spin } from 'antd';
import { Tag } from '@app/components/common/Tag/Tag';
import { Alert } from '@app/components/common/Alert/Alert';
import { IconEdit, IconInfoCircle, IconMap } from '@app/assets/slump-icons';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { BaseFormInputItem } from '@app/components/common/forms/components/BaseFormInputItem/BaseFormInputItem';
import { Radio, RadioGroup } from '@app/components/common/Radio/Radio';
import { Input, TextArea } from '@app/components/common/inputs/Input/Input';
import moment from 'moment';
import { ModalMapConcreteMixersLocation } from '@app/pages/modules/Monitoring/components/ModalMapConcreteMixersLocation';
import { setMixerMonitoring } from '@app/store/slices/monitoringSlice';
import { useDispatch } from 'react-redux';

const { RangePicker } = DatePicker;

interface IModalConcreteMixersAvailable {
  modalVisible: boolean;
  setModalVisible: Dispatch<SetStateAction<boolean>>;
}

const ModalConcreteMixersAvailable: React.FC<IModalConcreteMixersAvailable> = ({ modalVisible, setModalVisible }) => {
  const dispatch = useDispatch();
  const { branchMonitoringSelected } = useAppSelector((state) => state.monitoring);
  const [concreteMixerAvailable, setConcreteMixerAvailable] = useState<ConcreteMixerReturning[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [modalStatusVisible, setModalStatusVisible] = useState<boolean>(false);
  const [selectedConcreteMixer, setSelectedConcreteMixer] = useState<ConcreteMixerReturning>(
    {} as ConcreteMixerReturning,
  );
  const [modalMapTripVisible, setModalMapTripVisible] = useState<boolean>(false);

  const columns: ColumnsType<ConcreteMixerReturning> = [
    {
      title: 'BT',
      dataIndex: 'betoneira',
      showSorterTooltip: false,
      width: '3%',
      render: (betoneira) => <div style={{ whiteSpace: 'nowrap', wordBreak: 'keep-all' }}>{betoneira}</div>,
    },
    {
      title: '',
      dataIndex: 'betoneira',
      showSorterTooltip: false,
      width: '3%',
      render: (_, concreteMixer) => (
        <div
          style={{
            cursor: 'pointer',
          }}
          onClick={() => {
            setSelectedConcreteMixer(concreteMixer);
            setModalMapTripVisible((prevState) => !prevState);
            setModalVisible((prevState) => !prevState);
            dispatch(
              setMixerMonitoring({
                numeroBT: concreteMixer.betoneira,
                deviceId: concreteMixer.deviceId,
              }),
            );
          }}
        >
          <IconMap />
        </div>
      ),
    },
    {
      title: 'Motorista',
      dataIndex: 'motorista',
      showSorterTooltip: false,
      width: '10%',
    },
    {
      title: 'Período',
      dataIndex: 'betoneira',
      showSorterTooltip: false,
      width: '15%',
      render: (_, betoneiraModel: ConcreteMixerReturning) => {
        return (
          <span>
            {betoneiraModel.dataInicioIndisponibilidade
              ? new Date(betoneiraModel.dataInicioIndisponibilidade).toLocaleDateString()
              : ''}{' '}
            -{' '}
            {betoneiraModel.dataFimIndisponibilidade
              ? new Date(betoneiraModel.dataFimIndisponibilidade).toLocaleDateString()
              : betoneiraModel.dataInicioIndisponibilidade
              ? new Date(betoneiraModel.dataInicioIndisponibilidade).toLocaleDateString()
              : ''}
          </span>
        );
      },
    },
    {
      title: 'Motivo',
      dataIndex: 'motivo',
      showSorterTooltip: false,
      width: '15%',
    },
    {
      title: 'Observação',
      dataIndex: 'observacao',
      showSorterTooltip: false,
      width: '20%',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      showSorterTooltip: false,
      width: '5%',
      render: (status: boolean) => {
        return status === true ? (
          <Tag style={{ padding: '0.375rem 1rem', color: '#083F18', borderRadius: ' 0.375rem' }} color="#E9F4EE">
            Disponível
          </Tag>
        ) : (
          <Tag style={{ padding: '0.375rem 1rem', color: '#620E12', borderRadius: ' 0.375rem' }} color="#FEE9EA">
            Indisponível
          </Tag>
        );
      },
    },
    {
      title: '',
      dataIndex: 'betoneira',
      width: '2%',
      render: (_: string, betoneira: ConcreteMixerReturning) => {
        return (
          <div
            style={{
              cursor: 'pointer',
            }}
            onClick={() => {
              setSelectedConcreteMixer(betoneira);
              setModalStatusVisible((prevState) => !prevState);
              setModalVisible((prevState) => !prevState);
            }}
          >
            <IconEdit />
          </div>
        );
      },
    },
  ];

  const fetchConcreteMixersAvailable = async () => {
    setLoading(true);
    fetch(`${appSettings().APIs.Monitoramento.UrlBase}/monitoramento/obterBetoneirasDisponiveis`, {
      method: 'POST',
      body: JSON.stringify({
        idFilial: branchMonitoringSelected.idFilial,
        dataProgramacao: new Date().toLocaleDateString(),
      }),
      headers: new Headers({
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }),
    })
      .then((data) => data.json())
      .then((json) => {
        if (json !== null) {
          setConcreteMixerAvailable(json);
          setLoading(false);
        }
      })
      .catch((err) => {
        notificationController.error({ message: err });
        console.error(err);
        setLoading(false);
      });
  };

  const updateConcreteMixerStatus = async () => {
    setLoading(true);
    fetch(`${appSettings().APIs.Monitoramento.UrlBase}/monitoramento/atualizarStatusBetoneira`, {
      method: 'POST',
      body: JSON.stringify({
        idFilial: branchMonitoringSelected.idFilial,
        dataProgramacao: new Date().toLocaleDateString(),
        betoneira: selectedConcreteMixer.betoneira,
        status: selectedConcreteMixer.status,
        motivo: selectedConcreteMixer.motivo,
        observacao: selectedConcreteMixer.observacao,
        dataInicioIndisponibilidade: selectedConcreteMixer.dataInicioIndisponibilidade,
        dataFimIndisponibilidade: selectedConcreteMixer.dataFimIndisponibilidade,
      }),
      headers: new Headers({
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }),
    })
      .then((data) => data.json())
      .then((json) => {
        if (json !== null) {
          if (json.sucesso) {
            notificationController.success({ message: 'Status da betoneira atualizado com sucesso' });
            setModalStatusVisible(!modalStatusVisible);
            setModalVisible(!modalVisible);
          } else {
            notificationController.error({ message: json.message });
            setModalStatusVisible(!modalStatusVisible);
            setModalVisible(!modalVisible);
          }
        }
      })
      .catch((err) => {
        notificationController.error({ message: err });
        console.error(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (modalVisible) {
      fetchConcreteMixersAvailable();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalVisible]);

  return (
    <>
      <Modal
        open={modalStatusVisible}
        title="Editar status da betoneira"
        onCancel={() => {
          setModalStatusVisible(!modalStatusVisible);
          setModalVisible(!modalVisible);
        }}
        onOk={updateConcreteMixerStatus}
        cancelText="Cancelar"
        okText="Salvar"
        centered
        width={'60%'}
      >
        <BaseForm layout="vertical" style={{ width: '100%' }}>
          <>
            <Row gutter={24}>
              <Col xs={24} md={24}>
                <BaseFormInputItem label="Status da betoneira" supportText="" style={{ width: '100%' }}>
                  <RadioGroup
                    name="status"
                    onChange={(value) =>
                      setSelectedConcreteMixer((prevState) => ({ ...prevState, status: value.target.value }))
                    }
                    value={selectedConcreteMixer?.status}
                    style={{ width: '20rem', display: 'flex', flexDirection: 'column' }}
                  >
                    <Radio value={true}>Disponível</Radio>
                    <Radio value={false}>Indisponível</Radio>
                  </RadioGroup>
                </BaseFormInputItem>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col xs={24} md={24}>
                <BaseFormInputItem label="Período" style={{ width: '100%' }}>
                  <RangePicker
                    picker="date"
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    onChange={(values: any) =>
                      setSelectedConcreteMixer((prevState) => ({
                        ...prevState,
                        dataInicioIndisponibilidade: values[0],
                        dataFimIndisponibilidade: values[1],
                      }))
                    }
                    value={
                      selectedConcreteMixer.dataInicioIndisponibilidade !== null
                        ? [
                            moment(selectedConcreteMixer.dataInicioIndisponibilidade),
                            moment(
                              selectedConcreteMixer.dataFimIndisponibilidade !== null
                                ? selectedConcreteMixer.dataFimIndisponibilidade
                                : selectedConcreteMixer.dataInicioIndisponibilidade,
                            ),
                          ]
                        : undefined
                    }
                  />
                </BaseFormInputItem>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col xs={24} md={24}>
                <BaseFormInputItem label="Motivo" supportText="Máx. 50 caracteres" style={{ width: '100%' }}>
                  <Input
                    placeholder="Digite o motivo"
                    value={selectedConcreteMixer.motivo}
                    onChange={(e) =>
                      setSelectedConcreteMixer((prevState) => ({
                        ...prevState,
                        motivo: e.target.value,
                      }))
                    }
                    maxLength={50}
                    required
                  />
                </BaseFormInputItem>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col xs={24} md={24}>
                <BaseFormInputItem label="Observações" supportText="Opcional" style={{ width: '100%' }}>
                  <TextArea
                    name="observacao"
                    placeholder="Digite as observações"
                    value={selectedConcreteMixer.observacao}
                    onChange={(e) =>
                      setSelectedConcreteMixer((prevState) => ({
                        ...prevState,
                        observacao: e.target.value,
                      }))
                    }
                  />
                </BaseFormInputItem>
              </Col>
            </Row>
          </>
        </BaseForm>
      </Modal>

      <ModalMapConcreteMixersLocation
        mixerSelected={selectedConcreteMixer}
        open={modalMapTripVisible}
        onCancel={() => {
          setModalMapTripVisible((prevState) => !prevState);
          setModalVisible((prevState) => !prevState);
        }}
        onOk={() => {
          setModalMapTripVisible((prevState) => !prevState);
          setModalVisible((prevState) => !prevState);
        }}
      />

      <Modal
        open={modalVisible}
        title="Status das betoneiras"
        cancelText=""
        okText=""
        width={'80%'}
        onCancel={() => setModalVisible(!modalVisible)}
        footer={null}
        centered
      >
        <Spin spinning={loading}>
          <div>
            <Alert
              icon={<IconInfoCircle />}
              message="Estão listadas todas as betoneiras disponíveis. Se necessário, desative alguma(s) dela(s)."
              showIcon={true}
              style={{
                backgroundColor: '#CAD2EC99',
                display: 'flex',
                flexDirection: 'row',
                gap: '0.5rem',
                marginBottom: '1rem',
              }}
            />
            <Table columns={columns} dataSource={concreteMixerAvailable ?? []} bordered size="small" />
          </div>
        </Spin>
      </Modal>
    </>
  );
};

export default ModalConcreteMixersAvailable;
