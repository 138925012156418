import { SearchOutlined } from '@ant-design/icons';
import { Button } from '@app/components/common/buttons/Button/Button';
import { Spinner } from '@app/components/common/Spinner/Spinner';
import { Table } from '@app/components/common/Table/Table';
import { Tooltip } from '@app/components/common/Tooltip/Tooltip';
import Dashboard from '@app/components/dashboard/Dashboard';
import { notificationController } from '@app/controllers/notificationController';
import {
  ReportMixerErrorAnalyticModel,
  ReportMixerErrorDeviceAnalyticModel,
} from '@app/domain/interfaces/report/reportMixerErrorAnalytic';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import IReportService, { ReportService } from '@app/services/reportService';
import { setHeader } from '@app/store/slices/headerSlice';
import { Badge } from 'antd';
import { ColumnsType } from 'antd/es/table';
import moment from 'moment';
import { FC, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { exportReportMixerErrorDeviceAnalyticToExcel } from '../functions/excelFunctions';

const reportService: IReportService = new ReportService();

export const MixerReportAnalyticDashboard: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [existsReportInExecution, setExistsReportInExecution] = useState<boolean>(true);
  const [report, setReport] = useState<ReportMixerErrorAnalyticModel>();
  const [reportFiltered, setReportFiltered] = useState<ReportMixerErrorDeviceAnalyticModel[]>([]);
  const [reportToExport, setReportToExport] = useState<ReportMixerErrorDeviceAnalyticModel[]>([]);

  const handleSearch = (event: React.FormEvent<HTMLInputElement>) => {
    if (event.currentTarget.value.length > 0) {
      const inputValue = event.currentTarget.value.toLowerCase();
      setReportFiltered(
        report?.dispositivos?.filter(
          (item) =>
            item.imei?.toString().toLowerCase().includes(inputValue) ||
            item.deviceId?.toString().toLowerCase().includes(inputValue) ||
            item.betoneira?.toString().toLowerCase().includes(inputValue) ||
            item.versaoH2?.toString().toLowerCase().includes(inputValue) ||
            item.versaoCentral?.toString().toLowerCase().includes(inputValue) ||
            item.versaoHermes?.toString().toLowerCase().includes(inputValue) ||
            (item.online ? 'Sim' : 'Não').toLowerCase().includes(inputValue) ||
            (item.existeErro ? 'Sim' : 'Não').toLowerCase().includes(inputValue) ||
            item.obra?.toString().toLowerCase().includes(inputValue) ||
            item.filial?.toString().toLowerCase().includes(inputValue),
        ) ?? [],
      );
      setReportToExport(
        report?.dispositivos?.filter(
          (item) =>
            item.imei?.toString().toLowerCase().includes(inputValue) ||
            item.deviceId?.toString().toLowerCase().includes(inputValue) ||
            item.betoneira?.toString().toLowerCase().includes(inputValue) ||
            item.versaoH2?.toString().toLowerCase().includes(inputValue) ||
            item.versaoCentral?.toString().toLowerCase().includes(inputValue) ||
            item.versaoHermes?.toString().toLowerCase().includes(inputValue) ||
            (item.online ? 'Sim' : 'Não').toLowerCase().includes(inputValue) ||
            (item.existeErro ? 'Sim' : 'Não').toLowerCase().includes(inputValue) ||
            item.obra?.toString().toLowerCase().includes(inputValue) ||
            item.filial?.toString().toLowerCase().includes(inputValue),
        ) ?? [],
      );
    } else {
      setReportFiltered(report?.dispositivos ?? []);
    }
  };
  const handleGenerateReport = useCallback(() => {
    setLoading(true);

    reportService
      .generateMixerReportAnlytic()
      .then(() => {
        setExistsReportInExecution(true);
      })
      .catch((error) => notificationController.error(error))
      .finally(() => setLoading(false));
  }, []);
  const handleExportToExcel = useCallback(() => {
    exportReportMixerErrorDeviceAnalyticToExcel(reportToExport);
  }, [reportToExport]);

  const fetchReportInExecution = useCallback(() => {
    reportService
      .existsMixerReportAnlyticInExecution()
      .then((res) => {
        setExistsReportInExecution(res);
      })
      .catch((error) => notificationController.error(error))
      .finally(() => setLoading(false));
  }, []);
  const fetchReportData = useCallback(() => {
    setLoading(true);

    reportService
      .getMixerReportAnlytic()
      .then((res) => {
        setReport(res);
        setReportFiltered(res.dispositivos);
      })
      .catch((error) => notificationController.error(error))
      .finally(() => setLoading(false));
  }, []);

  const columns: ColumnsType<ReportMixerErrorDeviceAnalyticModel> = [
    {
      title: 'Id',
      dataIndex: 'deviceId',
      showSorterTooltip: false,
      sorter: (a, b) => a.deviceId?.localeCompare(b.deviceId),
    },
    {
      title: 'Imei',
      dataIndex: 'imei',
      showSorterTooltip: false,
      sorter: (a, b) => a.imei?.localeCompare(b.imei),
      ellipsis: true,
    },
    {
      title: 'Betoneira',
      dataIndex: 'betoneira',
      showSorterTooltip: false,
      sorter: (a, b) => a.betoneira?.localeCompare(b.betoneira),
    },
    {
      title: 'Últ. Obra',
      dataIndex: 'obra',
      showSorterTooltip: false,
      sorter: (a, b) => a.obra?.localeCompare(b.obra),
      ellipsis: true,
    },
    {
      title: 'Últ. Filial',
      dataIndex: 'filial',
      showSorterTooltip: false,
      sorter: (a, b) => a.filial?.localeCompare(b.filial),
      ellipsis: true,
      filters: Array.from(new Set(reportFiltered.map((r) => r.filial)))
        .filter((r) => r)
        .map((r) => ({ text: r, value: r }))
        .toSorted((a, b) => a.value.localeCompare(b.value)),
      onFilter: (value, record) => record.filial == value,
    },
    {
      title: 'Data/Hora Remessa',
      dataIndex: 'dataHoraUltimaRemessa',
      showSorterTooltip: false,
      sorter: (a, b) =>
        a.dataHoraUltimaRemessa && b.dataHoraUltimaRemessa
          ? new Date(a.dataHoraUltimaRemessa).getTime() - new Date(b.dataHoraUltimaRemessa).getTime()
          : 0,
      width: '15%',
      render: (dataHora: string) => (dataHora ? moment(dataHora).format('DD/MM/YYYY HH:mm') + 'h' : '-'),
    },
    {
      title: 'Estado',
      dataIndex: 'ultimaLocalizacaoEstado',
      showSorterTooltip: false,
      sorter: (a, b) => a.ultimaLocalizacaoEstado?.localeCompare(b.ultimaLocalizacaoEstado),
      ellipsis: true,
    },
    {
      title: 'Data/Hora Localização',
      dataIndex: 'ultimaLocalizacaoDataHora',
      showSorterTooltip: false,
      sorter: (a, b) =>
        a.ultimaLocalizacaoDataHora && b.ultimaLocalizacaoDataHora
          ? new Date(a.ultimaLocalizacaoDataHora).getTime() - new Date(b.ultimaLocalizacaoDataHora).getTime()
          : 0,
      width: '15%',
      render: (dataHora: string) => (dataHora ? moment(dataHora).format('DD/MM/YYYY HH:mm') + 'h' : '-'),
    },
    {
      title: 'Conectado',
      dataIndex: 'online',
      showSorterTooltip: false,
      filters: [
        { text: 'Sim', value: true },
        { text: 'Não', value: false },
      ],
      onFilter: (value, record) => record.online == value,
      render: (online) => (online ? <Badge color="green" text="Sim" /> : <Badge color="red" text="Não" />),
    },
    {
      title: 'Existe erro',
      dataIndex: 'existeErro',
      showSorterTooltip: false,
      ellipsis: true,
      filters: [
        { text: 'Sim', value: true },
        { text: 'Não', value: false },
      ],
      onFilter: (value, record) => record.existeErro == value,
      render: (_, record) =>
        record.existeErro ? <Badge color="green" text="Sim" /> : <Badge color="red" text="Não" />,
    },
    {
      title: '',
      width: '4%',
      dataIndex: 'deviceId',
      showSorterTooltip: false,
      render: (deviceId) => (
        <Button type="link" size="small" onClick={() => navigate(`/relatorio/erros-betoneira/${deviceId}`)}>
          <Tooltip title="Visualizar">
            <SearchOutlined />
          </Tooltip>
        </Button>
      ),
    },
  ];

  useEffect(() => {
    fetchReportData();
    fetchReportInExecution();
    dispatch(
      setHeader({
        title: 'Relatório analítico erros do dispositivo',
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Spinner spinning={loading}></Spinner>
      <Dashboard
        title={
          reportFiltered[0]?.dataHoraGeracao
            ? `Último relatório gerado em ${moment(reportFiltered[0]?.dataHoraGeracao).format('DD/MM/YYYY HH:mm')}h`
            : ''
        }
        buttonDisabled={existsReportInExecution}
        buttonText={existsReportInExecution ? 'Relatório em execução' : 'Gerar relatório'}
        handleButtonClick={handleGenerateReport}
        table={
          <Table
            bordered={true}
            columns={columns}
            dataSource={reportFiltered}
            onChange={(p, r, s, { currentDataSource }) => setReportToExport(currentDataSource)}
          />
        }
        placeholderTextSearch="Pesquisar"
        handleSearchOnChange={handleSearch}
        exportToExcel={handleExportToExcel}
      />
    </>
  );
};
