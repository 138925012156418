/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { ReactComponent as IconCampaign } from '@app/assets/icons/campaign.svg';
import { ReactComponent as IconCheck } from '@app/assets/icons/check_circle_outline.svg';
import { ReactComponent as IconFilter } from '@app/assets/icons/filterIcon.svg';
import { ReactComponent as IconJustificar } from '@app/assets/icons/iconJustificar.svg';
import { ReactComponent as IconReport } from '@app/assets/icons/report_gmailerrorred.svg';
import { Checkbox } from '@app/components/common/Checkbox/Checkbox';
import { Drawer } from '@app/components/common/Drawer/Drawer';
import { DrawerSuccess } from '@app/components/common/Drawer/DrawerSuccess/DrawerSuccess';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { SpinnerSlump } from '@app/components/common/SpinSlump/SpinSlump';
import { Spinner } from '@app/components/common/Spinner/Spinner';
import { Table } from '@app/components/common/Table/Table';
import { Tag } from '@app/components/common/Tag/Tag';
import { Button } from '@app/components/common/buttons/Button/Button';
import { ButtonTab } from '@app/components/common/buttons/ButtonTab/ButtonTab';
import { BaseFormInputItem } from '@app/components/common/forms/components/BaseFormInputItem/BaseFormInputItem';
import { TextArea } from '@app/components/common/inputs/Input/Input';
import { Select } from '@app/components/common/selects/Select/Select';
import Dashboard from '@app/components/dashboard/Dashboard';
import { AlertEntidade, AlertEstado, AlertType } from '@app/constants/enums/alerts';
import { TableDataType } from '@app/constants/enums/tableDataType';
import { AlertFilter } from '@app/constants/types/alertTypes';
import { hasAccessByPermission } from '@app/controllers/accessController';
import { notificationController } from '@app/controllers/notificationController';
import { AlertModel, LogAlarm } from '@app/domain/interfaces/alert/alertModel';
import { GetAlertRequestModel } from '@app/domain/interfaces/alert/getAlertRequestModel';
import { GetAlertResponseModel } from '@app/domain/interfaces/alert/getAlertResponseModel';
import { GetAlertsFilterRequestModel } from '@app/domain/interfaces/alert/getAlertsFilterRequestModel';
import { ReplyAlertRequestModel } from '@app/domain/interfaces/alert/replyAlertRequestModel';
import { UpdateAlertRequestModel } from '@app/domain/interfaces/alert/updateAlertRequestModel';
import { Justificativa } from '@app/domain/interfaces/configAlertModel';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { useMounted } from '@app/hooks/useMounted';
import IAlertService, { AlertService } from '@app/services/alertService';
import IConfigAlertService, { ConfigAlertService } from '@app/services/configAlertService';
import { setAlertFilter, setHeader } from '@app/store/slices/headerSlice';
import { Col, DatePicker, Row, Space } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { genericExportToExcel } from '@app/utils/exportToExcel';

const alertService: IAlertService = new AlertService();
const configAlertService: IConfigAlertService = new ConfigAlertService();

interface BadgesCount {
  new: number;
  confirmed: number;
  reported: number;
}

const initialAlertRequest: GetAlertRequestModel = {
  offset: 0,
  limit: 1000,
  predicate: '',
  entidades: [],
};

const AlertDashboard: React.FC = () => {
  const { isMounted } = useMounted();
  const [typeAlertSelected, setTypeAlertSelected] = useState<AlertType>(AlertType.Novo);
  const [badgesCount, setBadgesCount] = useState<BadgesCount>({ new: 0, confirmed: 0, reported: 0 });
  const [tableData, setTableData] = useState<{ data: AlertModel[]; loading: boolean }>({
    data: [],
    loading: true,
  });
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerAdvancedFilter, setDrawerAdvancedFilter] = useState(false);
  const [loading, setLoading] = useState(false);
  const [advancedFilters, setAdvancedFilters] = useState<AlertFilter[]>([]);
  const [advancedFiltersCopy, setAdvancedFiltersCopy] = useState<AlertFilter[]>([]);
  const [selectedAdvancedFilters, setSelectedAdvancedFilters] = useState<AlertFilter[]>([]);
  const [optionsSelected, setOptionsSelected] = useState<string[]>([]);
  const [dateSelected, setDateSelected] = useState<moment.Moment[]>([]);
  const [selectedAlert, setSelectedAlert] = useState<AlertModel | null>(null);
  const [checkEvent, setCheckEvent] = useState<boolean>(false);
  const [justification, setJustification] = useState<Justificativa | null>(null);
  const [justifications, setJustifications] = useState<Justificativa[]>([]);
  const [explanation, setExplanation] = useState<string>('');
  const [drawerConfirmedSuccess, setDrawerConfirmedSuccess] = useState<boolean>(false);
  const [drawerReportedSuccess, setDrawerReportedSuccess] = useState<boolean>(false);
  const [showFilters, setShowFilters] = useState<boolean>(false);
  const [clickClean, setClickClean] = useState<boolean>(false);

  const novosTotal = useMemo(() => badgesCount.new, [badgesCount.new]);
  const confirmadosTotal = useMemo(() => badgesCount.confirmed, [badgesCount.confirmed]);
  const reportadosTotal = useMemo(() => badgesCount.reported, [badgesCount.reported]);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { alertTextPredicate } = useAppSelector((state) => state.header);
  const { user } = useAppSelector((state) => state.user);

  const PAGINATION_VALUE = 8;

  const columns: ColumnsType<AlertModel> = [
    {
      title: 'Confirmação',
      dataIndex: 'dataHoraResposta',
      showSorterTooltip: false,
      hidden: !(typeAlertSelected == AlertType.Confirmado),
      render: (dataHoraResposta: string) => moment(dataHoraResposta).format('DD/MM/YYYY HH:mm'),
      sorter: (a: AlertModel, b: AlertModel) =>
        a.dataHoraResposta && b.dataHoraResposta ? a.dataHoraResposta.localeCompare(b.dataHoraResposta) : 0,
      ellipsis: true,
    },
    {
      title: 'Reporte',
      dataIndex: 'dataHoraResposta',
      showSorterTooltip: false,
      hidden: !(typeAlertSelected == AlertType.Reportado),
      render: (dataHoraResposta: string) => moment(dataHoraResposta).format('DD/MM/YYYY HH:mm'),
      sorter: (a: AlertModel, b: AlertModel) =>
        a.dataHoraResposta && b.dataHoraResposta ? a.dataHoraResposta.localeCompare(b.dataHoraResposta) : 0,
      ellipsis: true,
    },
    {
      title: 'BT',
      dataIndex: 'numeroBT',
      showSorterTooltip: false,
      sorter: (a: AlertModel, b: AlertModel) => {
        const aNumber = Number(a.numeroBT.replace(/\D/g, ''));
        const bNumber = Number(b.numeroBT.replace(/\D/g, ''));
        return aNumber - bNumber;
      },
      ellipsis: true,
    },
    {
      title: 'Motorista',
      dataIndex: 'nomeMotorista',
      showSorterTooltip: false,
      sorter: (a: AlertModel, b: AlertModel) =>
        a.nomeMotorista && b.nomeMotorista ? a.nomeMotorista.localeCompare(b.nomeMotorista) : 0,
      ellipsis: true,
    },
    {
      title: 'Cliente',
      dataIndex: 'cliente',
      showSorterTooltip: false,
      sorter: (a: AlertModel, b: AlertModel) => (a.cliente && b.cliente ? a.cliente.localeCompare(b.cliente) : 0),
      ellipsis: true,
    },
    {
      title: 'Título',
      dataIndex: 'logAlarmes',
      showSorterTooltip: false,
      render: (logAlarmes: LogAlarm[]) => (logAlarmes.length > 0 ? logAlarmes[0].titulo : ''),
      sorter: (a: AlertModel, b: AlertModel) =>
        a.logAlarmes[0].titulo && b.logAlarmes[0].titulo
          ? a.logAlarmes[0].titulo.localeCompare(b.logAlarmes[0].titulo)
          : 0,
      ellipsis: true,
    },
    {
      title: 'Categoria',
      dataIndex: 'logAlarmes',
      showSorterTooltip: false,
      render: (logAlarmes: LogAlarm[]) => (logAlarmes.length > 0 ? logAlarmes[0].categoria : ''),
      sorter: (a: AlertModel, b: AlertModel) =>
        a.logAlarmes[0].categoria && b.logAlarmes[0].categoria
          ? a.logAlarmes[0].categoria.localeCompare(b.logAlarmes[0].categoria)
          : 0,
      ellipsis: true,
    },
    {
      title: 'Horário',
      dataIndex: 'dataHora',
      showSorterTooltip: false,
      render: (dataHora: string) => moment(dataHora).format('DD/MM/YYYY HH:mm'),
      sorter: (a: AlertModel, b: AlertModel) => a.dataHora.localeCompare(b.dataHora),
    },
    {
      title: '',
      dataIndex: 'id',
      render: (id: string, row: AlertModel) => {
        if (row.logAlarmes.length > 0) {
          switch (row.logAlarmes[0].categoria) {
            case 'Engenharia':
              if (!hasAccessByPermission('alert_eng', 'U')) {
                return <></>;
              }
              break;
            case 'Operação':
              if (!hasAccessByPermission('alert_ope', 'U')) {
                return <></>;
              }
              break;
            case 'Manutenção':
              if (!hasAccessByPermission('alert_man', 'U')) {
                return <></>;
              }
              break;
            case 'Tecnologia':
              if (!hasAccessByPermission('alert_tec', 'U')) {
                return <></>;
              }
              break;
          }
        } else {
          return <></>;
        }

        return (
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end' }}>
            <Button
              type="text"
              style={{ color: 'var(--secondary-color)' }}
              icon={<IconJustificar />}
              onClick={(e: React.MouseEvent) =>
                navigate(`/alertas/justificar/${id}/${row.alertaAgua ? 'true' : 'false'}`)
              }
            >
              Justificar
            </Button>
          </div>
        );
      },
    },
  ].filter((column) => !column.hidden);

  const onCloseDrawer = () => {
    setDrawerOpen(false);
    cleanForm();
  };

  const onCloseDrawerAdvancedFilter = async () => {
    setDrawerAdvancedFilter(false);
    setSelectedAdvancedFilters([]);
    setOptionsSelected([]);
    setClickClean(false);
  };

  const cleanForm = () => {
    setCheckEvent(false);
    setJustification(null);
    setExplanation('');
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    setDrawerAdvancedFilter(true);
    setSelectedAdvancedFilters([]);
    setOptionsSelected([]);
  };

  const getBadgesCountWithFilter = useCallback(async (selectedAdvancedFilters: AlertFilter[]) => {
    setLoading(true);
    const res = await alertService.getCountAlerts(initialAlertRequest.predicate, selectedAdvancedFilters);

    setBadgesCount({
      new: res.novos,
      confirmed: res.confirmados,
      reported: res.reportados,
    });
    setLoading(false);
  }, []);

  const getBadgesCount = useCallback(async () => {
    setLoading(true);
    const res = await alertService.getCountAlerts(initialAlertRequest.predicate, initialAlertRequest.entidades);

    setBadgesCount({
      new: res.novos,
      confirmed: res.confirmados,
      reported: res.reportados,
    });
    setLoading(false);
  }, []);

  const fetch = useCallback((req: GetAlertRequestModel, alertType: AlertType) => {
    setLoading(true);
    alertService
      .getAlerts(req, alertType)
      .then((res: GetAlertResponseModel) => {
        if (isMounted.current) {
          setTableData({
            data: res.alertas,
            loading: false,
          });
        }
      })
      .catch((error) => {
        setTableData((prevState) => ({ ...prevState, loading: false }));
        notificationController.error(error);
        setLoading(false);
      });
  }, []);

  const fetchWithoutBadgesCount = useCallback((req: GetAlertRequestModel, alertType: AlertType) => {
    setLoading(true);
    alertService
      .getAlerts(req, alertType)
      .then((res: GetAlertResponseModel) => {
        if (isMounted.current) {
          setTableData({
            data: res.alertas,
            loading: false,
          });
        }
        setLoading(false);
      })
      .catch((error) => {
        setTableData((prevState) => ({ ...prevState, loading: false }));
        notificationController.error(error);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (alertTextPredicate !== null) {
      initialAlertRequest.predicate = alertTextPredicate;
    } else {
      initialAlertRequest.predicate = '';
    }

    fetch(initialAlertRequest, AlertType.Novo);
    getBadgesCount();
  }, [alertTextPredicate]);

  useEffect(() => {
    if (isMounted.current) {
      dispatch(
        setHeader({
          title: 'Alertas',
          buttonText: 'Filtros avançados',
          handleButtonClick: handleClick,
          tableData: tableData.data,
          tableDataType: TableDataType.Alert,
        }),
      );
    }
  }, [tableData]);

  const handleReportAlertClick = (e: React.MouseEvent) => {
    e.preventDefault();
    setLoading(true);

    if (selectedAlert && (justification || explanation)) {
      const alertRequest: ReplyAlertRequestModel = {
        IdAlerta: selectedAlert.id,
        Explicacao: explanation,
        idJustificativa: justification?.id ? justification.id : undefined,
        Justificativa: justification?.justificativa ? justification.justificativa : undefined,
        Responsavel: user?.username ? user.username : '',
        Status: AlertEstado.Reportado,
      };

      alertService
        .replyAlert(alertRequest)
        .then((res) => {
          setDrawerOpen(false);
          setDrawerReportedSuccess(true);
          setDrawerConfirmedSuccess(false);
          cleanForm();
          setLoading(false);

          setTimeout(() => {
            handleReportedSuccess();
          }, 1700);
          handleCleanFiltersClick();
        })
        .catch((err: Error) => {
          notificationController.error({ message: err.message });
          setLoading(false);
        });
    }
  };

  const handleReportedSuccess = async () => {
    setDrawerReportedSuccess(false);
    fetch(initialAlertRequest, AlertType.Novo);
    await getBadgesCount();
  };

  const handleConfirmAlertClick = (e: React.MouseEvent) => {
    e.preventDefault();
    setLoading(true);

    if (selectedAlert && (justification || explanation)) {
      const alertRequest: ReplyAlertRequestModel = {
        IdAlerta: selectedAlert.id,
        Explicacao: explanation,
        idJustificativa: justification?.id ? justification.id : undefined,
        Justificativa: justification?.justificativa ? justification.justificativa : undefined,
        Responsavel: user?.username ? user.username : '',
        Status: AlertEstado.Confirmado,
      };

      alertService
        .replyAlert(alertRequest)
        .then((res) => {
          setDrawerOpen(false);
          setDrawerReportedSuccess(false);
          setDrawerConfirmedSuccess(true);
          cleanForm();
          setLoading(false);

          setTimeout(() => {
            handleConfirmedSuccess();
          }, 1700);
          handleCleanFiltersClick();
        })
        .catch((err: Error) => {
          notificationController.error({ message: err.message });
          setLoading(false);
        });
    }
  };

  const handleConfirmedSuccess = async () => {
    setDrawerConfirmedSuccess(false);
    fetch(initialAlertRequest, AlertType.Novo);
    await getBadgesCount();
  };

  const handleUpdateAlertClick = (e: React.MouseEvent) => {
    e.preventDefault();
    setLoading(true);

    if (selectedAlert) {
      const alertRequest: UpdateAlertRequestModel = {
        IdAlerta: selectedAlert.id,
        Explicacao: explanation,
        idJustificativa: justification?.id ? justification.id : 0,
        Justificativa: justification?.justificativa ? justification.justificativa : '',
      };

      alertService
        .updateAlert(alertRequest)
        .then(async () => {
          setDrawerOpen(false);
          cleanForm();
          notificationController.success({ message: 'Alerta alterado com sucesso!' });
          fetch(initialAlertRequest, typeAlertSelected);
          await getBadgesCount();
          setLoading(false);
        })
        .catch((err: Error) => {
          notificationController.error({ message: err.message });
          setLoading(false);
        });
    }
  };

  const handleRowClick = (e: React.MouseEvent, id: string) => {
    e.preventDefault();

    const record = searchAlert(id);

    if (record) {
      setSelectedAlert(record);
      setDrawerOpen(true);
    }
  };

  const searchAlert = (id: string) => {
    return tableData.data.find((t) => t.id === id);
  };

  useEffect(() => {
    async function getJustifications() {
      setLoading(true);
      const response = await configAlertService.getArray('obter');
      const justificationsResponse = response.find((r) => r.chaveAlerta === selectedAlert?.logAlarmes[0].chave)
        ?.justificativas;

      if (justificationsResponse) {
        setJustifications(justificationsResponse);

        if (typeAlertSelected !== AlertType.Novo) {
          if (selectedAlert?.idJustificativa && selectedAlert.idJustificativa > 0) {
            const justificativa = justificationsResponse.find((j) => j.id === selectedAlert.idJustificativa);

            if (justificativa) {
              setJustification(justificativa);
            }
          } else {
            setJustification(null);
          }
        }
      } else {
        setJustifications([]);
      }

      if (typeAlertSelected !== AlertType.Novo) {
        if (selectedAlert?.explicacao && selectedAlert.explicacao.length > 0) {
          setExplanation(selectedAlert.explicacao);
        } else {
          setExplanation('');
        }
      }

      setLoading(false);
    }

    if (drawerOpen) {
      getJustifications();
      setSelectedAdvancedFilters([]);
      setOptionsSelected([]);
    }
  }, [selectedAlert, drawerOpen]);

  useEffect(() => {
    async function getFilters() {
      setLoading(true);
      const alertRequest: GetAlertsFilterRequestModel = {
        Entidades: [
          AlertEntidade.FiltroPeriodo,
          AlertEntidade.FiltroBetoneira,
          AlertEntidade.FiltroCategoria,
          AlertEntidade.FiltroCliente,
          AlertEntidade.FiltroHorario,
          AlertEntidade.FiltroMotorista,
          AlertEntidade.FiltroTitulo,
        ],
      };

      alertService
        .getFilters(alertRequest)
        .then((res) => {
          setAdvancedFilters(res);
          setAdvancedFiltersCopy(res);
          setLoading(false);
        })
        .catch((err: Error) => {
          notificationController.error({ message: err.message });
          setLoading(false);
        });
    }

    if (drawerAdvancedFilter) {
      getFilters();
    }
  }, [drawerAdvancedFilter]);

  const handleJustificationOnChange = (value: unknown) => {
    const newJustification = justifications.find((j) => j.id === value);

    if (newJustification) {
      setJustification(newJustification);
    }
  };

  const handleChangeTypeAlert = async (alertType: AlertType) => {
    setTableData((prevState) => ({ ...prevState, loading: true }));
    setTypeAlertSelected(alertType);
    fetchWithoutBadgesCount({ ...initialAlertRequest, entidades: selectedAdvancedFilters }, alertType);
  };

  const handleCleanFiltersClick = async () => {
    setSelectedAdvancedFilters([]);
    setOptionsSelected([]);
    setClickClean(true);
  };

  useEffect(() => {
    async function cleanFilter() {
      if (selectedAdvancedFilters.length === 0) {
        fetch({ ...initialAlertRequest, entidades: selectedAdvancedFilters }, typeAlertSelected);
        await getBadgesCount();
        setClickClean(false);
      }
    }
    cleanFilter();
  }, [selectedAdvancedFilters]);

  useEffect(() => {
    dispatch(setAlertFilter(optionsSelected.length));
  }, [optionsSelected]);

  const handleAdvancedFiltersClick = async () => {
    fetch({ ...initialAlertRequest, entidades: selectedAdvancedFilters }, typeAlertSelected);
    await getBadgesCountWithFilter(selectedAdvancedFilters);
    setDrawerAdvancedFilter(false);
    handleDateSelected(dateSelected);
    setShowFilters(selectedAdvancedFilters.length > 0);
  };

  const handleAdvancedFilter = (entidade: string, opcao: string[]) => {
    let selectedEntidade = selectedAdvancedFilters.find((af) => af.entidade === entidade);

    if (selectedEntidade && selectedEntidade !== undefined) {
      if (opcao.length > 0) {
        const newSelectedAdvancedFilters = selectedAdvancedFilters.filter((f) => f.entidade !== entidade);
        selectedEntidade.opcoes = opcao;
        newSelectedAdvancedFilters.push(selectedEntidade);
        setSelectedAdvancedFilters(newSelectedAdvancedFilters);
        setOptionsSelected(opcao);
      } else {
        const newSelectedAdvancedFilters = selectedAdvancedFilters.filter((f) => f.entidade !== entidade);
        setSelectedAdvancedFilters(newSelectedAdvancedFilters);
      }
    } else {
      if (opcao.length > 0) {
        const newOpcoes: string[] = [];
        opcao.forEach((element: string) => {
          newOpcoes.push(element);
        });

        selectedEntidade = {
          entidade: entidade,
          opcoes: newOpcoes,
        };

        const newSelectedAdvancedFilters = selectedAdvancedFilters;
        newSelectedAdvancedFilters.push(selectedEntidade);

        setSelectedAdvancedFilters(newSelectedAdvancedFilters);
        setOptionsSelected([...optionsSelected, ...opcao]);
      }
    }
  };

  const handleExportToExcel = () => {
    const data = tableData.data.map((a) => ({
      numeroBT: a.numeroBT,
      nomeMotorista: a.nomeMotorista,
      cliente: a.cliente,
      título: a.logAlarmes.length > 0 ? a.logAlarmes[0].titulo : '',
      categoria: a.logAlarmes.length > 0 ? a.logAlarmes[0].categoria : '',
      dataHora: a.dataHora ? moment(a.dataHora).format('DD/MM/YYYY HH:mm') : '',
    }));

    const columns = [
      { title: 'BT', dataIndex: 'numeroBT' },
      { title: 'Motorista', dataIndex: 'nomeMotorista' },
      { title: 'Cliente', dataIndex: 'cliente' },
      { title: 'Título', dataIndex: 'título' },
      { title: 'Categoria', dataIndex: 'categoria' },
      { title: 'Horário', dataIndex: 'dataHora' },
    ];

    genericExportToExcel('alertas', columns, data);
  };

  const footerReportarAlerta = (
    <>
      <div>
        <Button
          type="ghost"
          disabled={checkEvent && (justification || explanation.length > 0) ? false : true}
          onClick={(e: React.MouseEvent) => handleReportAlertClick(e)}
        >
          Reportar
        </Button>
      </div>
      <div>
        <Button
          type="primary"
          disabled={checkEvent && (justification || explanation.length > 0) ? false : true}
          onClick={(e: React.MouseEvent) => handleConfirmAlertClick(e)}
        >
          Confirmar
        </Button>
      </div>
    </>
  );

  const footerUpdateAlerta = (
    <>
      <div>
        <Button type="primary" onClick={(e: React.MouseEvent) => handleUpdateAlertClick(e)} disabled={loading}>
          Alterar
        </Button>
      </div>
    </>
  );

  const footerFiltrosAvancados = (
    <>
      <div>
        <Button type="ghost" onClick={handleCleanFiltersClick}>
          Limpar
        </Button>
      </div>
      <div>
        <Button type="primary" onClick={handleAdvancedFiltersClick}>
          Aplicar filtros
        </Button>
      </div>
    </>
  );

  const drawerConfirmAlert = (
    <Drawer
      title={selectedAlert?.logAlarmes[0] ? `${selectedAlert.logAlarmes[0].titulo}` : ''}
      placement="right"
      onClose={onCloseDrawer}
      open={drawerOpen}
      footer={footerReportarAlerta}
      textSpan="Preencha os campos obrigatórios para confirmar este evento"
      boxClient={true}
      boxClientName={selectedAlert ? selectedAlert.cliente : ''}
      boxClientAddress={selectedAlert?.endereco ? selectedAlert.endereco : ''}
      boxClientStatus={selectedAlert ? `${selectedAlert.numeroBT} • ${selectedAlert.nomeMotorista}` : ''}
      alertaAgua={selectedAlert?.alertaAgua ? selectedAlert.alertaAgua : undefined}
      alertaConcreto={selectedAlert?.alertaConcreto ? selectedAlert.alertaConcreto : undefined}
      style={{ overflowX: 'hidden' }}
    >
      <SpinnerSlump spinning={loading}>
        <div>
          {justifications.length > 0 && (
            <BaseFormInputItem label="Selecionar justificativa" supportText="*Campo obrigatório">
              <Select
                style={{ width: 330 }}
                value={justification?.justificativa}
                onChange={handleJustificationOnChange}
                options={justifications.map((j) => ({ label: j.justificativa, value: j.id }))}
              />
            </BaseFormInputItem>
          )}
          <BaseFormInputItem label="Explique (obrigatório)" supportText="*Campo obrigatório">
            <TextArea
              rows={4}
              placeholder="Digite aqui sua explicação"
              value={explanation}
              onChange={(e: React.FormEvent<HTMLTextAreaElement>) => setExplanation(e.currentTarget.value)}
            />
          </BaseFormInputItem>
          <div>
            <Checkbox
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-start',
                alignSelf: 'flex-end',
                flexDirection: 'row',
                marginTop: '3rem',
              }}
              checked={checkEvent}
              onChange={() => setCheckEvent(!checkEvent)}
            >
              Evento analisado e confirmado por {user?.username}
            </Checkbox>
          </div>
        </div>
      </SpinnerSlump>
    </Drawer>
  );

  const drawerUpdateAlert = (
    <Drawer
      title={selectedAlert?.logAlarmes[0] ? `${selectedAlert.logAlarmes[0].titulo}` : ''}
      placement="right"
      onClose={onCloseDrawer}
      visible={drawerOpen}
      footer={footerUpdateAlerta}
      textSpan=""
      boxClient={true}
      boxClientName={selectedAlert ? selectedAlert.cliente : ''}
      boxClientAddress={selectedAlert?.endereco ? selectedAlert.endereco : ''}
      boxClientStatus={selectedAlert ? `${selectedAlert.numeroBT} • ${selectedAlert.nomeMotorista}` : ''}
      alertaAgua={selectedAlert?.alertaAgua ? selectedAlert.alertaAgua : undefined}
      alertaConcreto={selectedAlert?.alertaConcreto ? selectedAlert.alertaConcreto : undefined}
      style={{ overflowX: 'hidden' }}
    >
      <div>
        {justifications.length > 0 && (
          <BaseFormInputItem label="Selecionar justificativa" supportText="*Campo obrigatório">
            <Select
              style={{ width: 330 }}
              value={justification?.justificativa}
              onChange={handleJustificationOnChange}
              options={justifications.map((j) => ({ label: j.justificativa, value: j.id }))}
            />
          </BaseFormInputItem>
        )}
        <BaseFormInputItem label="Explique">
          <TextArea
            rows={4}
            style={{ width: 330 }}
            placeholder="Digite aqui sua explicação"
            value={explanation}
            onChange={(e: React.FormEvent<HTMLTextAreaElement>) => setExplanation(e.currentTarget.value)}
          />
        </BaseFormInputItem>
        <div>
          <Checkbox
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'flex-start',
              alignSelf: 'flex-end',
              flexDirection: 'row',
              marginTop: '3rem',
            }}
            checked
          >
            Evento analisado e confirmado por {selectedAlert?.responsavel}
          </Checkbox>
        </div>
      </div>
    </Drawer>
  );

  const { RangePicker } = DatePicker;

  const handleDateSelected = (dateSelected: any) => {
    if (dateSelected[0] !== undefined && dateSelected[1] !== undefined) {
      const startDate = dateSelected[0].format('YYYY-MM-DD');
      const endDate = dateSelected[1] ? dateSelected[1].format('YYYY-MM-DD') : new Date();
      const entidadeData: AlertFilter = {
        entidade: AlertEntidade.FiltroHorario,
        opcoes: [startDate, endDate],
      };
      setSelectedAdvancedFilters((prevState) => [...prevState, entidadeData]);
    }
  };

  const drawerAdvancedFilters = (
    <Drawer
      title="Filtros avançados"
      placement="right"
      onClose={onCloseDrawerAdvancedFilter}
      open={drawerAdvancedFilter}
      footer={footerFiltrosAvancados}
      textSpan=""
      style={{ overflowX: 'hidden' }}
    >
      <SpinnerSlump spinning={loading}>
        {advancedFilters !== undefined && (
          <div style={{ width: 330 }}>
            <BaseFormInputItem label="Períodos">
              <RangePicker
                format={'DD-MM-YYYY'}
                onChange={(e: any) => handleDateSelected(e)}
                style={{ width: 330, fontWeight: 700 }}
                clearIcon={false}
              />
            </BaseFormInputItem>
            {advancedFilters.map((af) => {
              return (
                <BaseFormInputItem label={af.entidade} key={af.entidade} supportText="Opcional">
                  <Select
                    style={{ width: 330 }}
                    showSearch
                    showArrow
                    key={af.entidade}
                    onChange={(e: any) => handleAdvancedFilter(af.entidade, e)}
                    mode="tags"
                    placeholder={`Selecione uma ou mais ${af.entidade}`}
                    options={af.opcoes.map((o) => ({
                      value: o,
                      label: o,
                    }))}
                    filterOption={(input, option) => option?.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    // tagRender={(props) => (
                    //   <Tag key={props.value} closable>
                    //     {props.label}
                    //   </Tag>
                    // )}
                  />
                </BaseFormInputItem>
              );
            })}
          </div>
        )}
      </SpinnerSlump>
    </Drawer>
  );

  return (
    <>
      {drawerOpen && typeAlertSelected !== AlertType.Novo ? drawerUpdateAlert : drawerConfirmAlert}

      {drawerAdvancedFilter && drawerAdvancedFilters}

      {drawerConfirmedSuccess && (
        <DrawerSuccess
          text="Alerta confirmado
        com sucesso!"
          title="Confirmar alerta"
          placement="right"
          onClose={handleConfirmedSuccess}
          visible={drawerConfirmedSuccess}
          style={{ overflowX: 'hidden' }}
        />
      )}

      {drawerReportedSuccess && (
        <DrawerSuccess
          text="Alerta reportado
        com sucesso!"
          title="Reportar alerta"
          placement="right"
          onClose={handleReportedSuccess}
          open={drawerReportedSuccess}
          style={{ overflowX: 'hidden' }}
        />
      )}

      <PageTitle>Alertas</PageTitle>

      <Spinner spinning={loading}>
        <Row>
          <Col span={24}>
            <Dashboard
              filter={selectedAdvancedFilters.length > 0 && showFilters ? `Filtros selecionados` : ''}
              tags={
                <>
                  {selectedAdvancedFilters.length > 0 && showFilters ? (
                    <>
                      {selectedAdvancedFilters.map((e) => {
                        return (
                          <Tag key={e.entidade} closable onClose={handleCleanFiltersClick}>
                            {e.entidade}
                          </Tag>
                        );
                      })}
                    </>
                  ) : null}
                </>
              }
              buttonText="Filtros"
              icon={<IconFilter style={{ marginRight: '0.625rem', width: '0.75rem', height: '0.75rem' }} />}
              handleButtonClick={handleClick}
              table={
                <>
                  <Row
                    style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', flexWrap: 'wrap' }}
                  >
                    <Col span={24}>
                      <Space
                        style={{
                          padding: '0.125rem 5rem 0rem 1rem',
                          marginBottom: '1.5rem',
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'flex-start',
                          flexWrap: 'wrap',
                        }}
                      >
                        <ButtonTab
                          type="ghost"
                          size="large"
                          badgeCount={novosTotal}
                          severity={typeAlertSelected == AlertType.Novo ? 'warning' : undefined}
                          icon={<IconCampaign style={{ marginRight: '0.825rem', marginLeft: '0.125rem' }} />}
                          description="Novos"
                          onClick={() => handleChangeTypeAlert(AlertType.Novo)}
                        ></ButtonTab>
                        <ButtonTab
                          type="ghost"
                          size="large"
                          badgeCount={confirmadosTotal}
                          severity={typeAlertSelected == AlertType.Confirmado ? 'success' : undefined}
                          icon={<IconCheck style={{ marginRight: '0.825rem', marginLeft: '0.125rem' }} />}
                          description="Confirmados"
                          onClick={() => handleChangeTypeAlert(AlertType.Confirmado)}
                        ></ButtonTab>
                        <ButtonTab
                          type="ghost"
                          size="large"
                          badgeCount={reportadosTotal}
                          severity={typeAlertSelected == AlertType.Reportado ? 'error' : undefined}
                          icon={<IconReport style={{ marginRight: '0.825rem', marginLeft: '0.125rem' }} />}
                          description="Reportados"
                          onClick={() => handleChangeTypeAlert(AlertType.Reportado)}
                        ></ButtonTab>
                      </Space>
                    </Col>
                  </Row>
                  <Table
                    columns={columns}
                    dataSource={tableData.data}
                    loading={false}
                    // pagination={tableData.pagination}
                    // onChange={handleTableChange}
                    scroll={{ x: 800 }}
                    bordered
                  />
                </>
              }
              exportToExcel={handleExportToExcel}
            />
          </Col>
        </Row>
      </Spinner>
    </>
  );
};

export default AlertDashboard;
