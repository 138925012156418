import appSettings from '@app/config/appsettings';
import {
  GoogleMap,
  GoogleMapProps,
  OverlayView,
  DistanceMatrixService,
  useJsApiLoader,
  Circle,
  Polygon,
} from '@react-google-maps/api';
import { useCallback, useEffect, useRef, useState } from 'react';
import * as S from '../styles';
import { IconCaminhaoBetoneiraEsquerda } from '@app/assets/slump-icons';
import HomeIcon from '@app/assets/icons/HomeIcon.svg';
import BalaoBetoneiraIcon from '@app/assets/icons/BalaoBetoneiraIcon.svg';
import { CheckCircleOutlined, FileExcelOutlined, FilePdfOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Col, Row, Tooltip } from 'antd';
import { Tag } from '@app/components/common/Tag/Tag';
import {
  ConstructionModel,
  LocalizationModel,
  ShipmentModel,
  TimeModel,
} from '@app/domain/interfaces/report/reportConcreteMixerModel';
import moment from 'moment';
import { TravelState } from '@app/constants/enums/travelState';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { getDifferenceBetweenDatesInMinutes, minutesToHoursAndMinutes } from '@app/utils/utils';
import { notificationController } from '@app/controllers/notificationController';
import ModalFooter from '../../ModalFooter/ModalFooter';
import { ColumnsType } from 'antd/lib/table';
import { Table } from '../../Table/Table';
import { exportShipmentToExcel, exportShipmentToPdf, renderMixerStatus } from '../functions/mapFunctions';
import IConcreteMixerService, { ConcreteMixerService } from '@app/services/concreteMixerService';
import { ConcreteMixerModel } from '@app/domain/interfaces/concreteMixerModel';

const supermixLocation = {
  lat: -19.878877423869252,
  lng: -43.97931295928024,
  raio: 150,
};

interface MapConcreteMixerProps {
  construction?: ConstructionModel;
  showFooter?: boolean;
}

interface Distance {
  origin: LocalizationModel;
  destination: LocalizationModel;
}

const mixerService: IConcreteMixerService = new ConcreteMixerService();

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const MapConcreteMixer: React.FC<MapConcreteMixerProps> = ({ construction, showFooter }) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const printRef = useRef<any>();
  const mapRef = useRef<google.maps.Map | null>(null);
  const controlsRef = useRef<HTMLDivElement | null>(null);
  const [centerMap, setCenterMap] = useState<LocalizationModel>();
  const [centers, setCenters] = useState<LocalizationModel[]>();
  const [mixersMap, setMixersMap] = useState<ShipmentModel[]>([]);
  const [mixerSelected, setMixerSelected] = useState<ShipmentModel>();
  const [mixerSelectedDuration, setMixerSelectedDuration] = useState<string>();
  const [modalVisible, setModalVisible] = useState(false);
  const mixerHeader = useAppSelector((state) => state.monitoring.mixer);
  const [distance, setDistance] = useState<Distance>();
  const [mixers, setMixers] = useState<ConcreteMixerModel[]>([]);
  const { isLoaded } = useJsApiLoader({
    id: 'mixer-dashboard-map',
    googleMapsApiKey: appSettings().MAPS_API_KEY,
    libraries: ['places', 'geometry'],
  });

  const columns: ColumnsType<ShipmentModel> = [
    {
      title: 'Remessa',
      dataIndex: 'id',
      showSorterTooltip: false,
      sortDirections: [],
      render: (id, shipment) => (
        <>
          {id}&nbsp;
          <Tooltip title={shipment.traco}>
            <InfoCircleOutlined />
          </Tooltip>
        </>
      ),
    },
    {
      title: 'BT',
      dataIndex: 'betoneira',
      showSorterTooltip: false,
      sortDirections: [],
    },
    {
      title: 'Motorista',
      dataIndex: 'motorista',
      showSorterTooltip: false,
      sortDirections: [],
      render: (motorista, shipment) => (
        <>
          {motorista}&nbsp;
          <Tooltip title={shipment.motoristaMatricula}>
            <InfoCircleOutlined />
          </Tooltip>
        </>
      ),
    },
    {
      title: 'Peça',
      dataIndex: 'peca',
      showSorterTooltip: false,
      sortDirections: [],
    },
    {
      title: 'Volume',
      dataIndex: 'volume',
      showSorterTooltip: false,
      sortDirections: [],
      render: (volume) => (volume ? `${volume}m³` : '-'),
    },
    {
      title: 'Água retida',
      dataIndex: 'aguaRetida',
      showSorterTooltip: false,
      sortDirections: [],
      render: (agua) => (agua ? `${agua}L` : '-'),
    },
    {
      title: 'Água central',
      dataIndex: 'agua',
      showSorterTooltip: false,
      sortDirections: [],
      render: (_, shipment) => (shipment.agua?.central ? `${shipment.agua?.central.toFixed(1)}L` : '-'),
    },
    {
      title: 'Água obra',
      dataIndex: 'agua',
      showSorterTooltip: false,
      sortDirections: [],
      render: (_, shipment) => (shipment.agua?.obra ? `${shipment.agua?.obra.toFixed(1)}L` : '-'),
    },
    {
      title: 'Sobra da água',
      dataIndex: 'agua',
      showSorterTooltip: false,
      sortDirections: [],
      render: (_, shipment) => (shipment.agua?.sobraM3 ? `${shipment.agua?.sobraM3.toFixed(1)}L/m³` : '-'),
    },
    {
      title: 'Central',
      dataIndex: 'tempo',
      showSorterTooltip: false,
      sortDirections: [],
      render: (tempo: TimeModel) => minutesToHoursAndMinutes(tempo?.central),
    },
    {
      title: 'A caminho',
      dataIndex: 'tempo',
      showSorterTooltip: false,
      sortDirections: [],
      render: (tempo: TimeModel) => minutesToHoursAndMinutes(tempo?.aCaminho),
    },
    {
      title: 'Aguardando',
      dataIndex: 'tempo',
      showSorterTooltip: false,
      sortDirections: [],
      render: (tempo: TimeModel) => minutesToHoursAndMinutes(tempo?.aguardando),
    },
    {
      title: 'Descarregando',
      dataIndex: 'tempo',
      showSorterTooltip: false,
      sortDirections: [],
      render: (tempo: TimeModel) => minutesToHoursAndMinutes(tempo?.descarregando),
    },
    {
      title: 'Tempo total',
      dataIndex: 'tempo',
      showSorterTooltip: false,
      sortDirections: [],
      render: (tempo: TimeModel) => minutesToHoursAndMinutes(tempo?.total),
    },
    {
      title: 'Estado atual',
      dataIndex: 'estado',
      showSorterTooltip: false,
      sortDirections: [],
      align: 'center',
      render: (estado, record) =>
        estado == TravelState.ChegadaCentral ? (
          <CheckCircleOutlined style={{ color: '#159D3B' }} />
        ) : getDifferenceBetweenDatesInMinutes(record.ultimaAtualizacao ?? new Date(), new Date()) > 10 ? (
          <Tooltip title="Dados inválidos">
            <Tag textColor="#A71916" bgColor="#FEE9EA" style={{ fontSize: '0.75rem' }}>
              {renderMixerStatus(estado)}
            </Tag>
          </Tooltip>
        ) : (
          <Tag textColor="#383838" bgColor="#E8E8E8" style={{ fontSize: '0.75rem' }}>
            {renderMixerStatus(estado)}
          </Tag>
        ),
    },
  ];

  const handleCallBackDistance = (
    response: google.maps.DistanceMatrixResponse | null,
    status: google.maps.DistanceMatrixStatus,
  ) => {
    if (mixerSelected?.estado == TravelState.SaidaCentral || mixerSelected?.estado == TravelState.SaidaObra) {
      if (status === 'OK' && response) {
        setMixerSelectedDuration(response.rows[0].elements[0].duration.text.replace('mins', 'min'));
      } else {
        notificationController.error({ message: 'Erro ao calcular a duração de viagem da betoneira.' });
      }
    }
  };

  const handleMixerClick = (shipment?: ShipmentModel) => {
    if (!shipment) return;

    if (shipment.estado == TravelState.SaidaCentral && shipment.betoneiraLocalizacao && construction?.obraLocalizacao) {
      setDistance({ origin: shipment.betoneiraLocalizacao, destination: construction?.obraLocalizacao });
    } else if (
      shipment.estado == TravelState.SaidaObra &&
      shipment.betoneiraLocalizacao &&
      shipment.centralLocalizacao
    ) {
      setDistance({ origin: shipment.betoneiraLocalizacao, destination: shipment.centralLocalizacao });
    }

    mixerSelected?.betoneira == shipment.betoneira
      ? setMixerSelected(undefined)
      : setMixerSelected({
          ...shipment,
          conectado:
            mixers.find((m) => m.numeroBT == shipment.betoneira)?.connectionState?.toLocaleLowerCase() == 'connected',
        });
  };

  const fetchMixers = useCallback(() => {
    mixerService
      .getArray('obter-todos-com-device')
      .then((res) => {
        setMixers(res.filter((r) => r.deviceId));
      })
      .catch(() => notificationController.error({ message: 'Erro ao buscar a lista de betoneiras.' }));
  }, []);

  const onMapLoad: GoogleMapProps['onLoad'] = useCallback((map: google.maps.Map) => {
    mapRef.current = map;

    map.setOptions({
      gestureHandling: 'cooperative',
      streetViewControl: false,
      mapTypeControl: false,
      fullscreenControl: true,
      zoom: 14,
      fullscreenControlOptions: {
        position: google.maps.ControlPosition.RIGHT_BOTTOM,
      },
      zoomControl: true,
      zoomControlOptions: {
        position: google.maps.ControlPosition.RIGHT_TOP,
      },
    });

    if (map && controlsRef.current) {
      map.controls[google.maps.ControlPosition.RIGHT_TOP].push(controlsRef.current);
    }
  }, []);

  const loadCenters = useCallback((shipments?: ShipmentModel[]) => {
    let newCenters: LocalizationModel[] = [];

    if (shipments) {
      for (const remessa of shipments) {
        if (remessa.centralLocalizacao?.lat && remessa.centralLocalizacao?.lng) {
          newCenters = [
            ...newCenters,
            {
              lat: remessa.centralLocalizacao?.lat,
              lng: remessa.centralLocalizacao?.lng,
              raio: remessa.centralLocalizacao.raio,
            },
          ];
        }
      }
    }

    setCenters(newCenters);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadCenterMap = useCallback((constructionModel?: ConstructionModel) => {
    if (!constructionModel?.remessas?.length && !constructionModel?.obraLocalizacao) {
      setCenterMap(supermixLocation);
      return;
    }

    // eslint-disable-next-line prefer-const
    let coordinates: LocalizationModel[] = [];

    constructionModel?.remessas?.map(
      (m) =>
        m.betoneiraLocalizacao?.lat &&
        m.betoneiraLocalizacao?.lng &&
        coordinates.push({
          lat: m.betoneiraLocalizacao?.lat,

          lng: m.betoneiraLocalizacao?.lng,
        }),
    );

    constructionModel?.remessas?.map(
      (m) =>
        m.centralLocalizacao?.lat &&
        m.centralLocalizacao?.lng &&
        coordinates.push({
          lat: m.centralLocalizacao?.lat,
          lng: m.centralLocalizacao?.lng,
        }),
    );

    if (constructionModel.obraLocalizacao?.lat && constructionModel.obraLocalizacao.lng)
      coordinates.push({
        lat: constructionModel.obraLocalizacao?.lat,
        lng: constructionModel.obraLocalizacao?.lng,
      });

    if (coordinates.length === 0) {
      setCenterMap(supermixLocation);
      return;
    }

    let latSum = 0;
    let LngSum = 0;

    for (const coordinate of coordinates) {
      if (coordinate.lat && coordinate.lng) {
        latSum += coordinate.lat;

        LngSum += coordinate.lng;
      }
    }

    const latMedia = latSum / coordinates.length;
    const lngMedia = LngSum / coordinates.length;

    setCenterMap({
      lat: latMedia,
      lng: lngMedia,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    loadCenters(construction?.remessas);

    // eslint-disable-next-line prefer-const
    let onlyLastShipments: ShipmentModel[] = [];

    const orderedShipments = construction?.remessas?.sort((a, b) =>
      !a.ultimaAtualizacao
        ? 1
        : !b.ultimaAtualizacao
        ? -1
        : new Date(b.ultimaAtualizacao).getTime() - new Date(a.ultimaAtualizacao).getTime(),
    );

    orderedShipments?.map(
      (s) => !onlyLastShipments.find((os) => os.betoneira == s.betoneira) && onlyLastShipments.push(s),
    );

    if (!construction?.obra) setMixersMap(onlyLastShipments);
    else setMixersMap(onlyLastShipments.filter((o) => o.estado != TravelState.AguardandoRemessa));

    if (onlyLastShipments && mixerHeader?.numeroBT) {
      if (mixerSelected?.betoneira != mixerHeader?.numeroBT) {
        const currentShipment = onlyLastShipments.find((r) => r.betoneira == mixerHeader.numeroBT);

        if (currentShipment) {
          setCenterMap(currentShipment.betoneiraLocalizacao);
          handleMixerClick(currentShipment);
        }
      }
      return;
    } else {
      loadCenterMap(construction);
    }

    handleMixerClick({});
    setCenterMap(supermixLocation);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [construction, mixers]);

  useEffect(() => fetchMixers(), [fetchMixers]);
  useEffect(() => {
    if (mixerSelected) {
      setMixerSelected({
        ...mixerSelected,
        conectado:
          mixers.find((m) => m.numeroBT == mixerSelected.betoneira)?.connectionState?.toLocaleLowerCase() ==
          'connected',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mixers]);

  useEffect(() => {
    //feito assim por que o handleCallBackDistance quebra o ciclo
    //la sempre é chamado 2x e sempre com o estado antigo
    setTimeout(() => {
      switch (mixerSelected?.estado) {
        case TravelState.SaidaCentral:
        case TravelState.SaidaObra:
          break;
        case TravelState.AguardandoRemessa:
          setMixerSelectedDuration('-');
          break;
        default:
          setMixerSelectedDuration(
            minutesToHoursAndMinutes(
              getDifferenceBetweenDatesInMinutes(mixerSelected?.estadoInicio ?? new Date(), new Date()),
            ),
          );
          break;
      }
    }, 500);
  }, [mixerSelected?.estado, mixerSelected?.estadoInicio]);

  return (
    <>
      <ModalFooter
        title="Informações das viagens"
        open={modalVisible}
        onCancel={() => setModalVisible(false)}
        bodyStyle={{ overflowY: 'auto' }}
      >
        <div ref={printRef}>
          <Row align={'bottom'}>
            <Col xs={24} md={3}>
              <S.FooterInfoCardDescription>Obra</S.FooterInfoCardDescription>
              <S.FooterInfoCardValue>{construction?.obra ?? '-'}</S.FooterInfoCardValue>
            </Col>
            <Col xs={24} md={15}>
              <S.FooterInfoCardDescription>Endereço</S.FooterInfoCardDescription>
              <S.FooterInfoCardValue>{construction?.obraEndereco ?? '-'}</S.FooterInfoCardValue>
            </Col>
            <Col xs={24} md={6}>
              <Row justify={'end'} gutter={18}>
                <Col>
                  <S.FooterInfoCardLinkButton
                    onClick={() =>
                      exportShipmentToPdf(construction?.remessas ?? [], construction?.obra, construction?.obraEndereco)
                    }
                  >
                    <FilePdfOutlined /> Exportar pdf
                  </S.FooterInfoCardLinkButton>
                </Col>
                <Col>
                  <S.FooterInfoCardLinkButton onClick={() => exportShipmentToExcel(construction?.remessas ?? [])}>
                    <FileExcelOutlined /> Exportar excel
                  </S.FooterInfoCardLinkButton>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row style={{ marginTop: '1rem', marginBottom: '1rem' }}>
            <Col xs={24}>
              <Table columns={columns} dataSource={construction?.remessas} bordered pagination={{ pageSize: 4 }} />
            </Col>
          </Row>
        </div>
      </ModalFooter>
      {isLoaded && (
        <GoogleMap
          mapContainerStyle={S.GoogleMapStyled}
          onLoad={onMapLoad}
          center={centerMap?.lat ? centerMap : supermixLocation}
        >
          {distance?.origin && distance?.destination && (
            <DistanceMatrixService
              options={{
                origins: [distance.origin],
                destinations: [distance.destination],
                travelMode: google.maps.TravelMode.DRIVING,
              }}
              callback={handleCallBackDistance}
            />
          )}
          <S.HeaderInfoCard>
            <Row gutter={18} align={'middle'}>
              <Col>
                <S.HeaderInfoCardTitle>{mixerSelected ? mixerSelected.betoneira : 'BT-0000'}</S.HeaderInfoCardTitle>
              </Col>
              <Col>
                <Tag textColor="#383838" bgColor="#E8E8E8">
                  {renderMixerStatus(mixerSelected?.estado)}
                </Tag>
              </Col>
            </Row>
            <Row style={{ marginTop: '0.2rem' }}>
              {mixerSelected ? (
                <>
                  <S.HeaderInfoCardProperty>Início&nbsp;</S.HeaderInfoCardProperty>
                  <S.HeaderInfoCardPropertyValue>
                    {mixerSelected.estadoInicio ? moment(mixerSelected.estadoInicio).format('HH:mm') : '-'} |
                  </S.HeaderInfoCardPropertyValue>
                  <S.HeaderInfoCardProperty>
                    &nbsp;
                    {mixerSelected.estado == TravelState.SaidaCentral
                      ? 'Estimativa de chegada na obra'
                      : mixerSelected.estado == TravelState.SaidaObra
                      ? 'Estimativa de chegada na central'
                      : 'Duração'}
                    &nbsp;
                  </S.HeaderInfoCardProperty>
                  <S.HeaderInfoCardPropertyValue>{mixerSelectedDuration ?? '-'}</S.HeaderInfoCardPropertyValue>
                </>
              ) : (
                <S.HeaderInfoCardPropertyValue>- | -</S.HeaderInfoCardPropertyValue>
              )}
            </Row>
            <Row style={{ marginTop: '0.5rem' }}>
              <S.HeaderInfoCardProperty>Emissão nota fiscal&nbsp;</S.HeaderInfoCardProperty>
              <S.HeaderInfoCardPropertyValue>
                {mixerSelected?.dataHoraEmissaoNota
                  ? moment(mixerSelected?.dataHoraEmissaoNota).format('DD/MM/yyyy')
                  : '-'}
              </S.HeaderInfoCardPropertyValue>
            </Row>
            <Row style={{ marginTop: '0.5rem' }}>
              <S.HeaderInfoCardProperty>Temp. Ambiente: &nbsp;</S.HeaderInfoCardProperty>
              <S.HeaderInfoCardPropertyValue>
                {mixerSelected?.temperaturaAmbiente != null && mixerSelected?.temperaturaAmbiente != undefined
                  ? `${mixerSelected?.temperaturaAmbiente}º`
                  : '-'}
                &nbsp;|&nbsp;
              </S.HeaderInfoCardPropertyValue>
              <S.HeaderInfoCardProperty>Temp. Concreto: &nbsp;</S.HeaderInfoCardProperty>
              <S.HeaderInfoCardPropertyValue>
                {mixerSelected?.temperaturaConcreto != null && mixerSelected?.temperaturaConcreto != undefined
                  ? `${mixerSelected?.temperaturaConcreto}º`
                  : '-'}
              </S.HeaderInfoCardPropertyValue>
            </Row>
            <Row style={{ marginTop: '0.2rem' }} align={'middle'}>
              <S.HeaderInfoCardProperty>Última atualização às&nbsp;</S.HeaderInfoCardProperty>
              <S.HeaderInfoCardPropertyValue>
                {mixerSelected?.ultimaAtualizacao ? moment(mixerSelected?.ultimaAtualizacao).format('HH:mm') : '-'}
              </S.HeaderInfoCardPropertyValue>
              <S.HeaderInfoCardProperty>&nbsp;de&nbsp;</S.HeaderInfoCardProperty>
              <S.HeaderInfoCardPropertyValue>
                {mixerSelected?.ultimaAtualizacao ? moment(mixerSelected?.ultimaAtualizacao).format('DD/MM/yyyy') : '-'}
                &nbsp;
              </S.HeaderInfoCardPropertyValue>
              <Col>
                {mixerSelected ? (
                  mixerSelected?.conectado ? (
                    <Tag textColor="#083F18" bgColor="#E9F4EE">
                      Conectado
                    </Tag>
                  ) : (
                    <Tag textColor="#620E12" bgColor="#FEE9EA">
                      Desconectado
                    </Tag>
                  )
                ) : (
                  <Tag textColor="#383838" bgColor="#E8E8E8">
                    -
                  </Tag>
                )}
              </Col>
            </Row>
          </S.HeaderInfoCard>

          {/*{showFooter && (*/}
          {/*  <S.FooterInfoCard>*/}
          {/*    <S.FooterInfoCardLabel>Informações da Viagem</S.FooterInfoCardLabel>*/}
          {/*    <Button type="primary" size="small" color="white" onClick={() => setModalVisible(true)}>*/}
          {/*      <PlusOutlined />*/}
          {/*    </Button>*/}
          {/*  </S.FooterInfoCard>*/}
          {/*)}*/}

          {mixersMap
            .filter((m) => m.estado != TravelState.ChegadaCentral && m.betoneira == mixerSelected?.betoneira)
            .map((m, i) =>
              m.betoneiraLocalizacao?.lng && m.betoneiraLocalizacao.lng ? (
                <OverlayView
                  position={{ lat: m.betoneiraLocalizacao.lat, lng: m.betoneiraLocalizacao.lng }}
                  key={i}
                  mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                >
                  <S.ConcreteMixerMarker selected={true}>
                    <IconCaminhaoBetoneiraEsquerda />
                  </S.ConcreteMixerMarker>
                </OverlayView>
              ) : (
                <></>
              ),
            )}

          {construction?.obraLocalizacao?.lat &&
            construction?.obraLocalizacao?.lat > 0 &&
            construction?.obraLocalizacao?.lng &&
            construction?.obraLocalizacao?.lng > 0 && (
              <OverlayView
                position={{ lat: construction.obraLocalizacao?.lat, lng: construction.obraLocalizacao?.lng }}
                mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
              >
                <>
                  <S.PinMarker>
                    <img src={BalaoBetoneiraIcon} alt="" />
                  </S.PinMarker>
                  <Circle
                    center={{ lat: construction.obraLocalizacao?.lat, lng: construction.obraLocalizacao?.lng }}
                    radius={construction.obraLocalizacao?.raio}
                    options={{
                      fillColor: '#861412',
                      fillOpacity: 0.3,
                      strokeColor: '#861412',
                      strokeOpacity: 1,
                      strokeWeight: 2,
                    }}
                  />
                </>
              </OverlayView>
            )}

          {construction?.coordenadas && construction?.coordenadas.length > 0 && (
            <OverlayView
              position={{ lat: construction?.coordenadas[0].lat, lng: construction?.coordenadas[0].lng }}
              mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
            >
              <>
                <S.PinMarker>
                  <img src={BalaoBetoneiraIcon} alt="" />
                </S.PinMarker>
                <Polygon
                  paths={construction?.coordenadas}
                  options={{
                    fillColor: '#861412',
                    fillOpacity: 0.3,
                    strokeColor: '#861412',
                    strokeOpacity: 1,
                    strokeWeight: 2,
                  }}
                />
              </>
            </OverlayView>
          )}

          {centers?.map((c, i) =>
            c.lng && c.lng ? (
              <OverlayView position={{ lat: c.lat, lng: c.lng }} key={i} mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}>
                <>
                  <S.PinMarker>
                    <img src={HomeIcon} alt="" />
                  </S.PinMarker>
                  <Circle
                    center={{ lat: c.lat, lng: c.lng }}
                    radius={c.raio}
                    options={{
                      fillColor: '#861412',
                      fillOpacity: 0.1,
                      strokeColor: '#861412',
                      strokeOpacity: 1,
                      strokeWeight: 2,
                    }}
                  />
                </>
              </OverlayView>
            ) : (
              <></>
            ),
          )}
        </GoogleMap>
      )}
    </>
  );
};

export default MapConcreteMixer;
