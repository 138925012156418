import React from 'react';
import { Row } from 'antd';
import {
  CardInfoProperty,
  CardInfoTitle,
  CardInfoValue,
} from '@app/components/common/Card/CardInfo/CardDeviceInformation.styles';
import * as S from '@app/pages/modules/Reports/components/HeaderInfoDetailDetail/HeaderInfoDetailDetail.styles';
import { TravelInfoDetailsModel } from '@app/domain/interfaces/report/TravelInfoDetailsReport';

interface WaterConcreteInfoProps {
  travelDetails: TravelInfoDetailsModel;
}

export const WaterConcreteInfo: React.FC<WaterConcreteInfoProps> = ({ travelDetails }) => {
  return (
    <>
      <Row>
        <CardInfoTitle>Informações da água no concreto</CardInfoTitle>
      </Row>
      <Row gutter={24}>
        <S.ColStyled>
          <CardInfoProperty>Retida</CardInfoProperty>
          <CardInfoValue style={{ marginTop: 0 }}>{travelDetails.aguaRetida ?? ''} L</CardInfoValue>
        </S.ColStyled>
        <S.ColStyled>
          <CardInfoProperty>Central</CardInfoProperty>
          <CardInfoValue style={{ marginTop: 0 }}>{travelDetails.aguaCentral?.toFixed(1) ?? '-'} L</CardInfoValue>
        </S.ColStyled>
        <S.ColStyled>
          <CardInfoProperty>Obra</CardInfoProperty>
          <CardInfoValue style={{ marginTop: 0 }}>{travelDetails.aguaObra?.toFixed(1) ?? ''} L</CardInfoValue>
        </S.ColStyled>
        <S.ColStyled>
          <CardInfoProperty>Total</CardInfoProperty>
          <CardInfoValue style={{ marginTop: 0 }}>{travelDetails.aguaTotal?.toFixed(1) ?? ''} L</CardInfoValue>
        </S.ColStyled>
        <S.ColStyled>
          <CardInfoProperty>Sobra de água</CardInfoProperty>
          <CardInfoValue style={{ marginTop: 0 }}>{travelDetails.aguaSobra?.toFixed(1) ?? ''} L/m³</CardInfoValue>
        </S.ColStyled>
      </Row>
    </>
  );
};
