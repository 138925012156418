import { TravelState } from '@app/constants/enums/travelState';
import { ShipmentModel } from '@app/domain/interfaces/report/reportConcreteMixerModel';
import { minutesToHoursAndMinutes } from '@app/utils/utils';
import ExcelJS from 'exceljs';
import FileSaver from 'file-saver';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

export const renderMixerStatus = (state?: TravelState): string => {
  switch (state) {
    case TravelState.AguardandoRemessa:
    case TravelState.ChegadaCentral:
      return 'Aguardando remessa';
    case TravelState.ChegadaRemessa:
      return 'Central';
    case TravelState.ChegadaObra:
      return 'Aguardando obra';
    case TravelState.SaidaCentral:
      return 'A caminho';
    case TravelState.SaidaObra:
    case TravelState.FinalDescarga:
      return 'Retornando';
    case TravelState.InicioDescarga:
      return 'Descarregando';
    default:
      return '-';
  }
};

const headers: string[] = [
  'Remessa',
  'Traço',
  'BT',
  'Motorista',
  'Matrícula',
  'Peça',
  'Volume',
  'Água retida',
  'Água central',
  'Água obra',
  'Sobra da água',
  'Central (min)',
  'A caminho (min)',
  'Aguardando (min)',
  'Descarregando (min)',
  'Tempo total (min)',
  'Estado atual',
];

export const exportShipmentToExcel = async (shipments: ShipmentModel[]) => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('data');

  worksheet.addRow(headers);
  shipments.map((s) => {
    worksheet.addRow([
      s.id?.toString() ?? '',
      s.traco?.toString() ?? '',
      s.betoneira?.toString() ?? '',
      s.motorista?.toString() ?? '',
      s.motoristaMatricula?.toString() ?? '',
      s.peca?.toString() ?? '',
      s.volume?.toString() ?? '',
      s.aguaRetida?.toString() ?? '',
      s.agua?.central ? s.agua.central.toFixed(1).toString() : '',
      s.agua?.obra ? s.agua.obra.toFixed(1).toString() : '',
      s.agua?.sobraM3 ? s.agua.sobraM3.toFixed(1).toString() : '',
      s.tempo?.central?.toString() ?? '',
      s.tempo?.aCaminho?.toString() ?? '',
      s.tempo?.aguardando?.toString() ?? '',
      s.tempo?.descarregando?.toString() ?? '',
      s.tempo?.total?.toString() ?? '',
      renderMixerStatus(s.estado),
    ]);
  });

  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
  });
  FileSaver.saveAs(blob, `relatorio_betoneira.xlsx`);
};

export const exportShipmentToPdf = async (shipments: ShipmentModel[], construction?: string, address?: string) => {
  const doc = new jsPDF({ orientation: 'landscape', unit: 'mm' });

  doc.setFontSize(8);
  doc.setTextColor(140, 140, 140);
  doc.text(`Obra:`, 15, 10, {});
  doc.text(`Endereço:`, 80, 10, {});

  doc.setTextColor(84, 84, 84);
  doc.text(`${construction}`, 15, 15, { maxWidth: 60 });
  doc.text(`${address}`, 80, 15, {});

  const data = shipments.map((s) => [
    s.id?.toString() ?? '',
    s.traco?.toString() ?? '',
    s.betoneira?.toString() ?? '',
    s.motorista?.toString() ?? '',
    s.motoristaMatricula?.toString() ?? '',
    s.peca?.toString() ?? '',
    s.volume ? `${s.volume}m³` : '-',
    s.aguaRetida ? `${s.aguaRetida}L` : '-',
    s.agua?.central ? `${s.agua?.central.toFixed(1)}L` : '-',
    s.agua?.obra ? `${s.agua?.obra.toFixed(1)}L` : '-',
    s.agua?.sobraM3 ? `${s.agua?.sobraM3.toFixed(1)}L/m³` : '-',
    minutesToHoursAndMinutes(s.tempo?.central),
    minutesToHoursAndMinutes(s.tempo?.aCaminho),
    minutesToHoursAndMinutes(s.tempo?.aguardando),
    minutesToHoursAndMinutes(s.tempo?.descarregando),
    minutesToHoursAndMinutes(s.tempo?.total),
    renderMixerStatus(s.estado),
  ]);

  autoTable(doc, {
    startY: 20,
    theme: 'plain',
    head: [headers.map((h) => h.replace('(min)', ''))],
    tableLineColor: [232, 232, 232],
    tableLineWidth: 0.05,
    body: data,
    headStyles: {
      fillColor: [245, 245, 245],
      textColor: [0, 0, 0],
      valign: 'middle',
    },
    styles: {
      fontSize: 5,
      cellWidth: 'auto',
    },
    tableWidth: 'auto',
  });

  doc.save('relatorio_betoneira.pdf');
};
