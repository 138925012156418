import styled from 'styled-components';
import { LAYOUT } from '@app/styles/themes/constants';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: ${LAYOUT.desktop.paddingVertical} ${LAYOUT.desktop.paddingHorizontal};
  overflow-x: auto;
`;

export const Header = styled.div`
  padding: 0rem 1rem;
`;
