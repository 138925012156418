import styled, { css } from 'styled-components';

export const GraphContainer = styled.div<{ position: number }>`
  content: ' ';
  width: 25px;
  height: 25px;
  padding: 0.5rem;
  margin-bottom: -0.75rem;
  background: white;
  border-top: 1px solid #bababa;
  border-left: 1px solid #bababa;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  margin-left: ${(props) => `${props.position}px`};
`;

export const SecondGraphContainer = styled.div<{ visible: boolean }>`
  border: 1px solid #bababa;
  padding: 2rem;
  border-radius: 10px;
  padding: 2rem;

  ${({ visible }) =>
    visible
      ? css``
      : css`
          display: none;
        `}
`;
export const CardInfoValue = styled.div`
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.563rem;
  color: var(--text-label);
  margin-top: -0.1;
  overflow-wrap: break-word;
`;

export const CardInfoTitle = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-bottom: 1rem;

  color: #8c8c8c;
  font-family: Mulish;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const CardInfoProperty = styled.div`
  color: var(--Neutral-Gray-Gray700, #545454);
  font-family: 'Mulish';
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-left: 0.5rem;
`;

export const ContainerChart = styled.div`
  display: flex;
  width: 100%;
  padding: 1.25rem;
  border: 1px solid #bababa;
  border-radius: 8px;
`;
