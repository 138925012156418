import React, { useEffect, useState } from 'react';
import * as S from './FillCupWater.styles';

interface FillCupWaterProps {
  fillLevel: number;
}

const FillCupWater: React.FC<FillCupWaterProps> = ({ fillLevel }) => {
  const [isFillAnimating, setIsFillAnimating] = useState<boolean>(false);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (isFillAnimating) {
      timer = setTimeout(() => {
        setIsFillAnimating(false);
      }, 3000);
    }
    return () => clearTimeout(timer);
  }, [isFillAnimating]);

  useEffect(() => {
    if (fillLevel > 0) return setIsFillAnimating(true);

    setIsFillAnimating(false);
  }, [fillLevel]);

  return (
    <S.Cup fillLevel={fillLevel} isFillAnimating={isFillAnimating}>
      {fillLevel > 70 && !isFillAnimating ? (
        <S.BellIcon fillLevel={fillLevel} isFillAnimating={isFillAnimating} />
      ) : null}
    </S.Cup>
  );
};

export default FillCupWater;
