import { Row } from 'antd';
import * as S from './RowCollpasable.styles';
import { Panel } from '../Collapse/Collapse';

interface IRowCollpasableProps {
  header: JSX.Element;
  key: string;
  children: React.ReactNode;
}

const RowCollpasable: React.FC<IRowCollpasableProps> = ({ header, key, children }) => {
  return (
    <S.ContainerCollapse bordered={false} defaultActiveKey={['1']} key={key} ghost collapsible="header">
      <Panel header={header} key={key}>
        <Row>{children}</Row>
      </Panel>
    </S.ContainerCollapse>
  );
};

export default RowCollpasable;
