import { FormItemProps } from 'antd';
import React from 'react';
import { BaseForm } from '../../BaseForm/BaseForm';
import { BaseFormSupportText } from '../BaseFormSupportText/BaseFormSupportText';

export interface BaseFormInputItemProps extends FormItemProps {
  label?: string;
  supportText?: string;
  children?: React.ReactNode;
  successText?: string;
  errorText?: string;
  isSuccess?: boolean;
}

export const BaseFormInputItem = React.forwardRef<HTMLElement, BaseFormInputItemProps>(
  ({ label, children, supportText, isSuccess, successText, errorText, ...props }) => {
    return (
      <BaseForm.Item label={label} $isSuccess={isSuccess} $successText={successText} $errorText={errorText} {...props}>
        {React.cloneElement(children as React.ReactElement, { isSuccess: isSuccess })}
        {!supportText && !isSuccess && !errorText ? (
          <></>
        ) : (
          <BaseFormSupportText
            isSuccess={isSuccess}
            text={isSuccess == null || isSuccess == undefined ? supportText : isSuccess ? successText : errorText}
          />
        )}
      </BaseForm.Item>
    );
  },
);
