import styled from 'styled-components';
import { Drawer as AntDrawer } from 'antd';

export const DrawerRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
`;

export const DrawerMiddleSpan = styled.span`
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
  display: flex;
  align-items: center;
  text-align: center;
  color: var(--secondary-color);
  padding-top: 1rem;
  padding-bottom: 1rem;
`;

export const DrawerContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
`;

export const DrawerSpan = styled.span`
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.375rem;
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  color: var(--tooltip-background);
  flex: none;
  order: 0;
  flex-grow: 0;
`;

export const DrawerCard = styled.div`
  margin-bottom: 0.525rem;
  display: flex;
  align-items: center;
  flex: none;
  order: 0;
  flex-grow: 0;
`;

export const Drawer = styled(AntDrawer)`
  .ant-drawer-content {
    width: 100%;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &:-webkit-scrollbar {
      display: none;
    }
  }

  .ant-drawer-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: var(--background-base-color);
    color: var(--text-label);
    height: 5rem;
  }

  .ant-drawer-header-title {
    height: 2rem;
    display: flex;
    align-items: center;
    margin-bottom: 0.25rem;
  }

  .ant-drawer-title {
    font-size: 1.5rem;
    line-height: 1.75rem;
    font-family: 'Mulish';
    gap: 2rem;
    margin-left: 3rem;
    min-width: 14rem;
    display: flex;
    align-items: center;
  }

  .ant-drawer-close {
    margin-top: 0.5rem;
    margin-left: 19.2rem;
    margin-right: 2.01125rem;
    position: absolute;
  }

  .ant-drawer-extra {
    height: 100%;
    margin-right: 20rem;
    display: flex;
    align-items: center;
  }

  .ant-row {
    margin-bottom: -0.75rem;
  }

  .ant-drawer-body {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow-x: hidden;
  }

  .ant-drawer-footer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 1rem 1.5rem;
    gap: 0.75rem;
    width: 100%;
    height: 5.5rem;
    background: var(--white);
    border-top: 0.063rem solid var(--border-drawer);
  }
`;

export const DrawerClientBoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 99;
  margin-top: -1.5rem;
  margin-bottom: 1rem;
`;

export const DrawerClientBoxText = styled.span`
  background: var(--background-base-color);
  margin: 0;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 600;
  font-size: 0.938rem;
  line-height: 1.375rem;
  color: var(--text-label);
  margin-left: -1.5rem;
  margin-right: -1.5rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;

  &:first-child {
    padding-top: 1rem;
  }

  &:last-child {
    padding-bottom: 1rem;
  }
`;

export const DrawerClientBoxTitle = styled.span`
  background: var(--background-base-color);
  margin: 0;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.375rem;
  color: var(--text-label);
  margin-left: -1.5rem;
  margin-right: -1.5rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  text-align: center;

  &:first-child {
    padding-top: 1rem;
  }

  padding-bottom: 1rem;
`;

export const DrawerClientBoxWaterOrConcreteAlert = styled.span`
  background: var(--background-base-color);
  margin: 0;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.375rem;
  color: var(--text-label);
  margin-left: -1.5rem;
  margin-right: -1.5rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;

  padding-top: 1rem;

  padding-bottom: 1rem;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
`;

export const DrawerClientBoxWaterOrConcreteText = styled.span`
  background: var(--background-base-color);
  margin: 0;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.375rem;
  color: var(--text-label);
  margin-left: -1.5rem;
  margin-right: -1.5rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;

  &:first-child {
    padding-top: 1rem;
  }

  &:last-child {
    padding-bottom: 1rem;
  }
`;
