/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { ConstructionModel } from '@app/domain/interfaces/report/reportConcreteMixerModel';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { notificationController } from '@app/controllers/notificationController';
import IReportService, { ReportService } from '@app/services/reportService';
import { Spinner } from '@app/components/common/Spinner/Spinner';
import MapConcreteMixer from '@app/components/common/Map/ConcreteMixerMap/MapConcreteMixer';
import * as S from '@app/components/common/Map/styles';
import { Col, Row } from 'antd';
import { FileExcelOutlined, FilePdfOutlined } from '@ant-design/icons';
import { exportShipmentToExcel, exportShipmentToPdf } from '@app/components/common/Map/functions/mapFunctions';
import ModalFooterMonitoring from '@app/components/common/ModalFooter/ModalFooterMonitoring';
import { LogMonitoringModel } from '@app/domain/interfaces/monitoring/logMonitoringModel';
import { useDispatch } from 'react-redux';
import { setMixerMonitoring } from '@app/store/slices/monitoringSlice';

interface IModalTripInfo {
  modalVisible: boolean;
  setModalVisible: Dispatch<SetStateAction<boolean>>;
  monitoring: LogMonitoringModel;
}

const reportService: IReportService = new ReportService();

const ModalTripInfo: React.FC<IModalTripInfo> = ({ modalVisible, setModalVisible, monitoring }) => {
  const dispatch = useDispatch();
  const [construction, setConstruction] = useState<ConstructionModel>();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (monitoring.obraId > 0 && modalVisible) {
      setLoading(true);

      reportService
        .getMixerReportByConstruction(monitoring.obraId)
        .then((res) => {
          if (!res?.obras?.[0].id) {
            setConstruction({
              remessas: [
                {
                  ...res?.obras?.[0].remessas?.[0],
                  betoneira:
                    monitoring.betoneirasAguardando.contador > 0
                      ? monitoring.betoneirasAguardando.betoneiras[0]
                      : monitoring.betoneirasCaminho.contador > 0
                      ? monitoring.betoneirasCaminho.betoneiras[0]
                      : monitoring.betoneirasDescarregando.contador > 0
                      ? monitoring.betoneirasDescarregando.betoneiras[0]
                      : monitoring.betoneirasPatio.contador > 0
                      ? monitoring.betoneirasPatio.betoneiras[0]
                      : '',
                  conectado: res?.obras?.[0].remessas?.[0].conectado,
                  betoneiraLocalizacao: res?.obras?.[0].remessas?.[0].betoneiraLocalizacao,
                },
              ],
            });

            notificationController.info({
              message: 'Betoneira não está em viagem no momento.',
            });
            setLoading(false);
            return;
          }

          setConstruction(res?.obras?.[0]);
          dispatch(
            setMixerMonitoring({
              numeroBT: res?.obras?.[0].remessas?.[0].betoneira ?? '',
            }),
          );
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
          setConstruction({});
          notificationController.error({
            message: 'Houve um erro ao buscar dados da betoneira.',
          });
        });

      return;
    }
  }, [modalVisible]);

  return (
    <ModalFooterMonitoring
      title="Mapa das viagens"
      open={modalVisible}
      onCancel={() => setModalVisible(false)}
      bodyStyle={{ overflowY: 'auto' }}
    >
      <Spinner spinning={loading}>
        <Row align={'bottom'}>
          <Col xs={24} md={3}>
            <S.FooterInfoCardDescription>Obra</S.FooterInfoCardDescription>
            <S.FooterInfoCardValue>{construction?.obra ?? '-'}</S.FooterInfoCardValue>
          </Col>
          <Col xs={24} md={15}>
            <S.FooterInfoCardDescription>Endereço</S.FooterInfoCardDescription>
            <S.FooterInfoCardValue>{construction?.obraEndereco ?? '-'}</S.FooterInfoCardValue>
          </Col>
          <Col xs={24} md={6}>
            <Row justify={'end'} gutter={18}>
              <Col>
                <S.FooterInfoCardLinkButton
                  onClick={() =>
                    exportShipmentToPdf(construction?.remessas ?? [], construction?.obra, construction?.obraEndereco)
                  }
                >
                  <FilePdfOutlined /> Exportar pdf
                </S.FooterInfoCardLinkButton>
              </Col>
              <Col>
                <S.FooterInfoCardLinkButton onClick={() => exportShipmentToExcel(construction?.remessas ?? [])}>
                  <FileExcelOutlined /> Exportar excel
                </S.FooterInfoCardLinkButton>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row style={{ marginTop: '1rem', marginBottom: '1rem' }}>
          <Col xs={24}>
            <MapConcreteMixer construction={construction} showFooter={false} />
          </Col>
        </Row>
      </Spinner>
    </ModalFooterMonitoring>
  );
};

export default ModalTripInfo;
