import styled, { css } from 'styled-components';
import { Button as AntButton } from 'antd';
import { Severity } from '@app/constants/interfaces/interfaces';
import { defineColorBySeverity } from '@app/utils/utils';
import { FONT_SIZE, media } from '@app/styles/themes/constants';

interface BtnProps {
  $severity?: Severity;
  $noStyle?: boolean;
}

export const Button = styled(AntButton)<BtnProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;

  ${(props) =>
    props.$noStyle &&
    css`
      width: unset;
      padding: 0;
      height: unset;
    `}

  &[disabled],
  &[disabled]:active,
  &[disabled]:focus,
  &[disabled]:hover {
    color: var(--border-base);
    border-color: var(--disabled-input-color);
    background-color: var(--disabled-input-color);
  }
  ${(props) =>
    !props.danger &&
    css`
      ${props.$severity &&
      css`
        box-shadow: none;
        text-shadow: none;
        background: var(--secondary-color);

        border-color: ${defineColorBySeverity(props.$severity)};

        color: ${defineColorBySeverity(props.$severity)};

        &:hover {
          background: var(--background-color);

          border-color: rgba(${defineColorBySeverity(props.$severity, true)}, 0.9);

          color: rgba(${defineColorBySeverity(props.$severity, true)}, 0.9);
        }

        &:focus {
          background: var(--background-color);

          border-color: rgba(${defineColorBySeverity(props.$severity, true)}, 0.9);

          color: rgba(${defineColorBySeverity(props.$severity, true)}, 0.9);
        }
      `}

      ${props.type === 'text' &&
      css`
        &:hover {
          background: transparent;
          color: var(--secondary-color);
        }
      `}

      ${props.type === 'ghost' &&
      css`
        color: var(--text-label);
        border-color: var(--input-placeholder-color);
        &:hover {
          color: var(--secondary-color);
          background-color: var(--button-hover);
          border-color: var(--secondary-color);
        }
        &[disabled],
        &[disabled]:active,
        &[disabled]:focus,
        &[disabled]:hover {
          color: var(--input-placeholder-color);
          border-color: var(--disabled-bg-color);
          background-color: var(--background-main-color);
        }
      `}

      ${props.type === 'primary' &&
      css`
        background: var(--secondary-color);
        border-color: var(--secondary-color);
        &:hover {
          background: var(--text-dark-color);

          border-color: var(--text-dark-color);
        }
      `}

      ${props.type === 'link' &&
      css`
        & span,
        a {
          text-decoration: none;
        }
      `};
    `}

  .ant-btn-link {
    font-size: ${FONT_SIZE.lg};
    text-decoration: none;
    color: #263668;

    line-height: 1.25rem;
    letter-spacing: 0.04rem;

    display: flex;
    justify-content: start;
    align-items: flex-start;

    &:hover {
      color: #374450;
    }

    @media only screen and (${media.xs}) {
      font-size: ${FONT_SIZE.xxs};
    }

    @media only screen and (${media.md}) {
      font-size: 1rem;
    }
  }
`;
