import React from 'react';
import InitialsAvatar from 'react-initials-avatar';
import 'react-initials-avatar/lib/ReactInitialsAvatar.css';
import * as S from './CardProfile.styles';

interface CardProfileProps {
  nameUser: string;
  email: string;
  // avatar: JSX.Element;
}

export const CardProfile: React.FC<CardProfileProps> = ({ nameUser, email }) => {
  // const img_url = 'https://www.pngmart.com/files/21/Account-Avatar-Profile-PNG-Photo.png';

  return (
    <S.CardProfileContainer>
      <InitialsAvatar name={nameUser.replace('.', ' ')} className="avatar-card-profile" />
      <S.CardProfileWrap>
        <S.CardProfileName>{nameUser}</S.CardProfileName>
        <S.CardProfileEmail>{email}</S.CardProfileEmail>
      </S.CardProfileWrap>
    </S.CardProfileContainer>
  );
};
