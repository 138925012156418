import ExcelJS from 'exceljs';
import { ReportMixerErrorDeviceAnalyticModel } from '@app/domain/interfaces/report/reportMixerErrorAnalytic';
import moment from 'moment';
import FileSaver from 'file-saver';

const headers: string[] = [
  'DeviceId',
  'Imei',
  'Betoneira',
  'Calibração H2',
  'Versão H2',
  'Versão Central',
  'Versão Hermes',
  'Online',
  'Última Obra',
  'Última Filial',
  'Apn',
  'ICCID',
  'Data/Hora Última Remessa',
  'Última Localização Lat',
  'Última Localização Lng',
  'Última Localização Data/Hora',
  'Última Localização Estado',
  'Erro Central',
  'Erro Sensor Pressão de Óleo',
  'Erro Sensor Pressão de Ar',
  'Erro Sensor Indutivo',
  'Erro H2',
  'Erro Rede Can',
  'Erro Rede GNSS',
  'Existe Erro',
  'Data/Hora Geração',
];

export const exportReportMixerErrorDeviceAnalyticToExcel = async (report: ReportMixerErrorDeviceAnalyticModel[]) => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('data');

  worksheet.addRow(headers);

  report.map((r) => {
    worksheet.addRow([
      r.deviceId,
      r.imei,
      r.betoneira,
      r.calibracaoH2,
      r.versaoH2,
      r.versaoCentral,
      r.versaoHermes,
      r.online ? 'Sim' : 'Não',
      r.obra,
      r.filial,
      r.apn,
      r.iccid,
      r.dataHoraUltimaRemessa ? moment(r.dataHoraUltimaRemessa).format('DD/MM/YYYY HH:mm') + 'h' : '-',
      r.ultimaLocalizacaoLat,
      r.ultimaLocalizacaoLng,
      r.ultimaLocalizacaoDataHora ? moment(r.ultimaLocalizacaoDataHora).format('DD/MM/YYYY HH:mm') + 'h' : '-',
      r.ultimaLocalizacaoEstado,
      r.erroCentral ? 'Sim' : 'Não',
      r.erroSensorPressaoOleo ? 'Sim' : 'Não',
      r.erroSensorPressaoAr ? 'Sim' : 'Não',
      r.erroSensorIndutivo ? 'Sim' : 'Não',
      r.erroH2 ? 'Sim' : 'Não',
      r.erroRedeCan ? 'Sim' : 'Não',
      r.erroRedeGnss ? 'Sim' : 'Não',
      r.existeErro ? 'Sim' : 'Não',
      r.dataHoraGeracao ? moment(r.dataHoraGeracao).format('DD/MM/YYYY HH:mm') + 'h' : '-',
    ]);
  });

  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
  });
  FileSaver.saveAs(blob, `relatorio_erros_dispositivo_betoneira.xlsx`);
};
