import styled from 'styled-components';
import { ReactComponent as SpinnerSupermix } from '@app/assets/icons/loadingSlump.svg';
import { FONT_SIZE, media } from '@app/styles/themes/constants';

export const SpinnerSlumpContainer = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.69);

  color: #ffff;
  font-size: 1.25rem;
  letter-spacing: 0.05rem;
  font-weight: 500;
  line-height: 2.5rem;

  @media only screen and (${media.xs}) {
    font-size: ${FONT_SIZE.xs};
  }
  @media only screen and (${media.md}) {
    font-size: ${FONT_SIZE.lg};
  }
  @media only screen and (${media.xxl}) {
    font-size: 1.25rem;
  }
`;

export const Spinner = styled(SpinnerSupermix)`
  width: 5.25rem;
  height: 5.25rem;
  animation: loadingSpinner 3s 0s ease-in-out infinite;
  margin-bottom: 1.25rem;

  @keyframes loadingSpinner {
    0% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(180deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  @media only screen and (${media.xs}) {
    width: 4rem;
    height: 4rem;
  }
  @media only screen and (${media.md}) {
    width: 5rem;
    height: 5rem;
  }
  @media only screen and (${media.xxl}) {
    width: 5.25rem;
    height: 5.25rem;
  }
`;

//  export const Spinner = styled(Spin)``
