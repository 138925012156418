import moment from 'moment';
import ApexCharts from 'apexcharts';
import { useEffect, useState } from 'react';
import { GroupByEnum } from '@app/constants/enums/chart';
import { groupBy } from 'lodash';
import { readFilter, readValue } from '@app/services/localStorage.service';
import { ReportFilterModel } from '@app/domain/interfaces/report/reportFilterModel';

export interface ISeriesDataChart {
  y: any;
  x: any;
  z?: any;
  w?: any;
}

export interface ISeriesChart {
  type: string;
  name: string;
  group: string;
  data: ISeriesDataChart[];
}

interface IDatetimeChart {
  chartId: string;
  type: string;
  groupBy: GroupByEnum;
  seriesData: ISeriesChart[];
  colors: string[];
  selectedBarData?: ISeriesDataChart[] | null;
  isSecondGraph: boolean;
  negativeColor?: string;
  // Novas propriedades para os dados do cabeçalho
  reportHeader?: {
    title: string;
    branch: string;
    numberOfTrips?: number;
    reportDate: Date;
    user: string;
  };
  table?: React.ReactNode;
  handleOpenReportDetailed?: (groupedBy: GroupByEnum, datesClicked: string, valueClicked: string) => void;
}

const DatetimeChart: React.FC<IDatetimeChart> = (datetimeChartModel) => {
  const [isCreated, setIsCreated] = useState(false);

  const handleOpenChartChild = (event: MouseEvent, context: any, config: any) => {
    const { handleOpenReportDetailed } = datetimeChartModel;

    if (handleOpenReportDetailed)
      handleOpenReportDetailed(
        datetimeChartModel.groupBy,
        context.data.twoDSeriesX[config.dataPointIndex],
        datetimeChartModel.seriesData[0]?.data[config.dataPointIndex]?.w,
      );
  };

  //função para agrupamento de data, no apexchart nao faz por default
  const renderSeries = (): ISeriesChart[] => {
    // moment.locale('pt-br');
    return datetimeChartModel.seriesData.map((item) => {
      const grouped = groupBy(item.data, ({ x }) => renderFormat(moment(new Date(x))));
      const serieGrouped = [];

      if (datetimeChartModel.chartId.indexOf('child') > -1) {
        for (const date in grouped) {
          let sum = 0;
          let qtdViagens = 0;
          Array.from(grouped[date]).forEach((serie) => (sum += serie.y * serie.z));
          Array.from(grouped[date]).forEach((serie) => (qtdViagens += serie.z));
          serieGrouped.push({
            x: date,
            y: (sum / qtdViagens).toFixed(1),
            z: qtdViagens > 0 ? qtdViagens : '',
          });
        }
      } else {
        for (const date in grouped) {
          let sum = 0;
          let qtdViagens = 0;
          Array.from(grouped[date]).forEach((serie) => (sum += serie.y));
          Array.from(grouped[date]).forEach((serie) => (qtdViagens += serie.z));
          serieGrouped.push({
            x: date,
            y: (sum / grouped[date].length).toFixed(1),
            z: qtdViagens > 0 ? qtdViagens : '',
          });
        }
      }

      // datetimeChartModel.setLoading(false);
      return {
        ...item,
        data: serieGrouped,
      };
    });
  };

  const renderFormat = (date: moment.Moment) => {
    switch (datetimeChartModel.groupBy) {
      case GroupByEnum.Day:
        return date.format('DD/MM/YYYY');
      case GroupByEnum.Week:
        const week = date.week() - moment(date).startOf('month').week() + 1;
        const month = date.format('MMM');
        return `${week} semana/${month}`;
      case GroupByEnum.Hours:
        return date.format('HH:mm');
      case GroupByEnum.Month:
        return date.format('MMMM/yyyy');
      default:
        return date.format('dd/MM');
    }
  };

  const calculateAdjustedMaxValue = (
    series: ISeriesChart[],
    datetimeChartModel: IDatetimeChart,
    max: number,
  ): number => {
    if (max < 10) return Math.ceil(max / 2) * 2;
    if (max < 40) return Math.ceil(max / 5) * 5;
    return Math.ceil(max / 10) * 10;
  };

  const renderOptions = (series: ISeriesChart[]) => {
    const maxYValue = (max: number) => calculateAdjustedMaxValue(series, datetimeChartModel, max);

    return {
      chart: {
        animations: {
          enabled: false,
        },
        id: datetimeChartModel.chartId,
        type: 'bar',
        width: '100%',
        height: 500,
        stacked: true,
        toolbar: {
          show: false,
        },
        events: {
          dataPointSelection: handleOpenChartChild,
          xAxisLabelClick: handleOpenChartChild,
        },
      },
      fill: {
        opacity: 1,
        fontSize: '20px',
      },
      plotOptions: {
        bar: {
          horizontal: false,
          colors: {
            ranges: [
              {
                from: Number.MIN_SAFE_INTEGER,
                to: 0,
                color: '#FF0000',
              },
            ],
            backgroundBarColors: ['#FFFFFF'],
          },
        },
      },
      grid: {
        strokeDashArray: 0.5,
      },
      series: series,
      colors: datetimeChartModel.colors,
      legend: {
        show: true,
        showForSingleSeries: true,
        position: 'top',
        horizontalAlign: 'right',
        fontSize: '20px',
        fontFamily: 'Mulish',
        fontWeight: 600,
        fontStyle: 'normal',
        markers: {
          width: 16,
          height: 18,
          radius: 0,
        },
      },
      tooltip: {
        style: {
          fontSize: '18px',
          fontFamily: 'Mulish',
          fontWeight: 600,
        },
        z: {
          title: 'Viagens: ',
        },
      },
      yaxis: {
        min: datetimeChartModel.isSecondGraph ? (min: number) => min - 10 + (min % 10) : 0,
        max: maxYValue,
        labels: {
          style: {
            fontSize: '16px',
            fontFamily: 'Mulish',
            fontWeight: 600,
          },
          formatter: (value: number) => value?.toFixed(1),
        },
        forceNiceScale: true,
        annotations: {
          yaxis: [
            {
              y: 0,
            },
          ],
        },
      },
      xaxis: {
        type: 'category',
        tooltip: {
          enabled: false,
        },
        labels: {
          rotate: -66,
          rotateAlways: true,
          style: {
            colors: '#707070',
            fontSize: '18px',
            fontFamily: 'Mulish',
            fontWeight: 600,
          },
        },
        style: {
          fontSize: '16px',
          fontFamily: 'Mulish',
          fontWeight: 600,
        },
      },
      dataLabels: {
        style: {
          fontSize: '20px',
          fontFamily: 'Mulish',
          fontWeight: 600,
        },
      },
    };
  };

  useEffect(() => {
    if (isCreated) {
      ApexCharts.exec(datetimeChartModel.chartId, 'updateOptions', renderOptions(renderSeries()), true, true);
    }
  }, [datetimeChartModel.seriesData, datetimeChartModel.groupBy]);

  useEffect(() => {
    const chart = new ApexCharts(document.getElementById(datetimeChartModel.chartId), renderOptions(renderSeries()));
    chart.render();

    setIsCreated(true);

    return () => {
      chart.destroy();
    };
  }, []);

  return (
    <>
      <div
        style={{
          padding: '2rem',
          pageBreakInside: 'avoid',
          cursor: 'pointer',
        }}
        className="chart-print"
        id={datetimeChartModel.chartId}
      ></div>
      {/* {xAxisSelected && <ViewReportDetails selectedDate={xAxisSelected} table={datetimeChartModel.table} />} */}
    </>
  );
};

export default DatetimeChart;
