import moment from 'moment';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { setHeader } from '@app/store/slices/headerSlice';
import React, { useEffect, useState } from 'react';
import { Spinner } from '@app/components/common/Spinner/Spinner';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { BaseFormInputItem } from '@app/components/common/forms/components/BaseFormInputItem/BaseFormInputItem';
import { Col, DatePicker, Divider, Row } from 'antd';
import { Select } from '@app/components/common/selects/Select/Select';
import { Button } from '@app/components/common/buttons/Button/Button';
import { ClientBranchModel } from '@app/domain/interfaces/client_branch/clientBranchModel';
import { HeaderReports } from '@app/components/common/HeaderReports/HeaderReports';
import { ReactComponent as PdfIcon } from '@app/assets/icons/pdf.svg';
import IClientBranchService, { ClientBranchService } from '@app/services/clientBranchService';
import IGroupService, { GroupService } from '@app/services/groupService';
import { notificationController } from '@app/controllers/notificationController';
import { GroupModel } from '@app/domain/interfaces/client_branch/groupModel';
import { ReportFilterModel } from '@app/domain/interfaces/report/reportFilterModel';
import { IReportType } from '@app/constants/interfaces/interfaces';
import { reportTypes } from '@app/constants/reportTypes';
import { TypeReport } from '@app/constants/enums/report';
import { WaterReportChart } from './WaterReport/WaterReport';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { TimeReport } from './TimeReport/TimeReport';
import { TraceReport } from './TraceReport/TraceReport';
import { Input } from '@app/components/common/inputs/Input/Input';
import { ITraceSelected, setTraceSelected, setTraces } from '@app/store/slices/reportsSlice';
import { DriverReport } from './DriverReport/DriverReport';
import { hasAccessByPermission } from '@app/controllers/accessController';
import { readUser } from '@app/services/localStorage.service';
import { ArgsProps } from 'antd/lib/notification';
import { HeaderCharPDF } from '@app/pages/modules/Reports/components/HeaderPDF/HeaderCharPDF';
import { GroupByEnum } from '@app/constants/enums/chart';
const { RangePicker } = DatePicker;

const groupService: IGroupService = new GroupService();
const branchService: IClientBranchService = new ClientBranchService();

const periods = [
  {
    value: 1,
    label: 'Últimos 7 dias',
  },
  {
    value: 2,
    label: 'Mês atual',
  },
  {
    value: 3,
    label: 'Mês passado',
  },
  {
    value: 4,
    label: 'Personalizar',
  },
];

const ReportsDashboard: React.FC = () => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [generatePdf, setGeneratePdf] = useState(false);
  const [period, setPeriod] = useState<number>();
  const [group, setGroup] = useState<GroupModel>();
  const [numberTravel, setNumberTravel] = useState<number>();
  const [showReport, setShowReport] = useState(false);
  const [groups, setGroups] = useState<GroupModel[]>();
  const [branchSelected, setBranchSelected] = useState<number>();
  const [branches, setBranches] = useState<ClientBranchModel[]>();
  const [showCustomPeriod, setShowCustomPeriod] = useState(false);
  const [customRangeDate, setCustomRangeDate] = useState<(moment.Moment | null)[]>([]);
  const [reportType, setReportType] = useState<IReportType>({} as IReportType);
  const [reportTypesSelecteds, setReportTypesSelecteds] = useState<string[]>([]);
  const [btnGenerateReportDisabled, setBtnGenerateReportDisabled] = useState(false);
  const [reportFilter, setReportFilter] = useState<ReportFilterModel>({} as ReportFilterModel);
  const [reportFilterToComponent, setReportFilterToComponent] = useState<ReportFilterModel>({} as ReportFilterModel);
  const [selectedTraceIndex, setSelectedTraceIndex] = useState<number | undefined>();
  const [selectedTraco, setSelectedTraco] = useState<string | null>(null);

  const { tracos, traceSelected } = useAppSelector((state) => state.reports);

  useEffect(() => {
    setSelectedTraco(traceSelected.trace);
  }, [traceSelected.trace]);

  const fetchCombosData = async () => {
    try {
      setLoading(true);

      const groupIds: number[] = [];
      const resGroups = await groupService.getArray('');
      const resBranches = await branchService.getArray('');

      resBranches?.filter((cb) => cb.codigoFilialSiac).map((cb) => groupIds.push(cb.agrupamentos.idAgrupamento));

      setGroups(resGroups.filter((g) => groupIds.includes(g.id)));
      setBranches(resBranches);

      setLoading(false);
    } catch (error) {
      notificationController.error(error as ArgsProps);
      setLoading(false);
    }
  };

  useEffect(() => {
    setBranchSelected(undefined);
  }, [group?.id]);

  const renderReportChart = () => {
    switch (reportType.type) {
      case TypeReport.Water:
        return (
          <WaterReportChart
            filter={reportFilterToComponent}
            typesSelecteds={reportTypesSelecteds}
            handleOpenReportDetailed={handleOpenReportDetailed}
          />
        );
      case TypeReport.Time:
        return (
          <TimeReport
            filter={reportFilterToComponent}
            typeSelecteds={reportTypesSelecteds}
            handleOpenReportDetailed={handleOpenReportDetailed}
          />
        );
      case TypeReport.Driver:
        return (
          <DriverReport
            filter={reportFilterToComponent}
            typeSelecteds={reportTypesSelecteds}
            handleOpenReportDetailed={handleOpenReportDetailed}
          />
        );
      case TypeReport.Trace:
        return (
          <TraceReport
            filter={reportFilterToComponent}
            typeSelecteds={reportTypesSelecteds}
            selectedBarIndex={selectedTraceIndex}
            handleOpenReportDetailed={handleOpenReportDetailed}
          />
        );
      default:
        break;
    }
  };

  const handleOpenReportDetailed = (groupedBy: GroupByEnum, datesClicked: string, valueClicked?: string) => {
    // eslint-disable-next-line prefer-const
    let periodClicked = unrenderFormat(groupedBy, datesClicked);

    if (!periodClicked) return;

    if (reportFilter.dataHoraInicio.getTime() > periodClicked.startDate.getTime())
      periodClicked.startDate = reportFilter.dataHoraInicio;
    if (reportFilter.dataHoraFim.getTime() < periodClicked.endDate.getTime())
      periodClicked.endDate = reportFilter.dataHoraFim;

    const params = {
      dataHoraInicio: moment(periodClicked.startDate).format('DD-MM-YYYY'),
      dataHoraFim: moment(periodClicked.endDate).format('DD-MM-YYYY'),
      codigosFilialSiac: reportFilter.codigosFilialSiac.join(','),
      tipoDado: reportType.children
        .filter((id) => reportTypesSelecteds.includes(id.logicalName))
        .map((obj) => obj.name)
        .join(','),
      nomeGrupo: group?.nome ?? '',
      valorClicado: valueClicked ?? '',
      numeroMinViagens: reportFilter.numeroMinViagem?.toString() ?? '',
    };

    window.open(`relatorio/detalhamento/${reportType.type}?${new URLSearchParams(params).toString()}`, '_blank');
  };

  const unrenderFormat = (groupedBy: GroupByEnum, date: string) => {
    switch (groupedBy) {
      case GroupByEnum.Day:
        return {
          startDate: moment(date, 'DD/MM/YYYY').toDate(),
          endDate: moment(date, 'DD/MM/YYYY').toDate(),
        };
      case GroupByEnum.Week:
        const separetedDate = date.split('/');
        const week = separetedDate[0].replace(' semana', '');
        const month = separetedDate[1];

        if (week && month) {
          const numberOfWeek = Number(week) - 1;
          const numberOfMonth = moment().month(month).month();

          let firstDayOfWeek = moment()
            .set({ month: numberOfMonth, date: 1 })
            .startOf('week')
            .add(numberOfWeek, 'weeks');

          let lastDayOfWeek = firstDayOfWeek.clone().endOf('week');

          if (firstDayOfWeek.month() != numberOfMonth)
            firstDayOfWeek = moment().set({ month: numberOfMonth, date: 1 }).startOf('month');
          if (lastDayOfWeek.month() != numberOfMonth)
            lastDayOfWeek = moment().set({ month: numberOfMonth, date: 1 }).endOf('month');

          return {
            startDate: firstDayOfWeek.toDate(),
            endDate: lastDayOfWeek.toDate(),
          };
        }
        return null;
      case GroupByEnum.Month:
        const momentDate = moment(date, 'MMMM/YYYY');

        return {
          startDate: momentDate.startOf('month').toDate(),
          endDate: momentDate.endOf('month').toDate(),
        };
      default:
        return null;
    }
  };

  const handleGetReport = () => {
    setShowReport(true);
    setReportFilterToComponent(reportFilter);
    setBtnGenerateReportDisabled(true);
  };

  const handlerOnDeselectReportType = async (value: any) => {
    setReportTypesSelecteds(reportTypesSelecteds.filter((id) => id != value));
  };

  const handlerOnSelectReportType = async (value: any) => {
    setReportTypesSelecteds(reportTypesSelecteds.concat(value));
  };

  const handleChangeRangePicker = (dates: [moment.Moment, moment.Moment]) => {
    setCustomRangeDate(dates);
    setReportFilter({
      ...reportFilter,
      dataHoraInicio: dates[0].hours(12).utc().toDate(),
      dataHoraFim: dates[1].hours(12).utc().toDate(),
    });
  };

  const handleCleanFilters = async () => {
    setShowReport(false);
    setShowCustomPeriod(false);
    setBranchSelected(undefined);
    setGroup({} as GroupModel);
    setReportType({} as IReportType);
    setReportTypesSelecteds([]);
    setReportFilter({} as ReportFilterModel);
    setCustomRangeDate([]);
    setPeriod(undefined);
    setShowReport(false);
    setNumberTravel(undefined);
    dispatch(setTraces([]));
  };

  const handlePeriodChange = (id: number) => {
    setPeriod(id);
    setShowCustomPeriod(id === 4);

    switch (id) {
      case 1:
        setReportFilter({
          ...reportFilter,
          dataHoraFim: new Date(),
          dataHoraInicio: moment().utc().add(-7, 'd').toDate(),
        });
        break;
      case 2:
        setReportFilter({
          ...reportFilter,
          dataHoraFim: moment().utc().endOf('month').toDate(),
          dataHoraInicio: moment().utc().startOf('month').toDate(),
        });
        break;
      case 3:
        setReportFilter({
          ...reportFilter,
          dataHoraFim: moment().utc().add(-1, 'M').endOf('month').toDate(),
          dataHoraInicio: moment().utc().add(-1, 'M').startOf('month').toDate(),
        });
        break;
      default:
        break;
    }
  };

  const handleChangeGroup = (id: number) => {
    setGroup(groups?.find((group) => group.id == (id as number)) ?? ({} as GroupModel));
    setReportFilter({
      ...reportFilter,
      codigosFilialSiac:
        branches?.filter((cb) => cb.agrupamentos.idAgrupamento == id).map((cb) => cb.codigoFilialSiac) ?? [],
    });
  };

  const handleChangeBranch = (id: number) => {
    if (id == 0) {
      setReportFilter({
        ...reportFilter,
        codigosFilialSiac:
          branches?.filter((cb) => cb.agrupamentos.idAgrupamento == group?.id).map((cb) => cb.codigoFilialSiac) ?? [],
      });
      setBranchSelected(id);
    } else {
      const branch = branches?.find((branch) => branch.id == id) ?? ({} as ClientBranchModel);
      // setBranch(branch);
      setReportFilter({
        ...reportFilter,
        codigosFilialSiac: [branch.codigoFilialSiac],
      });
    }
    setBranchSelected(id);
  };

  const handleClickExportPdf = () => {
    setLoading(true);
    setGeneratePdf(true);
  };

  const handleNumberInputChange = (num: number) => {
    if (num >= 0) {
      setReportFilter({
        ...reportFilter,
        numeroMinViagem: num,
      });
      setNumberTravel(num);
    } else {
      setNumberTravel(undefined);
      setReportFilter({
        ...reportFilter,
        numeroMinViagem: undefined,
      });
    }
    setNumberTravel(num);
  };

  const handleChangeTrace = (e: any) => {
    setSelectedTraceIndex(e);
    if (tracos && tracos.length > 0) {
      setSelectedTraco(tracos[e]);
      const newTraceSelected: ITraceSelected = {
        barClicked: true,
        barIndex: 0,
        trace: tracos[e],
      };
      dispatch(setTraceSelected(newTraceSelected));
    }
  };

  //feito desta forma para o loading funcionar normalmente
  useEffect(() => {
    if (!generatePdf) return;

    const charts = document.querySelectorAll('.chart-print');
    const headerElement = document.querySelector('.header-chart-print-pdf') as HTMLElement;
    headerElement.style.display = 'block';

    if (!charts && Array.from(charts).length <= 0) {
      setLoading(false);
      return;
    }

    const pdf = new jsPDF({ orientation: 'portrait', unit: 'mm' });
    const tasks = Array.from(charts).map((chart) => html2canvas(chart as HTMLElement));
    const header = html2canvas(document.querySelector('.header-chart-print-pdf') as HTMLElement);
    tasks.unshift(header);

    Promise.all(tasks)
      .then((canvases) => {
        let chartCounter = 0;
        let chartPosition = 0;
        let isFirstPage = true;

        for (const canvas of canvases) {
          if (canvas.width) {
            if (!isFirstPage && chartCounter >= 5) {
              chartPosition = 0;
              chartCounter = 0;
              pdf.addPage();
            } else if (isFirstPage && chartCounter >= 4) {
              isFirstPage = false;
              chartPosition = 0;
              chartCounter = 0;
              pdf.addPage();
            }

            const imgData = canvas.toDataURL('image/png');
            const imgProps = pdf.getImageProperties(imgData);
            const pageWidth = pdf.internal.pageSize.getWidth();
            const pageHeight = (imgProps.height * pageWidth) / imgProps.width;
            pdf.addImage(imgData, 'PNG', 0, chartPosition, pageWidth, pageHeight);
            chartPosition += pageHeight + 5;
            chartCounter++;
          }
        }
        headerElement.style.display = 'none';

        pdf.save(`relatorio.pdf`);
        setLoading(false);
        setGeneratePdf(false);
      })
      .catch(() => {
        notificationController.error({ message: 'Ocorreu um erro ao gerar o relatório.' });
        setLoading(false);
        setGeneratePdf(false);
      });
  }, [generatePdf]);

  useEffect(() => {
    dispatch(
      setHeader({
        title: 'Relatórios',
      }),
    );
    fetchCombosData();
  }, []);

  useEffect(() => {
    if ((reportType.type == TypeReport.Trace || reportType.type == TypeReport.Driver) && !numberTravel) {
      setBtnGenerateReportDisabled(true);
      return;
    }

    if (
      reportTypesSelecteds.length > 0 &&
      reportFilter.codigosFilialSiac?.length > 0 &&
      reportFilter.dataHoraInicio &&
      reportFilter.dataHoraFim
    ) {
      setBtnGenerateReportDisabled(false);
      return;
    }

    setBtnGenerateReportDisabled(true);
  }, [reportFilter, reportTypesSelecteds, reportType]);

  const getSelectedLabels = () => {
    if (!reportType?.children || !reportTypesSelecteds) return '';

    const selectedLabels = reportTypesSelecteds
      .map((selectedValue) => {
        const foundOption = reportType.children.find((option) => option.logicalName === selectedValue);
        return foundOption ? foundOption.name : '';
      })
      .filter((label) => label);

    return selectedLabels.join(' - ');
  };

  return (
    <>
      <PageTitle>Relatórios</PageTitle>
      <Spinner spinning={loading}></Spinner>
      <HeaderReports>
        <BaseForm
          style={{
            marginBottom: '2rem',
            width: '100%',
          }}
        >
          <Row gutter={18}>
            <Col xs={24} md={4}>
              <BaseFormInputItem label="Relatórios">
                <Select
                  disabled={showReport === true}
                  showSearch
                  showArrow
                  value={reportType.type}
                  onChange={(value) =>
                    setReportType(
                      reportTypes?.find((type) => type.type.toString() == (value as number).toString()) ??
                        ({} as IReportType),
                    )
                  }
                  options={reportTypes
                    .filter((type) => hasAccessByPermission('report', type.permission))
                    .map((type) => ({ value: type.type, label: type.name }))}
                  placeholder="Selecione o tipo de relatório"
                />
              </BaseFormInputItem>
            </Col>
            <Col xs={24} md={4}>
              <BaseFormInputItem label="Tipo de dado" supportText="Pesquise e selecione de um ou mais tipos">
                <Select
                  value={reportTypesSelecteds}
                  options={reportType?.children?.map((type) => ({ value: type.logicalName, label: type.name }))}
                  filterOption={(input, option) => option?.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  mode="tags"
                  showSearch
                  showArrow
                  onSelect={handlerOnSelectReportType}
                  onDeselect={handlerOnDeselectReportType}
                  placeholder="Selecione o tipo de dado"
                />
              </BaseFormInputItem>
            </Col>
            {tracos && tracos.length > 0 && (
              <Col xs={24} md={4}>
                <BaseFormInputItem label="Tipo de traço" supportText="">
                  <Select
                    value={selectedTraco}
                    options={tracos.map((t, index) => ({ value: index, label: t }))}
                    filterOption={(input, option) => option?.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    showSearch
                    showArrow
                    onChange={handleChangeTrace}
                    placeholder="Selecione o tipo de dado"
                  />
                </BaseFormInputItem>
              </Col>
            )}
            {selectedTraceIndex && (
              <Col xs={24} md={8}>
                <BaseFormInputItem label="Número mínimo de viagens">
                  <Select
                    value={selectedTraceIndex}
                    // options={reportType?.children?.map((type) => ({ value: type.logicalName, label: type.name }))}
                    // filterOption={(input, option) => option?.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    showSearch
                    showArrow
                    onChange={(value) => setSelectedTraceIndex(value as number)}
                    placeholder="Selecione o tipo de dado"
                  />
                </BaseFormInputItem>
              </Col>
            )}
            {(reportType.type === TypeReport.Trace || reportType.type === TypeReport.Driver) && (
              <Col xs={24} md={4}>
                <BaseFormInputItem label="Número mínimo de viagens">
                  <Input
                    value={numberTravel}
                    type="number"
                    placeholder="Digite o número"
                    onChange={(value) => handleNumberInputChange(parseInt(value.target.value, 0))}
                  />
                </BaseFormInputItem>
              </Col>
            )}
            <Col xs={24} md={4}>
              <BaseFormInputItem label="Tipo do período">
                <Select
                  value={period}
                  options={periods}
                  onChange={(value) => handlePeriodChange(value as number)}
                  placeholder="Selecione o tipo do perído"
                />
              </BaseFormInputItem>
            </Col>
            {showCustomPeriod && (
              <Col xs={24} md={4}>
                <BaseFormInputItem label="Períodos">
                  <RangePicker
                    value={[customRangeDate[0], customRangeDate[1]]}
                    onChange={(values) => handleChangeRangePicker(values as [moment.Moment, moment.Moment])}
                    format={'DD-MM-YYYY'}
                    style={{ height: '3.375rem' }}
                    clearIcon={false}
                  />
                </BaseFormInputItem>
              </Col>
            )}
            <Col xs={24} md={4}>
              <BaseFormInputItem label="Regional">
                <Select
                  showSearch
                  showArrow
                  onChange={(value) => handleChangeGroup(value as number)}
                  value={group?.id}
                  options={groups?.map((group) => ({
                    value: group.id,
                    label: group.nome,
                  }))}
                  filterOption={(input, option) => option?.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  placeholder="Selecione a regional"
                ></Select>
              </BaseFormInputItem>
            </Col>
            <Col xs={24} md={4}>
              <BaseFormInputItem label="Filial">
                <Select
                  disabled={!group?.id}
                  value={branchSelected}
                  showSearch
                  showArrow
                  onChange={(value) => handleChangeBranch(value as number)}
                  options={branches
                    ?.filter((cb) => cb.agrupamentos.idAgrupamento == group?.id && cb.codigoFilialSiac)
                    .map((cb) => ({ value: cb.id, label: cb.nome }))
                    .concat({ value: 0, label: 'Selecionar Todos' })}
                  filterOption={(input, option) => option?.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  placeholder="Selecione a filial"
                />
              </BaseFormInputItem>
            </Col>
          </Row>

          <Row justify="end" align="middle" gutter={18} style={{ marginTop: '1rem' }}>
            {showReport && (
              <Col>
                <Button
                  type="link"
                  icon={<PdfIcon />}
                  style={{ color: 'var(--secondary-color)' }}
                  onClick={handleClickExportPdf}
                >
                  Exportar gráfico pdf
                </Button>
              </Col>
            )}
            {/* <Col>
                <Button type="link" icon={<ExcelIcon />} style={{ color: 'var(--secondary-color)' }}>
                  Exportar dados excel
                </Button>
              </Col> */}
            <Col>
              <Button type="ghost" onClick={handleCleanFilters} style={{ width: '14.75rem', height: '3.5rem' }}>
                Limpar filtros
              </Button>
            </Col>
            <Col>
              <Button
                disabled={btnGenerateReportDisabled}
                type="primary"
                onClick={handleGetReport}
                style={{ width: '14.75rem', height: '3.5rem' }}
              >
                Gerar relatório
              </Button>
            </Col>
          </Row>
        </BaseForm>
      </HeaderReports>
      <Divider type="horizontal" style={{ borderColor: 'var(--disabled-bg-color)' }} />
      {showReport ? (
        renderReportChart()
      ) : (
        <>
          <Row justify="center" align="middle" style={{ marginTop: '10rem' }}>
            <Col xs={24} md={24} style={{ color: '#707070', fontSize: '1rem', fontWeight: 700, textAlign: 'center' }}>
              Nenhum resultado exibido
            </Col>
            <Col
              xs={24}
              md={24}
              style={{ color: '#707070', fontSize: '0.875rem', fontWeight: 400, textAlign: 'center' }}
            >
              Selecione o relatório e configure os filtros para exibir os resultados
            </Col>
          </Row>
        </>
      )}
      <HeaderCharPDF
        className={'header-chart-print-pdf'}
        style={{ display: 'none' }}
        reportName={reportType.name}
        dataType={getSelectedLabels()}
        periodType={periods.find((p) => p.value == period)?.label ?? '-'}
        period={
          moment(reportFilter?.dataHoraInicio, 'DD-MM-YYYY').format('DD/MM/YYYY') +
          ' - ' +
          moment(reportFilter?.dataHoraFim, 'DD-MM-YYYY').format('DD/MM/YYYY')
        }
        regional={group?.nome ?? '-'}
        branch={branches?.find((b) => b.id == branchSelected)?.nome ?? '-'}
        numeroViagens={numberTravel}
        userName={readUser()?.username ?? '-'}
        withdrawnDate={moment().format('DD-MM-YYYY')}
      />
    </>
  );
};

export default ReportsDashboard;
