import React from 'react';
import * as S from './FooterListItem.styles';

export interface FooterLisItemtProps {
  betoneira?: string;
  tempoRetorno?: string;
  motorista?: string;
}

export const FooterListItem: React.FC<FooterLisItemtProps> = ({ tempoRetorno, betoneira, motorista }) => {
  return (
    <S.FooterListContainer>
      <S.FooterListWrapper>
        <S.FooterListItems>
          <S.FooterNumeroBT>{betoneira}</S.FooterNumeroBT>
          {/* <S.FooterDriver>{motorista}</S.FooterDriver> */}
          <S.FooterTime>{tempoRetorno}</S.FooterTime>
        </S.FooterListItems>
      </S.FooterListWrapper>
    </S.FooterListContainer>
  );
};
