import React from 'react';
import { DrawerProps } from 'antd';
import * as S from './DrawerSuccess.styles';
import { ReactComponent as SmallLogo } from '@app/assets/white-small-logo.svg';
import Lottie from 'lottie-react';
import successAnimation from '@app/assets/lotties/success.json';

interface CustomDrawerProps extends DrawerProps {
  text: string;
}

export const DrawerSuccess: React.FC<CustomDrawerProps> = ({ ...props }) => {
  return (
    <S.Drawer
      getContainer={false}
      {...props}
      headerStyle={{
        backgroundColor: '#263668',
        color: '#FFF',
      }}
      extra={
        <div
          style={{
            display: 'flex',
          }}
        >
          <SmallLogo width="2.25rem" height="2rem" />
        </div>
      }
    >
      <Lottie animationData={successAnimation} />
      <S.DrawerSpan>{props.text}</S.DrawerSpan>
    </S.Drawer>
  );
};
