import { Row as AntRow } from 'antd';
import styled from 'styled-components';

type RowProps = {
  marginBottom?: string;
  hideWhenXs?: boolean;
};

export const Row = styled(AntRow)<RowProps>`
  margin-bottom: ${(props) => props.marginBottom || '16px'};

  ${(props) => props.hideWhenXs && `@media (max-width: 1280px) { display: none; }`}
`;

export const CardInfoColumnTitle = styled.div`
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.563rem;
  color: var(--input-placeholder-color);
  text-align: center;
`;

export const CardInfoName = styled.div`
  color: var(--neutral-gray-gray-800, #383838);
  font-family: Mulish;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const CardInfoStatus = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
