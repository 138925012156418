import { ApiServiceBase, IApiServiceBase } from './apiServiceBase';
import { SummaryAlertModel } from '@app/domain/interfaces/alert/summaryAlertModel';

export default interface ISummaryAlertService extends IApiServiceBase<SummaryAlertModel, SummaryAlertModel> {}

export class SummaryAlertService
  extends ApiServiceBase<SummaryAlertModel, SummaryAlertModel>
  implements ISummaryAlertService
{
  constructor() {
    super('admin/alertaResumo');
  }
}
