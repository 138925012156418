import { GroupModel } from '@app/domain/interfaces/client_branch/groupModel';
import { ApiServiceBase, IApiServiceBase } from './apiServiceBase';

export default interface IGroupService extends IApiServiceBase<GroupModel, GroupModel> {}

export class GroupService extends ApiServiceBase<GroupModel, GroupModel> implements IGroupService {
  constructor() {
    super('admin/agrupamentos');
  }
}
