import styled from 'styled-components';
import { Alert as AntAlert } from 'antd';

export const Alert = styled(AntAlert)`
  color: var(--black);

  .ant-alert-message {
    color: var(--black);
  }
`;
