import {
  SendCommandModelRequest,
  SendCommandModelResponse,
} from '@app/domain/interfaces/sendCommandModel/sendCommandModel';
import { ApiServiceBase, IApiServiceBase } from './apiServiceBase';

export default interface ISendCommandService
  extends IApiServiceBase<SendCommandModelRequest, SendCommandModelResponse> {}

export class SendCommandService extends ApiServiceBase<SendCommandModelRequest, SendCommandModelResponse> {
  constructor() {
    super('admin/enviar-comando');
  }
}
