import { ReactComponent as Logo } from '@app/assets/images/Logo-Azul-Vertical.svg';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { Input } from '@app/components/common/inputs/Input/Input';
import { InputPassword } from '@app/components/common/inputs/InputPassword/InputPassword.styles';
import * as Auth from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import { getFirstUrlWithAccess } from '@app/controllers/accessController';
import { notificationController } from '@app/controllers/notificationController';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { readUser } from '@app/services/localStorage.service';
import { doLogin } from '@app/store/slices/authSlice';
import { Form, Space } from 'antd';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import * as S from './LoginForm.styles';

interface LoginFormData {
  username: string;
  password: string;
}

export const initValues: LoginFormData = {
  username: '',
  password: '',
};

export const LoginForm: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();

  const [isLoading, setLoading] = useState(false);

  const handleOnPaste = (e: React.ClipboardEvent) => {
    form.setFieldValue('password', e.clipboardData.getData('text').trim());
    e.preventDefault();
  };

  const handleSubmit = (values: LoginFormData) => {
    const { username, password } = values;
    setLoading(true);
    dispatch(doLogin({ username: username, password: password }))
      .unwrap()
      .then(() => {
        const user = readUser();
        if (user?.firstAccess) {
          navigate('/auth/first-access');
        } else {
          navigate(getFirstUrlWithAccess() ?? '/');
        }
      })
      .catch(() => {
        notificationController.error({ message: 'Usuário ou senha incorreto.' });
        setLoading(false);
      });
  };

  return (
    <Auth.FormWrapper>
      <Logo style={{ marginBottom: '2rem' }} />
      <BaseForm form={form} layout="vertical" onFinish={handleSubmit}>
        <Auth.FormTitle>Bem Vindo!</Auth.FormTitle>
        <Auth.FormDescription>Utilize seus dados cadastrados para acessar a plataforma</Auth.FormDescription>
        <Form.Item name="username" rules={[{ required: true, message: 'Campo obrigatório' }]} label="Usuário">
          <Input placeholder="Digite seu usuário" />
        </Form.Item>
        <Form.Item name="password" rules={[{ required: true, message: 'Campo obrigatório' }]} label="Senha">
          <InputPassword type="password" placeholder="Digite sua senha" onPaste={handleOnPaste} />
        </Form.Item>

        <Auth.ActionsWrapper>
          <Link to="/auth/forgot-password">
            <S.ForgotPasswordText>Esqueceu sua senha?</S.ForgotPasswordText>
          </Link>
        </Auth.ActionsWrapper>
        <BaseForm.Item noStyle>
          <Space direction="vertical" style={{ width: '100%' }}>
            <Auth.SubmitButton type="primary" htmlType="submit" loading={isLoading}>
              Entrar
            </Auth.SubmitButton>
          </Space>
        </BaseForm.Item>
      </BaseForm>
    </Auth.FormWrapper>
  );
};
