import { ConfigAlertModel } from '@app/domain/interfaces/configAlertModel';
import { ApiServiceBase, IApiServiceBase } from './apiServiceBase';

export default interface IConfigAlertService extends IApiServiceBase<ConfigAlertModel, ConfigAlertModel> {}

export class ConfigAlertService
  extends ApiServiceBase<ConfigAlertModel, ConfigAlertModel>
  implements IConfigAlertService
{
  constructor() {
    super('admin/alertas/config');
  }
}
