import React from 'react';
import { TravelInfoDetailsModel } from '@app/domain/interfaces/report/TravelInfoDetailsReport';
import * as S from './HeaderButtons.styles';
import { Button } from '@app/components/common/buttons/Button/Button';
import IconFilePdf from '@app/assets/slump-icons/IconFilePdf';

interface HeaderButtonsProps {
  travelDetails: TravelInfoDetailsModel;
  setOpenAlertModal: React.Dispatch<React.SetStateAction<boolean>>;
  exportToPdf: () => void;
}

export const HeaderButtons: React.FC<HeaderButtonsProps> = ({ travelDetails, setOpenAlertModal, exportToPdf }) => {
  const handleExportToPdf = () => {
    exportToPdf();
  };

  return (
    <S.ButtonContainer>
      <Button type="link" icon={<IconFilePdf />} onClick={handleExportToPdf} style={{ color: 'var(secondary-color)' }}>
        Exportar pdf da página
      </Button>
      <Button
        type="primary"
        onClick={() => {
          setOpenAlertModal(true);
        }}
        style={{ width: '236px' }}
      >
        Alerta
      </Button>
    </S.ButtonContainer>
  );
};
