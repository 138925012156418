import MapConcreteMixer from '@app/components/common/Map/ConcreteMixerMap/MapConcreteMixer';
import { PageContainer } from '@app/components/common/PageContainer/PageContainer';
import { Spinner } from '@app/components/common/SpinSlump/SpinSlump.styles';
import React, { useEffect, useState } from 'react';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { ConstructionModel } from '@app/domain/interfaces/report/reportConcreteMixerModel';
import IReportService, { ReportService } from '@app/services/reportService';
import { notificationController } from '@app/controllers/notificationController';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';

const reportService: IReportService = new ReportService();

const ConcreteMixerMonitoringDashboard: React.FC = ({}) => {
  const [construction, setConstruction] = useState<ConstructionModel>();
  const mixer = useAppSelector((state) => state.monitoring.mixer);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (mixer?.deviceId) {
      setLoading(true);

      reportService
        .getMixerReportByDevice(mixer.deviceId, mixer.numeroBT)
        .then((res) => {
          if (!res?.obras?.[0].id) {
            setConstruction({
              remessas: [
                {
                  ...res?.obras?.[0].remessas?.[0],
                  betoneira: mixer.numeroBT,
                  conectado: mixer.connectionState?.toLocaleLowerCase() == 'connected',
                  betoneiraLocalizacao: res?.obras?.[0].remessas?.[0].betoneiraLocalizacao,
                },
              ],
            });

            notificationController.info({
              message: 'Betoneira não está em viagem no momento.',
            });
            setLoading(false);
            return;
          }

          setConstruction(res?.obras?.[0]);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
          setConstruction({});
          notificationController.error({
            message: 'Houve um erro ao buscar dados da betoneira.',
          });
        });

      return;
    }
  }, [mixer]);

  return (
    <Spinner spinning={loading}>
      <PageTitle>Monitoramento</PageTitle>
      <PageContainer>
        <MapConcreteMixer construction={construction} showFooter={true}></MapConcreteMixer>
      </PageContainer>
    </Spinner>
  );
};

export default ConcreteMixerMonitoringDashboard;
