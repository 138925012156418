import { Table } from '@app/components/common/Table/Table';
import styled from 'styled-components';

export const TableReport = styled(Table)`
  padding: 1rem 0 0 0 !important;

  & .ant-table-thead tr th {
    background-color: white;
    border-bottom: 1px solid var(--disabled-bg-color) !important;
  }

  & .ant-table-column-title {
    font-family: Mulish;
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 1rem;
    text-align: left;
  }

  & .ant-table-cell {
    font-family: Mulish;
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1rem;
    text-align: left;
  }

  & .ant-table-pagination {
    display: none;
  }
`;
export const Rectangle = styled.div`
  border-radius: 0.2rem;
  height: 1.25rem;
  width: 2rem;
  background-color: ${({ color }) => color};
`;
