import React from 'react';
import * as S from './DropdownTable.styles';
import { IDropdown } from '@app/constants/interfaces/Dropdown/IDropdown';
import { Button } from '../common/buttons/Button/Button.styles';

const DropdownTable: React.FC<IDropdown> = ({ buttonText, iconSupermix, iconD, ...props }) => {
  return (
    <S.DropdownTable getPopupContainer={(triggerNode) => triggerNode} {...props}>
      <Button type="text" onClick={(e) => e.preventDefault()}>
        {iconSupermix}
        {buttonText}
        {iconD}
      </Button>
    </S.DropdownTable>
  );
};
export default DropdownTable;
