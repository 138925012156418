import ExcelJS from 'exceljs';
import FileSaver from 'file-saver';
import { ColumnsType } from 'antd/lib/table';

export const createWorkbook = (sheetName: string = 'data') => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet(sheetName);
  return { workbook, worksheet };
};

export const setHeaders = (worksheet: ExcelJS.Worksheet, headers: string[]) => {
  worksheet.addRow(headers);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const addData = (worksheet: ExcelJS.Worksheet, data: any[][]) => {
  data.forEach((row) => worksheet.addRow(row));
};

export const setColumnWidths = (worksheet: ExcelJS.Worksheet, widths: (number | { key: string; width: number })[]) => {
  worksheet.columns = widths;
};

export const saveToFile = async (workbook: ExcelJS.Workbook, fileName: string) => {
  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
  });
  FileSaver.saveAs(blob, `${fileName}.xlsx`);
};

export const getMaxColumnWidth = <T>(data: T[], header: string, column: string, defaultWidth = 10): number => {
  let maxColumnWidth = header.length;

  data.forEach((row) => {
    const cellValue = (row as { [key: string]: unknown })[column];
    if (cellValue && typeof cellValue === 'string') {
      const cellLength = cellValue.length;
      if (cellLength > maxColumnWidth) {
        maxColumnWidth = cellLength;
      }
    }
  });

  return Math.max(maxColumnWidth + 2, defaultWidth);
};

export const genericExportToExcel = async <T>(fileName: string, columns: ColumnsType<T>, data: T[] | unknown[]) => {
  const { workbook, worksheet } = createWorkbook();

  const validColumns = columns.filter(
    (col): col is { title: string; dataIndex: string } =>
      'dataIndex' in col &&
      typeof col.dataIndex === 'string' &&
      col.dataIndex.trim() !== '' &&
      'title' in col &&
      typeof col.title === 'string' &&
      col.title.trim() !== '',
  );

  const headers = validColumns.map((col) => col.title);
  setHeaders(worksheet, headers);

  const dataIndexes = validColumns.map((col) => col.dataIndex);
  const rowsData = data.map((row) => dataIndexes.map((index) => (row as { [key: string]: unknown })[index]));
  addData(worksheet, rowsData);

  const columnWidths = validColumns.map((col) => ({
    key: col.dataIndex,
    width: getMaxColumnWidth(data, col.title, col.dataIndex),
  }));
  setColumnWidths(worksheet, columnWidths);

  await saveToFile(workbook, fileName);
};
