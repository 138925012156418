import { CardMonitoringStatus } from '@app/constants/enums/monitoring';
import { Col, ColProps } from 'antd';
import styled, { css } from 'styled-components';

interface CardMonitoringProps {
  status?: CardMonitoringStatus;
  size?: 'small' | 'middle' | 'large' | string;
}

interface CardMonitoringIconMarkerProps {
  quantity: number;
}

interface CardMonitoringContentColProps extends ColProps {
  hasDivider?: boolean;
}

export const CardMonitoring = styled.div<CardMonitoringProps>`
  font-family: 'Inter';
  font-style: normal;
  box-sizing: border-box;
  width: 100%;
  border-radius: 12px;
  padding: 1rem 1.5rem 1rem 1.5rem;
  height: 100%;

  ${({ status }) => {
    switch (status) {
      case CardMonitoringStatus.InProgress:
      case CardMonitoringStatus.PartialSuspended:
        return css`
          color: #263668;
          background: #ffffff;
          border: 1px solid #f6f6f6;
          box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.08);
        `;
      case CardMonitoringStatus.Canceled:
      case CardMonitoringStatus.TotalSuspended:
        return css`
          color: #707372;
          background: #f9f9fe;
          border: 1px solid #f6f6f6;
          box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.08);
        `;
      default:
        return css`
          color: #263668;
          background: #ffffff;
          border: 1px solid #f6f6f6;
          box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.08);
        `;
    }
  }}
  span {
    font-weight: 700;
  }
`;

export const CardMonitoringHeader = styled.div`
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.5rem;
  text-transform: uppercase;
  flex: none;
  order: 0;
  flex-grow: 0;
`;

export const CardMonitoringSubHeader = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;
`;

export const CardMonitoringContent = styled.div<CardMonitoringProps>`
  margin-top: 0.781rem;
  font-weight: 600;
  font-size: 28px;
  line-height: 34px;
  text-transform: uppercase;
  display: flex;
  align-items: center;

  ${({ size }) => {
    switch (size) {
      default:
      case 'small':
        return css`
          min-height: 3rem;
        `;
      case 'middle':
        return css`
          min-height: 5rem;
        `;
      case 'large':
        return css`
          min-height: 9.375rem;
        `;
    }
  }}

  .ant-carousel {
    font-size: 28px;
    line-height: 34px;
  }
`;

export const CardMonitoringContentPaused = styled(Col)<ColProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #263668;
  align-items: center;
  column-gap: 15px;
  row-gap: 15px;
  width: 100%;
`;

export const CardMonitoringIconMarker = styled.div<CardMonitoringIconMarkerProps>`
  display: inline-flex;
  justify-content: center;
  width: 100%;
  align-items: center;
  column-gap: 20px;
  row-gap: 20px;

  ${({ quantity }) => {
    if (quantity) {
      return css`
        color: #263668;
      `;
    } else {
      return css`
        color: #a2abbc;
      `;
    }
  }}
`;

export const CardMonitoringContentCol = styled(Col)<CardMonitoringContentColProps>`
  display: flex;
  justify-content: center;

  ${({ hasDivider }) => {
    if (hasDivider) {
      return css`
        border-right: 2px solid #f6f6f6;
      `;
    }
  }}
`;

export const CardMonitoringFooter = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;
`;
