import React from 'react';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { FirstAccessForm } from '@app/components/auth/FirstAccess/FirstAccess';

const FirstAccessPage: React.FC = () => {
  return (
    <>
      <PageTitle>Primeiro Acesso</PageTitle>
      <FirstAccessForm />
    </>
  );
};

export default FirstAccessPage;
