import * as React from 'react';
const SvgIconTempo = () => (
  <svg width="25" height="24" viewBox="0 0 25 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.5 2V8H6.51L6.5 8.01L10.5 12L6.5 16L6.51 16.01H6.5V22H18.5V16.01H18.49L18.5 16L14.5 12L18.5 8.01L18.49 8H18.5V2H6.5ZM16.5 16.5V20H8.5V16.5L12.5 12.5L16.5 16.5ZM12.5 11.5L8.5 7.5V4H16.5V7.5L12.5 11.5Z"
      fill="current"
    />
  </svg>
);
export default SvgIconTempo;
