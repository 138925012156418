import * as React from 'react';
const SvgIconCadastro = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.7155 2.14453H2.57268C2.33697 2.14453 2.14411 2.33739 2.14411 2.5731V10.716C2.14411 10.9517 2.33697 11.1445 2.57268 11.1445H10.7155C10.9513 11.1445 11.1441 10.9517 11.1441 10.716V2.5731C11.1441 2.33739 10.9513 2.14453 10.7155 2.14453ZM9.32268 9.3231H3.96554V3.96596H9.32268V9.3231ZM21.4298 2.14453H13.287C13.0513 2.14453 12.8584 2.33739 12.8584 2.5731V10.716C12.8584 10.9517 13.0513 11.1445 13.287 11.1445H21.4298C21.6655 11.1445 21.8584 10.9517 21.8584 10.716V2.5731C21.8584 2.33739 21.6655 2.14453 21.4298 2.14453ZM20.037 9.3231H14.6798V3.96596H20.037V9.3231ZM21.4298 12.8588H13.287C13.0513 12.8588 12.8584 13.0517 12.8584 13.2874V21.4302C12.8584 21.666 13.0513 21.8588 13.287 21.8588H21.4298C21.6655 21.8588 21.8584 21.666 21.8584 21.4302V13.2874C21.8584 13.0517 21.6655 12.8588 21.4298 12.8588ZM20.037 20.0374H14.6798V14.6802H20.037V20.0374ZM9.64411 17.3588H6.21554V13.9302C6.21554 13.8124 6.11911 13.716 6.00126 13.716H4.71554C4.59768 13.716 4.50126 13.8124 4.50126 13.9302V17.3588H1.07268C0.954827 17.3588 0.858398 17.4552 0.858398 17.5731V18.8588C0.858398 18.9767 0.954827 19.0731 1.07268 19.0731H4.50126V22.5017C4.50126 22.6195 4.59768 22.716 4.71554 22.716H6.00126C6.11911 22.716 6.21554 22.6195 6.21554 22.5017V19.0731H9.64411C9.76197 19.0731 9.8584 18.9767 9.8584 18.8588V17.5731C9.8584 17.4552 9.76197 17.3588 9.64411 17.3588Z"
      fill="current"
    />
  </svg>
);
export default SvgIconCadastro;
