import React from 'react';
import { PopoverProps as PopoverPropsAntd } from 'antd';
import * as S from './Popover.styles';

interface PopoverProps extends PopoverPropsAntd {
  show?: boolean;
}

export const Popover: React.FC<PopoverProps> = ({ children, show = true, ...props }) => {
  if (!show) return children;
  return <S.Popover {...props}>{children}</S.Popover>;
};
