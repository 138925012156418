import { TypeReport } from './enums/report';
import { IReportType } from './interfaces/interfaces';

export const reportTypes: IReportType[] = [
  {
    type: TypeReport.Water,
    name: 'Usos Água',
    permission: 'A',
    children: [
      {
        id: 0,
        name: 'Central',
        logicalName: 'aguaCentral',
        color: '#263668',
      },
      {
        id: 1,
        name: 'Obra',
        logicalName: 'aguaObra',
        color: '#6D89B1',
      },
      {
        id: 2,
        name: 'Total',
        logicalName: 'total',
        color: '#17203E',
      },
    ],
  },
  {
    type: TypeReport.Time,
    name: 'Tempos',
    permission: 'T',
    children: [
      {
        id: 0,
        name: 'Central',
        logicalName: 'central',
        color: '#1E2B53',
      },
      {
        id: 1,
        name: 'A caminho',
        logicalName: 'aCaminho',
        color: '#263668',
      },
      {
        id: 2,
        name: 'Aguardo',
        logicalName: 'aguardo',
        color: '#475582',
      },
      {
        id: 3,
        name: 'Descarga',
        logicalName: 'descarga',
        color: '#416290',
      },
      {
        id: 4,
        name: 'Lavagem',
        logicalName: 'lavagem',
        color: '#6D89B1',
      },
      {
        id: 5,
        name: 'Retorno',
        logicalName: 'retorno',
        color: '#A9B3D2',
      },
      {
        id: 6,
        name: 'Total',
        logicalName: 'total',
        color: '#17203E',
      },
    ],
  },
  {
    type: TypeReport.Trace,
    permission: 'TR',
    name: 'Traços',
    children: [
      {
        id: 0,
        name: 'Água central',
        logicalName: 'aguaCentral',
        color: '#263668',
        negativeColor: '#FF0000',
      },
      {
        id: 1,
        name: 'Água obra',
        logicalName: 'aguaObra',
        color: '#263668',
        negativeColor: '#FF0000',
      },
      {
        id: 1,
        name: 'Sobra água',
        logicalName: 'sobraAgua',
        color: '#263668',
        negativeColor: '#FF0000',
      },
    ],
  },
  {
    type: TypeReport.Driver,
    permission: 'M',
    name: 'Motoristas',
    children: [
      {
        id: 0,
        name: 'Água central',
        logicalName: 'aguaCentral',
        color: '#263668',
      },
      {
        id: 1,
        name: 'Água obra',
        logicalName: 'aguaObra',
        color: '#263668',
      },
      {
        id: 2,
        name: 'Tempo Central',
        logicalName: 'tempoCentral',
        color: '#263668',
      },
    ],
  },
];
