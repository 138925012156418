import React from 'react';
import * as S from './MainSider/MainSider.styles';
import { useResponsive } from 'hooks/useResponsive';
// import logo from 'assets/logo.png';
// import logo from 'assets/slumpmix-logo.png';
// import logoDark from 'assets/logo-dark.png';
// import { useAppSelector } from '@app/hooks/reduxHooks';
import { ReactComponent as WhiteLogo } from '@app/assets/horizontal-white-logo.svg';
import { ReactComponent as WhiteSymbol } from '@app/assets/white-symbol.svg';

interface SiderLogoProps {
  isSiderCollapsed: boolean;
  toggleSider: () => void;
  forcedCollapse: boolean;
}
export const SiderLogo: React.FC<SiderLogoProps> = ({ isSiderCollapsed, toggleSider, forcedCollapse }) => {
  const { tabletOnly } = useResponsive();

  // const theme = useAppSelector((state) => state.theme.theme);

  // const img = theme === 'dark' ? logoDark : logo;

  return (
    <S.SiderLogoDiv>
      <S.SiderLogoLink onClick={toggleSider} $isCollapsed={isSiderCollapsed}>
        {isSiderCollapsed ? forcedCollapse || tabletOnly ? <WhiteSymbol /> : <WhiteLogo /> : <WhiteLogo />}
      </S.SiderLogoLink>
    </S.SiderLogoDiv>
  );
};
