import { Row } from 'antd';
import styled from 'styled-components';

interface SubtitleRectangleProps {
  color: string;
}
export const SubtitleRectangle = styled.div`
  border-radius: 0.2rem;
  height: 1.25rem;
  width: 2rem;
  background-color: ${({ color }) => color};
`;
export const Subtitle = styled.div`
  font-family: Mulish;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1rem;
  text-align: left;
  color: #545454;
  text-transform: uppercase;
`;
export const SubtitleRow = styled(Row)`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;
