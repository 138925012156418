import { ReportFilterModel } from '@app/domain/interfaces/report/reportFilterModel';
import IReportService, { ReportService } from '@app/services/reportService';
import { Spinner } from '@app/components/common/Spinner/Spinner';
import { reportTypes } from '@app/constants/reportTypes';
import { TypeReport } from '@app/constants/enums/report';
import { useEffect, useState } from 'react';
import {
  ReportTraceMediaModel,
  ReportTraceModel,
  ReportTraceUseModel,
} from '@app/domain/interfaces/report/reportTraceModel';
import { Description } from '@app/components/common/Description/Description';
import { notificationController } from '@app/controllers/notificationController';
import { getDifferenceDaysBetweenDates } from '@app/utils/utils';
import GroupedChart, { ISeriesDataChildGroupedChart, ISeriesDataGroupedChart } from '../components/GroupedChart';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { setTraces } from '@app/store/slices/reportsSlice';
import { GroupByEnum } from '@app/constants/enums/chart';

const reportService: IReportService = new ReportService();

interface ITraceReportChart {
  filter: ReportFilterModel;
  typeSelecteds: string[];
  selectedBarIndex?: number | null;
  handleOpenReportDetailed: (groupedBy: GroupByEnum, datesClicked: string, valueClicked: string) => void;
}

export const TraceReport: React.FC<ITraceReportChart> = ({ filter, typeSelecteds, handleOpenReportDetailed }) => {
  const dispatch = useAppDispatch();
  const report = reportTypes.find((report) => report.type == TypeReport.Trace);
  const [loading, setLoading] = useState(false);
  const [traceReportModel, setReportTraceModel] = useState<ReportTraceModel[]>([]);
  const [rangeDays, setRangeDays] = useState<number>(0);

  // const { traceSelected } = useAppSelector((state) => state.reports);

  const fetchTraceReport = () => {
    setLoading(true);

    reportService
      .getTraceReport(filter)
      .then((res) => {
        setReportTraceModel(res.traco);
        dispatch(setTraces(res.traco.map((t) => t.traco)));
        setLoading(false);
      })
      .catch(() => {
        setReportTraceModel([]);
        notificationController.error({ message: 'Não foi possível encontrar dados para o filtro' });
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchTraceReport();
    const days = getDifferenceDaysBetweenDates(filter.dataHoraInicio, filter.dataHoraFim);
    setRangeDays(days);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  return (
    <Spinner spinning={loading}>
      {(traceReportModel?.length ?? 0) > 0 && (
        <>
          <div style={{ padding: '2rem', cursor: 'pointer' }}>
            {typeSelecteds.map((type) => (
              <>
                <Description
                  title={`${report?.children
                    .filter((reportTimeType) => reportTimeType.logicalName === type)
                    .map((reportTimeType) =>
                      reportTimeType.name.toLocaleLowerCase().includes('água')
                        ? `${reportTimeType.name} (L/m³)`
                        : reportTimeType.name,
                    )}`}
                  subtitle={`Análise do ranking de consumo de ${report?.children
                    .filter((reportTimeType) => reportTimeType.logicalName === type)
                    .map((reportTimeType) => reportTimeType.name.toLocaleLowerCase())} dos traços`}
                >
                  <div id="chart-print">
                    <GroupedChart
                      type={TypeReport.Driver}
                      name={report?.children.find((r) => r.logicalName == type)?.name ?? 'Consumo'}
                      key={type}
                      chartId={type}
                      colors={[
                        report?.children.find((reportType) => reportType.logicalName === type)?.color ?? '#6D89B1',
                      ]}
                      negativeColor={
                        report?.children.find((reportType) => reportType.logicalName === type)?.negativeColor ??
                        '#FF0000'
                      }
                      seriesData={traceReportModel.map(
                        (t) =>
                          ({
                            y: (t.media[type as keyof ReportTraceMediaModel] as number)?.toFixed(1),
                            x: t.traco,
                            z: t.qtdViagens,
                          }) as ISeriesDataGroupedChart,
                      )}
                      seriesDataChild={traceReportModel.map(
                        (traco) =>
                          ({
                            xAxisParent: traco.traco,
                            series: traco.usoTraco.map(
                              (item) =>
                                ({
                                  x: item.dataHora,
                                  y: item[type as keyof ReportTraceUseModel] as number,
                                  z: item.qtdViagens,
                                  w: traco.traco,
                                }) as ISeriesDataGroupedChart,
                            ),
                          }) as ISeriesDataChildGroupedChart,
                      )}
                      rangeDays={rangeDays}
                      typeSelecteds={typeSelecteds}
                      handleOpenReportDetailed={handleOpenReportDetailed}
                    />
                  </div>
                </Description>
              </>
            ))}
          </div>
        </>
      )}
    </Spinner>
  );
};
