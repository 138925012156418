import React from 'react';
import { Col, Row } from 'antd';
import { ProfileDropdown } from '../components/profileDropdown/ProfileDropdown/ProfileDropdown';
import * as S from '../Header.styles';
import { useAppSelector } from '@app/hooks/reduxHooks';

interface DesktopHeaderProps {
  isTwoColumnsLayout: boolean;
}

export const DesktopHeader: React.FC<DesktopHeaderProps> = ({ isTwoColumnsLayout }) => {
  const { title, icon } = useAppSelector((state) => state.header);

  const headerTitle = (
    <Row>
      <Col>
        <Row>
          {icon ? <S.IconBack to="/">{icon}</S.IconBack> : null}
          <S.HeaderTitle>{title}</S.HeaderTitle>
        </Row>
      </Col>
    </Row>
  );

  return (
    <Row
      justify="space-between"
      align="middle"
      style={{ borderBottom: '1px solid var(--disabled-bg-color)', width: '100%' }}
    >
      {headerTitle}
      <S.ProfileColumn xl={3} xxl={4} $isTwoColumnsLayout={isTwoColumnsLayout}>
        <Row align="middle" justify="end" style={{ marginBottom: '0.5rem' }}>
          <ProfileDropdown />
        </Row>
      </S.ProfileColumn>
    </Row>
  );
};
