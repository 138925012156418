import { ConcreteMixerModel } from '@app/domain/interfaces/concreteMixerModel';
import { ApiServiceBase, IApiServiceBase } from './apiServiceBase';

export default interface IConcreteMixerService extends IApiServiceBase<ConcreteMixerModel, ConcreteMixerModel> {}

export class ConcreteMixerService
  extends ApiServiceBase<ConcreteMixerModel, ConcreteMixerModel>
  implements IConcreteMixerService
{
  constructor() {
    super('admin/betoneiras');
  }
}
