import { Dropdown } from '@app/components/common/Dropdown/Dropdown';
import { useAppSelector } from '@app/hooks/reduxHooks';
// import { useResponsive } from '@app/hooks/useResponsive';
import { Col, Row } from 'antd';
import React from 'react';
import InitialsAvatar from 'react-initials-avatar';
import 'react-initials-avatar/lib/ReactInitialsAvatar.css';
import { ProfileOverlay } from '../ProfileOverlay/ProfileOverlay';
import * as S from './ProfileDropdown.styles';

export const ProfileDropdown: React.FC = () => {
  // const { isTablet } = useResponsive();

  const user = useAppSelector((state) => state.user.user);
  // const img_url = 'https://www.pngmart.com/files/21/Account-Avatar-Profile-PNG-Photo.png';

  return user ? (
    <Dropdown overlay={<ProfileOverlay />} trigger={['click']}>
      <S.ProfileDropdownHeader as={Row} gutter={[10, 10]} align="middle">
        <Col>{user.username && <InitialsAvatar name={user.username.replace('.', ' ')} className="avatar" />}</Col>
      </S.ProfileDropdownHeader>
    </Dropdown>
  ) : null;
};
