import React from 'react';
import { ListItem, ListItemsProps } from './JustificationList/ListItems';
import * as S from './List.styles';

interface ListProps {
  title?: string;
  justificationItems: ListItemsProps[];
}

export const List: React.FC<ListProps> = ({ title, justificationItems }) => {
  return (
    <S.ListContainer>
      <S.Title>{title}</S.Title>
      {justificationItems.map((item) => {
        return <ListItem key={item.key} justification={item.justification} />;
      })}
    </S.ListContainer>
  );
};
