import styled from 'styled-components';
import { Dropdown as AntDropdown } from 'antd';
import { FONT_SIZE } from '@app/styles/themes/constants';

export const Dropdown = styled(AntDropdown)`
  border-radius: 0.25rem;
  border: none;
  background: transparent;

  &:hover {
    background-color: transparent;
    color: #374450;
  }
  .ant-dropdown-menu-item,
  .ant-dropdown-menu-item-only-child {
    height: 2.5rem;

    &:hover {
      background-color: #e6ecf1;
    }
  }
  .ant-btn-link {
    font-size: ${FONT_SIZE.xs};
    text-decoration: none;
    color: #374450;
  }
`;
