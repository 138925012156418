import React from 'react';
import * as S from './Divider.styles';

export interface DividerMProps {
  title?: string;
  subtitle?: string;
  icon?: JSX.Element;
  isDivider?: boolean;
}

export const DividerT: React.FC<DividerMProps> = ({ title, subtitle, icon, isDivider }) => {
  return (
    <S.Container>
      {isDivider ? false : <S.DividerS type="vertical"></S.DividerS>}

      <S.DividerMain>
        <S.DividerIcon>{icon}</S.DividerIcon>
        <S.DividerTitle>{title}</S.DividerTitle>
        <S.DividerSubtitle>{subtitle}</S.DividerSubtitle>
      </S.DividerMain>
    </S.Container>
  );
};
