import { media } from '@app/styles/themes/constants';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: 1rem;
`;

export const ContainerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 1.5rem;
`;

export const ContainerkWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 0.75rem;
  margin-left: -1.25rem;
`;

export const Title = styled.span`
  font-family: 'Mulish';
  font-size: 2rem !important;
  font-weight: 700;
  line-height: 30px;
  color: var(--secondary-color);

  @media only screen and (${media.md}) {
    font-family: 'Mulish';
    font-size: 1.125rem;
  }
  @media only screen and (${media.xs}) {
    font-size: 1.325rem;
  }
  @media only screen and (${media.xxl}) {
    font-size: 1.5rem;
  }
`;
