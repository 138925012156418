import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Modal } from '@app/components/common/Modal/Modal';
import { Col, Row, Space } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { PageContainer } from '@app/components/common/PageContainer/PageContainer';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { Spinner } from '@app/components/common/Spinner/Spinner';
import { Description } from '@app/components/common/Description/Description';
import { BaseFormInputItem } from '@app/components/common/forms/components/BaseFormInputItem/BaseFormInputItem';
import { FirmwareVersionModel } from '@app/domain/interfaces/firmware_version/firmwareVersionModel';
import { Radio, RadioGroup } from '@app/components/common/Radio/Radio';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { setHeaderRegisterPage } from '@app/store/slices/headerRegisterPage';
import IFirmwareVersionService, { FirmwareVersionService } from '@app/services/firmwareVersionService';
import { notificationController } from '@app/controllers/notificationController';
import { setFooter } from '@app/store/slices/footerPageSlice';
import { VersionModuleModel } from '@app/domain/interfaces/firmware_version/versionModuleModel';
import MaskedInput from 'react-text-mask';
import { isUserMaster, readUserFilter } from '@app/services/localStorage.service';
import { Select } from '@app/components/common/selects/Select/Select';
import { ClientModel } from '@app/domain/interfaces/clientModel';
import IClientService, { ClientService } from '@app/services/clientService';

const firmwareVersionService: IFirmwareVersionService = new FirmwareVersionService();
const clientService: IClientService = new ClientService();

const FirmwareVersionCreate: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState(false);
  const [modalCancel, setModalCancel] = useState(false);
  const [modalEditConfirm, setModalEditConfirm] = useState(false);
  const [clientsOptions, setClientsOptions] = useState<ClientModel[]>([]);
  const [versionTypeOptions, setVersionTypeOptions] = useState<VersionModuleModel[]>([]);
  const [statusOptions, setStatusOptions] = useState<VersionModuleModel[]>([]);
  const [firmwareVersion, setFirmwareVersion] = useState<FirmwareVersionModel>({
    id: Number(id) ?? 0,
    versao: '',
  });
  const [fieldValidation, setFieldValidation] = useState<{ [key: string]: boolean | undefined }>({
    idCliente: undefined,
    type: undefined,
    version: undefined,
    status: undefined,
  });

  const handleInputChange = useCallback((name: string, value: string) => {
    setFirmwareVersion((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }, []);

  const fetchClients = useCallback(() => {
    clientService
      .getArray('obter')
      .then((res: ClientModel[]) => {
        setClientsOptions(res);
      })
      .catch((error) => {
        notificationController.error(error);
      });
  }, []);

  const fetchModulesOptions = useCallback(async () => {
    const response = await firmwareVersionService.getModulesOptions();
    if (response) {
      setVersionTypeOptions(response);
    } else {
      notificationController.error({ message: 'Erro ao buscar opções de versão' });
    }
  }, []);

  const fetchStatusOptions = useCallback(async () => {
    const response = await firmwareVersionService.getStatusOptions();
    if (response) {
      setStatusOptions(response);
      return response;
    } else {
      notificationController.error({ message: 'Erro ao buscar opções de status' });
    }
  }, []);

  const fetchFirmwareVersion = useCallback(async (id: number) => {
    const response = await firmwareVersionService.getFirmwareVersionById(id);
    if (response) {
      setFirmwareVersion(response);
    } else {
      notificationController.error({ message: 'Erro ao buscar versão de firmware' });
    }
  }, []);

  const fetchComboData = useCallback(
    async (id?: number) => {
      setLoading(true);
      await Promise.all([fetchClients(), fetchModulesOptions(), fetchStatusOptions()]);
      if (id) await fetchFirmwareVersion(id);
      setLoading(false);
    },
    [fetchClients, fetchFirmwareVersion, fetchModulesOptions, fetchStatusOptions],
  );

  const isValidIp = useCallback((ip: string): boolean => {
    const ipFormat = /^([0-9]{1,3}\.){3}[0-9]{1,3}$/;
    if (!ipFormat.test(ip)) return false;

    return ip.split('.').every((num) => {
      const n = parseInt(num);
      return n >= 0 && n <= 255;
    });
  }, []);

  const isValidData = useMemo(() => {
    return !firmwareVersion.idTipo || !firmwareVersion.idStatus || !isValidIp(firmwareVersion.versao);
  }, [firmwareVersion.idTipo, firmwareVersion.idStatus, firmwareVersion.versao, isValidIp]);

  const saveFirmwareVersion = useCallback(async () => {
    setLoading(true);
    try {
      if (firmwareVersion.id) {
        const objectToUpdate = {
          id: firmwareVersion.id,
          idTipo: firmwareVersion.idTipo,
          idStatus: firmwareVersion.idStatus,
          idCliente: firmwareVersion.idCliente,
          versao: firmwareVersion.versao,
        };
        await firmwareVersionService.update('', objectToUpdate);
      } else {
        await firmwareVersionService.post('', { ...firmwareVersion, id: 0 });
      }

      notificationController.success({ message: 'Versão de firmware salva com sucesso' });
      navigate('/gestao/versao-firmware');
    } catch (error) {
      notificationController.error({ message: 'Erro ao salvar versão de firmware' });
    } finally {
      setLoading(false);
    }
  }, [firmwareVersion, navigate]);

  useEffect(() => {
    const filter = readUserFilter();
    if (!isUserMaster() && filter.client?.id) {
      setFirmwareVersion((prevState) => ({ ...prevState, idCliente: filter.client?.id }));
    }
    fetchComboData(firmwareVersion.id);
  }, [fetchComboData, firmwareVersion.id]);

  useEffect(() => {
    dispatch(
      setHeaderRegisterPage({
        title: !firmwareVersion.id ? 'Novo versão' : 'Editar versão',
        handleBackClick: () => (firmwareVersion.id ? navigate('/gestao/versao-firmware') : setModalCancel(true)),
      }),
    );
    dispatch(
      setFooter({
        confirmButtonText: firmwareVersion.id ? 'Atualizar versão' : 'Cadastrar versão',
        cancelButtonText: 'Cancelar',
        handleCancelButtonClick: () =>
          firmwareVersion.id ? navigate('/gestao/versao-firmware') : setModalCancel(true),
        handleConfirmButtonClick: () =>
          firmwareVersion.existeAgendamento ? setModalEditConfirm(true) : saveFirmwareVersion(),
        confirmButtonDisabled: isValidData,
      }),
    );
  }, [dispatch, firmwareVersion.existeAgendamento, firmwareVersion.id, isValidData, navigate, saveFirmwareVersion]);

  const maskIdProps = useMemo(
    () => ({
      guide: true,
      mask: (value: string): (string | RegExp)[] => {
        let result: (string | RegExp)[] = [];
        const chunks = value.split('.');

        for (let i = 0; i < 4; ++i) {
          const chunk = (chunks[i] || '').replace(/_/gi, '');

          if (chunk === '') {
            result.push(/\d/, /\d/, /\d/, '.');
            continue;
          } else if (+chunk === 0) {
            result.push(/\d/, '.');
            continue;
          } else if (chunks.length < 4 || (chunk.length < 3 && chunks[i].indexOf('_') !== -1)) {
            if ((chunk.length < 2 && +`${chunk}00` > 255) || (chunk.length < 3 && +`${chunk}0` > 255)) {
              result.push(/\d/, /\d/, '.');
              continue;
            } else {
              result.push(/\d/, /\d/, /\d/, '.');
              continue;
            }
          } else {
            result.push(...new Array(chunk.length).fill(/\d/), '.');
            continue;
          }
        }

        result = result.slice(0, -1);
        return result;
      },
      pipe: (value: string): string | false => {
        if (value === '.' || value.endsWith('..')) return false;

        const parts = value.split('.');

        if (parts.length > 4 || parts.some((part) => part === '00' || Number(part) < 0 || Number(part) > 255)) {
          return false;
        }

        return value;
      },
    }),
    [],
  );

  return (
    <>
      <Modal
        key="cancel-modal"
        title={`Cancelar ${!firmwareVersion.id ? 'cadastro' : 'edição'}`}
        open={modalCancel}
        onOk={() => navigate('/gestao/versao-firmware')}
        onCancel={() => setModalCancel(false)}
        cancelText="Cancelar"
        okText="Confirmar"
      >
        <Row align="middle">
          <Col span={2}>
            <InfoCircleOutlined size={20} style={{ color: '#FAAD14' }} />
          </Col>
          <Col span={22}>Deseja realmente cancelar {!firmwareVersion.id ? 'o cadastro' : 'a edição'} da versão?</Col>
        </Row>
      </Modal>

      <Modal
        key="edit-modal"
        title="Editar versão"
        open={modalEditConfirm}
        onOk={() => saveFirmwareVersion()}
        onCancel={() => setModalEditConfirm(false)}
        cancelText="Cancelar"
        okText="Confirmar"
      >
        <Row align="middle">
          <Col span={2}>
            <InfoCircleOutlined size={20} style={{ color: '#FAAD14' }} />
          </Col>
          <Col span={22}>
            <span>
              Essa versão está sendo utilizada no agendamento, deseja realmente <strong> editar essa versão </strong>
              selecionada?
            </span>
          </Col>
        </Row>
      </Modal>

      <PageContainer>
        <BaseForm layout="vertical" style={{ width: '100%' }}>
          <Spinner spinning={loading}>
            <Description
              title="Informações do módulo"
              subtitle={`Preencha os campos abaixo para ${
                !firmwareVersion?.id ? 'cadastrar uma nova' : 'editar a'
              }  versão`}
            >
              <Row>
                {isUserMaster() && (
                  <Col xs={24} md={8}>
                    <BaseFormInputItem
                      label="Cliente"
                      isSuccess={fieldValidation.idCliente}
                      errorText="Cliente inválida"
                      supportText="Pesquise e selecione apenas um cliente"
                    >
                      <Select
                        showArrow
                        showSearch
                        style={{ width: '100%' }}
                        placeholder="Selecione a concreteira"
                        disabled={!isUserMaster()}
                        value={firmwareVersion?.idCliente}
                        onChange={(value) => handleInputChange('idCliente', value as string)}
                        options={clientsOptions.map((c) => ({ value: c.id, label: c.razaoSocial }))}
                        filterOption={(input, option) => option?.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        onBlur={() =>
                          setFieldValidation((prevState) => ({ ...prevState, idCliente: !!firmwareVersion.idCliente }))
                        }
                      />
                    </BaseFormInputItem>
                  </Col>
                )}
              </Row>
              <Row>
                <Col xs={24} md={8}>
                  <BaseFormInputItem label="Escolha o módulo" isSuccess={fieldValidation.type}>
                    <RadioGroup
                      name="module"
                      value={firmwareVersion?.idTipo}
                      onChange={(e) => {
                        handleInputChange('idTipo', e.target.value);
                        setFieldValidation((prevState) => ({
                          ...prevState,
                          type: !!e.target.value,
                        }));
                      }}
                    >
                      <Space size={8} direction="vertical">
                        {versionTypeOptions.map((item) => (
                          <Radio key={item.id} value={item.id}>
                            {item.nome}
                          </Radio>
                        ))}
                      </Space>
                    </RadioGroup>
                  </BaseFormInputItem>
                </Col>
              </Row>
              <Row>
                <Col xs={24} md={8}>
                  <BaseFormInputItem label="Versão do módulo" isSuccess={fieldValidation.versao}>
                    <MaskedInput
                      {...maskIdProps}
                      className="ant-input"
                      placeholder="Digite a versão do módulo"
                      value={firmwareVersion.versao}
                      onChange={(e) => handleInputChange('versao', e.target.value)}
                      onBlur={(e) => {
                        const value = e.target.value.replaceAll('_', '');
                        handleInputChange('versao', value);
                        setFieldValidation((prevState) => ({
                          ...prevState,
                          versao: isValidIp(value),
                        }));
                      }}
                    />
                  </BaseFormInputItem>
                </Col>
              </Row>
              <Row>
                <Col xs={24} md={8}>
                  <BaseFormInputItem label="Escolha o status" isSuccess={fieldValidation.status}>
                    <RadioGroup
                      name="status"
                      value={firmwareVersion?.idStatus}
                      onChange={(e) => {
                        handleInputChange('idStatus', e.target.value);
                        setFieldValidation((prevState) => ({
                          ...prevState,
                          status: !!e.target.value,
                        }));
                      }}
                    >
                      <Space size={8} direction="vertical">
                        {statusOptions.map((item) => (
                          <Radio key={item.id} value={item.id}>
                            {item.nome}
                          </Radio>
                        ))}
                      </Space>
                    </RadioGroup>
                  </BaseFormInputItem>
                </Col>
              </Row>
            </Description>
          </Spinner>
        </BaseForm>
      </PageContainer>
    </>
  );
};

export default FirmwareVersionCreate;
