import { Button } from '@app/components/common/buttons/Button/Button.styles';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { Table } from '@app/components/common/Table/Table';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { useMounted } from '@app/hooks/useMounted';
import { setHeader } from '@app/store/slices/headerSlice';
import { ColumnsType } from 'antd/lib/table';
import React, { useCallback, useEffect, useState } from 'react';
import { notificationController } from '@app/controllers/notificationController';
import { Badge, Col, Row } from 'antd';
import { Modal } from '@app/components/common/Modal/Modal';
import { UserModel } from '@app/domain/interfaces/user/userModel';
import IUserService, { UserService } from '@app/services/userService';
import { Spinner } from '@app/components/common/Spinner/Spinner';
import { hasAccessByPermission } from '@app/controllers/accessController';
import DropdownTable from '@app/components/dropdown/DropdownTable';
import { IconMore } from '@app/assets/slump-icons';
import { Menu, MenuItem } from '@app/components/common/Menu/Menu';
import { isUserMaster, readUserFilter } from '@app/services/localStorage.service';
import Dashboard from '@app/components/dashboard/Dashboard';
import { useNavigate } from 'react-router-dom';
import InitialsAvatar from 'react-initials-avatar';
import 'react-initials-avatar/lib/ReactInitialsAvatar.css';
import { SpinnerSlump } from '@app/components/common/SpinSlump/SpinSlump';
import { genericExportToExcel } from '@app/utils/exportToExcel';

const userService: IUserService = new UserService();

const UserDashboard: React.FC = () => {
  const dispatch = useAppDispatch();
  const { isMounted } = useMounted();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [tableData, setTableData] = useState<{ data: UserModel[]; loading: boolean }>({
    data: [],
    loading: true,
  });
  const [user, setUser] = useState<UserModel>({} as UserModel);
  const [users, setUsers] = useState<UserModel[]>();
  const [loading, setLoading] = useState(false);
  const [modalDeleteVisible, setModalDeleteVisible] = useState(false);
  const [modalDeactivateVisible, setModalDeactivateVisible] = useState(false);
  const [searchUserFiltered, setSearchUserFiltered] = useState<UserModel[]>();

  const navigate = useNavigate();

  const handleDeleteClick = (user: UserModel) => {
    setModalDeleteVisible(true);
    setUser(user);
  };
  const handleDeactivateClick = (user: UserModel) => {
    setModalDeactivateVisible(true);
    setUser(user);
  };
  const handleEditClick = (model: UserModel) => {
    navigate(`/gestao/usuarios/${model.id}`);
  };
  const handleDeactivateUser = () => {
    setTableData((prevState) => ({ ...prevState, loading: true }));
    setLoading(true);

    if (!user.isDeleted && (!user.id || !user.userId)) {
      notificationController.error({ message: 'Usuário sem todas informações necessárias' });
      setLoading(false);
      return;
    }

    userService
      .activeDeactive({ id: user.id, userId: user.userId, ativo: user.isDeleted ?? false })
      .then(() => {
        notificationController.success({
          message: `Usuário ${user.isDeleted ? 'ativado' : 'desativado'} com sucesso!`,
        });
        setModalDeactivateVisible(false);
        setLoading(false);
        fetchUsers();
      })
      .catch((error) => {
        setModalDeactivateVisible(false);
        setTableData((prevState) => ({ ...prevState, loading: false }));
        setLoading(false);
        notificationController.error(error);
      });
  };
  const handleDeleteUser = () => {
    setTableData((prevState) => ({ ...prevState, loading: true }));
    setLoading(true);

    if (!user.id) {
      notificationController.error({ message: 'Usuário sem todas informações necessárias' });
      setLoading(false);
      return;
    }

    userService
      .delete(`/deletarUsuario/${user.id}`)
      .then(() => {
        notificationController.success({
          message: `Usuário deletado com sucesso!`,
        });
        setModalDeleteVisible(false);
        setLoading(false);
        fetchUsers();
      })
      .catch((error) => {
        setModalDeleteVisible(false);
        setTableData((prevState) => ({ ...prevState, loading: false }));
        setLoading(false);
        notificationController.error(error);
      });
  };

  const columns: ColumnsType<UserModel> = [
    {
      title: 'Usuário',
      dataIndex: 'username',
      showSorterTooltip: false,
      sorter: (a, b) => (a.username && b.username ? a.username.localeCompare(b.username) : 0),
      width: '20%',
      render: (username: string) => {
        return (
          <Row align="middle">
            <Col style={{ marginRight: '0.875rem' }}>
              {' '}
              <InitialsAvatar name={username.replace('.', ' ')} className="avatarUser" />
            </Col>
            <Col>{username}</Col>
          </Row>
        );
      },
    },
    {
      title: 'Email',
      dataIndex: 'email',
      showSorterTooltip: false,
      sorter: (a, b) => (a.email && b.email ? a.email.localeCompare(b.email) : 0),
      width: '20%',
    },
    {
      title: 'Telefone',
      dataIndex: 'phoneNumber',
      showSorterTooltip: false,
      sorter: (a, b) => a.phoneNumber?.localeCompare(b.phoneNumber),
      width: '20%',
    },
    {
      title: 'Ativo',
      dataIndex: 'isDeleted',
      sorter: (a, b) => (a.isDeleted === b.isDeleted ? 0 : a.isDeleted ? -1 : 1),
      showSorterTooltip: false,
      width: '8%',
      render: (isDeleted: boolean) => {
        return (
          <Badge
            status={!isDeleted ? 'error' : 'success'}
            text={!isDeleted ? 'Ativo' : 'Inativo'}
            color={!isDeleted ? 'green' : 'red'}
          />
        );
      },
    },
    {
      title: '',
      dataIndex: 'id',
      width: '10%',
      sorter: (a, b) => (a?.id ? a.id : 0) - (b?.id ? b.id : 0),
      sortDirections: [],
      defaultSortOrder: 'descend',
      render: (_, user) => {
        const canEdit = hasAccessByPermission('user', 'U');
        const canDeactivate = hasAccessByPermission('user', 'D');
        const canDelete = isUserMaster();
        return (
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end' }}>
            {(canEdit || canDeactivate || canDelete) && (
              <DropdownTable
                buttonText=""
                iconD={
                  <div style={{ color: '#545454' }}>
                    <IconMore />
                  </div>
                }
                trigger={['click']}
                placement="bottomRight"
                overlay={
                  <Menu>
                    {canEdit && (
                      <MenuItem onClick={() => handleEditClick(user)}>
                        <Button type="text">Editar</Button>
                      </MenuItem>
                    )}
                    {canDeactivate && (
                      <MenuItem onClick={() => handleDeactivateClick(user)}>
                        <Button type="text">{user.isDeleted ? 'Ativar' : 'Desativar'}</Button>
                      </MenuItem>
                    )}
                    {canDelete && user.isDeleted && (
                      <MenuItem onClick={() => handleDeleteClick(user)}>
                        <Button type="text">Deletar</Button>
                      </MenuItem>
                    )}
                  </Menu>
                }
              ></DropdownTable>
            )}
          </div>
        );
      },
    },
  ];

  const handleSearchUserFilter = (event: React.FormEvent<HTMLInputElement>) => {
    if (event.currentTarget.value.length > 0) {
      const inputValue = event.currentTarget.value.toLowerCase();
      setSearchUserFiltered(
        users?.filter(
          (item) =>
            item.email?.toString().toLowerCase().includes(inputValue) ||
            item.username?.toLowerCase().includes(inputValue),
        ),
      );
    } else {
      setSearchUserFiltered(users);
    }
  };

  const handleExportToExcel = () => {
    genericExportToExcel('Usuários', columns, searchUserFiltered ?? []);
  };

  useEffect(() => {
    if (isMounted.current) {
      dispatch(
        setHeader({
          title: 'Usuários',
          buttonText: hasAccessByPermission('user', 'C') ? 'Novo usuário' : '',
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isUserMaster()) {
      fetchUsers();
    } else {
      //correção deve ser feito no login e no preenchimento de filtro do usuário,
      //para buscar apenas usuários que sao do mesmo cliente
      fetchUsers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchUsers = useCallback(async () => {
    setLoading(true);

    const resUsers = await userService.getArray('');
    setUsers(resUsers);
    setSearchUserFiltered(resUsers);

    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMounted]);

  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault();
    navigate(`/gestao/usuarios/cadastrar`);
  };

  // useEffect(() => {
  //   if (!isUserMaster()) {
  //     handlerOnChangeSelect('idCliente', userLogado?.idCliente);
  //   }
  // }, [clients]);

  useEffect(() => {
    if (!modalDeleteVisible) {
      setUser({} as UserModel);
    }
  }, [modalDeleteVisible]);

  return (
    <>
      <Modal
        title={`${user?.isDeleted ? 'Ativar' : 'Desativar'} usuário`}
        open={modalDeactivateVisible}
        onOk={() => handleDeactivateUser()}
        onCancel={() => setModalDeactivateVisible(false)}
        cancelText="Não"
        okText="Sim"
      >
        <SpinnerSlump spinning={loading}>
          <p>Tem certeza que deseja {user?.isDeleted ? 'ativar' : 'desativar'} o usuário selecionado?</p>
        </SpinnerSlump>
      </Modal>
      <Modal
        title="Deletar usuário"
        open={modalDeleteVisible}
        onOk={() => handleDeleteUser()}
        onCancel={() => setModalDeleteVisible(false)}
        cancelText="Não"
        okText="Sim"
      >
        <SpinnerSlump spinning={loading}>
          <p>Tem certeza que deseja deletar o usuário selecionado?</p>
        </SpinnerSlump>
      </Modal>
      <PageTitle>Usuários</PageTitle>
      <Spinner spinning={loading}>
        <Dashboard
          title={`Usuários ${
            readUserFilter()?.client?.razaoSocial ? `- ${readUserFilter()?.client?.razaoSocial}` : ''
          }`}
          buttonText="Cadastrar novo usuário"
          handleButtonClick={handleClick}
          placeholderTextSearch="Pesquisar por nome do usuário ou e-mail"
          handleSearchOnChange={handleSearchUserFilter}
          table={<Table columns={columns} dataSource={searchUserFiltered} scroll={{ x: 800 }} bordered />}
          exportToExcel={handleExportToExcel}
        />
      </Spinner>
    </>
  );
};

export default UserDashboard;
