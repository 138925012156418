import * as S from './TooltipCard.styles';
import { TooltipProps } from 'antd';

export const TooltipCard: React.FC<TooltipProps> = ({ children, ...props }) => {
  return (
    <S.TooltipCard {...props} content={props.overlay}>
      {children}
    </S.TooltipCard>
  );
};
