/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import { InfoCircleOutlined } from '@ant-design/icons';
import { Description } from '@app/components/common/Description/Description';
import { PageContainer } from '@app/components/common/PageContainer/PageContainer';
import { SpinnerSlump } from '@app/components/common/SpinSlump/SpinSlump';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { BaseFormInputItem } from '@app/components/common/forms/components/BaseFormInputItem/BaseFormInputItem';
import { Input } from '@app/components/common/inputs/Input/Input.styles';
import { notificationController } from '@app/controllers/notificationController';
import { DeviceTwinModel } from '@app/domain/interfaces/device/deviceTwinModel';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import IDeviceTwinService, { DeviceTwinService } from '@app/services/deviceTwinService';
import { setFooter } from '@app/store/slices/footerPageSlice';
import { setHeaderRegisterPage } from '@app/store/slices/headerRegisterPage';
import { Col, Modal, Row } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const deviceTwinService: IDeviceTwinService = new DeviceTwinService();

interface DeviceTwinValidation {
  voltasInicioDescarga?: boolean | undefined;
  numeroVoltasDescontarInicioDescarga?: boolean | undefined;
  tempoConsiderarCaminhaoParado?: boolean | undefined;
  volumeConsiderarAlertaRedosagem?: boolean | undefined;
  volumeConsiderarHomogeneizacao?: boolean | undefined;
  volumeDescarregadoVolta?: boolean | undefined;
  tempoColetaViagem?: boolean | undefined;
  tempoColetaGpsDuranteViagem?: boolean | undefined;
  tempoColetaGpsForaViagem?: boolean | undefined;
  tempoIntervaloLeituraDados?: boolean | undefined;
}

const DeviceTwin: React.FC = () => {
  const { id, view } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [btnSaveDisabled, setBtnSaveDisabled] = useState(true);
  const [modalCancelVisible, setModalCancelVisible] = useState(false);
  const [deviceTwin, setDeviceTwin] = useState<DeviceTwinModel>({} as DeviceTwinModel);
  const [deviceTwinValidation, setDeviceTwinValidation] = useState<DeviceTwinValidation>({} as DeviceTwinValidation);

  const onKeyDownNumber = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (!/[0-9]/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Tab') {
      e.preventDefault();
    }
  };
  const handleChangeTwin = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.name == 'volumeDescarregadoVolta') {
      const x = e.target.value.replace(/\D/g, '').match(/(\d{0,1})(\d{0,2})/);
      if (x) {
        e.target.value = !x[2] ? x[1] : x[1] + '.' + x[2];
      }
    }

    setDeviceTwin({ ...deviceTwin, [e.target.name]: e.target.value });
    setDeviceTwinValidation({ ...deviceTwinValidation, [e.target.name]: e.target.value ? undefined : false });
  };
  const handleSaveDeviceTwin = () => {
    setLoading(true);
    deviceTwinService
      .post('atualizar', deviceTwin)
      .then(() => {
        navigate('/gestao/dispositivos');
        setTimeout(() => {
          notificationController.success({
            message: 'Configuração do device atualizada com sucesso',
          });
        }, 1000);
      })
      .catch((error) => {
        setLoading(false);
        notificationController.error(error);
      });
  };
  const fetch = useCallback(async () => {
    setLoading(true);

    deviceTwinService
      .get(`obterPorId/${id}`)
      .then((res) => {
        setDeviceTwin({ ...res, idDispositivo: parseInt(id ?? '0') });
        setLoading(false);
      })
      .catch((error) => {
        notificationController.error(error);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (view == 'visualizar') {
      setBtnSaveDisabled(true);
      return;
    }

    let disabled = false;
    for (const property in deviceTwinValidation) {
      if ((deviceTwinValidation as any)[property] == false) {
        disabled = true;
      }
    }
    setBtnSaveDisabled(disabled);
  }, [deviceTwinValidation]);

  useEffect(() => {
    fetch();
    dispatch(
      setHeaderRegisterPage({
        title: `Configuração do dispositivo`,
        handleBackClick: () => navigate('/gestao/dispositivos'),
      }),
    );
  }, []);

  useEffect(() => {
    //usado para atualizar botao de confirmar e a função
    dispatch(
      setFooter({
        confirmButtonDisabled: btnSaveDisabled,
        confirmButtonText: 'Salvar',
        cancelButtonText: 'Cancelar',
        handleConfirmButtonClick: handleSaveDeviceTwin,
        handleCancelButtonClick: () => setModalCancelVisible(true),
      }),
    );
  }, [deviceTwin]);

  return (
    <>
      <Modal
        title={`Cancelar edição da configuração do device`}
        visible={modalCancelVisible}
        onOk={() => navigate('/gestao/dispositivos')}
        onCancel={() => setModalCancelVisible(false)}
        cancelText="Cancelar"
        okText="Confirmar"
      >
        <Row align="middle">
          <Col span={2}>
            <InfoCircleOutlined size={20} style={{ color: '#FAAD14' }} />
          </Col>
          <Col span={22}>Deseja realmente cancelar a edição da configuração do dispositivo?</Col>
        </Row>
      </Modal>
      <PageContainer>
        <BaseForm layout="vertical" style={{ width: '100%' }} disabled={view == 'visualizar'}>
          <SpinnerSlump spinning={loading}>
            {view == 'visualizar' && (
              <Description title="Dispositivo Offline" subtitle="Edição bloqueada"></Description>
            )}
            <Description
              title="Parâmetros da viagem por voltas"
              subtitle="Preencha os campos com o número de voltas e tempo gasto"
            >
              <Row gutter={18}>
                <Col xs={24} md={8}>
                  <BaseFormInputItem
                    label="N° voltas início descarga"
                    isSuccess={deviceTwinValidation?.voltasInicioDescarga}
                    errorText="Campo inválido"
                    successText=""
                  >
                    <Input
                      name="voltasInicioDescarga"
                      onChange={handleChangeTwin}
                      onKeyDown={onKeyDownNumber}
                      placeholder="Voltas início descarga"
                      value={deviceTwin?.voltasInicioDescarga}
                    />
                  </BaseFormInputItem>
                </Col>
                <Col xs={24} md={8}>
                  <BaseFormInputItem
                    label="N° voltas descontar início descarga"
                    isSuccess={deviceTwinValidation?.numeroVoltasDescontarInicioDescarga}
                    errorText="Campo inválido"
                    successText=""
                  >
                    <Input
                      name="numeroVoltasDescontarInicioDescarga"
                      onChange={handleChangeTwin}
                      onKeyDown={onKeyDownNumber}
                      placeholder="Voltas descontar início descarga"
                      value={deviceTwin?.numeroVoltasDescontarInicioDescarga}
                    />
                  </BaseFormInputItem>
                </Col>
                <Col xs={24} md={8}>
                  <BaseFormInputItem
                    label="Tempo p/ considerar caminhão parado"
                    isSuccess={deviceTwinValidation?.tempoConsiderarCaminhaoParado}
                    errorText="Campo inválido"
                    successText=""
                    supportText="Minutos"
                  >
                    <Input
                      name="tempoConsiderarCaminhaoParado"
                      onChange={handleChangeTwin}
                      onKeyDown={onKeyDownNumber}
                      placeholder="Tempo caminhão parado"
                      value={deviceTwin?.tempoConsiderarCaminhaoParado}
                    />
                  </BaseFormInputItem>
                </Col>
              </Row>
            </Description>
            <Description
              title="Parâmetros da viagem por volume"
              subtitle="Preencha os campos com o volume e tempo gasto"
            >
              <Row gutter={18}>
                <Col xs={24} md={8}>
                  <BaseFormInputItem
                    label="Volume p/ considerar alerta redosagem"
                    isSuccess={deviceTwinValidation?.volumeConsiderarAlertaRedosagem}
                    errorText="Campo inválido"
                    supportText="Litros"
                  >
                    <Input
                      name="volumeConsiderarAlertaRedosagem"
                      onChange={handleChangeTwin}
                      onKeyDown={onKeyDownNumber}
                      placeholder="Alerta redosagem"
                      value={deviceTwin?.volumeConsiderarAlertaRedosagem}
                    />
                  </BaseFormInputItem>
                </Col>
                <Col xs={24} md={8}>
                  <BaseFormInputItem
                    label="Volume p/ considerar alerta homogeneização"
                    isSuccess={deviceTwinValidation?.volumeConsiderarHomogeneizacao}
                    errorText="Campo inválido"
                    supportText="Litros"
                  >
                    <Input
                      name="volumeConsiderarHomogeneizacao"
                      onChange={handleChangeTwin}
                      onKeyDown={onKeyDownNumber}
                      placeholder="Alerta homogeneização"
                      value={deviceTwin?.volumeConsiderarHomogeneizacao}
                    />
                  </BaseFormInputItem>
                </Col>
                <Col xs={24} md={8}>
                  <BaseFormInputItem
                    label="Tempo p/ considerar caminhão parado"
                    isSuccess={deviceTwinValidation?.volumeDescarregadoVolta}
                    errorText="Campo inválido"
                    supportText="m³/rpm"
                  >
                    <Input
                      name="volumeDescarregadoVolta"
                      onChange={handleChangeTwin}
                      onKeyDown={onKeyDownNumber}
                      placeholder="Volume descarregado volta"
                      value={deviceTwin?.volumeDescarregadoVolta}
                    />
                  </BaseFormInputItem>
                </Col>
              </Row>
            </Description>
            <Description title="Tempo para coleta de dados" subtitle="Preencha os campos com os dados da viagem">
              <Row gutter={18}>
                <Col xs={24} md={8}>
                  <BaseFormInputItem
                    label="Dados Viagem"
                    isSuccess={deviceTwinValidation?.tempoColetaViagem}
                    errorText="Campo inválido"
                    supportText="milisegundos"
                  >
                    <Input
                      name="tempoColetaViagem"
                      onChange={handleChangeTwin}
                      onKeyDown={onKeyDownNumber}
                      placeholder="Tempo coleta dados viagem"
                      value={deviceTwin?.tempoColetaViagem}
                    />
                  </BaseFormInputItem>
                </Col>
                <Col xs={24} md={8}>
                  <BaseFormInputItem
                    label="Dados GPS durante viagem"
                    isSuccess={deviceTwinValidation?.tempoColetaGpsDuranteViagem}
                    errorText="Campo inválido"
                    supportText="milisegundos"
                  >
                    <Input
                      name="tempoColetaGpsDuranteViagem"
                      onChange={handleChangeTwin}
                      onKeyDown={onKeyDownNumber}
                      placeholder="Tempo coleta dados GPS durante viagem"
                      value={deviceTwin?.tempoColetaGpsDuranteViagem}
                    />
                  </BaseFormInputItem>
                </Col>
                <Col xs={24} md={8}>
                  <BaseFormInputItem
                    label="Dados GPS fora viagem"
                    isSuccess={deviceTwinValidation?.tempoColetaGpsForaViagem}
                    errorText="Campo inválido"
                    supportText="milisegundos"
                  >
                    <Input
                      name="tempoColetaGpsForaViagem"
                      onChange={handleChangeTwin}
                      onKeyDown={onKeyDownNumber}
                      placeholder="Tempo coleta dados GPS fora viagem"
                      value={deviceTwin?.tempoColetaGpsForaViagem}
                    />
                  </BaseFormInputItem>
                </Col>
                <Col xs={24} md={8}>
                  <BaseFormInputItem
                    label="Intervalo leitura dados"
                    isSuccess={deviceTwinValidation?.tempoIntervaloLeituraDados}
                    errorText="Campo inválido"
                    supportText="milisegundos"
                  >
                    <Input
                      name="tempoIntervaloLeituraDados"
                      onChange={handleChangeTwin}
                      onKeyDown={onKeyDownNumber}
                      placeholder="Tempo intervalo leitura dados"
                      value={deviceTwin?.tempoIntervaloLeituraDados}
                    />
                  </BaseFormInputItem>
                </Col>
              </Row>
            </Description>
          </SpinnerSlump>
        </BaseForm>
      </PageContainer>
    </>
  );
};

export default DeviceTwin;
