import { Description } from '@app/components/common/Description/Description';
import { Spinner } from '@app/components/common/Spinner/Spinner';
import { TypeReport } from '@app/constants/enums/report';
import { reportTypes } from '@app/constants/reportTypes';
import { notificationController } from '@app/controllers/notificationController';
import {
  ReportDriverMediaModel,
  ReportDriverModel,
  ReportDriverUseModel,
} from '@app/domain/interfaces/report/reportDriverModel';
import { ReportFilterModel } from '@app/domain/interfaces/report/reportFilterModel';
import IReportService, { ReportService } from '@app/services/reportService';
import { getDifferenceDaysBetweenDates } from '@app/utils/utils';
import { useEffect, useState } from 'react';
import GroupedChart, { ISeriesDataChildGroupedChart, ISeriesDataGroupedChart } from '../components/GroupedChart';
import { GroupByEnum } from '@app/constants/enums/chart';

const reportService: IReportService = new ReportService();

interface IDriverReport {
  filter: ReportFilterModel;
  typeSelecteds: string[];
  selectedBarIndex?: number | null;
  handleOpenReportDetailed: (groupedBy: GroupByEnum, datesClicked: string, valueClicked: string) => void;
}

export const DriverReport: React.FC<IDriverReport> = ({ filter, typeSelecteds, handleOpenReportDetailed }) => {
  const report = reportTypes.find((report) => report.type == TypeReport.Driver);
  const [loading, setLoading] = useState(false);
  const [rangeDays, setRangeDays] = useState<number>(0);
  const [driverReportModel, setDriverReportModel] = useState<ReportDriverModel[]>([]);

  const fetchDriverReport = () => {
    setLoading(true);

    reportService
      .getDriverReport(filter)
      .then((res) => {
        setDriverReportModel(res.motorista);
        setLoading(false);
      })
      .catch(() => {
        notificationController.error({ message: 'Não foi possível encontrar dados para o filtro' });
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchDriverReport();
    const days = getDifferenceDaysBetweenDates(filter.dataHoraInicio, filter.dataHoraFim);
    setRangeDays(days);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  return (
    <Spinner spinning={loading}>
      {driverReportModel.length > 0 && (
        <>
          <div style={{ padding: '2rem', cursor: 'pointer' }}>
            {typeSelecteds.map((type) => (
              <>
                <Description
                  title={`${report?.children
                    .filter((reportTimeType) => reportTimeType.logicalName === type)
                    .map((reportTimeType) =>
                      reportTimeType.name.toLocaleLowerCase().includes('água')
                        ? `${reportTimeType.name} (L/m³)`
                        : reportTimeType.name,
                    )}`}
                  subtitle={`Análise do ranking de consumo de ${report?.children
                    .filter((reportTimeType) => reportTimeType.logicalName === type)
                    .map((reportTimeType) => reportTimeType.name.toLocaleLowerCase())} dos motoristas`}
                >
                  <div>
                    <GroupedChart
                      type={TypeReport.Driver}
                      name={report?.children.find((r) => r.logicalName == type)?.name ?? 'Consumo'}
                      key={type}
                      chartId={type}
                      colors={[
                        report?.children.find((reportType) => reportType.logicalName === type)?.color ?? '#6D89B1',
                      ]}
                      seriesData={driverReportModel.map(
                        (m) =>
                          ({
                            x: m.motorista,
                            y: (m.media[type as keyof ReportDriverMediaModel] as number)?.toFixed(1),
                            z: m.qtdViagens,
                          }) as ISeriesDataGroupedChart,
                      )}
                      seriesDataChild={driverReportModel.map(
                        (motorista) =>
                          ({
                            xAxisParent: motorista.motorista,
                            series: motorista.usoMotorista.map(
                              (item) =>
                                ({
                                  x: item.dataHora,
                                  y: item[type as keyof ReportDriverUseModel] as number,
                                  z: item.qtdViagens,
                                  w: motorista.motoristaMatricula,
                                }) as ISeriesDataGroupedChart,
                            ),
                          }) as ISeriesDataChildGroupedChart,
                      )}
                      rangeDays={rangeDays}
                      typeSelecteds={typeSelecteds}
                      handleOpenReportDetailed={handleOpenReportDetailed}
                    />
                  </div>
                </Description>
              </>
            ))}
          </div>
        </>
      )}
    </Spinner>
  );
};
