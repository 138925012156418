import { CSSProperties } from 'react';
import { FONT_SIZE, media } from '@app/styles/themes/constants';
import styled from 'styled-components';

export const GoogleMapStyled: CSSProperties = {
  width: '100%',
  minHeight: '100%',
  borderRadius: '0.75rem',
};

interface ConcreteMixerMarkerProps {
  selected?: boolean;
}

export const ConcreteMixerMarker = styled.div<ConcreteMixerMarkerProps>`
  color: white;
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  transform: translate(-50%, -50%);
  background-color: var(--primary-color);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  opacity: ${(props) => (props.selected ? 'unset' : '0.5')};
`;

export const LocationMarkerContainer = styled.div`
  width: 0.8rem;
  height: 0.8rem;
  cursor: pointer;
`;

export const LocationMarker = styled.div`
  color: white;
  border-radius: 50%;
  width: 0.7rem;
  height: 0.7rem;
  background-color: var(--primary-color);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  opacity: 0.9;
  z-index: 400;

  &::before {
    content: '';
    position: absolute;
    top: -0.5rem;
    left: -0.5rem;
    width: calc(100% + 1rem);
    height: calc(100% + 1rem);
    border-radius: 50%;
    background-color: transparent;
    z-index: -1;
    cursor: pointer;
  }
`;

export const LocationMarkerTooltip = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  background-color: white;
  color: #707070;
  padding: 5px 20px 10px 10px;
  border-radius: 5px;
  min-width: 300px;
  width: fit-content;
  text-align: center;
  top: calc(100% + 5px);
  left: 50%;
  transform: translateX(-50%);
  //opacity: 0;
  visibility: visible;
  transition:
    opacity 0.3s,
    visibility 0.3s;
  pointer-events: auto;
  cursor: text;
  user-select: text;
  z-index: 401;

  ${LocationMarker}:hover & {
    opacity: 1;
    visibility: visible;
  }
`;

export const LocationMarkerTooltipHeader = styled.div`
  margin-bottom: 0.8rem;
  font-size: 24px;
  font-weight: 700;
  line-height: 2.188rem;
  letter-spacing: 0em;
  text-align: left;
  color: #263668;
`;

export const LocationMarkerTooltipCloseButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 5px;
  z-index: 402;
`;

export const LocationMarkerTooltipBody = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 13px;
  line-height: 1.5;
  letter-spacing: 0em;
  text-align: left;
  white-space: nowrap;
  gap: 1rem;
`;

export const PinMarker = styled.div`
  width: 3rem;
  height: 3rem;
  border-radius: 50% 50% 50% 0;
  color: white;
  background-color: var(--notification-error-color);
  transform: rotate(-45deg) translate(40%, -120%);
  display: flex;
  justify-content: center;
  align-items: center;

  :first-child {
    transform: rotate(45deg) !important;
    max-width: 2rem;
  }
`;

export const HeaderInfoCard = styled.div`
  position: absolute;
  background-color: #ffffff;
  width: 80%;
  height: 10.875rem;
  margin: 1rem;
  font-family: 'Mulish';
  font-style: normal;
  padding: 1rem;

  @media only screen and (${media.md}) {
    margin: 0rem;
    top: 1rem;
    left: 1rem;
    width: 31rem;
    height: 10.875rem;
  }
`;

export const HeaderInfoCardTitle = styled.div`
  font-size: 28px;
  font-weight: 700;
  line-height: 2.188rem;
  letter-spacing: 0em;
  text-align: left;
  color: #263668;
`;

export const HeaderInfoCardProperty = styled.div`
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.25rem;
  letter-spacing: 0em;
  text-align: left;
  color: #707070;
`;

export const HeaderInfoCardPropertyValue = styled.div`
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25rem;
  letter-spacing: 0em;
  text-align: left;
  color: #263668;
`;

export const FooterInfoCard = styled.div`
  position: absolute;
  background-color: #ffffff;
  width: 19rem;
  height: 3.5rem;
  bottom: 1rem;
  left: 50%;
  margin-left: -10rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #bababa;
  box-shadow: 0px 0px 24px 0px #0000001f;
  border-radius: 0.5rem;
  padding-left: 1rem;
  padding-bottom: 0.5rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
`;

export const FooterInfoCardLabel = styled.div`
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 700;
  font-size: ${FONT_SIZE.md};
  line-height: 1.5rem;
`;

export const FooterInfoCardDescription = styled.div`
  font-family: Mulish;
  font-size: 1rem;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #8c8c8c;
`;

export const FooterInfoCardValue = styled.div`
  font-family: Mulish;
  font-size: 1rem;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #545454;
`;

export const FooterInfoCardLinkButton = styled.div`
  font-family: Mulish;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #263668;
  cursor: pointer;

  @media print {
    display: none;
  }
`;
