import React, { FC, ReactNode, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import './MouseTracker.styles.css';

interface Offset {
  x: number;
  y: number;
}

interface MouseTrackerProps {
  children: ReactNode;
  offset?: Offset;
}

const MouseTracker: FC<MouseTrackerProps> = ({ children, offset = { x: 0, y: 0 } }) => {
  const element = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handler(e: MouseEvent) {
      if (element.current) {
        const x = e.clientX + offset.x;
        const y = e.clientY + offset.y;
        element.current.style.transform = `translate(${x}px, ${y}px)`;
        element.current.style.visibility = 'visible';
      }
    }
    document.addEventListener('mousemove', handler);
    return () => document.removeEventListener('mousemove', handler);
  }, [offset.x, offset.y]);

  return createPortal(
    <div className="mouse-tracker" ref={element}>
      {children}
    </div>,
    document.body,
  );
};

export default MouseTracker;
