import { useAppSelector } from '@app/hooks/reduxHooks';
import React, { ReactNode } from 'react';
import * as S from './FooterRegister.styles';

interface FooterMonitoringProps {
  footer?: ReactNode;
  children?: ReactNode;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const FooterReagister: React.FC<FooterMonitoringProps> = ({ footer, children }) => {
  const {
    confirmButtonText,
    handleConfirmButtonClick,
    cancelButtonText,
    handleCancelButtonClick,
    confirmButtonDisabled,
  } = useAppSelector((state) => state.footerRegister);
  return (
    <>
      <S.FooterReagister>
        <S.FooterCancelButton type="ghost" onClick={() => handleCancelButtonClick()}>
          {cancelButtonText}
        </S.FooterCancelButton>
        <S.FooterConfirmButton
          type="primary"
          onClick={() => handleConfirmButtonClick()}
          disabled={confirmButtonDisabled}
        >
          {confirmButtonText}
        </S.FooterConfirmButton>
      </S.FooterReagister>
    </>
  );
};
