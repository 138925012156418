import { DetailedTravelInfoTimeConsumptionModel } from '@app/domain/interfaces/report/TravelInfoDetailsReport';
import { TipoTempo } from '@app/constants/enums/tipoTempo';
import { formatMinutesToHoursAndMinutes, formatSecondsToHoursAndMinutesReports } from '@app/utils/utils';

const getStateName = (tipoTempo: TipoTempo) => {
  switch (tipoTempo) {
    case TipoTempo.Central:
      return 'Central';
    case TipoTempo.ACaminho:
      return 'Caminho';
    case TipoTempo.Aguardo:
      return 'Aguardando';
    case TipoTempo.Descarga:
      return 'Descarga';
    case TipoTempo.Lavagem:
      return 'Lavagem';
    case TipoTempo.Retorno:
      return 'Retorno';
    default:
      return '';
  }
};

export const getTooltipConfig = (tempoViagemConsumo: DetailedTravelInfoTimeConsumptionModel) => {
  return {
    visible: true,
    title: `Tempo ${getStateName(tempoViagemConsumo.tipoTempo)}`,
    value: `${tempoViagemConsumo?.tempo ? formatMinutesToHoursAndMinutes(tempoViagemConsumo.tempo) : '-'}`,
  };
};
