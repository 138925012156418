import styled from 'styled-components';
import { Spin } from 'antd';

export const Spinner = styled(Spin)`
  width: 3rem;
  height: 3rem;
  animation: loadingSpinner 3s 0s ease-in-out infinite;
  margin-bottom: 1.25rem;

  @keyframes loadingSpinner {
    0% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(180deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
