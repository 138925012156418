import { HelperNotification } from '@app/components/common/HelperNotification/HelperNotification';
import { Modal } from '@app/components/common/Modal/Modal';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { Button, Col, Row, Spin } from 'antd';
import * as S from './SendCommandModal.styles';
import { useCallback, useEffect, useState } from 'react';
import { notificationController } from '@app/controllers/notificationController';
import InfoLastTransmission from '@app/pages/modules/ConcreteMixer/components/InfoLastTransmission';
import ISendCommandService, { SendCommandService } from '@app/services/sendCommandService';
import { SendCommandEnum } from '@app/constants/enums/sendCommand';
import { CommandModel } from '@app/domain/interfaces/commandModel/commandModel';
import { handleEncryptText } from '@app/utils/encryptText';
import ICommadService, { CommandService } from '@app/services/commandService';
import { CheckCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { ReactComponent as ErrorIcon } from '@app/assets/icons/report_gmailerrorred.svg';

const sendCommandModalService: ISendCommandService = new SendCommandService();
const commandService: ICommadService = new CommandService();

export interface LastTransmissionDataModel {
  payload: string;
}

interface ISendCommandToDeviceModalProps {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  deviceId: string;
}

const SendCommandModal: React.FC<ISendCommandToDeviceModalProps> = ({ visible, setVisible, deviceId }) => {
  const [infoLastTransmissionData, setInfoLastTransmissionData] = useState<LastTransmissionDataModel | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingReboot, setLoadingReboot] = useState<boolean>(false);
  const [loadingRebootSuccess, setLoadingRebootSuccess] = useState<boolean | null>(null);
  const [loadingCancel, setLoadingCancel] = useState<boolean>(false);
  const [loadingCancelSuccess, setLoadingCancelSuccess] = useState<boolean | null>(null);
  const [loadingInfoLastTransmission, setInfoLoadingLastTransmission] = useState<boolean>(false);
  const [commandSelected, setCommandSelected] = useState<string>('');
  const [modelCommand, setModelCommand] = useState<CommandModel[]>([]);

  const handleSendCommandToDevice = async (command: string, message?: string, endpoint?: string) => {
    setLoading(true);
    const response = await sendCommandModalService
      .post(endpoint ? endpoint : '', {
        idDispositivo: deviceId,
        metodo: await handleEncryptText(command),
        mensagem: message ? await handleEncryptText(message) : '',
      })
      .catch((err: Error) => {
        setLoading(false);
        setInfoLoadingLastTransmission(false);
        if (err.message.indexOf('429') > -1) {
          notificationController.error({
            message: `O limite de requisições foi ultrapassado para o endpoint solicitado.`,
          });
        } else {
          notificationController.error({
            message: `Ocorreu um erro durante a execução do comando.\n${err}`,
          });
        }
        return null;
      });

    notificationController.success({ message: 'Comando enviado com sucesso.' });
    setLoading(false);
    return response;
  };

  const handleSendLastTransmissionCommand = async () => {
    setInfoLoadingLastTransmission(true);
    const data = await handleSendCommandToDevice(SendCommandEnum.GET_INFO);
    if (data !== null) {
      setInfoLastTransmissionData({
        payload: data.payload,
      });
    }
    setInfoLoadingLastTransmission(false);
  };

  const handleSendRebootCommand = async () => {
    setLoadingReboot(true);
    const response = await handleSendCommandToDevice(SendCommandEnum.REBOOT);
    setLoadingReboot(false);
    if (response?.status === 200) {
      setLoadingRebootSuccess(true);
    } else {
      setLoadingRebootSuccess(false);
    }
  };

  const handleSendCancelShipmentCommand = async () => {
    setLoadingCancel(true);
    const response = await handleSendCommandToDevice(SendCommandEnum.CANCEL_SHIPMENT);
    setLoadingCancel(false);
    if (response?.status === 200) {
      setLoadingCancelSuccess(true);
    } else {
      setLoadingCancelSuccess(false);
    }
  };

  const handleCleanLastCommand = () => {
    setInfoLastTransmissionData(null);
    setCommandSelected('');
    setLoadingRebootSuccess(null);
    setLoadingCancelSuccess(null);
  };

  const handleSelectOnChange = async (value: unknown) => {
    handleCleanLastCommand();
    setCommandSelected(value as string);
  };

  const handleSendCommand = async () => {
    switch (commandSelected) {
      case SendCommandEnum.REBOOT:
        handleSendRebootCommand();
        break;

      case SendCommandEnum.GET_INFO:
        handleSendLastTransmissionCommand();
        break;

      case SendCommandEnum.CANCEL_SHIPMENT:
        handleSendCancelShipmentCommand();
        break;

      default:
        handleCleanLastCommand();
        break;
    }
  };

  const fetchCommands = useCallback(
    async () => {
      try {
        const response = await commandService.getArray('obter');
        setModelCommand(response);
      } catch (error) {
        notificationController.error({
          message: 'Erro!',
          description: 'Erro ao buscar os comandos',
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps

    [],
  );

  useEffect(() => {
    fetchCommands();
  }, [fetchCommands]);

  return (
    <>
      <Modal
        title={`Envio de comando`}
        open={visible}
        footer={null}
        onCancel={() => {
          setVisible(false);
          setCommandSelected('');
        }}
        size="large"
      >
        <HelperNotification>Escolha apenas um comando por vez para enviar.</HelperNotification>
        <BaseForm layout="horizontal" style={{ width: '100%', marginTop: '1rem' }}>
          <Row align="middle" gutter={18}>
            <Col xs={24}>
              <S.Select
                showArrow
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                }}
                placeholder="Selecione um comando por vez para enviar"
                loading={loading}
                disabled={loading}
                value={commandSelected}
                onChange={handleSelectOnChange}
                options={modelCommand.map((m) => ({
                  label: m.nomeExibido,
                  value: m.comando,
                }))}
              />
            </Col>
          </Row>
        </BaseForm>
        <S.Container>
          {commandSelected === SendCommandEnum.REBOOT && (
            <Row style={{ marginTop: '1.5rem' }}>
              <Col xs={24} md={24} style={{ marginBottom: '1.5rem' }}>
                {loadingReboot ? (
                  <S.Row>
                    <Spin indicator={<LoadingOutlined style={{ fontSize: 20 }} />} />
                    Aguardando a reinicialização do hermes
                  </S.Row>
                ) : loadingRebootSuccess !== null ? (
                  loadingRebootSuccess ? (
                    <S.Row>
                      <CheckCircleOutlined style={{ fontSize: 20, color: '#117E2F' }} />
                      Hermes reiniciado!
                    </S.Row>
                  ) : (
                    <S.Row>
                      <ErrorIcon />
                      Falha ao reiniciar, tente novamente!
                    </S.Row>
                  )
                ) : null}
              </Col>
            </Row>
          )}
          {commandSelected === SendCommandEnum.CANCEL_SHIPMENT && (
            <Row style={{ marginTop: '1.5rem' }}>
              <Col xs={24} md={24} style={{ marginBottom: '1.5rem' }}>
                {loadingCancel ? (
                  <S.Row>
                    <Spin indicator={<LoadingOutlined style={{ fontSize: 20 }} />} />
                    Aguardando a cancelamento da remessa
                  </S.Row>
                ) : loadingCancelSuccess !== null ? (
                  loadingCancelSuccess ? (
                    <S.Row>
                      <CheckCircleOutlined style={{ fontSize: 20, color: '#117E2F' }} />
                      Remessa cancelada!
                    </S.Row>
                  ) : (
                    <S.Row>
                      <ErrorIcon />
                      Falha ao cancelar remessa, tente novamente!
                    </S.Row>
                  )
                ) : null}
              </Col>
            </Row>
          )}
          <S.FooterButtonsContainer>
            <Button type="ghost" onClick={() => setVisible(false)}>
              Cancelar
            </Button>
            <Button type="primary" onClick={handleSendCommand}>
              Enviar comando
            </Button>
          </S.FooterButtonsContainer>
          {(loadingInfoLastTransmission || infoLastTransmissionData !== null) && (
            <InfoLastTransmission lastTransmissionData={infoLastTransmissionData} />
          )}
        </S.Container>
      </Modal>
    </>
  );
};

export default SendCommandModal;
