import * as S from './Slump.styles';

interface SlumpProps {
  slumpValue: number;
  targetSlump: number;
  targetTolerance: number;
}

export const validateSlumpValue = S.validateSlumpValue;

const Slump: React.FC<SlumpProps> = ({ slumpValue, targetSlump, targetTolerance }) => {
  return (
    <S.SlumpContainer>
      <S.RulerScale>
        {Array.from(Array(15)).map((_, i) => (
          <S.RulerMark key={i} />
        ))}
      </S.RulerScale>
      <S.BarLimit />
      <S.TrapazoidConcrete slumpValue={slumpValue} />
      <S.TrapazoidConcreteBase />
      <S.GoalBar targetSlump={targetSlump} targetTolerance={targetTolerance} />
      <S.CurrentLine slumpValue={slumpValue} />
      <S.CurrentValue slumpValue={slumpValue} targetSlump={targetSlump} targetTolerance={targetTolerance}>
        {slumpValue.toFixed(1).toString().replace('.', ',')}
      </S.CurrentValue>
    </S.SlumpContainer>
  );
};

export default Slump;
