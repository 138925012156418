import React from 'react';
import * as S from './HeaderReports.styles';

type HeaderreportsProps = {
  children: React.ReactNode;
};

export const HeaderReports = ({ children }: HeaderreportsProps) => {
  return (
    <>
      <S.Container>{children}</S.Container>
    </>
  );
};
