import styled, { css } from 'styled-components';
import { Select as AntSelect } from 'antd';
import { Dimension } from '@app/constants/interfaces/interfaces';
import { normalizeProp } from 'utils/utils';
import { BORDER_RADIUS, FONT_SIZE } from '@app/styles/themes/constants';

export interface SelectProps {
  width?: Dimension;
  shadow?: boolean;
  isSuccess?: boolean;
}

export const Select = styled(AntSelect).withConfig({
  shouldForwardProp: (prop) => !['shadow', 'width'].includes(prop),
})<SelectProps>`
  width: ${(props) => props.width && normalizeProp(props.width)};

  font-weight: 400;

  box-shadow: ${(props) => props.shadow && 'var(--box-shadow)'};

  &.ant-select-borderless {
    background: var(--background-main-color);
    color: var(--secondary-color);
    border-radius: ${BORDER_RADIUS};
  }

  .ant-select-selection-placeholder {
    font-size: ${FONT_SIZE.md};
    color: var(--disabled-placeholder-color);
  }

  .ant-select-arrow {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--text-label);
  }

  &.ant-select-multiple.ant-select-sm .ant-select-selection-item {
    height: 0.875rem;
    line-height: ${FONT_SIZE.xs};
    font-size: ${FONT_SIZE.xs};
    margin-top: 0.1875rem;
    margin-bottom: 0.1875rem;
    font-weight: 700;
  }

  &.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    color: var(--border-base);
  }

  &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: none;
  }

  .ant-select-clear {
    color: var(--disabled-input-color);
  }

  .ant-select-selection-item-remove {
    color: var(--text-label);
    &:hover {
      color: var(--text-label);
    }
  }

  .ant-select-item-option-disabled {
    color: var(--input-placeholder-color);
    background-color: var(--disabled-input-color);
  }

  .ant-select-selection-item {
    font-weight: 700 !important;
  }

  border-radius: 6px;

  ${({ isSuccess }) => {
    switch (isSuccess) {
      case true:
        return css`
          border: 2px solid var(--success-color);
          color: var(--input-placeholder-color);
        `;
      case false:
        return css`
          border: 2px solid var(--error-color);
          color: var(--input-placeholder-color);
        `;
      default:
        return css`
          border: 1px solid var(--border-base);
        `;
    }
  }}
`;
