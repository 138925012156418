import * as React from 'react';
const SvgIconHome = () => (
  <svg width="25" height="24" viewBox="0 0 25 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.5 5.69L17.5 10.19V18H15.5V12H9.5V18H7.5V10.19L12.5 5.69ZM12.5 3L2.5 12H5.5V20H11.5V14H13.5V20H19.5V12H22.5L12.5 3Z"
      fill="current"
    />
  </svg>
);
export default SvgIconHome;
