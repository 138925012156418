import styled from 'styled-components';

export const DrawerLinkItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
  background: transparent;
  border: 2px solid var(--secondary-color);
  border-radius: 0.5rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0.5rem 1rem;
  gap: 1.5rem;

  width: 20.5rem;
`;

export const DrawerLinkItemTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
`;

export const DrawerLinkItemTitle = styled.span`
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
  display: flex;
  align-items: center;
  color: #374450;
`;

export const DrawerLinkItemSubTitle = styled.span`
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
  display: flex;
  align-items: center;
  color: var(--input-placeholder-color);
`;

export const DrawerLinkItemIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    width: 2.5rem;
    height: 2rem;
  }
`;
