export enum TableDataType {
  None = 0,
  Alert = 1,
  Client = 2,
  ConcreteMixer = 3,
  Device = 4,
  ConfigAlert = 5,
  User = 6,
  ClientBranch = 7,
  ClientGroup = 8,
  Profile = 9,
}
