import { Divider } from 'antd';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import * as Auth from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import * as S from './ForgotPasswordSuccessForm.styles';
import { ReactComponent as Logo } from '@app/assets/images/Logo-Azul-Vertical.svg';

interface ForgotPasswordSuccessFormProps {
  onBack?: () => void;
  onFinish?: () => void;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const ForgotPasswordSuccessForm: React.FC<ForgotPasswordSuccessFormProps> = ({ onBack, onFinish }) => {
  return (
    <Auth.FormWrapper>
      <Logo style={{ margin: '0 auto', justifyContent: 'center', marginBottom: '2rem' }} />
      <BaseForm layout="vertical" requiredMark="optional">
        <Auth.FormTitle>E-mail enviado!</Auth.FormTitle>
        <S.FormDescription style={{ marginBottom: '2rem' }}>
          Se o e-mail estiver cadastrado no <S.BoldItem>SlumpMix</S.BoldItem>, você receberá uma mensagem com as
          instruções para trocar por uma nova senha.
        </S.FormDescription>
        <Divider type="horizontal" style={{ backgroundColor: '#D9D9D9', marginTop: '4.5rem' }} />
        <S.SubFormDescription>
          Caso não tenha recebido as instruções, verifique se digitou o endereço de e-mail corretamente ou se caiu na
          caixa de spam
        </S.SubFormDescription>
      </BaseForm>
    </Auth.FormWrapper>
  );
};
