import { InfoCircleOutlined } from '@ant-design/icons';
import { Description } from '@app/components/common/Description/Description';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { BaseFormInputItem } from '@app/components/common/forms/components/BaseFormInputItem/BaseFormInputItem';
import { Input } from '@app/components/common/inputs/Input/Input.styles';
import { Modal } from '@app/components/common/Modal/Modal';
import { PageContainer } from '@app/components/common/PageContainer/PageContainer';
import { Radio, RadioGroup } from '@app/components/common/Radio/Radio';
import { Select } from '@app/components/common/selects/Select/Select';
import { Spinner } from '@app/components/common/Spinner/Spinner';
import { Tag } from '@app/components/common/Tag/Tag';
import { UserType } from '@app/constants/enums/userType';
import { isUserMaster, readUserFilter } from '@app/services/localStorage.service';
import { notificationController } from '@app/controllers/notificationController';
import { ClientBranchModel } from '@app/domain/interfaces/client_branch/clientBranchModel';
import { ClientModel } from '@app/domain/interfaces/clientModel';
import { ProfileModel } from '@app/domain/interfaces/profile/profileModel';
import { UserModel } from '@app/domain/interfaces/user/userModel';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { useMounted } from '@app/hooks/useMounted';
import IClientBranchService, { ClientBranchService } from '@app/services/clientBranchService';
import IClientService, { ClientService } from '@app/services/clientService';
import IProfileService, { ProfileService } from '@app/services/profileService';
import IUserService, { UserService } from '@app/services/userService';
import { setFooter } from '@app/store/slices/footerPageSlice';
import { setHeaderRegisterPage } from '@app/store/slices/headerRegisterPage';
import { Col, Row } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const userService: IUserService = new UserService();
const clientService: IClientService = new ClientService();
const profileService: IProfileService = new ProfileService();
const clientBranchService: IClientBranchService = new ClientBranchService();

interface UserValidate {
  username: boolean | undefined;
  email: boolean | undefined;
}

const phoneMask = (value: string) => {
  if (!value) return '';
  value = value.replace(/\D/g, '');
  value = value.replace(/(\d{2})(\d)/, '($1) $2');
  value = value.replace(/(\d)(\d{4})$/, '$1-$2');
  return value;
};

const UserCreate: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isMounted } = useMounted();
  const [user, setUser] = useState<UserModel>({} as UserModel);
  const [userValidate, setUserValidate] = useState<UserValidate>({} as UserValidate);
  const [clients, setClients] = useState<ClientModel[]>([]);
  const [branchs, setBranchs] = useState<ClientBranchModel[]>([]);
  const [profiles, setProfiles] = useState<ProfileModel[]>([]);
  const [loading, setLoading] = useState(false);
  const [modalCancel, setModalCancel] = useState(false);

  const handleSaveUser = () => {
    let model;
    switch (user.type) {
      case UserType.Master:
        model = { ...user, idCliente: undefined, perfis: [], filiais: [] };
        break;
      case UserType.ClientAdmin:
        model = { ...user, filiais: [] };
        break;
      case UserType.UserFilial:
        model = user;
        break;
      default:
        notificationController.error({ message: 'Tipo do usuário não foi definido' });
        return;
    }

    if (!model.id) {
      setLoading(true);
      userService
        .post('', model)
        .then(() => {
          setTimeout(() => {
            notificationController.success({ message: 'Usuário cadastrado com sucesso!' });
            navigate('/gestao/usuarios');
          }, 1700);
        })
        .catch((error) => {
          setLoading(false);
          notificationController.error({ message: `Erro ao cadastrar usuário.\n ${error}` });
        });
    } else {
      setLoading(true);
      userService
        .update('', model)
        .then(() => {
          setTimeout(() => {
            notificationController.success({ message: 'Usuário editado com sucesso!' });
            navigate('/gestao/usuarios');
          }, 1700);
        })
        .catch((error) => {
          setLoading(false);
          notificationController.error({ message: `Erro ao editar usuário. \n ${error}` });
        });
    }
  };
  const handleBackUser = (e: React.MouseEvent) => {
    e.preventDefault();
    navigate(`/gestao/usuarios`);
  };
  const handleUserChange = (e: React.FormEvent<HTMLInputElement>) => {
    switch (e.currentTarget.name) {
      case 'email':
        const emailValid = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        setUserValidate({ ...userValidate, email: emailValid.test(e.currentTarget.value) ? undefined : false });
        setUser({ ...user, email: e.currentTarget.value });
        break;
      case 'username':
        setUserValidate({ ...userValidate, username: e.currentTarget.value?.length >= 8 ? undefined : false });

        const value = e.currentTarget.value.toLowerCase().replace(' ', '.');

        if (value != '.') {
          setUser({ ...user, username: value });
        }

        break;
      default:
        setUser({ ...user, [e.currentTarget.name]: e.currentTarget.value });
        setUserValidate({ ...userValidate, [e.currentTarget.name]: !e.currentTarget.value ? false : undefined });
        break;
    }
  };
  const handlerOnChangeSelect = async (name: string, value: unknown) => {
    if (name == 'idCliente') {
      fetchComboData(value as number);
    }
    setUser({ ...user, [name]: value });
  };
  const handleUsernameOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.code == 'Space' && (user.username?.includes('.') || !user?.username)) {
      e.preventDefault();
    }

    if (e.key == '.' && (user.username?.includes('.') || !user?.username)) {
      e.preventDefault();
    }

    if (/(?=.*[0-9]).*$/.test(e.key) || (/\W/.test(e.key) && e.code != 'Space' && e.key != '.')) {
      e.preventDefault();
    }
  };

  const fetchClients = useCallback(() => {
    clientService
      .getArray('obter')
      .then((res: ClientModel[]) => {
        setClients(res);
      })
      .catch((error) => {
        notificationController.error(error);
      });
  }, []);
  const fetchComboData = async (idCliente: number) => {
    setLoading(true);

    await clientBranchService
      .getArray(`${idCliente}`)
      .then((res) => {
        setBranchs(res);
      })
      .catch((error) => {
        notificationController.error(error);
      });

    await profileService
      .getArray(`obterPorIdCliente/${idCliente}`)
      .then((res) => {
        setProfiles(res);
      })
      .catch((error) => {
        notificationController.error(error);
      });

    setLoading(false);
  };
  const fetchUser = (userId: string) => {
    setLoading(true);

    userService
      .get(`obterPorId/${userId}`)
      .then(async (res) => {
        if (res.idCliente) {
          await fetchComboData(res.idCliente);
        }
        setUser(res);
        setLoading(false);
      })
      .catch((error) => {
        notificationController.error(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    dispatch(
      setHeaderRegisterPage({
        title: !id ? 'Novo usuário' : 'Editar usuário',
        handleBackClick: handleBackUser,
      }),
    );
    if (isMounted.current) {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const filter = readUserFilter();

    if (filter.client?.id) {
      handlerOnChangeSelect('idCliente', filter.client?.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clients]);

  useEffect(() => {
    fetchClients();

    if (id) {
      fetchUser(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let disabled = false;

    if (userValidate.username === false || !user.username) {
      disabled = true;
    }
    if (userValidate.email === false || !user.email) {
      disabled = true;
    }
    if (!user.type) {
      disabled = true;
    }
    if (
      (user.type == UserType.ClientAdmin || user.type == UserType.UserFilial) &&
      !user.isDeleted &&
      (user.perfis?.length ?? 0) == 0
    ) {
      disabled = true;
    }
    if (user.type == UserType.ClientAdmin && !user.idCliente) {
      disabled = true;
    }
    if (user.type == UserType.UserFilial && (user.filiais?.length ?? 0) == 0) {
      disabled = true;
    }

    dispatch(
      setFooter({
        confirmButtonDisabled: disabled,
        confirmButtonText: id ? 'Salvar' : 'Cadastrar usuário',
        handleConfirmButtonClick: handleSaveUser,
        cancelButtonText: 'Cancelar',
        handleCancelButtonClick: () => setModalCancel(true),
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <>
      <Modal
        title={`Cancelar ${!id ? 'cadastro' : 'edição'}`}
        visible={modalCancel}
        onOk={() => navigate('/gestao/usuarios')}
        onCancel={() => setModalCancel(false)}
        cancelText="Cancelar"
        okText="Confirmar"
      >
        <Row align="middle">
          <Col span={2}>
            <InfoCircleOutlined size={20} style={{ color: '#FAAD14' }} />
          </Col>
          <Col span={22}>Deseja realmente cancelar {!id ? 'o cadastro' : 'a edição'} do usuário?</Col>
        </Row>
      </Modal>
      <PageContainer>
        <BaseForm layout="vertical" style={{ width: '100%' }}>
          <Spinner spinning={loading}>
            <Description
              title="Informações do usuário"
              subtitle={`Preencha os campos abaixo para ${!user?.id ? 'cadastrar um novo' : 'editar o'}  usuário`}
            >
              <Row gutter={18}>
                <Col xs={24} md={8}>
                  <BaseFormInputItem
                    isSuccess={userValidate.username}
                    label="Nome de usuário"
                    errorText="Campo obrigatório"
                    supportText="É como o usuário ficará conhecido no sistema"
                  >
                    <Input
                      name="username"
                      value={user.username}
                      onChange={handleUserChange}
                      onKeyDown={handleUsernameOnKeyDown}
                      placeholder="Informe o nome do usuário"
                    />
                  </BaseFormInputItem>
                </Col>
                <Col xs={24} md={8}>
                  <BaseFormInputItem isSuccess={userValidate.email} label="E-mail" errorText="Campo inválido">
                    <Input
                      name="email"
                      value={user.email}
                      onChange={handleUserChange}
                      placeholder="Informe o nome do e-mail"
                    />
                  </BaseFormInputItem>
                </Col>
                <Col xs={24} md={8}>
                  <BaseFormInputItem label="Telefone">
                    <Input
                      type="tel"
                      name="phoneNumber"
                      placeholder="Informe o telefone do usuário"
                      value={phoneMask(user.phoneNumber)}
                      onChange={handleUserChange}
                      maxLength={15}
                    />
                  </BaseFormInputItem>
                </Col>
              </Row>
              <Row>
                <Col xs={24} md={8}>
                  <BaseFormInputItem label="Escolha o tipo de usuário">
                    <RadioGroup
                      name="type"
                      onChange={(e) => setUser({ ...user, type: e.target.value })}
                      value={user.type}
                    >
                      {isUserMaster() && <Radio value={UserType.Master}>Master</Radio>}
                      <Radio value={UserType.ClientAdmin}>Admin</Radio>
                      <Radio value={UserType.UserFilial}>Filial</Radio>
                    </RadioGroup>
                  </BaseFormInputItem>
                </Col>
                <Col xs={24} md={8}>
                  <BaseFormInputItem label="Status do usuário">
                    <RadioGroup
                      name="isDeleted"
                      onChange={() => setUser((prevState) => ({ ...prevState, isDeleted: !user.isDeleted }))}
                      value={user.isDeleted ? 0 : 1}
                    >
                      <Radio value={1}>Ativo</Radio>
                      <Radio value={0}>Inativo</Radio>
                    </RadioGroup>
                  </BaseFormInputItem>
                </Col>
              </Row>
            </Description>

            {user.type === UserType.ClientAdmin || user.type === UserType.UserFilial ? (
              <Description
                title="Perfil e permissões"
                subtitle="Selecione os campos para definir as configurações do usuário"
              >
                <Row gutter={18}>
                  {(user.type === UserType.ClientAdmin || user.type === UserType.UserFilial) && isUserMaster() && (
                    <Col xs={24} md={8}>
                      <BaseFormInputItem label="Concreteira" supportText="Campo obrigatório">
                        <Select
                          showSearch
                          showArrow
                          placeholder="Selecione a concreteira"
                          value={user?.idCliente}
                          onChange={(e) => handlerOnChangeSelect('idCliente', e)}
                          style={{ width: '100%' }}
                          options={clients?.map((client) => ({ value: client.id, label: client.razaoSocial }))}
                          filterOption={(input, option) =>
                            option?.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                        />
                      </BaseFormInputItem>
                    </Col>
                  )}

                  {user.type == UserType.UserFilial && (
                    <Col xs={24} md={8}>
                      <BaseFormInputItem label="Filiais" supportText="Campo obrigatório">
                        <Select
                          showSearch
                          showArrow
                          onChange={(e) => handlerOnChangeSelect('filiais', e)}
                          mode="multiple"
                          placeholder="Adicione um ou mais filiais"
                          style={{ width: '100%' }}
                          value={user.filiais}
                          options={branchs?.map((p) => ({ value: p.id, label: p.nome }))}
                          tagRender={(props) => (
                            <Tag key={props.value} closable onClose={props.onClose}>
                              {props.label}
                            </Tag>
                          )}
                        />
                      </BaseFormInputItem>
                    </Col>
                  )}
                  {user?.type !== undefined && !user.isDeleted && (
                    <Col xs={24} md={8}>
                      <BaseFormInputItem label="Perfil de permissões" supportText="Campo obrigatório">
                        <Select
                          showSearch
                          showArrow
                          value={user.perfis}
                          onChange={(e) => handlerOnChangeSelect('perfis', e)}
                          mode="multiple"
                          placeholder="Selecione um ou mais perfis"
                          style={{ width: '100%' }}
                          options={profiles?.map((p) => ({ value: p.idIdentityServer, label: p.descricao }))}
                          tagRender={(props) => (
                            <Tag key={props.value} closable onClose={props.onClose}>
                              {props.label}
                            </Tag>
                          )}
                        />
                      </BaseFormInputItem>
                    </Col>
                  )}
                </Row>
              </Description>
            ) : (
              <></>
            )}
          </Spinner>
        </BaseForm>
      </PageContainer>
    </>
  );
};
export default UserCreate;
