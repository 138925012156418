import { FONT_SIZE } from '@app/styles/themes/constants';
import styled from 'styled-components';

export const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem;
`;

export const Title = styled.span`
  font-family: 'Mulish';
  font-size: ${FONT_SIZE.lg};
  font-weight: 600;
  line-height: 25px;
  color: var(--text-label);
`;

export const Item = styled.span`
  font-family: 'Mulish';
  font-size: ${FONT_SIZE.xs};
  font-weight: 400;
  line-height: 18px;
  color: var(--text-label);

  margin-bottom: 0.125rem;
`;
