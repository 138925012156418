import { ButtonProps, Row } from 'antd';
import Button from 'antd/es/button';
import Search from 'antd/lib/input/Search';
import styled from 'styled-components';

export const HeaderRow = styled(Row)`
  background-color: white;
  height: 6.75rem;
  padding: 0rem 2.75rem 0rem 2.25rem;

  font-family: Inter;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.5rem;
  letter-spacing: 0em;
  text-align: left;
  color: #263668;
`;

export const HeaderLabelTitle = styled(Row)`
  font-family: Mulish;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.25rem;
  letter-spacing: 0em;
  text-align: left;
  color: #263668;
`;
export const HeaderLabelDescription = styled(Row)`
  font-family: Mulish;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25rem;
  letter-spacing: 0em;
  text-align: left;
  color: #545454;
`;

export const HeaderSubRow = styled(Row)`
  display: flex;
  gap: 0.8rem;
  height: 100%;
  width: 100%;
  align-items: center;
`;

export const HeaderDivider = styled.div`
  width: 1px;
  background-color: #ced6e2;
  height: 100%;
  margin: 0 10px;
`;

export const HeaderButton = styled.div`
  cursor: pointer;
`;

export const HeaderInputSearch = styled(Search)`
  & .ant-input {
    padding: 1rem;
    border-radius: 8px 0px 0px 8px;
    border-width: 2px 0px 2px 2px;
    border-style: solid;
    border-color: #1c213c;
  }

  & .ant-input-group-addon {
    border-radius: 0px 11px 11px 0px !important;
    border-width: 2px 2px 2px 0px !important;
    border-style: solid !important;
    border-color: #1c213c !important;
  }
`;

export const HeaderTutorialButton = styled(Button)<ButtonProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #545454;
  border-color: #545454;
  border-radius: 50%;
  color: white;
  margin-left: 4rem;

  span {
    font-size: 1rem !important;
  }

  &:hover {
    background-color: #686868;
    border-color: #686868;
  }
`;
