import {
  CentralTrackingModel,
  CentralTrackingReportModel,
} from '@app/domain/interfaces/centralTracking/CentralTrackingModel';
import { ApiServiceBase, IApiServiceBase } from './apiServiceBase';

export default interface ICentralTrackingService extends IApiServiceBase<CentralTrackingModel, CentralTrackingModel> {
  getChargingPointsByCodigoFilial(codigos: number[]): Promise<CentralTrackingModel>;
  getChargingPointReport(idBranch: number, point: string): Promise<CentralTrackingReportModel>;
}

export class CentralTrackingService
  extends ApiServiceBase<CentralTrackingModel, CentralTrackingModel>
  implements ICentralTrackingService
{
  constructor() {
    super('admin/acompanhamento-central');
  }

  async getChargingPointsByCodigoFilial(codigos: number[]): Promise<CentralTrackingModel> {
    return (await this.api.post('', { codigosFiliais: codigos })).data;
  }
  async getChargingPointReport(idBranch: number, point: string): Promise<CentralTrackingReportModel> {
    return (await this.api.post('obter-relatorio-por-ponto-de-carga', { codigoFilial: idBranch, pontoDeCarga: point }))
      .data;
  }
}
