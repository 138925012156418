import { Col } from 'antd';
import * as S from './DisplayInput.style';

interface DisplayInputProps {
  label?: string;
  value?: string;
}

const DisplayInput = ({ label, value }: DisplayInputProps) => {
  return (
    <S.RowDisplayInput>
      <Col style={{ textAlign: 'start', color: 'var(--input-placeholder-color)' }} span={12}>
        {label}
      </Col>
      <Col style={{ textAlign: 'end', color: 'var(--text-label)' }} span={12}>
        {value}
      </Col>
    </S.RowDisplayInput>
  );
};

export default DisplayInput;
