import { ModalProps } from 'antd';
import * as S from './ModalFooter.styles';

interface ModalFooterProps extends ModalProps {}

const ModalFooterMonitoring: React.FC<ModalFooterProps> = (props) => {
  return <S.ModalFooterMonitoring {...props}>{props.children}</S.ModalFooterMonitoring>;
};

export default ModalFooterMonitoring;
