import { Spinner } from '@app/components/common/Spinner/Spinner';
import { GroupByEnum, GroupedChartEnum } from '@app/constants/enums/chart';
import { notificationController } from '@app/controllers/notificationController';
import { ReportFilterModel } from '@app/domain/interfaces/report/reportFilterModel';
import IReportService, { ReportService } from '@app/services/reportService';
import React, { useEffect, useState } from 'react';
import DatetimeChart, { ISeriesChart } from '../components/DatetimeChart';
import { ReportWaterModel, ReportWaterUseModel } from '@app/domain/interfaces/report/reportWaterModel';
import { reportTypes } from '@app/constants/reportTypes';
import { TypeReport } from '@app/constants/enums/report';
import { Description } from '@app/components/common/Description/Description';
import { Col, Row } from 'antd';
import { Toolbar } from '@app/components/common/Toolbar/Toolbar';
import { TabReports } from '@app/components/common/TabsReports/TabsReports';
import { getDifferenceDaysBetweenDates } from '@app/utils/utils';

const reportService: IReportService = new ReportService();

interface IWaterReportChart {
  filter: ReportFilterModel;
  typesSelecteds: string[];
  handleOpenReportDetailed: (groupedBy: GroupByEnum, datesClicked: string, valueClicked: string) => void;
}

export const WaterReportChart: React.FC<IWaterReportChart> = ({ filter, typesSelecteds, handleOpenReportDetailed }) => {
  const report = reportTypes.find((report) => report.type == TypeReport.Water);
  const [rangeDays, setRangeDays] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [waterUseData, setWaterUseData] = useState<ReportWaterModel>();
  const [activeTabGroupedChart, setActiveTabGroupedChart] = useState<GroupedChartEnum>(GroupedChartEnum.Grouped);
  const [activeTabGroupByDate, setActiveTabGroupByDate] = useState<GroupByEnum>(GroupByEnum.Day);

  const fetchReport = async () => {
    setLoading(true);
    reportService
      .getWaterUseReport({ ...filter, codigosFilialSiac: filter.codigosFilialSiac })
      .then((res) => {
        setWaterUseData(res);
        setLoading(false);
      })
      .catch(() => {
        setWaterUseData({} as ReportWaterModel);
        notificationController.error({ message: 'Não encontrado dados para o filtro informado' });
        setLoading(false);
      });
  };

  const handleChangeTypeSelected = async (value: GroupByEnum) => {
    setLoading(true);
    setTimeout(() => {
      setActiveTabGroupByDate(value);
      setLoading(false);
    }, 1000);
  };

  const handleChangeGroupedTypeSelected = async (value: GroupedChartEnum) => {
    setLoading(true);
    setTimeout(() => {
      setActiveTabGroupedChart(value);
      setLoading(false);
    }, 1000);
  };

  useEffect(() => {
    fetchReport();
    const diffDays = getDifferenceDaysBetweenDates(filter.dataHoraInicio, filter.dataHoraFim);
    setActiveTabGroupByDate(
      diffDays < 31 ? GroupByEnum.Day : diffDays >= 31 && diffDays < 120 ? GroupByEnum.Week : GroupByEnum.Month,
    );
    setRangeDays(diffDays);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  return (
    <Spinner spinning={loading}>
      {(waterUseData?.usoAgua?.length ?? 0) > 0 && (
        <>
          <div style={{ padding: '2rem' }}>
            {typesSelecteds.map((type) => report?.children.map((reportType) => reportType.logicalName === type))
              .length > 1 ? (
              <Description
                title="Água total (L/m³)"
                subtitle={`Análise 
                  ${activeTabGroupedChart === 0 ? 'agrupada' : ''}
                  ${activeTabGroupedChart === 1 ? 'individual' : ''}
                  do consumo total de água na central e obra`}
              >
                <Row gutter={18}>
                  <Col xs={24} md={6}>
                    <Toolbar title="Selecione o tipo de visualização">
                      {rangeDays < 31 && (
                        <TabReports
                          onClick={() => handleChangeTypeSelected(GroupByEnum.Day)}
                          dateTime={activeTabGroupByDate === GroupByEnum.Day ? 'day' : undefined}
                          title="Dia"
                        />
                      )}
                      {rangeDays < 120 && (
                        <TabReports
                          onClick={() => handleChangeTypeSelected(GroupByEnum.Week)}
                          dateTime={activeTabGroupByDate === GroupByEnum.Week ? 'week' : undefined}
                          title="Semana"
                        />
                      )}
                      <TabReports
                        onClick={() => handleChangeTypeSelected(GroupByEnum.Month)}
                        dateTime={activeTabGroupByDate === GroupByEnum.Month ? 'month' : undefined}
                        title="Mês"
                      />
                    </Toolbar>
                  </Col>
                  <Col xs={24} md={8} xxl={6}>
                    <Toolbar title="Selecione o tipo de comparação">
                      <TabReports
                        onClick={() => handleChangeGroupedTypeSelected(GroupedChartEnum.Grouped)}
                        dateTime={activeTabGroupedChart === GroupedChartEnum.Grouped ? 'grouped' : undefined}
                        title="Agrupado"
                      />
                      <TabReports
                        onClick={() => handleChangeGroupedTypeSelected(GroupedChartEnum.Individual)}
                        dateTime={activeTabGroupedChart === GroupedChartEnum.Individual ? 'individual' : undefined}
                        title="Individual"
                      />
                    </Toolbar>
                  </Col>
                </Row>
              </Description>
            ) : (
              <>
                {typesSelecteds.map((type) => (
                  <div key={type}>
                    <Description
                      title={`${report?.children
                        .filter((reportTimeType) => reportTimeType.logicalName === type)
                        .map((reportTimeType) => reportTimeType.name)} (L/m³)`}
                      subtitle={`Análise do consumo de água ${report?.children
                        .filter((reportTimeType) => reportTimeType.logicalName === type)
                        .map((reportTimeType) => reportTimeType.name.toLocaleLowerCase())}`}
                    >
                      <Row gutter={18}>
                        <Col xs={24} md={6}>
                          <Toolbar title="Selecione o tipo de visualização">
                            {rangeDays < 31 && (
                              <TabReports
                                onClick={() => handleChangeTypeSelected(GroupByEnum.Day)}
                                dateTime={activeTabGroupByDate === GroupByEnum.Day ? 'day' : undefined}
                                title="Dia"
                              />
                            )}
                            {rangeDays < 120 && (
                              <TabReports
                                onClick={() => handleChangeTypeSelected(GroupByEnum.Week)}
                                dateTime={activeTabGroupByDate === GroupByEnum.Week ? 'week' : undefined}
                                title="Semana"
                              />
                            )}
                            <TabReports
                              onClick={() => handleChangeTypeSelected(GroupByEnum.Month)}
                              dateTime={activeTabGroupByDate === GroupByEnum.Month ? 'month' : undefined}
                              title="Mês"
                            />
                          </Toolbar>
                        </Col>
                      </Row>
                    </Description>
                  </div>
                ))}
              </>
            )}
          </div>
          <div id="chart-print">
            {activeTabGroupedChart === GroupedChartEnum.Grouped ? (
              <DatetimeChart
                handleOpenReportDetailed={handleOpenReportDetailed}
                chartId="individual-chart"
                colors={report?.children.map((reportType) => reportType.color) ?? ['#6D89B1']}
                groupBy={activeTabGroupByDate}
                isSecondGraph={false}
                type="column"
                seriesData={
                  report?.children
                    .filter((reportType) => typesSelecteds.find((value) => value === reportType.logicalName))
                    .map((reportType) => {
                      return {
                        type: 'bar',
                        name: reportType.name,
                        group: reportType?.logicalName == 'total' ? 'total' : 'other',
                        data: waterUseData?.usoAgua?.map((item) => ({
                          y: item[reportType?.logicalName as keyof ReportWaterUseModel] as number,
                          x: new Date(item.dataHora).getTime(),
                          z: item.qtdViagens,
                        })),
                      } as ISeriesChart;
                    }) ?? []
                }
              />
            ) : (
              typesSelecteds.map((type) => (
                <DatetimeChart
                  handleOpenReportDetailed={handleOpenReportDetailed}
                  key={type}
                  colors={[report?.children.find((reportType) => reportType.logicalName === type)?.color ?? '#6D89B1']}
                  chartId={type}
                  groupBy={activeTabGroupByDate}
                  isSecondGraph={false}
                  type="column"
                  seriesData={
                    report?.children
                      .filter((reportType) => reportType.logicalName === type)
                      .map((reportType) => {
                        return {
                          type: 'bar',
                          name: reportType.name,
                          group: reportType?.logicalName == 'total' ? 'total' : 'other',
                          data: waterUseData?.usoAgua?.map((item) => ({
                            y: item[reportType?.logicalName as keyof ReportWaterUseModel] as number,
                            x: new Date(item.dataHora).getTime(),
                          })),
                        } as ISeriesChart;
                      }) ?? []
                  }
                />
              ))
            )}
          </div>
        </>
      )}
    </Spinner>
  );
};
