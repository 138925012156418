import styled from 'styled-components';
import { LAYOUT } from '@app/styles/themes/constants';
import { Button } from '@app/components/common/buttons/Button/Button';

export const FooterReagister = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: end;

  background-color: transparent;
  border-top: 1px solid var(--disabled-bg-color);
  padding: ${LAYOUT.desktop.paddingVertical} ${LAYOUT.desktop.paddingHorizontal};
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
`;

export const FooterConfirmButton = styled(Button)`
  width: 14.75rem;
  height: 3.5rem;
`;

export const FooterCancelButton = styled(Button)`
  width: 14.75rem;
  height: 3.5rem;
  margin-right: 1rem;
`;
