import * as React from 'react';
const SvgIconArrowCircle = () => (
  <svg width="25" height="24" viewBox="0 0 25 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.5 5.125V8.5L16 4L11.5 -0.5V2.875C6.5275 2.875 2.5 6.9025 2.5 11.875C2.5 13.6412 3.0175 15.2838 3.895 16.6675L5.5375 15.025C5.03125 14.0913 4.75 13.0112 4.75 11.875C4.75 8.15125 7.77625 5.125 11.5 5.125ZM19.105 7.0825L17.4625 8.725C17.9575 9.67 18.25 10.7388 18.25 11.875C18.25 15.5987 15.2238 18.625 11.5 18.625V15.25L7 19.75L11.5 24.25V20.875C16.4725 20.875 20.5 16.8475 20.5 11.875C20.5 10.1088 19.9825 8.46625 19.105 7.0825Z"
      fill="current"
    />
  </svg>
);
export default SvgIconArrowCircle;
