import styled from 'styled-components';
import { FONT_SIZE, media } from '@app/styles/themes/constants';

export const FooterListContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  animation: swipe 5000ms linear infinite backwards;
`;

export const FooterListWrap = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 0.425rem;
  animation: swipe 5000ms linear infinite backwards;
`;

export const FooterTitle = styled.span`
  font-family: 'Mulish';
  font-size: 1.25rem;
  line-height: 1.5rem;
  font-weight: 700;
  overflow-y: auto;

  color: #f9f9fe;

  /* display: flex;
  justify-content: start;
  align-items: flex-start; */
  margin-bottom: 0.625rem;

  @media only screen and (${media.xs}) {
    font-size: ${FONT_SIZE.xs};
    margin-bottom: 0.425rem;
  }
  @media only screen and (${media.md}) {
    font-size: ${FONT_SIZE.md};
    margin-bottom: 0.525rem;
  }
  @media only screen and (${media.xxl}) {
    font-size: 1.325rem;
    margin-bottom: 0.725rem;
  }
`;
