import ExcelJS from 'exceljs';
import { CentralTrackingReportModel } from '@app/domain/interfaces/centralTracking/CentralTrackingModel';
import moment from 'moment';
import FileSaver from 'file-saver';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

const tableHeaders: string[] = [
  'Remessa',
  'Data Emissão NF',
  'BT',
  'Motorista',
  'Volume',
  'Água retida',
  'Água central',
  'Traço',
];

interface HeaderProps {
  regional: string;
  filial: string;
  pontoCarga: string;
  dataHora: string;
  usuario: string;
}

export const exportCentralTrackingReportToExcel = async (header: HeaderProps, data: CentralTrackingReportModel) => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('data');
  const { regional, filial, pontoCarga, dataHora, usuario } = header;

  worksheet.addRow(['Regional', 'Filial', 'Ponto de Carga', 'Data de Retirada', 'Usuário']);
  worksheet.addRow([regional, filial, pontoCarga, dataHora, usuario]);

  data.obras.map((o) => {
    worksheet.addRow([]);
    worksheet.addRow([o.obra]);
    worksheet.addRow(tableHeaders);
    o.remessas.map((r) => {
      worksheet.addRow([
        r.idViagem,
        moment(r.dataEmissaoNf).format('DD/MM/yy HH:mm'),
        r.betoneira,
        r.motorista,
        r.volume ? `${r.volume.toFixed(1)} m³` : '-',
        r.aguaUtilizada ? `${r.aguaUtilizada} L` : '-',
        r.aguaTotal ? `${r.aguaTotal} L` : '-',
        r.traco,
      ]);
    });
  });

  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
  });
  FileSaver.saveAs(blob, `relatorio_acompanhamento_central.xlsx`);
};

export const exportCentralTrackingReportToPdf = async (header: HeaderProps, data: CentralTrackingReportModel) => {
  const image = new Image();
  const doc = new jsPDF({ orientation: 'landscape', unit: 'mm' });
  const { regional, filial, pontoCarga, dataHora, usuario } = header;

  image.src = `${window.location.origin}/Logo-Azul-Vertical.png`;

  image.onload = async () => {
    const imgWidth = 25;
    const imgHeight = (image.height * imgWidth) / image.width;

    doc.addImage(image, 'PNG', 13, 2, imgWidth, imgHeight);

    doc.setFontSize(8);
    doc.setTextColor(56, 56, 56);
    doc.text(`RELATÓRIO - PONTO DE CARGA / P1 - ÁGUA CENTRAL / +70% DE ÁGUA RETIDA:`, 95, 10, {});

    doc.setDrawColor(186, 186, 186);
    doc.setLineWidth(0.1);
    doc.roundedRect(13, 18, doc.internal.pageSize.getWidth() - 27, 16, 3, 3, 'S');

    doc.setTextColor(140, 140, 140);
    doc.text(`Regional:`, 20, 25, {});
    doc.text(`Filial:`, 70, 25, {});
    doc.text(`Ponto de Carga:`, 135, 25, {});
    doc.text(`Data de retirada:`, 195, 25, {});
    doc.text(`Usuário:`, 250, 25, {});

    doc.setTextColor(84, 84, 84);
    doc.text(`${regional}`, 20, 28, { maxWidth: 60 });
    doc.text(`${filial}`, 70, 28, {});
    doc.text(`${pontoCarga}`, 135, 28, {});
    doc.text(`${dataHora}`, 195, 28, {});
    doc.text(`${usuario}`, 250, 28, {});

    let currentY = 40;

    data.obras.forEach((o) => {
      currentY += 8;

      doc.text(`${o.obra}`, 14, currentY - 4, { maxWidth: 60 });

      const remessa = o.remessas.map((r) => [
        r.idViagem?.toString() ?? '',
        moment(r.dataEmissaoNf).format('DD/MM/yy HH:mm'),
        r.betoneira ?? '',
        r.motorista ?? '',
        r.volume ? `${r.volume.toFixed(1)} m³` : '-',
        r.aguaUtilizada ? `${r.aguaUtilizada} L` : '-',
        r.aguaTotal ? `${r.aguaTotal} L` : '-',
        r.traco ?? '',
      ]);

      autoTable(doc, {
        startY: currentY,
        theme: 'plain',
        head: [tableHeaders],
        tableLineColor: [232, 232, 232],
        tableLineWidth: 0.05,
        body: remessa,
        headStyles: {
          fillColor: [245, 245, 245],
          textColor: [0, 0, 0],
          valign: 'middle',
        },
        styles: {
          fontSize: 5,
          cellWidth: 'auto',
        },
        tableWidth: 'auto',
        didDrawCell: (data) => {
          currentY = (data.cursor?.y ?? 0) + 10;
        },
      });
    });

    doc.save('relatorio_betoneira.pdf');
  };
};
