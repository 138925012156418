import React, { ReactNode } from 'react';
import { DrawerProps } from 'antd';
import * as S from './Drawer.styles';
import { ReactComponent as SmallLogo } from '@app/assets/small-logo.svg';
import { AlertConcrete, AlertWater } from '@app/domain/interfaces/alert/alertModel';

interface CustomDrawerProps extends DrawerProps {
  footer: ReactNode;
  boxClient?: boolean;
  boxClientName?: string;
  boxClientAddress?: string;
  boxClientStatus?: string;
  textSpan: string;
  description?: string;
  alertaConcreto?: AlertConcrete;
  alertaAgua?: AlertWater;
  cardProfile?: ReactNode;
}

export const Drawer: React.FC<CustomDrawerProps> = ({ children, ...props }) => {
  return (
    <S.Drawer
      getContainer={false}
      {...props}
      extra={
        <div>
          <SmallLogo width="1.5rem" height="1.4rem" />
        </div>
      }
      footer={props.footer}
    >
      {props.boxClient ? (
        <>
          <S.DrawerClientBoxContainer>
            <S.DrawerClientBoxTitle>{props.boxClientStatus}</S.DrawerClientBoxTitle>
            <S.DrawerClientBoxText>
              <strong>Cliente:</strong> {props.boxClientName}
            </S.DrawerClientBoxText>
            <S.DrawerClientBoxText>
              <strong>Endereço:</strong> {props.boxClientAddress}
            </S.DrawerClientBoxText>

            {props.alertaAgua && (
              <React.Fragment>
                <S.DrawerClientBoxWaterOrConcreteAlert>
                  Água:
                  <div
                    style={{ display: 'flex', flexDirection: 'column', marginTop: '-0.8rem', alignItems: 'flex-start' }}
                  >
                    <S.DrawerClientBoxWaterOrConcreteText>
                      CENTRAL ∙ {props.alertaAgua.aguaCentral} L
                    </S.DrawerClientBoxWaterOrConcreteText>
                    <S.DrawerClientBoxWaterOrConcreteText>
                      OBRA ∙ {props.alertaAgua.aguaObra} L
                    </S.DrawerClientBoxWaterOrConcreteText>
                    <S.DrawerClientBoxWaterOrConcreteText>
                      SOBRA ∙ {props.alertaAgua.sobraAgua} L/m3
                    </S.DrawerClientBoxWaterOrConcreteText>
                    <S.DrawerClientBoxWaterOrConcreteText>
                      RETIDA ∙ {props.alertaAgua.aguaRetida} L
                    </S.DrawerClientBoxWaterOrConcreteText>
                  </div>
                </S.DrawerClientBoxWaterOrConcreteAlert>
              </React.Fragment>
            )}

            {props.alertaConcreto && (
              <React.Fragment>
                <S.DrawerClientBoxWaterOrConcreteAlert>
                  Concreto:
                  <div
                    style={{ display: 'flex', flexDirection: 'column', marginTop: '-0.8rem', alignItems: 'flex-start' }}
                  >
                    <S.DrawerClientBoxWaterOrConcreteText>
                      CENTRAL ∙ {props.alertaConcreto.tempoCentral} h
                    </S.DrawerClientBoxWaterOrConcreteText>
                    <S.DrawerClientBoxWaterOrConcreteText>
                      CAMINHO ∙ {props.alertaConcreto.tempoTrajetoIda} h
                    </S.DrawerClientBoxWaterOrConcreteText>
                    <S.DrawerClientBoxWaterOrConcreteText>
                      AGUARDO ∙ {props.alertaConcreto.tempoAguardoObra} h
                    </S.DrawerClientBoxWaterOrConcreteText>
                    <S.DrawerClientBoxWaterOrConcreteText>
                      DESCARGA ∙ {props.alertaConcreto.tempoDescarga} h
                    </S.DrawerClientBoxWaterOrConcreteText>
                    <S.DrawerClientBoxWaterOrConcreteText>
                      LAVAGEM ∙ {props.alertaConcreto.tempoPosDescarga} h
                    </S.DrawerClientBoxWaterOrConcreteText>
                    <S.DrawerClientBoxWaterOrConcreteText>
                      TOTAL ∙ {props.alertaConcreto.tempoTotal} h
                    </S.DrawerClientBoxWaterOrConcreteText>
                  </div>
                </S.DrawerClientBoxWaterOrConcreteAlert>
              </React.Fragment>
            )}
          </S.DrawerClientBoxContainer>
          <S.DrawerSpan>{props.textSpan}</S.DrawerSpan>
        </>
      ) : (
        <>
          <S.DrawerCard>{props.cardProfile}</S.DrawerCard>
          <S.DrawerSpan>{props.textSpan}</S.DrawerSpan>
        </>
      )}
      {children}
    </S.Drawer>
  );
};
