import { DeviceTwinModel } from '@app/domain/interfaces/device/deviceTwinModel';
import { ApiServiceBase, IApiServiceBase } from './apiServiceBase';

export default interface IDeviceTwinService extends IApiServiceBase<DeviceTwinModel, DeviceTwinModel> {}

export class DeviceTwinService extends ApiServiceBase<DeviceTwinModel, DeviceTwinModel> implements IDeviceTwinService {
  constructor() {
    super('admin/dispositivos/twin');
  }
}
