import { PrepareAction, createAction, createSlice } from '@reduxjs/toolkit';

export interface ITraceSelected {
  barIndex: number | null;
  barClicked: boolean;
  trace: string | null;
}

export interface IReportsSlice {
  tracos: string[] | null;
  traceSelected: ITraceSelected;
}

const initialState: IReportsSlice = {
  tracos: null,
  traceSelected: {
    barClicked: false,
    barIndex: null,
    trace: null,
  },
};

export const setTraces = createAction<PrepareAction<string[]>>('reports/setTraces', (traces) => {
  return {
    payload: traces,
  };
});

export const setTraceSelected = createAction<PrepareAction<ITraceSelected>>('reports/setTraceSelected', (trace) => {
  return {
    payload: trace,
  };
});

export const reportsSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setTraces, (state, action) => {
      state.tracos = action.payload;
    });

    builder.addCase(setTraceSelected, (state, action) => {
      state.traceSelected = action.payload;
    });
  },
});

export default reportsSlice.reducer;
