import { Col, Row, Tooltip } from 'antd';
import Clock from '@app/components/common/Clock/Clock';
import * as S from './HeaderConcreteMixerMonitoring.styles';
import { useNavigate } from 'react-router-dom';
import { IconMonitoring } from '@app/assets/slump-icons';
import { ArrowsAltOutlined, ClockCircleOutlined, QuestionOutlined, SearchOutlined } from '@ant-design/icons';
import { Modal } from '@app/components/common/Modal/Modal';
import { useCallback, useEffect, useState } from 'react';
import { Checkbox, CheckboxChangeEvent } from '@app/components/common/Checkbox/Checkbox';
import IConcreteMixerService, { ConcreteMixerService } from '@app/services/concreteMixerService';
import { ConcreteMixerModel } from '@app/domain/interfaces/concreteMixerModel';
import { notificationController } from '@app/controllers/notificationController';
import { Select } from '@app/components/common/selects/Select/Select';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { useDispatch } from 'react-redux';
import { setMixerMonitoring } from '@app/store/slices/monitoringSlice';

interface HeaderMonitoringProps {}

const mixerService: IConcreteMixerService = new ConcreteMixerService();

const HeaderConcreteMixerMonitoring: React.FC<HeaderMonitoringProps> = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handlerClickFullScreen = () => {};
  const [modalVisible, setModalVisible] = useState(false);
  const [checkboxTutorial, setCheckboxTutorial] = useState(false);
  const [mixers, setMixers] = useState<ConcreteMixerModel[]>([]);
  const mixer = useAppSelector((state) => state.monitoring.mixer);

  const handleOnClickCheckboxTutorial = (e: CheckboxChangeEvent) => {
    setCheckboxTutorial(e.target.checked);
    localStorage.setItem('tutorial_mixer_monitoring', e.target.checked.toString());
  };

  const fetchMixers = useCallback(() => {
    mixerService
      .getArray('obter-todos-com-device')
      .then((res) => setMixers(res.filter((r) => r.deviceId)))
      .catch(() => notificationController.error({ message: 'Erro ao buscar a lista de betoneiras.' }));
  }, []);

  useEffect(() => {
    fetchMixers();

    const tutorialChecked = localStorage.getItem('tutorial_mixer_monitoring');

    if (tutorialChecked != 'true') {
      setModalVisible(true);
      return;
    }

    setCheckboxTutorial(true);
  }, [fetchMixers]);

  return (
    <>
      <Modal
        title="Tutorial"
        centered
        open={modalVisible}
        onOk={() => setModalVisible(false)}
        onCancel={() => setModalVisible(false)}
        okText="Vamos lá"
        showCancelButton={false}
      >
        <Row gutter={10} style={{ marginBottom: '0.5rem' }}>
          <Col>
            <S.HeaderLabelTitle>
              <SearchOutlined />
            </S.HeaderLabelTitle>
          </Col>
          <Col>
            <S.HeaderLabelTitle>Pesquisar</S.HeaderLabelTitle>
          </Col>
        </Row>
        <Row gutter={10} style={{ marginBottom: '1.5rem', marginLeft: '0.2rem' }}>
          <S.HeaderLabelDescription>
            Para utilizar o dashboard com todos seus recursos funcionando, faça uma pesquisa por betoneiras, obra ou
            contrato.
          </S.HeaderLabelDescription>
        </Row>
        <Row gutter={10} style={{ marginBottom: '1.5rem' }}>
          <Col>
            <S.HeaderLabelTitle>
              <ClockCircleOutlined />
            </S.HeaderLabelTitle>
          </Col>
          <Col>
            <S.HeaderLabelDescription>Tempo necessário: 30 segundos</S.HeaderLabelDescription>
          </Col>
        </Row>
        <Row>
          <Checkbox checked={checkboxTutorial} value={checkboxTutorial} onChange={handleOnClickCheckboxTutorial}>
            Não mostrar novamente
          </Checkbox>
        </Row>
      </Modal>

      <S.HeaderRow justify="space-between" align="middle">
        <Col xs={6}>
          <S.HeaderSubRow>
            <Col xs={20}>
              <Select
                showSearch
                placeholder="Pesquise o n° da BT ou placa do caminhão"
                value={mixer?.id}
                onChange={(value) => dispatch(setMixerMonitoring(mixers.find((b) => b.id === Number(value))))}
                options={mixers.map((c) => ({
                  value: c.id,
                  label: `${c.numeroBT} | ${c.placaCaminhao}`,
                }))}
                filterOption={(input, option) => (option?.label ?? '').toLowerCase().indexOf(input.toLowerCase()) >= 0}
              />
            </Col>
            <Col xs={4}>
              <Tooltip title="Visualizar Tutorial">
                <S.HeaderTutorialButton
                  onClick={() => setModalVisible(true)}
                  type="primary"
                  shape="circle"
                  size="small"
                  icon={<QuestionOutlined />}
                ></S.HeaderTutorialButton>
              </Tooltip>
            </Col>
          </S.HeaderSubRow>
        </Col>
        <Col xs={18} md={6}>
          <Row justify={'end'} gutter={10}>
            <Col>
              <S.HeaderSubRow align={'middle'}>
                <ClockCircleOutlined />
              </S.HeaderSubRow>
            </Col>
            <Col>
              <S.HeaderSubRow align={'middle'}>
                <Clock />
              </S.HeaderSubRow>
            </Col>
            <Col>
              <S.HeaderDivider />
            </Col>
            <Col>
              <S.HeaderSubRow align={'middle'}>
                <S.HeaderButton onClick={() => navigate('/monitoramento')} style={{ marginTop: '0.5rem' }}>
                  <IconMonitoring />
                </S.HeaderButton>
              </S.HeaderSubRow>
            </Col>
            <Col>
              <S.HeaderDivider />
            </Col>
            <Col>
              <S.HeaderSubRow align={'middle'}>
                <S.HeaderButton onClick={handlerClickFullScreen}>
                  <ArrowsAltOutlined />
                </S.HeaderButton>
              </S.HeaderSubRow>
            </Col>
          </Row>
        </Col>
      </S.HeaderRow>
      <Row style={{ borderBottom: '1px solid var(--disabled-bg-color)', width: '100%' }}></Row>
    </>
  );
};

export default HeaderConcreteMixerMonitoring;
