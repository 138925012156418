import { media } from '@app/styles/themes/constants';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  margin-top: 0.625rem;
`;

export const HeaderTable = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const HeaderFilterContainer = styled.div`
  display: flex;
  flex-direction: column;

  margin-top: 0.125rem;
  margin-bottom: 2.125rem;
`;

export const Tag = styled.div``;

export const HeaderTableTitle = styled.h1`
  font-family: 'Mulish';
  font-weight: 600;
  font-size: 1.5rem;
  color: var(--text-label);
  margin-top: 1rem;

  @media only screen and (${media.xs}) {
    font-size: 1.25rem;
  }
  @media only screen and (${media.xxl}) {
    font-size: 1.5rem;
  }
`;

export const HeaderFilter = styled.h1`
  font-family: 'Mulish';
  font-weight: 600;
  font-size: 1rem;
  color: var(--text-label);
  margin-top: 1rem;

  @media only screen and (${media.xs}) {
    font-size: 1rem;
  }
  @media only screen and (${media.xxl}) {
    font-size: 1.25rem;
  }
`;

export const ExportButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0.875rem 0;
  gap: 0.5rem;
  color: #263668;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0.875rem;
`;

export const FlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
`;
