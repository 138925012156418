import { styled } from 'styled-components';
import { Collapse as AntdCollapse } from 'antd';

export const ContainerModal = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  margin-bottom: 1rem;
  margin-top: 0.5rem;
`;
export const Container = styled.div``;

export const WapperModal = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
`;
export const Title = styled.h3`
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 2rem;
`;

export const TitleProgress = styled.span`
  display: flex;
  justify-content: start;
  align-items: center;

  color: var(--text-label);
  font-family: 'Mulish';
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  margin-bottom: 1rem;
  margin-top: 1rem;
`;

export const Collapse = styled(AntdCollapse)`
  margin-top: 2rem;
  background-color: white;
  margin-left: -0.75rem;

  && .ant-collapse-header-text {
    color: var(--Neutral-Gray-Gray800, #383838);
    font-family: Mulish;
    font-size: 18px;
    font-style: bold;
    font-weight: 600;
    line-height: normal;
  }

  & .ant-collapse-expand-icon {
    color: var(--secondary-color);
    margin-right: 1rem;
    font-weight: bold;
  }
`;
export const CollapseHeaderName = styled.span`
  color: var(--Neutral-Gray-Gray800, #383838);
  font-family: Mulish;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const ContainerParamers = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
export const TimeModal = styled.span`
  display: flex;
  justify-content: start;
  align-items: center;

  color: var(--text-label);
  font-family: 'Mulish';
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  margin-left: 0.5rem;
`;
