import { DropdownMenu } from '@app/components/header/Header.styles';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { readUser } from '@app/services/localStorage.service';
import { setDrawerVisibleRegister } from '@app/store/slices/headerRegisterPage';
import { setDrawerVisible } from '@app/store/slices/headerSlice';
import { getUserType } from '@app/utils/utils';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import * as S from './ProfileOverlay.styles';

export const ProfileOverlay: React.FC = ({ ...props }) => {
  const { token } = useAppSelector((state) => state.auth);
  const [typeOfUser, setTypeOfUser] = useState<string>('');

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const handleChangeVisionClick = (e: React.MouseEvent) => {
    e.preventDefault();
    dispatch(setDrawerVisible(true));
    dispatch(setDrawerVisibleRegister(true));
  };

  useEffect(() => {
    if (token !== null) {
      setTypeOfUser(getUserType(token));
    }
  }, [token]);

  return (
    <>
      <DropdownMenu
        selectable={false}
        {...props}
        style={{ overflowX: 'hidden', overflowY: 'hidden', minWidth: '150px' }}
      >
        {(typeOfUser === 'master' || readUser()?.simulationMode) && (
          <>
            <S.MenuItem key={0}>
              <Link to="#" onClick={(e: React.MouseEvent) => handleChangeVisionClick(e)}>
                <S.Text>Mudar visão</S.Text>
              </Link>
            </S.MenuItem>
            <S.ItemsDivider />
          </>
        )}
        {typeOfUser === 'client_admin' && !readUser()?.simulationMode && (
          <>
            <S.MenuItem key={0}>
              <Link to="#" onClick={(e: React.MouseEvent) => handleChangeVisionClick(e)}>
                <S.Text>Filtros</S.Text>
              </Link>
            </S.MenuItem>
            <S.ItemsDivider />
          </>
        )}
        <S.MenuItem key={1}>
          <Link to="/logout">
            <S.Text>{t('header.logout')}</S.Text>
          </Link>
        </S.MenuItem>
      </DropdownMenu>
    </>
  );
};
